import _get from "lodash/get";
import _keyBy from "lodash/keyBy";

import { fetchBatchWise, getAbortId } from "@onlinesales-ai/util-methods-v2";
import { OSBillingServiceV2 } from "@onlinesales-ai/services-v2";

import Types from "./types";

const updateClientStatus = (clientStatus, lowBalanceAmount) => ({
  type: Types.BILLING_UPDATE_CLIENT_STATUS,
  clientStatus,
  lowBalanceAmount,
});

const updateClientAppStatus = (clientStatus) => ({
  type: Types.BILLING_UPDATE_CLIENT_APP_STATUS,
  clientStatus,
});

const updateUserPendingTransactions = (transactions) => ({
  type: Types.BILLING_UPDATE_USER_PENDING_TRANSACTIONS,
  transactions,
});

export const setBillingHygieneData = (data) => ({
  type: Types.APP_SET_BILLING_HYGIENE_DATA,
  data,
});

export const updateCartStatus = (cartStatus, shouldOverride = true) => ({
  type: Types.BILLING_UPDATE_CART_STATUS,
  cartStatus,
  shouldOverride,
});

export const updateCartStatusAction = (updatedCartStatus) => {
  return (dispatch, getState) => {
    dispatch(updateCartStatus(updatedCartStatus));
  };
};

const setPendingOrders = (pendingOrderDetails) => ({
  type: Types.BILLING_UPDATE_PENDING_ORDERS,
  pendingOrderDetails,
});

const setPlanDetailsFetchInProgress = (isFetchInProgress) => ({
  type: Types.BILLING_SET_PLAN_DETAILS_FETCH_IN_PROGRESS,
  isFetchInProgress,
});

const setPackageDetails = (packageDetails) => ({
  type: Types.BILLING_SET_PLAN_DETAILS,
  packageDetails,
});

export const fetchClientsBillingStatus = ({
  clientId,
  taggingSelectors = [],
  fetchSubscriptions = true,
  fetchBalance = true,
  shouldSaveInStore = true,
  shouldSaveClientAppStatus,
  abortId,
} = {}) => (dispatch, getState) => {
  const state = getState();
  const payload = {
    agencyId: state.DomainConfig?.agencyId,
    currency: state.DomainConfig?.marketplaceCurrency,
    clientId,
    taggingSelectors,
    fetchBalance,
    fetchSubscriptions,
  };
  const lowBalanceAmount = state.DomainConfig?.commonConfigs?.walletBalanceV2?.minTopUpRequired;

  return new Promise(async (resolve, reject) => {
    try {
      let data = await fetchBatchWise({
        apiCall: OSBillingServiceV2.fetchClientsStatusForUser.bind(OSBillingServiceV2),
        payload,
        config: {
          limit: 50,
        },
        dataResolver: (response) => {
          return response.data || [];
        },
        application: "multiClientPayment",
        abortId: abortId || "APP_LEVEL_FETCH_CLIENT_BILLING_STATUS",
      });

      data = data?.filter(({
        clientId: clientIdFromRes,
      } = {}) => {
        return clientIdFromRes !== state.DomainConfig?.marketplaceClientId;
      });

      const clientStatus = {};

      data.map((clientInfo) => {
        if (clientInfo.clientId) {
          clientStatus[clientInfo.clientId] = clientInfo;
        }
      });
      if (shouldSaveInStore) {
        dispatch(updateClientStatus(clientStatus, lowBalanceAmount));
      }

      if (shouldSaveClientAppStatus) {
        dispatch(updateClientAppStatus(clientStatus));
      }

      resolve(data);
    } catch (err) {
      if (!err.isAborted) {
        reject({
          error: err,
          errorMsg: err?.errorMsg,
        });
      }
    }
  });
};

export const fetchUserLevelPendingTransactions = ({
  clientId,
  sellerDetailsSelector = [],
  shouldSaveInStore = true,
  limit = 50,
} = {}) => (dispatch, getState) => {
  const state = getState();
  const payload = {
    agencyId: state.DomainConfig?.agencyId,
    // currency: state.DomainConfig?.marketplaceCurrency,
    clientId,
    taggingSelectors: sellerDetailsSelector,
  };
  return new Promise(async (resolve, reject) => {
    try {
      const data = await fetchBatchWise({
        apiCall: OSBillingServiceV2.fetchPendingUserTransactions.bind(OSBillingServiceV2),
        payload,
        config: {
          limit,
        },
        dataResolver: (response) => {
          return response.transactions || [];
        },
        application: "multiClientPayment",
      });
      const transactions = {};

      data.forEach((transaction) => {
        if (transaction.id) {
          transactions[transaction.id] = transaction;
        }
      });
      if (shouldSaveInStore) {
        dispatch(updateUserPendingTransactions(transactions));
      }

      resolve(data);
    } catch (e) {
      reject({
        error: e,
        errorMsg: e?.errorMsg,
      });
    }
  });
};

export const fetchPendingOrders = () => (dispatch, getState) => {
  const state = getState();
  const payload = {
    agencyId: state.DomainConfig?.agencyId,
    "taggingSelectors": ["outletId", "outletName"],
    "filters": { "status":["PENDING"] }
  };

  return new Promise(async (resolve, reject) => {
    try {
      const response = await OSBillingServiceV2.fetchOrdersForUser(
        payload,
        "multiClientPayment",
      );

      const pendingOrderDetails = {
        orders: {},
        clients: response?.clients || [],
        clientChargeIds: response?.clientChargeIds || [],
      };
      if (response?.transactions) {
        response?.transactions.forEach((orderDetails) => {
          if (orderDetails.metadata?.paymentMode) {
            if (!pendingOrderDetails.orders[orderDetails.metadata.paymentMode]) {
              pendingOrderDetails.orders[orderDetails.metadata.paymentMode] = [];
            }
            pendingOrderDetails.orders[orderDetails.metadata.paymentMode].push(orderDetails);
          }
        });
      }

      dispatch(setPendingOrders(pendingOrderDetails));
      resolve(pendingOrderDetails);
    } catch (e) {
      reject({
        error: e,
        errorMsg: e?.errorMsg,
      });
    }
  });
};

export const fetchPackageDetails = ({ clientId } = {}) => (dispatch, getState) => {
  const state = getState();
  const payload = {
    agencyId: state.DomainConfig?.agencyId,
    clientId,
  };

  return new Promise(async (resolve, reject) => {
    const packageDetails = {};
    try {
      const Service = clientId
        ? OSBillingServiceV2.fetchClientPlanDetails.bind(OSBillingServiceV2)
        : OSBillingServiceV2.fetchAgencyPlanDetails.bind(OSBillingServiceV2);
      dispatch(setPlanDetailsFetchInProgress(true));
      const response = await Service(payload, "multiClientPayment");

      if (response?.packages) {
        response?.packages.forEach((packageInfo) => {
          if (packageInfo?.metadata?.ui_plan_info) {
            try {
              packageInfo.metadata.ui_plan_info = JSON.parse(packageInfo.metadata.ui_plan_info);
            } catch (err){}
          }

          if (packageInfo.planType) {
            if (!packageDetails[packageInfo.planType]) {
              packageDetails[packageInfo.planType] = [];
            }

            packageDetails[packageInfo.planType].push(packageInfo);
          }
        });
      }

      dispatch(setPackageDetails(packageDetails));
      dispatch(setPlanDetailsFetchInProgress(false));
      resolve(response);
    } catch (e) {
      dispatch(setPackageDetails(packageDetails));
      dispatch(setPlanDetailsFetchInProgress(false));
      reject({
        error: e,
        errorMsg: e?.errorMsg,
      });
    }
  });
};

const getClientIdMappings = ({ cartStatus, clientStatus, currencyCode }) => {
  return cartStatus?.map((cartStatusObj = {}) => {
    const { clientId, forUI, ...restCartStatus } = cartStatusObj;
    const objToReturn = { clientId, ...restCartStatus };
    if (clientStatus?.[clientId]?.billingInfo) {
      const billingInfo = clientStatus?.[clientId]?.billingInfo;
      objToReturn.balance = {
        amount: Math.max(billingInfo?.balance || 0, 0),
        currency: billingInfo?.currency || currencyCode,
      };
    }
    return objToReturn;
  });
};

export const fetchCartDetailsAction = (config, application = "dashboard") => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const agencyId = state.Application?.agencyId;
  const currencyCode = state.DomainConfig?.marketplaceCurrency;
  const cartStatus = state.Billing.clientsBillingPackageDetails?.cartStatus;
  const clientStatus = state.Billing.clientsBillingPackageDetails?.clientStatus;
  const mappings = getClientIdMappings({
    cartStatus,
    clientStatus,
    currencyCode,
  });
  const response = await OSBillingServiceV2.fetchCartDetails(
    {
      agencyId,
      currency: currencyCode,
      source: "Dealer",
      mappings,
      metadata: {},
      ...config,
    },
    application,
    {
      abortId: getAbortId(config.abortId, "BILLING_CART", config.abortIdPrefix),
    },
  );
  return response;
};

export const checkoutAction = ({
  totalAmount,
  shouldUpdateCartStatus = false,
  application = "dashboard",
  paymentModeValue,
  cartInvoiceId,
  metadata = {}
}) => async (dispatch, getState) => {
  const state = getState();
  const agencyId = state.Application?.agencyId;
  const cartStatus = state.Billing.clientsBillingPackageDetails?.cartStatus;
  const pendingOrderDetails = state.Billing.clientsBillingPackageDetails?.pendingOrderDetails;
  const clientStatus = state.Billing.clientsBillingPackageDetails?.clientStatus;
  const currencyCode = state.DomainConfig?.marketplaceCurrency;
  const mappings = getClientIdMappings({
    cartStatus,
    clientStatus,
    currencyCode,
  });
  const payload = {
    agencyId,
    currency: currencyCode,
    mappings,
    source: "Dealer",
    totalAmount,
    paymentMode: paymentModeValue,
    cartInvoiceId,
    metadata,
  };
  const Service =
    totalAmount === 0
      ? OSBillingServiceV2.postSubscription.bind(OSBillingServiceV2)
      : OSBillingServiceV2.postTopup.bind(OSBillingServiceV2);

  return new Promise(async (resolve, reject) => {
    try {
      const response = await Service(payload, application);
      if (shouldUpdateCartStatus) {
        dispatch(updateCartStatus([]));
      }
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const postDeactivate = (config) => async (dispatch, getState) => {
  const state = getState();
  const agencyId = state.Application?.agencyId;

  return OSBillingServiceV2.postDeactivate({
    agencyId,
    source: "Dealer",
    clients: config.clients,
    ...config,
  });
};

const checkIfNotAlreadySubscribed = (alreadySubscribedPackages = [], packageToAdd) => {
  let isAlreadySubscribed = false;
  if (alreadySubscribedPackages.length) {
    for (let i = 0; i < alreadySubscribedPackages.length; i++) {
      if (alreadySubscribedPackages[i]?.packageId === packageToAdd?.packageId) {
        isAlreadySubscribed = true;
        break;
      }
    }
  }
  return isAlreadySubscribed;
};

export const addToBillinCart = ({ rowIds, balanceToAdd, packageToAdd }) => {
  return (dispatch, getState) => {
    const state = getState();
    const { cartStatus, clientStatus } = state.Billing.clientsBillingPackageDetails;

    let newCartStatus = [...cartStatus];
    const cartStatusByKey = _keyBy(cartStatus, "clientId");

    rowIds.forEach((rowId) => {
      const clientData = clientStatus[rowId];
      const { billingInfo, billingPackages: alreadySubscribedPackages = [] } = clientData || {};
      const cartDetails = cartStatusByKey[clientData.clientId] || {};
      const detailsToAdd = {
        ...cartDetails,
        clientId: clientData.clientId,
        forUI: {
          clientId: clientData.clientId,
          clientData,
        },
      };

      if (balanceToAdd) {
        detailsToAdd.topup = {
          amount: balanceToAdd,
          currency: billingInfo?.currency,
        };
      } else if (balanceToAdd === 0) {
        // when renewal is done for outlet with some wallet balance
        // dont override it
        delete detailsToAdd.topup;
      }

      if (packageToAdd) {
        if (detailsToAdd.packages) {
          let isFound = false;
          packageToAdd.forEach((packageDetailsToAdd) => {
            if (!checkIfNotAlreadySubscribed(alreadySubscribedPackages, packageDetailsToAdd)) {
              detailsToAdd.packages.forEach((packageInfo, index) => {
                if (packageInfo?.packageId === packageDetailsToAdd?.packageId) {
                  detailsToAdd.packages[index] = {
                    ...packageInfo,
                    ...packageDetailsToAdd,
                  };
                  isFound = true;
                }
              });
              if (!isFound) {
                detailsToAdd.packages.push(...packageDetailsToAdd);
              }
            }
          });
        } else {
          detailsToAdd.packages = [...packageToAdd];
        }
      }

      if (cartStatusByKey[clientData.clientId]) {
        newCartStatus = newCartStatus.map((cart) => {
          if (cart.clientId === clientData.clientId) {
            return detailsToAdd;
          }
          return cart;
        });
      } else {
        newCartStatus = [...newCartStatus, detailsToAdd];
      }
    });
    const cartStatusToUse = newCartStatus.filter((cartStatusObj) => {
      return cartStatusObj?.topup || cartStatusObj?.packages;
    });
    dispatch(updateCartStatus(cartStatusToUse));
  };
};

export const addInvoicesToBillingCart = ({
  rowIds,
  tdsAmountData: pTDSAmountData,
  tdsProps,
}) => {
  return (dispatch, getState) => {
    const state = getState();
    const {
      cartStatus,
      userPendingTransactions,
      tdsAmountData: sTDSAmountData,
    } = state.Billing.clientsBillingPackageDetails;

    let newCartStatus = [...cartStatus];
    const cartStatusByKey = _keyBy(cartStatus, "clientChargeId");
    const tdsAmountData = pTDSAmountData || sTDSAmountData || {};

    let amountKeyToUse = "amount";

    if (tdsProps?.tdsTypesToShow?.length) {
      const allActiveKeys = [];
      Object.keys(tdsAmountData).forEach((metadataKey) => {
        if (tdsAmountData[metadataKey]) {
          allActiveKeys.push(Object.values(tdsProps.tdsTypeConfig).find(
            (val) => val?.metadataKey === metadataKey,
          ));
        }
      });

      if (allActiveKeys.length) {
        if (allActiveKeys.length === 1) {
          amountKeyToUse = allActiveKeys[0].apiKey;
        } else {
          const joinKey = allActiveKeys.map((active) => active.key).sort().join("_");
          if (tdsProps.tdsTypeConfig[joinKey]) {
            amountKeyToUse = tdsProps.tdsTypeConfig[joinKey]?.apiKey;
          }
        }
      }
    }

    rowIds.forEach((rowId) => {
      const transactionData = userPendingTransactions[rowId];
      const balanceToAdd = _get(transactionData, amountKeyToUse) || transactionData?.amount || 0;
      const { currency, id } = transactionData || {};
      const cartDetails = cartStatusByKey[transactionData.clientId] || {};

      const detailsToAdd = {
        ...cartDetails,
        clientId: transactionData.clientId,
        clientChargeId: id,
        topup: {
          amount: balanceToAdd,
          currency,
        },
        forUI: {
          clientChargeId: id,
          transactionData,
          tdsAmountData,
        },
      };

      if (cartStatusByKey[transactionData.id]) {
        newCartStatus = newCartStatus.map((cart) => {
          if (cart.clientChargeId === transactionData.id) {
            return detailsToAdd;
          }
          return cart;
        });
      } else {
        newCartStatus = [...newCartStatus, detailsToAdd];
      }
    });
    const cartStatusToUse = newCartStatus.filter((cartStatusObj) => {
      return cartStatusObj?.topup || cartStatusObj?.packages;
    });
    dispatch(updateCartStatus(cartStatusToUse));
  };
};

export const updateTDSAmountToBillingCart = ({ tdsAmountData, entityKey, tdsProps }) => {
  return (dispatch, getState) => {
    const state = getState();
    const { cartStatus } = state.Billing.clientsBillingPackageDetails;

    dispatch(
      addInvoicesToBillingCart({
        rowIds: cartStatus.map((c) => c[entityKey]),
        tdsAmountData,
        tdsProps,
      }),
    );
  };
};
