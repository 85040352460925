import _mergeWith from "lodash/mergeWith";
import _isArray from "lodash/isArray";
import _omit from "lodash/omit";

//Change this logic to merge with https://lodash.com/docs/4.17.15#mergeWith

/**
 * 1. All properties with __OVERRIDE__ prefix will be overriden and won't be deep merged
 * 2. All properties with __REMOVE__ prefix will be removed in mergedConfig
 * 3. Arrays properties will by default be overriden and cannot be deep merged
 * 4. Any other type like text, boolean, number will be overriden
 */
const isObject = (item) => {
    return (item && typeof item === "object") && !Array.isArray(item);
};

let globalMergeIdentifier = {
  OVERRIDE: "__OVERRIDE__",
  REMOVE: "__REMOVE__",
};

export const customMergeOSClosure = ({
  mergeIdentifierMapping
}) => {
  const funDefination = (target, ...sources) => {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const overrideConfigKey in source) {
            let isSourceValueObject = isObject(source[overrideConfigKey]);

            if (isSourceValueObject && overrideConfigKey.includes("|")) {
                let keyStrSplit = overrideConfigKey.split("|");
                let keyToOverride = keyStrSplit[0];
                let mergeIdentifier = keyStrSplit[1];
                switch (mergeIdentifier) {
                    case mergeIdentifierMapping.OVERRIDE:
                        Object.assign(target, { [keyToOverride]: source[overrideConfigKey] });
                        break;
                    case mergeIdentifierMapping.REMOVE:
                        delete target[keyToOverride];
                        break;
                    default:
                        if (!target[overrideConfigKey]) {
                          Object.assign(target, { [overrideConfigKey]: {} });
                        }
                        funDefination(target[overrideConfigKey], source[overrideConfigKey]);
                }
            } else if(isSourceValueObject && !target[overrideConfigKey]) {
                Object.assign(target, { [overrideConfigKey]: {} });
                funDefination(target[overrideConfigKey], source[overrideConfigKey]);
            } else if (isSourceValueObject) {
                funDefination(target[overrideConfigKey], source[overrideConfigKey]);
            }
            else {
                Object.assign(target, { [overrideConfigKey]: source[overrideConfigKey] });
            }
        }
    }
    return funDefination(target, ...sources);
  };
  return funDefination;
};

export const customMergeOS = customMergeOSClosure({ mergeIdentifierMapping: globalMergeIdentifier });

export const mergeObject = (...objects) => {
  return _mergeWith(...objects, (objValue, srcValue) => {
    if (_isArray(objValue)) {
      return srcValue;
    }
  });
};

export const configReplacer = (props, configDefinition, configKey = "configDefinitionKey") => {
  const replacerConfig = configDefinition?.[props?.configDefinitionKey];
  if (props?.configDefinitionKey && isObject(replacerConfig)) {
    return _omit(customMergeOS({}, replacerConfig || {}, props || {}), [configKey]);
  } else {
    return props;
  }
};

export const masterConfigReplacer = (config, masterConfigDefinition, configKey = "masterConfigKey") => {
  const preparedConfig = {};
  Object.keys(config)?.forEach((key) => {
    preparedConfig[key] = _omit(
      customMergeOS(
        {},
        masterConfigDefinition[config?.[key]?.[configKey]] || {},
        config?.[key] || {},
        [configKey],
      ),
    );
  });
  return preparedConfig;
};
