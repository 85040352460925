import { formComponents } from "@onlinesales-ai/form-components-v2";

import SpendingLimitInfo from "./spendingLimitInfo";
import TopupLimit from "./topupLimit";
import WalletBalance from "./walletBalance";
import LinkToShow from "./linkToShow";
import TopupNew from "./topupNew";
import TopupWithRunwayDays from "./topupWithRunwayDays";
import AutoRenewalCandy from "./autoRenewalCandy";

export default {
  ...formComponents,
  SpendingLimitInfo,
  TopupLimit,
  WalletBalance,
  LinkToShow,
  TopupNew,
  TopupWithRunwayDays,
  AutoRenewalCandy,
};
