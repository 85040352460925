import React, { useCallback, useState, useEffect, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { connect } from "react-redux";
import { Trans } from "react-i18next";

import { ModalBase } from "@onlinesales-ai/modal-window-v2";
import { Text } from "@onlinesales-ai/label-v2";

import "./index.less";

const DrawerComponent = ({
  isOpen,
  header,
  headerRightContent,
  children,
  containerClass,
  onClickClose,
  closebutton,
  maskClosable,
  onChangeUnMounted,
  position,
  size,
  isMobileSize,
  deviceType,
  stopBodyClickPropagation,
  stopContentClickPropagation,
  onExited,
  element,
  fileDownloadStatus,
  renderInline,
}) => {
  const [isVisible, setIsVisible] = useState(isOpen);

  const didMountRef = useRef(false);

  const checkAndModifyBodyClass = (isDrawerOpen) => {
    const bodyClassList = element.classList || [];

    if (isDrawerOpen) {
      if (!bodyClassList.contains("drawer-open")) {
        bodyClassList.add("drawer-open");
      }
    } else if (didMountRef.current) {
      if (bodyClassList.contains("drawer-open")) {
        bodyClassList.remove("drawer-open");
      }
    }
  };

  useEffect(() => {
    checkAndModifyBodyClass(isOpen);
    didMountRef.current = true;

    if (isOpen) {
      setIsVisible(isOpen);
    }

    return () => {
      if (isOpen) {
        checkAndModifyBodyClass(false);
      }
    };
  }, [isOpen]);

  const onCloseDrawer = () => {
    if (onClickClose) {
      onClickClose();
    }
  };

  const onClickBody = useCallback((event) => {
    if (stopBodyClickPropagation) {
      event.stopPropagation();
    }
    if (maskClosable) {
      onCloseDrawer();
    }
  }, []);

  const onClickModalWrapper = useCallback((event) => {
    if (stopContentClickPropagation) {
      event.stopPropagation();
    }
  }, [stopContentClickPropagation]);

  const onExitedDrawer = () => {
    setIsVisible(isOpen);
    onChangeUnMounted(true);
    onExited();
  };

  return (
    <CSSTransition in={isOpen} timeout={1000} classNames="drawer-body-animation">
      <div
        className={`pure-container ${
          fileDownloadStatus?.length ? "download-trigger" : "download-trigger-closed"
        } ${deviceType} ${isMobileSize ? "enable-mobile" : ""} ${
          isVisible ? " drawer-open" : ""
        } ${containerClass} ${size} ${!renderInline ? "" : "drawer-inline"}`}
        onClick={onClickBody}
      >
        <CSSTransition
          in={isOpen}
          timeout={600}
          classNames="purge-ignore-drawer-animation"
          onExited={onExitedDrawer}
        >
          <div className={`pure-drawer ${position || ""}`} onClick={onClickModalWrapper}>
            {closebutton && (
              <label
                className="pure-toggle-label icon icon-close1"
                data-toggle-label="right"
                onClick={onCloseDrawer}
              />
            )}
            <div className="drawer-content clearfix">
              {header ? (
                <div className="drawer-header drawer-flex">
                  <Text className="drawer-title">{header}</Text>
                  {headerRightContent && (
                    <div className="drawer-header-right">{headerRightContent}</div>
                  )}
                </div>
              ) : null}
              {position && <div className="drawer-close-bar" onClick={onCloseDrawer} />}
              {children}
            </div>
          </div>
        </CSSTransition>
      </div>
    </CSSTransition>
  );
};

/**
 * Drawer Component
 *
 * this component is used modal to append drawer into the dom.
 * we passed custom component in modal for drawer
 */
const Drawer = (props) => {
  return <ModalBase component={DrawerComponent} {...props} />;
};

Drawer.Body = ({ children, className, ...props }) => {
  return (
    <div className={`drawer-body ${className || ""}`} {...props}>
      {children}
    </div>
  );
};

Drawer.Footer = ({ children, className, ...props }) => {
  return (
    <div className={`drawer-footer drawer-flex ${className || ""}`} {...props}>
      {children}
    </div>
  );
};

Drawer.defaultProps = {
  maskClosable: false,
  isOpen: false,
  containerClass: "",
  closebutton: true,
  size: "normal", // ["normal", "medium", "large"] // [medium, large, fullheight] for mobile
  position: "", // bottom
  stopBodyClickPropagation: false,
  stopContentClickPropagation: true,
  onExited: () => {},
};

const mapStateToProps = (state) => {
  const { deviceType, fileDownloadStatus } = state.Application;

  return {
    deviceType,
    fileDownloadStatus
  };
};

export default connect(mapStateToProps)(Drawer);
