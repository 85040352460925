import _omit from "lodash/omit";
import _groupBy from "lodash/groupBy";
import _sortBy from "lodash/sortBy";

import { customMergeOS } from "@onlinesales-ai/util-methods-v2";

import Types from "./types";
import AppActionTypes from "../application/types";

const initialState = {
  audience: {},
  CMS: {
    CUSTOM_TARGETING: {
      settingDetails: {
        iconUrl: "https://osads.gumlet.io/image/authenticated/s--3mnm0tti--/v1716897137/product/pulse/CT_Candy.svg",
        name: "Custom Targeting",
        description: "Custom Targeting is used to target ads based on information gathered about visitors to your website or app.",
        visibility: "INTERNAL",
        containerClass: "pendo-track-custom-targeting-setting-card",
      },
    },
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AppActionTypes.APP_CLIENT_CHANGE:
      {
        state = {
          ...initialState,
        };
      }
      break;

    case Types.SET_SETTING_AUDIENCE_DATA:
      {
        state = {
          ...state,
          audience: action.data || {},
        };
      }
      break;

    case Types.UPDATE_SETTING_AUDIENCE_DATA:
      {
        const audience = {
          ...state.audience,
          ...action.data,
        };
        state = {
          ...state,
          audience,
        };
      }
      break;
    case Types.UPDATE_USER_INFO_V2:
      {
        state = {
          ...state,
          CMS: {
            ...initialState.CMS,
            ...action?.userInfo,
          },
        };
      }
      break;
    case Types.UPDATE_CUSTOM_TARGETING_SETUP:
      {
        state = {
          ...state,
          CMS: {
            ...state.CMS,
            CUSTOM_TARGETING: {
              ...(state?.CMS?.CUSTOM_TARGETING || {}),
              settingDetails: {
                ...(state?.CMS?.CUSTOM_TARGETING?.settingDetails || {}),
                ...(action?.userInfo || {}),
              },
            },
          },
        };
      }
      break;

    default:
      break;
  }
  return state;
};

export default {
  Setting: reducer,
};
