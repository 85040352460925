import React, { useState, useRef, useMemo } from "react";
import { connect } from "react-redux";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Drawer } from "@onlinesales-ai/drawer-v2";
import { Button } from "@onlinesales-ai/button-v2";
import { useOnClickOutside } from "@onlinesales-ai/util-methods-v2";

import { FILTER_COMPONENT_TYPES } from "./contants";
import Filter from "./filter";

import "./index.less";

const FilterButtonComponent = ({
  iconClass,
  value,
  filterListConfig,
  showFilterInDrawer: showFilterInDrawerProp,
  placement,
  className,
  isMobile,
  popoverClassName,
  getCustomComponentSelectedFiltersCount,
  disbaled,
  ...restProps
}) => {
  const [isShow, setIsShow] = useState(false);
  const [isShowFilterDrawer, setIsShowFilterDrawer] = useState(false);
  const [timeStamp] = useState(new Date().getTime());
  const containerRef = useOnClickOutside(() => {
    setIsShow(false);
  });

  const showFilterInDrawer = useMemo(() => {
    return showFilterInDrawerProp || isMobile;
  }, [showFilterInDrawerProp, isMobile]);

  const hideOverlay = () => {
    setIsShow(false);
  };

  const hideFilterDrawer = () => {
    setIsShowFilterDrawer(false);
  };

  const openDrawer = () => {
    setIsShowFilterDrawer(true);
  };

  const getSelectedFiltersCount = () => {
    const filterListConfigKeys = Object.keys(filterListConfig);
    let count = 0;

    filterListConfigKeys.forEach((key) => {
      const config = filterListConfig[key];
      if (config && config.key && config.componentMetaData) {
        const dataKey = config.key;
        switch (config.type) {
          case FILTER_COMPONENT_TYPES.RADIO_BUTTON:
            {
              if (dataKey && value[dataKey]) {
                count++;
              }
            }
            break;
          case FILTER_COMPONENT_TYPES.CHECKBOX:
            {
              if (dataKey && value[dataKey]?.length) {
                count += value[dataKey]?.length;
              }
            }
            break;
          case FILTER_COMPONENT_TYPES.RANGE_FILTER:
            {
              if (dataKey && (value[dataKey]?.greater_than || value[dataKey]?.less_than)) {
                count++;
              }
            }
            break;
          case FILTER_COMPONENT_TYPES.CONTAINS_TEXT:
            {
              if (dataKey && value[dataKey]) {
                const valuesForDataKey = value[dataKey] || {};
                Object.keys(valuesForDataKey).forEach((key) => {
                  if (valuesForDataKey[key]) {
                    count++;
                  }
                });
              }
            }
            break;
          case FILTER_COMPONENT_TYPES.DATE_SELECT:
          case FILTER_COMPONENT_TYPES.UTC_DATE_SELECT:
            {
              if (dataKey && (value[dataKey]?.startDate || value[dataKey]?.endDate)) {
                count++;
              }
            }
            break;
          default:
            {
              const currentFilterCount = getCustomComponentSelectedFiltersCount(config, value) || 0;
              count += currentFilterCount;
            }
            break;
        }
      }
    });

    if (count > 0) {
      return <span className="badge d-center os-bg-danger">{count}</span>;
    }
  };

  return showFilterInDrawer ? (
    <>
      <Drawer
        containerClass="filter-drawer-wrapper"
        isNoPadding
        isOpen={isShowFilterDrawer}
        onClickClose={hideFilterDrawer}
      >
        <Filter
          value={value}
          filterListConfig={filterListConfig}
          showFilterInDrawer
          onJobDone={hideFilterDrawer}
          filterTitle="Filter"
          getCustomComponentSelectedFiltersCount={getCustomComponentSelectedFiltersCount}
          {...restProps}
        />
      </Drawer>
      <div ref={containerRef} className="filter-button-wrapper">
        <Button type="default" className="os-filter-btn" onClick={openDrawer}>
          <div className={`filter-icon icon ${iconClass}`} />
          {getSelectedFiltersCount()}
        </Button>
      </div>
    </>
  ) : (
    <OverlayTrigger
      show={isShow}
      onToggle={setIsShow}
      trigger="click"
      placement={placement}
      container={containerRef.current}
      overlay={
        <Popover
          id={`filters-popover-wrap-${timeStamp}`}
          className={`filters-popover-wrap ${popoverClassName}`}
          placement="bottom"
        >
          <Popover.Content>
            <Filter
              value={value}
              filterListConfig={filterListConfig}
              onJobDone={hideOverlay}
              getCustomComponentSelectedFiltersCount={getCustomComponentSelectedFiltersCount}
              {...restProps}
            />
          </Popover.Content>
        </Popover>
      }
      rootClose
    >
      <div ref={containerRef} className="filter-button-wrapper">
        <Button type="default" className={`os-filter-btn ${className || ""}`}>
          <div className={`filter-icon icon ${iconClass}`} />
          {getSelectedFiltersCount()}
        </Button>
      </div>
    </OverlayTrigger>
  );
};

const mapStateToProps = (state) => {
  return {
    isMobile: state.Application.isMobile || false,
  };
};

const FilterButton = connect(mapStateToProps)(FilterButtonComponent);

FilterButton.defaultProps = {
  iconClass: "icon-funnel3",
  value: {},
  filterListConfig: {},
  showFilterInDrawer: false,
  placement: "bottom-end",
  getCustomComponentSelectedFiltersCount: () => {},
};

export { FilterButton, Filter };

export * from "./contants";
