import _isEmpty from "lodash/isEmpty";
import _get from "lodash/get";

import { EMAIL_REGEX } from "@onlinesales-ai/constants-v2";
import { markDownToReactWithPlaceholder } from "@onlinesales-ai/html-v2";

export const inputValidationRegex = {
  URL: /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/,
  URL_HTTPS: /^(https:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/,
  DYNAMIC_VERIABLE: /\${(.+)}/i,
  EMAIL: new RegExp(EMAIL_REGEX),
  ALPHA_NUMERIC: /^[a-zA-Z0-9]+$/,
  SPECIAL_CHARACTERS: /[!@#$%&*()'+,-./:;<=>?[\]^`{|}~"\\\u0080-\uABFF\uD7A4-\uFFFF]/, // this regex includes icon validation
  SPECIAL_CHARACTERS_CAMPAIGN_NAME: /[!@*'/;<=>?[\]^`{}~"\\\t]/,
  FILE_NAME: /^[\w\-. ]+$/, // alphanumeric, -, _, . and space,
  TVING_APP_WEB_URL: /^(http[s]?:\/\/|tvingapp:\/\/){1}(www\.){0,1}[a-zA-Z0-9\.\-]+[\.a-zA-Z]{2,5}[\.]{0,1}(?!.*\s)/,
};

// Validation for InputText
export const validateValue = ({ validations = [], value, formValues, title = "", t = (str) => str }) => {
  let isValid = true;
  let errorMsg = null;
  let errorClassName = "";

  for (let i = 0; i < validations.length; i++) {
    if (
      !validations[i].type ||
      !validations[i].msg ||
      (validations[i].ignoreIfDynamic && inputValidationRegex.DYNAMIC_VERIABLE.test(value))
    ) {
      continue;
    }

    let validationValue = validations[i].value;
    if (validations[i]?.isValueDataKey) {
      validationValue = _get(formValues, validationValue);
    }

    switch (validations[i].type) {
      case "nonEmpty":
        {
          if (!value) {
            errorMsg = validations[i].msg;
            errorClassName = validations[i].className || "pendo-track-field-empty-error";
          }

          if (validations[i]?.allowZero && value === 0) {
            errorMsg = null;
          }
        }
        break;
      case "nonEmptyTrim":
        {
          if (!value || !value.trim()) {
            errorMsg = validations[i].msg;
            errorClassName = validations[i]?.className || "pendo-track-field-empty-trim-error";
          }
        }
        break;
      case "url":
        {
          if (validations[i]?.allowEmpty && !value) {
            // skip this
          } else if (!inputValidationRegex.URL.test(value)) {
            errorMsg = validations[i].msg;
            errorClassName = validations[i]?.className || "pendo-track-field-url-invalid-error";
          }
        }
        break;
      case "tvingAppWebUrl":
        {
          if (validations[i]?.allowEmpty && !value) {
            // skip this
          } else if (!inputValidationRegex.TVING_APP_WEB_URL.test(value)) {
            errorMsg = validations[i].msg;
            errorClassName = validations[i]?.className || "pendo-track-tving-field-url-invalid-error";
          }
        }
        break;
      case "url_native":
        {
          if (validations[i]?.allowEmpty && !value) {
            // skip this
          } else {
            let url = null;
            let isInputRelativePath = false;

            try {
              url = new URL(value);
            } catch (_) {}

            if (
              url &&
              validations[i]?.allowedProtocols?.length &&
              !validations[i]?.allowedProtocols.includes(url.protocol)
            ) {
              url = null;
            }

            if (!url && validations[i]?.isAllowRelativePath && value.startsWith("/")) {
              try {
                url = new URL(`https://sampledomain.com${value}`);
              } catch (_) {}

              if (!!url) {
                isInputRelativePath = true;
              }
            }

            if (url && !isInputRelativePath && validations[i]?.allowedDomains?.length) {
              const hostname = url.hostname;
              const validDomainRegex = `^.*(${validations[i]?.allowedDomains.join("|")})$`;
              const re = new RegExp(validDomainRegex);
              if (!re.test(hostname)) {
                url = null;
              }
            }

            if (!url) {
              errorMsg = validations[i].msg;
              errorClassName = validations[i]?.className || "pendo-track-common-error-for-invalid-url";
            }
          }
        }
        break;
      case "url_protocol":
        {
          if (validations[i]?.allowEmpty && !value) {
            // skip this
          } else {
            try {
              const url = new URL(value);
              if (validations[i].allowedProtocols?.inludes(url.protocol)) {
                errorMsg = validations[i].msg;
                errorClassName = validations[i]?.className || "pendo-track-field-url-protocol-allowed-error";
              }
            } catch (e) {
              errorMsg = validations[i].msg;
              errorClassName = validations[i]?.className || "pendo-track-field-url-protocol-error";
            }
          }
        }
        break;
      case "email":
        {
          if (validations[i]?.allowEmpty && !value) {
            // skip this
          } else if (!inputValidationRegex.EMAIL.test(value)) {
            errorMsg = validations[i].msg;
            errorClassName = validations[i]?.className || "pendo-track-field-email-invalid-error";
          }
        }
        break;
      case "minValue":
        {
          value = typeof value === "string" ? value.replace(/,/g, "") : value;
          validationValue = typeof validationValue === "string" ? validationValue.replace(/,/g, "") : validationValue;

          if (validations[i]?.allowEmpty && !Number(value)) {
            // skip this
          } else if (parseFloat(value) < parseFloat(validationValue)) {
            errorMsg = validations[i].msg;
            errorClassName = validations[i]?.className || "pendo-track-field-min-value-error";
          }
        }
        break;
      case "maxValue":
        {
          if (parseFloat(value) > parseFloat(validationValue)) {
            errorMsg = validations[i].msg;
            errorClassName = validations[i]?.className || "pendo-track-field-max-value-error";
          }
        }
        break;
      case "maxLength":
        {
          const valueToValidate = value || "";
          if (valueToValidate.length > validationValue) {
            errorMsg = validations[i].msg;
            errorClassName = validations[i]?.className || "pendo-track-field-max-length-error";
          }
        }
        break;
      case "minLength":
        {
          const valueToValidate = value || "";
          if (valueToValidate.length < validationValue) {
            errorMsg = validations[i].msg;
            errorClassName = validations[i]?.className || "pendo-track-field-min-length-error";
          }
        }
        break;
      case "betweenLength":
        {
          const valueToValidate = value || "";
          if (validations[i]?.min && validations[i]?.max) {
            if (
              valueToValidate.length < validations[i].min ||
              valueToValidate.length > validations[i].max
            ) {
              errorMsg = validations[i].msg;
              errorClassName = validations[i]?.className || "pendo-track-field-between-length-error";
            }
          }
        }
        break;
      case "betweenValue":
        {
          const valueToValidate = parseFloat(value);
          if (validations[i]?.min && validations[i]?.max) {
            if (
              valueToValidate < parseFloat(validations[i].min) ||
              valueToValidate > parseFloat(validations[i].max)
            ) {
              errorMsg = validations[i].msg;
              errorClassName = validations[i]?.className || "pendo-track-field-between-value-error";
            }
          }
        }
        break;
      case "greaterThanOrEquals":
        {
          if (Number.isNaN(parseFloat(value)) || (parseFloat(value) < parseFloat(validationValue))) {
            errorMsg = validations[i].msg;
            errorClassName = validations[i]?.className || "pendo-track-field-greater-than-equal-error";
          }
        }
        break;
      case "equals":
        {
          if (value !== validationValue) {
            errorMsg = validations[i].msg;
            errorClassName = validations[i]?.className || "pendo-track-field-value-equal-error";
          }
        }
        break;
      case "notEquals":
        {
          if (value === validationValue) {
            errorMsg = validations[i].msg;
            errorClassName = validations[i]?.className || "pendo-track-field-value-not-equal-error";
          }
        }
        break;
      case "regex":
        {
          if (validations[i]?.doNotValidateEmpty && !value) {
            // skip this
          } else if (!new RegExp(validationValue, validations[i]?.regexFlag).test(value || "")) {
            errorMsg = validations[i].msg;
            errorClassName = validations[i]?.className || "pendo-track-field-value-invalid-error";
          }
        }
        break;
      case "sameNumber":
        {
          if (validations[i]?.doNotValidateEmpty && !value) {
            // skip this
          } else if (
            `${value || ""}`.length > 1 &&
            `${value || ""}`.split("").every((v) => v === `${value[0] || ""}`)
          ) {
            errorMsg = validations[i].msg;
            errorClassName = validations[i]?.className || "pendo-track-field-same-value-error";
          }
        }
        break;
      case "shouldHaveAtListOneValue":
        {
          if (validations[i]?.keysToValidate?.length > 0 && formValues) {
            const hasSomeData = (validations[i].keysToValidate || []).some(
              (key) => !!formValues[key],
            );
            if (!hasSomeData) {
              errorMsg = validations[i].msg;
              errorClassName = validations[i]?.className || "pendo-track-field-should-have-atleast-one-error";
            }
          } else {
            errorMsg = validations[i].msg;
            errorClassName = validations[i]?.className || "pendo-track-field-should-have-atleast-one-error-empty";
          }
        }
        break;
      case "alphaNumeric":
        {
          if (validations[i]?.allowEmpty && !value) {
            // skip this
          } else if (!inputValidationRegex.ALPHA_NUMERIC.test(value)) {
            errorMsg = validations[i].msg;
            errorClassName = validations[i]?.className || "pendo-track-field-alpha-numeric-error";
          }
        }
        break;
      case "specialCharacters":
        {
          if (validations[i]?.allowEmpty && !value) {
            // skip this
          } else if (inputValidationRegex.SPECIAL_CHARACTERS.test(value)) {
            errorMsg = validations[i].msg;
            errorClassName = validations[i]?.className || "pendo-track-field-special-charaters-error";
          }
        }
        break;
      case "fileName":
        {
          if (validations[i]?.allowEmpty && !value) {
            // skip this
          } else if (!inputValidationRegex.FILE_NAME.test(value)) {
            errorMsg = validations[i].msg;
            errorClassName = validations[i]?.className || "pendo-track-field-file-name-error";
          }
        }
        break;
      case "prohibitedValues":
        {
          if (validations[i]?.allowEmpty && !value) {
            // skip this
          } else if (validations[i]?.prohibitedValues?.includes(value.toLowerCase())) {
            errorMsg = validations[i]?.msg?.replace("__VALUE__", value);
            errorClassName = validations[i]?.className || "pendo-track-field-prohibited-value-error";
          }
        }
        break;
      default:
        break;
    }

    if (typeof errorMsg === "string") {
      errorMsg = errorMsg.replace("__FIELD_TITLE__", title.toLowerCase());
      if (validations[i].markdownMsg) {
        errorMsg = markDownToReactWithPlaceholder(errorMsg);
      } else if (validations[i].msgPlaceholder) {
        errorMsg = t(errorMsg, validations[i].msgPlaceholder);
      }
      break;
    }
  }

  if (errorMsg) {
    isValid = false;
  }

  return { isValid, errorMsg, errorClassName };
};

export const validateDropdownValue = ({ validations = [], value, title = "", errorTitle }) => {
  let isValid = true;
  let errorMsg = null;
  let errorClassName = "";

  for (let i = 0; i < validations.length; i++) {
    if (!validations[i].type || !validations[i].msg) {
      continue;
    }

    switch (validations[i].type) {
      case "nonEmpty":
        {
          if (typeof value === "object" ? _isEmpty(value) : !value) {
            errorMsg = validations[i].msg;
            errorClassName = validations[i]?.className || "pendo-track-field-empty-error";
          }
        }
        break;
      case "nonEmptyWithoutBoolean":
        {
          if (typeof value !== "boolean") {
            if (typeof value === "object" ? _isEmpty(value) : !value) {
              errorMsg = validations[i].msg;
              errorClassName = validations[i]?.className || "pendo-track-field-empty-without-boolean-error";
            }
          }
        }
        break;
      default:
        break;
    }

    if (errorMsg) {
      errorMsg = errorMsg.replace("__FIELD_TITLE__", errorTitle || title.toLowerCase());
      break;
    }
  }

  if (errorMsg) {
    isValid = false;
  }

  return { isValid, errorMsg, errorClassName };
};

export const formValidations = {
  InputText: validateValue,
  Dropdown: validateDropdownValue,
};
