import React, { forwardRef, useState } from "react";
import ReactDOM from "react-dom";

import { Dropdown } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";

import WithTooltip from "@onlinesales-ai/tooltip-v2";

const DropdownMenu = ({
  iconClassName,
  options,
  isHoverDropdown,
  alignMenuRight,
  textalignMenuItems,
  dropdownBtnClass,
  children,
  onClickItem,
  showCollapse,
  className,
  disabled,
  dropdownMenuProps,
  portalElement,
  renderInPortal,
}) => {
  const { t } = useTranslation();
  const [dropdownOpen, setdropdownOpen] = useState(false);

  const dropdownHovered = () => {
    setdropdownOpen(true);
  };

  const dropdownExited = () => {
    setdropdownOpen(false);
  };

  const renderMenu = () => {
    return (
      <Dropdown.Menu align={textalignMenuItems} {...dropdownMenuProps}>
        {options.map((op) => {
          const { value, label, icon, imgIcon, className: oClassName, isDisabled, tooltip } = op;
          return (
            <WithTooltip placement="auto" title={t(tooltip)}>
              <Dropdown.Item
                key={value}
                className={oClassName}
                onClick={() => onClickItem(value, op)}
                eventKey={value}
                disabled={disabled || isDisabled}
              >
                {icon && <span className={`icon ${icon}`} />}
                {imgIcon && <img alt="" src={imgIcon} className="img-fulid" />}
                <Trans>{label}</Trans>
              </Dropdown.Item>
            </WithTooltip>
          );
        })}
      </Dropdown.Menu>
    );
  };

  return (
    <Dropdown
      className={`dropdown-with-menu d-center ${className}`}
      alignRight={alignMenuRight}
      {...(isHoverDropdown
        ? {
            show: dropdownOpen,
            onMouseLeave: dropdownExited,
          }
        : {})}
    >
      <Dropdown.Toggle
        disabled={disabled}
        className={`dropdown-menu-btn ${dropdownBtnClass}`}
        onMouseOver={dropdownHovered}
      >
        {iconClassName ? <span className={`icon ${iconClassName}`} /> : null}
        {children}
        {showCollapse && <span className="icon icon-caret-right collapse-icon" />}
      </Dropdown.Toggle>
      {renderInPortal ? ReactDOM.createPortal(renderMenu(), portalElement) : renderMenu()}
    </Dropdown>
  );
};

DropdownMenu.defaultProps = {
  iconClassName: "icon-ellipsis-v-light",
  isHoverDropdown: false,
  alignMenuRight: true,
  textalignMenuItems: "left",
  dropdownBtnClass: "",
  onClickItem: () => {},
  dropdownMenuProps: {},
  options: [],
  showCollapse: false,
  className: "",
  renderInPortal: false,
  portalElement: document.body,
};

export default DropdownMenu;
