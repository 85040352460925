import { getParameterByName } from "./utils";

export const oAuthCallBack = () => {
  const error = getParameterByName("error");

  const data = {
    success: !error,
    error,
    metadata: getParameterByName("metadata"),
    email: getParameterByName("email"),
  };

  const targetOrigin = window.location.origin;
  if (window.opener) {
    window.opener.postMessage(JSON.stringify(data), targetOrigin);
  } else {
    // console.error("ERROR: window.opener is null, /callback must be opened from another window.");
  }

  return null;
};
