import React, { useEffect, useState } from "react";

import { Drawer } from "@onlinesales-ai/drawer-v2";

import FilePreview from "./index";

const FilePreviewWithDrawer = ({ isOpen, onClickClose, ...props }) => {
  return (
    <Drawer isOpen={isOpen} onClickClose={onClickClose}>
      <FilePreview {...props} />
    </Drawer>
  );
};

export default FilePreviewWithDrawer;
