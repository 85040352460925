import React from "react";
import { Trans } from "react-i18next";

import { getLSItem } from "@onlinesales-ai/util-methods-v2";

import "./index.less";

const Downtime = ({
  imgUrl = "https://osads.gumlet.io/image/upload/v1542979686/website/Servor_Error_Asset.png",
  title = "We've Encountered a Server Error",
  description = "Dont Worry! We've dispatched our team of best engineers to fix it right away.",
  containerClass = "",
  showReload = true,
}) => {
  const refresh = () => {
    let redirectUrl = getLSItem("urlPath");
    if (redirectUrl) {
      window.location.href = redirectUrl;
    } else {
      window.location.reload(true);
    }
  };

  return (
    <div className={`dt-wrapper ${containerClass}`}>
      <div className="container">
        <img src={imgUrl} alt="down time" className="dt-img" />
        <div className="content">
          <div className="dt-title">
            <Trans>{title}</Trans>
          </div>
          <div className="dt-description">
            <Trans>{description}</Trans>
          </div>
          {showReload ? (
            <div className="dt-btn" onClick={refresh}>
              <Trans>Reload</Trans>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

Downtime.defaultProps = {};

export default Downtime;
