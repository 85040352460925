import BaseClient from "../baseClient";

const servicePrefix = "/craftService/";
class CraftService extends BaseClient {
  static getErrorMessages() {
    return {
      default: "Looks like we are facing some internal issue. Please, retry after sometime.",
      IP0000:
        "It seems some input parameters are not valid. Please update your inputs and try again.",
      AD0000:
        "Oops. It seems you are not authorized to take this action. Please log in with appropriate credentials.",
      I0004:
        "Oops. Seems the system is acting up - sorry for the inconvenience. Please try again in some time. (Please be assured our tech geniuses have been notified of this glitch).",
      INVALID_PARAMETER_ERROR:
        "It seems some input parameters are not valid. Please update your inputs and try again.",
      AD_CREATIVE_DUPLICATE_ERROR:
        "It seems creative template exists with same name, Please change name to continue.",
      INTERNAL_SERVER_ERROR:
        "Looks like we are facing some internal issue. Please, retry after sometime.",
      UNAUTHORIZED:
        "Oops. It seems you are not authorized to take this action. Please log in with appropriate credentials.",
      ACCESS_DENIED: "Oops. It seems you are not authorized to take this action. Please log in with appropriate credentials.",
      AD_PREVIEW_GENERATION_FAILED: "Unable to generate ad preview.",
      MISSING_PARAMETER_ERROR: "It seems __PARAMETERS__ paramater(s) are not valid, Please update parameters and try again.",
    };
  }

  static fetchAgencyCreativesAdPreview(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}creative/draft`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
    );
  }

  static fetchClientCreativesAdPreview(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}creative/draft/client`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
    );
  }

  static fetchAgencyCreatives(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}creative`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(payload),
        },
      },
      application,
      options,
    );
  }

  static fetchClientCreatives(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}creative/client`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(payload),
        },
      },
      application,
      options,
    );
  }

  static fetchClientCreativesV2(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}creative/clientV2`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(payload),
        },
      },
      application,
    );
  }

  static postAgencyCreatives(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}creative`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
    );
  }

  static postClientCreatives(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}creative/client`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
    );
  }

  static getAgencyTemplates(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}creative/agencyCreatives`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(payload),
        },
      },
      application,
    );
  }

  static postAgencyTemplates(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}creative/agencyCreatives`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
    );
  }

  static creative(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}creative/creative`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
    );
  }

  static getAdPlaceholders(config = {}, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}placeholders`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(config),
        },
      },
      application,
    );
  }

  static fetchAdPreview(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}creative/preview/client`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
      options
    );
  }
}

export default CraftService;
