import { NotificationService } from "@onlinesales-ai/services-v2";

import { asyncWait, fetchBatchWise } from "@onlinesales-ai/util-methods-v2";
import { uiAPIMonitor } from "@onlinesales-ai/error-catcher-v2";

import { NotificationTypesEnum } from "src/utilities/constants/dashboard";

import Types from "./types";

const NOTIFICATION_PAGE_LIMIT = 25;

const resetUINotifications = (notificationType) => {
  return {
    type: Types.RESET_UI_NOTIFICATION,
    notificationType,
    data: {
      notifications: [],
      isLoading: false,
      lastFetchedTime: null,
      firstFetchedTime: null,
      newNotificationsCount: 0,
    },
  };
};

const setUINotifications = (notificationType, notifications) => {
  return {
    type: Types.SET_UI_NOTIFICATION,
    notificationType,
    notifications,
  };
};

const setUINotificationsLastFetchedTime = (notificationType, time) => {
  return {
    type: Types.SET_LAST_FETCHED_TIME,
    notificationType,
    time,
  };
};

const setUINotificationsFirstFetchedTime = (notificationType, time) => {
  return {
    type: Types.SET_FIRST_FETCHED_TIME,
    notificationType,
    time,
  };
};

const notificationsfetchInProgress = (notificationType, isLoading) => {
  return {
    type: Types.NOTIFICATIONS_IS_LOADING,
    notificationType,
    isLoading,
  };
};

const updateUINotifications = (notificationType, notificationIds) => {
  return {
    type: Types.UPDATE_UI_NOTIFICATION,
    notificationType,
    notificationIds,
  };
};

export const fetchUINotifications = async (
  dispatch,
  getState,
  { notificationType, isIncludeSeen = true, shouldThrowError = false },
) => {
  const state = getState();
  const { clientId, userInfo } = state.Application;
  const userId = userInfo?.id;
  const notificationsData = state.Notifications[notificationType] || {};

  const { lastFetchedTime, firstFetchedTime } = notificationsData;

  let after = null;
  let before = null;
  let pageNumber = 1;

  after = lastFetchedTime;
  before = new Date().getTime();
  dispatch(setUINotificationsLastFetchedTime(notificationType, before));
  if (!firstFetchedTime) {
    dispatch(setUINotificationsFirstFetchedTime(notificationType, before));
  }

  const payload = {
    clientId,
    userId,
    includeSeen: isIncludeSeen,
    includeExpired: false,
    responseType: "UI_NOTIFICATIONS",
    before,
    after,
  };

  try {
    const notifications = await fetchBatchWise({
      apiCall: NotificationService.getUINotifications.bind(NotificationService, notificationType),
      payload,
      config: { limit: NOTIFICATION_PAGE_LIMIT },
      dataResolver: (response) => {
        const tempNotifications = response?.notifications || [];
        pageNumber += 1;

        if (notificationType === NotificationTypesEnum.ANNOUNCEMENT_BOARD) {
          tempNotifications.forEach((notification) => {
            if (notification.message) {
              try {
                notification.message = JSON.parse(notification.message);
              } catch (e) {
                notification.message = {};
              }
            }
          });
        }

        return tempNotifications;
      },
      payloadModifier: (request) => {
        const copyRequest = { ...request, pageNumber };
        delete copyRequest.limit;
        delete copyRequest.offset;

        return copyRequest;
      },
      application: "dashboard",
    });
    if (notifications?.length) {
      dispatch(setUINotifications(notificationType, notifications));
    }
  } catch (err) {
    if (shouldThrowError) {
      throw err;
    }
  }
  dispatch(notificationsfetchInProgress(notificationType, false));
};

export const getUINotifications = (config, hasPolling) => {
  let poll = true;

  return (dispatch, getState) => {
    dispatch(resetUINotifications(config.notificationType));
    dispatch(notificationsfetchInProgress(config.notificationType, true));

    if (hasPolling) {
      return {
        startPoll: async () => {
          while (poll) {
            if (poll) {
              try {
                if (navigator?.onLine && document?.visibilityState === "visible") {
                  await fetchUINotifications(dispatch, getState, {...config, shouldThrowError: true});
                }
              } catch (err) {
                if (err?.errorCode === "UNAUTHORIZED" || err?.errorCode === "AD0000") {
                  poll = false;
                }
                uiAPIMonitor("SEV3", `ANNOUNCEMENT_CALL_ERROR_${new Date().valueOf()}`, {
                  ...config,
                  error: err,
                });
              }
            }
            await asyncWait(60 * 1000);
          }
        },
        stopPoll: () => {
          poll = false;
        },
      };
    }

    return fetchUINotifications(dispatch, getState, config);
  };
};

export const updateSeenUINotifications = (notificationType, notifications) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { clientId } = state.Application;

    try {
      const data = await NotificationService.updateUINotifications({
        clientId,
        notifications,
      });
      dispatch(updateUINotifications(notificationType, data?.notificationIds || []));
    } catch (err) {}
  };
};
