import React, { useState, useEffect, useContext, useMemo, useRef } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { bindActionCreators } from "redux";

import {
  getCurrencyCode,
  getCurrencyDOM,
  formatValuesInThousands,
  populateEvent,
  fixedNumber,
  isNullOrUndefined,
  polling,
} from "@onlinesales-ai/util-methods-v2";
import { ReportingUIService } from "@onlinesales-ai/services-v2";
import PlatformEventManager from "@onlinesales-ai/event-manager-v2";
import { GlobalContext } from "@onlinesales-ai/utils-components-v2";
import { Drawer } from "@onlinesales-ai/drawer-v2";
import { updateClientAchievements as updateClientAchievementsAction } from "@onlinesales-ai/app-v2/clientAchievement";
import AsyncImage from "@onlinesales-ai/async-image-v2";
import { Button } from "@onlinesales-ai/button-v2";
import { PopoverNotificationConsumer } from "@onlinesales-ai/utils-components-v2";

import ExternalTopup from "../topup/externalTopup";
import Topup from "../topup";
import TopupWithBilling from "../billingV2";

import "./index.less";

const fireIntercomEvents = (action, metaData) => {
  populateEvent(`WALLET_BALANCE_V2||${action}`, metaData);
};

const WalletBalanceV2 = ({
  children,
  clientId,
  showTopupInDrawer,
  topUpProps,
  enableTopup,
  enableText,
  enableImage,
  topupUrl,
  targetType,
  minTopUpRequired,
  hideWalletBalance,
  currencyPrecision = 0,
  drawerComponentName,
  updateClientAchievements,
  redirectToAllSellerRoute,
  isTopupWithBilling,
  topupBtnText,
  showMinErrorPopover,
  lowBalanceMsg,
  topupTooltip,
  isRemainingBudgetV2,
  onDataAvailableCallback = () => {},
}) => {
  const { t } = useTranslation();
  const pollRef = useRef();
  const { redirectUrl, changeClientId } = useContext(GlobalContext);
  const [isOpen, setIsOpen] = useState(false);
  const [fetchState, setFetchState] = useState({
    isLoading: false,
    errorMsg: null,
    walletBalance: 0,
    walletBalanceNumber: 0,
  });
  const [isShowWalletBalanceTopUp, setIsShowWalletBalanceTopUp] = useState(false);

  const isMinRequiredError = (data) => {
    return !data.isLoading && minTopUpRequired > data.walletBalanceNumber;
  };

  const isMinError = useMemo(() => {
    return isMinRequiredError(fetchState);
  }, [fetchState, minTopUpRequired]);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const fetchClientAchievements = async (isShowLoader=true) => {
    const payload = {
      clientId,
      currency: getCurrencyCode(),
      fetchAccountBalance: true,
      dateRange: {
        min: "2021-08-08",
        max: "2021-08-22",
        // NOTE: date range doesn't have any effect on wallet balance amount
      },
      isRemainingBudgetV2,
    };

    if (isShowLoader) {
      setFetchState({
        isLoading: true,
        walletBalance: 0,
        walletBalanceNumber: 0,
      });
    }

    try {
      const response = await ReportingUIService.fetchClientAchievements(payload, "dashboard");
      let walletBalance = response?.summary?.prepaidAccountBalance || 0;
      updateClientAchievements({ prepaidAccountBalance: walletBalance });
      if (!isNullOrUndefined(walletBalance)) {
        if (walletBalance < 0) {
          walletBalance = 0;
        }
        const roundedNumber = fixedNumber(walletBalance, currencyPrecision);
        const formattedBalance = formatValuesInThousands(roundedNumber, currencyPrecision);
        setFetchState({
          isLoading: false,
          walletBalance: formattedBalance,
          walletBalanceNumber: roundedNumber || 0,
        });

        onDataAvailableCallback({
          walletBalance: formattedBalance,
          walletBalanceNumber: roundedNumber || 0,
          isSufficientWalletBalance: minTopUpRequired <= roundedNumber,
        });
      } else {
        setFetchState({
          isLoading: false,
          walletBalance: 0,
          walletBalanceNumber: 0,
          errorMsg: "Unknown Error Occurred. Please try again later.",
        });

        onDataAvailableCallback({
          walletBalanceNumber: 0,
          walletBalance: 0,
          isSufficientWalletBalance: minTopUpRequired <= 0,
        });
      }
    } catch (err) {
      setFetchState({
        isLoading: false,
        walletBalance: 0,
        walletBalanceNumber: 0,
        errorMsg: err.errorMsg,
      });

      onDataAvailableCallback({
        walletBalanceNumber: 0,
        walletBalance: 0,
        isSufficientWalletBalance: false,
      });
      if ((err?.errorCode === "UNAUTHORIZED" || err?.errorCode === "AD0000") && pollRef.current) {
        pollRef?.current?.stopPoll();
      }
    }
  };

  useEffect(() => {
    PlatformEventManager.on("OS_ACC_BALANCE_UPDATE", fetchClientAchievements);

    return () => {
      PlatformEventManager.off("OS_ACC_BALANCE_UPDATE", fetchClientAchievements);
    };
  }, []);

  useEffect(() => {
    fetchClientAchievements();
    if (clientId) {
      pollRef.current = polling(() => {
        fetchClientAchievements(false);
      }, 180000);
  
      pollRef?.current?.startPoll();
  
      return () => {
        pollRef?.current?.stopPoll();
      };
    }
  }, [clientId]);

  const onRedirect = () => {
    setIsShowWalletBalanceTopUp(false);
  };

  const clickHandler = () => {
    if (enableTopup) {
      if (topupUrl) {
        fireIntercomEvents("OPEN_EXTENAL_TOPUP_URL", { topupUrl });
        setIsShowWalletBalanceTopUp(true);
        return null;
      }

      fireIntercomEvents("OPEN_TOPUP_DRAWER");
      if (showTopupInDrawer) {
        toggleDrawer();
      } else if (redirectToAllSellerRoute) {
        changeClientId("CLIENT_AGGREGATOR");
        redirectUrl(redirectToAllSellerRoute);
      } else {
        redirectUrl(`__APPEND__/drawer/${drawerComponentName}`);
      }
    }
  };

  const defaultPopoverNotifications = useMemo(() => {
    let notifications = [];

    if(enableTopup && fetchState.errorMsg){
      notifications.push({
        placement: "bottom",
        name: "DEFAULT_ERROR_TOOLTIP_NOTIFICATION",
        type: "ERROR",
        showFirstTime: true,
        childrenClass: "min-top-up-error",
        templateProps: {
          message: fetchState.errorMsg,
          className: "purge-ignore-wallet-balance-widget-low-popover pendo_feature_error_popover",
        },
      })
    }
    if (enableTopup && showMinErrorPopover && isMinError && !fetchState.errorMsg) {
      if (lowBalanceMsg) {
        notifications.push({
          placement: "bottom",
          name: "DEFAULT_LOW_WALLET_BALANCE_TOOLTIP_NOTIFICATION",
          type: "ERROR",
          showFirstTime: true,
          childrenClass: "min-top-up-error",
          templateProps: {
            message: lowBalanceMsg,
            className: "purge-ignore-wallet-balance-widget-low-popover pendo_feature_wallet_balance_low_popover",
          },
          metadata:
            `{"walletBalance": ${fetchState.walletBalance}`,
        })
      }
    }
    if (topupTooltip) {
      notifications.push({
        placement: "bottom",
        name: "WALLET_BALANCE_DEFAULT_TOOLTIP",
        type: "DEFAULT",
        templateProps: {
          message: topupTooltip,
          className: "purge-ignore-wallet-balance-widget-default-popover pendo_feature_wallet_balance_default_popover",
        },
        showFirstTime: false,
      });
    }
    return notifications;
  }, [enableTopup, showMinErrorPopover, isMinError]);

  const renderWalletBalance = () => {
    return fetchState.isLoading ? (
      <div className="animated-bg wallet-loader" />
    ) : (
      <PopoverNotificationConsumer
        defaultNotifications={defaultPopoverNotifications}
        notificationKey='WALLET_BALANCE'
        >
          {({ togglePopover, childrenClass }) => {
            return (
              <div onClick={togglePopover} className={fetchState.errorMsg ?`wallet-error-background `:`purge-ignore-wallet-balance-wrapper-inner d-align-center purge-ignore-wallet-balance-button pendo_feature_wallet_balance_button ${childrenClass} `}>
                <div className={`purge-ignore-wallet-balance-text d-align-center ${enableTopup ? "cursor-pointer" : ""}`} onClick={clickHandler}>
                  {enableImage && (
                    <AsyncImage imgClassName="purge-ignore-wallet-balance-img" imgSrc="https://osads.gumlet.io/image/upload/v1651575233/product/wallet-balance.svg" />
                  )}
                  {enableText && (
                    <span className="purge-ignore-wallet-balance-title">{enableText}</span>
                  )}
                  {!fetchState.errorMsg && !hideWalletBalance && (<span className="purge-ignore-wallet-balance-amount">
                    {getCurrencyDOM()}
                    {fetchState.walletBalance}
                  </span>)}
                  {fetchState.errorMsg && (                
                      <div className="wallet-error-text">Error!</div>      
                  )}
                </div>
                {!fetchState.errorMsg && enableTopup && (
                  <Button type="primary" link onClick={clickHandler} className="wallet-btn purge-ignore-left-wallet-balance-button">
                    {topupBtnText}
                  </Button>
                )}
              </div>
            );
          }}
        </PopoverNotificationConsumer>
    );
  };

  const TopupComponent = isTopupWithBilling ? TopupWithBilling : Topup;

  return (
    <div className="purge-ignore-wallet-balance-wrapper">
      {children
        ? children({
            clickHandler,
            balance: fetchState.walletBalance,
            walletBalanceNumber: fetchState.walletBalanceNumber,
            isLoading: fetchState.isLoading,
            enableTopup,
            minTopUpRequired,
            isSufficientWalletBalance: minTopUpRequired <= fetchState.walletBalanceNumber,
            hideWalletBalance,
          })
        : !hideWalletBalance  && renderWalletBalance()}
      <Drawer isOpen={isOpen} onClickClose={toggleDrawer}>
        <TopupComponent {...topUpProps} jobDoneCallback={toggleDrawer} />
      </Drawer>
      {isShowWalletBalanceTopUp && <ExternalTopup topupUrl={topupUrl} onRedirect={onRedirect} targetType={targetType} />}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { clientId } = state.Application || {};
  const { commonConfigs } = state.DomainConfig;

  return {
    location: state.router.location,
    clientId,
    ...(commonConfigs?.walletBalanceV2 || {}),
    ...ownProps
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateClientAchievements: updateClientAchievementsAction,
    },
    dispatch,
  );
};

WalletBalanceV2.defaultProps = {
  drawerComponentName: "TOP_UP",
  lowBalanceMsg: "Balance low! Add sufficient amount in your media wallet to keep your campaigns running.",
  topupTooltip: "Wallet balance is the amount available in your program wallet.",
  isTopupWithBilling: false,
  enableImage: true,
  enableText: false,
  isRemainingBudgetV2: true,
};

export default connect(mapStateToProps, mapDispatchToProps)(WalletBalanceV2);
