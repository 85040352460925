import _uniq from "lodash/uniq";

import AppActionTypes from "@onlinesales-ai/app-v2/application/types";

import Types from "./types";

const initialState = {
  dataFetchInProgress: false,
  fetchError: null,
  creativeTemplatesData: {},
  adTypeWiseTemplateIds: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AppActionTypes.APP_CLIENT_CHANGE:
      {
        state = {
          ...initialState,
        };
      }
      break;
    case Types.SET_CREATIVES_TEMPLATES_DATA:
      {
        const {
          creativeTemplatesData: creativeTemplates,
          adTypeWiseTemplateIds: newAdTypeWiseTemplateIds,
        } = action.data;

        state = {
          ...state,
          creativeTemplatesData: {
            ...state.creativeTemplatesData,
            ...creativeTemplates,
          },
          adTypeWiseTemplateIds: {
            ...state.adTypeWiseTemplateIds,
            ...newAdTypeWiseTemplateIds,
          },
        };
      }
      break;
    case Types.UPDATE_CREATIVES_TEMPLATES_DATA:
      {
        const {
          creativeTemplatesData: creativeTemplates,
          adTypeWiseTemplateIds: newAdTypeWiseTemplateIds,
        } = action.data;

        const updatedAdTypeIds = { ...state.adTypeWiseTemplateIds };

        Object.keys(newAdTypeWiseTemplateIds || {}).forEach((adType) => {
          if (updatedAdTypeIds[adType]) {
            newAdTypeWiseTemplateIds[adType].forEach((id) => {
              if (!updatedAdTypeIds[adType].includes(id)) {
                updatedAdTypeIds[adType].unshift(id);
              }
            });
          } else {
            updatedAdTypeIds[adType] = newAdTypeWiseTemplateIds[adType];
          }
        });

        state = {
          ...state,
          creativeTemplatesData: {
            ...state.creativeTemplatesData,
            ...creativeTemplates,
          },
          adTypeWiseTemplateIds: updatedAdTypeIds,
        };
      }
      break;
    case Types.SET_CREATIVES_TEMPLATES_FETCH_IN_PROGRESS:
      {
        state = {
          ...state,
          dataFetchInProgress: action.dataFetchInProgress,
        };
      }
      break;
    case Types.SET_CREATIVES_TEMPLATES_FETCH_ERROR:
      {
        state = {
          ...state,
          fetchError: action.error,
        };
      }
      break;
    case Types.SET_CREATIVES_TEMPLATES_POST_IN_PROGRESS:
      {
        state = {
          ...state,
          dataPostInProgress: action.dataPostInProgress,
        };
      }
      break;
    case Types.SET_CREATIVES_TEMPLATES_POST_ERROR:
      {
        state = {
          ...state,
          dataPostError: action.error,
        };
      }
      break;
    default:
      break;
  }

  return state;
};

export default {
  Creatives: reducer,
};
