import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { OSHOCWithUtilities } from "@onlinesales-ai/os-hoc-with-utilities-v2";
import { BaseClient, ReportingUIService } from "@onlinesales-ai/services-v2";
import { FetchBanner } from "@onlinesales-ai/message-card-v2";
import { ConfigurationCandy } from "@onlinesales-ai/goals-candy-v2";
import { ToggleButton } from "@onlinesales-ai/toggle-button-v2";
import { OverlayLoader } from "@onlinesales-ai/loader-v2";
import { populateEvent } from "@onlinesales-ai/util-methods-v2";
import { FormWrapper } from "@onlinesales-ai/form-components-v2";
import "./index.less";

const fireIntercomEvents = (action, metaData) => {
  populateEvent("BILLING", action, metaData);
};

const AutoRenewalCandy = ({
  clientId,
  agencyId,
  shopInfo = {},
  userInfo = {},
  platform,
  appName,
  packageName,
  currency,
  paymentMode,
  errorTitle,
  showConfirmationModal = () => {},
  resetConfirmationModal = () => {},
  showToastMessage = () => {},
  ...restProps
}) => {
  const [isFetchInProgress, setIsFetchInProgress] = useState(true);
  const [fetchErrorMsg, setFetchErrorMsg] = useState(null);
  const [isAutoRenewalEnabled, setIsAutoRenewalEnabled] = useState(false);
  const [isPostInProgress, setIsPostInProgress] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    fetchAutoRenewalData();
  }, [clientId]);

  const fetchAutoRenewalData = async () => {
    setIsFetchInProgress(true);
    setFetchErrorMsg(null);
    setIsAutoRenewalEnabled(false);

    try {
      let payload = {
        clientId,
        agencyId,
        storeType: shopInfo.storeType || "",
        platform,
        appName,
        packageName,
        currency,
      };
      const response = await ReportingUIService.fetchClientSubscriptions(payload, "Dashboard");
      const isEnabled = response?.data?.customer?.customerData?.isPrepaidAutoSwipeEnabled || false;
      setIsAutoRenewalEnabled(isEnabled);
    } catch (error) {
      setFetchErrorMsg(error.errorMsg);
    } finally {
      setIsFetchInProgress(false);
    }
  };

  const toggleAutoRenewalStatus = async () => {
    const isPrepaidAutoSwipeEnabled = !isAutoRenewalEnabled;
    const payload = {
      clientId,
      subscriptions: [],
      userId: userInfo?.id,
      paymentMode,
      platform,
      email: userInfo?.email,
      isPrepaidAutoSwipeEnabled,
      shouldSwipeOnSubscribe: false,
      isSendInvoiceEnabled: false,
    };

    setIsPostInProgress(true);

    try {
      await ReportingUIService.postSubscriptionPlans(payload, "Dashboard");
      setIsAutoRenewalEnabled(isPrepaidAutoSwipeEnabled);
    } catch (error) {
      let errorMessage = BaseClient.getErrorMessage();
      if (typeof error === "object" && error.errorMsg) {
        errorMessage = error.errorMsg;
      }
      showToastMessage({
        messageToDisplay: errorMessage,
        type: "ERROR",
        toastDuration: 5000,
      });
    } finally {
      setIsPostInProgress(false);
    }
  };

  const onAutoRenewalToggle = () => {
    const message = isAutoRenewalEnabled
      ? "Are you sure you want turn off auto-renewal? All your campaigns will be paused on budget exhaustion."
      : "Great! Let us take care of your auto top-ups and campaigns.";

    const actionBtnText = isAutoRenewalEnabled ? "Turn Off" : "Turn On";

    fireIntercomEvents("AUTO_RENEWAL_TOGGLE_CLICK", {
      currentAutoRenewalState: isAutoRenewalEnabled,
    });

    showConfirmationModal({
      isShow: true,
      title: t(message),
      actionBtnText: t(actionBtnText),
      rightBtnText: t("cancel"),
      actionBtnCallback: () => {
        fireIntercomEvents("AUTO_RENEWAL_TOGGLE_CONFIRMED", {
          from: isAutoRenewalEnabled,
          to: !isAutoRenewalEnabled,
        });
        resetConfirmationModal();
        toggleAutoRenewalStatus();
      },
      rightBtnCallback: () => {
        fireIntercomEvents("AUTO_RENEWAL_TOGGLE_DENIED", {
          currentAutoRenewalState: isAutoRenewalEnabled,
        });
        resetConfirmationModal();
      },
    });
  };

  const getCandyLoaderDOM = () => {
    return <ConfigurationCandy isLoading={isFetchInProgress} />;
  };

  const renderCandy = () => {
    return (
      <ConfigurationCandy
        isLoading={false}
        firstRowRightChildren={
          <>
            <p className="text">
              <Trans>
                Your campaign would keep running everyday for the set budget. If you do not have
                sufficient wallet balance, your wallet balance would be automatically topped up by
                debiting your COD outstanding.
                {/* {isAutoRenewalEnabled
                  ? "Auto Renewal mode enables automatic recurring payments when your budget is about to get exhausted"
                  : "Turn on the Auto Renewal mode for automatic recurring payments"} */}
              </Trans>
            </p>
            <div className="auto-renewal-toggle-wrapper">
              <span className="auto-renewal-toggle-btn">
                <label>
                  <Trans>Off</Trans>
                </label>
                <ToggleButton
                  toggleType="primary"
                  isOn={isAutoRenewalEnabled}
                  onToggleCallback={onAutoRenewalToggle}
                />
                <label>
                  <Trans>On</Trans>
                </label>
              </span>
              <span className="auto-renewal-toggle-btn-text">
                <Trans>Auto Renewal</Trans>
              </span>
            </div>
          </>
        }
      />
    );
  };

  return (
    <FormWrapper
      title={restProps.title}
      formGroupClassName={`auto-renewal-candy-form-component ${restProps.formGroupClassName || ""}`}
      titleTooltip={restProps.titleTooltip}
      titleGuidText={restProps.titleGuidText}
      labelColumns={restProps.labelColumns}
    >
      <div className="auto-renewal-candy-wrapper">
        {isPostInProgress && <OverlayLoader />}
        <FetchBanner
          isLoading={isFetchInProgress}
          errorTitle={errorTitle}
          errorMsg={fetchErrorMsg}
          Loader={getCandyLoaderDOM()}
        >
          {renderCandy()}
        </FetchBanner>
      </div>
    </FormWrapper>
  );
};

AutoRenewalCandy.defaultProps = {
  platform: "OS",
  appName: "acquire",
  packageName: undefined,
  currency: undefined,
  errorTitle: "Error fetching auto renewal status",
  paymentMode: "OFFLINE",
};

const mapStateToProps = (state) => {
  return {
    clientId: state.Application.clientId,
    shopInfo: state.Application.shopInfo || {},
    userInfo: state.Application.userInfo || {},
  };
};

export default connect(mapStateToProps)(OSHOCWithUtilities(AutoRenewalCandy));
