import { ObjectivesEnum } from "@onlinesales-ai/constants-v2";

export const bidTargetLevelEnum = {
  MARKETING_CAMPAIGN: "marketingCampaign",
  CATEGORY: "category",
  SKU: "sku",
};

export const bidTableFieldEnum = {
  TEXT: "text",
  TEXT_IMAGE: "text-image",
  NUMBER: "number",
  FORMATED_NUMBER: "formated-number",
  EDITABLE_NUMBER: "editable-number",
};

export const bidTypeEnum = {
  ROI: {
    key: "ROI",
    displayName: "ROI",
    info: "Return on Investment",
    objective: ObjectivesEnum["Absolute Revenue"].key,
  },
  CPC: {
    key: "CPC",
    displayName: "CPC",
    info: "Cost Per Click",
    objective: ObjectivesEnum.Visitors.key,
  },
};
