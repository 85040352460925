import React from "react";
import { Trans } from "react-i18next";
import { Button } from "@onlinesales-ai/button-v2";
import "./index.less";

const FeatureReleaseBoard = (previewData = {}) => {
  return (
    <div className="feature-release-layout">
      <div className="feature-left first-block">
        <div className="feedBox">
          <h3>
            <Trans>{previewData.featureTitle}</Trans>
          </h3>
          <p>
            <Trans>{previewData.featureDescription}</Trans>
          </p>
        </div>
      </div>
      <div className="feature-right second-block">
        <div className="feedBoxMeta">
          <h3>
            <Trans>{previewData.title}</Trans>
          </h3>
          <p>
            <Trans>{previewData.description}</Trans>
          </p>
        </div>
        <div className="feature-btn-group">
          <Button onClick={previewData.redirectTo} type="default" className="feature-cta">
            {previewData.cta.text}
          </Button>
          {previewData.ghostCta && (
            <Button type="default" className="feature-cta default-cta">
              {previewData.ghostCta.text}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

FeatureReleaseBoard.defaultProps = {
  featureTitle: "",
  featureDescription: "",
  title: "",
  description: "",
  cta: {
    text: "",
    url: "",
  },
  ghostCta: {
    text: "",
    url: "",
  },
  redirectTo: () => {},
};

export default FeatureReleaseBoard;
