import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import _omit from "lodash/omit";

import { Text } from "@onlinesales-ai/label-v2";
import AsyncImage from "@onlinesales-ai/async-image-v2";

import Radio from "../Radio";

import "./index.less";

const RadioGroupTab = ({
  defaultValue,
  groupValue,
  name,
  className,
  tabsWrapperClass,
  data,
  layout,
  onChange,
  allowUndefined,
  disabled: groupDisabled,
  mainLabel,
  type,
  onlyBorder,
}) => {
  const [timeStamp] = useState(new Date().getTime());
  const [selectedValue, setSelectedValue] = useState(
    typeof groupValue !== "undefined" || allowUndefined ? groupValue : defaultValue,
  );

  useEffect(() => {
    if (typeof groupValue !== "undefined" || allowUndefined) {
      setSelectedValue(groupValue);
    }
  }, [groupValue]);

  const onChangeRadio = (value, e) => {
    if (typeof groupValue === "undefined") {
      setSelectedValue(value);
    }
    onChange(value, e);
  };

  const renderLabel = ({ labelDefinationKey, label, iconUrl, description, labelProps }) => {
    let newLabel = null;

    if (labelDefinationKey) {
      newLabel = <Text labelDefinationKey={labelDefinationKey} {...labelProps} />;
    } else {
      newLabel = label;
    }

    if (iconUrl) {
      newLabel = (
        <div className={`${description ? "with-sub-label" : "label-with-icon"}`}>
          <AsyncImage imgSrc={iconUrl} />
          <div className="right">
            <div className="label">{newLabel}</div>
            {description && <div className="sub-label">{description}</div>}
          </div>
        </div>
      );
    }
    return newLabel;
  };

  return (
    <div
      className={`radio-group-tabs-wrapper ${onlyBorder ? "bordered-tabs" : ""} ${
        tabsWrapperClass || ""
      }`}
    >
      {mainLabel && (
        <label className="main-label">
          <Trans>{mainLabel}</Trans>
        </label>
      )}
      <div className={`radio-group radio-group-tabs ${className || ""} ${layout} ${type}`}>
        {data.map(({ id, value, disabled, ...rest }) => {
          const newLabel = renderLabel(rest);
          return (
            <Radio
              {..._omit(rest || {}, ["labelDefinationKey"])} // ommiting labelDefinationKey as its already handled here in RadioGroupTab
              label={newLabel}
              key={value}
              disabled={groupDisabled || disabled}
              name={name}
              id={id || `${value}-${timeStamp}`}
              value={value}
              checked={selectedValue === value}
              onChange={(val, e) => onChangeRadio(value, e)}
            />
          );
        })}
      </div>
    </div>
  );
};

RadioGroupTab.defaultProps = {
  layout: "vertical",
  disabled: false,
  allowUndefined: false,
  type: "default", // tabs
  onlyBorder: false,
};

export default RadioGroupTab;
