export const entityTypeEnum = {
  AGENCY: "AGENCY",
  CLIENT: "CLIENT",
  MCC: "MCC",
};

export const entityTypeEnumForStore = {
  CLIENT: "clients",
  MCC: "mccs",
};

// export const businessDefinitionEnum = {
//   AGENCY: "AGENCY",
//   ADVERTISER: "ADVERTISER",
// };
