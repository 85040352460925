import React, { useState, useRef } from "react";
import { Popover, Overlay } from "react-bootstrap";

import { Button } from "@onlinesales-ai/button-v2";

import TicketComponent from "../ticketComponent";

import "./index.less";

const ZohoTicket = ({ ...props }) => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const target = useRef(null);

  const toggleForm = () => {
    setIsFormOpen(!isFormOpen);
  };

  return (
    <>
      <Button
        ref={target}
        icon="icon-customer-service"
        shape="circle"
        size="large"
        onClick={toggleForm}
        className="zoho-icon"
      />
      <Overlay rootClose show={isFormOpen} target={target} placement="top-end">
        <Popover className="zoho-ticket-popover ticket-popover">
          <div className="zoho-ticket-wrapper">
            <TicketComponent {...props} onClickClose={toggleForm} />
          </div>
        </Popover>
      </Overlay>
    </>
  );
};

ZohoTicket.defaultProps = {};

export default ZohoTicket;
