import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { redirectUrl } from "src/store/application";
import { RouteBasedModal } from "@onlinesales-ai/app-v2";
import GMBLocationVerificationModal from "src/pages/routeBasedDrawer/sellerPageDetails/gmbLocationVerificationModal";

const COMPONENT_MAPPING = {
  GMB_VERIFICATION_MODAL: GMBLocationVerificationModal
};

const RouteBasedModalWrapper = ({ 
  redirectUrlTo = () => {} 
}) => {
  return (
    <RouteBasedModal
      redirectUrl={redirectUrlTo}
      componentMapping={COMPONENT_MAPPING}
    />
  )
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      redirectUrlTo: redirectUrl,
    },
    dispatch,
  );
};
  
export default connect(null, mapDispatchToProps)(RouteBasedModalWrapper);