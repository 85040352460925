import React, { useState, useMemo, useRef } from "react";
import { connect } from "react-redux";
import { toBlob } from "html-to-image";
import { Popover, Overlay } from "react-bootstrap";

import { Button } from "@onlinesales-ai/button-v2";
import { ShopsUIService } from "@onlinesales-ai/services-v2";
import { Checkbox } from "@onlinesales-ai/checkbox-v2";
import { loaderWhite } from "@onlinesales-ai/loader-v2";
import { Textarea } from "@onlinesales-ai/input-v2";
import { MediaUpload } from "@onlinesales-ai/media-library-v2";

import "./index.less";

const ReportBug = ({ userInfo, shopInfo, clientId, recipients }) => {
  const fileUploaderRef = useRef(null);

  const [isFormOpen, setIsFormOpen] = useState(false);
  const [postState, setPostState] = useState({
    isLoading: false,
    postSuccess: false,
  });
  const [enableScreenshot, setEnableScreenshot] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [screenshotState, setScreenshotState] = useState({
    screenshotBlob: null,
    screenshotImgFile: null,
  });
  const [comment, setComment] = useState("");
  const isCommentInvalid = useMemo(() => comment?.length < 30, [comment]);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [target, setTarget] = useState(null);
  const wrapperRef = useRef(null);

  const clearFileUploadInput = () => {
    setUploadedFile(null);
    if (fileUploaderRef && fileUploaderRef.current) {
      fileUploaderRef.current.value = "";
    }
  };

  const clearForm = () => {
    clearFileUploadInput();
    setScreenshotState({
      screenshotBlob: null,
      screenshotImgFile: null,
    });
    setComment("");
    setEnableScreenshot(false);
    setShowErrors(false);
    setPostState({
      isLoading: false,
      postSuccess: false,
    });
  };

  const toggleBugForm = (e) => {
    setTarget(e?.target);
    setIsFormOpen(!isFormOpen);
    if (!isFormOpen) {
      clearForm();
    }
  };

  const handleFormSubmit = async () => {
    setShowErrors(true);
    if (!isCommentInvalid) {
      setPostState({
        isLoading: true,
        postSuccess: false,
      });
      const payload = new FormData();
      payload.append("clientId", clientId);
      payload.append("userId", userInfo?.id);
      payload.append("userInfo", JSON.stringify(userInfo));
      payload.append("shopInfo", JSON.stringify(shopInfo));
      payload.append("clientName", shopInfo?.name);
      payload.append("bugReportContent", comment);
      if (recipients) {
        payload.append("recipients", recipients);
      }
      if (uploadedFile) {
        payload.append("userGeneratedScreenshot", uploadedFile);
      }
      if (screenshotState?.screenshotImgFile) {
        payload.append("systemGeneratedScreenshotURL", screenshotState?.screenshotImgFile);
      }

      try {
        await ShopsUIService.sendBugReport(payload);
        clearForm();
        setPostState({
          isLoading: false,
          postSuccess: true,
        });
        window.setTimeout(() => {
          setIsFormOpen(false);
        }, 3000);
      } catch (error) {
        setPostState({
          isLoading: false,
          postSuccess: false,
          errorMsg: error?.errorMsg,
        });
      }
    }
  };

  const filterScreenshot = (node) => {
    const exclusionClasses = ["global-app-actions", "bug-form-wrapper"];
    return !exclusionClasses.some((classname) => {
      if (node?.classList?.contains) {
        return node.classList.contains(classname);
      }
      return false;
    });
  };

  const takeScreenshot = async () => {
    try {
      const useBody = [
        ".route-based-drawer-wrapper",
        ".modal-dialog",
        ".popover .popover-body",
      ].some((query) => document.querySelector(query));

      const ele = useBody
        ? document.body
        : document.getElementsByClassName("content-body")?.[0] || document.body;

      const imgBlob = await toBlob(ele, {
        filter: filterScreenshot,
        width: ele.scrollWidth,
        height: ele.scrollHeight,
      });
      const imgFile = new File([imgBlob], "screenshot.png", { type: imgBlob.type });
      const reader = new FileReader();
      reader.onload = (e) => {
        setScreenshotState({
          screenshotImgFile: imgFile,
          screenshotBlob: e.target.result,
        });
      };
      reader.readAsDataURL(imgFile);
    } catch (err) {
      // handle error
      setScreenshotState({
        screenshotError: "Unable to take screenshot, Please try again!",
      });
    }
  };

  const onChangeEnableScreenshot = (val) => {
    if (val) {
      takeScreenshot();
    } else {
      setScreenshotState({
        screenshotBlob: null,
      });
    }
    setEnableScreenshot(val);
  };

  const onChangeComment = (event) => {
    setComment(event.target.value);
  };

  const getFileUploaderOverlayDomNode = () => {
    return uploadedFile ? (
      <div className="uploaded-file">
        <span className="upload-filename ellipsis-text">{uploadedFile && uploadedFile.name}</span>
        <span onClick={clearFileUploadInput} className="clear-input icon icon-close" />
      </div>
    ) : (
      <label className="browse-btn">
        <div className="icon icon-add-plus">Add File</div>
      </label>
    );
  };

  const bugReportPop = (
    <>
      {isFormOpen ? (
        <div
          className={`bug-form-wrapper ${postState?.postSuccess ? "successful" : "not-successful"}`}
        >
          <div className="header">
            <div className="title">Report An Issue</div>
            <div className="closebtn icon icon icon-close1" onClick={toggleBugForm} />
          </div>
          <div className="bug-textarea">
            <Textarea
              textareaWrapperClassName={showErrors && isCommentInvalid ? "error-control" : ""}
              placeholder="Describe your issue."
              value={comment}
              onChange={onChangeComment}
            />
            <div className="bug-textarea-charcter">Minimum 30 characters</div>
          </div>
          <Checkbox
            checked={enableScreenshot}
            onChange={onChangeEnableScreenshot}
            label="Include Screenshot"
            id={"includescreenshot"}
          />
          <div className="preview-contain">
            {enableScreenshot ? (
              <div className="image-contain">
                {screenshotState?.screenshotError && (
                  <div className="error-msg">{screenshotState?.screenshotError}</div>
                )}
                {!screenshotState?.screenshotError &&
                  (screenshotState?.screenshotBlob ? (
                    <img src={screenshotState?.screenshotBlob} />
                  ) : (
                    loaderWhite()
                  ))}
              </div>
            ) : (
              <div className="noimage-contain">No image Captured</div>
            )}
          </div>
          <MediaUpload
            onStartUpload={(file) => setUploadedFile(file)}
            isUploadOnCloudinary={false}
            overlayDomNode={getFileUploaderOverlayDomNode()}
            inputRef={fileUploaderRef}
          />
          <div className="error-msg">{postState?.errorMsg}</div>
          <Button
            type="danger"
            block
            isLoading={postState?.isLoading}
            onClick={handleFormSubmit}
            disabled={postState?.isLoading}
          >
            Submit
          </Button>
          {postState?.postSuccess ? (
            <div className="bug-success-overlay">
              <div className="bug-success-overlay-inner">
                <div className="bug-success-overlay-txt">
                  Your issue has been reported.
                  <br />
                  We will get in touch with you shortly.
                </div>
                <div className="bug-success-overlay-img" />
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  );

  return (
    <>
      <Button icon="icon-bug" shape="circle" size="large" onClick={toggleBugForm} />
      <div className="bug-report-wrapper" ref={wrapperRef}>
        <Overlay
          rootClose
          show={isFormOpen}
          target={target}
          placement="top-end"
          container={wrapperRef.current}
        >
          <Popover>{bugReportPop}</Popover>
        </Overlay>
      </div>
    </>
  );
};

ReportBug.defaultProps = {};

const mapStateToProps = (state) => {
  const { clientId, userInfo, shopInfo } = state.Application || {};
  return {
    location: state.router.location,
    clientId,
    userInfo,
    shopInfo,
  };
};

export default connect(mapStateToProps)(ReportBug);
