import React from "react";
import { DropdownAsync } from "@onlinesales-ai/dropdown-v2";
import DropdownForm from "../dropdown";

const DropdownAsyncForm = (props) => {
  return (
    <DropdownForm
      dropdownComponent={DropdownAsync}
      {...props}
    />
  );
};

export default DropdownAsyncForm;
