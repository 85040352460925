import React, { useMemo } from "react";

import DyanamicList from "./component/dyanamicList";

const DayParting = ({
  actionsToShow: pActionsToShow,
  commonActionConfig,
  commonActionConfigKey,
  ...props
}) => {
  const prepareActionConfig = useMemo(() => {
    const newActionConfig = {};

    pActionsToShow.forEach(
      (action) =>
        (newActionConfig[action?.value] = {
          label: action?.label,
          disabled: action?.disabled,
          // actionGroup: action?.actionGroup,
          componentToShow: [commonActionConfigKey],
          componentConfig: commonActionConfig,
        }),
    );

    return newActionConfig;
  }, [pActionsToShow, commonActionConfig]);

  const actionsToShow = useMemo(() => {
    const keysToSend = [];
    pActionsToShow.forEach((actionConfig) => {
      keysToSend.push(actionConfig?.value);
    });

    return keysToSend;
  }, [pActionsToShow]);

  return (
    <DyanamicList
      actionsToShow={actionsToShow}
      commonComponentConfig={commonActionConfig}
      commonComponentToShow={[commonActionConfigKey]}
      actionConfig={prepareActionConfig}
      {...props}
    />
  );
};

export default DayParting;
