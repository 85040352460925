import AppActionTypes from "../application/types";

import Types from "./types";

const reducer = (state, action) => {
  switch (action.type) {
    case AppActionTypes.APP_CLIENT_CHANGE:
      {
        state = {
          ...action.domainConfig,
        };
      }
      break;
    case Types.SET_DOMAIN_CONFIG:
      {
        state = {
          ...(action.config || {}),
        };
      }
      break;
    default:
      break;
  }

  return state;
};

export default reducer;
