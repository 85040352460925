export const loadFlutterwavePaymentScript = () => {
  if (document.getElementById('flutterwave-checkout-script') && window?.Razorpay) {
    return Promise.resolve(window.Razorpay);
  }

  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.id = "flutterwave-checkout-script";
    script.src = "https://checkout.flutterwave.com/v3.js";
    script.async = true;

    script.onerror = () => {
      reject();
    };

    script.onload = () => {
      if (window?.FlutterwaveCheckout) {
        resolve(window.FlutterwaveCheckout);
      } else {
        reject();
      }
    };

    document.head.appendChild(script);
  });
};

export const loadRazorPayCheckout = () => {
  if (document.getElementById('razor-pay-checkout-script') && window?.Razorpay) {
    return Promise.resolve(window.Razorpay);
  }

  return new Promise((resove, reject) => {
    const script = document.createElement("script");
    script.id = 'razor-pay-checkout-script';
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;

    script.onerror = function () {
      reject();
    };

    script.onload = function () {
      if (window?.Razorpay) {
        resove(window.Razorpay);
      } else {
        reject();
      }
    };

    document.head.appendChild(script);
  });
};

export const loadSnapPayScript = (isStaging) => {
  if (document.getElementById('snap-pay-script') && window?.snap) {
    return Promise.resolve(window.snap);
  }

  return new Promise((resove, reject) => {
    const script = document.createElement("script");
    script.id = 'snap-pay-script';
    script.src = (NODE_ENV === "development" || isStaging) ? "https://app.sandbox.midtrans.com/snap/snap.js" : "https://app.midtrans.com/snap/snap.js";
    script["data-client-key"] = "SB-Mid-client-ZSW6-7knzyHktYLr";
    // script["data-client-key"] = "SB-Mid-client-GXUNFJGTaA-Pc_k6";
    script.async = true;

    script.onerror = function () {
      reject();
    };

    script.onload = function () {
      if (window?.snap) {
        resove(window.snap);
      } else {
        reject();
      }
    };

    document.head.appendChild(script);
  });
};

export const loadBankOpenScript = () => {
  if (document.getElementById('snap-pay-script') && window?.snap) {
    return Promise.resolve(window.snap);
  }

  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.id = 'open_money_layer';
    script.src = (NODE_ENV === "production") ? "https://payments.open.money/layer/js" : "https://sandbox-payments.open.money/layer/js";
    // script.src = (NODE_ENV === "production") ? "https://payments.open.money/layer" : "https://sandbox-payments.open.money/layer";
    script.async = false;

    script.onerror = function () {
      reject();
    };

    script.onload = function () {
      if (window?.Layer) {
        resolve(window.Layer);
      } else {
        reject();
      }
    };

    document.head.appendChild(script);
  });
};

export const loadYoutubeIframeAPI = () => {
  if (document.getElementById('youtube-iframe-api') && window?.YT) {
    return Promise.resolve(window.YT);
  }

  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.id = 'bankopen-layer-script';
    script.src = "https://www.youtube.com/iframe_api";
    script.async = true;

    script.onerror = function () {
      reject();
    };

    window.onYouTubeIframeAPIReady = function() {
      resolve(window?.YT);
    };

    document.head.appendChild(script);
  });
};

