import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import PlatformEventManager from "@onlinesales-ai/event-manager-v2";

import "./index.less";

const GuideProcessor = ({ UINotification, location }) => {
  const [classNames, setClassNames] = useState([]);
  const isGuideTriggered = useRef(false);

  const computeNotification = () => {
    if (UINotification?.length) {
      setClassNames(UINotification.map((p) => p?.pendoGuideClassName).filter((c) => !!c));

      const firstGuide = UINotification.find((p) => p?.pendoGuideId);

      // add multiple run logic
      if (firstGuide && window?.pendo?.showGuideById && !isGuideTriggered.current) {
        const isTriggerred = window.pendo.showGuideById(firstGuide.pendoGuideId);
        isGuideTriggered.current = isTriggerred;
      }
    }
  };

  useEffect(() => {
    const onPendoGuideLoaded = () => {
      computeNotification();
    };

    PlatformEventManager.on(
      "PENDO_GUIDE_LOADED",
      onPendoGuideLoaded,
    );

    return () => {
      PlatformEventManager.off(
        "PENDO_GUIDE_LOADED",
        onPendoGuideLoaded,
      );
    };
  }, [UINotification, location.pathname]);

  useEffect(() => {
    computeNotification();
  }, [UINotification, location.pathname]);

  return <div className={`pendo_guide_dom ${classNames.join(" ")}`} />;
};

const mapStateToProps = (state) => {
  return {
    UINotification: state.UINotification?.data?.PENDO_GUIDE_NOTIFICATION || [],
    location: state.router.location,
  };
};

export default connect(mapStateToProps)(GuideProcessor);
