import { getVisitorAccountObj } from "./utils";

export const populateEvent = (...props) => {
  if (window._sokEvents) {
    window._sokEvents.populateEvent(...props);
  }
};

export const pendoTrackEvent = (eventName, metadata = {}) => {
  if (window.pendo?.track) {
    if (typeof metadata === "object") {
      let newMetadata = { ...metadata };
      let modifiedData = {};
      Object.keys(newMetadata).map((key) => {
        if (typeof newMetadata[key] === "string") {
          modifiedData[key] = (newMetadata[key] || "").toString().trim().substring(0, 200);
        } else if (typeof newMetadata[key] === "object") {
          modifiedData[key] = JSON.stringify(newMetadata[key] || {}).trim().substring(0, 200);
        } else {
          modifiedData[key] = newMetadata[key];
        }
      });
      if (metadata.pendoAccountId && window.pendo?.updateOptions) {
        window.pendo.updateOptions(getVisitorAccountObj({ clientId: metadata.pendoAccountId }));
        window.pendo.track(eventName, modifiedData);
        window.pendo.updateOptions(getVisitorAccountObj());
      } else {
        window.pendo.track(eventName, modifiedData);
      }
    }
  }
};
