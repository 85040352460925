export const FILTER_COMPONENT_TYPES = {
  CHECKBOX: "CHECKBOX",
  RADIO_BUTTON: "RADIO_BUTTON",
  RANGE_FILTER: "RANGE_FILTER",
  CONTAINS_TEXT: "CONTAINS_TEXT",
  DATE_RANGE: "DATE_RANGE",
  DATE_SELECT: "DATE_SELECT",
  UTC_DATE_SELECT: "UTC_DATE_SELECT",
};

export const FILTER_TAB_COLORS = [
  "#65557c",
  "#4b5087",
  "#305e93",
  "#007ea2",
  "#77ad85",
  "#2a7f7c",
  "#2d6f88",
];
