import React, { useEffect, useState } from "react";
import classnames from "classnames";

import PropTypes from "prop-types";

import WithTooltip from "@onlinesales-ai/tooltip-v2";


import "./index.less";

const ToggleButton = ({
  isOn = false,
  onToggleCallback = () => {},
  isEnabled = true,
  tooltip,
  className,
  toggleType,
  placement,
  children,
  childrenClassName,
}) => {
  const [isOnState, setIsOnState] = useState(isOn);

  useEffect(() => {
    if (isOn !== isOnState) {
      setIsOnState(isOn);
    }
  }, [isOn]);

  const onToggleCheckBox = (event) => {
    if (isEnabled) {
      if (onToggleCallback && "function" === typeof onToggleCallback) {
        onToggleCallback(event, event.target.checked);
      }
    }
  };

  return (
    <WithTooltip title={tooltip} placement={placement}>
      <label className={`os-toggle-switch-container ${className} ${toggleType} ${!isEnabled ? "disabled" : ""}`}>
        <input type="checkbox" checked={isOn} onChange={onToggleCheckBox} />
        <span className="os-toggle-switch-round" />
        {children ? <div className={classnames("inner-wrapper", childrenClassName)}>{children}</div> : null}
      </label>
    </WithTooltip>
  );
};

ToggleButton.propTypes = {
  isOn: PropTypes.bool,
  onToggleCallback: PropTypes.func,
  isEnabled: PropTypes.bool,
};

ToggleButton.defaultProps = {
  isOn: false,
  onToggleCallback: () => {},
  isEnabled: true,
};

export default ToggleButton;
