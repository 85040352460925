import React from "react";

import { loaderWhite } from "@onlinesales-ai/loader-v2";

import ErrorMessage from "./ErrorMessage";

import "./index.less";

const FetchBanner = ({
  Loader,
  isLoading,
  errorTitle,
  errorMsg,
  errorProps,
  children,
  loaderClass,
}) => {
  return (
    <>
      {isLoading ? (
        Loader ? Loader : loaderWhite({ className: loaderClass })
      ) : (
        <>
          {errorMsg ? (
            <ErrorMessage
              title={errorTitle}
              description={errorMsg}
              {...errorProps}
            />
          ) : (
            children
          )}
        </>
      )}
    </>
  );
};

FetchBanner.defaultProps = {
  isLoading: false,
  errorProps: null,
  Loader: null
};

export default FetchBanner;
