import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import NotificationBar from "@onlinesales-ai/notification-bar-v2";

import { dimissUIGeneratedNotification } from "../uiNotification";

const NotificationBarWrapper = ({ ...props }) => {
  return <NotificationBar {...props} />;
};

const mapStateToProps = (state) => {
  const { notificationBarConfig = {} } = state.DomainConfig || {};
  const { staticNotifications, ...restProps } = notificationBarConfig;

  return {
    staticNotifications,
    uiNotifications: state.UINotification?.data?.NOTIFICATION_BAR,
    isShowStickyHeader: state.Application.isShowStickyHeader,
    clientId: state.Application.clientId,
    ...restProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      dismissUINotification: dimissUIGeneratedNotification,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationBarWrapper);
