import React, { useContext } from "react";

import { GlobalContext } from "@onlinesales-ai/utils-components-v2";
import { Button } from "@onlinesales-ai/button-v2";
import { populateEvent } from "@onlinesales-ai/util-methods-v2";

import "./index.less";

const fireIntercomEvents = (action, metaData) => {
  populateEvent(
    `TOPUP||${action}`,
    metaData,
  );
};

const LinkToShow = ({ label, redirectTo }) => {
  const { redirectUrl } = useContext(GlobalContext);

  const redirect = () => {
    fireIntercomEvents("CLICK_TRANSACTION_LOG");
    redirectUrl(redirectTo);
  };

  return (
    <Button className="topup-log-link-button" link onClick={redirect}>
      {label}
    </Button>
  );
};

LinkToShow.defaultProps = {
  label: "Transactions Log",
  redirectTo: "__APPEND__/drawer/TRANSACTIONS",
};

export default LinkToShow;
