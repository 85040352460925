import React from "react";
import { Modal } from "react-bootstrap";
import { Trans } from "react-i18next";

import "./index.less";

const ModalWindow = ({
  isShow,
  containerClass,
  onModalDestroy,
  onExited,
  headerTitle,
  modalProps,
  container,
  children,
  backdrop,
  closeButton,
  customCloseButton,
  onClickClose,
  mainClassName,
  backdropClassName,
  keyboard,
  size,
}) => {
  return (
    <Modal
      className={mainClassName}
      backdrop={backdrop}
      keyboard={keyboard}
      container={container}
      show={isShow}
      dialogClassName={`modal-dialog-centered bootstrap-modal ${containerClass} ${size}`}
      onHide={onModalDestroy}
      onExited={onExited}
      backdropClassName={backdropClassName}
      {...modalProps}
    >
      {headerTitle && (
        <Modal.Header closeButton={closeButton}>
          <Trans>{headerTitle}</Trans>
        </Modal.Header>
      )}
      {customCloseButton && <span className="icon icon-close1" onClick={onClickClose} />}
      {children}
    </Modal>
  );
};

ModalWindow.Body = Modal.Body;
ModalWindow.Footer = Modal.Footer;
ModalWindow.Title = Modal.Title;
ModalWindow.Header = Modal.Header;

ModalWindow.defaultProps = {
  containerClass: "",
  backdrop: true,
  keyboard: true,
  closeButton: true,
  onModalDestroy: () => {},
  mainClassName: "",
};

export default ModalWindow;
