import { AppStore } from "@onlinesales-ai/util-methods-v2";

import { entityTypeEnum } from "../constants";

export const getEntityInfo = (
  allEntityInfo,
  entityId,
) => {
  let retnData = {};

  if (entityId) {
    retnData = allEntityInfo?.mccs?.[entityId] || allEntityInfo?.clients?.[entityId] || {};
  }

  return retnData;
};

export const getEntityMetadata = (
  allEntityMetadata,
  entityId,
) => {
  let retnData = {};

  if (entityId) {
    retnData = allEntityMetadata?.mccs?.[entityId] || allEntityMetadata?.clients?.[entityId] || {};
  }

  return retnData;
};

export const parseProcessedEntityId = (processedEntityId) => {
  const retnData = {
    entityId: null,
    entityType: null,
  };

  try {
    const [entityType, entityId] = processedEntityId.split("_");
    retnData.entityType = entityType;
    retnData.entityId = entityId;
  } catch (e) {}

  return retnData;
};

export const generateProcessedEntityId = (entityType, entityId) => {
  return `${entityType}_${entityId}`;
};

export const getBreadCrumbText = ({ entityId }) => {
  let currentId = entityId;
  const storeState = AppStore.getState();
  const { entityInfo = {} } = storeState.EntityApplication;
  let breadCrumbItems = [];

  while (true) {
    const currentItem = getEntityInfo(entityInfo, currentId);
    if (currentItem.entityId) {
      breadCrumbItems = [
        {
          entityId: currentItem.entityId,
          entityType: currentItem.entityType,
          entityName: currentItem.entityName,
        },
        ...breadCrumbItems,
      ];

      if (!currentItem.parentMCCId) {
        break;
      }

      currentId = `${entityTypeEnum.MCC}_${currentItem.parentMCCId}`;
    } else {
      break;
    }
  }

  return breadCrumbItems.map((i) => i.entityName).join(" > ");
};

export const processEntityMetadataForAPI = (metadata) => {
  let metadataProcessed = {
    ...metadata,
  };
  let retnData = {};

  if (metadataProcessed?.onboardingData && typeof metadataProcessed?.onboardingData === "object") {
    metadataProcessed = {
      ...metadataProcessed,
      onboardingData: JSON.stringify(metadataProcessed?.onboardingData),
    };
  }

  if (metadataProcessed?.businessLegalInfo && typeof metadataProcessed?.businessLegalInfo === "object") {
    metadataProcessed = {
      ...metadataProcessed,
      businessLegalInfo: JSON.stringify(metadataProcessed?.businessLegalInfo),
    };
  }

  Object.keys(metadataProcessed).forEach((key) => {
    switch (key) {
      case "isBillingEntity": {
        retnData.customLabel1 = metadataProcessed[key];
      }
        break;
      case "onboardingData": {
        retnData.customLabel2 = metadataProcessed[key];
      }
        break;
      case "industryId":
      case "logoUrl":
      case "description": {
        retnData.customLabel3 = {
          ...(retnData?.customLabel3 || {}),
          [key]: metadataProcessed[key],
        };
      }
        break;
      case "billingMetadata": {
        retnData.customLabel4 = metadataProcessed[key];
      }
        break;
      case "industryName": {
        retnData.customLabel5 = metadataProcessed[key];
      }
        break;
      default: {
        retnData[key] = metadataProcessed[key];
      }
    }
  });

  if (retnData?.customLabel3 && typeof retnData?.customLabel3 === "object") {
    retnData = {
      ...retnData,
      customLabel3: JSON.stringify(retnData?.customLabel3),
    };
  }

  if (retnData?.customLabel4 && typeof retnData?.customLabel4 === "object") {
    retnData = {
      ...retnData,
      customLabel4: JSON.stringify(retnData?.customLabel4),
    };
  }

  return retnData;
};

export const processEntityMetadataFromAPI = (metadata) => {
  return {
    onboardingData: metadata,
  };
  // const retnData = {};

  // ADD LOGIC CLIENT WISE WHEN REQUIRED

  // Object.keys(metadata).forEach((key) => {
  //   switch (key) {
  //     case "customLabel1": {
  //       retnData.isBillingEntity = metadata[key];
  //     }
  //       break;
  //     case "customLabel2": {
  //       retnData.onboardingData = metadata[key];
  //     }
  //       break;
  //     case "customLabel4": {
  //       retnData.billingMetadata = metadata[key];
  //     }
  //       break;
  //     case "customLabel5": {
  //       retnData.industryName = metadata[key];
  //     }
  //       break;
  //     case "customLabel3": {} // processing this below so no need to pass
  //       break;
  //     default: {
  //       retnData[key] = metadata[key];
  //     }
  //   }
  // });

  // if (retnData?.onboardingData && typeof retnData?.onboardingData === "string") {
  //   try {
  //     retnData = {
  //       ...retnData,
  //       onboardingData: JSON.parse(retnData?.onboardingData),
  //     };
  //   } catch (e) {}
  // }

  // if (retnData?.billingMetadata && typeof retnData?.billingMetadata === "string") {
  //   try {
  //     retnData = {
  //       ...retnData,
  //       billingMetadata: JSON.parse(retnData?.billingMetadata),
  //     };
  //   } catch (e) {}
  // }

  // if (retnData?.businessLegalInfo && typeof retnData?.businessLegalInfo === "string") {
  //   try {
  //     retnData = {
  //       ...retnData,
  //       businessLegalInfo: JSON.parse(retnData?.businessLegalInfo),
  //     };
  //   } catch (e) {}
  // }

  // if (metadata?.customLabel3 && typeof metadata?.customLabel3 === "string") {
  //   try {
  //     retnData = {
  //       ...retnData,
  //       ...JSON.parse(metadata?.customLabel3),
  //     };
  //   } catch (e) {}
  // }

  // return retnData;
};
