import pendingOpportunityReducer from "@onlinesales-ai/app-v2/pendingOpportunity/reducer";

const initialState = {
  suggestionIds: [],
  pendingOpportunitiesCount: {},
  pendingOpportunitiesCountFetchInProgress: false,
  isHygieneFetchInProgress: false,
  isHygieneRefreshFetchInProgress: false,
  hygieneFetchErrorMsg: null,
  hygiene: {}
};

const reducer = (state = initialState, action) => {
  return pendingOpportunityReducer(state, action, initialState);
};

export default {
  PendingOpportunity: reducer,
};
