import React, { useState, useRef } from "react";
import { Overlay, Popover } from "react-bootstrap";
import classnames from "classnames";

import { useControlState, useOnClickOutside } from "@onlinesales-ai/util-methods-v2";

import "./index.less";

const PopoverTooltip = ({
  isShow,
  setIsShow,
  popoverContent = "",
  popoverClass = "",
  containerClass = "",
  labelClass = "",
  underline = true,
  tagName: TagName = "div",
  children = null,
  placement = "top",
  popoverContainer,
  enableBoxShadow,
  size,
  stickyPopover,
  clickOutside,
  isContentRefUsed,
  popoverConfig = {},
}) => {
  const [timeStamp] = useState(new Date().getTime());
  const [show, setShow] = useControlState(isShow, setIsShow, false);
  const ref = useRef(null);
  const targetRef = useRef(null);

  const handleTriggerMouseOver = () => {
    if (!stickyPopover) {
      setShow(true);
    }
  };

  const handleTriggerMouseOut = () => {
    if (!stickyPopover) {
      setShow(false);
    }
  };

  const handlePopoverMouseOver = () => {
    if (!stickyPopover) {
      setShow(true);
    }
  };

  const handlePopoverMouseOut = () => {
    if (!stickyPopover) {
      setShow(false);
    }
  };

  const handleTriggerClick = () => {
    if (stickyPopover) {
      setShow(!show);
    }
  };

  const popoverContentRef = useOnClickOutside(() => {
    if (clickOutside) {
      setShow(false);
    } else {
      setShow(true);
    }
  });

  return (
    <div
      ref={ref}
      className={classnames("os-popover-wrapper", containerClass, {
        "add-underline": underline,
      })}
    >
      <TagName
        ref={targetRef}
        className={`os-tooltip-label ${labelClass}`}
        onMouseOver={handleTriggerMouseOver}
        onClick={handleTriggerClick}
        onMouseOut={handleTriggerMouseOut}
      >
        {children}
      </TagName>

      {popoverContent && (
        <Overlay
          show={show}
          target={targetRef.current}
          placement={placement}
          container={popoverContainer || ref.current}
          containerPadding={20}
          transition
          popperConfig={popoverConfig}
        >
          <Popover
            className={classnames("os-tooltip-popover", popoverClass, size, {
              "box-shadow-only": enableBoxShadow,
              "sticky-popover": stickyPopover,
            })}
            id={`popover-${timeStamp}`}
            delay={{ show: 250, hide: 400 }}
            onMouseOver={handlePopoverMouseOver}
            onMouseOut={handlePopoverMouseOut}
          >
            {isContentRefUsed ? (
              <div ref={popoverContentRef}>{popoverContent}</div>
            ) : (
              popoverContent
            )}
          </Popover>
        </Overlay>
      )}
    </div>
  );
};

PopoverTooltip.defaultProps = {
  stickyPopover: false,
};

export default PopoverTooltip;
