import _sortBy from "lodash/sortBy";

import {
  vendorChannelMapping,
  alAccountStateEnum as accountStateEnum,
  alVendorEnum as vendorEnum,
} from "@onlinesales-ai/constants-v2";

export const getvendorAccountData = (paramDetails, canSort = true) => {
  const data = paramDetails.reduce((memo, { values }) => {
    values.forEach((cisData) => {
      if (!memo[cisData["@class"]]) {
        memo[cisData["@class"]] = [];
      }
      memo[cisData["@class"]].push(cisData);
    });
    return memo;
  }, {});

  if (canSort) {
    Object.keys(data).forEach((key) => {
      data[key] = _sortBy(data[key], "isActive").reverse();
    });
  }

  return data;
};

const isSubAccountLinked = (channelsDisplayed, vendorAccountKey, isActive) => {
  const isDisplayed = channelsDisplayed.hasOwnProperty(vendorAccountKey);

  return !isDisplayed || (isDisplayed && isActive);
};

const getAccountLinkingStatus = (
  vendorKey,
  selectedVendorConfig,
  vendorAccountData,
  channelsDisplayed = {},
) => {
  let accountLinkingStatus;
  const linkedAccountsByVendorAccountKey = {};

  Object.keys(selectedVendorConfig?.accountConfig || {}).forEach((vendorAccountKey) => {
    const vendorConfigDataForSelectedKey =
      selectedVendorConfig.accountConfig[vendorAccountKey] || {};
    const accountKey = vendorAccountData[vendorConfigDataForSelectedKey.class];
    let isActive = false;
    if (accountKey) {
      accountKey.forEach((accountInfo) => {
        if (!isActive && accountInfo.isActive) {
          isActive = true;
        }
      });
    }
    linkedAccountsByVendorAccountKey[vendorAccountKey] = isSubAccountLinked(
      channelsDisplayed,
      vendorAccountKey,
      isActive,
    );
  });

  switch (vendorKey) {
    case vendorEnum.GOOGLE:
      if (!linkedAccountsByVendorAccountKey.googleAdwordAccount) {
        accountLinkingStatus = "Please link your ad account to advertise on Google channel";
      } else if (
        linkedAccountsByVendorAccountKey.googleAdwordAccount &&
        !linkedAccountsByVendorAccountKey.googleMerchantCenter
      ) {
        accountLinkingStatus = "Please link your GMC account to advertise on Google Shopping";
      } else if (!linkedAccountsByVendorAccountKey.googleMyBysiness) {
        accountLinkingStatus = "Please link your GMB location to enable post creation";
      } else if (
        linkedAccountsByVendorAccountKey.googleAdwordAccount &&
        linkedAccountsByVendorAccountKey.googleMerchantCenter
      ) {
        accountLinkingStatus = "Click here to update your linked ad accounts";
      }
      break;
    case vendorEnum.FACEBOOK:
      if (!linkedAccountsByVendorAccountKey.facebook) {
        accountLinkingStatus = "Please link your ad account to advertise on Facebook channel";
      } else if (
        linkedAccountsByVendorAccountKey.facebook &&
        !linkedAccountsByVendorAccountKey.facebookPage
      ) {
        accountLinkingStatus = "Please link your Facebook page to advertise on Facebook channel";
      } else if (
        linkedAccountsByVendorAccountKey.facebook &&
        linkedAccountsByVendorAccountKey.facebookPage &&
        !linkedAccountsByVendorAccountKey.instagram
      ) {
        accountLinkingStatus = "Please link your Instagram account to advertise on Instagram";
      } else if (
        linkedAccountsByVendorAccountKey.facebook &&
        linkedAccountsByVendorAccountKey.facebookPage &&
        linkedAccountsByVendorAccountKey.instagram
      ) {
        accountLinkingStatus = "Click here to update your linked ad accounts";
      }
      break;

    default:
      accountLinkingStatus = "";
  }
  return accountLinkingStatus;
};

const getVendorLevelFlags = ({ vendorAccountData, vendorKey, vendorToShow, vendorConfig }) => {
  const subChannelsToShow = vendorToShow[vendorKey];
  const { accountConfig, listConfig } = vendorConfig[vendorKey];

  const mustHaveAccounts = listConfig?.mustHaveAccounts || [];
  const mustHaveAccountsDisplayed = mustHaveAccounts.filter((m) => !!subChannelsToShow[m]);

  let areMustHaveAccountsLinked = true;
  const mustHaveAccountsThatAreNotLinked = [];
  mustHaveAccountsDisplayed.forEach((accountKey) => {
    const accounts = vendorAccountData[accountConfig[accountKey]?.class] || [];
    if (!accounts.some((a) => a.isActive)) {
      areMustHaveAccountsLinked = false;
      mustHaveAccountsThatAreNotLinked.push(accountKey);
    }
  });

  return {
    areMustHaveAccountsLinked,
    mustHaveAccountsThatAreNotLinked,
  };
};

const checkfacebookPageAccessIssue = (hygiene = {}) => {
  let hasAccessIssue = false;
  if ((hygiene.CHECK_FACEBOOK_PAGE_ID && !hygiene.CHECK_FACEBOOK_PAGE_ID?.isSuccessful) ||
      (hygiene.CHECK_FACEBOOK_PAGE_ID_BY_PAGE_TOKEN && !hygiene.CHECK_FACEBOOK_PAGE_ID_BY_PAGE_TOKEN?.isSuccessful)) {
    try {
      const metadata = JSON.parse(hygiene?.CHECK_FACEBOOK_PAGE_ID?.metadata || hygiene?.CHECK_FACEBOOK_PAGE_ID_BY_PAGE_TOKEN?.metadata || "{}");
      if (metadata?.messageKeys?.accessOnPage === false) {
        hasAccessIssue = true;
      }
    } catch (err) {
      console.error(err);
    }
  }
  return hasAccessIssue;
};

const getListData = ({
  vendorAccountData,
  vendorConfig,
  vendorToShow,
  onBoardingData,
  hygiene,
}) => {
  const listData = {};

  Object.keys(vendorToShow).forEach((vendorKey) => {
    const channelsDisplayed = vendorToShow[vendorKey];
    const {
      listConfig, validations = [], createPageValidations = [], doNotShowLinkOption = false 
    } = vendorConfig[vendorKey] || {};

    if (listConfig) {
      const selectedVendorConfig = vendorConfig[vendorKey];
      const { areMustHaveAccountsLinked, mustHaveAccountsThatAreNotLinked } =
        getVendorLevelFlags({
          vendorAccountData,
          vendorKey,
          vendorToShow,
          vendorConfig,
        }) || {};
      let atLeastOneAccountExist = false;
      let masterAccountStatus = null;
      let isGMBLocationIsNotVerified = false;
      let facebookPageAccessIssue = false;

      Object.keys(channelsDisplayed).forEach((configKey) => {
        const selectedClass = selectedVendorConfig.accountConfig[configKey]?.class;
        const accountsForSelectedClass = vendorAccountData[selectedClass];

        if (Array.isArray(accountsForSelectedClass)) {
          (isGMBLocationIsNotVerified =
            configKey === "googleMyBysiness" && isGMBLocationUnVerified(accountsForSelectedClass)),
            (facebookPageAccessIssue =
              configKey === "facebookPage" && checkfacebookPageAccessIssue(hygiene)),
            accountsForSelectedClass.forEach((accountInfo) => {
              const isActive = !!accountInfo.isActive;
              if (isActive) {
                masterAccountStatus = accountInfo.masterAccountStatus;
              }
              atLeastOneAccountExist = true;
            });
        }
      });

      let stateOfAccount = accountStateEnum.NOT_LINKED;
      let isLinked = false;

      if (atLeastOneAccountExist && areMustHaveAccountsLinked) {
        stateOfAccount = accountStateEnum.LINKED;
        isLinked = true;
      } else if (atLeastOneAccountExist) {
        stateOfAccount = accountStateEnum.PARTIALLY_LINKED;
      }

      let isPageCreated = false;
      const { onboardingFlagsToCheck = [], enablePageCreation } = listConfig || {};

      if (onboardingFlagsToCheck?.length > 0 && enablePageCreation) {
        isPageCreated = onboardingFlagsToCheck.every((flag) => {
          return !!onBoardingData?.metadata?.[flag];
        });
      }

      if (isPageCreated && isGMBLocationIsNotVerified) {
        stateOfAccount = accountStateEnum.VERIFICATION_PENDING;
      }

      if (facebookPageAccessIssue) {
        stateOfAccount = accountStateEnum.PERMISSION_MISSING;
      }

      if (stateOfAccount === accountStateEnum.LINKED && vendorEnum[vendorKey] === vendorEnum.INSTAGRAM) {
        if (masterAccountStatus === "ADDED") {
          stateOfAccount = accountStateEnum.LINKED;
        } else if (masterAccountStatus === "NA") {
          stateOfAccount = accountStateEnum.LINKING_UNDER_PROGRESS;
        } else if (masterAccountStatus === "REJECTED") {
          stateOfAccount = accountStateEnum.REJECTED;
        }
      }

      const linkTooltip = !areMustHaveAccountsLinked ? listConfig?.tooltip?.linkTooltip : null;

      const accountLinkingStatus = getAccountLinkingStatus(
        vendorKey,
        selectedVendorConfig,
        vendorAccountData,
        channelsDisplayed,
      );

      listData[vendorKey] = {
        isGMBLocationIsNotVerified,
        facebookPageAccessIssue,
        vendorKey,
        listConfig,
        accountLinkingStatus,
        linkTooltip,
        stateOfAccount,
        isLinked,
        atLeastOneAccountExist,
        areMustHaveAccountsLinked,
        mustHaveAccountsThatAreNotLinked,
        isPageCreated,
        doNotShowLinkOption,
        validations,
        createPageValidations,
        masterAccountStatus
      };
    }
  });

  return listData;
};

export const getAccountListData = ({
  vendorAccountData,
  vendorConfig,
  vendorToShow,
  onBoardingData,
  hygiene,
}) => {
  const listData = getListData({
    vendorAccountData,
    vendorConfig,
    vendorToShow,
    onBoardingData,
    hygiene,
  });

  return {
    listData,
  };
};

const getHostnameFromUrl = (href) => {
  const l = document.createElement("a");
  l.href = href;
  let hostName = l.hostname;

  if (hostName && hostName.startsWith("www.")) {
    hostName = hostName.split("www.")[1];
  }

  return hostName;
};

export const isGMCWebsiteValid = (shopInfo, accountInfo) => {
  return getHostnameFromUrl(shopInfo.url) === getHostnameFromUrl(accountInfo.websiteUrl);
};

export const isGMBLocationUnVerified = (accounts) => {
  //While GMB page creation by OS "locationStatus" is not being populated and hence considering it as UNVERIFIED
  return (
    !!accounts &&
    accounts.some((acc) => {
      return !!acc.isActive && (!acc?.locationStatus || acc?.locationStatus === "UNVERIFIED");
    })
  );
};


export const isAtLeastOneAccountExistAndActive = (accounts) => {
  return !!accounts && accounts.some((acc) => !!acc.isActive);
};

export const isRMNAccountExistAndActiv = (channel, accounts) => {
  return (
    !!accounts && accounts.some((acc) => !!acc.isActive && acc?.rmnAccountProps?.name === channel)
  );
};

export const isChannelLinked = (channelName, vendorAccountsData = {}) => {
  let isLinked = false;

  const adwordsAccountsClass = "com.sokrati.clientInputSvcObjects.parameter.GoogleAdwordsAccount";
  const gmcAccountsClass = "com.sokrati.clientInputSvcObjects.parameter.GoogleGmcAccount";
  const fbAccountsClass = "com.sokrati.clientInputSvcObjects.parameter.FacebookAccount";
  const fbPageAccountsClass = "com.sokrati.clientInputSvcObjects.parameter.FacebookPage";
  const rmnAccountsClass = "com.sokrati.clientInputSvcObjects.parameter.RMNAccount";

  switch (channelName) {
    case vendorChannelMapping.GOOGLE_PLA.key:
      {
        const adwordsAccounts = vendorAccountsData[adwordsAccountsClass];
        const gmcAccounts = vendorAccountsData[gmcAccountsClass];
        isLinked =
          isAtLeastOneAccountExistAndActive(adwordsAccounts) &&
          isAtLeastOneAccountExistAndActive(gmcAccounts);
      }
      break;
    case vendorChannelMapping.GOOGLE_SEARCH.key:
    case vendorChannelMapping.GOOGLE_DISPLAY.key:
      {
        const adwordsAccounts = vendorAccountsData[adwordsAccountsClass];
        isLinked = isAtLeastOneAccountExistAndActive(adwordsAccounts);
      }
      break;
    case vendorChannelMapping.FACEBOOK.key:
    case vendorChannelMapping.FACEBOOK_DPA.key:
      {
        const fbAccounts = vendorAccountsData[fbAccountsClass];
        const fbPageAccounts = vendorAccountsData[fbPageAccountsClass];
        isLinked =
          isAtLeastOneAccountExistAndActive(fbAccounts) &&
          isAtLeastOneAccountExistAndActive(fbPageAccounts);
      }
      break;
    default:
      break;
  }

  if (channelName?.startsWith?.("RMN_") && vendorChannelMapping[channelName]) {
    const rmnAccounts = vendorAccountsData[rmnAccountsClass];
    isLinked = isRMNAccountExistAndActiv(vendorChannelMapping[channelName].channel, rmnAccounts);
  }

  return isLinked;
};