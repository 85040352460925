import React, { useEffect, useState } from "react";

import Radio from "./Radio";

const RadioGroup = ({
  defaultValue,
  groupValue,
  name,
  className,
  data,
  layout,
  onChange,
  allowUndefined,
  disabled: groupDisabled,
  type,
}) => {
  const [timeStamp] = useState(new Date().getTime());
  const [selectedValue, setSelectedValue] = useState(
    typeof groupValue !== "undefined" || allowUndefined ? groupValue : defaultValue,
  );

  useEffect(() => {
    if (typeof groupValue !== "undefined" || allowUndefined) {
      setSelectedValue(groupValue);
    } else {
      setSelectedValue(defaultValue);
    }
  }, [groupValue, defaultValue]);

  const onChangeRadio = ({ value, event, option }) => {
    if (typeof groupValue === "undefined") {
      setSelectedValue(value);
    }
    onChange(value, event, option);
  };

  return (
    <div className={`radio-group ${className || ""} ${layout} ${type}`}>
      {data.map((op) => {
        const { id, value, disabled, hideOption, ...rest } = op;

        if (hideOption) {
          return null;
        }

        return (
          <Radio
            {...rest}
            key={value}
            disabled={groupDisabled || disabled}
            name={name}
            id={id || `${value}-${timeStamp}`}
            value={value}
            checked={selectedValue === value}
            onChange={(val, e) => onChangeRadio({ value, option: op, event: e })}
          />
        );
      })}
    </div>
  );
};

RadioGroup.defaultProps = {
  layout: "vertical",
  disabled: false,
  allowUndefined: false,
  type: "default", // tabs
};

export default RadioGroup;
