import React from "react";
import WindowedSelect from "react-windowed-select";

import BaseDropdown from "./BaseDropdown";

const DropdownVirtualized = (props) => {
  return <BaseDropdown {...props} SelectComponent={WindowedSelect} />;
};

export default DropdownVirtualized;
