import React, { useState } from "react";

import ShortcutContext from "./shortcutContext";

const ShortcutProvider = ({ children }) => {
  const [shortcutDetails, setShortcutDetails] = useState({});

  return (
    <ShortcutContext.Provider value={{ shortcutDetails, setShortcutDetails }}>
      {children}
    </ShortcutContext.Provider>
  );
};

export default ShortcutProvider;
