import React from "react";
import { Trans } from "react-i18next";
import classnames from "classnames";

import { Input } from "@onlinesales-ai/input-v2";

const TextInputFilterComponent = ({ value, onChange, tabKey, componentMetaData, inputWrapperClassName }) => {
  return (
    <div className="form-group">
      {componentMetaData.items &&
        componentMetaData.items.map((itemData) => {
          const { text, key: itemKey, type, guideText, placeholder } = itemData;
          const inputValue = value[itemKey] || "";
          return (
            <div className="contains-input-wrapper" key={itemKey}>
              {text && (
                <div>
                  <label>
                    <Trans>{text}</Trans>
                  </label>
                </div>
              )}
              <Input
                type={type || "text"}
                value={inputValue || ""}
                onChange={(...params) => onChange(tabKey, itemKey, ...params)}
                isSearchInput
                placeholder={placeholder}
                inputWrapperClassName={classnames("border-padding", inputWrapperClassName)}
              />
              {guideText && (
                <div className="guide-text">
                  <Trans>{guideText}</Trans>
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
};

TextInputFilterComponent.defaultProps = {
  onChange: () => {},
  componentMetaData: {},
};

export default TextInputFilterComponent;
