import { ReportingUIService } from "@onlinesales-ai/services-v2";
import { getCurrencyCode } from "@onlinesales-ai/util-methods-v2";

import Types from "./types";

export const setClientAchievements = (data) => ({
  type: Types.SET_CLIENT_ACHIVEMENT_DATA,
  data,
});

export const updateClientAchievements = (data) => ({
  type: Types.UPDATE_CLIENT_ACHIVEMENT_DATA,
  data,
});

export const updateClientAchievementsData = (data) => ({
  type: Types.UPDATE_HYGIENE_CLIENT_ACHIVEMENT_DATA,
  data,
});

export const setClientAchievementsFetchState = (isLoading, fetchError) => ({
  type: Types.SET_CLIENT_ACHIVEMENT_FETCH_STATE,
  isLoading,
  fetchError,
});

export const fetchClientAchievements = (config, isShowLoading = true) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { clientId } = state.Application || {};

    const payload = {
      clientId,
      currency: getCurrencyCode(),
      ...config,
    };

    if (isShowLoading) {
      dispatch(setClientAchievementsFetchState(true, false));
    }

    try {
      const response = await ReportingUIService.fetchClientAchievements(payload, "dashboard");
      if (response.summary) {
        let clientAchievements = response.summary;
        if (
          clientAchievements.reporting &&
          clientAchievements.reporting.sokrati &&
          clientAchievements.reporting.sokrati.length &&
          clientAchievements.reporting.sokrati[0]
        ) {
          clientAchievements = {
            ...clientAchievements,
            ...clientAchievements.reporting.sokrati[0],
          };
        }

        dispatch(setClientAchievements({ ...clientAchievements, clientId }));
        dispatch(setClientAchievementsFetchState(false, false));

        return clientAchievements;
      }

      dispatch(
        setClientAchievementsFetchState(false, "Unknown Error Occurred. Please try again later."),
      );

      return Promise.reject({
        errorMsg: "Unknown Error Occurred. Please try again later.",
      });
    } catch (err) {
      dispatch(setClientAchievementsFetchState(false, err.errorMsg));

      return Promise.reject(err);
    }
  };
};
