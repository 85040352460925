export default {
  APP_CLIENT_CHANGE: "APP_CLIENT_CHANGE",
  APP_SET_PENDING_OPPORTUNITIES_COUNT_FETCH_IN_PROGRESS: "APP_SET_PENDING_OPPORTUNITIES_COUNT_FETCH_IN_PROGRESS",
  APP_SET_PENDING_OPPORTUNITIES_COUNT: "APP_SET_PENDING_OPPORTUNITIES_COUNT",
  APP_SET_PENDING_OPPORTUNITIES_SUGGESTIONS: "APP_SET_PENDING_OPPORTUNITIES_SUGGESTIONS",
  APP_SET_PENDING_OPPORTUNITIES_HYGIENE: "APP_SET_PENDING_OPPORTUNITIES_HYGIENE",
  HYGIENE_SET_HYGIENE_FETCH_IN_PROGRESS: "HYGIENE_SET_HYGIENE_FETCH_IN_PROGRESS",
  HYGIENE_SET_HYGIENE_REFRESH_FETCH_IN_PROGRESS: "HYGIENE_SET_HYGIENE_REFRESH_FETCH_IN_PROGRESS",
  HYGIENE_SET_HYGIENE_FETCH_ERROR_MSG: "HYGIENE_SET_HYGIENE_FETCH_ERROR_MSG",
  SUGGESTION_ACTED: "SUGGESTION_ACTED",
};
