import React from "react";

import { FormWrapper } from "@onlinesales-ai/form-components-v2";

import SpendingLimitBreakUp from "../../spendingLimitBreakUp";

import "./index.less";

const SpendingLimitInfo = ({
  title,
  labelColumns,
  titleTooltip,
  titleGuidText,
  formGroupClassName,
  setMaxSpendingLimit,
  isShowSpendingLimitBreakUp,
  spendingLimitBreakdownConfig,
}) => {
  const onChangeSpendingBreakUp = (val) => {
    setMaxSpendingLimit(val);
  };

  return (
    <FormWrapper
      title={title}
      formGroupClassName={formGroupClassName}
      titleTooltip={titleTooltip}
      titleGuidText={titleGuidText}
      labelColumns={labelColumns}
    >
      <SpendingLimitBreakUp
        isShowSpendingLimitBreakUp={isShowSpendingLimitBreakUp}
        spendingLimitBreakdownConfig={spendingLimitBreakdownConfig}
        onChangeSpendingBreakUp={onChangeSpendingBreakUp}
      />
    </FormWrapper>
  );
};

SpendingLimitInfo.defaultProps = {
  title: "Total Available Credit:",
};

export default SpendingLimitInfo;
