import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Header } from "@onlinesales-ai/header-v2";
import { getDefaultRoute, shouldRenderComponent } from "@onlinesales-ai/util-methods-v2";
import { EntityDropdown } from "@onlinesales-ai/client-v2";

import { changeClientIdAction as changeClientIdFunction } from "src/store/application/actions";
import { redirectUrl } from "src/store/application";

import { routes } from "src/utilities/constants";

import WalletBalanceHeaderWrapper from "./walletBalanceHeaderWrapper";
import "./index.less";

const customComponents = {
  WALLET_BALANCE: WalletBalanceHeaderWrapper,
};

const AppHeader = ({
  clientId,
  clients,
  isAllClientsFetched,
  onClientChange,
  isShowAllClientsOption,
  isAllClientsSelected,
  allClientDisplayName,
  isInternalUser,
  showClientList,
  isMobile,
  showNavigation,
  navLinks,
  redirect,
  location,
  shouldBeRendered,
  brandingLogo,
  mobileBrandingLogo,
  containerClass,
  customComponentToShow,
  customComponentConfig,
  isBillingNotSubscribed,
  isBillingStatusFetchInProgress,
  isOnboardingFetchInProgress,
  selectedEntityId,
  selectedEntityType,
  multiLevelDropdownProps,
  enableEntityHades,
}) => {
  if (!shouldBeRendered) {
    return null;
  }

  const navConfig = navLinks[location.pathname] || navLinks.common;

  const renderCustomComponents = () => {
    const commonProps = {
      isBillingStatusFetchInProgress,
      isBillingNotSubscribed,
      isOnboardingFetchInProgress,
    };
    return (
      <div className="custom-header-components pendo_feature_header_custom_components">
        {
          customComponentToShow?.map((key) => {
            const config = customComponentConfig?.[key] || {};
            const {
              type,
              props,
            } = config;

            const Component = customComponents?.[type];
            if (!Component) {
              return null;
            }
            return (
              shouldRenderComponent(config) &&
              <Component {...commonProps} {...props} />
            );
          })
        }
      </div>
    );
  };

  const renderCustomEntityDropdown = () => {
    if (
      !selectedEntityId ||
      !selectedEntityType
    ) {
      return null;
    }

    return <EntityDropdown {...multiLevelDropdownProps} isDarkMode />;
  };

  return (
    <Header
      homePageUrl={getDefaultRoute() || routes.DASHBOARD.path}
      clientId={clientId}
      clients={clients}
      onClientChange={onClientChange}
      isMobile={isMobile}
      isAllClientsFetched={isAllClientsFetched}
      isShowAllClientsOption={isShowAllClientsOption}
      isAllClientsSelected={isAllClientsSelected}
      allClientDisplayName={allClientDisplayName}
      isInternalUser={isInternalUser}
      showClientList={showClientList}
      showNavigation={showNavigation}
      navLinks={navConfig}
      redirectUrl={redirect}
      brandingLogo={brandingLogo}
      mobileBrandingLogo={mobileBrandingLogo}
      containerClass={containerClass}
      customComponents={
        !!customComponentToShow?.length && renderCustomComponents()
      }
      customEntityRender={enableEntityHades ? renderCustomEntityDropdown : null}
    />
  );
};

Header.defaultConfig = {
  showClientList: true,
  showNavigation: true,
  isShowAllClientsOption: false,
};

const mapStateToProps = (state) => {
  const {
    clientId,
    clients,
    isAllClientsFetched,
    isAllClientsSelected,
    isInternalUser,
    isMobile,
  } = state.Application || {};
  const { selectedEntityId, selectedEntityType } = state.EntityApplication || {};

  const { isShowAllClientsOption, header, enableEntityHades } = state.DomainConfig || {};

  return {
    location: state.router.location,
    clientId,
    clients,
    isMobile,
    isAllClientsFetched,
    isAllClientsSelected,
    isInternalUser,
    isShowAllClientsOption,
    navLinks: [],
    shouldBeRendered: true,
    isBillingNotSubscribed: state.Application.isBillingNotSubscribed,
    isBillingStatusFetchInProgress: state.Application.isBillingStatusFetchInProgress,
    isOnboardingFetchInProgress: state.OnBoarding.isLoading,
    selectedEntityId,
    selectedEntityType,
    enableEntityHades,
    ...header,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onClientChange: changeClientIdFunction,
      redirect: redirectUrl,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);
