import React from "react";

import RouteBasedDrawer from "../routeBasedDrawer/index";

const RouteBasedPendingAction = () => {
  return (
    <RouteBasedDrawer drawerRoutePrefix="/pending_action" containerClass="pending-action-drawer" />
  );
};

export default RouteBasedPendingAction;
