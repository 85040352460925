import React, { useState, useRef, useMemo, useCallback } from "react";
import { Overlay, Popover } from "react-bootstrap";
import { useSelector } from "react-redux";
import _isEmpty from "lodash/isEmpty";
import { Trans } from "react-i18next";
import classnames from "classnames";

import { customMergeOS, getCurrencyDOM, marketplaceNameReplacer } from "@onlinesales-ai/util-methods-v2";
import { HelpLink } from "@onlinesales-ai/helplink-v2";

import "./index.less";

const Label = ({ parentTooltip, labelDefinationKey, icon, fallBackLabel, suffix, customTransComponents, ...customOverrides }) => {
  const { labelDefination = {} } = useSelector((state) => state.DomainConfig || {});
  const [timeStamp] = useState(new Date().getTime());
  const [show, setShow] = useState(false);
  const ref = useRef(null);
  const targetRef = useRef(null);
  const {
    popoverConfig,
    overlayCondig,
    containerClass = "",
    className = "",
    underline,
    tagName: TagName = "div",
    wrapperTagName: WrapperTagName = "div",
    wrapperProps,
    tagProps,
    text,
    hideText,
    translationConfig,
    showInfoIcon: showInfoIconProp,
    hasDefination,
    tooltipIconClass,
  } = useMemo(() => {
    let finalResult = {};
    if (labelDefination && labelDefinationKey) {
      const defination = labelDefination[labelDefinationKey] || {};
      finalResult = customMergeOS({}, defination, customOverrides);
    } else {
      finalResult = customOverrides;
    }
    return {
      ...finalResult,
      hasDefination:
        !_isEmpty(finalResult) &&
        (finalResult?.text || (finalResult?.showInfoIcon && !_isEmpty(finalResult?.popoverConfig))),
    };
  }, [customOverrides, labelDefinationKey, labelDefination]);

  const showInfoIcon = useMemo(() => {
    return showInfoIconProp && popoverConfig?.popoverContent;
  }, [showInfoIconProp, popoverConfig]);

  const TranslationComponents = {
    currency: getCurrencyDOM(),
    bold: <b />,
    ...customTransComponents,
  };

  const {
    popoverContent = "",
    popoverClass = "",
    placement = "auto",
    helpLinkKey,
    enableBoxShadow,
    popoverContainer,
    popoverProps = {},
  } = useMemo(() => {
    return popoverConfig || {};
  }, [popoverConfig]);

  const getTransConfig = useCallback((textToRender) => {
    return {
      defaults: textToRender,
      values: {
        ...(translationConfig?.values || {}),
        marketplaceName: marketplaceNameReplacer("__MARKETPLACE_NAME__"),
      },
      components: {
        ...TranslationComponents,
        ...(translationConfig?.components || {}),
      },
    };
  }, [translationConfig, text]);

  return (
    <>
      {hasDefination && (
        <WrapperTagName
          {...(wrapperProps || {})}
          className={`os-label-wrapper ${containerClass} ${
            underline && !showInfoIcon ? "add-underline" : ""
          }`}
        >
          <TagName className={`os-tooltip-label ${className}`} {...(tagProps || {})}>
            {icon && <span className={`icon ${icon}`} />}
            {text && !hideText && <Trans {...getTransConfig(text)} />}
            {suffix}
            {showInfoIcon && (
              <span
                ref={targetRef}
                className={classnames("icon icon-info-circle1 label-info-icon", tooltipIconClass)}
                onMouseOver={(e) => {
                  if (parentTooltip) {
                    e.stopPropagation();
                  }
                  setShow(true);
                }}
                onMouseOut={() => setShow(false)}
              />
            )}
          </TagName>
          {!_isEmpty(popoverConfig) && (
            <Overlay
              show={show}
              target={targetRef.current}
              placement={placement}
              container={popoverContainer}
              containerPadding={20}
              transition
              {...overlayCondig}
            >
              <Popover
                className={`os-label-tooltip-popover ${popoverClass} ${helpLinkKey ? "with-help-link" : ""} ${enableBoxShadow ? "box-shadow-only" : ""}`}
                id={`popover-${timeStamp}`}
                delay={{ show: 250, hide: 400 }}
                onMouseOver={() => setShow(true)}
                onMouseOut={() => setShow(false)}
                onClick={(e) => e.stopPropagation()}
                {...popoverProps}
              >
                <div className="label-popover">
                  <span className="popover-content-inner">
                    {Array.isArray(popoverContent) ? (
                      <ul>
                        {popoverContent.map((item) => {
                          return (
                            <li>
                              <Trans {...getTransConfig(item)}>{item}</Trans>
                            </li>
                          );
                        })}
                      </ul>
                    ) : (
                      <>
                        {typeof popoverContent === "string" ? (
                          <Trans {...getTransConfig(popoverContent)}>{popoverContent}</Trans>
                        ) : (
                          popoverContent
                        )}
                      </>
                    )}
                  </span>
                  {helpLinkKey && (
                    <HelpLink helpLinkKey={helpLinkKey} setPopoverFalse={() => setShow(false)} />
                  )}
                </div>
              </Popover>
            </Overlay>
          )}
        </WrapperTagName>
      )}
      {!hasDefination && fallBackLabel}
    </>
  );
};

Label.defaultProps = {
  suffix: null,
  customTransComponents: {},
  enableBoxShadow: false,
};

export default Label;
