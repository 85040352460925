import React from "react";
import { Trans, useTranslation } from "react-i18next";

import { Button } from "@onlinesales-ai/button-v2";
import { getCurrencyDOM, formatValuesInThousands } from "@onlinesales-ai/util-methods-v2";

import "./index.less";

const CampaignActiveNote = ({
  imageUrl,
  title1,
  description1,
  title2,
  description2,
  cta,
  cta2,
  redirectTo,
  announcementMetaData,
  campaignRunningLabel,
}) => {
  const { t } = useTranslation();

  return (
    <div className={`feature-note-no-active-campaign ${cta?.containClassName || ""}`}>
      {imageUrl && (
        <div className="feature-left first-block">
          <img className="img-responsive" src={imageUrl} />
        </div>
      )}
      <div className="feature-right second-block-half">
        <div className="feature-right-header">
          {announcementMetaData.walletBalance && (
            <div className="purge-ignore-wallet-balance">
              <Trans>Wallet Balance:</Trans>
              <span className="value">{getCurrencyDOM()}<span className="amount">{formatValuesInThousands(Math.round(announcementMetaData.walletBalance))}</span></span>
            </div>
          )}
          {announcementMetaData.campaignActives && announcementMetaData.walletBalance && (
            <div className="separator" />
          )}
          {announcementMetaData.campaignActives && (
            <div className="active-campaigns">
              <Trans>{announcementMetaData.campaignRunningLabel || campaignRunningLabel}</Trans>
              <span className="value">{announcementMetaData.campaignActives}</span>
            </div>
          )}
        </div>
        <div className="feedBoxMeta">
          <h3>
            <Trans>{title1}</Trans>
          </h3>
          <p className="feature-description1">
            <Trans>{description1}</Trans>
          </p>
          {title2 && (
            <h3>
              <Trans>{title2}</Trans>
            </h3>
          )}
          {description2 && (
            <p
              className="feature-description2"
              dangerouslySetInnerHTML={{ __html: t(description2) }}
            />
          )}
        </div>
        {(cta?.text || cta2?.text) && (
          <div className="feature-btn-group">
            {cta?.text && (
              <Button
                onClick={redirectTo.bind(this, cta)}
                type="danger"
                className={`feature-cta-new ${cta.className || ""}`}
              >
                {cta.text}
              </Button>
            )}
            {cta2.text && (
              <Button
                onClick={redirectTo.bind(this, cta2)}
                type="danger"
                className={`feature-cta-new ${cta2.className || ""}`}
              >
                {cta2.text}
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

CampaignActiveNote.defaultProps = {
  cta: {},
  cta2: {},
  campaignRunningLabel: "Campaigns Running:",
};

export default CampaignActiveNote;
