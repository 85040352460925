import { customMergeOS } from "@onlinesales-ai/util-methods-v2";
import Types from "./types";

const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_ACTIVE_CLIENT_ID:
    case Types.APP_CLIENT_CHANGE:
      {
        state = {
          ...initialState,
        };
      }
      break;
    case Types.APP_SET_ENTITY_ID:
      {
        state = {
          ...initialState,
        };
      }
      break;
    case Types.UPDATE_CAMPAIGN_TYPE_WALLET_DATA:
      {
        state = {
          ...state,
          [action.clientId]: {
            ...(state[action.clientId] || {}),
            campaignTypeWallet: {
              ...state[action.clientId]?.campaignTypeWallet,
              overall: {
                ...state[action.clientId]?.overall,
                ...(action?.data?.overall || {}),
              },
              channelData: customMergeOS(
                {},
                state[action.clientId]?.campaignTypeWallet?.channelData,
                action?.data?.channelData,
              ),
            },
          },
        };
      }
      break;
    case Types.UPDATE_BILLING_SETTINGS:
      {
        state = {
          ...state,
          [action.clientId]: {
            ...(state[action.clientId] || {}),
            financeConfig: {
              ...state[action.clientId]?.financeConfig,
              ...action.data,
            },
          },
        };
      }
      break;
    case Types.UPDATE_WALLET_DATA:
      {
        state = {
          ...state,
          [action.clientId]: {
            ...(state[action.clientId] || {}),
            walletData: {
              ...state[action.clientId]?.walletData,
              data: customMergeOS({}, state[action.clientId]?.walletData?.data, action.data),
            },
          },
        };
      }
      break;
    case Types.UPDATE_PROMOTIONS_DATA:
      {
        state = {
          ...state,
          [action.clientId]: {
            ...(state[action.clientId] || {}),
            promotionsData: {
              ...state[action.clientId]?.promotionsData,
              data: {
                ...state[action.clientId]?.promotionsData,
                ...action.data,
              },
            },
          },
        };
      }
      break;
    case Types.UPDATE_PROMOTION_TABLE_DATA:
      {
        state = {
          ...state,
          promotions: {
            ...state.promotions,
            data: {
              ...state.promotions?.data,
              ...action.data,
            },
          },
        };
      }
      break;
    case Types.UPDATE_PROMOTION_FORM_DATA:
      {
        state = {
          ...state,
          promotions: {
            ...state.promotions,
            data: {
              ...state.promotions?.data,
              [action?.data?.id]: {
                ...state.promotions?.data?.[action?.data?.id],
                ...action.data,
              },
            },
          },
        };
      }
      break;
    case Types.GLOBAL_WALLET_DATA_FETCH_STATE:
      {
        state = {
          ...state,
          [action.clientId]: {
            ...(state[action.clientId] || {}),
            globalWalletDataFetchState: action.data,
          },
        };
      }
      break;
    default:
      break;
  }

  return state;
};

export default {
  Finance: reducer,
};
