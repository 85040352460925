/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Button } from "@onlinesales-ai/button-v2";
import { markDownToReact } from "@onlinesales-ai/html-v2";

import ModalWindow from "./modalWindow";

const ConfirmationModal = ({
  container,
  containerClass,
  isShow,
  title,
  headerTitle,
  actionBtnText,
  actionBtnCallback,
  rightBtnText,
  rightBtnCallback,
  rightButtonType,
  shouldShowCloseButton,
  closeButtonHandler,
  BodyComponent,
  rightBtnTextProps,
  isMarkDownString,
  bodyComponentProps,
  actionBtnClassName,
  rightBtnClassName,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState({});
  const { t } = useTranslation();

  return (
    <ModalWindow
      container={container}
      containerClass={`confirmation-modal-wrapper ${containerClass}`}
      modalProps={{
        backdrop: "static",
      }}
      isShow={isShow}
    >
      {(shouldShowCloseButton || headerTitle) && (
        <ModalWindow.Header>
          {headerTitle ? <span>{t(headerTitle)}</span> : null}
          {shouldShowCloseButton ? (
            <span
              closeButton={shouldShowCloseButton}
              onClick={() => closeButtonHandler()}
              className="icon icon-close1"
            />
          ) : null}
        </ModalWindow.Header>
      )}
      {(title || BodyComponent) && (
        <ModalWindow.Body>
          {BodyComponent ? (
            <Trans>
              <BodyComponent
                title={title}
                values={values}
                setValues={setValues}
                {...bodyComponentProps}
              />
            </Trans>
          ) : typeof title === "string" ? (
            isMarkDownString ? markDownToReact(t(title)) : t(title)
          ) : (
            title
          )}
        </ModalWindow.Body>
      )}

      <ModalWindow.Footer>
        {actionBtnText && (
          <Button
            className={`pendo_feature_confirmation_modal_action_btn ${actionBtnClassName}`}
            isLoading={isLoading}
            disabled={isLoading}
            onClick={(event) => {
              event.stopPropagation();
              actionBtnCallback({ setIsLoading, ...(values || {}) });
              setValues({});
            }}
          >
            {actionBtnText}
          </Button>
        )}
        {rightBtnText && (
          <Button
            className={`variable-button pendo_feature_confirmation_modal_right_btn ${rightBtnClassName}`}
            type="primary"
            link
            disabled={isLoading}
            onClick={(event) => {
              event.stopPropagation();
              rightBtnCallback({ ...(values || {}) });
              setValues({});
            }}
            {...(rightBtnTextProps || {})}
          >
            {rightBtnText}
          </Button>
        )}
      </ModalWindow.Footer>
    </ModalWindow>
  );
};

ConfirmationModal.defaultProps = {
  rightBtnText: "Cancel",
  containerClass: "",
  rightBtnCallback: () => {},
  actionBtnCallback: () => {},
  closeButtonHandler: () => {},
  shouldShowCloseButton: false,
};

export default ConfirmationModal;
