import React from "react";
import { isBefore } from "date-fns/esm";
import { UTCDatePicker } from "@onlinesales-ai/datepicker-v2";
import { OSHOCWithUtilities } from "@onlinesales-ai/os-hoc-with-utilities-v2";

const UTCDateSelectComponent = ({
  onChange,
  tabKey,
  showToastMessage,
  componentMetaData,
  value,
  endDatePlaceholder,
  startDatePlaceholder,
}) => {
  const { enableTime } = componentMetaData || {};

  const isDateRangeValid = (selectedDate) => {
    if (selectedDate?.startDate && selectedDate?.endDate) {
      return isBefore(selectedDate?.startDate, selectedDate?.endDate);
    }

    return true;
  };

  const onDateApply = (selectedDate, key) => {
    const valToUpdate = { ...value, [key]: selectedDate };
    if (isDateRangeValid(valToUpdate)) {
      onChange(valToUpdate, tabKey);
    } else {
      showToastMessage({
        type: "ERROR",
        messageToDisplay: "Please select valid date range",
        toastDuration: 5000,
      });
    }
  };

  return (
    <div className="date-select-component-wrapper">
      <UTCDatePicker
        value={value.startDate}
        position="left"
        enableTime={enableTime || false}
        placeHolder={startDatePlaceholder}
        onChange={(date) => onDateApply(date, "startDate")}
        showCTAs={enableTime || false}
        shouldCloseOnSelect={!enableTime}
        defaultTime="startOfDay"
      />
      <div className="separator">-</div>
      <UTCDatePicker
        value={value.endDate}
        position="left"
        enableTime={enableTime || false}
        placeHolder={endDatePlaceholder}
        onChange={(date) => onDateApply(date, "endDate")}
        showCTAs={enableTime || false}
        shouldCloseOnSelect={!enableTime}
        defaultTime="endOfDay"
      />
    </div>
  );
};

UTCDateSelectComponent.defaultProps = {
  startDatePlaceholder: "Select Start Date",
  endDatePlaceholder: "Select End Date",
};

export default OSHOCWithUtilities(UTCDateSelectComponent);
