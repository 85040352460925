import React from "react";
import HighChart from "@onlinesales-ai/highchart-v2";
import { withTranslation } from "react-i18next";
import _isEqual from "lodash/isEqual";
import {
  getCurrencyIconCode,
  getCurrencyCode,
  formatNumberInAbbreviations,
  format
} from "@onlinesales-ai/util-methods-v2";
import "./app.less";

class TwoLineChart extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      categories: [],
      line1Data: [],
      line2Data: [],
    };
  }

  // TODO: akshay refactor this component with hooks

  UNSAFE_componentWillMount() {
    this.setStateFromProps(this.props, true);
  }

  shouldComponentUpdate(nextProps) {
    return !_isEqual(nextProps, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    this.setStateFromProps(nextProps);
  }

  setStateFromProps = (props, isFirstTime) => {
    //TODO: Update state on will receive props if needed
    let stateToUpdate = {};
    let categories = [],
      line1Data = [],
      line2Data = [];
    let { line1Info = {}, line2Info = {}, data = [] } = props;
    let { dataKey: line1DataKey } = line1Info;
    let { dataKey: line2DataKey } = line2Info;

    data.forEach((dataObj) => {
      let dateEpoch = new Date(dataObj.date).valueOf();
      line1Data.push([dateEpoch, dataObj[line1DataKey] || 0]);
      line2Data.push([dateEpoch, dataObj[line2DataKey] || 0]);
    });

    stateToUpdate.categories = categories;
    stateToUpdate.line1Data = line1Data;
    stateToUpdate.line2Data = line2Data;

    this.setState(stateToUpdate);
  };

  getHighChartsConfig = () => {
    let {
      height = 70,
      width = 180,
      line1Info = {},
      line2Info = {},
      isAllowTooltipOutside = false,
      t
    } = this.props;
    let { categories = [], line1Data = [], line2Data = [] } = this.state;
    let {
      name: line1Name,
      color: line1Color = "#D1D1D1",
      showCurrency: line1ShowCurrency,
      precision: line1Precision,
      showInPercentage: line1ShowInPercentage
    } = line1Info;

    let {
      name: line2Name,
      color: line2Color = "#65D7EA",
      showCurrency: line2ShowCurrency,
      precision: line2Precision,
      showInPercentage: line2ShowInPercentage
    } = line2Info;

    let currencyClass = getCurrencyIconCode(getCurrencyCode());
    let line1Multiplier = line1ShowInPercentage ? 100 : 1;
    let line2Multiplier = line2ShowInPercentage ? 100 : 1;
    let line1CurrencyClass = line1ShowCurrency ? currencyClass : "";
    let line2CurrencyClass = line2ShowCurrency ? currencyClass : "";

    return {
      chart: {
        height: height,
        width: width,
      },
      title: {
        text: null,
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      xAxis: [
        {
          type: null,
          crosshair: false,
          labels: {
            enabled: false,
            tickLength: 0,
            lineColor: "transparent",
          },
          tickInterval: 1,
          tickLength: 0,
          minorTickLength: 0,
          lineColor: "transparent",
        },
      ],
      yAxis: {
        visible: false,
      },
      exporting: {
        enabled: false,
      },
      plotOptions: {
        spline: {
          label: {
            enabled: false,
          },
          marker: {
            enabled: false,
          },
        },
        series: {
          animation: false,
          lineWidth: 1.5,
          borderColor: "",
          marker: {
            enabled: categories.length === 1,
          },
          pointStart: 0,
        },
        line: {
          marker: {
            enabled: false,
          },
        },
      },
      series: [
        {
          type: "spline",
          name: line1Name,
          data: line1Data,
          color: line1Color,
          dashStyle: "Dot",
        },
        {
          type: "spline",
          name: line2Name,
          data: line2Data,
          color: line2Color,
        },
      ],
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                enabled: false,
              },
            },
          },
        ],
      },
      tooltip: {
        useHTML: true,
        backgroundColor: null,
        borderWidth: 0,
        shared: true,
        shadow: false,
        hideDelay: 0,
        outside: isAllowTooltipOutside,
        formatter: function () {
          let date = this.x;
          let tooltipStr = "";
          const formattedDate = format(date, "dd-MM-yyyy");
          try {
            tooltipStr =
              '<div class="two-line-chart-tooltip-wrapper">' +
              '<div style="background-color:#fff;color:#3a3a3a;" ' +
              '     class="high-chart-custom-tool-tip">' +
              formattedDate +
              "</div>" +
              '<div style="background-color:#ffffff;color:#606060;" ' +
              '     class="high-chart-custom-tool-tip-bottom">' +
              '     <table class="tool-tip-table"> ' +
              "        <tr> " +
              '           <td class="legend-data" width="30">' +
              '               <div class="high-chart-block-legend-symbol"' +
              '                    style="background-color:' +
              this.points[1].color +
              '">' +
              "               </div>" +
              "           </td>" +
              '           <td class="left-data">' +
              t(line2Name) +
              "</td> " +
              '           <td class="right-data"><span class="' +
              line2CurrencyClass +
              '"></span> ' +
              formatNumberInAbbreviations(
                (this.points[1].y || 0) * line2Multiplier,
                line2Precision,
              ) + (line2ShowInPercentage ? "%" : "") +
              "</td>" +
              "        </tr> " +
              "        <tr> " +
              '           <td class="legend-data" width="30">' +
              '               <div class="high-chart-block-legend-symbol"' +
              '                    style="background-color:' +
              this.points[0].color +
              '">' +
              "               </div>" +
              "           </td>" +
              '           <td class="left-data">' +
              t(line1Name) +
              "</td> " +
              '           <td class="right-data"><span class="' +
              line1CurrencyClass +
              '"></span> ' +
              formatNumberInAbbreviations(
                (this.points[0].y || 0) * line1Multiplier,
                line1Precision,
              ) + (line1ShowInPercentage ? "%" : "") +
              "</td>" +
              "        </tr> " +
              "     </table>" +
              "</div>" +
              "</div>";
          } catch (error) {
          }
          return tooltipStr;
        },
      },
      rangeSelector: {
        enabled: false,
      },
      navigator: {
        enabled: false,
      },
      scrollbar: {
        enabled: false,
      },
    };
  };

  render() {
    let highChartConfig = this.getHighChartsConfig();
    return (
      <div className="two-line-chart-wrapper">
        <HighChart options={highChartConfig} />
      </div>
    );
  }
}

TwoLineChart.defaultProps = {};

export default withTranslation()(TwoLineChart);
