import PopupHandler from "./popupHandler";

let popupWindow = null;

const closePopUpWindow = () => {
  try {
    popupWindow.close();
  } catch (e) {}
};

const performOAuth = (popupTitle = "Onlinesales.ai", url, callback, shouldClosePopUp = true) => {
  if (url) {
    PopupHandler.registerMessageListener((event) => {
      if (shouldClosePopUp) {
        setTimeout(closePopUpWindow, 500);
      }
      callback(event);
    });
    popupWindow = PopupHandler.open(url, popupTitle, 1100, 650);

    return popupWindow;
  }
};

export default {
  performOAuth,
  closePopUpWindow,
};
