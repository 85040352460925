import { getCurrencyClass } from "@onlinesales-ai/util-methods-v2";

import { markdownToHtml } from "./markdownParser";
import { htmlToReact } from "./htmlReact";

export const markDownToReact = (markdownString, options) => {
  const html = markdownToHtml(markdownString, options);
  return htmlToReact(html, options);
};

export const markDownToReactWithPlaceholder = (markdownString, options) => {
  let newString = markdownString;

  newString = newString.replace(
    /__CURRENCY__/g,
    `<span class="${getCurrencyClass()}"></span>`,
  );

  return markDownToReact(newString, options);
};
