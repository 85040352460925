import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { Button } from "@onlinesales-ai/button-v2";
import { logoConfigExtract, shouldRenderComponent } from "@onlinesales-ai/util-methods-v2";
import "./index.less";

const PWAInit = ({ installAppBanner, description, ctaText }) => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [drawerState, setDrawerState] = useState("not-open");

  const closePWAdrawer = () => {
    setDrawerState("not-open");
  };
  const installApp = () => {
    setDrawerState("not-open");
    try {
      if (deferredPrompt) {
        deferredPrompt.prompt();
      }
    } catch (e) {}
  };

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      setDeferredPrompt(e);

      let intervalId = window.setInterval(() => {
        let PawEle = document.getElementById("pwa-drawer");
        if (PawEle) {
          setDrawerState("open");
          window.clearInterval(intervalId);
        }
      }, 200);
    });
  }, []);

  if (shouldRenderComponent(installAppBanner)) {
    return (
      <>
        <div id="pwa-drawer" className={drawerState}>
          <div className="pwa-drawer-container">
            <div className="pwa-content">
              <img alt="onlinesales.ai logo" src={logoConfigExtract("logo_width_52")} />
              <h4>{description}</h4>
              <Button className="install-btn ml-0" onClick={installApp}>
                {ctaText}
              </Button>
              <Button onClick={closePWAdrawer} link icon="icon-close1" className="close os-widget-close" />
            </div>
          </div>
        </div>
        <div className="pwa-widget-overlay" />
      </>
    );
  }

  return null;
};

const mapStateToProps = (state) => {
  const domainConfig = state?.DomainConfig || {};
  const { installAppBanner, description, ctaText } = domainConfig?.pwaConfig || {};
  return {
    ctaText,
    description,
    installAppBanner,
    isMobile: state?.Application?.isMobile,
  };
};

PWAInit.defaultProps = {
  description: "AI Driven reporting at your fingertips, with OnlineSales.ai",
  ctaText: "Add to your Home screen",
  installAppBanner: {
    shouldBeRenderOnMobile: true,
    shouldBeRenderOnDesktop: false,
    shouldBeRenderedForAllClientsOnMobile: true,
    shouldBeRenderedForAllClientsOnDesktop: false,
  },
};

export default connect(mapStateToProps)(PWAInit);
