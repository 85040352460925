import React, { useEffect, useMemo, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { differenceInDays } from "date-fns/esm";
import { Overlay, Popover } from "react-bootstrap";

import { CardSelector } from "@onlinesales-ai/utils-components-v2";
import { Button } from "@onlinesales-ai/button-v2";
import { ModalWindow } from "@onlinesales-ai/modal-window-v2";
import { checkValidation as preDefinedValidation } from "@onlinesales-ai/hoc-with-validations-v2";
import { setLSItem, getLSItem, populateEvent } from "@onlinesales-ai/util-methods-v2";
import AsyncImage from "@onlinesales-ai/async-image-v2";

import "./index.less";

function fireIntercomEvents(action, metaData) {
  populateEvent("SHOPS||APP", "APP_ACTIONS||PLATFORM_TOUR||" + action, metaData);
}

const TourModal = ({
  containerClass,
  modalHeader,
  triggerTooltip,
  modalDesc,
  options,
  loaderPaths,
  postOnboardingData,
  clientId,
}) => {
  const { t } = useTranslation();
  const targetRef = useRef(null);
  const tooltipRef = useRef(null);
  const [selectedMode, setSelectedMode] = useState(null);
  const [showTourButton, setShowTourButton] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const { data = {} } = useSelector((state) => state.OnBoarding || {});
  const isAnyOptionComplete = useMemo(() => {
    return options.some(({ flagToCheck }) => {
      return !!data?.metadata?.[flagToCheck];
    });
  }, [options, data]);

  useEffect(() => {
    setSelectedMode(null);
    const tourVisitedAt = getLSItem("tourVisited");
    fireIntercomEvents("TOUR_INIT", { tourVisitedAt });
    if (!tourVisitedAt || differenceInDays(new Date(), new Date(tourVisitedAt)) > 6 || true) {
      fireIntercomEvents("MODAL_OPEN", { tourVisitedAt: new Date().toString() });
      setShowModal(true);
      setLSItem("tourVisited", new Date().toString());
    }
  }, [clientId]);

  useEffect(() => {
    setShowTourButton(!showModal);
    if (targetRef.current && !tooltipRef.current?.isShowedOnce) {
      fireIntercomEvents("TOUR_TOOLTIP_SHOW", { showModal });
      setShowTooltip(!showModal);
    }
  }, [showModal, targetRef]);

  const { isShowModal, badgeCount } = useMemo(() => {
    let filteredOptions = [];
    if (data?.metadata) {
      filteredOptions = options.filter(({ flagToCheck }) => {
        return !data.metadata?.[flagToCheck];
      });
    }
    return {
      isShowModal: filteredOptions.length > 0,
      badgeCount: filteredOptions.length,
    };
  }, [data?.metadata, options]);

  const getDescendantProp = (obj, desc) => {
    const arr = desc.split(".");
    while (arr.length && (obj = obj[arr.shift()]));
    return obj;
  };

  const { state, isLoading } = useSelector((state) => {
    let loading = false;
    for (let i = 0; i < loaderPaths?.length; i++) {
      const { path, value } = loaderPaths[i] || {};
      if (getDescendantProp(state, path) === value) {
        loading = true;
        break;
      }
    }
    return { state, isLoading: loading };
  });

  const onModalDestroy = () => {
    setShowModal(false);
    fireIntercomEvents("TOUR_CANCEL");
  };

  const triggerTour = (mode) => {
    const option = options.find(({ key }) => mode === key);
    let selectedActionConfig = {};
    for (let i = 0; i < option.actionConfig?.length; i++) {
      const config = option.actionConfig[i];
      if (config.conditions?.length) {
        const result = config.conditions.every(({ type, value }) => {
          return (
            preDefinedValidation(
              {
                validationType: type,
              },
              state,
            ) === value
          );
        });
        if (result) {
          selectedActionConfig = config;
          break;
        }
      } else {
        selectedActionConfig = config;
        break;
      }
    }
    if (selectedActionConfig.webTourId && selectedActionConfig.action === "WEB_TOUR") {
      try {
        fireIntercomEvents("TOUR_START", { option, selectedActionConfig });
        window.pendo?.showGuideById(selectedActionConfig.webTourId);
      } catch (e) {}
      onModalDestroy();
    } else if (selectedActionConfig.componentId && selectedActionConfig.action === "SCROLL_TO_COMPONENT") {
      window.setTimeout(() => {
        try {
          const $scrollTo = document.getElementById(selectedActionConfig.componentId);
          $scrollTo.scrollIntoView({
            behavior: 'auto',
            block: 'center',
            inline: 'center'
          });
        } catch (e) {}
      }, 200);

      onModalDestroy();
    }

    if (
      option.updateFlagInOnboardingMetadata &&
      option.flagToCheck &&
      !data.metadata?.[option.flagToCheck]
    ) {
      postOnboardingData({
        ...data,
        metadata: {
          ...(data.metadata || {}),
          [option.flagToCheck]: true,
        },
      });
    }
  };

  const modalBody = () => {
    return (
      <div className={`card-selector-wrapper ${isLoading ? "loading" : ""}`}>
        {isLoading && (
          <>
            {options.map(() => {
              return <div className="animated-bg" />;
            })}
          </>
        )}
        {!isLoading && (
          <>
            <p className="tour-modal-description">{t(modalDesc)}</p>
            <CardSelector
              selected={selectedMode}
              onChange={(mode) => {
                setSelectedMode(mode);
                triggerTour(mode);
              }}
            >
              {options.map(
                ({
                  key,
                  title,
                  description,
                  imageUrl,
                  flagToCheck,
                  topLeftContentUrl,
                  subTitle,
                }) => {
                  const isVisited = !!data.metadata?.[flagToCheck];
                  const topLeftContentUrlFunc = () => {
                    return <AsyncImage imgSrc={topLeftContentUrl} />;
                  };
                  return (
                    <CardSelector.Card
                      cardKey={key}
                      isRecommended={isAnyOptionComplete}
                      isDisabled={isVisited}
                      topLeftContent={topLeftContentUrl && topLeftContentUrlFunc}
                      noPointerCursor={isVisited}
                      recommendedText={isVisited ? "COMPLETED" : "PENDING"}
                      recommendedClass={isVisited ? "tour-completed" : "tour-pending"}
                      imageUrl={imageUrl}
                      title={title}
                      subTitle={subTitle}
                      showCta={false}
                      description={description}
                    />
                  );
                },
              )}
            </CardSelector>
          </>
        )}
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <Button onClick={triggerTour} disabled={!selectedMode}>
        <Trans>Start</Trans>
      </Button>
    );
  };

  return (
    <>
      {isShowModal ? (
        <ModalWindow
          isShow={showModal}
          onModalDestroy={onModalDestroy}
          containerClass={`tour-modal ${containerClass}`}
          modalProps={{
            backdrop: "static",
          }}
        >
          <ModalWindow.Header closeButton={true || isAnyOptionComplete}>
            {t(modalHeader)}
          </ModalWindow.Header>
          <ModalWindow.Body>{modalBody()}</ModalWindow.Body>
          {/* <ModalWindow.Footer>{renderFooter()}</ModalWindow.Footer> */}
        </ModalWindow>
      ) : (
        <></>
      )}
      <div ref={targetRef}>
        <Button
          className={`tour-modal-trigger-button ${
            isShowModal && !isLoading && showTourButton ? "" : "d-none"
          }`}
          onClick={() => {
            setSelectedMode(null);
            setShowModal(true);
          }}
        >
          <span className="notifications-count">{badgeCount}</span>
          <span className="icon icon-clipboard-tick" />
        </Button>
      </div>
      {isShowModal && !isLoading && showTourButton && showTooltip && (
        <div className="os-popover-wrapper tour-modal-trigger">
          <Overlay show={showTooltip} target={targetRef.current} containerPadding={20} transition>
            <Popover
              className="colored-tooltip os-tooltip-popover"
              id="popover-tour"
              delay={{ show: 0, hide: 400 }}
              onMouseOver={() => setShowTooltip(true)}
              // onMouseOut={() => {

              // }}
            >
              <div
                onClick={() => {
                  setShowTooltip(false);
                  tooltipRef.current = {
                    isShowedOnce: true,
                  };
                }}
              >
                <span className="icon icon-close" />
              </div>
              {t(triggerTooltip)}
            </Popover>
          </Overlay>
        </div>
      )}
    </>
  );
};

TourModal.defaultProps = {
  modalHeader: "Welcome to the platform",
  triggerTooltip:
    "Click here to learn how to create posts, activate campaigns or explore the platform.",
  modalDesc: "Choose among these 3 to begin with!",
  loaderPaths: [
    // {
    //   path: "AccountLinking.vendorAccountIsLoading",
    //   value: true,
    // },
    // {
    //   path: "Application.isBusinessDetailsFilled",
    //   value: undefined,
    // },
  ],
  options: [
    // {
    //   key: "CREATE_POST",
    //   flagToCheck: "postCreationVisited",
    //   updateFlagInOnboardingMetadata: false,
    //   title: "Create Post",
    //   description: "Congrats! You are eligible for 3 free posts. Start creating now.",
    //   imageUrl:
    //     "https://res.cloudinary.com/onlinesales/image/upload/h_250/v1625553231/product/monetize/smart_SKU.gif",
    //   actionConfig: [
    //     {
    //       conditions: [
    //         {
    //           type: "MIN_PAGE_LINK_CHECK",
    //           value: false,
    //         },
    //       ],
    //       action: "WEB_TOUR",
    //       webTourId: "bkE5yeV56B7bT5v_fj740Z0WLmo",
    //     },
    //     {
    //       action: "WEB_TOUR",
    //       webTourId: "bkE5yeV56B7bT5v_fj740Z0WLmo___",
    //     },
    //   ],
    // },
  ],
};

export default TourModal;
