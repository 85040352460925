import React, { useContext, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { ReportingUIService } from "@onlinesales-ai/services-v2";
import { populateEvent } from "@onlinesales-ai/util-methods-v2";
import { GlobalContext } from "@onlinesales-ai/utils-components-v2";

import StripCardUpdate from "../paymentMethods/stripCardUpdate";
import { parseSubscriptionData } from "../utils";

const fireIntercomEvents = (action, metaData) => {
  populateEvent(`PAYMENT||${action}`, metaData);
};

const PaymentModal = ({
  clientId,
  agencyId,
  shopInfo,
  storeType,
  packageName,
  platform,
  billingAppName,
  ModalComponent,
  onSubsciption,
  userInfo,
  checkForCardNumber,
  apiVersion,
  ...props
}) => {
  const { showToastMessage } = useContext(GlobalContext);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingCardData, setIsLoadingCardData] = useState(false);
  const [postIsLoading, setPostIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const updateCardRef = useRef({});
  const [subscriptionData, setSubscriptionData] = useState({});
  const { isSubscribed, planData, cardData } = subscriptionData || {};
  const { last4: cardNumber } = cardData || {};

  const fetchSubscriptionData = async () => {
    const payload = {
      clientId,
      agencyId,
      storeType,
      platform,
      appName: billingAppName,
      currency: shopInfo.currencyCode,
    };

    if (packageName) {
      payload.packageName = packageName;
    }

    try {
      const response = await ReportingUIService.fetchClientSubscriptions(payload, "BILLING");
      setSubscriptionData(parseSubscriptionData(response?.data, billingAppName));
    } catch (err) {
      setErrorMsg(err.errorMsg);
    }
  };

  const fetchData = async () => {
    try {
      await fetchSubscriptionData();
    } catch (err) {}
    setIsLoading(false);
  };

  const updateCardData = async () => {
    setIsLoadingCardData(true);
    try {
      await fetchSubscriptionData();
    } catch (err) {}
    setIsLoadingCardData(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const subscribedUserToPlan = async () => {
    setPostIsLoading(true);

    try {
      const packageData = planData.find((p) => p.packageName === packageName);

      if (packageData) {
        const payload = {
          clientId,
          agencyId,
          userId: userInfo?.id,
          platform,
          email: userInfo?.email,
          subscriptions: [
            {
              planId: packageData?.planId,
              status: "ACTIVE",
            },
          ],
        };

        await ReportingUIService.postSubscriptionPlans(payload, "TOPUP");

        fireIntercomEvents("SUBSCRIBED_SUCCESSFULLY", {
          clientId,
          agencyId,
          packageData,
        });

        onSubsciption();
      } else {
        fireIntercomEvents("SUBSCRIBED_FAILED", {
          clientId,
          agencyId,
          packageNotFound: true,
        });

        showToastMessage({
          type: "ERROR",
          messageToDisplay: t("Subscription package not found"),
          actionButtonLabel: null,
          toastDuration: 8000,
        });
      }
    } catch (err) {
      fireIntercomEvents("SUBSCRIBED_FAILED", {
        clientId,
        agencyId,
      });

      showToastMessage({
        type: "ERROR",
        messageToDisplay: t(err.errorMsg),
        actionButtonLabel: null,
        toastDuration: 8000,
      });
    }
    setPostIsLoading(false);
  };

  const onClickPayment = () => {
    fireIntercomEvents("CLICK_TO_PAY", {
      clientId,
      agencyId,
    });
    if (checkForCardNumber) {
      if (isSubscribed && cardNumber) {
        onSubsciption();
      } else if (updateCardRef?.current?.openCardUpdateModal) {
        updateCardRef.current.openCardUpdateModal();
      }
    } else if (isSubscribed) {
      onSubsciption();
    }
  };

  const onCardUpdate = () => {
    if (cardNumber) {
      showToastMessage({
        type: "SUCCESS",
        messageToDisplay: t("Card Updated Successfully."),
        actionButtonLabel: null,
        toastDuration: 8000,
      });
    }
    if (!isSubscribed) {
      subscribedUserToPlan();
    } else if (isSubscribed && !cardNumber) {
      onSubsciption();
    } else if (isSubscribed && cardNumber) {
      updateCardData();
    }
  };

  return (
    <StripCardUpdate
      platform={platform}
      showToastMessage={showToastMessage}
      onCardUpdate={onCardUpdate}
      updateCardRef={updateCardRef}
      apiVersion={apiVersion}
    >
      {({ openCardUpdateModal, isLoading: cardUpdateLoading }) => {
        return (
          <ModalComponent
            {...props}
            showToastMessage={showToastMessage}
            subscriptionIsLoading={isLoading}
            isLoadingCardData={isLoadingCardData}
            subscriptionPostIsLoading={postIsLoading}
            subscriptionError={errorMsg}
            subscriptionData={subscriptionData}
            openCardUpdateModal={openCardUpdateModal}
            cardUpdateLoading={cardUpdateLoading}
            onSubsciption={onSubsciption}
            onClickPayment={onClickPayment}
          />
        );
      }}
    </StripCardUpdate>
  );
};

PaymentModal.defaultProps = {
  platform: "OS",
  checkForCardNumber: true,
};

const mapStateToProps = (state) => {
  const { agencyId, shopInfo, clientId, billingAppName, userInfo } = state.Application || {};
  const { storeType } = state.DomainConfig || {};

  return {
    clientId,
    agencyId,
    storeType,
    billingAppName,
    shopInfo,
    userInfo,
  };
};

export default connect(mapStateToProps)(PaymentModal);
