import React from "react";
import { Trans } from "react-i18next";

import WithTooltip from "@onlinesales-ai/tooltip-v2";

import "./index.less";

const Button = React.forwardRef(
  (
    {
      className = "",
      type,
      size,
      shape,
      children,
      link,
      icon,
      iconCount,
      iconWeight,
      dashed,
      isLoading,
      disabled,
      isDisabled,
      bgcolor,
      outline,
      block,
      badgeIcon,
      onClick,
      isEllipsis,
      fontSize,
      btnTextClassName,
      ...props
    },
    ref,
  ) => {
    return (
      <button
        type="button"
        className={`button os-button ${className} button-${type} shape-${shape} ${size} ${
          disabled ? "disabled" : ""
        } ${outline ? " outline" : ""} ${block ? " block" : ""} ${dashed ? "dashed" : ""} ${
          link ? "link" : ""
        } ${fontSize}`}
        disabled={disabled}
        onClick={disabled || isDisabled ? () => {} : onClick}
        {...props}
        ref={ref}
      >
        {isLoading ? (
          <i className="icon icon-spinner icon-spin mr-2" />
        ) : (
          <>
            <>
              {icon ? (
                <span className={`icon ${icon} ${iconWeight ? "iconweight" : ""} iconbtn`} />
              ) : null}
            </>
            <>
              {iconCount ? (
                <span className={`badge d-center os-bg-${bgcolor}`}>{iconCount}</span>
              ) : null}
            </>
            <>{badgeIcon ? <span className={`badge-icon icon ${badgeIcon}`} /> : null}</>
          </>
        )}

        {!!children && typeof children === "string" ? (
          <span className={`btn-text-spacing ${isEllipsis ? "ellipsis-text" : ""} ${btnTextClassName}`}>
            <Trans>{children}</Trans>
          </span>
        ) : (
          children
        )}
      </button>
    );
  },
);

Button.defaultProps = {
  type: "primary", // primary | danger | default | success | warning
  className: "",
  outline: false,
  block: false,
  link: false,
  size: "medium", //small | large | medium
  shape: "cornor", //rounded || normal || circle
  iconWeight: false,
  dashed: false,
  isEllipsis: false,
  fontSize: "",
  btnTextClassName: "",
};

const ButtonWrapper = React.forwardRef(
  ({ tooltip, disabledTooltip, tooltipProps, disabled, ...props }, ref) => {
    if (disabled && disabledTooltip) {
      return (
        <WithTooltip title={disabledTooltip} {...tooltipProps}>
          <Button {...props} className={`${props.className || ""} disabled`} isDisabled ref={ref} />
        </WithTooltip>
      );
    } else if (tooltip) {
      return (
        <WithTooltip title={tooltip} {...tooltipProps}>
          <Button {...props} disabled={disabled} ref={ref} />
        </WithTooltip>
      );
    }

    return <Button {...props} disabled={disabled} ref={ref} />;
  },
);

export default ButtonWrapper;
