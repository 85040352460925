import React, { forwardRef, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";

import _range from "lodash/range";
import _isUndefined from "lodash/isUndefined";
import _isEqual from "lodash/isEqual";
import _isEmpty from "lodash/isEmpty";

import {
  getYear,
  getMonth,
  isAfter,
  isBefore,
  setHours,
  setMinutes,
  setSeconds,
  getHours,
  getMinutes,
} from "date-fns/esm";

import { Button } from "@onlinesales-ai/button-v2";
import { Dropdown } from "@onlinesales-ai/dropdown-v2";
import { format, formattedDate, usePrevious } from "@onlinesales-ai/util-methods-v2";

import "./datepicker.less";

const CustomInput = forwardRef(
  (
    {
      onClick,
      userValue,
      placeHolder,
      timePlaceHolder,
      enableTime,
      disableIcon,
      disabled,
    },
    ref,
  ) => {
    const text = enableTime ? timePlaceHolder : placeHolder;
    return (
      <div ref={ref} className={`os-datepicker ${disabled ? "disabled" : ""}`}>
        <div
          className="date-picker-input-wrapper d-align-between form-controls"
          onClick={!disabled ? onClick : () => {}}
        >
          {userValue ? (
            <div className="date-picker-input">
              {userValue
                ? formattedDate(userValue, enableTime ? "dateTime" : "date", { useFormat: true })
                : text}
            </div>
          ) : (
            <div className="date-picker-placeholder">{text}</div>
          )}
          {/* <div className="icon icon-calendar" /> */}
          {!disableIcon ? (
            <img
              className="datepicker-icon"
              src="https://osads.gumlet.io/image/authenticated/s--QF_p6Gt6--/v1627034581/product/monetize/calendar.svg"
            />
          ) : null}
        </div>
      </div>
    );
  },
);

const meridiemFormat = [
  {
    label: "am",
    value: "am",
  },
  {
    label: "pm",
    value: "pm",
  },
];

const SingleDatePicker = ({
  value,
  onChange,
  minDate,
  maxDate,
  placeHolder,
  enableTime,
  timeIntervals,
  timePlaceHolder,
  shouldCloseOnSelect,
  showCTAs,
  disableIcon,
  className,
  disabled,
  enableborderbottom,
  showMeridianFormat,
  defaultHours,
  defaultMinutes,
  defaultSeconds,
  popperClassName,
  extraMinutesOptions,
  ...props
}) => {
  const [selectedDate, setSelectedDate] = useState();
  const previousValue = usePrevious(value);
  const [selectedHours, setSelectedHours] = useState(defaultHours);
  const [selectedMinutes, setSelectedMinutes] = useState(defaultMinutes);
  const [selectedMeridiemFormat, setSelectedMeridiemFormat] = useState("am");
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const years = _range(1990, 2031, 1).map((year) => ({
    label: year,
    value: year,
  }));

  const months = _range(0, 12, 1).map((count) => ({
    label: format(new Date(1996, count, 1), "MMMM"),
    value: count,
  }));

  useEffect(() => {
    if (value) {
      if (!_isEqual(previousValue, value)) {
        setSelectedDate(value);

        if (enableTime) {
          let setHour = getHours(value);
          if (showMeridianFormat && selectedMeridiemFormat === "pm") {
            setHour -= 12;
          }
          setSelectedHours(setHour);
          setSelectedMinutes(getMinutes(value));
        }
      }
    } else {
      setSelectedDate(null);
    }
  }, [value, previousValue]);

  const onCalendarOpen = () => {
    setIsOpen(true);
  };

  const onCalendarClose = () => {
    setIsOpen(false);
    if (value) {
      setSelectedDate(value);
    }
  };

  const onClickApply = () => {
    if (enableTime && showMeridianFormat) {
      let hour = selectedHours;
      if (selectedMeridiemFormat == "pm") {
        hour += 12;
      }
      onChange(setHours(setMinutes(selectedDate, selectedMinutes), hour));
    } else if (enableTime) {
      onChange(
        setHours(
          setMinutes(setSeconds(selectedDate, defaultSeconds), selectedMinutes),
          selectedHours,
        ),
      );
    } else {
      onChange(selectedDate);
    }
    setIsOpen(false);
  };

  const onChangeSelectedDate = (date) => {
    let updatedDate = date;
    if (date && minDate && isBefore(date, minDate)) {
      updatedDate = minDate;
    } else if (date && maxDate && isAfter(date, maxDate)) {
      setSelectedDate(maxDate);
      updatedDate = maxDate;
    }

    setSelectedDate(updatedDate);

    if (shouldCloseOnSelect || !updatedDate) {
      onChange(updatedDate);
    }
  };

  const yearOptions = useMemo(() => {
    let yearOption = years;
    if (minDate) {
      const minYear = value ? Math.min(getYear(minDate), getYear(value)) : getYear(minDate);
      yearOption = yearOption.filter((y) => minYear <= y.value);
    }

    if (maxDate) {
      const maxYear = getYear(maxDate);
      yearOption = yearOption.filter((y) => maxYear >= y.value);
    }

    return yearOption;
  }, [minDate, maxDate]);

  const renderHeader = ({ date, changeYear, changeMonth }) => {
    return (
      <div className="os-month-year-wrapper os-datepicker-dropdown">
        <Dropdown
          value={getMonth(date)}
          options={months}
          onChange={changeMonth}
          className="os-mr month-dropdown"
          isSearchable={false}
        />
        <Dropdown
          value={getYear(date)}
          options={yearOptions}
          onChange={changeYear}
          isSearchable={false}
          className="year-dropdown"
        />
      </div>
    );
  };

  const hours = useMemo(() => {
    let hourRange = [0, 24, 1];
    if (showMeridianFormat) {
      hourRange = [1, 13, 1];
    }
    return _range(...hourRange).map((count) => {
      const options = {
        label: count,
        value: count,
      };
      if (minDate && !options.isDisabled) {
        options.isDisabled = isBefore(setHours(selectedDate, count), minDate);
      }
      if (maxDate && !options.isDisabled) {
        options.isDisabled = isAfter(setHours(selectedDate, count), maxDate);
      }
      return options;
    });
  }, [minDate, maxDate, selectedDate]);

  const minutes = useMemo(() => {
    const defaultOptions = _range(0, 60, timeIntervals).map((count) => {
      const options = {
        label: count,
        value: count,
      };
      if (minDate) {
        options.isDisabled = isBefore(setMinutes(selectedDate, count), minDate);
      }
      if (maxDate && !options.isDisabled) {
        options.isDisabled = isAfter(setMinutes(selectedDate, count), maxDate);
      }
      return options;
    });

    if (!_isEmpty(extraMinutesOptions || [])) {
      const allOptions = [...defaultOptions];
      extraMinutesOptions.forEach((option) => {
        if (!allOptions.some((opt) => opt.value === option)) {
          allOptions.push({
            label: option,
            value: option,
          });
        }
      });
      return allOptions.sort((a, b) => a.value - b.value);
    }

    return defaultOptions;
  }, [minDate, maxDate, selectedDate, timeIntervals, extraMinutesOptions]);

  const renderTimePicker = () => {
    return (
      <div className="os-hour-mins-wrapper">
        <div className="os-datepicker-dropdown os-time-clock">
          <i className="icon icon-clock-o glyphicon glyphicon-time" />
          <Dropdown
            placeholder="h"
            value={selectedHours}
            options={hours}
            onChange={setSelectedHours}
            isSearchable={false}
            menuPlacement="top"
            containerClassName="pendo-track-time-picker-hour-dropdown"
          />
          <span className="os-mx">:</span>
          <Dropdown
            placeholder="m"
            value={selectedMinutes}
            options={minutes}
            onChange={setSelectedMinutes}
            isSearchable={false}
            menuPlacement="top"
            containerClassName="pendo-track-time-picker-minute-dropdown"
          />
          {showMeridianFormat && (
            <>
              <span className="os-mx">:</span>
              <Dropdown
                placeholder="am"
                value={selectedMeridiemFormat}
                options={meridiemFormat}
                onChange={setSelectedMeridiemFormat}
                isSearchable={false}
                menuPlacement="top"
                containerClassName="pendo-track-time-picker-am-pm-dropdown"
              />
            </>
          )}
        </div>
      </div>
    );
  };

  const isApplyDisabled = enableTime
    ? !selectedDate || _isUndefined(selectedMinutes) || _isUndefined(selectedHours)
    : !selectedDate;

  return (
    <div className={`date-picker-wrapper ${className} ${enableborderbottom ? "borderbottom" : ""}`}>
      <DatePicker
        open={isOpen}
        shouldCloseOnSelect={shouldCloseOnSelect}
        onCalendarClose={onCalendarClose}
        selected={selectedDate}
        onChange={onChangeSelectedDate}
        onInputClick={onCalendarOpen}
        renderCustomHeader={renderHeader}
        popperClassName={`single-date-picker-wrapper ${popperClassName}`}
        customInput={
          <CustomInput
            userValue={value}
            placeHolder={t(placeHolder)}
            timePlaceHolder={t(timePlaceHolder)}
            enableTime={enableTime}
            disabled={disabled}
            disableIcon={disableIcon}
          />
        }
        minDate={minDate}
        maxDate={maxDate}
        disabledKeyboardNavigation
        disabled={disabled}
        {...props}
      >
        {enableTime ? renderTimePicker() : null}
        {showCTAs && (
          <div className="os-btn-wrapper">
            <Button className="pendo-track-time-picker-apply-button" disabled={isApplyDisabled} onClick={onClickApply}>
              Apply
            </Button>
            <Button className="pendo-track-time-picker-cancel-button" type="default" onClick={onCalendarClose}>
              Cancel
            </Button>
          </div>
        )}
      </DatePicker>
    </div>
  );
};

SingleDatePicker.defaultProps = {
  className: "",
  placeHolder: "Select a date",
  timePlaceHolder: "Select a date and time",
  enableborderbottom: false,
  enableTime: true,
  timeIntervals: 15,
  shouldCloseOnSelect: false,
  showCTAs: true,
  disableIcon: false,
  popperClassName: "",
  defaultSeconds: 0,
};

export default SingleDatePicker;
