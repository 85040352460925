import React from "react";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import _isEqual from "lodash/isEqual";
import { withTranslation } from "react-i18next";
import {
  validateDropdownValue,
} from "@onlinesales-ai/util-methods-v2";
import { Dropdown } from "@onlinesales-ai/dropdown-v2";
import WithTooltip from "@onlinesales-ai/tooltip-v2";
import { Text } from "@onlinesales-ai/label-v2";

import FormWrapper from "../../FormWrapper";
import { indianLanguages, yearsTillDate, facebookPageCTAs, gmbPageCategories } from "./config";

class DropdownForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DATASETS: {
        INDIA_LANGUAGES: indianLanguages,
        YEARS_TILL_DATE: yearsTillDate,
        FACEBOOK_PAGE_CTA: facebookPageCTAs,
      },
    };
    this.validate(this.getValue());
  }

  componentDidMount = () => {
    const { dataSetType, setFirstIfEmpty, options } = this.props;
    if (dataSetType === "GMB_PAGE_CATEGORIES") {
      gmbPageCategories().then((res) =>{
        this.setState((prevState) => {
          return {
            DATASETS: {
              ...prevState.DATASETS,
              GMB_PAGE_CATEGORIES: res,
            },
          };
        });
      });
    }

    if (setFirstIfEmpty && options?.length && !this.getValue()) {
      this.onChangeDropdown(options[0].value);
    }
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const newValue = _get(nextProps.formValues, nextProps.dataKey);
    const newErrorValue = _get(nextProps.formErrors, nextProps.dataKey);

    if (nextProps.multiSelect) {
      if (
        !_isEqual(newValue, this.getValue()) ||
        (!newValue && newErrorValue !== this.getError()) ||
        !_isEqual(this.props.validations, nextProps.validations)
      ) {
        this.validate(newValue, nextProps.validations);
      }
    } else {
      if (
        newValue !== this.getValue() ||
        (!newValue && newErrorValue !== this.getError()) ||
        !_isEqual(this.props.validations, nextProps.validations)
      ) {
        this.validate(newValue, nextProps.validations);
      }
    }

    if (nextProps.resetWhenOptionGroupChange) {
      if (!_isEqual(this.getOptionGroupValue(nextProps), this.getOptionGroupValue())) {
        this.onChangeDropdown(undefined);
      }
    }

    if (nextProps.setFirstIfEmpty && !this.props?.options?.length && nextProps.options?.length) {
      this.onChangeDropdown(nextProps.options[0].value);
    }
  };

  getValue = () => {
    const { dataKey, formValues } = this.props;
    return _get(formValues, dataKey);
  };

  getError = () => {
    const { dataKey, formErrors } = this.props;
    return _get(formErrors, dataKey);
  };

  getOptionGroupValue = (props) => {
    const { optionsGroup, formValues } = props || this.props;
    return _get(formValues, optionsGroup?.dataKey);
  };

  onChangeDropdown = (selectedValue) => {
    const { onChange, dataKey } = this.props;

    onChange({ [dataKey]: selectedValue });
  };

  validate = (value, pValidations) => {
    const { onError, dataKey, validations = [], title, errorTitle } = this.props;
    let errorMsg = null;

    const validationsToUse = pValidations || validations;
    const validationValue = validateDropdownValue({
      validations: validationsToUse,
      value,
      title,
      errorTitle,
    });
    errorMsg = validationValue?.errorMsg;
    onError({ [dataKey]: errorMsg });
  };

  render() {
    const {
      dropdownComponent,

      // FormWrapper props
      labelColumns,
      formGroupClassName,
      formGroupInnerClassName,
      guidText,
      guidElement,
      apiError,
      warning,
      title,
      titleTooltip,
      titleGuidText,
      isRequired,
      showEmptyTitle,

      // dropdown props
      t,
      dataKey,
      isEditable,
      showErrors,
      formErrors,
      options,
      placeholder,
      multiSelect,
      clearable,
      dataSetType,
      isLoading,
      disabled,
      isDisabled,
      dropdownType,
      isShowTooltip,
      autoSet,
      isClearable,
      createLabelPrefix,
      optionsGroup,
      className,
      menuPlacement,
      infoTooltip,
      enableborderbottom,
      lastComponent,
      drodownProps,
      defaultPlaceholder,
      labelDefinationKey,
      showTooltiptoNonEdit,
      enableOptionGroup,
      customLabelFunction,
      loadOptions,
      labelClassName,
      isErrorPossible,
      containerClassName,
    } = this.props;

    const value = this.getValue();
    let option = dataSetType && this.state.DATASETS[dataSetType] ? this.state.DATASETS[dataSetType] : options;

    if (optionsGroup?.dataKey) {
      const optionGroupValue = this.getOptionGroupValue();
      option = optionsGroup.options[optionGroupValue] || [];
    }

    const Component = dropdownComponent || Dropdown;

    const renderNonEditText = () => {
      let nonEditValue = defaultPlaceholder || "";

      if (multiSelect && value) {
        nonEditValue = value.join(", ");
      } else if (!multiSelect) {
        const labelValue = option?.find?.((op) => op.value === value)?.label;

        if (labelValue) {
          nonEditValue = labelValue;
        }

        if (!labelValue && value) {
          nonEditValue = value;
        }
      }

      if (customLabelFunction) {
        return customLabelFunction({ nonEditValue });
      }

      return nonEditValue;
    };

    return (
      <FormWrapper
        labelColumns={labelColumns}
        formGroupClassName={formGroupClassName}
        formGroupInnerClassName={formGroupInnerClassName}
        guidText={guidText}
        guidElement={guidElement}
        hasError={showErrors && formErrors[dataKey]}
        error={formErrors[dataKey]}
        apiError={apiError}
        warning={warning}
        title={title}
        titleTooltip={titleTooltip}
        titleGuidText={titleGuidText}
        isEditable={isEditable}
        isRequired={isRequired}
        infoTooltip={infoTooltip}
        labelDefinationKey={labelDefinationKey}
        showEmptyTitle={showEmptyTitle}
        labelClassName={labelClassName}
        isErrorPossible={isErrorPossible}
        containerClassName={containerClassName}
      >
        {isEditable ? (
          <Component
            type={dropdownType}
            isMulti={multiSelect}
            isShowTooltip={isShowTooltip}
            placeholder={t(placeholder)}
            options={option}
            value={value}
            clearable={clearable}
            isLoading={isLoading}
            onChange={this.onChangeDropdown}
            disabled={disabled}
            isDisabled={isDisabled}
            autoSet={autoSet}
            isClearable={isClearable}
            createLabelPrefix={createLabelPrefix}
            className={className}
            menuPlacement={menuPlacement}
            enableborderbottom={enableborderbottom}
            enableOptionGroup={enableOptionGroup}
            loadOptions={loadOptions}
            {...drodownProps}
          />
        ) : (
          <Text showEllipsis={showTooltiptoNonEdit} className="non-edit-mode-text">{renderNonEditText()}</Text>
        )}
        {lastComponent}
      </FormWrapper>
    );
  }
}

DropdownForm.defaultProps = {
  enableborderbottom: false,
  dataKey: null,
  title: "",
  formGroupClassName: "form-component-dropdown",
  formGroupInnerClassName: "",
  validations: [
    {
      type: "nonEmpty",
      msg: "Please select __FIELD_TITLE__",
    },
  ],
  defaultPlaceholder: "-",
  isClearable: false,
  resetWhenOptionGroupChange: true,
  lastComponent: null,
  drodownProps: {},
  isDisabled: false,
  showTooltiptoNonEdit: true,
};

export default withTranslation()(DropdownForm);