export const getManifestFileData = (manifestHref, callback = () => {}) => {
  const xhr = new XMLHttpRequest();
  xhr.open("GET", manifestHref);

  // this is IE10+
  xhr.onload = () => {
    try {
      const data = JSON.parse(xhr.responseText);
      callback(data);
    } catch (err) {}
  };
  xhr.send(null);
};

export const generateManifestForIOS = (manifestUrl) => {
  if (!manifestUrl) {
    return;
  }

  const userAgent = navigator.userAgent || "";
  const isSafari = navigator.vendor && navigator.vendor.indexOf("Apple") !== -1;
  const isSafariMobile = isSafari && userAgent.indexOf("Mobile/") !== -1;

  if (!isSafari) {
    return;
  }

  function push(localName, attr) {
    const node = document.createElement(localName);
    for (const k in attr) {
      node.setAttribute(k, attr[k]);
    }
    document.head.appendChild(node);
    return node;
  }

  function meta(name, content) {
    if (content) {
      if (content === true) {
        content = "yes";
      }
      push("meta", { name, content, class: "apple-mobile-ele" });
    }
  }

  //Remove previous tags
  const appleMobileEle = document.getElementsByClassName("apple-mobile-ele")[0];
  if(appleMobileEle){
    appleMobileEle.remove();
  }

  getManifestFileData(manifestUrl, (manifest = {}) => {
    const appleIconSizeMin = 120;
    meta("mobile-web-app-capable", true);
    meta("apple-mobile-web-app-title", manifest["short_name"] || manifest["name"]);
    meta("apple-mobile-web-app-status-bar-style", "black-translucent");
    const icons = manifest["icons"] || [];
    icons.sort((a, b) => parseInt(b.sizes, 10) - parseInt(a.sizes, 10)); // largest first

    icons.forEach((icon) => {
      // create regular link icons as byproduct
      const attr = {
        rel: "icon",
        href: icon["src"],
        sizes: icon["sizes"],
        class: "apple-mobile-ele",
      };
      push("link", attr);
      if (!isSafariMobile) {
        return;
      }
      const dim = parseInt(icon["sizes"], 10);
      if (dim < appleIconSizeMin) {
        return;
      }
      attr["rel"] = "apple-touch-icon";

      push("link", attr);
    });
  });
};

export const loadManifestFromDomainConfig = (domainConfig = {}) => {
  try {
    if (!domainConfig?.doNotLoadManifest) {
      let manifestUrl = "/manifest/manifest.json";
      if (domainConfig.manifestUrl) {
        manifestUrl = domainConfig.manifestUrl;
      }

      document.querySelector("#app-manifest-link").setAttribute("href", manifestUrl);

      //For IOS
      generateManifestForIOS(manifestUrl);
    } else {
      document.querySelector("#app-manifest-link").setAttribute("href", "");
    }
  }
  catch(error){
    //error
  }
};
