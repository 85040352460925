import React, { useMemo } from "react";
import { JSONPath } from "jsonpath-plus";
import { connect } from "react-redux";
import { Flipclock } from "@onlinesales-ai/flipclock-v2";

const Timer = ({
  timerEpochJSONPath,
  state,
}) => {
  const displayValue = useMemo(() => {
    return JSONPath({
      json: state,
      path: timerEpochJSONPath,
      wrap: false,
    })?.[0];
  }, [state, timerEpochJSONPath]);

  return (
    <Flipclock countdownTime={displayValue} />
  );
};

const mapStateToProps = (state) => {
  return ({
    state,
  });
};

export default connect(mapStateToProps)(Timer);
