import React from "react";
import { connect } from "react-redux";
import { Trans, withTranslation } from "react-i18next";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import _isEqual from "lodash/isEqual";
import classnames from "classnames";

import { GlobalContext } from "@onlinesales-ai/utils-components-v2";

import { DropDownCreatable, components } from "@onlinesales-ai/dropdown-v2";
import { Pill } from "@onlinesales-ai/pill-v2";
import {
  getEmailsFromLocalStorage,
  setEmailsInLocalStorage,
  isEmailValid,
} from "@onlinesales-ai/util-methods-v2";
import Dropdown from "../dropdown";
import "./index.less";

class EmailList extends React.Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);

    this.state = {
      emailOptions: [
        // ...(props.options || []),
      ],
    };

    this.defaultEmails = props?.userInfo?.email ? [{ email: props?.userInfo.email }] : [];
    this.validate(this.getValue());
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const newValue = _get(nextProps.formValues, nextProps.dataKey);

    if (!_isEqual(newValue, this.getValue())) {
      this.validate(newValue);
    }
  };

  componentDidMount() {
    this.setState({
      emailOptions: getEmailsFromLocalStorage(this.defaultEmails),
    });
  }

  getValue = () => {
    const { dataKey, formValues } = this.props;
    return _get(formValues, dataKey);
  };

  validate = (value) => {
    const { onError, dataKey, validations = [], title } = this.props;
    let errorMsg = null;

    for (let i = 0; i < validations.length; i++) {
      if (!validations[i].type || !validations[i].msg) {
        continue;
      }

      switch (validations[i].type) {
        case "nonEmpty":
          {
            if (!value || !value.length) {
              errorMsg = validations[i].msg;
            }
          }
          break;
        default:
          break;
      }

      if (errorMsg) {
        errorMsg = errorMsg.replace("__FIELD_TITLE__", title.toLowerCase());
        break;
      }
    }

    onError({ [dataKey]: errorMsg });
  };

  onEmailSelectionChange = (values = {}) => {
    const { onChange, dataKey, t } = this.props;
    const { showToastMessage } = this.context;
    const existingEmails = this.getValue() || [];
    const newEmail = values[dataKey]?.[0];
    if (newEmail) {
      if (existingEmails.includes(newEmail)) {
        showToastMessage({
          type: "ERROR",
          messageToDisplay: t(`"{{email}}" has already been added`, { email: newEmail }),
          toastDuration: 8000,
        });
        return;
      }

      const selectedEmails = [...existingEmails, newEmail];
      setEmailsInLocalStorage(selectedEmails, this.defaultEmails);

      onChange({
        [dataKey]: selectedEmails,
      });
    }
  };

  validateNewEmailId = (emailId) => {
    const { emailOptions } = this.state;
    const selectedEmails = this.getValue() || [];

    if (emailId) {
      let isValid = isEmailValid(emailId);
      if (isValid) {
        isValid = !emailOptions.some(({ email }) => email === emailId);
      }

      if (isValid) {
        isValid = !selectedEmails.includes(emailId);
      }

      return isValid;
    }

    return false;
  };

  getCreateLabel = (inputValue) => {
    return (
      <span>
        <Trans>
          Add <b>{{ inputValue }}</b>
        </Trans>
        ...
      </span>
    );
  };

  onNewEmailClicked = (email) => {
    const { emailOptions } = this.state;
    const { onChange, dataKey } = this.props;

    const selectedEmails = [...(this.getValue() || []), email];

    this.setState({
      emailOptions: [
        ...emailOptions,
        {
          email,
        },
      ],
    });

    onChange({
      [dataKey]: selectedEmails,
    });
  };

  getOptions = () => {
    const { emailOptions } = this.state;
    const selectedEmails = this.getValue() || [];
    const emailOptionsToShow = [];
    emailOptions.map((emailData) => {
      if (!selectedEmails.includes(emailData.email)) {
        emailOptionsToShow.push({
          value: emailData.email,
          label: emailData.name || emailData.email,
        });
      }
    });
    return emailOptionsToShow;
  };

  onClickRemove = (email) => {
    const { dataKey, onChange } = this.props;
    let selectedEmails = this.getValue();
    const existIndex = selectedEmails.indexOf(email);

    if (existIndex > -1) {
      selectedEmails.splice(existIndex, 1);
    }
    setEmailsInLocalStorage(selectedEmails, this.defaultEmails);

    this.validate(selectedEmails);

    onChange({
      [dataKey]: selectedEmails,
    });
  };

  ValueContainer = ({ children, ...props }) => <components.MultiValueContainer {...props} />;

  render() {
    const { t, showValueInline, formGroupClassName, enableFullWidth, isEditable, ...restProps } =
      this.props;
    const selectedEmails = this.getValue();

    return (
      <Dropdown
        {...restProps}
        dropdownComponent={DropDownCreatable}
        formGroupClassName={classnames(formGroupClassName, {
          "full-width": enableFullWidth,
        })}
        {...(showValueInline ? {} : { onChange: this.onEmailSelectionChange })}
        drodownProps={{
          ...(restProps.drodownProps || {}),
          isValidNewOption: this.validateNewEmailId,
          formatCreateLabel: this.getCreateLabel,
          noOptionsMessage: () => t("Enter valid email to add"),
          onCreateOption: this.onNewEmailClicked,
          ...(showValueInline
            ? {}
            : {
                value: [],
                valueComponent: () => {},
                components: { MultiValueContainer: this.ValueContainer },
              }),
        }}
        options={this.getOptions()}
        isEditable={isEditable}
        guidElement={
          <>
            {!showValueInline && !_isEmpty(selectedEmails) && Array.isArray(selectedEmails) && (
              <Pill.List className="email-list-pill-wrapper">
                {selectedEmails.map((v) => (
                  <Pill
                    key={v}
                    variant="grey"
                    position="right"
                    disabled={!isEditable}
                    onClickRemove={() => {
                      this.onClickRemove(v);
                    }}
                  >
                    <span>{v}</span>
                  </Pill>
                ))}
              </Pill.List>
            )}
          </>
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  const { userInfo } = state.Application || {};

  return {
    userInfo,
  };
};

EmailList.defaultProps = {
  formGroupClassName: "form-component-dropdown multiselect-dropdown email-list-dropdown",
  multiSelect: true,
  dataKey: null,
  title: "Communication Emails",
  labelColumns: 3,
  validations: [
    {
      type: "nonEmpty",
      msg: "Please enter __FIELD_TITLE__",
    },
  ],
  showValueInline: false,
  enableFullWidth: false,
  isEditable: true,
};

export default connect(mapStateToProps, null)(withTranslation()(EmailList));
