import React from "react";
import { Trans } from "react-i18next";
import { Button } from "@onlinesales-ai/button-v2";
import "./index.less";

const UpsellingTemplate = ({ containerClass, redirectTo, title, subtitle, components, cta }) => {
  let upsellingComponentsDOM = [];
  components.forEach((component = {}, index) => {
    upsellingComponentsDOM.push(
      <div className={"upselling-box-even"}>
        <div
          className="upselling-icon"
          onClick={() =>
            redirectTo({
              ...component,
              index: index,
            })
          }
        >
          <div className="upselling-title">
            <img src={component.imageUrl} />
            <div>
              <Trans>{component.text}</Trans>
            </div>
          </div>
          <div className="upselling-desc">
            <Trans>{component.description}</Trans>
          </div>
          {component.cta && (
            <Button type={"dark"} className="launch component-cta-button">
              <Trans>{component.cta}</Trans>
            </Button>
          )}
        </div>
      </div>,
    );
  });

  return (
    <div className={`upselling-template congratulations-badge-template ${containerClass}`}>
      {!!title && (
        <h3>
          <Trans>{title}</Trans>
        </h3>
      )}
      {subtitle && (
        <h5>
          <Trans>{subtitle}</Trans>
        </h5>
      )}
      <div className="upselling-boxes">{upsellingComponentsDOM}</div>
      {!!cta?.url && (
        <Button
          onClick={() => redirectTo({ url: cta?.url })}
          type="default"
          className="announcementCTA"
        >
          <Trans>{cta?.text}</Trans>
        </Button>
      )}
    </div>
  );
};

UpsellingTemplate.defaultProps = {
  redirectTo: () => {},
  containerClass: "",
  components: [],
  cta: {},
  // type: "UPSELL_TEMPLATE",
  // title: "Congratulation on your 200 Order",
  // components: [
  //     {
  //         "text": "DIFM",
  //         "imageUrl": "If want add here",
  //         "description": "optional",
  //         "url": "__APPEND__/drawer/DIFM_SAAS_SELLING?iframeUrl=https%3A%2F%2Fonlinesales.ai%2Fdifm-and-saas-difference%2F"
  //     },
  //     {
  //         "text": "SAAS",
  //         "imageUrl": "If want add here",
  //         "description": "optional"
  //         "url": "__APPEND__/drawer/DIFM_SAAS_SELLING?iframeUrl=https%3A%2F%2Fonlinesales.ai%2Fdifm-and-saas-difference%2F"
  //     }
  // ],
  // cta: {
  //     "text": "Learn More",
  //     "url": "__APPEND__/drawer/DIFM_SAAS_SELLING?iframeUrl=https://www.google.com"
  // }
};

export default UpsellingTemplate;
