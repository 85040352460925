import React from "react";
import { connect } from "react-redux";

import _get from "lodash/get";
import _isEqual from "lodash/isEqual";

import { withTranslation, Trans } from "react-i18next";
import { OSHOCWithUtilities } from "@onlinesales-ai/os-hoc-with-utilities-v2";
import AsyncImage from "@onlinesales-ai/async-image-v2";
import { FormWrapper } from "@onlinesales-ai/form-components-v2";

import { MediaUpload as MediaUploadComponent } from "../index";

import "./index.less";

class MediaUploadForm extends React.Component {
  constructor(props) {
    super(props);

    this.validate(this.getValue());
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const newValue = this.getValue(nextProps);

    if (!_isEqual(newValue, this.getValue())) {
      this.validate(newValue);
    }
  }

  getValue = (props) => {
    const { dataKey, formValues } = props || this.props;
    const value = [];
    dataKey.forEach((key) => {
      value.push(_get(formValues, key, null));
    });
    return value;
  };

  validate = (value) => {
    const { onError, dataKey, validations = [], title } = this.props;
    let errorMsg = null;
    const filteredValue = value?.filter((item) => {
      return !!item;
    });

    for (let i = 0; i < validations.length; i++) {
      if (!validations[i].type || !validations[i].msg) {
        continue;
      }

      switch (validations[i].type) {
        case "min":
          {
            if (filteredValue?.length < validations[i].min) {
              errorMsg = validations[i].msg;
            }
          }
          break;
        case "max":
          {
            if (filteredValue?.length > validations[i].max) {
              errorMsg = validations[i].msg;
            }
          }
          break;
        default:
          break;
      }

      if (errorMsg) {
        errorMsg = errorMsg.replace("__FIELD_TITLE__", title.toLowerCase());
        break;
      }
    }

    onError({ [dataKey]: errorMsg });
  };

  getOverlayDOMNode = (key, value) => {
    const { isEditable } = this.props;
    return (
      <>
        <div className={"media-upload-btn"}>
          {value ? (
            <div className="media-image">
              <AsyncImage imgSrc={value} />
              {
                isEditable &&
                <div
                  className="delete-icon icon icon-trash-o"
                  onClick={() => this.onUploadMedia(key, null)}
                />
              }
            </div>
          ) : (
            <div className="media-form-btn">
              <span className="add-btn icon icon-add-plus" />
            </div>
          )}
        </div>
      </>
    );
  };

  onUploadError = (errorMsg) => {
    this.props.showToastMessage({
      type: "ERROR",
      messageToDisplay: errorMsg,
      actionButtonLabel: null,
      toastDuration: 5000,
    });
  };

  onUploadMedia = (key, url) => {
    const { onChange } = this.props;
    onChange({
      [key]: url,
    });
  };

  render() {
    const {
      // FormWrapper props
      labelColumns,
      formGroupClassName,
      guidText,
      guidElement,
      apiError,
      warning,
      title,
      titleTooltip,
      titleGuidText,

      // tag input props
      dataKey,
      isEditable,
      showErrors,
      formErrors,
      t,
      clientId,
      ...restProps
    } = this.props;

    const value = this.getValue();

    return (
      <FormWrapper
        labelColumns={labelColumns}
        formGroupClassName={formGroupClassName}
        hasError={showErrors && formErrors[dataKey]}
        error={formErrors[dataKey]}
        apiError={apiError}
        guidElement={guidElement}
        guidText={guidText}
        warning={warning}
        title={title}
        titleTooltip={titleTooltip}
        titleGuidText={titleGuidText}
        isEditable={isEditable}
      >
        <div className="media-upload-container">
          {dataKey.map((key, index) => {
            return (
              <MediaUploadComponent
                containerClass={"form-component-wrapper"}
                onUploadError={this.onUploadError}
                overlayDomNode={this.getOverlayDOMNode(key, value[index])}
                clientId={clientId}
                onUpload={(...params) => this.onUploadMedia(key, ...params)}
                accept={"image/*"}
                disabled={!isEditable}
                isValidateSize
                isValidateDimension
                {...restProps}
              />
            );
          })}
        </div>
      </FormWrapper>
    );
  }
}

MediaUploadForm.defaultProps = {
  dataKey: null,
  title: "",
  labelColumns: 4,
  formGroupClassName: "form-component-sku-media-upload",
  validations: [
    {
      type: "min",
      min: 1,
      msg: "Please upload __FIELD_TITLE__",
    },
  ],
};

const mapStateToProps = (state) => {
  const { clientId } = state.Application || {};

  return {
    clientId,
  };
};

export default connect(mapStateToProps, null)(withTranslation()(OSHOCWithUtilities(MediaUploadForm)));
