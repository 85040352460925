export const BILLING_PACKAGE_TYPE = {
  ACTIVATION: "ACTIVATION",
  ADD_ON: "ADD_ON",
};

export const BILLING_STATUS = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
};

export const PAYMENT_METHOD = {
  RAZORPAY: "RAZORPAY",
  PAYU: "PAYU",
  PAYU_372426: "PAYU_372426",
  RAZORPAY_TEST: "RAZORPAY_TEST",
  RAZORPAY_290038: "RAZORPAY_290038",
  RAZORPAY_290039: "RAZORPAY_290039",
  RAZORPAY_506963: "RAZORPAY_506963",
  RAZORPAY_506964: "RAZORPAY_506964",
  RAZORPAY_372976: "RAZORPAY_372976",
  RAZORPAY_372977: "RAZORPAY_372977",
  RAZORPAY_10006294: "RAZORPAY_10006294",
  RAZORPAY_10006292: "RAZORPAY_10006292",
  STRIPE: "STRIPE",
  MIDTRANS_BLIBLI: "MIDTRANS_BLIBLI",
  MIDTRANS_BUKUKAS: "MIDTRANS_BUKUKAS",
  MIDTRANS_BUKUKAS_STAGING: "MIDTRANS_BUKUKAS_STAGING",
  BANK_OPEN: "BANK_OPEN",
  BANK_OPEN_STAGING: "BANK_OPEN_STAGING",
  DIRECT_TRANSFER: "DIRECT_TRANSFER",
  FLUTTERWAVE: "FLUTTERWAVE",
  FLUTTERWAVE_STAGING: "FLUTTERWAVE_STAGING",
  ONLINE: "ONLINE",
  TWOCTWOP_10011728: "TWOCTWOP_10011728",
  TWOCTWOP_10011725: "TWOCTWOP_10011725",
  CUSTOM_ROUTE: "CUSTOM_ROUTE",
};
