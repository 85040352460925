import React, { useMemo } from "react";

import { Downtime } from "@onlinesales-ai/downtime-v2";
import { isChunkError } from "./util";

const SentryErrorCatcher = ({ error, defaultErrorInfo, chunkErrorInfo }) => {
  const errorState = useMemo(() => {
    return {
      hasError: !!error,
      chunkError: isChunkError(error),
    };
  }, [error]);

  if (errorState.chunkError) {
    return <Downtime {...chunkErrorInfo} />;
  }

  if (errorState.hasError) {
    return <Downtime {...defaultErrorInfo} />;
  }

  return null;
};

SentryErrorCatcher.defaultProps = {
  defaultErrorInfo: {
    imgUrl:
      "https://osads.gumlet.io/image/authenticated/s--IbTZA8IH--/v1638855748/product/Group_124861.svg",
    title: "We've Encountered a Internal Error",
    description: "We've reported this error. We are looking into it.",
    containerClass: "",
    appName: "MAVERICK_UI",
  },
  chunkErrorInfo: {
    imgUrl:
      "https://osads.gumlet.io/image/authenticated/s--PQMDQgs7--/product/system_upgraded.svg",
    title: "New version available",
    description:
      "Hey There, we have upgraded our system. Please refresh the app for the seamless experience.",
    containerClass: "",
    appName: "MAVERICK_UI",
  },
};

export default SentryErrorCatcher;
