import _keyBy from "lodash/keyBy";
import _isEmpty from "lodash/isEmpty";

import AppActionTypes from "@onlinesales-ai/app-v2/application/types";

import Types from "./types";

const initialState = {
  postIds: [],
  postData: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AppActionTypes.APP_CLIENT_CHANGE:
      {
        state = {
          ...initialState,
        };
      }
      break;
    case Types.SET_SMM_POSTS_FETCH_DATA:
      {
        const { posts } = action;
        const postIds = [];
        const postData = {};
        posts.forEach((postObj = {}) => {
          const { id } = postObj;
          if (!state.postIds?.includes(id)) {
            postIds.push(id);
          }
          postData[id] = postObj;
        });
        let updatedPostIds = [...state.postIds, ...postIds];
        updatedPostIds = updatedPostIds.sort().reverse();
        // irrespective of sorting given to api from UI sort in desc order
        state = {
          ...state,
          postIds: updatedPostIds,
          postData: {
            ...state.postData,
            ...postData,
          },
        };
      }
      break;
    case Types.UPDATE_SMM_POST_POLL_DATA:
      {
        state = {
          ...state,
          postData: {
            ...state.postData,
            ..._keyBy(action.postData, "id"),
          },
        };
      }
      break;
    case Types.SET_SMM_POSTS_FETCH_ERROR:
      {
        state = {
          ...state,
          smmPostsFetchError: action.error,
        };
      }
      break;
    case Types.SET_SMM_POSTS_FETCH_IN_PROGRESS:
      {
        state = {
          ...state,
          smmPostsFetchInProgress: action.dataFetchInProgress,
        };
      }
      break;
    case Types.SET_SMM_POST_SAVE_IN_PROGRESS:
      {
        state = {
          ...state,
          smmPostSaveInProgress: action.dataPostInProgress,
        };
      }
      break;
    case Types.UPDATE_SMM_POST_SUGGESTION:
      {
        const { id } = action.postData;

        const copyIds = [...state.postIds];

        if (!copyIds.includes(id)) {
          copyIds.unshift(id);
        }

        state = {
          ...state,
          postIds: copyIds,
          postData: {
            ...state.postData,
            [id]: {
              ...(state.postData[id] || {}),
              ...action.postData,
            },
          },
        };
      }
      break;
    case Types.DELETE_SMM_POST:
      {
        const { id } = action;
        const copyIds = [...state.postIds];
        if (copyIds.includes(id)) {
          copyIds.splice(copyIds.indexOf(id), 1);
        }
        const newPostData = { ...state.postData };
        delete newPostData[id];
        state = {
          ...state,
          postIds: copyIds,
          postData: newPostData,
        };
      }
      break;
    case Types.UPDATE_BOOST_DATA:
      {
        if (!_isEmpty(action?.data?.posts)) {
          const newPostData = {
            ...state.postData,
          };
          Object.values(action?.data?.posts).forEach((post) => {
            const { postId } = post?.launchConfig || {};
            if (newPostData[postId]) {
              newPostData[postId] = {
                ...newPostData[postId],
                ...post,
              };
            }
          });

          state = {
            ...state,
            postData: newPostData,
          };
        }
      }
      break;

    default:
      break;
  }

  return state;
};

const boostInitialState = {
  boostCampaginData: {
    isLoading: true,
    errorMsg: false,
    data: {},
  },
  isLoading: true,
  errorMsg: false,
  data: {},
};

const boostReducer = (state = boostInitialState, action) => {
  switch (action.type) {
    case AppActionTypes.APP_CLIENT_CHANGE:
      {
        state = {
          ...initialState,
        };
      }
      break;

    case Types.SET_FETCH_STATE_BOOST_DATA:
      {
        state = {
          ...state,
          isLoading: action.isLoading,
          errorMsg: action.errorMsg,
        };
      }
      break;

    case Types.SET_BOOST_DATA:
      {
        state = {
          ...state,
          data: action.data,
        };
      }
      break;

    case Types.UPDATE_BOOST_DATA:
      {
        const newBoostData = {
          posts: {},
          postIds: [],
          ...(state?.data || {}),
        };

        if (action.data?.postIds?.length) {
          action.data.postIds.forEach((id) => {
            if (!newBoostData.postIds.includes(id)) {
              newBoostData.postIds.unshift(id);
            }

            newBoostData.posts[id] = {
              ...(newBoostData.posts[id] || {}),
              ...(action.data.posts[id] || {}),
            };
          });
        }

        if (action.data.marketingCampaign) {
          newBoostData.marketingCampaign = action.data.marketingCampaign;
        }

        state = {
          ...state,
          data: newBoostData,
        };
      }
      break;

    case Types.SET_FETCH_STATE_BOOST_CAMPAIGN_DATA:
      {
        state = {
          ...state,
          boostCampaginData: {
            ...state.boostCampaginData,
            isLoading: action.isLoading,
            errorMsg: action.errorMsg,
          },
        };
      }
      break;

    case Types.SET_BOOST_CAMPAIGN_DATA:
      {
        state = {
          ...state,
          boostCampaginData: {
            ...state.boostCampaginData,
            data: {
              ...(state.boostCampaginData?.data || {}),
              ...action.data,
            },
          },
        };
      }
      break;

    case Types.DELETE_SMM_POST:
      {
        if (state?.data?.postIds?.length && action?.id) {
          const newPostIds = state.data.postIds.filter((id) => id !== action.id);
          const newPosts = { ...state.data.posts };
          delete newPosts[action.id];

          state = {
            ...state,
            data: {
              ...state.data,
              postIds: newPostIds,
              posts: newPosts,
            },
          };
        }
      }
      break;
    default:
      break;
  }

  return state;
};

export default {
  SMMPosts: reducer,
  SMMBoosts: boostReducer,
};
