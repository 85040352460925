import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Carousel } from "react-bootstrap";
import { populateEvent } from "@onlinesales-ai/util-methods-v2";

import "./CarouselContainer.less";

const fireIntercomEvents = (intercomEventPrefix, action, metaData) => {
  populateEvent(intercomEventPrefix, `${intercomEventPrefix}||CAROUSEL||${action}`, metaData);
};

const CarouselContainer = ({
  intercomEventPrefix,
  containerClassName,
  noDataDOM,
  isIndicatorShow,
  items,
  windowSize,
  mobileWindowSize,
  isMobile,
  interval,
  ...props
}) => {
  const [carouselItems, setCarouselItems] = useState([]);

  const getCarouselItems = () => {
    const carouselItemsWithGrouping = [];

    const windowSizeToConsider = isMobile ? mobileWindowSize || windowSize : windowSize;
    items.forEach((itemDOM, index) => {
      const carouselIndex = Number.parseInt(index / windowSizeToConsider);
      if (!carouselItemsWithGrouping[carouselIndex]) {
        carouselItemsWithGrouping[carouselIndex] = [];
      }

      carouselItemsWithGrouping[carouselIndex].push(itemDOM);
    });

    return carouselItemsWithGrouping;
  };

  useEffect(() => {
    setCarouselItems(getCarouselItems());
  }, [items, isMobile]);

  const handleCarouselSelect = (selectedIndex, e) => {
    if (e) {
      e.stopPropagation();
    }
    fireIntercomEvents(intercomEventPrefix, "CAROUSEL_SELECT", {
      selectedIndex,
      direction: e?.direction,
    });
  };

  return (
    <div className={`os-carousel-wrapper ${containerClassName}`}>
      {items && items.length ? (
        <Carousel
          indicators={isIndicatorShow && carouselItems.length > 1}
          interval={interval}
          controls={carouselItems.length > 1}
          onSelect={handleCarouselSelect}
          prevIcon={<span className="icon icon-angle-left" />}
          nextIcon={<span className="icon icon-angle-right" />}
          {...props}
        >
          {carouselItems.map((carouselItem, index) => {
            return <Carousel.Item key={intercomEventPrefix + index}>{carouselItem}</Carousel.Item>;
          })}
        </Carousel>
      ) : (
        noDataDOM
      )}
    </div>
  );
};

CarouselContainer.defaultProps = {
  windowSize: 3,
  items: [],
  isMobile: false,
  isIndicatorShow: true,
  noDataDOM: <span>No Data Available</span>,
  intercomEventPrefix: "COMMON_CAROUSEL",
  containerClassName: "",
  interval: null,
};

export default withTranslation()(CarouselContainer);
