import BaseClient from "../baseClient";

const servicePrefix = "/kuberService/";

class KuberService extends BaseClient {
  static getHandledErrorMessages() {
    return {
      INVALID_SELLER_CODE: "This option is not available at the moment. Please try again later.",
      AUTO_DEBITED_TODAY: "This option is not available at the moment. Please try again later.",
      INSUFFICIENT_BALANCE: "You do not have sufficient outstanding balance",
      TRANSACTION_NOT_FOUND: "This option is not available at the moment. Please try again later.",
      GLOWROAD_SELLER_NOT_ELIGIBLE_FOR_RECHARGE: "This feature is not live for you account",
      GLOWROAD_SELLER_LAST_PAYOUT_NEGATIVE: "You do not have sufficient outstanding balance",
      TOKEN_FETCH_ERROR: "This option is not available at the moment. Please try again later.",
      CONSECUTIVE_RECHARGE_TIME_LIMIT: "Only 1 recharge allowed in 90 seconds!",
    };
  }

  static getErrorMessages() {
    return {
      default: "Looks like we are facing some internal issue. Please, retry after sometime.",
      UNAUTHORIZED:
        "Sorry, you do not have the necessary permissions to perform this action. Please contact your admin for assistance.",
      AD0000:
        "Sorry, you do not have the necessary permissions to perform this action. Please contact your admin for assistance.",
      INVALID_PARAMETERS_ERROR:
        "It seems some input parameters are not valid. Please update your inputs and try again.",
      ACCESS_DENIED:
        "Sorry, you do not have the necessary permissions to perform this action. Please contact your admin for assistance.",
      ...this.getHandledErrorMessages(),
    };
  }

  static getErrorMessageTitle() {
    const defaultTitles = super.getErrorMessageTitle();

    return {
      ...defaultTitles,
      default: "Internal Error",
    };
  }

  static fetchCampaignTypeWalletBalance(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}balance/tags`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
      options,
    );
  }

  static fetchActiveCampaigns(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}billing/profile/campaigns`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
      options,
    );
  }

  static fetchWalletBalance(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}balance/wallets`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
      options,
    );
  }

  static fetchBillingSettings(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}billing/settings`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
      options,
    );
  }

  static fetchBillingPromotions(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}billing/promotions`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
      options,
    );
  }

  static postCharge(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}payment`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static postWallet(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}billing/wallet`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static chargeExpire(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}billing/charge/expire`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static postAppliedPromotion(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}billing/promotions`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static postTransferWallet(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}billing/wallet/transfer`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static fetchPromotions(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}promotions`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
      options,
    );
  }

  static postPromotions(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}promotions/post`,
        // method: "POST",
        // data: JSON.stringify(payload),
        // test
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
      options,
    );
  }

  static fetchInvoices(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}invoices`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
      options,
    );
  }

  static fetchWalletLogs(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}billing/wallet/logs`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
      options,
    );
  }

  static getSpendingLimitForKonga(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}balance/konga`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
    );
  }

  static getSnapdealCODBalance(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}balance/snapdeal`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
    );
  }

  static getGlowroadCODBalance(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}balance/glowroad`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
    );
  }

  static getAdvertisersManagementData(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}advertisersManangement`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
      options,
    );
  }

  static getPostpaidAuditLog(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}getAuditLog`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
      options,
    );
  }

  static postAdvertiserApprovalData(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}postPaidBilling/approval`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }
}

export default KuberService;
