import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Button } from "@onlinesales-ai/button-v2";
import { Carousel } from "react-bootstrap";
import "./index.less";

const CelebratoryNoteBoard = ({
  imageUrl,
  title1,
  description1,
  title2,
  description2,
  cta,
  cta2,
  redirectTo,
}) => {
  const { t } = useTranslation();

  return (
    <div className={`feature-release-layout-note ${cta?.containClassName || ""}`}>
      {imageUrl && (
        <div className="feature-left first-block">
          <img className="img-responsive" src={imageUrl} />
        </div>
      )}
      <div className="feature-right second-block-half">
        <div className="feedBoxMeta">
          <h3>
            <Trans>{title1}</Trans>
          </h3>
          <p className="feature-description1">
            <Trans>{description1}</Trans>
          </p>
          {title2 && (
            <h3>
              <Trans>{title2}</Trans>
            </h3>
          )}
          {description2 && (
            <p
              className="feature-description2"
              dangerouslySetInnerHTML={{ __html: t(description2) }}
            />
          )}
        </div>
        {(cta?.text || cta2?.text) && (
          <div className="feature-btn-group">
            {cta?.text && (
              <Button
                onClick={redirectTo.bind(this, cta)}
                type="danger"
                className={`feature-cta-new ${cta.className || ""}`}
              >
                {cta.text}
              </Button>
            )}
            {cta2.text && (
              <Button
                onClick={redirectTo.bind(this, cta2)}
                type="danger"
                className={`feature-cta-new default-cta ${cta2.className || ""}`}
              >
                {cta2.text}
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

CelebratoryNoteBoard.defaultProps = {
  cta: {},
  cta2: {},
};

export default CelebratoryNoteBoard;
