// import { useCallback, useEffect, useState } from "react";

import { useHotkeys } from "react-hotkeys-hook";

// const key = {
//   ALT: "ALT",
//   SHIFT: "SHIFT",
// };

// const keyCode = {
//   [key.ALT]: 18,
//   [key.SHIFT]: 16,
// };

// const keyMap = {
//   [key.ALT]: "altKey",
//   [key.SHIFT]: "shiftKey",
// };

// const useKeyboard = ({
//   keyboard, // keyboard key which we have to check ex: "a", "b", "c"
//   onClick, // callback function when given key pressed
//   pressKey = key.ALT,
//   enablePressKey = true,
//   enable = true,
//   actionOnKey = "UP",
// }) => {
//   const [showToast, setShowToast] = useState(false);

//   const doAction = (event) => {
//     if (enablePressKey && !event[keyMap[pressKey]]) {
//       return null;
//     }

//     if (Array.isArray(keyboard) ? keyboard.includes(event.key) : event.key === keyboard) {
//       onClick(event.key);
//     }
//   };

//   const onKeyDown = useCallback((event) => {
//     if (enablePressKey && event.keyCode === keyCode[pressKey]) {
//       setShowToast(true);
//     }

//     if (actionOnKey === "DOWN") {
//       doAction(event);
//     }
//   }, [onClick]);

//   const onKeyUp = useCallback((event) => {
//     if (actionOnKey === "UP") {
//       doAction(event);
//     }

//     if (enablePressKey && event.keyCode === keyCode[pressKey]) {
//       setShowToast(false);
//     }
//   }, [onClick]);

//   const onBlur = useCallback(() => {
//     setShowToast(false);
//   }, []);

//   useEffect(() => {
//     if (enable) {
//       window.addEventListener("keydown", onKeyDown, false);
//       window.addEventListener("keyup", onKeyUp, false);

//       if (enablePressKey) {
//         window.addEventListener("blur", onBlur, false);
//       }
//     }

//     return () => {
//       if (enable) {
//         window.removeEventListener("keydown", onKeyDown);
//         window.removeEventListener("keyup", onKeyUp);

//         if (enablePressKey) {
//           window.removeEventListener("blur", onBlur, false);
//         }
//       }
//     };
//   }, [enable, onKeyDown, onKeyUp]);

//   // this flag will use if we want to show popover near button or shortcut element
//   return {
//     showToast,
//   };
// };

export default useHotkeys;
