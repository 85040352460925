import React, { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { populateEvent } from "@onlinesales-ai/util-methods-v2";
import { Announcement } from "@onlinesales-ai/announcement-v2";
import { Button } from "@onlinesales-ai/button-v2";
import WithTooltip from "@onlinesales-ai/tooltip-v2";

import { getUINotifications, updateSeenUINotifications } from "src/store/notifications";
import { NotificationTypesEnum } from "src/utilities/constants/dashboard";
import { redirectUrl, changeClientIdAction as changeClientIdFunction } from "src/store/application";
import "./index.less";
import { CLIENT_AGGREGATOR_CONFIG } from "@onlinesales-ai/constants-v2";

const AnnouncementBoard = ({
  fetchUINotifications,
  announcements,
  redirectUrlTo,
  clientId,
  userInfo,
  doNotLoadAPIAnnouncements,
  showAnnouncements,
  updateSeenUIAnnouncement,
  ...restProps
}) => {
  useEffect(() => {
    if (showAnnouncements && clientId && !doNotLoadAPIAnnouncements && clientId != CLIENT_AGGREGATOR_CONFIG.value ) {
      const poll = fetchUINotifications(
        { notificationType: NotificationTypesEnum.ANNOUNCEMENT_BOARD, isIncludeSeen: false },
        true,
      );
      poll.startPoll();

      return () => {
        poll.stopPoll();
      };
    }
  }, [clientId, showAnnouncements]);

  const setAsSeen = (announcement) => {
    if(announcement?.metadata?.notificationSource !== "UI") {
      let notificationToPost = JSON.parse(JSON.stringify(announcement));
      notificationToPost.seen = true;
      notificationToPost.message = JSON.stringify(notificationToPost.message);

      if (notificationToPost) {
        populateEvent("ACQUIRE", "ANNOUNCEMENT||ANNOUNCEMENT_SEEN", {
          announcement: notificationToPost,
        });

        updateSeenUIAnnouncement(NotificationTypesEnum.ANNOUNCEMENT_BOARD, [notificationToPost]);
      }
    }
  };

  if (showAnnouncements) {
    return (
      <div className="annoucement-btn-wrapper">
        <Announcement
          announcements={announcements}
          redirectUrlAction={redirectUrlTo}
          setAsSeenCallback={setAsSeen}
          clientId={clientId}
          userInfo={userInfo}
          {...restProps}
        />
      </div>
    );
  } else {
    return <></>;
  }
};

const mapStateToProps = (state) => {
  const { clientId, userInfo } = state.Application;
  const {notifications: announcements} = state.Notifications[NotificationTypesEnum.ANNOUNCEMENT_BOARD] || {};
  const { data: onboardingDetails = {} } = state.OnBoarding || {};
  const {showAnnouncements = false, showAnnouncementsIcon = false, announcementProps = {}} = state.DomainConfig || {};

  // state["Hygiene"] = {hygieneCheckResult:{
  //   CHECK_GOOGLE_ACCOUNT_HYGIENE:{
  //     metadata:{
  //       messageKeys:{
  //         isBudgetExhausted:1
  //       }
  //     }
  //   }
  // }}

  return {
    clientId,
    userInfo,
    announcements,
    onboardingDetails,
    showAnnouncements,
    showAnnouncementsIcon,
    state,
    ...announcementProps
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchUINotifications: getUINotifications,
      updateSeenUIAnnouncement: updateSeenUINotifications,
      redirectUrlTo: redirectUrl,
      changeClientId: changeClientIdFunction,
    },
    dispatch,
  );
};

AnnouncementBoard.defaultProps = {
  onboardingFlagToAnnouncementConfig: {},
  userInfo: {},
  doNotLoadAPIAnnouncements: false
};

export default connect(mapStateToProps, mapDispatchToProps)(AnnouncementBoard);
