import React, { useContext, useMemo } from "react";
import { connect } from "react-redux";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";

import { Button } from "@onlinesales-ai/button-v2";
import { customMergeOS, outletTextReplacer } from "@onlinesales-ai/util-methods-v2";
import PlatformEventManager from "@onlinesales-ai/event-manager-v2";

import "./index.less";

const HelpLink = ({ helpLinkKey, isShow, isMobile, setPopoverFalse, ...customOverrides }) => {
  const { showGlobalHelpLink, helpLinkDefination } = useSelector(
    (state) => state.DomainConfig || {},
  );
  const {
    className = "",
    url,
    label,
    icon,
    onClick,
    doNotShowHelpDescInMobile,
    fragmentIdentifier,
    ...config
  } = useMemo(() => {
    if (helpLinkDefination && helpLinkKey) {
      const defination = helpLinkDefination[helpLinkKey] || {};
      return customMergeOS({}, defination, customOverrides);
    } else {
      return customOverrides;
    }
  }, [helpLinkDefination, helpLinkKey, customOverrides]);
  return showGlobalHelpLink || isShow ? (
    <Button
      link
      className={`help-link-button ${className}`}
      icon={icon}
      onClick={(e) => {
        if (typeof onClick === "function") {
          e.stopPropagation();
          onClick(url, ...config);
        } else {
          PlatformEventManager.emit("OPEN_HELP_DRAWER", { iframeUrl: url, fragmentIdentifier });
        }
        if (typeof setPopoverFalse === "function") {
          setPopoverFalse();
        }
      }}
    >
      {!(isMobile && doNotShowHelpDescInMobile) && (
        <span className="help-link-button-text">
          <Trans
            defaults={label}
            values={{
              outletText: outletTextReplacer("__OUTLET_TEXT__"),
            }}
          />
        </span>
      )}
    </Button>
  ) : (
    <></>
  );
};

const mapStateToProps = (state) => {
  return {
    isMobile: state.Application.isMobile,
  };
};

export default connect(mapStateToProps)(HelpLink);
