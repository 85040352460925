import React, { forwardRef, useEffect, useRef, useState } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HighChartsMore from "highcharts/highcharts-more";
import Treemap from "highcharts/modules/treemap";
import Drilldown from "highcharts/modules/drilldown";
import PatternFill from "highcharts/modules/pattern-fill";
import VariablePie from "highcharts/modules/variable-pie";
import Heatmap from "highcharts/modules/heatmap";

import ChartTooltip from "./chartTooltip";

import "./index.less";

HighChartsMore(Highcharts);
Treemap(Highcharts);
Drilldown(Highcharts);
PatternFill(Highcharts);
VariablePie(Highcharts);
Heatmap(Highcharts);

const HighChartWrapper = ({ options = {}, lang, renderTooltip, chartId, outside, doNotUseUTC, redrawOnChange, tooltipClickable, ...rest }) => {
  const chartRef = useRef();
  const [showCustomTooltip, setShowCustomTooltip] = useState(false);
  useEffect(() => {
    if (lang) {
      Highcharts.setOptions({
        lang,
      });
    }
  }, [lang, doNotUseUTC]);

  useEffect(() => {
    setShowCustomTooltip(true);
  }, []);

  useEffect(() => {
    if (chartRef.current && typeof chartRef.current.chart.redraw === "function" && redrawOnChange) {
      chartRef.current.chart.redraw();
    }
  }, [options]);

  return (
    <>
      <HighchartsReact
        ref={chartRef}
        highcharts={Highcharts}
        constructorType="stockChart"
        options={{
          ...options,
          time: {
            ...(options?.time || {}),
            useUTC : !doNotUseUTC
          }
        }}
        {...rest}
      />
      {renderTooltip && showCustomTooltip && chartRef?.current?.chart ? (
        <ChartTooltip chart={chartRef?.current?.chart} chartId={chartId} outside={outside} clickable={tooltipClickable}>
          {renderTooltip}
        </ChartTooltip>
      ) : null}
    </>
  );
};

HighChartWrapper.defaultProps = {
  doNotUseUTC: false,
  redrawOnChange: false,
};

export default HighChartWrapper;
