import Types from "./types";

const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.APP_CLIENT_CHANGE: {
      state = {
        ...initialState,
      };
    }
    break;
    case Types.LEAD_STATUS_COUNT: {
      const columnName = action?.accessor;
      if (Array.isArray(action?.data?.leadsGroupedData) && columnName) {
        let statusWiseCount = {
          ALL_LEADS: 0,
          statusCount: {
            nullSelectionHandle: 0,
          },
        };
        action?.data?.leadsGroupedData.map((status) => {
          if (status[columnName]) {
            statusWiseCount.statusCount[status[columnName]?.toLowerCase()] = status?.count;
          }
          statusWiseCount.ALL_LEADS += status?.count || 0;
        });
        state = {
          ...state,
          ...statusWiseCount,
        };
      }
    }
    break;
    case Types.LEAD_ALL_TIME_STATUS_COUNT: {
      const columnName = action?.accessor;
      if (Array.isArray(action?.data?.leadsGroupedData) && columnName) {
        let statusWiseCount = {
          ALL_LEADS: 0,
          statusCount: {
            nullSelectionHandle: 0,
          },
        };
        action?.data?.leadsGroupedData.map((status) => {
          if (status[columnName]) {
            statusWiseCount.statusCount[status[columnName]?.toLowerCase()] = status?.count;
          }
          statusWiseCount.ALL_LEADS += status?.count || 0;
        });
        state = {
          ...state,
          allTimeData: {
            ...(state.allTimeData || {}),
            ...statusWiseCount
          }
        };
      }
    }
    break;
    default:
      break;
  }

  return state;
};

export default {
  Lms: reducer,
};
