import React, { useState } from "react";
import { CSSTransition } from 'react-transition-group';

import classnames from "classnames";

import { useNetworkCheck } from "@onlinesales-ai/util-methods-v2";
import { Text } from "@onlinesales-ai/label-v2";

import "./index.less";

const OfflineToast = () => {
  const { isOnline } = useNetworkCheck();
  return (
    <CSSTransition
      in={!isOnline}
      timeout={2000}
      classNames="fade"
      unmountOnExit
    >
      <div
        className={classnames("fixed-top offline-ribbon", {
          offline: !isOnline,
          online: isOnline,
        })}
      >
        <Text
          className="d-center gap-5"
          iconClassName="text-small lh0"
        >
          <Text weight="semiBold">You are offline.</Text>
          <Text>Please check your internet connection!</Text>
        </Text>
      </div>
    </CSSTransition>
  );
};

OfflineToast.defaultProps = {};

export default OfflineToast;
