import React, { useMemo, useEffect } from "react";
import _get from "lodash/get";
import _times from "lodash/times";
import _isEmpty from "lodash/isEmpty";
import _cloneDeep from "lodash/cloneDeep";

import { FormWrapper } from "@onlinesales-ai/form-components-v2";
import { OSHOCWithUtilities } from "@onlinesales-ai/os-hoc-with-utilities-v2";
import { Text } from "@onlinesales-ai/label-v2";

import "./index.less";

const dynamicMultiComponent = ({
  dataKey,
  formValues,
  formErrors,
  onChange,
  onError,
  renderComponent,
  initialComponentValue,
  renderCustomAddComponent,
  title,
  infoMessage,
  validations,
  commonComponentToShow,
  commonComponentConfig,
  minCount,
  maxCount,
  showConfirmationModal,
  resetConfirmationModal,
  showTitle,
  isEditable,
  ...props
}) => {
  const value = useMemo(() => {
    return _get(formValues, dataKey) || [];
  }, [dataKey, formValues]);

  const showRemoveButton = useMemo(() => {
    return value?.length > minCount;
  }, [value, minCount]);

  const showAddComponent = useMemo(() => {
    return value?.length < maxCount;
  }, [value, maxCount]);

  useEffect(() => {
    if (minCount && value.length < minCount) {
      const intialMinComponents = [];

      _times(minCount - (value.length || 0), () =>
        intialMinComponents.push(_cloneDeep(initialComponentValue) || {}),
      );

      onChange({
        [dataKey]: intialMinComponents,
      });
    }
  }, [minCount]);

  const validate = () => {
    let errorMsg = null;

    for (let i = 0; i < validations.length; i++) {
      if (!validations[i].type || !validations[i].msg) {
        continue;
      }

      switch (validations[i].type) {
        case "nonEmpty":
          {
            if (!value?.length) {
              errorMsg = validations[i].msg;
            }
          }
          break;
        case "evenLength":
          {
            if ((value?.length || 0) % 2 !== 0) {
              errorMsg = validations[i].msg;
            }
          }
          break;
        default:
          break;
      }

      if (errorMsg) {
        errorMsg = errorMsg.replace("__FIELD_TITLE__", title.toLowerCase());
        break;
      }
    }

    onError({ [dataKey]: errorMsg });
  };

  useEffect(() => {
    validate();
  }, [value]);

  const onClickAdd = () => {
    onChange({
      [dataKey]: [...value, _cloneDeep(initialComponentValue) || {}],
    });
  };

  const onClickRemove = (index) => {
    let showConfirmation = false;

    commonComponentToShow.forEach((comp) => {
      const component = commonComponentConfig[comp];
      const subDataKey = component?.props?.dataKey;

      const finalDataKey = `${dataKey}[${index}].${subDataKey}`;

      if (!_isEmpty(_get(formValues, finalDataKey))) {
        showConfirmation = true;
      }
    });

    const performDeleteActions = () => {
      onChange({ [dataKey]: value.filter((item, i) => i !== index) });
      onError(
        Object.keys(formErrors).reduce((memo, key) => {
          if (key.includes(`${dataKey}[${index}].`)) {
            memo[key] = null;
          }
          return memo;
        }, {}),
      );

      if (index !== value.length - 1) {
        onError(
          Object.keys(formErrors).reduce((memo, key) => {
            if (key.includes(`${dataKey}[${value.length - 1}].`)) {
              memo[key] = null;
            }
            return memo;
          }, {}),
        );
      }
    };

    if (showConfirmation) {
      showConfirmationModal({
        isShow: true,
        title: "Are you sure you want delete, your data will be lost.",
        rightBtnText: "No",
        actionBtnText: "Yes",
        actionBtnCallback: () => {
          performDeleteActions();
          resetConfirmationModal();
        },
        rightBtnCallback: () => {
          resetConfirmationModal();
        },
      });
    } else {
      performDeleteActions();
    }
  };

  const renderAddComponent = () => {
    return renderCustomAddComponent ? (
      renderCustomAddComponent({ onClickAdd })
    ) : (
      <div
        className={`custom-add-new-wrapper d-center flex-column cursor-pointer ${
          showTitle ? "with-title" : ""
        }`}
        onClick={onClickAdd}
      >
        <div className="d-flex-column-center">
          <div className="add-button d-center mb10">
            <span className="icon-plus-2" />
          </div>
          Add more images
        </div>
      </div>
    );
  };

  const renderCommonCommponent = ({ val, index }) => {
    return (
      <div className="common-comp-wrapper width-flex-1">
        {commonComponentToShow?.map?.((key) => {
          const component = commonComponentConfig[key];

          return renderComponent({
            component,
            overrides: {
              dataKey: `${dataKey}[${index}].${component.props.dataKey}`,
              title: component.props.doNotShowTitle ? "" : `Image ${index + 1}`,
            },
          });
        })}
      </div>
    );
  };

  const renderRemoveButton = ({ index }) => {
    return (
      <div onClick={() => onClickRemove(index)} className="delete-icon">
        <Text size="large" type="primary">
          <span className="icon icon-trash-2 cursor-pointer" />
        </Text>
      </div>
    );
  };

  return (
    <FormWrapper
      title={title}
      dataKey={dataKey}
      formValues={formValues}
      labelColumns={0}
      error={formErrors[dataKey]}
      hasError={props.showErrors && !!formErrors[dataKey]}
      {...props}
    >
      <div className={`dyanamic-upload-wrapper ${isEditable ? "" : "read-only"}`}>
        {infoMessage && (
          <Text className="mb-3" type="primary" size="small">
            {infoMessage}
          </Text>
        )}
        <div className="dyanamic-upload-wrapper-inner d-wrap">
          {value.map((val, index) => {
            return (
              <div className="d-flex-column justify-content-between position-relative" key={index}>
                {showTitle && (
                  <Text weight="semiBold" type="primary" className="pt-1 pb-4 btnheight">
                    Image {index + 1}
                  </Text>
                )}
                {renderCommonCommponent({ val, index })}
                {isEditable && showRemoveButton && renderRemoveButton({ index })}
              </div>
            );
          })}
          {isEditable && showAddComponent && renderAddComponent()}
        </div>
      </div>
    </FormWrapper>
  );
};

dynamicMultiComponent.defaultProps = {
  initialComponentValue: {},
  showTitle: false,
  validations: [],
};

export default OSHOCWithUtilities(dynamicMultiComponent);
