import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { Button } from "@onlinesales-ai/button-v2";
import { Drawer } from "@onlinesales-ai/drawer-v2";
import { FixLayout } from "@onlinesales-ai/fix-layout-v2";
import { ReportingUIService } from "@onlinesales-ai/services-v2";
import { ModalWindow } from "@onlinesales-ai/modal-window-v2";
import { populateEvent } from "@onlinesales-ai/util-methods-v2";
import { GlobalContext } from "@onlinesales-ai/utils-components-v2";

import StripCard from "../stripeCard";

import "./index.less";

const fireIntercomEvents = (action, metaData) => {
  populateEvent(`PAYMENT||CARD_UPDATE||${action}`, metaData);
};

const StripCardUpdate = ({
  onCardUpdate,
  showInDrawer,
  showInModal,
  showToastMessage: pShowToastMessage,
  children,
  platform,
  clientId,
  userInfo,
  updateCardRef,
  apiVersion,
  isPaymentInProgress = false
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const stripeCardRef = useRef({});
  const {showToastMessage: globalShowToastMessage} = useContext(GlobalContext);
  const showToastMessage = pShowToastMessage || globalShowToastMessage;

  const openModal = async () => {
    setIsOpen(true);
    fireIntercomEvents("OPEN_MODAL", { clientId, userInfo });
  };

  const closeModal = () => {
    fireIntercomEvents("CLOSE_MODAL", { clientId, userInfo });
    setIsOpen(false);
  };

  useEffect(() => {
    if (updateCardRef.current) {
      updateCardRef.current.openCardUpdateModal = openModal;
    }
  });

  const onClickSave = async () => {
    if (!stripeCardRef?.current?.getToken) {
      return null;
    }

    fireIntercomEvents("CLICK_UPDATE", { clientId, userInfo });

    setIsLoading(true);
    let token = null;

    try {
      token = await stripeCardRef.current.getToken();
      fireIntercomEvents("TOKEN_GENERATED", { clientId, token, userInfo });
    } catch (err) {
      fireIntercomEvents("TOKEN_GENERATION_FAILED", { clientId, userInfo });

      showToastMessage({
        type: "ERROR",
        messageToDisplay: t(err),
        actionButtonLabel: null,
        toastDuration: 8000,
      });
      setIsLoading(false);
      return null;
    }

    try {
      const payload = {
        clientId,
        userId: userInfo?.id,
        platform,
        cardToken: token,
        email: userInfo?.email,
      };

      const response = await ReportingUIService.postSubscriptionPlans(payload, "TOPUP");

      fireIntercomEvents("CARD_UPDATE_SUCCESSFULLY", { clientId, userInfo, token });

      onCardUpdate(response);
      closeModal();
    } catch (err) {
      fireIntercomEvents("CARD_UPDATE_FAILED", { clientId, userInfo, token, errorMsg: err.errorMsg });

      showToastMessage({
        type: "ERROR",
        messageToDisplay: t(err.errorMsg),
        actionButtonLabel: null,
        toastDuration: 8000,
      });
    }
    setIsLoading(false);
  };

  const renderStripCard = () => {
    return <StripCard stripeCardRef={stripeCardRef} apiVersion={apiVersion} />;
  };

  const renderFooter = () => {
    return (
      <Button disabled={isLoading} isLoading={isLoading} onClick={onClickSave}>
        Save
      </Button>
    );
  };

  const renderDrawer = () => {
    return (
      <Drawer containerClass="payment-drawer" isOpen={isOpen} onClickClose={closeModal}>
        <FixLayout>
          <FixLayout.Body>{renderStripCard()}</FixLayout.Body>
          <FixLayout.Footer>{renderFooter()}</FixLayout.Footer>
        </FixLayout>
      </Drawer>
    );
  };

  const renderInModal = () => {
    return (
      <ModalWindow
        backdrop="static"
        isShow={isOpen}
        onModalDestroy={closeModal}
        containerClass="stripe-card-modal-wrapper"
      >
        <ModalWindow.Body>
          {renderStripCard()}
        </ModalWindow.Body>
        <ModalWindow.Footer>
          {renderFooter()}
          <Button type="primary" link onClick={closeModal}>
            Close
          </Button>
        </ModalWindow.Footer>
      </ModalWindow>
    );
  };

  return (
    <>
      {showInDrawer ? renderDrawer() : null}
      {showInModal ? renderInModal() : null}
      {children({ openCardUpdateModal: openModal, isLoading: isPaymentInProgress || isOpen })}
    </>
  );
};

StripCardUpdate.defaultProps = {
  showInDrawer: false,
  showInModal: true,
  platform: "OS",
  updateCardRef: {},
};

const mapStateToProps = (state) => {
  const { clientId, userInfo } = state.Application || {};

  return {
    clientId,
    userInfo,
  };
};

export default connect(mapStateToProps)(StripCardUpdate);
