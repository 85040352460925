import _get from "lodash/get";
import _isNumber from "lodash/isNumber";
import _isEqual from "lodash/isEqual";

const computeOp = ({ query, values, ...restValues }) => {
  let queryResult = false;
  let valueToCompare = _get(restValues, query.key);
  if (typeof valueToCompare === "undefined") {
    valueToCompare = _get(values, query.key);
  }

  switch (query.op) {
    case "equals":
      queryResult = valueToCompare === query.value;
      break;
    case "notEquals":
      queryResult = !_isEqual(valueToCompare, query.value);
      break;
    case "in":
      queryResult = query.value.includes(valueToCompare);
      break;
    case "notIn":
      queryResult = !query.value.includes(valueToCompare);
      break;
    case "isNotEmpty":
      queryResult =
        typeof valueToCompare !== "undefined" && valueToCompare !== null && valueToCompare !== "";
      break;
    case "greaterThan":
      queryResult = _isNumber(valueToCompare) && valueToCompare > query.value;
      break;
    case "isEmpty":
      queryResult = !valueToCompare;
      break;
    case "contains":
      queryResult = valueToCompare?.includes(query.value);
      break;
    default:
      break;
  }

  return queryResult;
};

// (a && d) || (b && u) || (c && v)
// [[a, d], [b, u], [c, v]]
export const computeQuery = ({ query: orQuery, values, ...restValues }) => {
  return orQuery.some((andQuery) => {
    return andQuery.every((query) => computeOp({ query, values, ...restValues }));
  });
};
