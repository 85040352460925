/* eslint-disable no-shadow */
import { useContext, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _isEmpty from "lodash/isEmpty";

import { routes } from "src/utilities/constants";
import { redirectUrl as redirectUrlAction } from "src/store/application";
import { fetchOnBoardingData as fetchOnBoardingDataAction } from "@onlinesales-ai/client-v2/store/onBoarding";
import { getDefaultRoute, isNullOrUndefined } from "@onlinesales-ai/util-methods-v2";
import {
  fetchPropertySetting as fetchPropertySettingAction,
  setAppLevelDataFetchState as setAppLevelDataFetchStateAction,
} from "@onlinesales-ai/app-v2/application";
import { AccessControlContext } from "@onlinesales-ai/access-control-v2";
import { uiAPIMonitor } from "@onlinesales-ai/error-catcher-v2";

const EntityLevelData = ({
  selectedEntityId,
  selectedEntityType,
  fetchOnBoardingData,
  redirectUrl,
  notAllowedRoutesForClient,
  location,
  onBoardingData,
  fetchPropertySetting,
  appAppLevelData,
  setAppLevelDataFetchState,
  fetchAppLevelData,
  userPermissions,
}) => {
  const { setPermissions } = useContext(AccessControlContext);

  const appLevelActionsEnum = {
    PROPERTY_SETTINGS: fetchPropertySetting,
  };

  const fetchConfigData = async (dataConfig) => {
    if (!dataConfig?.dataToFetch?.length) {
      return Promise.resolve();
    }

    const allActions = dataConfig.dataToFetch.map((key) => {
      const config = dataConfig.config[key];
      const action = appLevelActionsEnum[config.action];
      return action({
        ...config,
        redirectUrl,
      });
    });

    await Promise.all(allActions);
  };

  const onEntityChange = async () => {
    if (selectedEntityId && selectedEntityType) {
      // this will call only once in app level
      if (fetchAppLevelData) {
        try {
          await fetchConfigData(appAppLevelData);
        } catch (err) {
          uiAPIMonitor("SEV2", "DOWNTIME_PAGE_CALLED", {
            calledFrom: "onEntityChange",
            error: err,
            selectedEntityId,
            selectedEntityType,
            appAppLevelData,
          });
          redirectUrl(routes.DOWNTIME.path);
        } finally {
          setAppLevelDataFetchState(false);
        }
      }
      await fetchOnBoardingData({ redirectUrl, routes });
    }
  };

  const checkIfRouteMatch = (routes = []) => {
    let isMatch = false;

    for (let i = 0; i < routes.length; i++) {
      if (routes[i] && location.pathname.includes(routes[i])) {
        isMatch = true;
        break;
      }
    }

    return isMatch;
  };

  useEffect(() => {
    if (
      selectedEntityId &&
      selectedEntityType &&
      location &&
      checkIfRouteMatch(notAllowedRoutesForClient)
    ) {
      redirectUrl(getDefaultRoute() || routes?.DASHBOARD?.path);
    }
  }, [location?.pathname]);

  useEffect(() => {
    // const { finalStatus } = onBoardingData || {};
    const { pathname } = location || {};

    if (selectedEntityId && !isNullOrUndefined(onBoardingData)) {
      // CHECK STATUS HERE IN FUTURE
      if (!_isEmpty(onBoardingData) && pathname.includes(routes.ONBOARDING.path)) {
        redirectUrl(getDefaultRoute() || routes.DASHBOARD?.path);
      }
    }
  }, [onBoardingData, selectedEntityId, location.pathname]);

  useEffect(() => {
    onEntityChange();
  }, [selectedEntityId, selectedEntityType]);

  useEffect(() => {
    setPermissions({
      permissions: userPermissions,
    });
  }, [userPermissions]);

  return null;
};

const mapStateToProps = (state) => {
  const { selectedEntityId, selectedEntityType, userPermissions } = state.EntityApplication;
  const { notAllowedRoutesForClient, appAppLevelData } = state.DomainConfig;
  const { fetchAppLevelData } = state.Application;

  return {
    selectedEntityId,
    selectedEntityType,
    notAllowedRoutesForClient,
    location: state.router?.location || {},
    onBoardingData: state.OnBoarding.data,
    appAppLevelData,
    fetchAppLevelData,
    userPermissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      redirectUrl: redirectUrlAction,
      fetchOnBoardingData: fetchOnBoardingDataAction,
      fetchPropertySetting: fetchPropertySettingAction,
      setAppLevelDataFetchState: setAppLevelDataFetchStateAction,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EntityLevelData);
