import React, { useState, useEffect, useMemo } from "react";
import { Trans } from "react-i18next";
import { OverlayTrigger, Popover } from "react-bootstrap";
import _range from "lodash/range";
import _uniqueId from "lodash/uniqueId";

import "./index.less";

const make2Digit = (num) => {
  if (`${num}`.length === 1) {
    return `0${num}`;
  }

  return num;
};

const TimePicker = ({
  className,
  is24Hr,
  disabled,
  placement,
  time,
  onChange,
  defaultTime,
  minHour,
  minutesGap,
}) => {
  const [hour, setHour] = useState();
  const [minute, setMinute] = useState();
  const [type, setType] = useState("AM");
  const popoverId = useMemo(() => _uniqueId("timePicker_"), []);
  const [togglePopover, setTogglePopver] = useState(false);

  useEffect(() => {
    if (hour !== undefined && minute !== undefined && type) {
      if (is24Hr) {
        onChange(`${make2Digit(hour)}:${make2Digit(minute)}`);
      } else if (type) {
        if (type === "AM" && hour === 12) {
          onChange("00:00");
        } else if (type === "PM" && hour === 12) {
          onChange("12:00");
        } else {
          const valueToChange = `${type === "AM" ? make2Digit(hour) : hour + 12}:${make2Digit(
            minute,
          )}`;
          onChange(valueToChange);
        }
      }
    }
  }, [hour, minute, type]);

  useEffect(() => {
    if (typeof time !== "undefined" || defaultTime) {
      const [newHr, newMin] = (time || defaultTime)?.split?.(":") || [];

      if (newHr && newMin) {
        if (is24Hr) {
          setHour(Number(newHr));
          setMinute(Number(newMin));
        } else {
          const newType = Number(newHr) > 12 ? "PM" : "AM";
          setMinute(Number(newMin));

          if (newHr === "00") {
            setHour(12);
            setType("AM");
          } else if (newHr === "12" && newMin === "00") {
            setHour(12);
            setType("PM");
          } else {
            setHour(newType === "AM" ? Number(newHr) : Number(newHr) - 12);
            setType(newType);
          }
        }
      } else {
        setHour();
        setMinute();
      }
    }
  }, [time]);

  const onClickHour = (hr) => {
    setHour(hr);
    if (!minute) {
      setMinute(0);
    }
  };

  const onClickMinute = (min) => {
    setMinute(min);
    if (!hour) {
      setHour(minHour || 0);
    }
  };

  const getHours = () => {
    return _range(is24Hr ? 0 : 1, (is24Hr ? 23 : 12) + 1).map((num) => {
      const isDisabled = typeof minHour !== "undefined" && minHour > num;

      return (
        <span
          className={`tp-hour-data ${num === hour ? "selected" : ""} ${
            isDisabled ? "disabled" : ""
          }`}
          onClick={() => !isDisabled && onClickHour(num)}
        >
          {make2Digit(num)}
        </span>
      );
    });
  };

  const getMinutes = () => {
    return _range(0, 60, minutesGap).map((num) => {
      return (
        <span
          className={`tp-min-data ${num === minute ? "selected" : ""}`}
          onClick={() => onClickMinute(num)}
        >
          {make2Digit(num)}
        </span>
      );
    });
  };

  const getAmPm = () => {
    return (
      <>
        <span
          onClick={() => {
            setType("AM");
          }}
          className={`${type === "AM" ? "selected tp-am" : ""}`}
        >
          <Trans>AM</Trans>
        </span>
        <span
          onClick={() => {
            setType("PM");
          }}
          className={`${type === "PM" ? "selected tp-pm" : ""}`}
        >
          <Trans>PM</Trans>
        </span>
      </>
    );
  };

  const popover = (
    <Popover id={popoverId}>
      <div className="custom-timepicker-inner">
        <div className="custom-timepicker-ui">
          <div className="custom-timepicker-li">{getHours()}</div>
          <div className="custom-timepicker-li">{getMinutes()}</div>
          {!is24Hr ? <div className="custom-timepicker-li">{getAmPm()}</div> : null}
        </div>
      </div>
    </Popover>
  );

  const renderContent = () => {
    return (
      <div className={`${className || "time-picker-block"} ${disabled ? "disabled" : ""}`} onClick={() => {setTogglePopver(!togglePopover)}}>
        <span className="time-picker-time">
          {hour !== undefined && minute !== undefined && type ? (
            <>
              {is24Hr
                ? `${type === "AM" ? make2Digit(hour) : hour + 12}:${make2Digit(minute)}`
                : `${make2Digit(hour)}:${make2Digit(minute)} ${type}`}
            </>
          ) : (
            <span className="placeholder">Select time</span>
          )}
        </span>
        <span className="icon icon-clock time-picker-icon ml-2" />
      </div>
    );
  };

  if (disabled) {
    return renderContent();
  }

  return (
    <OverlayTrigger trigger="click" overlay={popover} rootClose placement={placement} rootCloseEvent="mousedown">
      {renderContent()}
    </OverlayTrigger>
  );
};

TimePicker.defaultProps = {
  maxHours: 24,
  is24Hr: true,
  placement: "auto",
  minutesGap: 1,
};

export default TimePicker;
