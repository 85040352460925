import React, { useCallback, useEffect, useState } from "react";
import PlatformEventManager from "@onlinesales-ai/event-manager-v2";
import FluffyLinesAndWhatsNext from "../FluffyLinesAndWhatsNext";

const EventWrapper = ({ eventToListen, ...restProps }) => {
  const [isShow, setIsShow] = useState(false);
  const [config, setConfig] = useState({});

  const onEvent = useCallback((config = {}) => {
    setConfig(config);
    setIsShow(true);
  }, []);

  useEffect(() => {
    if (eventToListen) {
      PlatformEventManager.on(eventToListen, onEvent);
    }

    return () => {
      if (eventToListen) {
        PlatformEventManager.off(eventToListen, onEvent);
      }
    };
  }, []);

  if (isShow) {
    return (
      <FluffyLinesAndWhatsNext
        jobDoneCallback={() => setIsShow(false)}
        {...restProps}
        {...config}
      />
    );
  } else {
    return null;
  }
};

export default EventWrapper;
