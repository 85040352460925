import React from "react";
import { Trans } from "react-i18next";

import { Button } from "@onlinesales-ai/button-v2";
import { FixLayout } from "@onlinesales-ai/fix-layout-v2";

import "./index.less";

const IO = ({ msaDocUrl, jobDoneCallback, ctaText, showPartnershipBilling, channelsText }) => {
  const renderBody = () => {
    return (
      <div className="io-agreement-wrapper">
        <div className="io-agreement-header clearfix">
          <img
            alt="logo"
            className="logo"
            src="https://osads.gumlet.io/image/upload/v1611234558/Product_v2/onlinesales-black-logo.png"
          />
          <span className="io-agreement-title">
            <Trans>Subscription Agreement</Trans>
          </span>
        </div>

        <div className="io-agreement-content">
          <Trans>
            This is the subscription agreement between the Company set forth below (“Client”) and
            OnlineSales Inc, registered in the US and a fully owned subsidiary of OSX TechLabs Pvt
            Ltd (“OnlineSales.ai”) effective as of execution of this agreement.
          </Trans>
        </div>

        <div className="io-agreement-details">
          <div className="io-agreement-subscriptions-plans">
            <div className="acquire-io-plans">
              <div className="acquire-io-plans-title">
                <span>
                  <Trans>Acquire (Paid Marketing) Plan Subscription Details</Trans>
                </span>
              </div>
              <div className="acquire-io-plans-content">
                <table className="acquire-io-plans-table">
                  <tbody>
                    <tr>
                      <td className="acquire-io-plans-name">
                        <Trans>Channels</Trans>
                      </td>
                      <td className="acquire-io-plans-description">
                        <Trans>{channelsText}</Trans>
                      </td>
                    </tr>
                    <tr>
                      <td className="acquire-io-plans-name">
                        <Trans>Commercials</Trans>
                      </td>
                      <td className="acquire-io-plans-description">
                        {showPartnershipBilling.ioMessage}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="seven-day-money-back-guarranty" style={{ "margin-top": "25px" }}>
                  <Trans>
                    <strong>No Refunds policy: ​</strong>
                    If you wish to pause your campaigns or Unsubscribe from this solution, we will
                    prorate the remaining balance in your account and hold it as a credit. This
                    credit will be applied towards Campaigns that you may wish to activate anytime
                    over the 30 day period. Sorry, but we will not be able to refund the credits in
                    any case.
                  </Trans>
                </div>
              </div>
            </div>
          </div>
          <div className="io-agreement-msa">
            <Trans>
              By signing this document, you agree to the terms set herein and Master Services
              Agreement as attached here -{" "}
              <strong>
                <a href={msaDocUrl} target="_blank" rel="noreferrer">
                  {msaDocUrl}
                </a>
              </strong>
            </Trans>
          </div>
        </div>
      </div>
    );
  };

  const renderFooter = () => {
    return <Button onClick={jobDoneCallback}>{ctaText}</Button>;
  };

  return (
    <FixLayout>
      <FixLayout.Body>{renderBody()}</FixLayout.Body>
      <FixLayout.Footer>{renderFooter()}</FixLayout.Footer>
    </FixLayout>
  );
};

IO.defaultProps = {
  msaDocUrl: "https://apps.onlinesales.ai/docs/MSA",
  ctaText: "Done",
};

export default IO;
