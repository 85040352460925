import React, { lazy, useEffect, useMemo, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { oAuthCallBack } from "@onlinesales-ai/util-methods-v2";
import { Downtime } from "@onlinesales-ai/downtime-v2";
import { GoogleLoginCallback } from "@onlinesales-ai/login-v2";
import { Router } from "@onlinesales-ai/app-v2/";
import { trackUIDashboardLoad } from "@onlinesales-ai/tracking-reporting-v2";
import { useTimeBasedMonitor, uiAPIMonitor } from "@onlinesales-ai/error-catcher-v2";

import { routes } from "src/utilities/constants";
import {
  redirectUrl as redirectUrlAction,
  changeClientIdAction as changeClientIdFunction,
} from "src/store/application";
import RouteBasedDrawer from "src/pages/routeBasedDrawer";
import RouteBasedModal from "src/pages/routeBasedModal";
import Login from "src/pages/login";
import AppHeader from "src/components/header";
import { postOnboardingData as postOnboardingDataAction } from "src/store/onBoarding";
import RouteBasedPendingAction from "src/pages/routeBasedPendingAction";

import AppAndAuthData from "./AppAndAuthData";
import ClientLevelData from "./ClientLevelData";
import EntityLevelData from "./EntityLevelData";

const AppSideBarWrapper = lazy(() => import("src/components/appSideBarWrapper"));
const DataFetchProcessor = lazy(() => import("src/app/DataFetchProcessorWrapper"));
const NotificationProcessor = lazy(() =>
  import("@onlinesales-ai/app-v2/src/notificationProcessor"),
);

const protectedRoutes = {
  [routes.DASHBOARD.path]: {
    component: lazy(() => import("src/pages/dashboard")),
  },
  [routes.EVENTS.path]: {
    component: lazy(() => import("src/pages/dashboard/monetize/inmarevents.index")),
  },
  [routes.PACKAGES.path]: {
    component: lazy(() => import("src/pages/dashboard/monetize/packages.index")),
  },
  [routes.BOOKINGS.path]: {
    component: lazy(() => import("src/pages/dashboard/monetize/bookings.index")),
  },
  [routes.CAMPAIGNS.path]: {
    component: lazy(() => import("src/pages/dashboard/monetize/campaigns.index")),
  },
  [routes.ONBOARDING.path]: {
    component: lazy(() => import("src/pages/onboarding")),
  },
  [routes.SETTINGS.path]: {
    component: lazy(() => import("src/pages/settings")),
  },
  // [routes.EMAIL_CAMPAIGNS.path]: {
  //   component: lazy(() => import("@onlinesales-ai/acquire-common-v2/hyperlocal/socialGoalsLauncher/socialGoalsList")),
  // },
  [routes.OMS_BOOKINGS.path]: {
    component: lazy(() => import("src/pages/bookings")),
  },
  [routes.OMS_VIDEO_CREATIVE_LIBRARY.path]: {
    component: lazy(() => import("src/pages/omsVideoCreativeLibrary")),
  },
  [routes.OMS_ANALYTICS.path]: {
    component: lazy(() => import("src/pages/omsAnalytics")),
  },
  [routes.REPORTS.path]: {
    component: lazy(() => import("src/pages/scheduleReport")),
  },
};

const openRoutes = {
  [routes.LOGIN.path]: {
    component: Login,
  },
  [routes.FORGOT_PASSWORD.path]: {
    component: lazy(() => import("src/pages/forgotPassword")),
  },
  [routes.CREATE_PASSWORD.path]: {
    component: lazy(() => import("src/pages/createPassword")),
  },
  [routes.DOWNTIME.path]: {
    component: Downtime,
  },
  [routes.TAKEALOT_CALLBACK.path]: {
    component: lazy(() => import("src/callback/takealot")),
  },
  [routes.PURPLLE_CALLBACK.path]: {
    component: lazy(() => import("src/callback/purplle")),
  },
  [routes.PAYU_CALLBACK.path]: {
    component: lazy(() => import("src/callback/payu")),
  },
  [routes.PAYU_PAYMENT_WINDOW_CALLBACK.path]: {
    component: lazy(() =>
      import("@onlinesales-ai/billing-v2/src/paymentCallback/payu/paymentWindow"),
    ),
  },
  [routes.TWOC2P_PAYMENT_WINDOW_CALLBACK.path]: {
    component: lazy(() =>
      import("@onlinesales-ai/billing-v2/src/paymentCallback/2c2p/paymentWindow"),
    ),
  },
  [routes.KONGA_CALLBACK.path]: {
    component: lazy(() => import("src/callback/konga")),
  },
  [routes.PAYTM_CALLBACK.path]: {
    component: lazy(() => import("src/callback/paytm")),
  },
  [routes.PAYTM_BRANDS_CALLBACK.path]: {
    component: lazy(() => import("src/callback/paytm")),
  },
  [routes.SHARAF_MIRAKL_CALLBACK.path]: {
    component: lazy(() => import("src/callback/sharafMirakl")),
  },
  [routes.MRD_CALLBACK.path]: {
    component: lazy(() => import("src/callback/mrd")),
  },
  [routes.OS_TOKEN_AUTH_CALLBACK.path]: {
    component: lazy(() => import("src/callback/OSTokenAuth")),
  },
  [routes.GLOWROAD_CALLBACK.path]: {
    component: lazy(() => import("src/callback/glowroad")),
  },
  [routes.SENHENG_CALLBACK.path]: {
    component: lazy(() => import("src/callback/senheng")),
  },
  [routes.PAYTM_LANDING_PAGE.path]: {
    component: lazy(() => import("src/pages/landingpage/paytm")),
  },
  [routes.PAYTM_BRANDS_LANDING_PAGE.path]: {
    component: lazy(() => import("src/pages/landingpage/paytm")),
  },
  [routes.SHOPMATIC_CALLBACK.path]: {
    component: lazy(() => import("src/callback/shopmatic")),
  },
  [routes.GOOGLE_CALLBACK.path]: {
    component: GoogleLoginCallback,
  },
  [routes.OAUTH_CALLBACK.path]: {
    component: oAuthCallBack,
  },
  [routes.SNAPDEAL_CALLBACK.path]: {
    component: lazy(() => import("src/callback/snapdeal")),
  },
};

const AuthAndClientComp = ({ domainConfig }) => (
  <>
    <AppAndAuthData />
    {domainConfig?.enableEntityHades ? <EntityLevelData /> : <ClientLevelData />}
  </>
);

const Routes = ({
  isOnboardingFetchInProgress,
  clientsFetchInProgress,
  isBillingStatusFetchInProgress,
  clientsFetchError,
  postOnboardingData,
  userFetchError,
  redirectUrl,
  changeClientId,
  enableEntityHades,
  entitiesFetchInProgress,
  entitiesFetchError,
  fetchAppLevelData,
  pathname,
  userInfo,
}) => {
  const isFirstTime = useRef(true);

  const isAppLoading = useMemo(() => {
    if (enableEntityHades) {
      return isOnboardingFetchInProgress || entitiesFetchInProgress || fetchAppLevelData;
    }

    return isOnboardingFetchInProgress || clientsFetchInProgress || isBillingStatusFetchInProgress;
  }, [
    enableEntityHades,
    isOnboardingFetchInProgress,
    entitiesFetchInProgress,
    clientsFetchInProgress,
    isBillingStatusFetchInProgress,
    fetchAppLevelData,
  ]);

  const hasAppError = useMemo(() => {
    if (enableEntityHades) {
      return userFetchError || entitiesFetchError;
    }

    return userFetchError || clientsFetchError;
  }, [enableEntityHades, entitiesFetchError, clientsFetchError, userFetchError]);

  useEffect(() => {
    if (isFirstTime.current && !isAppLoading) {
      try {
        trackUIDashboardLoad();
      } catch (e) {}
      isFirstTime.current = false;
    }
  }, [isAppLoading]);

  useTimeBasedMonitor(
    {
      data: isAppLoading,
      checkCondition: () => !openRoutes?.[pathname] && !!userInfo?.id,
      dataShouldBe: false,
      dependancy: `${pathname}+${userInfo?.id}`,
    },
    10000,
    "SEV2",
    "APP_LEVEL_LOADING",
    {
      isAppLoading,
      enableEntityHades,
      isOnboardingFetchInProgress,
      clientsFetchInProgress,
      isBillingStatusFetchInProgress,
      entitiesFetchInProgress,
      fetchAppLevelData,
    },
  );

  useEffect(() => {
    if (hasAppError) {
      uiAPIMonitor("SEV2", "APP_LEVEL_ERROR", {
        hasAppError,
        enableEntityHades,
        userFetchError,
        clientsFetchError,
        entitiesFetchError,
      });
    }
  }, [hasAppError]);

  return (
    <Router
      components={{
        HEADER: AppHeader,
        AUTH_AND_CLIENT: AuthAndClientComp,
        ROUTE_BASED_DRAWER: RouteBasedDrawer,
        ROUTE_BASED_MODAL: RouteBasedModal,
        SIDEBAR_WRAPPER: AppSideBarWrapper,
        DATA_FETCH_PROCESSOR: DataFetchProcessor,
        NOTIFICATION_PROCESSOR: NotificationProcessor,
        ROUTE_BASED_PENDING_ACTION: RouteBasedPendingAction,
      }}
      routes={routes}
      isAppLoading={isAppLoading}
      hasAppError={hasAppError}
      openRoutes={openRoutes}
      protectedRoutes={protectedRoutes}
      redirectUrl={redirectUrl}
      changeClientId={changeClientId}
      postOnboardingData={postOnboardingData}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    userInfo: state.Application?.userInfo,
    isOnboardingFetchInProgress: state.OnBoarding.isLoading,
    clientsFetchInProgress: state.Application.clientsFetchInProgress,
    clientsFetchError: state.Application.clientsFetchError,
    userFetchError: state.Application.userFetchError,
    isBillingStatusFetchInProgress: state.Application.isBillingStatusFetchInProgress,
    enableEntityHades: state.DomainConfig.enableEntityHades,
    entitiesFetchInProgress: state.EntityApplication.entitiesFetchInProgress,
    entitiesFetchError: state.EntityApplication.entitiesFetchError,
    fetchAppLevelData: state.Application.fetchAppLevelData,
    pathname: state.router?.location?.pathname,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      redirectUrl: redirectUrlAction,
      changeClientId: changeClientIdFunction,
      postOnboardingData: postOnboardingDataAction,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
