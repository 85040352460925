import AppActionTypes from "@onlinesales-ai/app-v2/application/types";

import Types from "./types";

const initialState = {
  isLoading: false,
  fetchError: false,
  definitions: {},
  suggestionIds: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AppActionTypes.APP_CLIENT_CHANGE: {
      state = {
        ...initialState
      };
    }
      break;
    case Types.SET_SUGGESTIONS_IDS:
      {
        const suggestionIds = action.suggestionIds || [];
        state = {
          ...state,
          suggestionIds,
        };
      }
      break;
    case Types.SET_SUGGESTIONS_DEFINITION:
      {
        const definitions = action.definitions || [];
        state = {
          ...state,
          definitions: {
            ...state.definitions,
            ...definitions,
          },
        };
      }
      break;
    case Types.SET_SUGGESTIONS_FETCH_STATE:
      {
        state = {
          ...state,
          isLoading: action.isLoading,
          fetchError: action.fetchError,
        };
      }
      break;
    case Types.SUGGESTION_CHANGE_STATUS:
      {
        const updatedDefinition = state.definitions?.[action.id] || {};
        let newState = { ...state };
        if (updatedDefinition) {
          updatedDefinition.status = action.status;
        }

        if (updatedDefinition?.status === "ACCEPTED") {
          if (newState?.definitions?.[action.id]) {
            delete newState.definitions[action.id];
          }
          newState.suggestionIds = newState.suggestionIds?.filter((_id) => _id !== action.id);
        } else {
          newState = {
            ...newState,
            definitions: {
              ...newState.definitions,
              [action.id]: updatedDefinition,
            },
          };
        }

        state = {
          ...state,
          ...newState,
        };
      }
      break;
    default:
      break;
  }

  return state;
};

export default {
  Suggestions: reducer,
};
