import React, { useContext, useEffect, useMemo, useState, useRef } from "react";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import { Trans, useTranslation } from "react-i18next";

import { Dropdown } from "@onlinesales-ai/dropdown-v2";
import { Pill } from "@onlinesales-ai/pill-v2";
import { GlobalContext } from "@onlinesales-ai/utils-components-v2";
import { useDebounce } from "@onlinesales-ai/util-methods-v2";
import { Button } from "@onlinesales-ai/button-v2";
import { Checkbox } from "@onlinesales-ai/checkbox-v2";

import FormWrapper from "../../FormWrapper";
import "./index.less";

const DropdownAsyncWithBelowPills = (props) => {
  const {
    formValues,
    dataKey,
    onChange,
    defaultPlaceholderTag,
    isEditable,
    isDisabled,
    validations,
    onError,
    title,
    fetchOptionValues: pFetchOptionValues,
    fetchOptions: pFetchOptions,
    getValue,
    fetchOnChange,
    placeholder,
    noOptionMessage,
    drodownProps,
    beforeOnChange,
    getValueDOM,
    isShowCheckbox,
    pillWrapperClass,
  } = props;
  const pillListRef = useRef();
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState(false);
  const [showLess, setShowLess] = useState(false);
  const [showFullBody, setShowFullBody] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [valueOptionFetchState, setValueOptionFetchState] = useState({
    isLoading: true,
    fetchError: false,
  });
  const [valueOptions, setValueOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const { showToastMessage } = useContext(GlobalContext);

  const searchTerm = useDebounce(inputValue, 500);

  const value = useMemo(() => {
    return getValue ? getValue(formValues, dataKey) : _get(formValues, dataKey) || [];
  }, [formValues, dataKey]);

  const validate = () => {
    let errorMsg = null;

    for (let i = 0; i < validations.length; i++) {
      if (!validations[i].type || !validations[i].msg) {
        continue;
      }

      switch (validations[i].type) {
        case "nonEmpty":
          {
            if (_isEmpty(value)) {
              errorMsg = validations[i].msg;
            }
          }
          break;
        default:
          break;
      }

      if (errorMsg) {
        errorMsg = errorMsg.replace("__FIELD_TITLE__", title.toLowerCase());
        break;
      }
    }
    onError({ [dataKey]: errorMsg });
  };

  useEffect(() => {
    validate();

    if (_isEmpty(value)) {
      setValueOptions([]);
    }
  }, [value]);

  const fetchOptions = async () => {
    setIsLoading(true);
    try {
      const newOptions = await pFetchOptions(searchTerm);

      setOptions(newOptions);
    } catch (err) {
      showToastMessage({
        type: "ERROR",
        messageToDisplay: err?.errorMsg,
        actionButtonLabel: null,
        toastDuration: 5000,
      });
    }
    setIsLoading(false);
  };

  const fetchOptionValues = async () => {
    try {
      const newOptions = await pFetchOptionValues(value);

      setValueOptions(newOptions);

      setValueOptionFetchState({
        isLoading: false,
        fetchError: false,
      });
    } catch (err) {
      setValueOptionFetchState({
        isLoading: false,
        fetchError: err.errorMsg,
      });
    }
  };

  useEffect(() => {
    fetchOptions();
  }, [searchTerm, fetchOnChange]);

  useEffect(() => {
    if (value?.length) {
      fetchOptionValues();
    } else {
      setValueOptionFetchState({
        isLoading: false,
        fetchError: false,
      });
    }
  }, []);

  useEffect(() => {
    if (!isLoading && pillListRef.current) {
      if (pillListRef.current.scrollHeight > pillListRef.current.clientHeight) {
        setShowMore(true);
        setShowLess(false);
      } else {
        setShowMore(false);
        setShowLess(false);
      }
    }
  }, [valueOptions, isLoading]);

  const optionsToUse = useMemo(() => {
    const selectedValues = valueOptions.map(({ value: val }) => val);
    return options.filter((op) => !selectedValues?.includes(op.value));
  }, [options, valueOptions]);

  const onClickShowMore = () => {
    setShowFullBody(true);
    setShowMore(false);
    setShowLess(true);
  };
  const onClickShowLess = () => {
    setShowFullBody(false);
    setShowMore(true);
    setShowLess(false);
  };

  const onClickRemove = async (op) => {
    if (typeof beforeOnChange === "function") {
      try {
        await beforeOnChange();
        onChange({ [dataKey]: value.filter((val) => val !== op.value) });
        setValueOptions((currentOptions) => currentOptions.filter((o) => o.value !== op.value));
      } catch (e) {}
    } else {
      onChange({ [dataKey]: value.filter((val) => val !== op.value) });
      setValueOptions((currentOptions) => currentOptions.filter((o) => o.value !== op.value));
    }
  };

  const onChangeValue = (selected) => {
    if (!value.includes(selected.value)) {
      onChange({ [dataKey]: [...value, selected.value] });
      setValueOptions((op) => [...op, selected]);
    } else if (isShowCheckbox) {
      onClickRemove(selected);
    }
  };

  const funcBeforeOnChange = async (params) => {
    if (typeof beforeOnChange === "function") {
      try {
        await beforeOnChange();
        onChangeValue(params);
      } catch (e) {}
    } else {
      onChangeValue(params);
    }
  };

  const getOptionDOM = (option, { context }) => {
    const valuesList = [...value];
    const isSelected = value.includes(option?.value);
    if (isSelected && !valuesList?.includes(option.value)) {
      valuesList.push(option.value);
    }
    if (context === "menu") {
      return (
        <div className="option-with-checkbox">
          <Checkbox
            id={option?.value}
            label={option?.label}
            className="shops-custom-checkbox option-checkbox"
            checked={isSelected}
            onChange={() => {
              // Ignoring this event as it is handled by the react-select iteam click
            }}
            disableLabelClick
          />
          {typeof getValueDOM === "function" && getValueDOM(option)}
        </div>
      );
    }
  };

  const customLabelFunction = () => {
    return (
      <Pill.List
        className={`dropdown-async-pill-wrapper ${pillWrapperClass} ${showFullBody ? "full" : "half"}`}
        listRef={pillListRef}
      >
        {valueOptions.map((op) => {
          return (
            <Pill
              key={op.value}
              variant="grey"
              position="right"
              disabled={isDisabled}
              hidePillCloseClass={!isEditable}
              onClickRemove={() => {
                if (isEditable) {
                  onClickRemove(op);
                }
              }}
            >
              <span>{op.label}</span>
            </Pill>
          );
        })}
        {showMore && (
          <Button link className="see-more-text" onClick={onClickShowMore}>
            <Trans>Show More</Trans>
            <span className="icon icon-angle-down ml-1" />
          </Button>
        )}
        {showLess && (
          <Button link className="show-less-text" onClick={onClickShowLess}>
            <Trans>Show Less</Trans>
            <span className="icon icon-angle-up ml-1" />
          </Button>
        )}
      </Pill.List>
    );
  };

  const noOptionsMessage = useMemo(
    () => (isLoading ? () => t("Loading…") : () => t(noOptionMessage || "Type to search")),
    [isLoading, noOptionMessage],
  );

  const renderDefaultPlaceholderTag = () => {
    if (defaultPlaceholderTag) {
      return (
        <Pill.List className="dropdown-async-pill-wrapper no-margin-on-top mt10">
          <Pill variant="grey" position="right" hidePillCloseClass>
            <span>{t(defaultPlaceholderTag)}</span>
          </Pill>
        </Pill.List>
      );
    }

    return null;
  };

  return (
    <FormWrapper
      {...props}
      guidElement={
        <>
          {!valueOptionFetchState.isLoading && !valueOptionFetchState.fetchError ? (
            <>
              {!_isEmpty(valueOptions) && Array.isArray(valueOptions)
                ? customLabelFunction()
                : renderDefaultPlaceholderTag()}
            </>
          ) : null}
        </>
      }
    >
      {valueOptionFetchState.isLoading ? <div className="animated-bg option-loading" /> : null}
      {valueOptionFetchState.fetchError ? (
        <div className="error-msg">{valueOptionFetchState.fetchError}</div>
      ) : null}
      {!valueOptionFetchState.isLoading && !valueOptionFetchState.fetchError ? (
        <Dropdown
          autoSet={false}
          inputValue={inputValue}
          onInputChange={(val, action) => {
            if (action.action === "input-change") setInputValue(val);
          }}
          options={options}
          enableFullBorder
          noOptionsMessage={noOptionsMessage}
          isLoading={isLoading}
          onChange={funcBeforeOnChange}
          placeholder={t(placeholder)}
          formatOptionLabel={isShowCheckbox && getOptionDOM}
          {...drodownProps}
        />
      ) : null}
    </FormWrapper>
  );
};

DropdownAsyncWithBelowPills.defaultProps = {
  isShowCheckbox: false,
  closeMenuOnSelect: true,
};

export default DropdownAsyncWithBelowPills;
