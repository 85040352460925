export const parseSubscriptionData = (data = {}, appName) => {
  const planData = data?.planData?.data || [];
  let clientSubscriptions = data?.clientSubscriptions?.data || [];
  const appNameFilter = (d) => d.appName === appName;
  clientSubscriptions = clientSubscriptions.filter(appNameFilter);

  return {
    isSubscribed: !!clientSubscriptions?.length,
    clientSubscriptions,
    planData: planData.filter(appNameFilter),
    paymentMode: data.paymentMode,
    cardData: data?.cardInfo?.cardDetails?.[0] || {},
    originaldata: data,
  };
};
