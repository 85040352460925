import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Trans, withTranslation, useTranslation } from "react-i18next";
import { Input } from "@onlinesales-ai/input-v2";
import { OverlayLoader } from "@onlinesales-ai/loader-v2";
import { Button } from "@onlinesales-ai/button-v2";
import { AdCardService, OSBillingService } from "@onlinesales-ai/services-v2";
import { getCurrencyCode, getCurrencyDOM, populateEvent } from "@onlinesales-ai/util-methods-v2";
import { OSHOCWithUtilities } from "@onlinesales-ai/os-hoc-with-utilities-v2";
import PlatformEventManager from "@onlinesales-ai/event-manager-v2";

import "./index.less";

function fireIntercomEvents(action, metaData) {
  populateEvent("BILLING", `BILLING||${action}`, metaData);
}

const CouponCodeComp = ({
  clientId,
  marketplaceClientId,
  userInfo,
  isVerifyCoupon,
  isRedeemCoupon,
  onCouponCodeRemoved,
  onCouponCodeVerified,
  onCouponCodeApplied,
  showToastMessage,
}) => {
  const [isInputVisible, setIsInputVisible] = useState(false);
  const [couponCodeText, setCouponCodeText] = useState("");
  const [couponCodeDetails, setCouponCodeDetails] = useState({});
  const [isVerified, setIsVerified] = useState(false);
  const [isVerifyInProgress, setIsVerifyInProgress] = useState(false);
  const [isRedeemed, setIsRedeemed] = useState(false);
  const [isRedeemInProgress, setIsRedeemInProgress] = useState(false);
  const { t } = useTranslation();

  const removeCouponCode = () => {
    const currentCouponCode = couponCodeText;
    setCouponCodeText("");
    setIsVerified(false);
    onCouponCodeRemoved(currentCouponCode);
  };

  useEffect(() => {
    if (isRedeemed) {
      const timer = setTimeout(() => {
        setCouponCodeText("");
        setCouponCodeDetails({});
        setIsVerified(false);
        setIsRedeemed(false);
        setIsInputVisible(false);
      }, 5000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [isRedeemed]);

  const onCouponCodeVerifyClick = async () => {
    const trackingDetails = {
      code: couponCodeText,
      clientId,
      userId: userInfo?.id,
      email: userInfo?.email,
      contact: userInfo?.contactNo,
    };

    fireIntercomEvents("COUPON_CODE_VERIFICATION_REQUESTED", trackingDetails);

    setIsVerifyInProgress(true);

    try{
      const couponCodeDetail = await getCouponDetails();
      setIsVerified(true);
      setCouponCodeDetails(couponCodeDetail);
      onCouponCodeVerified(couponCodeDetail);
    }
    catch (error) {
      fireIntercomEvents("COUPON_CODE_VERIFICATION_FAILURE", trackingDetails);
      showToastMessage({
        type: "ERROR",
        messageToDisplay: t(error.errorMsg),
        actionButtonLabel: null,
        toastDuration: 8000,
      });
    }
    finally {
      setIsVerifyInProgress(false);
    }
  };

  const getCouponDetails = () => {
    const request = {
      code: couponCodeText,
      clientId: marketplaceClientId,
    };

    return new Promise(async (resolve, reject) => {
      try {
        const response = await AdCardService.fetchCodeDetails(request, "Billing");
        if (response?.qualifiers?.length) {
          reject({
            errorMsg: "This coupon code is already used"
          });
        }
        else{
          resolve({
            code: couponCodeText,
            ...(response?.offers?.[0] || {}),
          });
        }
      }
      catch(error){
        reject(error);
      }
    });
  };

  const onCouponCodeApplyClick = async () => {
    let couponCodeToUse = couponCodeDetails;

    try {
      setIsRedeemInProgress(true);

      if(!couponCodeToUse?.code || !couponCodeToUse?.value){
        couponCodeToUse = await getCouponDetails();
        setCouponCodeDetails(couponCodeToUse);
      }

      const trackingDetails = {
        code: couponCodeToUse?.code,
        amount: couponCodeToUse?.value,
        clientId,
        userId: userInfo?.id,
        email: userInfo?.email,
        contact: userInfo?.contactNo,
      };

      fireIntercomEvents("COUPON_CODE_APPLY_REQUESTED", trackingDetails);

      const request = {
        clientId,
        amount: couponCodeToUse?.value,
        currency: getCurrencyCode(),
        couponCode: couponCodeToUse?.code,
        description: `"${couponCodeToUse?.code}" coupon code  applied by ${
          userInfo?.name || userInfo?.email
        }`,
      };

      await OSBillingService.chargeAmount(request, "Billing");
      setIsRedeemed(true);
      PlatformEventManager.emit("OS_ACC_BALANCE_UPDATE");
      onCouponCodeApplied(couponCodeToUse);
      fireIntercomEvents("COUPON_CODE_APPLY_SUCCESS", trackingDetails);
    } catch (error) {
      fireIntercomEvents("COUPON_CODE_APPLY_FAILURE", {
        code: couponCodeToUse?.code,
        amount: couponCodeToUse?.value,
        clientId,
        userId: userInfo?.id,
        email: userInfo?.email,
        contact: userInfo?.contactNo,
      });
      showToastMessage({
        type: "ERROR",
        messageToDisplay: t(error.errorMsg),
        actionButtonLabel: null,
        toastDuration: 8000,
      });
    } finally {
      setIsRedeemInProgress(false);
    }
  };

  return (
    <div className="coupon-code-container">
      {
        isRedeemInProgress &&
          <OverlayLoader/>
      }
      {!isRedeemed && (
        <a
          href="javascript:void(0)"
          className="have-coupon-text"
          onClick={() => setIsInputVisible(!isInputVisible)}
        >
          <Trans>Got a coupon code?</Trans>
        </a>
      )}
      {isInputVisible && (
        <div className="coupon-code-wrapper">
          {!isRedeemed && (
            <div className="coupon-code-block">
              <div className="coupon-code-tb-container">
                <Input
                  className="coupon-code-tb"
                  placeholder={t("Coupon Code")}
                  disabled={isVerified || isVerifyInProgress || isRedeemInProgress}
                  value={couponCodeText}
                  onChange={setCouponCodeText}
                />
                {isVerified && (
                  <span className="remove-code icon icon-close1" onClick={removeCouponCode}></span>
                )}
              </div>
              {isVerifyCoupon && !isVerified && (
                <Button
                  type="primary" link
                  isLoading={isVerifyInProgress}
                  disabled={!couponCodeText || isVerifyInProgress}
                  onClick={onCouponCodeVerifyClick}
                >
                  Verify
                </Button>
              )}
              {(!isVerifyCoupon || isVerified) && isRedeemCoupon && (
                <Button
                  type="primary" link
                  isLoading={false}
                  disabled={!couponCodeText || isRedeemInProgress}
                  onClick={onCouponCodeApplyClick}
                >
                  Apply Code
                </Button>
              )}
            </div>
          )}
          {isVerified && !isRedeemed && (
            <div className="verified-msg success-msg guide-text">
              <Trans>
                Your coupon is valid, You will receive {getCurrencyDOM()}
                {{ amount: couponCodeDetails.value }}
              </Trans>
            </div>
          )}
          {isRedeemed && (
            <div className="verified-msg success-msg guide-text">
              <Trans>
                Coupon code applied, You have received {getCurrencyDOM()}
                {{ amount: couponCodeDetails.value }}
              </Trans>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

CouponCodeComp.defaultProps = {
  isRedeemCoupon: false,
  isVerifyCoupon: true,
  onCouponCodeVerified: () => {},
  onCouponCodeApplied: () => {},
  onCouponCodeRemoved: () => {},
  showToastMessage: () => {},
};

const mapStateToProps = (state) => {
  return {
    clientId: state.Application.clientId,
    userInfo: state.Application.userInfo || {},
    marketplaceClientId: state.DomainConfig.marketplaceClientId,
  };
};

export default connect(mapStateToProps)(withTranslation()(OSHOCWithUtilities(CouponCodeComp)));
