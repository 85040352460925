import React, { useMemo } from "react";
import _isEqual from "lodash/isEqual";
import { components } from "react-select";
import { useTranslation } from "react-i18next";

import WithTooltip from "@onlinesales-ai/tooltip-v2";

import "./index.less";

export const defaultGetValue = (opts, val, enableOptionGroup) => {
  if (enableOptionGroup) {
    let final;

    for (let i = 0; i < opts.length; i++) {
      const optToFind = opts[i].options.find((o) => _isEqual(o.value, val));
      if (optToFind) {
        final = optToFind;
        break;
      }
    }

    return final;
  }
  return opts?.find((o) => _isEqual(o.value, val));
};

export const defaultGetMultipleValue = (opts, val, enableOptionGroup) => {
  if (enableOptionGroup) {
    const final = [];

    for (let i = 0; i < opts.length; i++) {
      const optToFind = opts[i]?.options?.filter?.((o) => val.includes(o.value));
      if (optToFind?.length) {
        final.push(...optToFind);
      }
    }

    return final;
  }

  return opts.filter((o) => val.includes(o.value));
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <span className="icon-caret-down" />
    </components.DropdownIndicator>
  );
};

const SingleValue = ({ children, ...props }) => {
  return (
    <WithTooltip title={children}>
      <div className="single-tooltip-value-parent">
        <components.SingleValue {...props}>
          <div className="single-tooltip-value ellipsis-text">{children}</div>
        </components.SingleValue>
      </div>
    </WithTooltip>
  );
};

const ClearIndicator = (props) => {
  const {
    children = <span className="icon icon-close1 clear-indicator" />,
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles("clearIndicator", props)}
    >
      <div style={{ margin: "-2px 0px -4px 0px" }}>{children}</div>
    </div>
  );
};

const Dropdown = ({
  containerClassName,
  className,
  dropdownClass,
  value,
  style,
  options = [],
  autoSet,
  isMulti,
  onChange,
  type,
  components,
  enableOptionGroup,
  SelectComponent,
  isDisabled,
  getValue,
  getMultipleValue,
  isTranslateOptions,
  enableborderbottom,
  enableFullBorder,
  isShowTooltip,
  menuPosition,
  menuShouldScrollIntoView,
  ...props
}) => {
  const { t } = useTranslation();
  const optionsToUse = useMemo(() => {
    if (isTranslateOptions) {
      return options?.map((option) => {
        option.label = t(option.label);
        return option;
      });
    }
    return options;
  }, [options]);
  const onChangeValue = (newVal, actionMeta) => {
    if (!onChange) {
      return;
    }

    if (autoSet) {
      if (isMulti) {
        if (newVal) {
          onChange(
            newVal.map((v) => v.value),
            actionMeta,
            newVal,
          );
        } else {
          onChange(newVal, actionMeta, newVal);
        }
      } else {
        onChange(newVal?.value, actionMeta, newVal);
      }
    } else {
      onChange(newVal, actionMeta, newVal);
    }
  };

  let computedValue = value;

  if (autoSet) {
    if (isMulti) {
      computedValue = value ? getMultipleValue(optionsToUse, value, enableOptionGroup) : [];
    } else {
      computedValue = getValue(optionsToUse, value, enableOptionGroup) || null;
    }
  } else if (!computedValue) {
    if (isMulti) {
      computedValue = [];
    } else {
      computedValue = null;
    }
  }

  const customComponents = useMemo(() => {
    const comp = {};

    if (isShowTooltip) {
      comp.SingleValue = SingleValue;
    }

    return comp;
  }, [isShowTooltip]);

  return (
    <div
      className={`os-drodown-wrapper ${enableborderbottom ? "borderbottom" : ""} ${
        enableFullBorder && "fullborder"
      } ${containerClassName} ${isDisabled ? "disabled" : ""}`}
      style={style}
    >
      <SelectComponent
        autosize
        classNamePrefix={`${dropdownClass} Select`}
        className={`os-select-dropdown ${type} ${className}`}
        value={computedValue}
        options={optionsToUse}
        isMulti={isMulti}
        components={{ DropdownIndicator, ...customComponents, ...components, ClearIndicator }}
        onChange={onChangeValue}
        isDisabled={isDisabled}
        isOptionDisabled={(option) => option.disabled}
        menuPosition={menuPosition}
        menuShouldScrollIntoView={menuShouldScrollIntoView}
        noOptionsMessage={() => t("No options")}
        {...props}
      />
    </div>
  );
};

Dropdown.defaultProps = {
  getValue: defaultGetValue,
  getMultipleValue: defaultGetMultipleValue,
  autoSet: true,
  isMulti: false,
  isTranslateOptions: true,
  isShowTooltip: false,
  className: "",
  enableborderbottom: false,
  enableFullBorder: false,
  type: "select-dropdown", //  default | select-dropdown | dropdown
  containerClassName: "",
  dropdownClass: "",
  enableOptionGroup: false,
  menuShouldScrollIntoView: true,
  // menuPlacement="auto" top | bottom can be used to show dropdown menu direction
  // isSearchable={false}
  // menuPosition: "fixed",
};

export default Dropdown;
