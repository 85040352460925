let messageHandler = null;

const whiteListedDomains = ["onlinesales", "o-s.io", "inmar.com", "ia.ooo", "hotstar.com", "pnpiq.co.za", "tving.com", "dhretailmedia.com"];

const isDomainWhiteListed = (domain) => {
  let isWhiteListed = false;

  if (domain) {
    for (let i = 0; i < whiteListedDomains.length; i++) {
      const domainRegEx = RegExp(whiteListedDomains[i], "i");
      if (domainRegEx.test(domain)) {
        isWhiteListed = true;
        break;
      }
    }
  }

  return isWhiteListed;
};

export default {
  open: (url, windowName, pwidth, pheight) => {
    let w = pwidth || 700;
    let h = pheight || 500;
    let dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screen.left;
    let dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screen.top;

    const width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : window.screen.width;
    const height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : window.screen.height;

    const leftPosition = width / 2 - w / 2 + dualScreenLeft;
    const topPosition = height / 2 - h / 2 + dualScreenTop;
    let features =
      "status=no,height=__HEIGHT__,width=__WIDTH__,resizable=yes,left=__LEFT__" +
      ",top=__TOP__,screenX=__LEFT__,screenY=__TOP__" +
      ",toolbar=no,menubar=no,scrollbars=no,location=no,directories=no";
    features = features
      .replace(/__HEIGHT__/, h || 400)
      .replace(/__WIDTH__/, w || 400)
      .replace(/__LEFT__/g, leftPosition)
      .replace(/__TOP__/g, topPosition);
    /* decode the url to remove any HTML encoded characters like &amp; */
    // Open the window.
    const popUpWindow = window.open(decodeURI(url), windowName, features);
    return popUpWindow;
  },
  registerMessageListener: (fnCallback) => {
    if (messageHandler) {
      window.removeEventListener("message", messageHandler);
    }
    messageHandler = (event) => {
      if (event.origin && !isDomainWhiteListed(event.origin)) {
        return;
      }
      fnCallback(event);
    };
    window.addEventListener("message", messageHandler, false);
  },
};
