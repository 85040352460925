import AppActionTypes from "../application/types";

import Types from "./types";

const reducer = (state, action, initialState) => {
  switch (action.type) {
    case AppActionTypes.APP_LOG_OUT:
    case AppActionTypes.APP_CLIENT_CHANGE:
      {
        state = {
          ...initialState,
        };
      }
      break;
    case Types.SET_ONBOARDING_DETAILS:
      {
        state = {
          ...state,
          data: action.data,
        };

        //GA
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          onboardingDetails: {
            key: "ACQUIRE||finalStatus",
            value: state.data?.apps?.ACQUIRE?.finalStatus
          },
          'event': "ONBOARDING_DETAILS_SET"
        });
        Object.keys(state.data?.apps?.ACQUIRE?.states || {}).forEach((key) => {
          window.dataLayer.push({
            onboardingDetails: {
              key: `ACQUIRE||${key}`,
              value: state.data?.apps?.ACQUIRE?.states[key]
            },
            'event': "ONBOARDING_DETAILS_SET"
          });
        });

        Object.keys(state.data?.metadata || {}).forEach((flagName) => {
          window.dataLayer.push({
            onboardingDetails: {
              key: flagName,
              value: state.data?.metadata[flagName]
            },
            'event': "ONBOARDING_DETAILS_SET"
          });
        });

      }
      break;

    case Types.SET_ONBOARDING_FETCH_IN_PROGRESS:
      {
        state = {
          ...state,
          isLoading: action.isLoading,
        };
      }
      break;

    case Types.SET_ONBOARDING_FETCH_ERROR:
      {
        state = {
          ...state,
          fetchError: action.fetchError,
        };
      }
      break;
    default:
      break;
  }

  return state;
};

export default reducer;
