import React from "react";
import { Trans } from "react-i18next";

import "./index.less";

const ICON_BY_TYPE = {
  ERROR:
    "https://osads.gumlet.io/image/upload/v1651653053/product/warning-alert-icon.svg",
  WARNING:
    "https://osads.gumlet.io/image/upload/v1666006241/product/yellow-warning-icon.svg",
};

const WithIconHeader = ({ discription, onClose, triggerOnClick, title, type }) => {
  return (
    <div className="with-header-icon-wrapper d-align-center">
      {triggerOnClick ? (
        <span onClick={onClose} className="icon icon-close1 close-btn" />
      ) : null}
      <div className="alert-icon">
        <img src={ICON_BY_TYPE[type]} className="ing-fulid" alt="warning alert" />
      </div>
      <div className="alert-caption">
        <h5 className="heading">
          <Trans>{title}</Trans>
        </h5>
        <span className="subheading">
          <Trans>{discription}</Trans>
        </span>
      </div>
    </div>
  );
};

export default WithIconHeader;
