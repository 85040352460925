import React, { useEffect, useMemo, forwardRef } from "react";
import _get from "lodash/get";

import { InputText } from "@onlinesales-ai/form-components-v2";
import "./index.less";

const PasswordWithTick = forwardRef(({
  dataKey,
  formErrors,
  enableFocusToolTip,
  ...props
}, ref) => {
  const successTick = useMemo(() => {
    return !_get(formErrors, dataKey);
  }, [formErrors]);

  return (
    <InputText
      ref={ref}
      dataKey={dataKey}
      formErrors={formErrors}
      {...props}
      suffix={successTick ? <div className="icon-check-circle valid-password" /> : ""}
      enableFocusToolTip={enableFocusToolTip}
      isShowInfoIcon={false}
    />
  );
});

export default PasswordWithTick;
