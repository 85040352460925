import React from "react";
import { DropdownVirtualized } from "@onlinesales-ai/dropdown-v2";
import DropdownForm from "../dropdown";

const DropdownVirtualizedForm = (props) => {
  return (
    <DropdownForm
      dropdownComponent={DropdownVirtualized}
      {...props}
    />
  );
};

export default DropdownVirtualizedForm;
