import React, { useEffect } from "react";

import { useLocation } from "react-router-dom";
import { Dropdown } from "@onlinesales-ai/form-components-v2";

const Type = ({ onChange, dataKey, options, ...props }) => {
  const location = useLocation();

  useEffect(() => {
    if (options && location?.pathname) {
      const op = options.find((o) => {
        if (o?.routes?.length) {
          return o.routes.some((route) => location.pathname.includes(route));
        }
        return false;
      });

      if (op) {
        onChange({
          [dataKey]: op.value,
        });
      }
    }
  }, []);

  return <Dropdown onChange={onChange} dataKey={dataKey} options={options} {...props} />;
};

export default Type;
