import AppActionTypes from "@onlinesales-ai/app-v2/application/types";

import Types from "./types";

const initialState = {
  header: {
    isLoading: false,
    notifications: [],
    lastFetchedTime: null,
    firstFetchedTime: null,
    newNotificationsCount: 0,
  },
  annoucementBoard: {
    isLoading: false,
    notifications: [],
    lastFetchedTime: null,
    firstFetchedTime: null,
    newNotificationsCount: 0,
  },
};

const getUnseenNotifications = (notifications) => {
  let count = 0;
  if (Array.isArray(notifications)) {
    notifications.forEach((notObj) => {
      if (!notObj.seen) {
        count += 1;
      }
    });
  }
  return count;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AppActionTypes.APP_CLIENT_CHANGE: {
      state = {
        ...initialState
      };
    }
      break;
    case Types.RESET_UI_NOTIFICATION:
      {
        state = {
          ...state,
          [action.notificationType]: action.data,
        };
      }
      break;
    case Types.SET_UI_NOTIFICATION:
      {
        const all = [
          ...(state[[action.notificationType]]?.notifications || []),
          ...action.notifications,
        ];

        state = {
          ...state,
          [action.notificationType]: {
            ...state[[action.notificationType]],
            notifications: all,
            newNotificationsCount: getUnseenNotifications(all),
          },
        };
      }
      break;
    case Types.NOTIFICATIONS_IS_LOADING:
      {
        state = {
          ...state,
          [action.notificationType]: {
            ...state[[action.notificationType]],
            isLoading: action.isLoading,
          },
        };
      }
      break;
    case Types.SET_FIRST_FETCHED_TIME:
      {
        state = {
          ...state,
          [action.notificationType]: {
            ...state[[action.notificationType]],
            firstFetchedTime: action.time,
          },
        };
      }
      break;
    case Types.SET_LAST_FETCHED_TIME:
      {
        state = {
          ...state,
          [action.notificationType]: {
            ...state[[action.notificationType]],
            lastFetchedTime: action.time,
          },
        };
      }
      break;
    case Types.UPDATE_UI_NOTIFICATION:
      {
        const all = state[[action.notificationType]]?.notifications || [];

        if (action.notificationIds.length) {
          all.forEach((notification) => {
            if (action.notificationIds.includes(notification.id)) {
              notification.seen = true;
            }
          });
        }

        state = {
          ...state,
          [action.notificationType]: {
            ...state[[action.notificationType]],
            notifications: all,
            newNotificationsCount: getUnseenNotifications(all),
          },
        };
      }
      break;
    default:
      break;
  }

  return state;
};

export default {
  Notifications: reducer,
};
