import React, { useEffect, useState } from "react";

import StepsContext from "./StepsContext";

import "./index.less";

const Steps = ({
  children,
  initActiveIndex = 1,
  activeIndex,
  onChangeActive,
  showStepNumber,
  totalSteps: pTotalSteps,
  className,
}) => {
  const [currentIndex, setCurrentIndex] = useState(initActiveIndex);

  const totalSteps = pTotalSteps || React.Children.count(children);

  useEffect(() => {
    if (typeof activeIndex !== "undefined") {
      setCurrentIndex(activeIndex);
    }
  }, [activeIndex]);

  const onChangeIndex = (newIndex) => {
    if (typeof activeIndex === "undefined") {
      setCurrentIndex(newIndex);
    }

    if (onChangeActive) {
      onChangeActive(newIndex);
    }
  };

  return (
    <StepsContext.Provider
      value={{
        currentIndex,
        onChangeIndex,
        totalSteps,
      }}
    >
      <div className={`steps-wrapper ${className}`}>
        {React.Children.map(children, (child, index) => {
          return React.cloneElement(child, {
            stepNumber: index + 1,
            isLastStep: index === totalSteps - 1,
            showStepNumber,
            ...child?.props,
          });
        })}
      </div>
    </StepsContext.Provider>
  );
};

Steps.defaultProps = {
  showStepNumber: true,
};

export default Steps;
