import React, { useEffect } from "react";
import { withTranslation, Trans } from "react-i18next";

import { loaderWhite } from "@onlinesales-ai/loader-v2";
import { populateEvent } from "@onlinesales-ai/util-methods-v2";
import { ModalWindow } from "@onlinesales-ai/modal-window-v2";

import "./index.less";

function fireIntercomEvents(action, metaData) {
  populateEvent("DASHBOARD", `DASHBOARD||${action}`, metaData);
}

const ExternalTopup = ({ isShow = true, topupUrl, onRedirect, targetType = "_blank" }) => {
  useEffect(() => {
    if (isShow) {
      fireIntercomEvents("EXTERNAL_TOPUP_CLICKED");

      setTimeout(() => {
        if (topupUrl) {
          fireIntercomEvents("EXTERNAL_TOPUP_REDIRECTED");
          try {
            window.open(topupUrl, targetType);
            onRedirect();
          } catch (err) {}
        }
      }, 2000);
    }
  }, [isShow]);

  return (
    <ModalWindow isShow={isShow} containerClass="download-modal-container" headerTitle="">
      <ModalWindow.Body>
        <div className="redirect-wrapper text-center">
          {loaderWhite()}
          <Trans>Redirecting to payment page</Trans>
        </div>
      </ModalWindow.Body>
    </ModalWindow>
  );
};

export default withTranslation()(ExternalTopup);
