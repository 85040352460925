import { sub, differenceInMilliseconds } from "date-fns/esm";
import _isEmpty from "lodash/isEmpty";
import _groupBy from "lodash/groupBy";

import { SuggestionsService } from "@onlinesales-ai/services-v2";

import Types from "./types";

export const setSuggestionsIds = (suggestionIds) => ({
  type: Types.SET_SUGGESTIONS_IDS,
  suggestionIds,
});

export const setSuggestionDefinitions = (definitions) => ({
  type: Types.SET_SUGGESTIONS_DEFINITION,
  definitions,
});

export const setSuggestionsFetchState = (isLoading, fetchError) => ({
  type: Types.SET_SUGGESTIONS_FETCH_STATE,
  isLoading,
  fetchError,
});

const suggestionActed = (suggestionId) => {
  return {
    type: Types.SUGGESTION_ACTED,
    suggestionId,
  };
};

const suggestionChangeStatus = (id, status) => {
  return {
    type: Types.SUGGESTION_CHANGE_STATUS,
    id,
    status,
  };
};

const getSuggestionsRequest = (config) => {
  const currentDate = new Date();
  const lastMonthDate = new Date();

  lastMonthDate.setMonth(lastMonthDate.getMonth() - 2);
  lastMonthDate.setHours(0, 0, 0);

  const request = {
    clientId: config.clientId,
    filter: {},
    ordering: {
      orderBy: config.orderBy || "created_date",
      order: config.order || "DESCENDING",
    },
  };

  if (config.updatedDate !== null) {
    // set updatedDate to null from config to remove updatedDate filter
    request.filter.updatedDate = config.updatedDate || {
      from: lastMonthDate.getTime(),
      to: currentDate.getTime(),
    };
  }

  if (config.updatedDataFrom) {
    request.filter.updatedDate = config.updatedDate || {
      from: sub(currentDate, config.updatedDataFrom).valueOf(),
      to: currentDate.getTime(),
    };
  }

  if (config.marketingCampaignIds) {
    request.filter.marketingCampaignIds = config.marketingCampaignIds || [];
  }

  if (config.probabilities) {
    request.filter.probabilities = config.probabilities || [];
  }

  if (config.suggestionTypes) {
    request.filter.suggestionTypes = config.suggestionTypes || [];
  }

  if (config.subChannels) {
    request.filter.subChannels = config.subChannels || [];
  }

  if (config.status) {
    request.filter.status = config.status || [];
  }

  if (config.showExpired) {
    if (_isEmpty(config.status)) {
      if (!config.showActive) {
        request.filter.isExpired = config.showExpired;
        request.ordering.orderBy = "expiration_date";
      } else if (config.showActive) {
        request.filter.status = ["SEEN", "UNSEEN"];
      }
    } else if (config.showActive) {
      request.filter.status = [...request.filter.status, "SEEN", "UNSEEN"];
    } else {
      request.filter.isExpired = config.showExpired;
    }
  } else if (!_isEmpty(config.status)) {
    if (config.showActive) {
      request.filter.isExpired = false;
    }
  } else {
    request.filter.isExpired = false;
  }
  return request;
};

const filterSuggestionByAttribute = (rollupSuggestions, attributeTypes) => {
  return rollupSuggestions.filter((suggestion) => {
    const attributes = attributeTypes?.[suggestion?.metaData?.suggestionType] || [];
    return attributes.length === 0 || attributes.includes(suggestion?.metaData?.attribute);
  });
};

export const fetchSuggestionsByClientId = (config = {}) => {
  return async (dispatch, getState) => {
    const state = getState();

    const request = getSuggestionsRequest({
      ...config,
      clientId: state.Application.clientId,
    });

    dispatch(setSuggestionsFetchState(true, false));

    try {
      const response = await SuggestionsService.fetch(request, "suggestions");

      let suggestions = [];

      if (Array.isArray(response?.rollUpSuggestions) && response.rollUpSuggestions.length) {
        const rollupSuggestions = response.rollUpSuggestions || [];

        // remove expired suggestions
        suggestions = rollupSuggestions.filter((suggestion) => {
          const diff = differenceInMilliseconds(new Date(), new Date(suggestion.expirationDate));
          if (["UNSEEN", "SEEN"].includes(suggestion.status)) {
            return diff < 0;
          }
          return true;
        });

        // Filter by attribute types
        if (!_isEmpty(config.attributeTypes)) {
          suggestions = filterSuggestionByAttribute(suggestions, config.attributeTypes);
        }

        if (config.statusSortOrder) {
          const groupedData = _groupBy(suggestions, "status");
          suggestions = [];
          config.statusSortOrder.forEach((status) => {
            if (groupedData[status]) {
              suggestions.push(...groupedData[status]);
            }
          });
        }
      }
      const suggestionIds = [];
      const suggestionDefinitions = {};

      suggestions.forEach((suggestionObj = {}) => {
        const { _id } = suggestionObj;
        if (_id) {
          suggestionIds.push(_id);
          suggestionDefinitions[_id] = suggestionObj;
        }
      });
      dispatch(setSuggestionDefinitions(suggestionDefinitions));
      dispatch(setSuggestionsIds(suggestionIds));
      dispatch(setSuggestionsFetchState(false, false));

      return suggestions;
    } catch (err) {
      dispatch(setSuggestionsFetchState(false, err.errorMsg));

      return Promise.reject(err);
    }
  };
};

export const takeActionOnSuggestion = (suggestionId, status) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { clientId, userInfo } = state.Application;

    const request = {
      userId: userInfo.id || 4662,
      clientId,
      rollUpSuggestion: {
        _id: suggestionId,
        clientId,
        status,
      },
    };

    try {
      await SuggestionsService.takeAction(request, "suggestions");

      dispatch(suggestionChangeStatus(suggestionId, status));
      dispatch(suggestionActed(suggestionId));
    } catch (err) {
      return Promise.reject(err);
    }
  };
};
