import React, { useEffect, useRef } from "react";

import WithTooltip from "@onlinesales-ai/tooltip-v2";
import { Text, Label } from "@onlinesales-ai/label-v2";

import "./index.less";

const Checkbox = ({
  className,
  onChange,
  inputClass,
  labelClass,
  label,
  id,
  children,
  tooltip,
  labelDefinationKey,
  disableLabelClick,
  labelProps,
  indeterminate,
  ...props
}) => {
  const checkboxInputRef = useRef();
  useEffect(() => {
    if (checkboxInputRef.current) {
      checkboxInputRef.current.indeterminate = indeterminate;
    }
  }, [checkboxInputRef, indeterminate]);

  const handleChange = (event) => {
    if (onChange) {
      onChange(event.target.checked, event);
    }
  };
  return (
    <WithTooltip title={tooltip}>
      <div className={`checkbox-wrapper ${className || ""} ${props.checked ? "selected" : ""} ${props.disabled ? "disabled" : ""}`}>
        {children ? (
          children({
            id,
            inputClass,
            label,
            labelClass,
            checkboxInputRef,
            ...props,
            onChange: handleChange,
          })
        ) : (
          <>
            <input
              id={`checkbox-${id}`}
              type="checkbox"
              className={`os-custom-checkbox ${inputClass}`}
              {...props}
              onChange={handleChange}
              ref={checkboxInputRef}
            />
            <Text
              className={`os-custom-checkbox-label ${labelClass}`}
              tagProps={{
                htmlFor: disableLabelClick ? false : `checkbox-${id}`,
              }}
            >
              {label || ""}
            </Text>
            {(labelDefinationKey || labelProps) && <Label parentTooltip={tooltip} {...labelProps} labelDefinationKey={labelDefinationKey} />}
          </>
        )}
      </div>
    </WithTooltip>
  );
};

Checkbox.defaultProps = {
  name: "",
  label: "",
  inputClass: "",
  labelClass: "",
};

export default Checkbox;
