import { useRef, useEffect, useState, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import _debounce from "lodash/debounce";
import _isEqual from "lodash/isEqual";

export const useOnClickOutside = (handler, containerRef) => {
  const ref = useRef(null);
  const listener = (event) => {
    if (
      !ref.current ||
      ref.current.contains(event.target) ||
      containerRef?.current?.contains(event.target)
    ) {
      return;
    }
    handler(event);
  };

  useEffect(() => {
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler, containerRef]);
  return ref;
};

export const useSticky = (enabled, parentDivId) => {
  const [isSticky, setSticky] = useState(false);
  const eleRef = useRef(null);

  const handleScroll = () => {
    const parentTop = document.getElementById(parentDivId).getBoundingClientRect().top;

    return parentTop > eleRef.current.getBoundingClientRect().bottom
      ? setSticky(true)
      : setSticky(false);
  };

  useEffect(() => {
    if (enabled) {
      const debounceScroll = _debounce(handleScroll, 0);
      const parent = document.getElementById(parentDivId);
      parent.addEventListener("scroll", debounceScroll);
      return () => {
        parent.removeEventListener("scroll", debounceScroll);
      };
    }
  }, []);

  return { isSticky, eleRef };
};

export const useMediaQuery = (mediaQuery) => {
  if (!window?.matchMedia) {
    return null;
  }

  const [isVerified, setIsVerified] = useState(!!window.matchMedia(mediaQuery).matches);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQuery);
    const documentChangeHandler = () => setIsVerified(!!mediaQueryList.matches);

    try {
      mediaQueryList.addEventListener("change", documentChangeHandler);
    } catch (e) {
      // Safari isn't supporting mediaQueryList.addEventListener
      console.error(e);
      mediaQueryList.addListener(documentChangeHandler);
    }

    documentChangeHandler();
    return () => {
      try {
        mediaQueryList.removeEventListener("change", documentChangeHandler);
      } catch (e) {
        // Safari isn't supporting mediaQueryList.removeEventListener
        console.error(e);
        mediaQueryList.removeListener(documentChangeHandler);
      }
    };
  }, [mediaQuery]);

  return isVerified;
};

export const useControlled = (controlledValue, defaultValue) => {
  const controlledRef = useRef(false);
  controlledRef.current = controlledValue !== undefined;

  const [uncontrolledValue, setUncontrolledValue] = useState(defaultValue);

  // If it is controlled, this directly returns the attribute value.
  const value = controlledRef.current ? controlledValue : uncontrolledValue;

  const setValue = useCallback(
    (nextValue) => {
      // Only update the value in state when it is not under control.
      if (!controlledRef.current) {
        setUncontrolledValue(nextValue);
      }
    },
    [controlledRef],
  );

  return [value, setValue, controlledRef.current];
};

export const useNotification = ({ keysToExtract = [] } = {}) => {
  const { uiNotifications = {} } = useSelector((state) => {
    return {
      uiNotifications: state.UINotification?.data,
    };
  });

  const getMetadata = (notification = {}) => {
    let parsedMetadata = {};
    if (notification.metadata) {
      try {
        parsedMetadata = JSON.parse(notification.metadata);
      } catch (error) {}
    }
    return parsedMetadata;
  };

  const notifications = {};

  const filteredNotificationKeys = useMemo(() => {
    return Object.keys(uiNotifications).filter((notificationKey) => {
      return keysToExtract?.includes(notificationKey);
    });
  }, [uiNotifications, keysToExtract]);

  filteredNotificationKeys.forEach((notificationKey) => {
    if (uiNotifications[notificationKey]?.length) {
      const notification = uiNotifications[notificationKey]?.[0];
      const metadata = getMetadata(notification) || {};
      notifications[notificationKey] = {
        ...notification,
        metadata,
      };
    }
  });

  return [notifications];
};

export const useDebounce = (value, delay) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay], // Only re-call effect if value or delay changes
  );
  return debouncedValue;
};

export const useMemoCompare = (next, compare = _isEqual) => {
  // Ref for storing previous value
  const previousRef = useRef();
  const previous = previousRef.current;
  // Pass previous and next value to compare function
  // to determine whether to consider them equal.
  const isEqual = compare(previous, next);
  // If not equal update previousRef to next value.
  // We only update if not equal so that this hook continues to return
  // the same old value if compare keeps returning true.
  useEffect(() => {
    if (!isEqual) {
      previousRef.current = next;
    }
  });
  // Finally, if equal then return the previous value
  return isEqual ? previous : next;
};

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const useMutationObserver = (
  ref,
  callback,
  options = {
    attributes: true,
    characterData: true,
    childList: true,
    subtree: true,
  },
) => {
  useEffect(() => {
    if (ref.current) {
      const observer = new MutationObserver(callback);
      observer.observe(ref.current, options);
      return () => observer.disconnect();
    }
  }, [callback, options]);
};

export const useControlState = (controlledValue, setControlValue, defaultValue) => {
  const [localValue, setLocalValue] = useState(defaultValue);
  const isFirstTime = useRef(true);

  const isControlled = useMemo(() => typeof controlledValue !== "undefined", [controlledValue]);

  const value = isControlled ? controlledValue : localValue;
  const onChange = isControlled && setControlValue ? setControlValue : setLocalValue;

  useEffect(() => {
    if (!isFirstTime.current && !isControlled && setControlValue) {
      setControlValue(value);
    }
  }, [isControlled, value]);

  useEffect(() => {
    isFirstTime.current = false;
  }, []);

  return [value, onChange];
};

export const useIntersectionObserver = (options) => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const targetRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setIsIntersecting(entry.isIntersecting);
    }, options);

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [options]);

  return { targetRef, isIntersecting };
};
