import React, { useEffect, useMemo } from "react";
import { isAfter, isBefore } from "date-fns/esm";
import _get from "lodash/get";

import { MonthPicker as SingleMonthPicker, computeTime } from "@onlinesales-ai/datepicker-v2";
import { format, formattedDate } from "@onlinesales-ai/util-methods-v2";
import { Text } from "@onlinesales-ai/label-v2";

import FormWrapper from "../../FormWrapper";

const MonthPicker = ({
  formValues,
  dataKey,
  onChange,
  className,
  disabled,
  saveMonthFormat,
  enableTime,
  disableIcon,
  showCTAs,
  shouldCloseOnSelect,
  popperPlacement,
  minMonthConfig,
  minMonth: pMinMonth,
  maxMonthConfig,
  maxMonth: pMaxMonth,
  placeholder,
  labelColumns,
  formGroupClassName,
  guidText,
  guidElement,
  apiError,
  warning,
  title,
  titleTooltip,
  titleGuidText,
  contentInfoTooltip,
  formErrors,
  showErrors,
  icon,
  isEditable,
  isRequired,
  showEmptyTitle,
  labelDefinationKey,
  labelClassName,
  valueFormat,
  initMonthConfig,
  defaultValueToShow,
  onError,
  compareDataKey,
  validations,
  popperClassName,
  innerLabelClass,
  ...props
}) => {

  const value = useMemo(() => {
    return _get(formValues, dataKey);
  }, [formValues, dataKey]);

  const minMonth = useMemo(() => {
    if (minMonthConfig) {
      return computeTime(minMonthConfig.type, minMonthConfig.value);
    } else if (pMinMonth) {
      return pMinMonth;
    }
  }, [minMonthConfig, pMinMonth]);

  const maxMonth = useMemo(() => {
    if (maxMonthConfig) {
      return computeTime(maxMonthConfig.type, maxMonthConfig.value);
    } else if (pMaxMonth) {
      return pMaxMonth;
    }
  }, [maxMonthConfig, pMaxMonth]);

  const onChangeMonth = (value) => {
    if (saveMonthFormat && value?.valueOf) {
      onChange({
        [dataKey]: format(value, saveMonthFormat, null),
      });
    } else {
      onChange({
        [dataKey]: value?.valueOf ? value.valueOf() : value,
      });
    }
  };

  const validate = () => {
    let errorMsg = null;

    const validationsToUse = validations;

    for (let i = 0; i < validationsToUse.length; i++) {
      if (!validationsToUse[i].type || !validationsToUse[i].msg) {
        continue;
      }

      switch (validationsToUse[i].type) {
        case "nonEmpty":
          {
            if (!value) {
              errorMsg = validationsToUse[i].msg;
            }
          }
          break;
        case "greaterThanFormValue":
          {
            if (value && _get(formValues, compareDataKey)) {
              if (isAfter(value, _get(formValues, compareDataKey))) {
                errorMsg = validationsToUse[i].msg;
              } else {
                onError({ [compareDataKey]: "" });
              }
            }
          }
          break;
        case "lessThanFormValue":
          {
            if (value && _get(formValues, compareDataKey)) {
              if (isBefore(value, _get(formValues, compareDataKey))) {
                errorMsg = validationsToUse[i].msg;
              } else {
                onError({ [compareDataKey]: "" });
              }
            }
          }
          break;
        default:
          break;
      }

      if (errorMsg) {
        errorMsg = errorMsg.replace("__FIELD_TITLE__", title.toLowerCase());
        break;
      }
    }

    onError({ [dataKey]: errorMsg });
  };

  useEffect(() => {
    if (!value && initMonthConfig) {
      const { type, value: pValue } = initMonthConfig;
      const month = computeTime(type, pValue);
      onChangeMonth(month);
    }
  }, [value, initMonthConfig]);

  useEffect(() => {
    validate();
  }, [value]);

  return (
    <FormWrapper
      labelColumns={labelColumns}
      formGroupClassName={formGroupClassName}
      hasError={showErrors && formErrors[dataKey]}
      error={formErrors[dataKey]}
      apiError={apiError}
      guidElement={guidElement}
      guidText={guidText}
      warning={warning}
      title={title}
      icon={icon}
      titleTooltip={titleTooltip}
      titleGuidText={titleGuidText}
      isEditable={isEditable}
      contentInfoTooltip={contentInfoTooltip}
      showEmptyTitle={showEmptyTitle}
      isRequired={isRequired}
      labelDefinationKey={labelDefinationKey}
      labelClassName={labelClassName}
      innerLabelClass={innerLabelClass}
    >
      {isEditable ? (
        <SingleMonthPicker
          className={className}
          value={value && new Date(value)}
          onChange={onChangeMonth}
          disabled={disabled}
          minMonth={minMonth}
          maxMonth={maxMonth}
          enableTime={enableTime}
          disableIcon={disableIcon}
          showCTAs={showCTAs}
          shouldCloseOnSelect={shouldCloseOnSelect}
          popperPlacement={popperPlacement}
          placeHolder={placeholder}
          popperClassName={popperClassName}
          {...props}
        />
      ) : (
        <Text>
          <div className="non-edit-mode-text">
            {value ? formattedDate(value, "month") : defaultValueToShow}
          </div>
        </Text>
      )}
    </FormWrapper>
  );
};

MonthPicker.defaultProps = {
  shouldCloseOnSelect: true,
  placeholder: "Select Month",
  defaultValueToShow: "-",
  validations: [
    {
      type: "nonEmpty",
      msg: "Please enter __FIELD_TITLE__",
    },
  ],
};

export default MonthPicker;
