import React, { useRef, useState, useMemo, useCallback, useEffect } from "react";
import DataListInput from "react-datalist-input";
import "./index.less";

const InputWithDataList = ({
  inputWrapperClassName,
  enableFullBorder,
  enableDropdownArrow,
  characterLimit,
  suffix,
  data,
  onChange,
  value,
  placeholder,
}) => {
  const onSelect = (selectedItem) => {
    onChange(selectedItem.key);
  };
  const [isDropdownOpen, setIsDropdownOpen] = useState("");
  const catMenu = useRef(null);

  const closeOpenMenus = (e) => {
    if (catMenu.current && !catMenu.current.contains(e.target)) {
      setIsDropdownOpen(false);
    } else {
      setIsDropdownOpen(true);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeOpenMenus);
    return () => {
      document.removeEventListener("mousedown", closeOpenMenus);
    };
  }, []);

  return (
    <div
      className={`datalist-input-wrapper ${inputWrapperClassName} ${
        enableFullBorder && "full-border"
      }`}
      ref={catMenu}
    >
      <DataListInput
        placeholder={placeholder}
        items={data}
        onSelect={onSelect}
        onInput={onChange}
        value={value}
        suppressReselect={false}
        dropdownClassName={isDropdownOpen ? "default-datalist-items dropdown-is-open" : "default-datalist-items"}
      />
      {enableDropdownArrow && <span className="icon icon-caret-down datalist-arrow" />}
      {suffix && <span className="suffix-metric">{suffix}</span>}
      {!suffix && characterLimit && (
        <span className="suffix-metric character-suffix">{characterLimit}</span>
      )}
    </div>
  );
};

InputWithDataList.defaultProps = {
  enableDropdownArrow: false,
  placeholder: "",
};

export default InputWithDataList;
