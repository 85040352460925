import React, { useEffect, useState } from "react";

import Checkbox from "./Checkbox";

const CheckboxGroup = ({
  defaultValue,
  groupValue,
  name,
  className,
  data,
  layout,
  onChange,
  disabled: groupDisabled,
  disableLabelClick,
  isTabsEnabled,
  idPrefix,
  noOptionMessage,
  checkboxRender,
  size,
  doNotSetToLocalState,
}) => {
  const [selectedValue, setSelectedValue] = useState(
    typeof groupValue !== "undefined" ? groupValue : defaultValue,
  );

  useEffect(() => {
    if (typeof groupValue !== "undefined") {
      setSelectedValue(groupValue);
    }
  }, [groupValue]);

  const onChangeChecked = (checked, value) => {
    const newValue = checked
      ? [...selectedValue, value]
      : selectedValue.filter((s) => s !== value);

    if (typeof groupValue === "undefined" && !doNotSetToLocalState) {
      setSelectedValue(newValue);
    }

    onChange(newValue, value);
  };

  return (
    <div className={`checkbox-group ${className} ${layout} ${isTabsEnabled ? "tabs" : ""} ${size}`}>
      {data.map(({ id, value, disabled, ...rest }, index) => {
        return (
          <Checkbox
            {...rest}
            disableLabelClick={disableLabelClick}
            key={`checkbox_group_${index}`}
            disabled={groupDisabled || disabled}
            name={name}
            id={`${idPrefix || ""}${id || value || ""}`}
            value={value}
            checked={selectedValue.includes(value)}
            onChange={(checked) => onChangeChecked(checked, value)}
          >{checkboxRender}</Checkbox>
        );
      })}
      {noOptionMessage && !data?.length ? (
        <div className={`${groupDisabled ? "disabled" : ""}`}>{noOptionMessage}</div>
      ) : null}
    </div>
  );
};

CheckboxGroup.defaultProps = {
  layout: "vertical",
  className: "",
  defaultValue: [],
  disabled: false,
  noOptionMessage: "",
  size: "",
  doNotSetToLocalState: false,
};

export default CheckboxGroup;
