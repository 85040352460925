/* eslint-disable camelcase */
/* eslint-disable eqeqeq */
import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { OAuthHelper, populateEvent } from "@onlinesales-ai/util-methods-v2";
import PlatformEventManager from "@onlinesales-ai/event-manager-v2";
import { BaseClient, OSBillingServiceV2, OSBillingService, KuberService } from "@onlinesales-ai/services-v2";

function fireIntercomEvents(action, metaData) {
  populateEvent("APP", `PAYMENT||2C2P_PAYMENT||${action}`, metaData);
}

const TwoCTwoPPayment = (props) => {
  const [fetchOrderIdLoading, setFetchOrderIdLoading] = useState(false);
  const { children } = props;

  const orderDataRef = useRef(null);

  const fetchOrderId = async (overrides) => {
    const { chargeAPICall } = props;
    const {
      clientId,
      userInfo,
      currency,
      amount,
      useDummyEmail,
      chargeExtraPayload,
      financeChargeAPICall,
    } = props;
    setFetchOrderIdLoading(true);
    let userEmail = userInfo?.email;
    if (!userEmail && useDummyEmail && userInfo?.contact) {
      userEmail = `${userInfo?.contact}@onlinesales.ai`;
    }
    let response = {};

    if (financeChargeAPICall) {
      response = await financeChargeAPICall();
      response = {
        transactionId: response?.result?.transactionId,
        metadata: {
          twoCtwoPParams: {
            ...(response?.result?.entityPayments?.[0]?.paymentModeInfo || {}),
          },
        },
      };
    } else if (chargeAPICall) {
      response = await chargeAPICall();
    } else {
      response = await OSBillingServiceV2.charge({
        clientId,
        email: userEmail,
        platform: "OS",
        amount,
        currency,
        ...chargeExtraPayload,
        ...(overrides || {}),
      });
    }

    const { webPaymentUrl, paymentToken } = response?.metadata?.twoCtwoPParams || {};

    if (!webPaymentUrl || !paymentToken) {
      throw Error("2C2P payment not supported please contact support.");
    }
    orderDataRef.current = {
      paymentUrl: webPaymentUrl,
      paymentToken,
      transactionId: response?.transactionId,
    };
    setFetchOrderIdLoading(false);
    fireIntercomEvents("ORDER_CREATED", orderDataRef.current);
    return orderDataRef.current;
  };

  const wait = (time) => {
    return new Promise((resolve) => {
      setTimeout(resolve, time);
    });
  };

  const checkIfTransactionIsAccepted = async (transactionId) => {
    if (!transactionId) {
      return Promise.reject({ errorMsg: BaseClient.getErrorMessage() });
    }

    const { clientId, isFinancePayment } = props;

    return new Promise(async (resolve, reject) => {
      try {
        let isAccepted = false;
        let retryCount = 10;

        while (true) {
          let statusResponse = {};

          if (isFinancePayment) {
            statusResponse = await KuberService.fetchWalletLogs({
              entityId: clientId,
              entityType: "CLIENT",
              entityLevel: "ENTITY_TRANSACTIONS",
              selectors: [
                "transactionId",
                "transactionStatus",
              ],
              filters: [
                {
                  column: "transactionId",
                  value: [encodeURIComponent(transactionId)],
                  operator: "EQUAL_TO",
                },
              ],
              limit: 1,
              offset: 0,
            });

            if (statusResponse?.result?.[0]?.transactionStatus) {
              if (statusResponse?.result?.[0]?.transactionStatus === "ACCEPTED") {
                isAccepted = true;
              } else if (statusResponse?.result?.[0]?.transactionStatus !== "PENDING") {
                retryCount = 0;
              }
            }
          } else {
            statusResponse = await OSBillingService.fetchInfo({
              "selectors": [
                "transactionId",
                "status",
              ],
              "entityLevel": "CLIENT_CREDIT_LOG",
              "filters": [
                {
                  "column": "clientId",
                  "value": `${clientId}`,
                  "operator": "EQUAL_TO",
                },
                {
                  "column": "transactionId",
                  "value": encodeURIComponent(transactionId),
                  "operator": "EQUAL_TO",
                },
              ],
              "clientId": clientId,
            });

            if (statusResponse?.data?.[0]?.status) {
              if (statusResponse?.data?.[0]?.status === "ACCEPTED") {
                isAccepted = true;
              } else if (statusResponse?.data?.[0]?.status !== "PENDING") {
                retryCount = 0;
              }
            }
          }

          retryCount--;

          if (isAccepted) {
            resolve();
            break;
          } else if (retryCount <= 0) {
            reject({ errorMsg: "Could not verify status of your transaction, Please contact support team if money is debited from your bank account." });
            break;
          } else {
            await wait(1000);
          }
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const openPaymentModal = async (noInUse, extraProps) => {
    const { onPaymentSuccess, onError, beforePayment, fireEventOnPaymentSuccess } = props;
    let popUpWindow = null;

    const { overrides = {} } = extraProps || {};
    try {
      await beforePayment();
    } catch (err) {
      return;
    }

    try {
      const orderDetails = await fetchOrderId(overrides);
      const { paymentUrl } = orderDetails;

      setFetchOrderIdLoading(true);

      const handlePaymentPostMessages = async (event) => {
        const { paymentResult } = event.data;
        if (paymentResult) {
          const { respCode } = paymentResult;
          if (
            respCode == "1001" ||
            respCode == "1002" ||
            respCode == "1003" ||
            respCode == "1004" ||
            respCode == "1005" ||
            respCode == "2001" ||
            respCode == "1000"
          ) {
            // DO NOT DO ANYTHING HERE.
          } else if (respCode == "2002" || respCode == "2003") {
            onError();
            setFetchOrderIdLoading(false);
            await wait(5000);
            OAuthHelper.closePopUpWindow();
          } else {
            await wait(5000);
            OAuthHelper.closePopUpWindow();

            try {
              setFetchOrderIdLoading(true);
              await checkIfTransactionIsAccepted(orderDetails?.transactionId);
            } catch (err) {
              setFetchOrderIdLoading(false);
              onError(err?.errorMsg);
              return;
            } finally {
              setFetchOrderIdLoading(false);
            }
            orderDataRef.current = null;
            fireIntercomEvents("PAYMENT_SUCCESS", JSON.stringify(orderDetails));
            if (fireEventOnPaymentSuccess && fireEventOnPaymentSuccess.length) {
              fireEventOnPaymentSuccess.forEach((platformEvent) => {
                PlatformEventManager.emit(platformEvent);
              });
            }
            onPaymentSuccess();
          }
        }
      };

      const callbackurlToOpen = `${
        window.location.origin
      }/2c2pPaymentWindowCallback?paymentUrl=${encodeURIComponent(paymentUrl)}`;

      popUpWindow = OAuthHelper.performOAuth("authentication", callbackurlToOpen, () => {}, false);

      popUpWindow.window.addEventListener("load", () => {
        popUpWindow.window.addEventListener("unload", () => {
          // Triggers when the popup is closed
          setFetchOrderIdLoading(false);
        });
      });

      popUpWindow.addEventListener(
        "message",
        (event) => {
          handlePaymentPostMessages(event);
        },
        false,
      );
    } catch (err) {
      setFetchOrderIdLoading(false);
      onError(err);
    }
  };

  return (
    <>
      {children({
        openPayment: openPaymentModal,
        isLoading: fetchOrderIdLoading,
      })}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { contactNo, email, name, id } = state.Application?.userInfo || {};
  const userInfo = {
    name,
    email,
    contact: contactNo,
    id,
  };

  return {
    clientId: ownProps.clientId || state.Application.clientId,
    userInfo: ownProps.userInfo || userInfo,
  };
};

export default connect(mapStateToProps)(TwoCTwoPPayment);
