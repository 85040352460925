import React from "react";
import _get from "lodash/get";
import { withTranslation } from "react-i18next";
import { Checkbox } from "@onlinesales-ai/checkbox-v2";

import FormWrapper from "../../FormWrapper";

import "./index.less";

class CheckboxToggle extends React.Component {
  getValue = () => {
    const { dataKey, formValues, getValue } = this.props;

    if (getValue) {
      return getValue(this.props);
    }

    return _get(formValues, dataKey);
  };

  onChangeCheckbox = (checked) => {
    const { onChange, dataKey } = this.props;

    onChange({
      [dataKey]: checked,
    });
  };

  render() {
    const {
      // FormWrapper props
      labelColumns,
      formGroupClassName,
      guidText,
      guidElement,
      apiError,
      warning,
      title,
      titleTooltip,
      titleGuidText,
      checkBoxProps,

      // tag input props
      dataKey,
      isEditable,
      showErrors,
      formErrors,
      disabled,
      showContentWhildReadonly,
    } = this.props;

    const value = this.getValue();

    return (
      <FormWrapper
        formGroupClassName={formGroupClassName}
        labelColumns={labelColumns}
        hasError={showErrors && formErrors[dataKey]}
        error={formErrors[dataKey]}
        apiError={apiError}
        guidElement={guidElement}
        guidText={guidText}
        warning={warning}
        title={title}
        titleTooltip={titleTooltip}
        titleGuidText={titleGuidText}
        isEditable={isEditable}
      >
        {isEditable || showContentWhildReadonly ? (
          <Checkbox
            id={dataKey}
            name={dataKey}
            onChange={this.onChangeCheckbox}
            checked={value}
            disabled={!isEditable || disabled}
            {...checkBoxProps}
          />
        ) : (
          <div className="non-edit-mode-text">
            {value ? "Yes" : "No"}
          </div>
        )}
      </FormWrapper>
    );
  }
}

CheckboxToggle.defaultProps = {
  dataKey: null,
  title: "",
  labelColumns: 3,
  showContentWhildReadonly: false,
  formGroupClassName: "form-component-checkbox-single",
};

export default withTranslation()(CheckboxToggle);
