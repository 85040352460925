/* eslint-disable camelcase */
import React from "react";
import { connect } from "react-redux";

import { BaseClient, OSBillingServiceV2, OSBillingService, KuberService } from "@onlinesales-ai/services-v2";
import PlatformEventManager from "@onlinesales-ai/event-manager-v2";
import { populateEvent, loadRazorPayCheckout } from "@onlinesales-ai/util-methods-v2";
import { triggerMonitor } from "@onlinesales-ai/error-catcher-v2";

function fireIntercomEvents(action, metaData) {
  populateEvent("APP", `PAYMENT||RAZOR_PAY_PAYMENT||${action}`, metaData);
}

class RazorPayPayment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fetchOrderIdLoading: false,
    };

    this.orderData = null;
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (
      newProps.amount !== this.props.amount ||
      newProps.currency !== this.props.currency ||
      newProps.userInfo?.email !== this.props.userInfo?.email
    ) {
      this.orderData = null;
    }
  }

  async componentDidMount() {
    const { onError } = this.props;

    try {
      await loadRazorPayCheckout();
      fireIntercomEvents("CHECKOUT_LOADED");
    } catch (err) {
      fireIntercomEvents("CHECKOUT_LOAD_ERROR");
      onError(err);
    }
  }

  fetchOrderId = async (overrides) => {
    const { chargeAPICall, forceNewOrderId, financeChargeAPICall } = this.props;
    if (!forceNewOrderId && this.orderData) {
      return Promise.resolve(this.orderData);
    }

    const {
      clientId,
      userInfo,
      currency,
      amount,
      fireEventOnOrderId,
      useDummyEmail,
      chargeExtraPayload,
    } = this.props;

    this.setState({
      fetchOrderIdLoading: true,
    });

    let userEmail = userInfo.email;

    if (!userEmail && useDummyEmail && userInfo.contact) {
      userEmail = `${userInfo.contact}@onlinesales.ai`;
    }
    let response = {};

    if (financeChargeAPICall) {
      response = await financeChargeAPICall({
        payloadOverride: overrides,
      });
      response = {
        metadata: {
          razorpay_order_id:
            response?.result?.entityPayments?.[0]?.paymentModeInfo?.razorpay_order_id,
          razorpay_api_key:
            response?.result?.entityPayments?.[0]?.paymentModeInfo?.razorpay_api_key,
          transactionId: response?.result?.transactionId,
        },
      };
    } else if (chargeAPICall) {
      response = await chargeAPICall();
    } else {
      response = await OSBillingServiceV2.charge({
        clientId,
        email: userEmail,
        platform: "OS",
        amount,
        currency,
        ...chargeExtraPayload,
        ...(overrides || {}),
      });
    }

    const { razorpay_order_id, razorpay_api_key, transactionId } = response?.metadata || {};

    if (!razorpay_order_id || !razorpay_api_key) {
      throw Error("Razor Pay payment not supported please contact support.");
    }

    this.orderData = {
      orderId: razorpay_order_id,
      apiKey: razorpay_api_key,
      transactionId,
    };

    this.setState({
      fetchOrderIdLoading: false,
    });

    fireIntercomEvents("ORDER_CREATED", response);

    if (fireEventOnOrderId && fireEventOnOrderId.length) {
      fireEventOnOrderId.forEach((event) => {
        PlatformEventManager.emit(event);
      });
    }

    return this.orderData;
  };

  wait = (time) => {
    return new Promise((resolve) => {
      setTimeout(resolve, time);
    });
  };

  checkIfTransactionIsAccepted = async (transactionId) => {
    if (!transactionId) {
      return Promise.reject({ errorMsg: BaseClient.getErrorMessage() });
    }

    const { clientId, isFinancePayment } = this.props;

    return new Promise(async (resolve, reject) => {
      try {
        let isAccepted = false;
        let retryCount = 10;

        while (true) {
          let statusResponse = {};

          if (isFinancePayment) {
            statusResponse = await KuberService.fetchWalletLogs({
              entityId: clientId,
              entityType: "CLIENT",
              entityLevel: "ENTITY_TRANSACTIONS",
              selectors: [
                "transactionId",
                "transactionStatus",
              ],
              filters: [
                {
                  column: "transactionId",
                  value: [encodeURIComponent(transactionId)],
                  operator: "EQUAL_TO",
                },
              ],
              limit: 1,
              offset: 0,
            });

            if (statusResponse?.result?.[0]?.transactionStatus) {
              if (statusResponse?.result?.[0]?.transactionStatus === "ACCEPTED") {
                isAccepted = true;
              } else if (statusResponse?.result?.[0]?.transactionStatus !== "PENDING") {
                retryCount = 0;
              }
            }
          } else {
            statusResponse = await OSBillingService.fetchInfo({
              "selectors": [
                "transactionId",
                "status",
              ],
              "entityLevel": "CLIENT_CREDIT_LOG",
              "filters": [
                {
                  "column": "clientId",
                  "value": `${clientId}`,
                  "operator": "EQUAL_TO",
                },
                {
                  "column": "transactionId",
                  "value": encodeURIComponent(transactionId),
                  "operator": "EQUAL_TO",
                },
              ],
              "clientId": clientId,
            });

            if (statusResponse?.data?.[0]?.status) {
              if (statusResponse?.data?.[0]?.status === "ACCEPTED") {
                isAccepted = true;
              } else if (statusResponse?.data?.[0]?.status !== "PENDING") {
                retryCount = 0;
              }
            }
          }

          retryCount--;

          if (isAccepted) {
            resolve();
            break;
          } else if (retryCount <= 0) {
            triggerMonitor("SEV2", "TRANSACTION_STATUS_VERIFYING_FAILED", {
              calledFrom: "fetchWalletLogs",
            });
            reject({ errorMsg: "Could not verify status of your transaction, Please contact support team if money is debited from your bank account." });
            break;
          } else {
            await this.wait(1000);
          }
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  openPaymentModal = async (noInUse, extraProps) => {
    const {
      name,
      description,
      image,
      currency,
      amount,
      userInfo = {},
      onPaymentSuccess,
      onError,
      beforePayment,
      fireEventOnPaymentSuccess,
      onDismissCallback,
      paymentWindowConfig,
    } = this.props;

    const {
      onDismissCallback: onDismissCallbackSecond,
      onPaymentSuccess: onPaymentSuccessSecond,
      overrides = {},
    } = extraProps || {};

    try {
      await beforePayment();
    } catch (err) {
      return;
    }

    try {
      const orderDetails = await this.fetchOrderId(overrides);

      const options = {
        name,
        description,
        image,
        currency,
        key: orderDetails.apiKey,
        amount: `${amount}`,
        order_id: orderDetails.orderId,
        handler: async (response) => {
          try {
            this.setState({
              fetchOrderIdLoading: true,
            });
            await this.checkIfTransactionIsAccepted(orderDetails?.transactionId);
          } catch (err) {
            this.setState({
              fetchOrderIdLoading: false,
            });
            onError(err?.errorMsg);
            return;
          } finally {
            this.setState({
              fetchOrderIdLoading: false,
            });
          }

          // reset order data after successfull payment
          this.orderData = null;

          fireIntercomEvents("PAYMENT_SUCCESS", JSON.stringify(orderDetails));
          if (fireEventOnPaymentSuccess && fireEventOnPaymentSuccess.length) {
            fireEventOnPaymentSuccess.forEach((event) => {
              PlatformEventManager.emit(event);
            });
          }

          onPaymentSuccess(response);
          if (typeof onPaymentSuccessSecond === "function") {
            onPaymentSuccessSecond(response);
          }
        },
        modal: {
          backdropclose: false,
          // https://razorpay.com/docs/payment-gateway/web-integration/standard/additional-features/
          ondismiss: () => {
            if (typeof onDismissCallbackSecond === "function") {
              onDismissCallbackSecond(orderDetails);
            }
            return onDismissCallback(orderDetails);
          },
        },
        prefill: {
          name: userInfo.name,
          email: userInfo.email,
          contact: userInfo.contact,
        },
        ...(overrides || {}),
        ...(paymentWindowConfig || {}),
      };

      this.razorpay = new window.Razorpay(options);
      this.razorpay.open();

      fireIntercomEvents("MODAL_OPEN", JSON.stringify(options));
    } catch (err) {
      this.setState({
        fetchOrderIdLoading: false,
      });
      onError(err);
    }
  };

  render() {
    const { children } = this.props;
    const { fetchOrderIdLoading } = this.state;

    if (typeof children === "function") {
      return children({
        openPayment: this.openPaymentModal,
        isLoading: fetchOrderIdLoading,
      });
    }

    return null;
  }
}

const mapStateToProps = (state, ownProps) => {
  const { contactNo, email, name } = state.Application?.userInfo || {};
  const userInfo = {
    name,
    email,
    contact: contactNo,
  };

  return {
    clientId: ownProps.clientId || state.Application.clientId,
    userInfo: ownProps.userInfo || userInfo,
  };
};

const RazorPayPaymentWrapper = connect(mapStateToProps)(RazorPayPayment);

RazorPayPaymentWrapper.defaultProps = {
  beforePayment: () => Promise.resolve(),
  fireEventOnOrderId: [],
  fireEventOnPaymentSuccess: [],
  currency: "INR",
  name: "Onlinesales.ai",
  description: "",
  image: "https://osads.gumlet.io/image/upload/logos/logo_without_text_250x250.png",
  onDismissCallback: () => {},
  forceNewOrderId: false,
  chargeExtraPayload: {},
};

export default RazorPayPaymentWrapper;
