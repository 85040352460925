import React, { useState, useEffect } from "react";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import _isEqual from "lodash/isEqual";

import { OSHOCWithUtilities } from "@onlinesales-ai/os-hoc-with-utilities-v2";
import { RadioGroup } from "@onlinesales-ai/radio-v2";
import FormWrapper from "../../FormWrapper";

import "./index.less";

const RadioTabSelection = (props) => {
  const {
    formValues,
    radioProps,
    onChange,

    labelColumns,
    formGroupClassName,
    guidText,
    guidElement,
    apiError,
    warning,
    title,
    titleTooltip,
    titleGuidText,

    isEditable,
    showErrors,
    formErrors,
    dataKey,
    options,
    isDisabled,
    isRequired,
  } = props;

  const getValue = () => {
    return _get(formValues, dataKey);
  };

  const [selectedValue, setSelectedValue] = useState();

  const onRadioValueChange = (value) => {
    setSelectedValue(value);
    onChange({
      [dataKey]: value,
    });
  };

  useEffect(() => {
    const value = getValue() || radioProps?.defaultValue;
    if (value) {
      onRadioValueChange(value);
    }
  }, [radioProps]);

  const validate = (value) => {
    const { onError, validations = [] } = props;
    let errorMsg = null;

    for (let i = 0; i < validations.length; i++) {
      if (!validations[i].type || !validations[i].msg) {
        continue;
      }

      switch (validations[i].type) {
        case "nonEmpty":
          {
            if (_isEmpty(value)) {
              errorMsg = validations[i].msg;
            }
          }
          break;
        default:
          break;
      }

      if (errorMsg) {
        errorMsg = errorMsg.replace("__FIELD_TITLE__", title.toLowerCase());
        break;
      }
    }

    onError({ [dataKey]: errorMsg });
  };

  useEffect(() => {
    const newValue = _get(formValues, dataKey);
    validate(newValue, dataKey);
  }, [formValues]);

  const value = getValue();

  return (
    <FormWrapper
      labelColumns={labelColumns}
      formGroupClassName={formGroupClassName}
      hasError={showErrors && formErrors[dataKey]}
      error={formErrors[dataKey]}
      apiError={apiError}
      guidElement={guidElement}
      guidText={guidText}
      warning={warning}
      title={title}
      titleTooltip={titleTooltip}
      titleGuidText={titleGuidText}
      isEditable={isEditable}
      isRequired={isRequired}
    >
      <RadioGroup
        name="radio-tab-selection-name"
        onChange={onRadioValueChange}
        {...radioProps}
        defaultValue={selectedValue || value}
        className="radio-tab-selection"
        data={options}
        disabled={isDisabled || !isEditable}
      />
    </FormWrapper>
  );
};

RadioTabSelection.defaultProps = {
  isDisabled: false,
  placeholder: "Schedule Type",
  options: [
    {
      label: "All",
      value: "All",
    },
    {
      label: "Men",
      value: "Male",
    },
    {
      label: "Women",
      value: "Female",
    },
  ],
  radioProps: {
    defaultValue: "ALL",
    layout: "horizontal",
  },
  formErrors: {},
  formValues: {},
  formGroupClassName: "form-component-radio",
};

export default OSHOCWithUtilities(RadioTabSelection);
