import _keyBy from "lodash/keyBy";
import { KuberService, ReportingUIService } from "@onlinesales-ai/services-v2";
import { getCurrencyCode, customMergeOS, fetchBatchWise } from "@onlinesales-ai/util-methods-v2";

import Types from "./types";

export const appendClientId = (action) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { clientId } = state.Application || {};

    dispatch({
      clientId: action.clientId || clientId,
      ...action,
    });
  };
};

export const updateCampaignTypeWalletData = (clientId, data) =>
  appendClientId({
    type: Types.UPDATE_CAMPAIGN_TYPE_WALLET_DATA,
    data,
    clientId,
  });

export const updateWalletData = (clientId, data) => {
  return appendClientId({
    type: Types.UPDATE_WALLET_DATA,
    data,
    clientId,
  });
};

export const updateBillingSettingData = (clientId, data) =>
  appendClientId({
    type: Types.UPDATE_BILLING_SETTINGS,
    data,
    clientId,
  });

export const updatePromotionsData = (clientId, data) =>
  appendClientId({
    type: Types.UPDATE_PROMOTIONS_DATA,
    data,
    clientId,
  });

export const updateGlobalDataFetchState = (clientId, data) =>
  appendClientId({
    type: Types.GLOBAL_WALLET_DATA_FETCH_STATE,
    data,
    clientId,
  });

export const updatePromotionTableData = (data) => ({
  type: Types.UPDATE_PROMOTION_TABLE_DATA,
  data,
});

export const updatePromotionFormData = (data) => ({
  type: Types.UPDATE_PROMOTION_FORM_DATA,
  data,
});

export const fetchCampaignTypeWalletData = ({ clientId: pClientId, abortId, ...extraConfig }) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { clientId } = state.Application || {};

    const payload = {
      entityId: pClientId || clientId,
      entityType: "CLIENT",
      fetchPromotionData: true,
      selectors: ["billingTag", "totalBalance", "topupBalance", "promotionalBalance", "unallocatedSpend"],
      limit: 20,
      offset: 0,
      overallRequired: true,
      ...extraConfig,
    };

    const response = await KuberService.fetchCampaignTypeWalletBalance(payload, "dashboard", {
      abortId,
    });

    const { overall, billingTags } = response?.result || {};

    dispatch(
      updateCampaignTypeWalletData(payload.entityId, {
        overall,
        channelData: _keyBy(billingTags, "billingTag"),
      }),
    );

    return response?.result || {};
  };
};

export const fetchWalletAllDataForApp = ({ clientId: pClientId }) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { clientId } = state.Application || {};
    const financeData = state.Finance || {};

    if (
      financeData[clientId]?.globalWalletDataFetchState?.isLoading ||
      financeData[clientId]?.globalWalletDataFetchState?.dataLoaded
    ) {
      return null;
    }

    const payload = {
      entityId: pClientId || clientId,
      entityType: "CLIENT",
      overallRequired: true,
      selectors: [
        "billingProfileId",
        "billingProfileName",
        "totalBalance",
        "isPrepaidAutoSwipeEnabled",
        "unallocatedSpend",
      ],
      limit: 50,
      offset: 0,
    };

    const response = {
      result: {},
    };

    dispatch(
      updateGlobalDataFetchState(payload.entityId, {
        isLoading: true,
      }),
    );
    response.result.wallets = await fetchBatchWise({
      apiCall: KuberService.fetchWalletBalance.bind(KuberService),
      payload,
      config: { limit: 20 },
      dataResolver: (res) => {
        const { wallets, overall } = res?.result || {};
        response.result.overall = overall;
        return wallets;
      },
      application: "finance",
    });

    const { wallets } = response?.result || {};

    dispatch(
      updateWalletData(payload.entityId, {
        ..._keyBy(wallets, "billingProfileId"),
      }),
    );

    dispatch(
      updateGlobalDataFetchState(payload.entityId, {
        isLoading: false,
        dataLoaded: true,
      }),
    );

    return response?.result || {};
  };
};

export const fetchActiveCampaignsData = (config) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { clientId } = state.Application || {};

    const payload = {
      clientId,
      ...config,
    };

    const response = await ReportingUIService.fetchMarketingCampaigns(payload, "dashboard");

    return response;
  };
};

export const fetchWalletData = ({
  clientId: pClientId,
  abortId,
  fetchAllData = true,
  ...extraConfig
}) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { clientId } = state.Application || {};

    const payload = {
      entityId: pClientId || clientId,
      entityType: "CLIENT",
      overallRequired: true,
      selectors: [
        "billingProfileId",
        "billingProfileName",
        "billingTags",
        "totalBalance",
        "topupBalance",
        "promotionalBalance",
        "metadata",
        "unallocatedSpend",
      ],
      limit: 20,
      offset: 0,
      ...extraConfig,
    };

    let response = {
      result: {},
    };

    if (fetchAllData) {
      response.result.wallets = await fetchBatchWise({
        apiCall: KuberService.fetchWalletBalance.bind(KuberService),
        payload,
        config: { limit: 20 },
        dataResolver: (res) => {
          const { wallets, overall } = res?.result || {};
          response.result.overall = overall;
          return wallets;
        },
        application: "finance",
      });
    } else {
      response = await KuberService.fetchWalletBalance(payload, "dashboard", {
        abortId,
      });
    }

    const { wallets, overall } = response?.result || {};

    dispatch(
      updateWalletData(payload.entityId, {
        ..._keyBy(wallets, "billingProfileId"),
        ALL: {
          ...(overall || {}),
          billingProfileId: "ALL",
          billingProfileName: "All",
        },
      }),
    );

    return response?.result || {};
  };
};

export const fetchBillingSettings = (config) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { clientId } = state.Application || {};
    const { financeConfig } = state.DomainConfig || {};

    const payload = {
      entityId: config?.clientId || clientId,
      entityType: "CLIENT",
    };

    const response = await KuberService.fetchBillingSettings(payload, "dashboard");

    dispatch(
      updateBillingSettingData(payload.entityId, customMergeOS({}, response.result, financeConfig)),
    );

    return response.result;
  };
};

export const postWallet = (config) => {
  return async (dispatch, getState) => {
    const state = getState();

    const { clientId } = state.Application || {};

    const payload = {
      entityId: config?.clientId || clientId,
      entityType: "CLIENT",
      currency: getCurrencyCode(),
      ...config,
    };

    const response = await KuberService.postWallet(payload, "dashboard");

    const walletResponse = response?.result || {};

    dispatch(updateWalletData(payload.entityId, _keyBy([walletResponse], "billingProfileId")));

    return walletResponse;
  };
};

export const postTransferWallet = (config) => {
  return async (dispatch, getState) => {
    const state = getState();

    const { clientId } = state.Application || {};

    const payload = {
      entityId: config?.clientId || clientId,
      entityType: "CLIENT",
      currency: getCurrencyCode(),
      ...config,
    };

    const response = await KuberService.postTransferWallet(payload, "dashboard");

    const { destinationBillingProfile, sourceBillingProfile } = response?.result?.[0] || {};

    dispatch(
      updateWalletData(
        payload.entityId,
        _keyBy([destinationBillingProfile, sourceBillingProfile], "billingProfileId"),
      ),
    );

    return response;
  };
};

export const postAppliedPromotion = (config) => {
  return async (dispatch, getState) => {
    const state = getState();

    const { clientId } = state.Application || {};

    const payload = {
      entityId: config?.clientId || clientId,
      entityType: "CLIENT",
      ...config,
    };

    const response = await KuberService.postAppliedPromotion(payload, "dashboard");

    dispatch(updatePromotionsData(config?.clientId || clientId, _keyBy(response?.data, "id")));

    return response;
  };
};

export const fetchBillingPromotions = (config) => {
  return async (dispatch, getState) => {
    const state = getState();

    const { clientId } = state.Application || {};

    const payload = {
      entityId: config?.clientId || clientId,
      entityType: "CLIENT",
      ...config,
    };

    const response = await KuberService.fetchBillingPromotions(payload);

    dispatch(updatePromotionsData(config?.clientId || clientId, _keyBy(response?.data, "id")));

    return response;
  };
};

export const fetchPromotionsList = (config) => {
  return async (dispatch, getState) => {
    const state = getState();

    const { clientId } = state.Application || {};

    const payload = {
      entityId: config?.clientId || clientId,
      entityType: "CLIENT",
      ...config,
    };

    const response = await KuberService.fetchPromotions(payload);

    dispatch(updatePromotionTableData(_keyBy(response?.data, "id")));

    return response;
  };
};

export const fetchPromotionDetails = (config) => {
  return async (dispatch, getState) => {
    const state = getState();

    const { clientId } = state.Application || {};

    const payload = {
      entityId: config?.clientId || clientId,
      entityType: "CLIENT",
      ...config,
    };

    const response = await KuberService.fetchPromotions(payload);

    dispatch(updatePromotionTableData(_keyBy(response?.data, "id")));

    return response;
  };
};

export const postPromotionDetails = (config) => {
  return async (dispatch, getState) => {
    const state = getState();

    const { clientId } = state.Application || {};

    const payload = {
      entityId: config?.clientId || clientId,
      entityType: "CLIENT",
      ...config,
    };

    const response = await KuberService.postPromotions(payload);

    dispatch(updatePromotionFormData(response?.data));

    return response;
  };
};
