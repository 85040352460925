import React, { useContext, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { Trans } from "react-i18next";
import _uniq from "lodash/uniq";

import {
  populateEvent,
  getRemainingDays,
  setCookie,
  asyncWait,
  removeLSItem,
} from "@onlinesales-ai/util-methods-v2";
import { CarouselContainer } from "@onlinesales-ai/carousel-container-v2";
import WithTooltip from "@onlinesales-ai/tooltip-v2";
import { Button } from "@onlinesales-ai/button-v2";
import { GlobalContext } from "@onlinesales-ai/utils-components-v2";
import { callToActionTypes } from "@onlinesales-ai/constants-v2";

import Timer from "./timer";
import "./index.less";

const fireIntercomEvents = (action, metaData) => {
  populateEvent("APPLICATION", `NOTIFICATION_BAR||${action}`, metaData);
};

const NotificationBar = ({
  staticNotifications,
  uiNotifications,
  isShowStickyHeader,
  redirectUrl,
  dismissUINotification,
  clientId,
  isAllowLocalDismiss,
}) => {
  const [notificaitons, setNotifications] = useState([]);
  const [elementIdList, setElementIdList] = useState([]);
  const [foundElementIdList, setfoundElementIdList] = useState([]);

  const allNotifications = useMemo(() => {
    return [...(staticNotifications || []), ...(uiNotifications || [])];
  }, [staticNotifications, uiNotifications]);
  const { changeClientId } = useContext(GlobalContext);

  useEffect(() => {
    setNotifications(allNotifications);
  }, [allNotifications]);

  useEffect(() => {
    const list = [];
    notificaitons.forEach(({ elementId }) => {
      if (elementId) {
        list.push(elementId);
      }
    });
    setElementIdList(list);
  }, [notificaitons]);

  const onNotificationDismiss = (event, key, isUINotification) => {
    event.stopPropagation();
    //TODO: Handle BE notificaiton dismiss
    if (isAllowLocalDismiss) {
      setNotifications((extNotifications) => {
        return extNotifications.filter(({ key: k }) => k !== key);
      });
      setfoundElementIdList((list) => {
        return list.filter((k) => k !== key);
      });
    } else if (isUINotification) {
      dismissUINotification("NOTIFICATION_BAR", key);
    }
  };

  const handleCtaType = (ctaType, href) => {
    if (ctaType === callToActionTypes.REDIRECT_TO_ALL_OUTLET_URL) {
      changeClientId("CLIENT_AGGREGATOR");
    }

    if (ctaType === callToActionTypes.REDIRECT_TO_PULSE) {
      const urlToOpen = window.location.origin.replace("-admin", "-pulse");
      try {
        let d = new Date();
        d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
        let expires = "expires=" + d.toUTCString();
        let domain = ".onlinesales.ai";

        document.cookie =
          "move_to_legacy_portal_v2" +
          "=" +
          "" +
          "; " +
          expires +
          "; domain=" +
          domain +
          "; SameSite=None; path=/;" +
          "secure";
      } catch (error) {}

      // setCookie("move_to_pulse_v2", true, 200, false);
      window.open(urlToOpen, "_self");
    }

    if (href?.includes("__CLIENT_ID__") && clientId) {
      href = href.replace("__CLIENT_ID__", clientId);
    }

    redirectUrl(href);
  };

  const onCTAClick = (event, key, href, ctaType) => {
    event.stopPropagation();

    fireIntercomEvents("NOTIFICATION_CTA_CLICK", { key, href });

    handleCtaType(ctaType, href);
  };

  const onNotificationClick = (event, key, href, ctaType) => {
    event.stopPropagation();

    fireIntercomEvents("NOTIFICATION_CLICK", { key, href });
    handleCtaType(ctaType, href);
  };

  const renderDom = (eleId) => {
    const notificationInfo = notificaitons.find(({ elementId }) => elementId === eleId);
    if (!notificationInfo) {
      return <></>;
    }
    const {
      key,
      message,
      icon,
      href,
      isDiscardable,
      ctaConfig,
      isUINotification,
      className,
      containerClass,
      iconImage,
      dangerousMessage,
      timerConfig,
      showTimer,
      ctaType,
      endDate,
      type = "default",
    } = notificationInfo;
    return (
      <div className={`notification-bar-container ${containerClass} ${type}`}>
        <div className="notification-bar-container-inner">
          <div className="single-item">
            <div
              key={key}
              className={`notification-item ${href ? "clickable" : ""} ${className}`}
              onClick={href ? (event) => onNotificationClick(event, key, href, ctaType) : null}
            >
              <div className="notification-item-inner">
                {/*<WithTooltip title={message} placement="bottom">*/}
                <div className="notification-item-inner-text">
                  <span className="content">
                    {icon && <span className={`icon ${icon}`} />}
                    {iconImage && (
                      <img src={iconImage} className="notification-imgicon img-fulid" />
                    )}
                    {showTimer && <Timer {...timerConfig} />}
                    <span className="ellipsis-text content-inner">
                      <Trans
                        defaults={message}
                        components={{
                          strong: <b />,
                          a: <a />,
                        }}
                        values={{ remainingDays: getRemainingDays(endDate) }}
                      />
                    </span>
                    {dangerousMessage ? (
                      <span
                        className="notification-title"
                        dangerouslySetInnerHTML={{ __html: dangerousMessage }}
                      />
                    ) : null}
                  </span>
                  {ctaConfig && (
                    <Button
                      className={`cta ${ctaConfig.className || ""}`}
                      onClick={
                        ctaConfig.href
                          ? (event) => onCTAClick(event, key, ctaConfig.href, ctaType)
                          : null
                      }
                      type={ctaConfig.type}
                    >
                      <Trans>{ctaConfig.name}</Trans>
                    </Button>
                  )}
                </div>
                {/*</WithTooltip>*/}
              </div>
              {isDiscardable && (
                <span
                  onClick={(event) => onNotificationDismiss(event, key, isUINotification)}
                  className="icon icon-close1 notification-bar-close"
                ></span>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const onElementFound = (eleId) => {
    setfoundElementIdList((ext) => {
      return _uniq([...ext, eleId]);
    });
    setElementIdList((eleList) => {
      return eleList.filter((k) => k !== eleId);
    });
  };

  useEffect(() => {
    let pollTimer = null;
    if (elementIdList?.length > 0) {
      pollTimer = setInterval(() => {
        elementIdList.forEach((id) => {
          const element = document.getElementById(id);

          if (element) {
            onElementFound(id);
          }
        });
      }, 2000);
    } else {
      clearInterval(pollTimer);
    }
    return () => clearInterval(pollTimer); // Cleanup on component unmount
  }, [elementIdList, onElementFound]);

  return foundElementIdList.map((eleId) => {
    if (document.getElementById(eleId)) {
      return createPortal(renderDom(eleId), document.getElementById(eleId));
    }
  });
};

NotificationBar.propTypes = {};

export default NotificationBar;
