/* eslint-disable no-lone-blocks */
/* eslint-disable default-case */
import AppActionTypes from "@onlinesales-ai/app-v2/application/types";

import { bidTargetLevelEnum } from "src/utilities/constants/bid";

import Types from "./types";

const initialState = {
  clientLevel: {},
  goalLevel: {},
};

const reducer = (state = initialState, action) => {
  let hasChanged = false;
  let copyState = null;
  switch (action.type) {
    case AppActionTypes.APP_CLIENT_CHANGE: {
      state = {
        ...initialState
      };
    }
      break;
    case Types.BID_SETTINGS_SET_BID_DATA:
      {
        copyState = JSON.parse(JSON.stringify(state));
        hasChanged = true;
        let { config = {}, bidData = [] } = action;
        let bidConfig = null;
        if (config.marketingCampaignId) {
          if (!copyState.goalLevel) {
            copyState.goalLevel = {};
          }

          if (!copyState.goalLevel[config.marketingCampaignId]) {
            copyState.goalLevel[config.marketingCampaignId] = {};
          }

          bidConfig = copyState.goalLevel[config.marketingCampaignId];
        } else {
          if (!copyState.clientLevel) {
            copyState.clientLevel = {};
          }

          bidConfig = copyState.clientLevel;
        }

        let bidConfigForChannel = null;
        if (bidConfig) {
          if (!bidConfig[config.vendorChannelName]) {
            bidConfig[config.vendorChannelName] = {};
          }

          bidConfigForChannel = bidConfig[config.vendorChannelName];
        }

        if (bidConfigForChannel) {
          if (config.bidTargetLevel === bidTargetLevelEnum.MARKETING_CAMPAIGN) {
            bidConfigForChannel.settings = bidData[0];
          }
          if (config.bidTargetLevel === bidTargetLevelEnum.CATEGORY) {
            if (!bidConfigForChannel.categoryLevel) {
              bidConfigForChannel.categoryLevel = {};
            }

            bidData.forEach((bidInfo) => {
              let bidType = bidInfo.biddingStrategyType;
              if (bidType && bidInfo.isActive) {
                if (!bidConfigForChannel.categoryLevel[bidType]) {
                  bidConfigForChannel.categoryLevel[bidType] = {};
                }

                if (bidInfo.categoryValue) {
                  bidConfigForChannel.categoryLevel[bidType][
                    bidInfo.categoryValue
                  ] = bidInfo;
                }
              } else if (bidType && !bidInfo.isActive) {
                delete bidConfigForChannel.categoryLevel[bidType][
                  bidInfo.categoryValue
                ];
              }
            });
          }
          if (config.bidTargetLevel === bidTargetLevelEnum.SKU) {
            if (!bidConfigForChannel.skuLevel) {
              bidConfigForChannel.skuLevel = {};
            }

            bidData.forEach((bidInfo) => {
              let bidType = bidInfo.biddingStrategyType;
              if (bidType && bidInfo.isActive) {
                if (!bidConfigForChannel.skuLevel[bidType]) {
                  bidConfigForChannel.skuLevel[bidType] = {};
                }

                if (bidInfo.skuId) {
                  bidConfigForChannel.skuLevel[bidType][
                    bidInfo.skuId
                  ] = bidInfo;
                }
              } else if (bidType && !bidInfo.isActive) {
                if (
                  bidConfigForChannel.skuLevel[bidType] &&
                  bidConfigForChannel.skuLevel[bidType][bidInfo.skuId]
                ) {
                  delete bidConfigForChannel.skuLevel[bidType][bidInfo.skuId];
                }
              }
            });
          }
        }
      }
      break;
  }
  if(hasChanged) {
    return copyState;
  }
  return state;
};

export default {
  BidSettings: reducer,
};
