import React, { memo } from "react";
import PropTypes from "prop-types";
import { Trans } from "react-i18next";
import { loaderLine } from "@onlinesales-ai/loader-v2";
import { getCurrencyDOM, formatNumberInAbbreviations } from "@onlinesales-ai/util-methods-v2";
import TwoLineChart from "./twoLineChart/app.js";
import PopoverTooltip from "@onlinesales-ai/popover-tooltip-v2";

export const PerformanceDataLoader = ({ list }) => {
  return (
    <div className="configuration-candy-chart-stats-wrapper is-loading">
      {list.map((key) => {
        return (
          <div
            className="configuration-candy-stat"
            key={`${key}`}
          >
            <div className="configuration-candy-stat-metric">
              <div className="animated-bg">
                <div className="bg-masker top"></div>
                <div className="bg-masker middle"></div>
                <div className="bg-masker middle-center"></div>
                <div className="bg-masker bottom"></div>
              </div>
            </div>
            <div className="configuration-candy-stat-chart">
              {loaderLine()}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const PerformanceData = memo((props) => {
  const getPerformanceDataToShow = (goalData = {}) => {
    const { objectiveSettings = {}, objective } = goalData;
    const currentGoalObjective = objective || objectiveSettings.name || "";
    const { performanceMetricsToShow = {} } = props;
    const performanceMetricKeysToShow = [];

    Object.keys(performanceMetricsToShow).map((performanceMetricKey) => {
      let performanceDataObj =
        performanceMetricsToShow[performanceMetricKey] || {};
      if (
        !performanceDataObj.forObjective ||
        performanceDataObj.forObjective === currentGoalObjective
      ) {
        performanceMetricKeysToShow.push(performanceMetricKey);
      }
    });

    return performanceMetricKeysToShow;
  };
  const getPerformanceDataDOM = (goalId, goalData = {}) => {
    let {
      overallPerformanceData = {},
      overallPerformanceDataPrevWindow = {},
      dateWisePerformanceData = [],
    } = props.goalPerformanceData || {};
    const { performanceMetricsToShow = {}, performanceFetchInProgress } = props;
    const performanceMetricKeysToShow = getPerformanceDataToShow(goalData);

    if (performanceFetchInProgress) {
      return (
        <PerformanceDataLoader list={performanceMetricKeysToShow} />
      );
    } else {
      return (
        <div className="configuration-candy-chart-stats-wrapper">
          {performanceMetricKeysToShow.map((key) => {
            let performanceDataObj = performanceMetricsToShow[key] || {};
            let currentPerformance =
              overallPerformanceData[performanceDataObj.alias] || 0;
            let prevWindowPerformance =
              overallPerformanceDataPrevWindow[performanceDataObj.alias] || 0;
            let changeInPerformanceRatio =
              (currentPerformance - prevWindowPerformance) /
              prevWindowPerformance;
            let changeInPerformance = (Number.isNaN(changeInPerformanceRatio) ||
            changeInPerformanceRatio === Infinity
              ? 0
              : changeInPerformanceRatio * 100
            ).toFixed(1);
            const multiplier = performanceDataObj.showInPercentage ? 100 : 1;

            return (
              <div
                className="configuration-candy-stat"
                key={`${goalId}-${key}`}
              >
                <div className="configuration-candy-stat-metric">
                  <div className="stats-title">
                    {performanceDataObj.tooltipText ? (
                      <PopoverTooltip
                        placement="top"
                        containerClass="learnMoreLinkTooltip"
                        allowClickOnPopoverBody={false}
                        popoverContent={
                          <Trans>{performanceDataObj.tooltipText}</Trans>
                        }
                      >
                        <Trans>{performanceDataObj.displayName}</Trans>
                      </PopoverTooltip>
                    ) : (
                      <Trans>{performanceDataObj.displayName}</Trans>
                    )}
                  </div>
                  <div className="stats-number-container">
                    <div className="stats-number">
                      {performanceDataObj.showCurrency
                        ? getCurrencyDOM()
                        : null}
                      {formatNumberInAbbreviations(
                        (overallPerformanceData[performanceDataObj.alias] || 0) * multiplier,
                        performanceDataObj.precision,
                      )}
                      {performanceDataObj.showInPercentage ? "%" : ""}
                    </div>
                    <div
                      className={
                        "stats-number-change " +
                        (changeInPerformance < 0 ? "error-msg" : "success-msg")
                      }
                    >
                      {changeInPerformance}%
                    </div>
                  </div>
                </div>
                {!props.isMobile && (
                  <div className="configuration-candy-stat-chart">
                    <TwoLineChart
                      data={dateWisePerformanceData}
                      line1Info={{
                        name: performanceDataObj.targetDisplayName,
                        dataKey: performanceDataObj.targetAlias,
                        showCurrency: performanceDataObj.showCurrency,
                        precision: performanceDataObj.precision,
                        showInPercentage: performanceDataObj.showInPercentage,
                      }}
                      line2Info={{
                        name: performanceDataObj.displayName,
                        dataKey: performanceDataObj.alias,
                        showCurrency: performanceDataObj.showCurrency,
                        precision: performanceDataObj.precision,
                        showInPercentage: performanceDataObj.showInPercentage,
                      }}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      );
    }
  };
  return getPerformanceDataDOM(props.goalId, props.goalData);
});

PerformanceData.propTypes = {
  goalId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  goalPerformanceData: PropTypes.object,
};

PerformanceData.defaultProps = {
  performanceMetricsToShow: {
    spend: {
      displayName: "Spend",
      targetDisplayName: "Budget",
      alias: "spend",
      targetAlias: "daily_budget",
      showCurrency: true,
      selectors: [
        {
          alias: "spend",
          canonicalColumn: "convert_currency_VND.sum..cost",
          isDistinct: false,
          isCount: false,
        },
      ],
    },
    revenue: {
      displayName: "Revenue",
      targetDisplayName: "Target Revenue",
      forObjective: "Absolute Revenue",
      targetAlias: "daily_goal",
      alias: "revenue",
      showCurrency: true,
      precision: 2,
      selectors: [
        {
          alias: "revenue",
          canonicalColumn: "convert_currency_VND.sum..sales",
          isDistinct: false,
          isCount: false,
        },
      ],
    },
    orders: {
      displayName: "Orders",
      targetDisplayName: "Target Orders",
      forObjective: "Transactions",
      targetAlias: "daily_goal",
      alias: "orders",
      showCurrency: false,
      precision: 1,
      selectors: [
        {
          alias: "orders",
          canonicalColumn: "sum..leads",
          isDistinct: false,
          isCount: false,
        },
      ],
    },
    visits: {
      displayName: "Visits",
      targetDisplayName: "Target Visits",
      forObjective: "Visitors",
      targetAlias: "daily_goal",
      alias: "visits",
      showCurrency: false,
      selectors: [
        {
          alias: "visits",
          canonicalColumn: "sum..clicks",
          isDistinct: false,
          isCount: false,
        },
      ],
    },
  },
};

export default PerformanceData;
