import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";

import { Input } from "@onlinesales-ai/input-v2";
import { CheckboxGroup } from "@onlinesales-ai/checkbox-v2";
import {EmptyMessage} from "@onlinesales-ai/message-card-v2";

const CheckBoxFilterComponent = ({
  value,
  onChange,
  tabKey,
  componentMetaData,
  emptyMsg,
  inputWrapperClassName,
  ...restProps
}) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");

  const getFilteredItems = (items = [], searchStr) => {
    const filteredItems = [];

    if (items) {
      items.forEach(({ label, value }) => {
        const lowerCasedText = label && label.toLowerCase();
        const lowerCasedSearchStr = searchStr && searchStr.toLowerCase();
        if (lowerCasedText && lowerCasedText.includes(lowerCasedSearchStr)) {
          filteredItems.push({ label, value });
        }
      });
    }

    return filteredItems;
  };

  const handleSearchInputChange = (value) => {
    setSearchQuery(value);
  };

  const renderSearchInput = (tabKey, searchPlaceholder = "Type to Search") => {
    return (
      <div className="search-box-container">
        <Input
          value={searchQuery}
          placeholder={t(searchPlaceholder)}
          onChange={handleSearchInputChange}
          inputWrapperClassName={classnames("border-padding", inputWrapperClassName)}
          isSearchInput
        />
      </div>
    );
  };

  const { showSearchInput = false, searchPlaceholder, ...rest } = restProps;
  const { items: originalItems = [] } = componentMetaData;
  let filteredItems = [...originalItems];
  if (searchQuery) {
    filteredItems = getFilteredItems(filteredItems, searchQuery);
  }
  return (
    <div className="form-group">
      {showSearchInput && renderSearchInput(tabKey, searchPlaceholder)}
      {
        filteredItems.length ? (
          <CheckboxGroup
            name={`filter-tab-${tabKey}`}
            groupValue={value}
            data={filteredItems}
            idPrefix={tabKey}
            onChange={(...params) => onChange(tabKey, ...params)}
            {...rest}
          />
        ) : (
          <EmptyMessage>
            {emptyMsg}
          </EmptyMessage>
        )

      }
    </div>
  );
};

CheckBoxFilterComponent.defaultProps = {
  value: [],
  onChange: () => {},
  componentMetaData: {},
  emptyMsg: "No matching items found"
};

export default CheckBoxFilterComponent;
