import BaseClient from "../baseClient";

const servicePrefix = "/nexusService/";

class NexusService extends BaseClient {
  static getErrorMessages() {
    return {
      default: "Looks like we are facing some internal issue. Please, retry after sometime.",
      IP0000:
        "It seems some input parameters are not valid. Please update your inputs and try again.",
      AD0000:
        "Oops. It seems you are not authorized to take this action. Please log in with appropriate credentials.",
      I0004:
        "Oops. Seems the system is acting up - sorry for the inconvenience. Please try again in some time. (Please be assured our tech geniuses have been notified of this glitch).",
    };
  }

  static fetchCampaigns(config = {}, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}brandAdsCampaign`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(config),
        },
      },
      application,
      options,
    );
  }

  static postCampaignInfo(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}brandAdsCampaign`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static postMarketingCampaign(payload, application, options) {
    const request = encodeURIComponent(JSON.stringify(payload));
    return this.apiCall(
      {
        url: `${servicePrefix}campaign`,
        method: "POST",
        data: request,
      },
      application,
      options,
    );
  }

  static linkMarketplaceSeller(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}accountLinking`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static getBrandForMarketplace(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}listBrandForMarketplace`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(payload),
        },
      },
      application,
      options,
    );
  }

  static postAgencyUserAccess(config, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}userAccess/agency`,
        method: "POST",
        data: JSON.stringify(config),
      },
      application,
      options,
    );
  }

  static postClientUserAccess(config, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}userAccess/client`,
        method: "POST",
        data: JSON.stringify(config),
      },
      application,
      options,
    );
  }
}

export default NexusService;
