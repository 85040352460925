import React, { useMemo } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { JSONPath } from "jsonpath-plus";

import { outletTextReplacer } from "@onlinesales-ai/util-methods-v2";

const getId = (option) => {
  return `header-menu-${option.id || option?.name?.replace?.(" ", "-")?.toLowerCase?.()}`;
};

const NavMenu = React.forwardRef(({ app, onClick, state }, ref) => {
  const { t } = useTranslation();

  const countData = useMemo(() => {
    let value;
    let valueClassName;

    for (let i = 0; i < app?.countJSONPath?.length; i++) {
      try {
        value = JSONPath({ json: state, path: app?.countJSONPath[i].jsonPath, wrap: false });
      } catch (error) {}

      if (value) {
        valueClassName = app?.countJSONPath[i]?.className;
        break;
      }
    }

    return { value, valueClassName };
  }, [state, app]);

  const renderCount = () => {
    return (
      <>
        {countData.value > 0 ? (
          <span className={`nav-count count-position ${countData.valueClassName}`}>
            {countData.value}
          </span>
        ) : countData.value > 10 ? (
          <Pill className={`round count-position  ${countData.valueClassName}`}>
            {countData.value}
          </Pill>
        ) : null}
      </>
    );
  };

  return (
    <NavLink
      to={app.href}
      target={app.target}
      className={({ isActive }) => `${app.className} nav-link ${isActive ? " active" : ""}`}
      onClick={(e) => {
        e.preventDefault();
        onClick(e, app);
      }}
      activeClassName="active"
      ref={ref}
      key={app.href}
      id={getId(app)}
    >
      <span className={`nav-icon icon ${app.icon}`}>
        {app.titleTag && (
          <span className="new-feature-tag-2 red no-before-after top-right round">{app.titleTag}</span>
        )}
      </span>
      {app.countJSONPath && renderCount()}
      <span className="nav-text">
        {t(outletTextReplacer(app.name))}
        <span className="icon icon-angle-down dropdown-indicator" />
      </span>
      <span className="nav-bar-underline" />
    </NavLink>
  );
});

const mapStateToProps = (state) => {
  return {
    state,
  };
};

export default connect(mapStateToProps)(withTranslation()(NavMenu));
