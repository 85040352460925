import React from "react";
import classnames from "classnames";
import _get from "lodash/get";

import InputText from "../inputText";
import UniversalDropdownForm from "../universalDropdown";
import FormWrapper from "../../FormWrapper";

import "./index.less";

const InputWithDropdown = ({
  dataKey,
  dataKeyPrefix,
  dataKeyForDropdown,
  labelDefinationKey,
  title,
  inputProps,
  dropdownProps,
  enableFullborder,
  containerClass,
  inputWrapperClassName,
  showErrors,
  formErrors,
  ...props
}) => {
  return (
    <FormWrapper
      labelDefinationKey={labelDefinationKey}
      title={title}
      hasError={showErrors && formErrors[dataKey]}
      error={formErrors[dataKey]}
      {...props}
    >
      <div className="input-with-dropdown-wrapper d-align-center">
        <InputText
          dataKey={dataKey}
          inputWrapperClassName={classnames("full-width max-height", inputWrapperClassName, {
            "border-padding white-bg": enableFullborder,
          })}
          errorClassName="d-none"
          formGroupInnerClassName="mb-0"
          showErrors={showErrors}
          formErrors={formErrors}
          {...inputProps}
          {...props}
        />
        <UniversalDropdownForm
          dataKey={`${dataKeyPrefix || ""}${dataKeyForDropdown}`}
          errorClassName="d-none"
          formGroupInnerClassName="mb-0"
          containerClass={classnames("with-white-bg with-form-height", containerClass)}
          showErrors={showErrors}
          formErrors={formErrors}
          {...dropdownProps}
          {...props}
        />
      </div>
    </FormWrapper>
  );
};

InputWithDropdown.defaultProps = {
  enableFullborder: true,
};

export default InputWithDropdown;
