/**
 * React Component to display Toast (message at a page level).
 *
 * TODO: Support to change Toast position through config.(Currently it shows message at the top)
 * TODO: Support to change background color through config.
 */
import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import classnames from "classnames";

import { withTranslation, Trans } from "react-i18next";
import { ToastTypeEnum } from "@onlinesales-ai/constants-v2";

import "./index.less";

const Toast = ({
  messageToDisplay= null,
  actionButtonLabel= null,
  actionButton=function (){},
  onToastDestroy=function (){},
  toastDuration= 8000,
  usePortal= false,
  portalDOMNode,
  type=ToastTypeEnum.DEFAULT.key,
  className,
}) => {
  const toastContainer = useRef(null);

  /**
   * get fadeout and then gets destroyed after the given toastDuration.
   */
  useEffect(() => {
    if (toastContainer && toastDuration) {
      setTimeout(() => {
        onToastDestroyHandle();
      }, toastDuration);
    }
  }, []);

  /**
   * @function Toast Action Button click event listener.
   * @param event
   */
  const onToastActionBtnClick = (event) => {
    event.preventDefault();
    if (typeof actionButton === "function") {
      actionButton();
    }
  };

  /**
   * @function Toast on Destroy event listener.
   */
  const onToastDestroyHandle = () => {
    if (typeof onToastDestroy === "function") {
      onToastDestroy();
    }
  };

  /**
   * @function Action Button is only visible on the UI when actionButtonLabel is passed in config as props.
   * @returns {XML}
   */
  const getActionButtonDom = () => {
    return (
      <span
        className="os-toast-action-btn"
        onClick={onToastActionBtnClick}
      >
        <Trans>{actionButtonLabel}</Trans>
      </span>
    );
  };

  /**
   * @function Returns DOM for Toast.
   * @returns {XML}
   */
  const getToastContainerDom = () => {
    let actionButtonDom = actionButtonLabel
      ? getActionButtonDom()
      : null;
    return (
      <div className={classnames("os-toast-container", className)} ref={toastContainer}>
        <div className="os-toast-message">
          {typeof messageToDisplay === "object" &&
          messageToDisplay.length ? (
            messageToDisplay.map((message) => (
              <div>
                <Trans>{message}</Trans>
              </div>
            ))
          ) : (
            <div>
              <Trans>{messageToDisplay}</Trans>
            </div>
          )}
          {actionButtonDom}
        </div>
      </div>
    );
  };

  const getToastDOM = () => {
    let toastContainerDom = messageToDisplay
      ? getToastContainerDom()
      : null;
    let toastType = type ? type : ToastTypeEnum.DEFAULT.key;
    let toastTypeClass = ToastTypeEnum[toastType]
      ? ToastTypeEnum[toastType].classname
      : ToastTypeEnum.DEFAULT.classname;
    return (
      <div className={"toast-wrapper " + toastTypeClass}>
        {toastContainerDom}
      </div>
    );
  };

  if (usePortal) {
    let lPortalDOMNode = portalDOMNode ? portalDOMNode : document.querySelector("#root");
    return ReactDOM.createPortal(getToastDOM(), lPortalDOMNode);
  } else {
    return getToastDOM();
  }
};

Toast.propTypes = {
  messageToDisplay: PropTypes.string.isRequired,
  actionButtonLabel: PropTypes.string,
  actionButton: PropTypes.func,
  onToastDestroy: PropTypes.func,
  toastDuration: PropTypes.number,
  type: PropTypes.string,
};

Toast.defaultProps = {
  messageToDisplay: null,
  actionButtonLabel: null,
  actionButton: function () {},
  onToastDestroy: function () {},
  toastDuration: 8000,
  usePortal: false,
  portalDOMNode: document.querySelector("#root"),
};

export default withTranslation()(Toast);
