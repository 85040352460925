import React from "react";
import _get from "lodash/get";
import { withTranslation } from "react-i18next";

import { Label } from "@onlinesales-ai/label-v2";
import AsyncImage from "@onlinesales-ai/async-image-v2";

import FormWrapper from "../../FormWrapper";

class Tab extends React.Component {
  constructor(props) {
    super(props);

    const { dataKey, formValues } = this.props;
    this.validate(_get(formValues, dataKey));
  }

  componentDidMount() {
    if (!this.getValue()) {
      const { dataSet } = this.props;
      if (dataSet && dataSet.length) {
        const selected = dataSet.find((d) => d.defaultSelected) || dataSet[0];
        this.onChangeSelection(selected.value);
      }
    }
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const { dataKey, formValues } = this.props;
    const newValue = _get(nextProps.formValues, nextProps.dataKey);

    if (newValue !== _get(formValues, dataKey)) {
      this.validate(newValue);
    }
  };

  getValue = () => {
    const { dataKey, formValues } = this.props;
    return _get(formValues, dataKey);
  };

  onChangeSelection = (value, disabled) => {
    const { isEditable } = this.props;
    if (disabled || !isEditable) {
      return;
    }

    const { onChange, dataKey } = this.props;
    onChange({ [dataKey]: value });
  };

  validate = (value) => {
    const { onError, dataKey, validations = [], title } = this.props;
    let errorMsg = null;

    for (let i = 0; i < validations.length; i++) {
      if (!validations[i].type || !validations[i].msg) {
        continue;
      }

      switch (validations[i].type) {
        case "nonEmpty":
          {
            if (!value) {
              errorMsg = validations[i].msg;
            }
          }
          break;
        default:
          break;
      }

      if (errorMsg) {
        errorMsg = errorMsg.replace("__FIELD_TITLE__", title.toLowerCase());
        break;
      }
    }

    onError({ [dataKey]: errorMsg });
  };

  render() {
    const {
      // FormWrapper props
      formGroupClassName,
      guidText,
      guidElement,
      apiError,
      warning,
      title,
      titleTooltip,
      titleGuidText,
      isEditable,

      // tab props
      t,
      dataKey,
      showErrors,
      formErrors,
      dataSet,
    } = this.props;

    const selectedValue = this.getValue();

    return (
      <FormWrapper
        labelColumns={12}
        formGroupClassName={formGroupClassName}
        guidText={guidText}
        guidElement={guidElement}
        hasError={showErrors && formErrors[dataKey]}
        error={formErrors[dataKey]}
        apiError={apiError}
        warning={warning}
        title={title}
        titleTooltip={titleTooltip}
        titleGuidText={titleGuidText}
        isEditable={isEditable}
      >
        <div className="form-tab-container mt-2">
          {dataSet.map(
            ({ value, description, disabled, tabLabel, image, ...restProps }) => {
              return (
                <div
                  className={`form-tab-wrapper d-align-center ${selectedValue === value ? "selected" : ""} ${
                    disabled ? "disabled" : ""
                  }`}
                  onClick={() => this.onChangeSelection(value, disabled)}
                >
                  <div className="w100p">
                    <div>
                      <span className="form-tab-title">
                        <span className={`icon tab-select-icon ${selectedValue === value ? "icon-check-circle-2" : "icon-circle-2"}`} />
                        <Label {...restProps} />
                      </span>
                      {tabLabel && <div className="form-tab-label">{tabLabel}</div>}
                      {description && <div className="form-tab-desc">{t(description)}</div>}
                    </div>
                    <AsyncImage imgClassName="tab-image" imgSrc={image} />
                  </div>
                </div>
              );
            },
          )}
        </div>
      </FormWrapper>
    );
  }
}

Tab.defaultProps = {
  dataKey: null,
  title: "",
  validations: [
    {
      type: "nonEmpty",
      msg: "Please enter __FIELD_TITLE__",
    },
  ],
};

export default withTranslation()(Tab);
