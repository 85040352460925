import React, { useState, useContext, useMemo } from "react";
import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";

import { Button } from "@onlinesales-ai/button-v2";
import { PAYMENT_METHOD } from "@onlinesales-ai/constants-v2";
import { OSHOCWithUtilities } from "@onlinesales-ai/os-hoc-with-utilities-v2";
import { OverlayLoader } from "@onlinesales-ai/loader-v2";
import { getCurrencyCode, getCurrencyDOM, fireGoogleAnalyticsEvent } from "@onlinesales-ai/util-methods-v2";
import {
  OSBillingService,
  ReportingUIService,
  OSBillingServiceV2,
} from "@onlinesales-ai/services-v2";
import PlatformEventManager from "@onlinesales-ai/event-manager-v2";
import { Checkbox } from "@onlinesales-ai/checkbox-v2";
import { GlobalContext } from "@onlinesales-ai/utils-components-v2";
import { getEntityInfo, getEntityMetadata } from "@onlinesales-ai/ott-common-v2";
import { uiAPIMonitor } from "@onlinesales-ai/error-catcher-v2";

import RazorPayPayment from "../../paymentMethods/razorpayPayment";
import TwoCTwoPPayment from "../../paymentMethods/2c2p";
import FlutterwavePayment from "../../paymentMethods/flutterwavePayment";
import BliblisnapPayment from "../../paymentMethods/bliblisnapPayment";
import BankOpenLayer from "../../paymentMethods/bankOpenLayer";
import PayU from "../../paymentMethods/payu";
import PaymentModal from "../../paymentModal";
import ThanaChartPaymentModal from "../thanaChartPaymentModal";
import IODrawer from "../../io/IODrawer";
import StripeBillingV2Wrapper from "../../paymentMethods/stripCardUpdate/billingV2Wrapper";

import "./index.less";
import SnapdealCOD from "../snapdealCOD/index";

const PAYMENT_MODEL = {
  THANACHART: ThanaChartPaymentModal,
};

const TopupButton = ({
  // button props
  isLoading: pIsLoading,
  disabled: pDisabled,
  jobDoneCallback,
  beforePayment,
  topUpAmount,
  ctaText,
  ctaGuideText,
  payLaterCallback,
  useChargeV2Api,
  selectedEntityId,
  selectedEntityType,
  entityInfo,
  entityMetadata,
  paymentMethodOverideProps,
  successMessage,
  errorMessage,

  // auto props
  showConfirmationModal,
  resetConfirmationModal,
  shopInfo,
  onboardingDetails,
  clientId,
  billingDetails,
  userInfo,

  // from domain config
  paymentMethodProps,
  paymentMethod,
  isSubscribeAndCharge,
  showConfirmationOnTopup,
  enablePayLater,
  payLaterText,
  showTC,
  ioProps,
  forceNewOrderId,
  marketplaceCurrency,
  isFireGoogleEvent,
}) => {
  const { t } = useTranslation();
  const [isTopUpPostLoading, setIsTopUpPostLoading] = useState(false);
  const [showTCDrawer, setShowTCDrawer] = useState(false);
  const [tcAccepted, setTCAccepted] = useState(false);
  const { showToastMessage, redirectUrl, changeClientId } = useContext(GlobalContext);

  const subscribeAndCharge = async () => {
    topUpAmount = Number(topUpAmount);

    const payload = {
      clientId,
      subscriptions: [
        {
          planId: billingDetails.planId,
          status: "ACTIVE",
        },
      ],
      email: userInfo.email,
      platform: billingDetails.platform || "OS",
      userId: userInfo.id || null,
      paymentMode: billingDetails.paymentMode || "OFFLINE",
      prepaidSwipeAmount: topUpAmount,
      prepaidSwipeAmountCurrency: getCurrencyCode(),
      shouldSwipeOnSubscribe: true,
      isSendInvoiceEnabled: billingDetails.isSendInvoiceEnabled,
    };

    setIsTopUpPostLoading(true);

    try {
      await ReportingUIService.postSubscriptionPlans(payload, "TOPUP");
    } catch (err) {
      showToastMessage({
        messageToDisplay: err.errorMsg,
        type: "ERROR",
        toastDuration: 5000,
      });
    }

    setIsTopUpPostLoading(false);

    return Promise.resolve();
  };

  const onTopUp = async () => {
    if (isSubscribeAndCharge) {
      return subscribeAndCharge();
    }

    setIsTopUpPostLoading(true);

    try {
      if (useChargeV2Api) {
        const payload = {
          clientId,
          amount: Number(topUpAmount),
          currency: getCurrencyCode(),
          platform: paymentMethodProps?.platform || "OS",
          email: userInfo.email,
        };

        await OSBillingServiceV2.charge(payload, "Dashboard");
      } else {
        const payload = {
          clientId,
          amount: Number(topUpAmount),
          currency: getCurrencyCode(),
          couponCode: paymentMethodProps?.couponCodeDetails?.code,
          description: `Top-Up from ${userInfo?.name || userInfo?.email}`,
        };

        await OSBillingService.chargeAmount(payload, "Dashboard");
      }

      showToastMessage({
        messageToDisplay: "Top-Up has been successful!",
        type: "SUCCESS",
      });
      PlatformEventManager.emit("OS_ACC_BALANCE_UPDATE");
      setIsTopUpPostLoading(false);
      jobDoneCallback();
    } catch (err) {
      setIsTopUpPostLoading(false);
      showToastMessage({
        messageToDisplay: err.errorMsg,
        type: "ERROR",
        toastDuration: 5000,
      });
    }

    return null;
  };

  const onClickPayment = () => {
    if (showConfirmationOnTopup) {
      showConfirmationModal({
        isShow: true,
        title: (
          <Trans>
            Great! We will be adding {getCurrencyDOM()}
            {{ topUpAmount }} to your account. Please confirm the amount.
          </Trans>
        ),
        rightBtnText: "Cancel",
        actionBtnText: "I confirm",
        actionBtnCallback: () => {
          resetConfirmationModal();
          beforePayment().then(() => {
            onTopUp();
          });
        },
        rightBtnCallback: () => {
          resetConfirmationModal();
        },
      });
    } else {
      beforePayment().then(() => {
        onTopUp();
      });
    }
  };

  const triggerGoogleAnalytics = () => {
    const entityInfoLocal = getEntityInfo(entityInfo, `${selectedEntityType}_${selectedEntityId}`);
    const entityMetadataLocal = getEntityMetadata(entityMetadata, `${selectedEntityType}_${selectedEntityId}`);

    if (entityInfoLocal?.metadata?.businessDefinition === "AGENCY") {
      fireGoogleAnalyticsEvent("Agency Wallet topup ", {
        name: entityInfoLocal?.entityName,
      });
    } else if (
      entityInfoLocal?.metadata?.businessDefinition === "ADVERTISER" &&
      entityMetadataLocal?.isBillingEntity
    ) {
      fireGoogleAnalyticsEvent("Direct Advertiser wallet topup", {
        name: entityInfoLocal?.entityName,
      });
    }
  };

  const onPaymentSuccess = () => {
    if (isFireGoogleEvent) {
      triggerGoogleAnalytics();
    }
    showToastMessage({
      messageToDisplay: t(successMessage),
      type: "SUCCESS",
    });
    jobDoneCallback();
  };

  const onPaymentError = (error) => {
    const message = typeof error === "string" ? error : errorMessage;
    uiAPIMonitor("SEV2", "TOPUP_FAILED", {
      error,
    });
    showToastMessage({
      messageToDisplay: t(message),
      type: "ERROR",
      toastDuration: 5000,
    });
  };

  const getRazorPayDescription = () => {
    let retnVal = null;

    if (onboardingDetails.marketplaceStoreDetails?.id) {
      retnVal = `Topup Wallet - ${shopInfo.storeType} - seller - ${onboardingDetails.marketplaceStoreDetails?.id}`;
    } else {
      retnVal = `Topup Wallet - ${shopInfo.storeType} - client - ${clientId}`;
    }

    return retnVal;
  };

  const renderCTAGuideText = () => {
    if (!ctaGuideText) {
      return null;
    }

    return <div className="guide-text cta-text m-2">{ctaGuideText}</div>;
  };

  const toggleTCDrawer = () => {
    setShowTCDrawer(!showTCDrawer);
  };

  const renderTnc = () => {
    if (!showTC) {
      return null;
    }

    return (
      <div className="cta-tc-text">
        <IODrawer isOpen={showTCDrawer} onClickClose={toggleTCDrawer} {...ioProps} />
        <Checkbox
          checked={tcAccepted}
          onChange={setTCAccepted}
          label={
            <Trans>
              I Agree to{" "}
              <a href="javascript:void(0)" onClick={toggleTCDrawer}>
                Terms and Condition
              </a>
            </Trans>
          }
        />
      </div>
    );
  };

  if (
    paymentMethod === PAYMENT_METHOD.BANK_OPEN ||
    paymentMethod === PAYMENT_METHOD.BANK_OPEN_STAGING
  ) {
    return (
      <BankOpenLayer
        amount={Number(topUpAmount)}
        currency={shopInfo.currencyCode}
        beforePayment={beforePayment}
        onPaymentSuccess={onPaymentSuccess}
        onError={onPaymentError}
        // fireEventOnOrderId={["OS_ACC_BALANCE_UPDATE"]}
        fireEventOnPaymentSuccess={["OS_ACC_BALANCE_UPDATE"]}
        {...paymentMethodProps}
      >
        {({ openPayment, isLoading }) => {
          return (
            <div className="bank-open-topup">
              {isLoading ? <OverlayLoader /> : null}
              <div className="topup-button-row">
                <Button
                  className="pendo_feature_topup_button"
                  isLoading={isLoading}
                  disabled={pDisabled || isLoading}
                  onClick={openPayment}
                >
                  {ctaText}
                </Button>
                {enablePayLater && (
                  <Button className="ml-2" disabled={isLoading} onClick={payLaterCallback}>
                    {payLaterText}
                  </Button>
                )}
              </div>
              {renderCTAGuideText(ctaGuideText)}
            </div>
          );
        }}
      </BankOpenLayer>
    );
  }

  if (
    paymentMethod === PAYMENT_METHOD.MIDTRANS_BLIBLI ||
    paymentMethod === PAYMENT_METHOD.MIDTRANS_BUKUKAS ||
    paymentMethod === PAYMENT_METHOD.MIDTRANS_BUKUKAS_STAGING
  ) {
    return (
      <BliblisnapPayment
        paymentMethod={paymentMethod}
        amount={Math.ceil(Number(topUpAmount))}
        currency={shopInfo.currencyCode}
        beforePayment={beforePayment}
        onPaymentSuccess={onPaymentSuccess}
        onError={onPaymentError}
        // fireEventOnOrderId={["OS_ACC_BALANCE_UPDATE"]}
        fireEventOnPaymentSuccess={["OS_ACC_BALANCE_UPDATE"]}
        {...paymentMethodProps}
      >
        {({ openPayment, isLoading }) => {
          return (
            <div className="topup-button-row">
              <Button
                className="pendo_feature_topup_button"
                isLoading={isLoading}
                disabled={pDisabled || isLoading}
                onClick={openPayment}
              >
                {ctaText}
              </Button>
              {enablePayLater && (
                <Button className="ml-2" disabled={isLoading} onClick={payLaterCallback}>
                  {payLaterText}
                </Button>
              )}
              {renderCTAGuideText(ctaGuideText)}
            </div>
          );
        }}
      </BliblisnapPayment>
    );
  }

  if (
    paymentMethod === PAYMENT_METHOD.RAZORPAY_506964 ||
    paymentMethod === PAYMENT_METHOD.RAZORPAY_506963
  ) {
    return (
      <RazorPayPayment
        description={getRazorPayDescription()}
        amount={Number(topUpAmount)}
        currency={shopInfo.currencyCode}
        beforePayment={beforePayment}
        onPaymentSuccess={onPaymentSuccess}
        onError={onPaymentError}
        // fireEventOnOrderId={["OS_ACC_BALANCE_UPDATE"]}
        fireEventOnPaymentSuccess={["OS_ACC_BALANCE_UPDATE"]}
        {...paymentMethodProps}
        forceNewOrderId={forceNewOrderId}
      >
        {({ openPayment, isLoading }) => {
          return (
            <>
              <SnapdealCOD
                className="pendo_feature_topup_button"
                isLoading={isLoading}
                amount={Number(topUpAmount)}
                fireEventOnPaymentSuccess={["OS_ACC_BALANCE_UPDATE"]}
                beforePayment={beforePayment}
                disabled={pDisabled || isLoading}
                openPayment={openPayment}
                ctaText={ctaText}
                chargeExtraPayload={paymentMethodProps.chargeExtraPayload || {}}
                codPaymentMode={paymentMethodProps?.codPaymentMode}
              />
              {renderCTAGuideText(ctaGuideText)}
            </>
          );
        }}
      </RazorPayPayment>
    );
  }

  if (
    paymentMethod === PAYMENT_METHOD.RAZORPAY ||
    paymentMethod === PAYMENT_METHOD.RAZORPAY_TEST ||
    paymentMethod === PAYMENT_METHOD.RAZORPAY_290039 ||
    paymentMethod === PAYMENT_METHOD.RAZORPAY_290038 ||
    paymentMethod === PAYMENT_METHOD.RAZORPAY_372976 ||
    paymentMethod === PAYMENT_METHOD.RAZORPAY_372977 ||
    paymentMethod === PAYMENT_METHOD.RAZORPAY_10006294 ||
    paymentMethod === PAYMENT_METHOD.RAZORPAY_10006292
  ) {
    return (
      <RazorPayPayment
        description={getRazorPayDescription()}
        amount={Number(topUpAmount)}
        currency={shopInfo.currencyCode}
        beforePayment={beforePayment}
        onPaymentSuccess={onPaymentSuccess}
        onError={onPaymentError}
        // fireEventOnOrderId={["OS_ACC_BALANCE_UPDATE"]}
        fireEventOnPaymentSuccess={["OS_ACC_BALANCE_UPDATE"]}
        {...paymentMethodProps}
        forceNewOrderId={forceNewOrderId}
      >
        {({ openPayment, isLoading }) => {
          return (
            <>
              <Button
                className="pendo_feature_topup_button"
                isLoading={isLoading}
                disabled={pDisabled || isLoading}
                onClick={openPayment}
              >
                {ctaText}
              </Button>
              {renderCTAGuideText(ctaGuideText)}
            </>
          );
        }}
      </RazorPayPayment>
    );
  }

  if (
    paymentMethod === PAYMENT_METHOD.FLUTTERWAVE ||
    paymentMethod === PAYMENT_METHOD.FLUTTERWAVE_STAGING
  ) {
    return (
      <FlutterwavePayment
        description={getRazorPayDescription()}
        amount={Number(topUpAmount)}
        currency={shopInfo.currencyCode}
        beforePayment={beforePayment}
        onPaymentSuccess={onPaymentSuccess}
        onError={onPaymentError}
        // fireEventOnOrderId={["OS_ACC_BALANCE_UPDATE"]}
        fireEventOnPaymentSuccess={["OS_ACC_BALANCE_UPDATE"]}
        {...paymentMethodProps}
      >
        {({ openPayment, isLoading }) => {
          return (
            <div className="topup-button-row">
              <Button
                className="pendo_feature_topup_button"
                isLoading={isLoading}
                disabled={pDisabled || isLoading}
                onClick={openPayment}
              >
                {ctaText}
              </Button>
              {enablePayLater && (
                <Button className="ml-2" disabled={isLoading} onClick={payLaterCallback}>
                  {payLaterText}
                </Button>
              )}
              {renderCTAGuideText(ctaGuideText)}
            </div>
          );
        }}
      </FlutterwavePayment>
    );
  }

  if (paymentMethod === PAYMENT_METHOD.ONLINE) {
    return (
      <StripeBillingV2Wrapper
        amount={Number(topUpAmount)}
        currency={shopInfo.currencyCode}
        beforePayment={beforePayment}
        apiVersion={paymentMethodProps?.apiVersion}
        onPaymentSuccess={onPaymentSuccess}
        onError={onPaymentError}
        fireEventOnOrderId={["OS_ACC_BALANCE_UPDATE"]}
        fireEventOnPaymentSuccess={["OS_ACC_BALANCE_UPDATE"]}
        {...paymentMethodProps}
      >
        {({ openCardUpdateModal: openPayment, isLoading }) => {
          return (
            <div className="topup-footer-buttons-row">
              <Button
                className="pendo_feature_topup_button"
                isLoading={isLoading}
                disabled={pDisabled || isLoading}
                onClick={openPayment}
              >
                {ctaText}
              </Button>
              {enablePayLater && (
                <Button className="ml-2" disabled={isLoading} onClick={payLaterCallback}>
                  {payLaterText}
                </Button>
              )}
              {renderCTAGuideText(ctaGuideText)}
            </div>
          );
        }}
      </StripeBillingV2Wrapper>
    );
  }

  if (paymentMethod === PAYMENT_METHOD.STRIPE) {
    return (
      <PaymentModal
        ModalComponent={PAYMENT_MODEL[paymentMethodProps?.paymentModel]}
        showToastMessage={showToastMessage}
        packageName={paymentMethodProps?.packageName}
        apiVersion={paymentMethodProps?.apiVersion}
        amount={Number(topUpAmount)}
        beforePayment={beforePayment}
        onSubsciption={() => onTopUp()}
        containerClass="payment-top-up-modal"
      >
        {({ openPayment, isLoading }) => {
          return (
            <div className="topup-button-row">
              <Button
                isLoading={isTopUpPostLoading}
                disabled={isTopUpPostLoading}
                onClick={openPayment}
                className="pendo_feature_topup_button"
              >
                {ctaText}
              </Button>
              {enablePayLater && (
                <Button
                  className="ml-2"
                  disabled={pDisabled || isLoading}
                  onClick={payLaterCallback}
                >
                  {payLaterText}
                </Button>
              )}
              {renderCTAGuideText(ctaGuideText)}
            </div>
          );
        }}
      </PaymentModal>
    );
  }

  if (paymentMethod === PAYMENT_METHOD.PAYU_372426 || paymentMethod === PAYMENT_METHOD.PAYU) {
    return (
      <PayU
        amount={Number(topUpAmount)}
        currency={shopInfo?.currencyCode || marketplaceCurrency}
        beforePayment={beforePayment}
        onPaymentSuccess={onPaymentSuccess}
        onError={onPaymentError}
        fireEventOnOrderId={["OS_ACC_BALANCE_UPDATE", "TRANSACTION_LOG_UPDATE"]}
        fireEventOnPaymentSuccess={["OS_ACC_BALANCE_UPDATE", "TRANSACTION_LOG_UPDATE"]}
        fireEventOnPaymentFailed={["TRANSACTION_LOG_UPDATE"]}
        entityId={selectedEntityId}
        entityType={selectedEntityType}
        {...paymentMethodProps}
        {...paymentMethodOverideProps || {}}
      >
        {({ openPayment, isLoading }) => {
          return (
            <div className="payu-topup">
              {isLoading ? <OverlayLoader /> : null}
              <div className="topup-button-row">
                <Button
                  isLoading={isLoading}
                  disabled={pDisabled || isLoading}
                  onClick={openPayment}
                  className="pendo_feature_topup_button"
                >
                  {ctaText}
                </Button>
                {enablePayLater && (
                  <Button className="ml-2" disabled={isLoading} onClick={payLaterCallback}>
                    {payLaterText}
                  </Button>
                )}
              </div>
              {renderCTAGuideText(ctaGuideText)}
            </div>
          );
        }}
      </PayU>
    );
  }

  if (
    paymentMethod === PAYMENT_METHOD.TWOCTWOP_10011728 ||
    paymentMethod === PAYMENT_METHOD.TWOCTWOP_10011725
  ) {
    return (
      <TwoCTwoPPayment
        description={getRazorPayDescription()}
        amount={Number(topUpAmount)}
        currency={shopInfo.currencyCode}
        beforePayment={beforePayment}
        onPaymentSuccess={onPaymentSuccess}
        onError={onPaymentError}
        fireEventOnOrderId={["OS_ACC_BALANCE_UPDATE"]}
        fireEventOnPaymentSuccess={["OS_ACC_BALANCE_UPDATE"]}
        {...paymentMethodProps}
        forceNewOrderId={forceNewOrderId}
      >
        {({ openPayment, isLoading }) => {
          return (
            <>
              <Button
                className="pendo_feature_topup_button"
                isLoading={isLoading}
                disabled={pDisabled || isLoading}
                onClick={openPayment}
              >
                {ctaText}
              </Button>
              {renderCTAGuideText(ctaGuideText)}
            </>
          );
        }}
      </TwoCTwoPPayment>
    );
  }

  if (paymentMethod === PAYMENT_METHOD.CUSTOM_ROUTE) {
    const { urlToRedirect } = paymentMethodProps;
    let urlToUse = urlToRedirect.replace("__CLIENT_ID__", clientId);
    urlToUse = urlToUse.replace("__BALANCE_TO_ADD__", topUpAmount);
    return (
      <div className="topup-button-row">
        <Button
          disabled={pDisabled}
          className="pendo_feature_topup_button"
          onClick={async () => {
            try {
              await beforePayment();
            } catch (err) {
              return null;
            }
            changeClientId("CLIENT_AGGREGATOR");
            redirectUrl(urlToUse);
          }}
        >
          {ctaText}
        </Button>
        {renderCTAGuideText(ctaGuideText)}
      </div>
    );
  }

  return (
    <>
      {renderTnc()}
      <Button
        isLoading={pIsLoading || isTopUpPostLoading}
        disabled={pDisabled || isTopUpPostLoading}
        onClick={onClickPayment}
        className="pendo_feature_topup_button"
      >
        {ctaText}
      </Button>
    </>
  );
};

TopupButton.defaultProps = {
  ctaText: "Topup",
  successMessage: "Your payment was successful. Your ad wallet will be credited in the next 15 mins.",
  errorMessage: "Internal error has occurred while top-up",
};

const mapStateToProps = (state, ownProps) => {
  const {
    clientId,
    shopInfo,
    userInfo,
    selectedEntityId,
    selectedEntityType,
    entityInfo,
    entityMetadata,
  } = state.Application;
  const { commonConfigs, marketplaceCurrency } = state.DomainConfig;

  return {
    ...(commonConfigs?.topUpButtonProps || {}),
    ...ownProps,
    clientId,
    shopInfo,
    userInfo,
    entityInfo,
    entityMetadata,
    onboardingDetails: state.OnBoarding.data,
    selectedEntityId,
    selectedEntityType,
    marketplaceCurrency,
  };
};

export default connect(mapStateToProps)(OSHOCWithUtilities(TopupButton));
