import React, { useEffect, useState, useRef, useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Trans, useTranslation } from "react-i18next";
import { isAfter } from "date-fns/esm";

import { OverlayLoader, loaderWhite } from "@onlinesales-ai/loader-v2";
import { ErrorMessage, EmptyMessage } from "@onlinesales-ai/message-card-v2";
import { ModalWindow } from "@onlinesales-ai/modal-window-v2";
import { Button } from "@onlinesales-ai/button-v2";
import { FormWrapper } from "@onlinesales-ai/form-components-v2";
import {Dropdown} from "@onlinesales-ai/dropdown-v2";
import {Input} from "@onlinesales-ai/input-v2";
import { OSHOCWithUtilities } from "@onlinesales-ai/os-hoc-with-utilities-v2";
import AsyncImage from "@onlinesales-ai/async-image-v2";
import { Drawer } from "@onlinesales-ai/drawer-v2";
import { alVendorEnum as vendorEnum } from "@onlinesales-ai/constants-v2";

import { format, populateEvent, isGMBLocationUnVerified } from "@onlinesales-ai/util-methods-v2";
import { StarkService, BaseClient } from "@onlinesales-ai/services-v2";
import {refreshVendorAccountsData as refreshVendorAccountsDataAction} from "@onlinesales-ai/account-linking-v2/src/store/actions";
import { defaultVendorConfig } from "@onlinesales-ai/account-linking-v2/src/utilities/config";

import "./index.less";

const fireIntercomEvents = (action, metaData) => {
  populateEvent("APPLICATION", `GMB_VERIFICATION_MODAL||${action}`, metaData);
};

const VERIFICATION_OPTIONS_CONFIG = {
  AUTO: {
    key: "AUTO",
    priority: 1,
    displayName: "Auto"
  },
  SMS: {
    key: "SMS",
    priority: 2,
    displayName: "SMS"
  },
  ADDRESS: {
    key: "ADDRESS",
    priority: 4,
    displayName: "Post Card"
  },
  PHONE_CALL: {
    key: "PHONE_CALL",
    priority: 3,
    displayName: "Call"
  }
};

const COMPONENT_STATES = {
  VERIFICATION_LIST: "VERIFICATION_LIST",
  PIN_VERIFICATION: "PIN_VERIFICATION"
};

const CustomOption = ({data, innerProps = {}, isDisabled, ...rest}) => {
  const {method, createTime} = data;
  return (
    <div {...innerProps} className={`custom-select-option gmb-custom-select-option ${isDisabled ? "disabled" : ""}`}>
      <Trans>Verification by {VERIFICATION_OPTIONS_CONFIG[method].displayName}</Trans> ({format(new Date(createTime), "do MMM | hh:mm")})
    </div>
  );
};

const GMBLocationVerificationModal = ({
  //Passed from outside
  languageCode,
  isShow,
  onToggle = () => {},
  jobDoneCallback = () => {},
  onExited,
  containerClass = "",
  isMobile,
  
  //Other props
  clientId,
  refreshVendorAccountsData,
  showToastMessage = () => {}
}) => {
  const {t} = useTranslation();
  const initialState = {
    state: COMPONENT_STATES.VERIFICATION_LIST,
    pendingVerifications: [],
    verificationOptions: [],
    selectedPendingVerification: null,
    isPinVerificationInProgress: false,
    initiateVerificationInProgressFor: null
  };

  const [isVerified, setIsVerified] = useState(false);
  const [isVerificationCheckInProgess, setIsVerificationCheckInProgress] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [pin, setPin] = useState("");
  const [currentVerificationStatus, setCurrentVerificationStatus] = useState({
    ...initialState
  });
  const processedPendingVerificaion = useMemo(() => {
    return currentVerificationStatus.pendingVerifications.map((verificationInfo, index) => {
      return {
        ...verificationInfo,
        value: `${index}`
      };
    });
  }, [currentVerificationStatus?.pendingVerifications]);

  const modalRef = useRef(null);

  useEffect(() => {
    if(isVerified){
      refreshGMBLocationData();
    }
  }, [isVerified]);

  const refreshGMBLocationData = async() => {
    try{
      const gmbAccountConfig = defaultVendorConfig[vendorEnum.GOOGLE]?.accountConfig?.googleMyBysiness;
      await refreshVendorAccountsData([gmbAccountConfig?.name], "application");
    }
    catch(e){}
  };

  useEffect(() => {
    if(isShow){
      fireIntercomEvents("GMB_VERIFICATION_OPENED");
      onRetry();
    }
  }, [isShow]);

  const onComponentMount = async() => {
    setIsVerificationCheckInProgress(true);
    try{
      const gmbAccountConfig = defaultVendorConfig[vendorEnum.GOOGLE]?.accountConfig?.googleMyBysiness;
      const vendorAccountData = await refreshVendorAccountsData([gmbAccountConfig?.name], "application");
      const gmbAccountData = vendorAccountData[gmbAccountConfig?.class];
      const isNotVerified = !gmbAccountData || isGMBLocationUnVerified(gmbAccountData);
      if(!isNotVerified){
        setIsVerified(true);
      }
      else{
        const verificationOptionsResponse = await StarkService.fetchGMBVerificationsOptions({
          clientId,
          languageCode
        }, "application");


        const verificationOptions = verificationOptionsResponse?.verificationOptions || [];

        verificationOptions.sort((verification1, verification2) => {
          return (VERIFICATION_OPTIONS_CONFIG[verification1.verificationMethod]?.priority - VERIFICATION_OPTIONS_CONFIG[verification2.verificationMethod]?.priority);
        });

        const currentVerificationsResponse = await StarkService.fetchCurrentGMBVerifications({
          clientId,
          pageSize: 100
        }, "application");

        let pendingVerifications = currentVerificationsResponse.verifications || [];
       
        pendingVerifications = pendingVerifications.filter((verificationInfo) => {
          return (verificationInfo.state === "PENDING");
        });

        if(pendingVerifications?.length){
          //Sort the pending verification if needed, Not needed is google is sending this way only
          // pendingVerifications.sort((verification1, verification2) => {
          //   return (isAfter(new Date(verification1.createTime), new Date(verification2.createTime))) ? -1 : 1;
          // });

          setCurrentVerificationStatus({
            state: COMPONENT_STATES.PIN_VERIFICATION,
            pendingVerifications,
            verificationOptions,
            selectedPendingVerification: "0"
          });
        }
        else{ 
          const isAutoOptionAvailable = verificationOptions.some((obj) => obj.verificationMethod === VERIFICATION_OPTIONS_CONFIG.AUTO.key);

          if(isAutoOptionAvailable){
            fireIntercomEvents("GMB_VERIFICATION_INITIATED", {verificationMethod: VERIFICATION_OPTIONS_CONFIG.AUTO.key});
            await StarkService.initiateGMBVerification({
              clientId,
              verification: {
                verificationMethod: VERIFICATION_OPTIONS_CONFIG.AUTO.key,
                languageCode
              }
            }, "application");

            setIsVerified(true);
          }
          else{
            setCurrentVerificationStatus({
              state: COMPONENT_STATES.VERIFICATION_LIST,
              pendingVerifications,
              verificationOptions,
              selectedPendingVerification: null
            });
          }
        }
      }
    }
    catch({errorMsg}){
      setErrorMsg(typeof error === "string" ? error : "We've encountered error while verification of your GMB Location");
    }
    finally{
      setIsVerificationCheckInProgress(false);
    }
  };

  const onRetry = () => {
    setIsVerified(false);
    setIsVerificationCheckInProgress(false);
    setErrorMsg(null);
    setPin("");
    setCurrentVerificationStatus({
      ...initialState
    })
    onComponentMount();
  };

  const onClickVerifyUsingAnotherMethod = () => {
    fireIntercomEvents("VERIFY_USING_ANOTHER_METHOD_CLICKED");
    setCurrentVerificationStatus({
      ...currentVerificationStatus,
      state: COMPONENT_STATES.VERIFICATION_LIST,
      isPinVerificationInProgress: false,
      initiateVerificationInProgressFor: null
    });
    setPin("");
  };

  const onClickEnterPinAndVerify = () => {
    fireIntercomEvents("HAVE_A_PIN_VERIFY_NOW_CLICKED");
    setCurrentVerificationStatus({
      ...currentVerificationStatus,
      state: COMPONENT_STATES.PIN_VERIFICATION,
      isPinVerificationInProgress: false,
      initiateVerificationInProgressFor: null
    });
  };

  const onInitiateVerificaion = async ({verificationMethod, ...rest}) => {
    try{
      fireIntercomEvents("GMB_VERIFICATION_INITIATED", {verificationMethod, ...rest});
      setCurrentVerificationStatus({
        ...currentVerificationStatus,
        initiateVerificationInProgressFor: verificationMethod
      });

      const response = await StarkService.initiateGMBVerification({
        clientId,
        verification: {
          verificationMethod,
          ...rest,
          languageCode
        }
      }, "application");

      if(response.verification){
        fireIntercomEvents("GMB_VERIFICATION_INITIATION_SUCCESS", {verificationMethod, ...rest});

        let successMsg = "";

        if(verificationMethod === VERIFICATION_OPTIONS_CONFIG.SMS.key){
          successMsg = "PIN has been sent via SMS and will be valid for next 30 mins";
        }
        else if(verificationMethod === VERIFICATION_OPTIONS_CONFIG.PHONE_CALL.key){
          successMsg = "Your request has been submitted, You will receive the call shortly.";
        }
        else if(verificationMethod === VERIFICATION_OPTIONS_CONFIG.ADDRESS.key){
          successMsg = t(`PIN will been sent to you via Post Card on your address in approximately {{noOfDays}} days.`, {noOfDays: rest?.addressData?.expectedDeliveryDaysRegion || 15});
        }
        
        if(successMsg){
          showToastMessage({
            messageToDisplay: successMsg,
            type: "SUCCESS",
            usePortal: true,
            portalDOMNode: modalRef.current
          });
        }

        let newPendingVerifications = [
            ...(currentVerificationStatus.pendingVerifications || [])
        ];

        newPendingVerifications = newPendingVerifications.filter((verificationInfo) => {
          return (verificationInfo.method !== verificationMethod);
        });

        setCurrentVerificationStatus({
          ...currentVerificationStatus,
          state: COMPONENT_STATES.PIN_VERIFICATION,
          pendingVerifications: [
            response.verification,
            ...(newPendingVerifications)
          ],
          selectedPendingVerification: "0",
          isPinVerificationInProgress: false,
          initiateVerificationInProgressFor: null
        });
      }
      else{
        fireIntercomEvents("GMB_VERIFICATION_INITIATION_FAILED", {verificationMethod, ...rest});
        showToastMessage({
          messageToDisplay: BaseClient.getErrorMessage(),
          type: "ERROR",
          usePortal: true,
          portalDOMNode: modalRef.current
        });

        setCurrentVerificationStatus({
          ...currentVerificationStatus,
          initiateVerificationInProgressFor: null
        });
      }
    }
    catch(error){
      fireIntercomEvents("GMB_VERIFICATION_INITIATION_FAILED", {verificationMethod, ...rest});
      showToastMessage({
        messageToDisplay: error?.errorMsg || BaseClient.getErrorMessage(),
        type: "ERROR",
        usePortal: true,
        portalDOMNode: modalRef.current
      });

      setCurrentVerificationStatus({
        ...currentVerificationStatus,
        initiateVerificationInProgressFor: null
      });
    }
  };

  const onVerifyGMBLocation = async () => {
    const {pendingVerifications = [], selectedPendingVerification} = currentVerificationStatus;
    try{
      if(!pin){
        showToastMessage({
          messageToDisplay: "Please enter PIN",
          type: "ERROR",
          usePortal: true,
          portalDOMNode: modalRef.current
        });
      }
      else if(!selectedPendingVerification || !pendingVerifications[selectedPendingVerification]){
        showToastMessage({
          messageToDisplay: BaseClient.getErrorMessage(),
          type: "ERROR",
          usePortal: true,
          portalDOMNode: modalRef.current
        });
      }
      else {
        fireIntercomEvents("GMB_VERIFICATION_VERIFY_PIN", {
          pin,
          ...(pendingVerifications[selectedPendingVerification] || {})
        });

        setCurrentVerificationStatus({
          ...currentVerificationStatus,
          isPinVerificationInProgress: true
        });

        const {name} = pendingVerifications[selectedPendingVerification];
        await StarkService.postGMBVerificationsOption({
          clientId,
          verification: {
            method: name,
            pin
          }
        }, "application");
        
        fireIntercomEvents("GMB_VERIFICATION_VERIFY_PIN_SUCCESS", {
          pin,
          ...(pendingVerifications[selectedPendingVerification] || {})
        });

        setIsVerified(true);
      }
    }
    catch(error){
      fireIntercomEvents("GMB_VERIFICATION_VERIFY_PIN_ERROR", {
        pin,
        ...(pendingVerifications[selectedPendingVerification] || {})
      });
      showToastMessage({
        messageToDisplay: "PIN verification failed. Please try again with the correct PIN.",
        type: "ERROR",
        usePortal: true,
        portalDOMNode: modalRef.current
      });
      
      setCurrentVerificationStatus({
        ...currentVerificationStatus,
        isPinVerificationInProgress: false
      });
    }
  };

  const renderErrorScreen = () => {
    return (
      <div className="error-screen-container">
        <div className="message">
          <span className="icon icon-warning-filled" />
          <span><Trans>Error while verifying your GMB location</Trans></span>
        </div>
        <Button outline icon="icon-repeat" onClick={onRetry}>Retry</Button>
      </div>
    )
  }

  const renderVerifiedScreen = () => {
    return (
      <div className="verified-screen-container">
        <div className="success-img">
          <AsyncImage imgSrc="https://osads.gumlet.io/image/upload/v1646024585/product/monetize/done.svg" />
        </div>
        <div className="message">
          <Trans>Congrats! Your Google My Business Location has been successfully verified!</Trans>
        </div>
      </div>
    )
  };

  const renderVerificationList = () => {
    const {verificationOptions = [], initiateVerificationInProgressFor} = currentVerificationStatus;

    return (
      <div className={`verification-list ${verificationOptions.length === 1 ? "only-one-item" : ""}`}>
        {initiateVerificationInProgressFor && <OverlayLoader />}
        {
          verificationOptions.length ? (
            <>
              <ol>
                {
                  verificationOptions.map(({ verificationMethod, ...rest }) => {
                    switch (verificationMethod) {
                      case VERIFICATION_OPTIONS_CONFIG.SMS.key: {
                        return (
                          <li className={`item ${verificationMethod}`}>
                            <div
                              className="item-content"
                              onClick={() => {
                                if (isMobile) {
                                  onInitiateVerificaion({ ...rest, verificationMethod })
                                }
                              }}
                            >
                              <div className="left">
                                <AsyncImage imgSrc="https://osads.gumlet.io/image/upload/v1645764748/product/monetize/verifyCode.svg"/>
                              </div>
                              <div className="right">
                                <div className="title"><Trans>Verify by SMS</Trans></div>
                                {
                                  rest.phoneNumber &&
                                  <div className="sub-title"><Trans>PIN will be sent to "{{contactNo: rest.phoneNumber}}"" via SMS.</Trans></div>
                                }
                              </div>
                            </div>
                            {!isMobile && (
                            <Button
                              isLoading={initiateVerificationInProgressFor === verificationMethod}
                              disabled={!!initiateVerificationInProgressFor}
                              onClick={() => onInitiateVerificaion({...rest, verificationMethod})}>
                              Send SMS
                            </Button>
                            )}
                          </li>
                        );
                      }
                      break;
                      case VERIFICATION_OPTIONS_CONFIG.PHONE_CALL.key: {
                        return (
                          <li className={`item ${verificationMethod}`}>
                            <div
                              className="item-content"
                              onClick={() => {
                                if (isMobile) {
                                  onInitiateVerificaion({ ...rest, verificationMethod })
                                }
                              }}
                            >
                              <div className="left">
                                <AsyncImage imgSrc="https://osads.gumlet.io/image/upload/v1645764748/product/monetize/verifyCall.svg"/>
                              </div>
                              <div className="right">
                                <div className="title"><Trans>Verify by Call</Trans></div>
                                {
                                  rest.phoneNumber &&
                                  <div className="sub-title"><Trans>Receive PIN via phone call on "{{contactNo: rest.phoneNumber}}".</Trans></div>
                                }
                              </div>
                            </div>
                            {!isMobile && (
                            <Button
                              isLoading={initiateVerificationInProgressFor === verificationMethod}
                              disabled={!!initiateVerificationInProgressFor}
                              onClick={() => onInitiateVerificaion({...rest, verificationMethod})}>
                              Call Now
                            </Button>
                            )}
                          </li>
                        );
                      }
                      break;
                      case VERIFICATION_OPTIONS_CONFIG.ADDRESS.key: {
                        return (
                          <li className={`item ${verificationMethod}`}>
                            <div
                              className="item-content"
                              onClick={() => {
                                if (isMobile) {
                                  onInitiateVerificaion({ ...rest, verificationMethod })
                                }
                              }}
                            >
                              <div className="left">
                                <AsyncImage imgSrc="https://osads.gumlet.io/image/upload/v1645764748/product/monetize/verifyMessage.svg"/>
                              </div>
                              <div className="right">
                                <div className="title"><Trans>Verify by Post Card</Trans></div>
                                {
                                  rest.addressData?.address &&
                                  (
                                    <>
                                      <div className="sub-title"><Trans>Post Card will be sent to following address:</Trans></div>
                                      <div className="address">
                                        {
                                          `${rest.addressData.business}, `
                                        }
                                        {rest.addressData?.address?.addressLines?.join(", ")}
                                        {
                                          !!rest.addressData?.address?.administrativeArea &&
                                          `, ${rest.addressData?.address?.administrativeArea}`
                                        }
                                        {
                                          !!rest.addressData?.address?.locality &&
                                          `, ${rest.addressData?.address?.locality}`
                                        }
                                        {
                                          !!rest.addressData?.address?.postalCode &&
                                          `, ${rest.addressData?.address?.postalCode}`
                                        }
                                      </div>
                                    </>
                                  )
                                }
                              </div>
                            </div>
                            {!isMobile && (
                            <Button
                              isLoading={initiateVerificationInProgressFor === verificationMethod}
                              disabled={!!initiateVerificationInProgressFor}
                              onClick={() => onInitiateVerificaion({...rest, verificationMethod})}>
                              Send PIN via Post
                            </Button>
                            )}
                          </li>
                        );
                      }
                      default:
                        return null;
                      break;
                    }
                  })
                }
            </ol>
            {
              currentVerificationStatus?.pendingVerifications?.length > 0 &&
              <div className="cta-container">
                <Button 
                  link
                  onClick={onClickEnterPinAndVerify}>
                    Have a PIN? Verify Now
                </Button>
              </div>
            }
            </>
          ) : (
            <EmptyMessage>
              No verification method available for your location
            </EmptyMessage>
          )
        }
     </div>
    )
  };

  const renderPinVerificationScreen = () => {
    const {isPinVerificationInProgress} = currentVerificationStatus;

    return (
      <div className="pin-verification-wrapper">
        <FormWrapper
          formGroupClassName={"pin-verification-form"}
          labelColumns={3}
          title={"Verification For:"}
        >
          <Dropdown
            type="default"
            placeholder="Choose"
            options={processedPendingVerificaion}
            components={{
              Option: CustomOption
            }}
            getOptionLabel={(data) => CustomOption({data})}
            onChange={(val) => {
              setCurrentVerificationStatus({
                ...currentVerificationStatus,
                selectedPendingVerification: val
              });
            }}
            value={currentVerificationStatus.selectedPendingVerification}
            isDisabled={false}
          />
        </FormWrapper>

        <FormWrapper
          formGroupClassName={"pin-verification-form"}
          labelColumns={3}
          title={"PIN:"}
        >
          <>
            <Input
              type="text"
              placeholder={t("Enter PIN here")}
              value={pin}
              onChange={setPin}
              inputWrapperClassName="border-padding gmb-pin-wrapper"
            />
            <Button
              isLoading={isPinVerificationInProgress}
              disabled={isPinVerificationInProgress}
              className="pin-verify-btn"
              onClick={onVerifyGMBLocation}>
                Verify
            </Button>
          </>
        </FormWrapper>
        <div className="cta-container">
          <Button 
            link
            onClick={onClickVerifyUsingAnotherMethod}>
              Verify using another method
          </Button>
        </div>
      </div>
    )
  };

  const renderPendingVerificationScreen = () => {
    if(currentVerificationStatus.state === COMPONENT_STATES.VERIFICATION_LIST){
      return renderVerificationList();
    }
    else if(currentVerificationStatus.state === COMPONENT_STATES.PIN_VERIFICATION){
      return renderPinVerificationScreen();
    }
  };

  const onModalDestroy = () => {
    if(isVerified){
      jobDoneCallback();
    }
    else{
      onToggle(false);
    }
  };

  return (
    <>{isMobile ? (
      <Drawer
        stopBodyClickPropagation
        maskClosable
        size={"large"}
        position={isMobile ? "bottom" : "left"}
        isMobileSize={isMobile}
        closebutton={false}
        containerClass="gmb-location-verification-drawer"
        isOpen={isShow}
        onClickClose={onModalDestroy}
        onExited={onExited}
        header="Google My Business Verification"
      >
        <Drawer.Body>
          <div className="gmb-location-verification-wrapper">
            {
              isVerificationCheckInProgess ? (
                <div className="modal-loader-container">
                  {loaderWhite()}
                  <div className="message">
                    <Trans>Verifying your Google My Business Location</Trans>
                  </div>
                </div>
              ) : (
                errorMsg ? (
                    renderErrorScreen()
                ) : (
                  isVerified ? (
                    renderVerifiedScreen()
                  ) : (
                    renderPendingVerificationScreen()
                  )
                )
              )
            }
          </div>
        </Drawer.Body>
      </Drawer>
    ) : (
      <ModalWindow
        closeButton
        isShow={isShow}
        onModalDestroy={onModalDestroy}
        onExited={onExited}
        containerClass={`gmb-location-verification-modal ${containerClass}`}
        headerTitle={"Google My Business Verification"}
        modalProps={{
          backdrop: "static",
        }}
      >
        <ModalWindow.Body ref={modalRef}>
          <div className="gmb-location-verification-wrapper">
            {
              isVerificationCheckInProgess ? (
                <div className="modal-loader-container">
                  {loaderWhite()}
                  <div className="message">
                    <Trans>Verifying your Google My Business Location</Trans>
                  </div>
                </div>
              ) : (
                errorMsg ? (
                    renderErrorScreen()
                ) : (
                  isVerified ? (
                    renderVerifiedScreen()
                  ) : (
                    renderPendingVerificationScreen()
                  )
                )
              )
            }
          </div>
        </ModalWindow.Body>
      </ModalWindow>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    vendorAccountData,
  } = state.AccountLinking || {};

  const { clientId } = state.Application || {};

  return {
    clientId,
    vendorAccountData,
    isMobile: state.Application.isMobile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      refreshVendorAccountsData: refreshVendorAccountsDataAction,
    },
    dispatch,
  );
};


export default connect(mapStateToProps, mapDispatchToProps)(OSHOCWithUtilities(GMBLocationVerificationModal));