import { Hades } from "@onlinesales-ai/services-v2";
import { uiAPIMonitor } from "@onlinesales-ai/error-catcher-v2";

import {
  processEntityMetadataFromAPI,
} from "../../src/utilities";

import {
  // upsertEntityMetadata,
  parseEntityData,
  updateEntityInfo,
  updateEntityMetadataList,
  setUserPermissions,
} from "../entityApplication";

import types from "./types";

export const setOnboardingData = (onBoardingData) => {
  return async (dispatch, getState) => {
    const state = getState();

    dispatch({
      type: types.SET_ONBOARDING_DETAILS,
      data: onBoardingData,
      domainConfig: state.DomainConfig,
    });
  };
};

export const setOnboardingDataFetchInProgress = (isLoading) => ({
  type: types.SET_ONBOARDING_FETCH_IN_PROGRESS,
  isLoading,
});

export const fetchOnBoardingData = ({ redirectUrl, routes }) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { selectedEntityId, selectedEntityType, agencyId } = state.Application;

    dispatch(setOnboardingDataFetchInProgress(true));

    const payload = {
      agencyId,
      entityId: selectedEntityId,
      entityType: selectedEntityType,
      selectors: [
        // "metadata.customLabel2",
      ],
    };

    try {
      const response = await Hades.getEntityDataBySelectors(payload, "onboarding_status_fetch");
      const metadata = processEntityMetadataFromAPI(response?.entity?.metadata);
      const { entityInfoToUpdate, entityMedataToUpdate } = parseEntityData([response?.entity]);

      dispatch(setOnboardingData(metadata?.onboardingData || {}));
      // dispatch(upsertEntityMetadata(`${selectedEntityType}_${selectedEntityId}`, metadata));
      dispatch(setUserPermissions(response?.entity?.permissions || {}));
      dispatch(updateEntityInfo(entityInfoToUpdate));
      dispatch(
        updateEntityMetadataList({ ...entityMedataToUpdate.clients, ...entityMedataToUpdate.mccs }),
      );
    } catch (err) {
      if (err.isAborted) {
        return;
      }
      uiAPIMonitor("SEV2", "APP_LEVEL_ENTITY_CONFIG_DATA_ERROR", { error: err });

      const errorMsg = err?.data?.exception?.error?.message;
      if (
        errorMsg === "UNAUTHORIZED" ||
        errorMsg === "application can not be null/empty" ||
        errorMsg === "Hades Authorization failed" ||
        errorMsg === "User Authentication failed"
      ) {
        redirectUrl(routes?.LOGIN?.path);
      } else {
        redirectUrl(routes?.DOWNTIME?.path);
      }
    } finally {
      dispatch(setOnboardingDataFetchInProgress(false));
    }
  };
};
