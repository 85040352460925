import _omit from "lodash/omit";
import { isAfter, isBefore } from "date-fns/esm";
import _keyBy from "lodash/keyBy";
import _uniq from "lodash/uniq";

import { StarkService } from "@onlinesales-ai/services-v2";
import { fetchBatchWise, asyncWait, getCurrencyCode } from "@onlinesales-ai/util-methods-v2";
import PlatformEventManager from "@onlinesales-ai/event-manager-v2";

import { keysToDelete, smmPostStatusEnum } from "../utilities/configs";
import Types from "./types";

export const setSMMPostsData = (posts) => ({
  type: Types.SET_SMM_POSTS_FETCH_DATA,
  posts,
});

export const setSMMPostsFetchInProgress = (dataFetchInProgress) => ({
  type: Types.SET_SMM_POSTS_FETCH_IN_PROGRESS,
  dataFetchInProgress,
});

export const setSMMPostsFetchError = (error) => ({
  type: Types.SET_SMM_POSTS_FETCH_ERROR,
  error,
});

export const updateSMMPostSuggestion = (postData) => ({
  type: Types.UPDATE_SMM_POST_SUGGESTION,
  postData,
});

export const updateSMMPostPollData = (postData) => ({
  type: Types.UPDATE_SMM_POST_POLL_DATA,
  postData,
});

export const takeActionOnSMMSuggestion = (postData = {}) => {
  return (dispatch) => {
    dispatch(updateSMMPostSuggestion(postData));
  };
};

export const setSMMPostSaveInProgress = (dataPostInProgress) => ({
  type: Types.SET_SMM_POST_SAVE_IN_PROGRESS,
  dataPostInProgress,
});

export const setSMMPostSaveError = (error) => ({
  type: Types.SET_SMM_POST_SAVE_ERROR,
  error,
});

export const deleteSMMPost = (id) => ({
  type: Types.DELETE_SMM_POST,
  id,
});

export const setFetchStateBoostData = (isLoading, errorMsg) => ({
  type: Types.SET_FETCH_STATE_BOOST_DATA,
  isLoading,
  errorMsg,
});

export const setBoostData = (data) => ({
  type: Types.SET_BOOST_DATA,
  data,
});

export const updateBoostData = (data) => ({
  type: Types.UPDATE_BOOST_DATA,
  data,
});

export const setFetchStateBoostCampaignData = (isLoading, errorMsg) => ({
  type: Types.SET_FETCH_STATE_BOOST_CAMPAIGN_DATA,
  isLoading,
  errorMsg,
});

export const setBoostCampaignData = (data) => ({
  type: Types.SET_BOOST_CAMPAIGN_DATA,
  data,
});

export const fetchPostData = ({
  vendor,
  sortBy,
  isAgency,
  application = "dashboard",
  filters = {},
  limit = 50,
  shouldFetchBatchWise = true,
  maxDataLimit,
  offset = 0,
  taggingFilters,
} = {}) => {
  return async (dispatch, getState) => {
    const state = getState();
    const payload = {
      filters: {
        ...filters,
      },
      limit,
      offset,
      sortBy,
      // taggingFilters, // TODO pending backend support
    };
    if (vendor) {
      payload.filters.vendors = {
        $in: [vendor],
      };
    }

    if (isAgency) {
      payload.agencyId = state.Application.agencyId;
      payload.fetchClientData = true;
    } else {
      payload.clientId = state.Application.clientId;
      payload.debug = true; // tmp hack to get vendorData
    }
    dispatch(setSMMPostsFetchInProgress(true));

    try {
      let allPosts = [];
      if (shouldFetchBatchWise) {
        allPosts = await fetchBatchWise({
          apiCall: isAgency
            ? StarkService.fetchBulkPosts.bind(StarkService)
            : StarkService.fetchPosts.bind(StarkService),
          payload,
          config: { limit, maxDataLimit },
          dataResolver: (response) => response?.posts || [],
          application: "smm",
        });
      } else {
        let response = {};
        if (isAgency) {
          response = await StarkService.fetchBulkPosts(payload, application);
        } else {
          response = await StarkService.fetchPosts(payload, application);
        }
        const { posts: postsFromResponse } = response || {};
        allPosts = postsFromResponse;
      }

      if (allPosts && allPosts.length) {
        dispatch(setSMMPostsData(allPosts));
      }
      dispatch(setSMMPostsFetchInProgress(false));
      return allPosts;
    } catch (err) {
      const { errorMsg } = err;
      dispatch(setSMMPostsFetchInProgress(false));
      dispatch(setSMMPostsFetchError(errorMsg));
      throw err;
    }
  };
};

export const savePostData = (payload, { isAgency = false, application = "smm" } = {}) => {
  return async (dispatch, getState) => {
    const state = getState();
    const api = isAgency ? StarkService.postBulkPosts : StarkService.postPosts;

    if (isAgency) {
      payload.agencyId = state.Application.agencyId;
    } else {
      payload.clientId = state.Application.clientId;
    }

    dispatch(setSMMPostSaveInProgress(true));

    try {
      const response = await api.call(StarkService, payload, application);

      if (payload.isSuggestedPost && payload.suggestionStatus === "DRAFT") {
        dispatch(updateSMMPostSuggestion(response));
      } else {
        dispatch(setSMMPostsData([response]));
      }

      dispatch(setSMMPostSaveInProgress(false));

      return response;
    } catch (err) {
      const errMsg = err?.errorMsg || StarkService.getErrorMessage();
      setSMMPostSaveError(errMsg);
      dispatch(setSMMPostSaveInProgress(false));
      throw errMsg;
    }
  };
};

export const deletePost = (id, { isAgency = false, application = "smm" } = {}) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { posts: boostPosts } = state.SMMBoosts?.data || {};

    const api = isAgency ? StarkService.deleteBulkPost : StarkService.deletePost;
    const payload = {
      id,
    };
    if (isAgency) {
      payload.agencyId = state.Application.agencyId;
    } else {
      payload.clientId = state.Application.clientId;
    }

    dispatch(setSMMPostSaveInProgress(true));

    let showBoostErrorMsg = false;
    if (boostPosts?.[id] && boostPosts?.[id]?.launchConfig?.boostStatus === "ACTIVE") {
      try {
        await dispatch(
          changeBulkBoostPostStatus({
            newStatus: "PAUSED",
            postsToStatusUpdate: [boostPosts[id]],
          }),
        );
        showBoostErrorMsg = true;
      } catch (err) {
        const errMsg = err?.errorMsg || StarkService.getErrorMessage();
        setSMMPostSaveError(errMsg);
        dispatch(setSMMPostSaveInProgress(false));
        throw errMsg;
      }
    }

    try {
      const response = await api.call(StarkService, payload, application);
      if (response?.status === "OK") {
        dispatch(deleteSMMPost(id));
      }

      dispatch(setSMMPostSaveInProgress(false));

      return response;
    } catch (err) {
      const errMsg = showBoostErrorMsg
        ? "Post boost paused successfully. Please retry to delete the post."
        : err?.errorMsg || StarkService.getErrorMessage();
      setSMMPostSaveError(errMsg);
      dispatch(setSMMPostSaveInProgress(false));
      throw { errMsg, type: showBoostErrorMsg ? "WARNING" : "ERROR" };
    }
  };
};

const STATUS_TO_POLL = [smmPostStatusEnum.PROCESSING.key];

const postPolling = async (dispatch, getState, config) => {
  const { SMMPosts, Application } = getState();
  const { postData = {} } = SMMPosts || {};
  const { isAgency } = config || {};

  const postList = Object.values(postData);

  const idsToPoll = postList
    .filter((post) => STATUS_TO_POLL.includes(post.status))
    .map((post) => post.id);

  if (idsToPoll.length) {
    const payload = {
      filters: {
        ids: idsToPoll,
      },
    };

    if (isAgency) {
      payload.agencyId = Application.agencyId;
      payload.fetchClientData = true;
    } else {
      payload.clientId = Application.clientId;
    }

    try {
      const allPosts = await fetchBatchWise({
        apiCall: isAgency
          ? StarkService.fetchBulkPosts.bind(StarkService)
          : StarkService.fetchPosts.bind(StarkService),
        payload,
        config: { limit: 50 },
        dataResolver: (response) => response?.posts || [],
        application: "smm",
      });

      dispatch(updateSMMPostPollData(allPosts));
      const processingToActive = allPosts?.filter(({ status } = {}) => {
        return status === smmPostStatusEnum.ACTIVE.key;
      });
      if (processingToActive?.length) {
        PlatformEventManager.emit("SMM_POST_UPDATE"); // to refresh post table
      }
    } catch (err) {}
  }
};

export const pollData = (config) => {
  return (dispatch, getState) => {
    let poll = true;

    return {
      startPoll: async () => {
        while (poll) {
          if (poll) {
            try {
              await postPolling(dispatch, getState, config);
            } catch (err) {}
          }
          await asyncWait(5000);
        }
      },
      stopPoll: () => {
        poll = false;
      },
    };
  };
};

export const fetchReviewAndComment = (config = {}) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { clientId } = state.Application;

    const payload = {
      clientId,
      filters: {
        vendor: {
          $in: ["GMB", "FACEBOOK"],
        },
        $or: [
          {
            vendor: "FACEBOOK",
            "vendorData.postId": {
              $exists: true,
            },
          },
          {
            vendor: "FACEBOOK",
            commentType: "PAGE_REVIEW",
          },
          {
            vendor: "GMB",
          },
        ],
        ...(config.filters || {}),
      },
      groupBy: ["clientId", "vendor", "commentType", "date"],
      orderBy: [
        {
          key: "commentTimestamp",
          order: "DESC",
        },
      ],
      fetchPostData: typeof config.fetchPostData === "undefined" ? true : config.fetchPostData,
      limit: config.limit || 8,
      offset: config.offset || 0,
      taggingFilters: config?.taggingFilters
    };

    if (config.agencyId) {
      delete payload.clientId;
      payload.agencyId = config.agencyId;
    }

    if (config.filterChannel) {
      payload.filters.vendor = config.filterChannel;
    }

    if (config.filterSentiment) {
      payload.filters["sentimentData.sentiment"] = config.filterSentiment;
    }

    if (config.fetchPageReview && config.pageId) {
      payload.filters["vendorData.pageId"] = config.pageId;
    }

    if (config.fetchPostComment && config.postId) {
      payload.filters["vendorData.postId"] = config.postId;
    }

    if (config.fetchPageReview && config.locationId) {
      payload.filters["vendorData.locationId"] = config.locationId;
    }

    if (config.commentId) {
      payload.filters.id = config.commentId;
    }

    const apiCall = (
      config.agencyId ?
        StarkService.fetchAgencyComments.bind(StarkService) :
        StarkService.fetchComments.bind(StarkService)
    );

    const response = await apiCall(payload, "smm", {
      abortId: config.abortId,
    });

    const { data } = response || {};

    return data;
  };
};

export const fetchCommentReviewsFromVendor = (config) => {
  return async (dispatch, getState) => {
    const state = getState();
    return new Promise(async (resolve, reject) => {
      const ServiceCall = (
        config?.vendor === "GMB" ?
          StarkService.fetchRealtimeReviews.bind(StarkService) :
          StarkService.fetchCommentReplies.bind(StarkService)
      );
      try {
        const response = await ServiceCall({
          clientId: state.Application.clientId,
          ...config,
        });

        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };
};

export const prepareBoostPostData = ({ postData }) => {
  const budgetType = postData.launchConfig?.budgetType || "SHARED";

  const data = {
    budgetType,
    postId: postData.id,
    vendor: "FACEBOOK",
    osVendorPostId: postData.posts.find((p) => p.vendor === "FACEBOOK").id,
    boostStatus: postData.launchConfig?.boostStatus || "ACTIVE",
    marketingCampaign: {},
    ...postData.launchConfig,
  };

  if (budgetType === "INDIVIDUAL") {
    data.marketingCampaign = {
      currency: getCurrencyCode(),
      ...(postData.launchConfig?.marketingCampaign || {}),
    };
  }

  if (budgetType === "SHARED") {
    delete data.marketingCampaign;
  }

  if (data.marketingCampaign) {
    data.marketingCampaign.scheduleType =
      data.marketingCampaign?.startTime && data.marketingCampaign?.endTime ? "MANUAL" : "AUTO";
  }

  return data;
};

export const processBoostPost = (response) => {
  const posts = response?.posts || [];
  return {
    ...response,
    posts: _keyBy(posts, "id"),
    postIds: posts.map((p) => p.id),
  };
};

export const processUpdatedBoostPost = ({ postsToAdd, ...response }) => {
  let posts = response?.posts || [];

  posts = posts.map((p) => {
    if (postsToAdd) {
      const post = postsToAdd.find((pa) => pa.id === p.postId);
      if (post) {
        return { ...post, launchConfig: p };
      }
    }

    return { launchConfig: p };
  });

  return {
    ...response,
    posts: _keyBy(posts, "launchConfig.postId"),
    postIds: posts.map((p) => p.launchConfig.postId),
  };
};

export const computeTotalBoostBudget = (boostData) => {
  const sharedBudget = boostData?.marketingCampaign?.budget || 0;
  let individualTotalBudget = 0;

  if (boostData?.postIds?.length) {
    const posts = Object.values(boostData.posts);
    posts.forEach((post) => {
      let isActive =
        post?.launchConfig?.budgetType === "INDIVIDUAL" &&
        post?.launchConfig?.boostStatus === "ACTIVE";

      if (
        isActive &&
        post.launchConfig?.marketingCampaign?.startTime &&
        post.launchConfig?.marketingCampaign?.endTime
      ) {
        isActive =
          isAfter(new Date(), new Date(post.launchConfig.marketingCampaign.startTime)) &&
          isBefore(new Date(), new Date(post.launchConfig.marketingCampaign.endTime));
      }

      if (isActive) {
        individualTotalBudget += post.launchConfig.marketingCampaign?.budget || 0;
      }
    });
  }

  return {
    sharedBudget,
    individualTotalBudget,
    totalDailyBudget: sharedBudget + individualTotalBudget,
  };
};

export const getAllMarketingCampaginIdsfromBoostPost = ({
  boostData,
  allNonFilteredPosts,
  currentIds,
}) => {
  const ids = [];

  if (boostData?.marketingCampaign?.id) {
    ids.push(boostData.marketingCampaign.id);
  }

  if (boostData?.postIds?.length) {
    const posts = [...Object.values(boostData.posts), ...(allNonFilteredPosts || [])];
    posts.forEach((post) => {
      if (post.launchConfig.budgetType === "INDIVIDUAL") {
        ids.push(post.launchConfig.marketingCampaign.id);
      }
    });
  }

  if (currentIds?.length) {
    ids.push(...currentIds);
  }

  return _uniq(ids);
};

export const getBoostCampaignStatus = (boostData) => {
  let status = "DRAFT";

  if (boostData?.postIds?.length) {
    const posts = Object.values(boostData.posts);
    for (let i = 0; i < posts.length; i++) {
      const post = posts[i];
      let isActive = post?.launchConfig?.boostStatus === "ACTIVE";
      const isPaused = post?.launchConfig?.boostStatus === "PAUSED";

      if (isPaused) {
        status = "PAUSED";
        continue;
      }

      if (
        isActive &&
        post?.launchConfig?.budgetType === "INDIVIDUAL" &&
        post.launchConfig?.marketingCampaign?.startTime &&
        post.launchConfig?.marketingCampaign?.endTime
      ) {
        isActive = isBefore(new Date(), new Date(post.launchConfig.marketingCampaign.endTime));
      }

      if (isActive) {
        status = "ACTIVE";
      }

      if (status === "ACTIVE") {
        break;
      }
    }
  }

  return status;
};

export const computeAndSaveBoostCampaignData = (boostData, allNonFilteredPosts) => {
  return async (dispatch, getState) => {
    const state = getState();
    const storeBoostData = state.SMMBoosts || {};

    const boostDataToConsider = boostData || storeBoostData?.data || {};

    const budgets = computeTotalBoostBudget(boostDataToConsider);

    dispatch(
      setBoostCampaignData({
        status: getBoostCampaignStatus(boostDataToConsider),
        marketingCampaigns: getAllMarketingCampaginIdsfromBoostPost({
          boostData: boostDataToConsider,
          allNonFilteredPosts,
          currentIds: storeBoostData?.boostCampaginData?.data?.marketingCampaigns || [],
        }),
        ...budgets,
      }),
    );
  };
};

export const fetchBoostPostData = (config) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { clientId } = state.Application || {};

    if (config.saveBoostCampagin && config.showCampaginLoading) {
      dispatch(setFetchStateBoostCampaignData(true, false));
    }
    dispatch(setFetchStateBoostData(true, false));

    try {
      let response = {};
      const allPostResponse = await fetchBatchWise({
        apiCall: StarkService.fetchBoostPosts.bind(StarkService),
        payload: {
          clientId,
          ...config,
          filters: {
            ...(config.filters || {}),
          },
        },
        config: { limit: 50 },
        dataResolver: ({ posts = [], ...other }) => {
          response = other;
          return posts;
        },
        application: "smm",
      });

      const postResponse = allPostResponse.filter((post) => {
        if (post.status !== "DELETED" && post.vendors.includes("FACEBOOK")) {
          return true;
        }
        return false;
      });

      const boostDataToSave = processBoostPost({
        ...response,
        posts: postResponse,
      });

      dispatch(setBoostData(boostDataToSave));

      if (config.saveBoostCampagin) {
        dispatch(computeAndSaveBoostCampaignData(boostDataToSave, allPostResponse));
      }

      dispatch(setFetchStateBoostData(false, false));
      if (config.saveBoostCampagin && config.showCampaginLoading) {
        dispatch(setFetchStateBoostCampaignData(false, false));
      }
    } catch (err) {
      dispatch(setFetchStateBoostData(false, err.errorMsg));
      if (config.saveBoostCampagin && config.showCampaginLoading) {
        dispatch(setFetchStateBoostCampaignData(false, err.errorMsg));
      }
      return Promise.reject(err);
    }
  };
};

export const updateBoostPostData = ({ dataToUpdate, postsToAdd }) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { clientId, userInfo } = state.Application || {};
    const { smmBoostConfig } = state.DomainConfig || {};
    const { marketingCampaign, posts } = state.SMMBoosts?.data || {};

    const payload = {
      userId: userInfo.id,
      clientId,
      country: smmBoostConfig.country,
      posts: [],
      ...dataToUpdate,
      marketingCampaign: {
        currency: getCurrencyCode(),
        ...marketingCampaign,
        ...(dataToUpdate?.marketingCampaign || {}),
      },
    };

    // const allKeys = _uniq([
    //   ...Object.keys(posts || {}),
    //   ...(dataToUpdate?.posts?.map?.((d) => d.postId) || []),
    // ]);

    // if (allKeys.length) {
    //   const isAllPaused = allKeys.every((key) => {
    //     const postToCheck =
    //       dataToUpdate?.posts?.find?.((p) => p.postId === key) || posts[key]?.launchConfig;

    //     return postToCheck?.boostStatus === "PAUSED";
    //   });

    //   if (isAllPaused) {
    //     payload.marketingCampaign.budget = 0;
    //   }
    // }

    try {
      const response = await StarkService.postBoostPost(payload);
      dispatch(
        updateBoostData(
          processUpdatedBoostPost({
            ...response,
            postsToAdd,
          }),
        ),
      );
    } catch (err) {
      return Promise.reject(err);
    }
  };
};

export const changeBulkBoostPostStatus = ({ newStatus, postsToStatusUpdate }) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { posts, marketingCampaign: stateMarketingCampaign } = state.SMMBoosts?.data || {};
    // const { singlePostMinDailyBudget = 0 } = state?.DomainConfig?.smmBoostConfig?.postDrawerConfig || {};

    const postsToCheck = postsToStatusUpdate || Object.values(posts);
    // const marketingCampaign = {};

    const postsToUpdate = [];
    if (newStatus === "PAUSED") {
      postsToCheck.forEach((post) => {
        if (post.launchConfig?.boostStatus === "ACTIVE") {
          postsToUpdate.push(post);
        }
      });
    } else if (newStatus === "ACTIVE") {
      postsToCheck.forEach((post) => {
        if (post.launchConfig?.boostStatus === "PAUSED") {
          let shouldChange = post.launchConfig?.budgetType === "SHARED";

          if (!shouldChange && post.launchConfig?.budgetType === "INDIVIDUAL") {
            if (
              post.launchConfig?.marketingCampaign?.startTime &&
              post.launchConfig?.marketingCampaign?.endTime
            ) {
              if (isBefore(new Date(), new Date(post.launchConfig.marketingCampaign.endTime))) {
                shouldChange = true;
              }
            } else {
              shouldChange = true;
            }
          }

          if (shouldChange) {
            postsToUpdate.push(post);
          }
        }
      });

      // const sharedPosts = postsToUpdate.filter((p) => p.launchConfig?.budgetType === "SHARED");

      // marketingCampaign.budget =
      //   stateMarketingCampaign.budget + sharedPosts.length * singlePostMinDailyBudget;
    }

    await dispatch(
      updateBoostPostData({
        dataToUpdate: {
          // marketingCampaign,
          posts: postsToUpdate.map((post) => {
            return prepareBoostPostData({
              postData: {
                ...post,
                launchConfig: {
                  ...post.launchConfig,
                  boostStatus: newStatus,
                },
              },
            });
          }),
        },
      }),
    );

    dispatch(computeAndSaveBoostCampaignData());
  };
};

export const pausedBoostDeletedPost = (id) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { posts: boostPosts } = state.SMMBoosts?.data || {};

    if (boostPosts?.[id] && boostPosts?.[id]?.launchConfig?.boostStatus === "ACTIVE") {
      return dispatch(
        changeBulkBoostPostStatus({
          newStatus: "PAUSED",
          postsToStatusUpdate: [boostPosts[id]],
        }),
      );
    }

    return false;
  };
};

// export const fetchBoostCampaignData = (config = {}) => {
//   return async (dispatch, getState) => {
//     const state = getState();
//     const { clientId } = state.Application || {};

//     if (!config.doNotShowLoading) {
//       dispatch(setFetchStateBoostCampaignData(true, false));
//     }

//     try {
//       const response = await StarkService.fetchBoostCampaignData({
//         clientId,
//       });

//       dispatch(setBoostCampaignData(response));
//       if (!config.doNotShowLoading) {
//         dispatch(setFetchStateBoostCampaignData(false, false));
//       }
//     } catch (err) {
//       if (!config.doNotShowLoading) {
//         dispatch(setFetchStateBoostCampaignData(false, err.errorMsg));
//       }
//       return Promise.reject(err);
//     }
//   };
// };

const BOOST_STATUS_TO_POLL = ["PROCESSING"];

const boostPostPolling = async (dispatch, getState) => {
  const { SMMBoosts: boostData, Application } = getState();

  if (!boostData?.data?.postIds?.length) {
    return null;
  }

  const posts = Object.values(boostData.data.posts);

  const idsToPoll = posts
    .filter((post) => BOOST_STATUS_TO_POLL.includes(post?.launchConfig?.boostCompletionStatus))
    .map((post) => post.id);

  if (idsToPoll.length) {
    const payload = {
      clientId: Application.clientId,
      filters: {
        ids: idsToPoll,
      },
    };

    try {
      const postResponse = await fetchBatchWise({
        apiCall: StarkService.fetchBoostPosts.bind(StarkService),
        payload,
        config: { limit: 50 },
        dataResolver: (res) => res?.posts || [],
        application: "smm",
      });

      const nonProcessingPosts = postResponse?.filter((post) => {
        return post?.launchConfig?.boostCompletionStatus !== "PROCESSING";
      });
      if (nonProcessingPosts?.length) {
        dispatch(
          updateBoostData(
            processBoostPost({
              posts: nonProcessingPosts,
            }),
          ),
        );
        PlatformEventManager.emit("SMM_BOOST_POST_UPDATE");
      }
    } catch (err) {}
  }
};

export const pollBoostPostData = (config) => {
  return (dispatch, getState) => {
    let poll = true;

    return {
      startPoll: async () => {
        while (poll) {
          if (poll) {
            try {
              await boostPostPolling(dispatch, getState, config);
            } catch (err) {}
          }
          await asyncWait(30000);
        }
      },
      stopPoll: () => {
        poll = false;
      },
    };
  };
};

export const retryPost = (postData) => {
  return async (dispatch, getState) => {
    const state = getState();
    const posts = [];
    const clients = [];
    return new Promise(async (resolve, reject) => {
      try {
        const responce = await StarkService.postBulkPostsClients({
          agencyId: state.Application.agencyId,
          id: postData?.id,
          filters: {
            status: "ACTIVE",
          },
          limit: 1000,
          offset: 0,
        });
        responce.clients.map((value) => {
          if (value.finalStatus === "FAILED") {
            clients.push({
              clientId: value.clientId,
              status: "ACTIVE",
            });
          }
        });
        postData.posts.forEach((postInfo) => {
          const data = _omit(postInfo, keysToDelete.value);
          data.status = "ACTIVE";
          posts.push(data);
        });
        const payload = { ...postData, clients, posts };
        try {
          const value = await dispatch(savePostData(payload, { isAgency: true }));
          resolve(value);
        } catch (error) {
          throw new Error(error);
        }
      } catch (err) {
        reject(err);
      }
    });
  };
};
