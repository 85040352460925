import BaseClient from "../baseClient/index";
import { OSBillingService } from "../index";

const servicePrefix = "/osBillingServiceV2";

const errorCodeParse = ({ data }) => {
  return data?.details?.error?.code;
};
class OSBillingServiceV2Client extends BaseClient {
  static getErrorMessages() {
    return {
      ...(OSBillingService.getErrorMessages() || {}),
      default: "Looks like we are facing some internal issue. Please, retry after sometime.",
      IP0000:
        "It seems some input parameters are not valid. Please update your inputs and try again.",
      AD0000:
        "Oops. It seems you are not authorized to take this action. Please log in with appropriate credentials.",
      I0004:
        "Oops. Seems the system is acting up - sorry for the inconvenience. Please try again in some time. (Please be assured our tech geniuses have been notified of this glitch).",
      APIERR023: "Coupon code used is invalid, please check coupon",
      ADCARD0000: "Coupon has already been utilised",
      TRANSFER_CHARGE_ERROR: "Looks like we are facing some internal issue. Please, retry after sometime.",
      INVALID_CART_ERROR: "Cart details are invalid, please verify the same and try again.",
      TRANSFER_CHARGE_AMOUNT_INVALID: "Amount to transfer is invalid, please verify the amount and try again.",
      "CHECKOUT_PENDING_ERROR": "Oops! One of the selected outlet already has a pending order, Please re-try after some time.",
      "INVALID_BALANCE_DATA": "Your cart information is outdated, please refresh and try again.",
      APISD002: "Maximum limit to deduct from COD balance has been reached.",
    };
  }

  static charge(payload, application) {
    const request = JSON.stringify(payload);

    return this.apiCall(
      {
        url: `${servicePrefix}/charge`,
        method: "POST",
        data: request,
      },
      application,
    );
  }

  static smmDeductAmount(payload, application) {
    const request = JSON.stringify(payload);

    return this.apiCall(
      {
        url: `${servicePrefix}/smm/deduct`,
        method: "POST",
        data: request,
      },
      application,
    );
  }

  static fetchClientsStatusForUser(config = {}, application,options) {
    return this.apiCall(
      {
        url: `${servicePrefix}/payment/fetch`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(config),
        },
      },
      application, options
    );
  }

  static fetchOrdersForUser(config = {}, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}/payment/transactions/marketplace`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(config),
        },
      },
      application,
    );
  }

  static fetchOrdersForAgency(config = {}, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}/payment/transactions/agencies`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(config),
        },
      },
      application,
    );
  }

  static fetchClientPendingOrdersForUser(config = {}, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}/payment/pending/client`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(config),
        },
      },
      application,
    );
  }

  static fetchAgencyPlanDetails(config = {}, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}/payment/packages`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(config),
        },
      },
      application,
    );
  }

  static fetchClientPlanDetails(config = {}, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}/payment/packages/client`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(config),
        },
      },
      application,
    );
  }

  static fetchBillingOffers(config = {}, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}/payment/offers`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(config),
        },
      },
      application,
    );
  }

  static fetchCartDetails(config = {}, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}/payment/cart`,
        method: "POST",
        data: JSON.stringify(config),
      },
      application,
      options,
    );
  }

  static fetchClientCartDetails(config = {}, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}/payment/cart/client`,
        method: "POST",
        data: JSON.stringify(config),
      },
      application,
    );
  }

  static postTopup(payload, application) {
    const request = JSON.stringify(payload);
    return this.apiCall(
      {
        url: `${servicePrefix}/payment/cart/checkout`,
        method: "POST",
        data: request,
        errorCodeParse
      },
      application,
    );
  }

  static postClientTopup(payload, application) {
    const request = encodeURIComponent(JSON.stringify(payload));
    return this.apiCall(
      {
        url: `${servicePrefix}/payment/cart/checkout/client`,
        method: "POST",
        data: request,
        headers: {
          "Content-Type": "text/plain",
        },
        errorCodeParse
      },
      application,
    );
  }

  static postSubscription(payload, application) {
    const request = JSON.stringify(payload);
    return this.apiCall(
      {
        url: `${servicePrefix}/payment/cart/subscribe`,
        method: "POST",
        data: request,
        errorCodeParse
      },
      application,
    );
  }

  static postSubscriptionClient(payload, application) {
    const request = JSON.stringify(payload);
    return this.apiCall(
      {
        url: `${servicePrefix}/payment/cart/subscribe/client`,
        method: "POST",
        data: request,
        errorCodeParse
      },
      application,
    );
  }

  static postDeactivate(payload, application) {
    const request = JSON.stringify(payload);
    return this.apiCall(
      {
        url: `${servicePrefix}/payment/deactivate`,
        method: "POST",
        data: request,
      },
      application,
    );
  }

  static chargeTransfer(payload, application) {
    const request = JSON.stringify(payload);
    return this.apiCall(
      {
        url: `${servicePrefix}/payment/transfer`,
        method: "POST",
        data: request,
        errorCodeParse: (err) => err.data.details.error.code,
      },
      application,
    );
  }

  static cancelOrder(payload, application) {
    const request = JSON.stringify(payload);
    return this.apiCall(
      {
        url: `${servicePrefix}/payment/expire`,
        method: "POST",
        data: request,
      },
      application,
    );
  }

  static cancelClientOrder(payload, application) {
    const request = JSON.stringify(payload);
    return this.apiCall(
      {
        url: `${servicePrefix}/payment/expire/client`,
        method: "POST",
        data: request,
      },
      application,
    );
  }

  static fetchInvoice(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}/payment/invoices`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(payload),
        },
      },
      application,
    );
  }

  static fetchClientInvoice(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}/payment/invoices/client`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(payload),
        },
      },
      application,
    );
  }

  static fetchPendingUserTransactions(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}/payment/transactions/user`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(payload),
        },
      },
      application,
    );
  }

  static fetchSpandealCODBalance(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}/marketplace/balanceV2`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(payload),
        },
        errorCodeParse,
      },
      application,
    );
  }
}

export default OSBillingServiceV2Client;
