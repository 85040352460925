import BaseClient from "../baseClient";

const servicePrefix = "/neonService/";

class NeonService extends BaseClient {
  static getErrorMessages() {
    return {
      default: "Looks like we are facing some internal issue. Please, retry after sometime.",
      UNAUTHORIZED:
        "Oops. It seems you are not authorized to take this action. Please log in with appropriate credentials.",
      AD0000:
        "Oops. It seems you are not authorized to take this action. Please log in with appropriate credentials.",
      INVALID_PARAMETERS_ERROR:
        "It seems some input parameters are not valid. Please update your inputs and try again.",
      USER_ALREADY_EXIST:
        "User with email/phone already exist. Please update your inputs and try again.",
      SENDER_EMAIL_NOT_REGISTERED: "At the moment, no sender is configured to share password reset information.",
      SOCIAL_USER_RESET_PASSWORD_NOT_ALLOWED: "You cannot reset your password since your first signup used social login and you did not create one.",
    };
  }

  static fetchMerchantIndustryBenchmark(config, application, options) {
    const payload = JSON.stringify(config);
    return this.apiCall(
      {
        url: `${servicePrefix}fetchMerchantIndustryBenchmark`,
        method: "POST",
        data: payload,
      },
      application,
      options,
    );
  }

  static forgotPassword(request, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}userAccess/resetPassword`,
        method: "POST",
        data: JSON.stringify(request),
        errorCodeParse: ({ data }) => {
          return data?.code;
        },
      },
      application,
      options,
    );
  }

  static fetchSellerData(config, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}hyperlocalSellerDetails`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(config) },
      },
      application,
    );
  }

  static postSellerData(config, application) {
    const payload = JSON.stringify(config);
    return this.apiCall(
      {
        url: `${servicePrefix}hyperlocalSellerDetails`,
        method: "POST",
        data: payload,
        errorCodeParse: ({ data }) => {
          return data?.code;
        },
      },
      application,
    );
  }

  static fetchMonetizeSellerData(config, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}monetizeSellerDetails`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(config) },
      },
      application,
    );
  }

  static postMonetizeSellerData(config, application) {
    const payload = JSON.stringify(config);
    return this.apiCall(
      {
        url: `${servicePrefix}monetizeSellerDetails`,
        method: "POST",
        data: payload,
        errorCodeParse: ({ data }) => {
          return data?.code;
        },
      },
      application,
    );
  }

  static createUserAndRemoveExisting(config, application) {
    const payload = JSON.stringify(config);
    return this.apiCall(
      {
        url: `${servicePrefix}userCreationWithLogin`,
        method: "POST",
        data: payload,
        errorCodeParse: ({ data }) => {
          return data?.code;
        },
      },
      application,
    );
  }

  static createUser(config, application) {
    const payload = JSON.stringify(config);
    return this.apiCall(
      {
        url: `${servicePrefix}createUser/client`,
        method: "POST",
        data: payload,
        errorCodeParse: ({ data }) => {
          return data?.code;
        },
      },
      application,
    );
  }

  static getAgencyLeads(config = {}, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}leadsData/agency`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(config),
        },
      },
      application,
      options,
    );
  }

  static fetchAllClientsLeads(config, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}leadsData/agency/user`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(config) },
      },
      application,
      options,
    );
  }

  static embed(config, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}embed`,
        method: "POST",
        data: JSON.stringify(config),
      },
      application,
    );
  }

  static fetchClientLeadStatusCount(config, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}leadsData/groupData`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(config) },
      },
      application,
      options,
    );
  }

  static fetchAllClientsLeadStatusCount(config, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}leadsData/groupData/agency/user`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(config) },
      },
      application,
      options,
    );
  }

  static fetchAgencyLeadStatusCount(config, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}leadsData/groupData/agency`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(config) },
      },
      application,
      options,
    );
  }

  static embedAdmin(config, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}embed/admin`,
        method: "POST",
        data: JSON.stringify(config),
      },
      application,
    );
  }

  static embedClients(config, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}embed/clients`,
        method: "POST",
        data: JSON.stringify(config),
      },
      application,
    );
  }

  static fetchAgencyCreatives(config, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}agencyCreatives/fetch`,
        method: "POST",
        data: JSON.stringify(config),
      },
      application,
    );
  }

  static postAgencyCreatives(config, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}agencyCreatives/save`,
        method: "POST",
        data: JSON.stringify(config),
      },
      application,
    );
  }

  static postLssConfig(config, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}postLssConfig`,
        method: "POST",
        data: JSON.stringify(config),
      },
      application,
    );
  }

  static fetchMonetizeGoals(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}monetizeGoals`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
    );
  }

  static fetchDealerMetadata(config, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}hyperlocalSellerData`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(config) },
      },
      application,
    );
  }

  static stopClevertapCampaign(config, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}cts/campaign/stop`,
        method: "POST",
        data: JSON.stringify(config),
      },
      application,
    );
  }

  static postLssConfigClient(config, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}postLssConfig/client`,
        method: "POST",
        data: JSON.stringify(config),
      },
      application,
    );
  }

  static fetchClientUserAccess(config, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}userAccess/client`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(config) },
      },
      application,
      options,
    );
  }

  static fetchAgencyUserAccess(config, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}userAccess/agency`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(config) },
      },
      application,
      options,
    );
  }

  static fetchUserClientsAccess(config, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}userAccess/user`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(config) },
      },
      application,
      options,
    );
  }

  static postAgencyUserAccess(config, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}userAccess/agency`,
        method: "POST",
        data: JSON.stringify(config),
      },
      application,
      options,
    );
  }

  static postClientUserAccess(config, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}userAccess/client`,
        method: "POST",
        data: JSON.stringify(config),
      },
      application,
      options,
    );
  }

  static fetchLiveData(config, application, options = {}) {
    const payload = {
      ...config,
      start: config.start || 0,
      offset: config.offset || 0,
      limit: config.limit || 1000,
      clientIds: (config.clientIds && config.clientIds.length) ? config.clientIds : [`${config.clientId}`],
      vendors: config.vendors || ["facebook", "google", "bing"],
      agencyId: null,
      userId: null,
      sokratiRequestId: null,
      clientId: `${config.clientId}`,
    };

    return this.apiCall(
      {
        url: `${servicePrefix}/rtr`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      {
        retryConfig: {
          TypeError: 1,
        },
        ...options,
      },
    );
  }
}

export default NeonService;
