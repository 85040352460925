import React from "react";
import classnames from "classnames";

import BaseAction from "../baseAction";

import "./index.less";

const RuleAction = ({ formGroupClassName, ...props }) => {
  return (
    <BaseAction
      formGroupClassName={classnames("rule-action-group-component", formGroupClassName)}
      actionTitle="Action"
      actionTitleColumn={2}
      {...props}
    />
  );
};

export default RuleAction;
