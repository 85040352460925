import BaseClient from "../baseClient/index";

const servicePrefix = "/osBillingService";

class OSBillingService extends BaseClient {
  static getErrorMessages() {
    return {
      default: "Looks like we are facing some internal issue. Please, retry after sometime.",
      IP0000:
        "It seems some input parameters are not valid. Please update your inputs and try again.",
      AD0000:
        "Oops. It seems you are not authorized to take this action. Please log in with appropriate credentials.",
      I0004:
        "Oops. Seems the system is acting up - sorry for the inconvenience. Please try again in some time. (Please be assured our tech geniuses have been notified of this glitch).",
      API000: "Plan is already active on Shopify",
      API001: "Please retry after sometime.",
      API002: "Invalid request is generated for plan creation.",
      API003: "Invalid credentials found. Please re-authorise.",
      API004: "Services are experiencing brain freezing. Please try again.",
      API005: "Please try again or contanct us if it occurs again.",
      API006:
        "We're unable to connect to Shopify. Please try again or contanct us if it occurs again.",
      API007: "You can only activate existing Subscriptions. Please create one before activation.",
      API008:
        "We're unable to activate your plan on Shopify. Please try again or contanct us if it occurs again.",
      API009: "Your plan is successfully activated on Shopify.",
      API010:
        "You have declined subscription request and hence no plan has been subscribed for you. You can re-initiate your subscription request by choosing one of the plan",
      API011: "Please try again or contact us if it occurs again.",
      APIERR010: "Your card has declined. Please update card details and try again.",
      APIERR018: "Insufficient wallet balance, Please update your daily budget.",
      APIERR017:
        "We are facing issues in doing a transaction on your wallet. Please connect with Konga Support team.",
      APIERR019: "Looks like we are facing some internal issue. Please, retry after sometime.",
      DARAZAPI001: "Oops! You can make only one transaction in 24 hours. Kindly try again later.",
      APISD002: "Maximum limit to deduct from COD balance has been reached.",
    };
  }

  static fetchInfo(request, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}/fetch`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(request) },
      },
      application,
    );
  }

  static chargeV2(payload, application) {
    const request = JSON.stringify(payload);

    return this.apiCall(
      {
        url: `${servicePrefix}/charge`,
        method: "POST",
        data: request,
      },
      application,
    );
  }

  static fetchChargeV2(params, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}/chargeV2`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(params),
        },
      },
      application,
    );
  }

  static saveChargeV2(payload, application) {
    const request = encodeURIComponent(JSON.stringify(payload));

    return this.apiCall(
      {
        url: `${servicePrefix}/chargeV2`,
        method: "POST",
        data: request,
      },
      application,
    );
  }

  static chargeAmount(payload, application) {
    const request = encodeURIComponent(JSON.stringify(payload));

    return this.apiCall(
      {
        url: `${servicePrefix}/chargeAmount`,
        method: "POST",
        data: request,
      },
      application,
    );
  }

  // ** fetchEntityTransaction, fetchEntityWalletBalance, transferWalletBalance are being replaced and Moved to Finance & Inception service

  static fetchEntityTransaction(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}/entityTransactionRawFetch`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
      options,
    );
  }

  static fetchEntityWalletBalance(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}/entityTransactionsFetchAdmin`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
      options,
    );
  }

  static transferWalletBalance(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}/entityTransactionTransfer`,
        method: "POST",
        data: encodeURIComponent(JSON.stringify(payload)),
      },
      application,
      options,
    );
  }
}

export default OSBillingService;
