import React from "react";
import { useTranslation } from "react-i18next";

import { logoConfigExtract } from "@onlinesales-ai/util-methods-v2";

import "./index.less";

const ErrorPage = ({ message, fallBackLogo, logoClassName = "" }) => {
  const { t } = useTranslation();
  const logoSrc = logoConfigExtract("logo_width_52") || fallBackLogo;

  return (
    <div className="error-page-wrapper">
      <img className={`error-logo ${logoClassName}`} src={logoSrc} alt="Error" />
      <p className="error-message">{t(message)}</p>
    </div>
  );
};

ErrorPage.defaultProps = {
  message: "Looks like we are facing some internal issue. Please, retry after sometime.",
  fallBackLogo: "https://osads.gumlet.io/image/authenticated/s--XHZGSuNX--/v1702397812/christmas/OS_logo_symbol_32X40.svg",
};

export default ErrorPage;
