import React, { useState, useEffect } from "react";
import { endOfDay } from "date-fns/esm";
import { DatePicker } from "@onlinesales-ai/datepicker-v2";
import { OSHOCWithUtilities } from "@onlinesales-ai/os-hoc-with-utilities-v2";

const DateSelectComponent = ({
  onChange,
  tabKey,
  showToastMessage,
  componentMetaData,
  value,
  endDatePlaceholder,
  startDatePlaceholder,
}) => {
  const [dates, setDates] = useState(
    value || { startDate: new Date(-8640000000000000), endDate: null },
  );
  const { enableTime, maxDate, minDate } = componentMetaData || {};
  useEffect(() => {
    setDates(value);
  }, [value]);

  const getEpoch = (date) => {
    return new Date(date).getTime();
  };
  const isDateRangeValid = (selectedDate) => {
    return (
      getEpoch(selectedDate?.startDate || new Date(-8640000000000000)) <=
      getEpoch(selectedDate?.endDate)
    );
  };
  const onStartDateApply = (selectedDate) => {
    setDates({ ...dates, startDate: selectedDate });
    onChange({ ...dates, startDate: selectedDate }, tabKey);
  };
  const onEndDateApply = (selectedDate) => {
    const endDate = enableTime ? selectedDate : endOfDay(selectedDate);
    if (isDateRangeValid({ ...dates, endDate })) {
      setDates({ ...dates, endDate });
      onChange({ ...dates, endDate }, tabKey);
    } else {
      showToastMessage({
        type: "ERROR",
        messageToDisplay: "Please select valid date range",
        toastDuration: 5000,
      });
    }
  };
  return (
    <div className="date-select-component-wrapper">
      <DatePicker
        value={dates.startDate}
        position="left"
        enableTime={enableTime || false}
        placeHolder={startDatePlaceholder}
        onChange={onStartDateApply}
        showCTAs={enableTime || false}
        shouldCloseOnSelect={!enableTime}
        maxDate={maxDate}
        minDate={minDate}
      />
      <div className="separator">-</div>
      <DatePicker
        value={dates.endDate}
        position="left"
        enableTime={enableTime || false}
        placeHolder={endDatePlaceholder}
        onChange={onEndDateApply}
        showCTAs={enableTime || false}
        shouldCloseOnSelect={!enableTime}
        maxDate={maxDate}
        minDate={minDate}
      />
    </div>
  );
};

DateSelectComponent.defaultProps = {
  startDatePlaceholder: "Select Start Date",
  endDatePlaceholder: "Select End Date",
};

export default OSHOCWithUtilities(DateSelectComponent);
