/* eslint-disable max-classes-per-file */
import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import "./index.less";

class FluffyLinesAndWhatsNext extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};

    this.windowHeight = window.innerHeight;
    this.windowWidth = window.innerWidth;
    this.confettiCanvasRef = React.createRef();
    this.fluffyLinesWrapperRef = React.createRef();
    this.fluffyLinesAndWhatsNextWrapperRef = React.createRef();
    this.whatsNextStepsWrapperRef = React.createRef();
  }

  componentDidMount = () => {
    if (this.props.showFluffyLines) {
      class confettiParticle {
        constructor() {
          this.x = Math.random() * self.windowWidth; // x
          this.y = Math.random() * self.windowHeight - self.windowHeight; // y
          this.r = randomFromTo(11, 33); // radius
          this.d = Math.random() * maxConfettis + 11;
          this.color = possibleColors[Math.floor(Math.random() * possibleColors.length)];
          this.tilt = Math.floor(Math.random() * 33) - 11;
          this.tiltAngleIncremental = Math.random() * 0.07 + 0.05;
          this.tiltAngle = 0;
        }

        draw() {
          if (context) {
            context.beginPath();
            context.lineWidth = this.r / 2;
            context.strokeStyle = this.color;
            context.moveTo(this.x + this.tilt + this.r / 3, this.y);
            context.lineTo(this.x + this.tilt, this.y + this.tilt + this.r / 5);
            return context.stroke();
          }
        }
      }

      let self = this;
      const canvas = self.confettiCanvasRef?.current || {};
      const context = canvas && canvas.getContext && canvas.getContext("2d");
      const maxConfettis = 70;
      const particles = [];

      const possibleColors = [
        "DodgerBlue",
        "OliveDrab",
        "Gold",
        "Pink",
        "SlateBlue",
        "LightBlue",
        "Gold",
        "Violet",
        "PaleGreen",
        "SteelBlue",
        "SandyBrown",
        "Chocolate",
        "Crimson",
        "#492684",
        "#bea4ff",
        "#feb535",
        "#ff6e83",
        "#58cafe",
      ];

      const randomFromTo = (from, to) => {
        return Math.floor(Math.random() * (to - from + 1) + from);
      };

      const Draw = () => {
        const results = [];

        requestAnimationFrame(Draw);

        if (context) {
          context.clearRect(0, 0, self.windowWidth, window.innerHeight);
        }

        for (let i = 0; i < maxConfettis; i++) {
          results.push(particles[i].draw());
        }

        let particle = {};
        let remainingFlakes = 0;
        for (let i = 0; i < maxConfettis; i++) {
          particle = particles[i];

          particle.tiltAngle += particle.tiltAngleIncremental;
          particle.y += (Math.cos(particle.d) + 3 + particle.r / 2) / 2;
          particle.tilt = Math.sin(particle.tiltAngle - i / 3) * 15;

          if (particle.y <= self.windowHeight) remainingFlakes++;

          // If a confetti has fluttered out of view,
          // bring it back to above the viewport and let if re-fall.
          if (
            particle.x > self.windowWidth + 30 ||
            particle.x < -30 ||
            particle.y > self.windowHeight
          ) {
            particle.x = Math.random() * self.windowWidth;
            particle.y = -30;
            particle.tilt = Math.floor(Math.random() * 10) - 20;
          }
        }

        return results;
      };

      window.addEventListener(
        "resize",
        () => {
          self.windowWidth = window.innerWidth;
          self.windowHeight = window.innerHeight;
          canvas.width = window.innerWidth;
          canvas.height = window.innerHeight;
        },
        false,
      );

      // Push new confetti objects to `particles[]`
      for (let i = 0; i < maxConfettis; i++) {
        particles.push(new confettiParticle());
      }

      // Initialize
      canvas.width = self.windowWidth;
      canvas.height = self.windowHeight;
      Draw();

      if (this.fluffyLinesWrapperRef?.current) {
        const fluffyLinesWrapperElement = this.fluffyLinesWrapperRef?.current;
        fluffyLinesWrapperElement.classList.add("fade-in");
        if (this.props && this.props.fluffyLinesContent) {
          setTimeout(() => {
            if (this.props.showWhatsNextSteps) {
              //TODO: Verify this logic
              fluffyLinesWrapperElement.classList.add("animate-up");
              if (this.fluffyLinesAndWhatsNextWrapperRef?.current) {
                this.fluffyLinesAndWhatsNextWrapperRef?.current.classList.add("hide-now");
              }
              setTimeout(() => {
                fluffyLinesWrapperElement.style.display = "none";
                if (this.whatsNextStepsWrapperRef?.current) {
                  this.whatsNextStepsWrapperRef?.current.classList.add("fade-in-up");
                }
              }, 200);
            } else {
              this.props.jobDoneCallback();
            }
          }, (this.props.fluffyLinesContent.length * 2 + 1) * 1000);
        }

        if (/*this.props.showWhatsNextSteps &&*/ this.props.fluffyLinesContent) {
          setTimeout(() => {
            if (this.fluffyLinesAndWhatsNextWrapperRef?.current) {
              this.fluffyLinesAndWhatsNextWrapperRef?.current.classList.add("show-confetti");
            }
          }, (this.props.fluffyLinesContent.length * 2 - 2) * 1000);
        }
      }
    } else {
      if (this.whatsNextStepsWrapperRef?.current) {
        this.whatsNextStepsWrapperRef?.current.classList.add("fade-in-up");
      }
    }
  };

  renderCheckMark = () => (
    <div className="fluffy-lines-checkmark-wrapper">
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <circle
          className="fluffy-lines-checkmark-circle"
          fill="none"
          stroke="#73AF55"
          strokeWidth={3}
          strokeMiterlimit={5}
          cx={16}
          cy={16}
          r={14}
        />
        <polyline
          className="fluffy-lines-checkmark-check"
          fill="none"
          stroke="#73AF55"
          strokeWidth={3}
          strokeLinecap="round"
          strokeMiterlimit={5}
          points="23,11 12.5,22 7.5,16.5"
        />
      </svg>
    </div>
  );

  render() {
    let fluffyLinesContent = [];
    const { t } = this.props;
    let userName = this.props.userInfo && this.props.userInfo.name;
    let firstName = userName && userName.split(" ")[0];
    if (this.props && this.props.fluffyLinesContent) {
      this.props.fluffyLinesContent.forEach((content) => {
        content = t(content, { firstName });

        fluffyLinesContent.push(
          <li>
            <span>{content}</span>
            {this.props.isMobile && this.renderCheckMark()}
          </li>,
        );
      });
    }

    const isMobileClassName = this.props.isMobile ? " is-mobile " : "";
    return (
      <div
        className={`whats-next-with-fluffy-lines-wrapper ${isMobileClassName}`}
        ref={this.fluffyLinesAndWhatsNextWrapperRef}
      >
        {this.props.showFluffyLines && (
          <div className="fluffy-lines-wrapper" ref={this.fluffyLinesWrapperRef}>
            <div className="fluffy-lines-container">
              <div className="fluffy-lines-rotating-text-wrapper">
                <div className="fluffy-lines-rotating-text-hide-top" />
                <div className="fluffy-lines-rotating-text-content">
                  {!this.props.isMobile && this.renderCheckMark()}
                  <div className="fluffy-lines-rotating-text-textlist-wrapper">
                    <ul className="fluffy-lines-rotating-text-textlist">{fluffyLinesContent}</ul>
                  </div>
                </div>
                <div className="fluffy-lines-rotating-text-hide-bottom" />
              </div>
            </div>
          </div>
        )}
        {this.props.showFluffyLines && (
          <canvas className="confetti-canvas" ref={this.confettiCanvasRef} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { clientId, userInfo = {}, isMobile } = state.Application || {};

  return {
    clientId,
    userInfo,
    isMobile,
  };
};

FluffyLinesAndWhatsNext.defaultProps = {
  clientId: null,
  showFluffyLines: true,
  isMobile: false,
  showWhatsNextSteps: false, //for future use
  fluffyLinesContent: [
    "Hey {{firstName}}, We have Touch Down!",
    "Aligning Your Advertising Stars for Added Luck",
    "May The Jedi Forces Rustle Up The Ads",
    "Picasso Dreams Your Creatives To Life",
    "Shakespeare is Getting Your Ad Copy Ready",
    "Mapping 324,568 Data Points To Optimize Your Campaigns",
  ],
  jobDoneCallback: () => {},
};

export default withTranslation()(connect(mapStateToProps)(FluffyLinesAndWhatsNext));
