import React, { Suspense, useEffect, lazy, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Routes, Route, Outlet } from "react-router-dom";

import { Navigate } from "@onlinesales-ai/utils-components-v2";
import { getLSItem, getDefaultRoute, CheckAdBlocker } from "@onlinesales-ai/util-methods-v2";
import { loaderApp } from "@onlinesales-ai/loader-v2";
import { OSHOCWithUtilities } from "@onlinesales-ai/os-hoc-with-utilities-v2";
import { ChrunBlock } from "@onlinesales-ai/downtime-v2";
import { GlobalContext } from "@onlinesales-ai/utils-components-v2";

import { setIsAdBlockerEnabled } from "../application";
import AppLevelTabs from "./appLevelTabs";

import AppLoader from "./AppLoader";
import NotificationBarWrapper from "./notificationBarWrapper";

const ErrorPage = lazy(() => import("@onlinesales-ai/utils-components-v2/src/errorPage")) ;

const defaultComponentList= {
  HEADER: () => null,
  AUTH_AND_CLIENT: () => null,
  ROUTE_BASED_DRAWER: () => null,
  CONTEXT_BASED_DRAWER: () => null,
  SIDEBAR_WRAPPER: () => null,
  DATA_FETCH_PROCESSOR: () => null,
  NOTIFICATION_PROCESSOR: () => null,
  ROUTE_BASED_MODAL: () => null,
  ROUTE_BASED_PENDING_ACTION: () => null,
};

const AppRouter = ({
  components,
  routes,
  isAppLoading,
  openRoutes,
  protectedRoutes,
  location,
  redirectUrl,
  setIsAdBlocker,
  showToastMessage,
  showSuccessModal,
  resetSuccessModal,
  domainConfig,
  changeClientId,
  isAllClientsSelected,
}) => {
  const [isBrowserSupported, setIsBrowserSupported] = useState(true);
  const [contextDrawerData, setContextDrawerData] = useState({});

  const checkIfBrowserIsSupported = () => {
    const userAgent = window.navigator.userAgent;
    let isSupported = true;

    const isIE = !!document.documentMode;
    const isVivoBrowser = userAgent.includes("VivoBrowser");

    if(isIE || isVivoBrowser){
      isSupported = false;
    }

    return isSupported;
  };

  const openContextDrawer = (component, data) => {
    setContextDrawerData({ component, data });
  };

  useEffect(() => {
    const isSupported = checkIfBrowserIsSupported();
    setIsBrowserSupported(isSupported);

    if(!isSupported){
      return;
    }

    if (location && location.pathname === routes?.DOWNTIME?.path) {
      redirectUrl(getLSItem("urlPath") || getDefaultRoute() || routes?.DASHBOARD?.path);
    }
    setIsAdBlocker(CheckAdBlocker());
  }, []);

  const {
    HEADER: AppHeader,
    AUTH_AND_CLIENT: AuthAndClientComp,
    ROUTE_BASED_DRAWER: RouteBasedDrawer,
    CONTEXT_BASED_DRAWER: ContextBasedDrawer,
    SIDEBAR_WRAPPER: SidebarWrapper,
    DATA_FETCH_PROCESSOR: DataFetchProcessor,
    NOTIFICATION_PROCESSOR: NotificationProcessor,
    ROUTE_BASED_MODAL: RouteBasedModal,
    ROUTE_BASED_PENDING_ACTION: RouteBasedPendingAction
  } = {
    ...defaultComponentList,
    ...components,
  };

  if (domainConfig.isClientChurn) {
    return <ChrunBlock />;
  }

  const routesToRender = [];

  const subRouteElements = (
    [
      <Route 
        path={`${routes.ROUTE_BASED_DRAWER.path.substr(1)}/:component/:subComponent?`} 
        element={isAppLoading ? null : <RouteBasedDrawer />}
      />,
      routes.ROUTE_BASED_MODAL?.path ?
      <Route 
        path={`${routes.ROUTE_BASED_MODAL.path.substr(1)}/:component/:subComponent?`}
        element={isAppLoading ? null : <RouteBasedModal />}/>
      : null,
      <Route 
        path={`${routes.ROUTE_BASED_PENDING_ACTION.path.substr(1)}/:component/:subComponent?`} 
        element={isAppLoading ? null : <RouteBasedPendingAction />}
      />
    ]
  );

  Object.keys(openRoutes || {}).forEach((key) => {
    const {component: Component, ...rest} = openRoutes[key];
    routesToRender.push(
      <Route 
        key={key} 
        path={key}
        element={
          <Component 
            {...(domainConfig[key] || {})}/>
        } 
        {...rest}
      />
    );
  });

  {
    Object.keys(protectedRoutes || {}).forEach((key) => {
      const { component: Component, isStrict, doNotRenderOutlet, ...props } = protectedRoutes[key];
      routesToRender.push(
        <Route
          key={key}
          path={`${key}/*`}
          {...props}
          element={
            isAppLoading ?
              loaderApp() :
              <>
                <Component
                  subRouteElements={subRouteElements}
                  {...(domainConfig[key] || {})}
                />
                {doNotRenderOutlet ? null : <Outlet />}
              </>
          }
        >
          {
            subRouteElements
          }
        </Route>
      );
    })
  }

  routesToRender.push(
    <Route 
      key="default" 
      path="*" 
      element={<Navigate replace to={getDefaultRoute() || routes.DASHBOARD?.path} />}
    />
  );

  return (
    <GlobalContext.Provider
      value={{
        showToastMessage,
        showSuccessModal,
        resetSuccessModal,
        redirectUrl,
        changeClientId,
        isAllClientsSelected,
        contextDrawerData,
        setContextDrawerData,
        openContextDrawer,
      }}
    >
      {
        isBrowserSupported ? (
          <>
            {!openRoutes[location.pathname] && <AuthAndClientComp domainConfig={domainConfig} />}
            {!openRoutes[location.pathname] ||
            location.pathname.includes(routes.ROUTE_BASED_DRAWER.path) ||
            location.pathname.includes(routes.SETTINGS.path) ||
            location.pathname.includes(routes.ROUTE_BASED_PENDING_ACTION.path) ? (
              <AppHeader />
            ) : null}
            <NotificationBarWrapper redirectUrl={redirectUrl}/>
            <div id="sticky-top-ribbon-selector" />
            <Suspense fallback={AppLoader()}>
              <div className="main-body-wrapper">
                <Routes>
                  {routesToRender}
                </Routes>
                {
                  !openRoutes[location.pathname] &&
                  !location.pathname.includes(routes.ONBOARDING.path) &&
                  !isAppLoading &&
                    <>
                      <SidebarWrapper/>
                      <DataFetchProcessor/>
                      <NotificationProcessor/>
                      <ContextBasedDrawer />
                    </>
                }
              </div>
            </Suspense>
            {!openRoutes[location.pathname] && !location.pathname.includes(routes.ONBOARDING.path) && (
              <AppLevelTabs />
            )}
          </>
        ) : (
          <Suspense fallback={AppLoader()}>
            <div className="main-body-wrapper">
              <ErrorPage
                  message="Oops! The browser you are using is not optimised to use the platform. Please use popular browsers like Google Chrome, Firefox or Safari."/>
            </div>
          </Suspense>

        )
      }
    </GlobalContext.Provider>
  );
};

AppRouter.defaultProps = {
  routes: {},
  openRoutes: {},
  protectedRoutes: {},
  isAppLoading: false,
  hasAppError: null,
  components: {
    ...defaultComponentList
  },
};

const mapStateToProps = (state) => {
  return {
    location: state.router.location,
    isMobile: state.Application.isMobile,
    domainConfig: state.DomainConfig || {},
    isAllClientsSelected: state.Application.isAllClientsSelected,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setIsAdBlocker: setIsAdBlockerEnabled,
    },
    dispatch,
  );
};

export default OSHOCWithUtilities(
  connect(mapStateToProps, mapDispatchToProps)(AppRouter),
  undefined,
  {
    doNotShowConfirmationModal: true,
  },
);
