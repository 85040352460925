import _isEmpty from "lodash/isEmpty";
import _get from "lodash/get";
import {
  goalStatusEnum,
  goalSortTypesConst,
  goalSortOrderTypes,
  brandAdsGoalStatusEnum,
} from "@onlinesales-ai/constants-v2";
import { customMergeOS } from "./customMergeOS";

export const getOverridesForGoal = (overrideInfo, goalInfo, config = {}) => {
  let retnData = {};
  if (overrideInfo && goalInfo) {
    const overrideKeys = Object.keys(overrideInfo);
    for (let i = 0; i < overrideKeys.length; i++) {
      const { query, overrides } = overrideInfo[overrideKeys[i]] || {};
      if (query && overrides) {
        let isMatch = true;

        query.forEach((queryInfo) => {
          if (!isMatch) {
            return;
          }
          switch (queryInfo.type) {
            case "VENDOR":
              {
                isMatch = false;
                if (goalInfo.channelSettings && queryInfo.vendor) {
                  goalInfo.channelSettings.forEach(({ vendor, status }) => {
                    if (status === goalStatusEnum.ACTIVE && queryInfo.vendor === vendor) {
                      isMatch = true;
                    }
                  });
                }
              }
              break;
            case "OBJECTIVE":
              {
                isMatch = goalInfo.objective == queryInfo.objective;
              }
              break;
            case "SMM_CUSTOM_OBJECTIVE":
              {
                isMatch = goalInfo?.metadata?.customObjective === queryInfo.customObjective;
              }
              break;
            case "ALL_CAMPAIGN_TYPE":
              {
                isMatch = goalInfo.type === queryInfo.campaignType;
              }
              break;
            case "CAMPAIGN_SUB_TYPE":
              {
                // @parth change this after BE implementation
                isMatch = goalInfo.type === queryInfo.subType;
              }
              break;
            case "METADATA":
              {
                isMatch = goalInfo?.metadata?.[queryInfo.key] === queryInfo.value;
              }
              break;
            case "VENDOR_CHANNEL":
              {
                isMatch = false;

                if (queryInfo?.channels?.length) {
                  isMatch = true;

                  queryInfo.channels.forEach(({ vendor, channel }) => {
                    let isChannelActive = false;

                    goalInfo.channelSettings.forEach(
                      ({ channel: goalChannel, vendor: goalVendor, status }) => {
                        if (vendor === goalVendor && channel === goalChannel) {
                          isChannelActive = status === goalStatusEnum.ACTIVE;
                        }
                      },
                    );

                    isMatch = isMatch && isChannelActive;
                  });
                }
              }
              break;
            case "RMN_VENDOR_CHECK":
              {
                const channels = goalInfo?.channelSettings || [];

                const rmnVendorId = channels.find((c) => c.rmnVendorId);

                const accountData = Object.values(config.rmnAccountConfig || {}).find(
                  (account) => account.agencyId === rmnVendorId,
                );

                isMatch = accountData?.category === queryInfo.category;
              }
              break;
            case "PAUSED_DUE_TO_MAX_CAP_REACHED_DISPLAY_ADS":
              {
                isMatch = goalInfo?.effectiveStatus === "PAUSED_DUE_TO_MAX_CAP_REACHED";
              }
              break;
            case "PAUSED_DUE_TO_END_DATE_REACHED_DISPLAY_ADS":
              {
                isMatch = goalInfo?.effectiveStatus === "PAUSED_DUE_TO_END_DATE_REACHED";
              }
              break;
            case "PAUSED_DUE_TO_MAX_CAP_REACHED_PLA":
              {
                isMatch = goalInfo?.data?.effectiveStatus === "PAUSED_DUE_TO_MAX_CAP_REACHED";
              }
              break;
            case "VIEW_ONLY_FOR_ADVERTISER":
              {
                isMatch =
                  !config?.isInternalOrPartnerInternalUser &&
                  goalInfo?.marketingCampaignUiFilters?.accessibility === "read";
              }
              break;
            default: {
              isMatch = false;
            }
          }
        });

        if (isMatch) {
          retnData = customMergeOS(retnData, overrides);
        }
      }
    }
  }

  return retnData;
};

export const getGoalStatus = (
  goalData = {},
  isUserLaunchedCheckFlagName,
  onboardingDetails,
  billingMetadataFlag,
) => {
  // TODO: create a commoun utility method
  let goalStatus = goalData.status;
  if (
    !_isEmpty(isUserLaunchedCheckFlagName) &&
    goalData.metadata &&
    goalData.metadata.hasOwnProperty(isUserLaunchedCheckFlagName) &&
    goalData.metadata[isUserLaunchedCheckFlagName] === false
  ) {
    goalStatus = "DRAFT";
  } else if (
    billingMetadataFlag &&
    onboardingDetails &&
    onboardingDetails.metadata &&
    !onboardingDetails.metadata[billingMetadataFlag]
  ) {
    goalStatus = "DRAFT";
  }

  return goalStatus;
};

export const getBudgetValue = (goalData = {}) => {
  const { budget = {}, budgetType } = goalData;
  const budgetValue =
    (budget &&
      (budgetType === "DAILY_BUDGET_WITH_MAX_CAP" ? budget.dailyBudget : budget.spendCap)) ||
    0;
  return budgetValue;
};

export const RMN_API_WRAPPER_PREFIX = "/nexusService/wrap";

const getStatusPriority = (status) => {
  switch (status) {
    case goalStatusEnum.ACTIVE: {
      return 1;
    }
    case goalStatusEnum.LAUNCH_INPROGRESS: {
      return 2;
    }
    case brandAdsGoalStatusEnum.DRAFT_REJECT: {
      return 3;
    }
    case brandAdsGoalStatusEnum.UNDER_REVIEW: {
      return 4;
    }
    case brandAdsGoalStatusEnum.DELIVERED: {
      return 5;
    }
    case goalStatusEnum.PAUSED: {
      return 6;
    }
    case goalStatusEnum.DRAFT: {
      return 7;
    }
    default: {
      return 0;
    }
  }
};

const getEntityComparison = (goals, goalSortPerformanceData, sortInfo, prevId, nextId) => {
  let result = 0;

  switch (sortInfo.key) {
    case goalSortTypesConst.ID.key:
      {
        try {
          result = goals[prevId].data.id - goals[nextId].data.id;
        } catch (error) {
          console.error("Error in getEntityComparison Creation Date: ", error);
        }
      }
      break;
    case goalSortTypesConst.BUDGET.key:
      {
        let prevIdBudget = 0;
        let nextIdBudget = 0;
        try {
          const prevGoalBudgetType = goals[prevId].data.budgetType;
          const nextGoalBudgetType = goals[nextId].data.budgetType;
          prevIdBudget =
            prevGoalBudgetType === "DAILY_BUDGET_WITH_MAX_CAP"
              ? goals[prevId].data.budget.dailyBudget * 30
              : goals[prevId].data.budget.spendCap;
          nextIdBudget =
            nextGoalBudgetType === "DAILY_BUDGET_WITH_MAX_CAP"
              ? goals[nextId].data.budget.dailyBudget * 30
              : goals[nextId].data.budget.spendCap;
        } catch (error) {
          console.error("Error parsing goal value", error);
        }
        result = prevIdBudget - nextIdBudget;
      }
      break;
    case goalSortTypesConst.STATUS.key:
      {
        const goalA = goals[prevId];
        const goalB = goals[nextId];
        if (goalA.data && goalA.data.status && goalB.data && goalB.data.status) {
          const goalAPriority = getStatusPriority(goalA.data.status);
          const goalBPriority = getStatusPriority(goalB.data.status);
          result = goalAPriority - goalBPriority;
        }
      }
      break;
    case goalSortTypesConst.STATUS_PRIORITY.key:
      try {
        const goalA = goals[prevId];
        const goalB = goals[nextId];
        if (goalA.data && goalA.data.statusPriority && goalB.data && goalB.data.statusPriority) {
          result = goalA.data.statusPriority - goalB.data.statusPriority;
        }
      } catch (error) {
        console.error("Error in goalSortPerformanceData statusPriority: ", error);
      }
      break;
    case goalSortTypesConst.SPEND.key:
      try {
        result =
          (goalSortPerformanceData?.[prevId]?.spend || 0) -
          (goalSortPerformanceData?.[nextId]?.spend || 0);
      } catch (error) {
        console.error("Error in goalSortPerformanceData spend: ", error);
      }
      break;
    case goalSortTypesConst.BRAND_ADS_CREATION_DATE.key:
      try {
        result =
          goals[prevId]?.data?.marketingCampaignId - goals[nextId]?.data?.marketingCampaignId;
      } catch (error) {
        console.error("Error in getEntityComparison Creation Date: ", error);
      }
      break;
    case goalSortTypesConst.IMPRESSION.key:
      try {
        result =
          (goalSortPerformanceData[prevId]?.impressions || 0) -
          (goalSortPerformanceData[nextId]?.impressions || 0);
      } catch (error) {
        console.error("Error in goalSortPerformanceData impressions: ", error);
      }
      break;
    case goalSortTypesConst.ORDERS.key:
      try {
        result =
          (goalSortPerformanceData[prevId]?.conversions || 0) -
          (goalSortPerformanceData[nextId]?.conversions || 0);
      } catch (error) {
        console.error("Error in goalSortPerformanceData conversions: ", error);
      }
      break;

    default:
      result = 0;
      break;
  }

  return sortInfo.order === goalSortOrderTypes.ASC.key ? result : result * -1;
};

export const sortGoalData = (sortConfig, goals, goalSortPerformanceData, topLevelGoalFlagPath) => {
  const goalIds = Object.keys(goals || {});
  const { sortingOrder = [] } = sortConfig || {};

  if (goalIds.length) {
    goalIds.sort((prevId, nextId) => {
      let result = 0;
      for (let i = 0; i < sortingOrder.length; i += 1) {
        const tempResult = getEntityComparison(
          goals,
          goalSortPerformanceData,
          sortingOrder[i],
          prevId,
          nextId,
        );
        if (tempResult !== 0) {
          result = tempResult;
          break;
        }
      }
      return result;
    });

    if (topLevelGoalFlagPath) {
      goalIds.sort((goalId) => {
        const doesFlagExist = _get(goals?.[goalId]?.data, topLevelGoalFlagPath);
        if (doesFlagExist) {
          return -1;
        } else {
          return 1;
        }
      });
    }
  }

  return goalIds;
};
