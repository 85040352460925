import React from "react";
import _get from "lodash/get";
import { withTranslation } from "react-i18next";
import _isEqual from "lodash/isEqual";
import { connect } from "react-redux";

import { TimePicker } from "@onlinesales-ai/datepicker-v2";

import FormWrapper from "../../FormWrapper";

class TimePickerForm extends React.Component {
  constructor(props) {
    super(props);

    this.validate(this.getValue());
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { validations } = this.props;
    const newValue = _get(nextProps.formValues, nextProps.dataKey);

    if (newValue !== this.getValue() || !_isEqual(validations, nextProps.validations)) {
      this.validate(newValue, nextProps.validations);
    }
  }

  getValue = () => {
    const { dataKey, formValues } = this.props;
    return _get(formValues, dataKey);
  };

  validate = (value, pValidations) => {
    const { onError, dataKey, validations = [], title } = this.props;
    let errorMsg = null;

    const validationsToUse = pValidations || validations;

    for (let i = 0; i < validationsToUse.length; i++) {
      if (!validationsToUse[i].type || !validationsToUse[i].msg) {
        continue;
      }

      switch (validationsToUse[i].type) {
        case "nonEmpty":
          {
            if (!value) {
              errorMsg = validationsToUse[i].msg;
            }
          }
          break;
        default:
          break;
      }

      if (errorMsg) {
        errorMsg = errorMsg.replace("__FIELD_TITLE__", title.toLowerCase());
        break;
      }
    }

    onError({ [dataKey]: errorMsg });
  };

  onChangeDate = (value) => {
    const { onChange, dataKey } = this.props;
    onChange({
      [dataKey]: value,
    });
  };

  render() {
    const {
      // FormWrapper props
      labelColumns,
      formGroupClassName,
      guidText,
      guidElement,
      apiError,
      warning,
      title,
      titleTooltip,
      titleGuidText,

      // tag input props
      dataKey,
      isEditable,
      showErrors,
      formErrors,
      disabled,
      className,
      is24Hr,
      placement,
      defaultTime,
      minHour,
      defaultValueToShow,
      showTimezone,
      timezone,
      minutesGap,
    } = this.props;

    const value = this.getValue();

    let guidTextToUse = guidText;

    if (showTimezone && timezone) {
      guidTextToUse = `Timezone: ${timezone}`;
    }

    return (
      <FormWrapper
        labelColumns={labelColumns}
        formGroupClassName={formGroupClassName}
        hasError={showErrors && formErrors[dataKey]}
        error={formErrors[dataKey]}
        apiError={apiError}
        guidElement={guidElement}
        guidText={guidTextToUse}
        warning={warning}
        title={title}
        titleTooltip={titleTooltip}
        titleGuidText={titleGuidText}
        isEditable={isEditable}
      >
        {isEditable ? (
          <TimePicker
            className={className}
            time={value}
            onChange={this.onChangeDate}
            disabled={disabled}
            is24Hr={is24Hr}
            placement={placement}
            defaultTime={defaultTime}
            minHour={minHour}
            minutesGap={minutesGap}
          />
        ) : (
          <div className="non-edit-mode-text">
            {value || defaultValueToShow}
          </div>
        )}
      </FormWrapper>
    );
  }
}

TimePickerForm.defaultProps = {
  dataKey: null,
  title: "",
  labelColumns: 3,
  defaultValueToShow: "-",
  formGroupClassName: "form-component-timepicker",
  validations: [
    {
      type: "nonEmpty",
      msg: "Please enter __FIELD_TITLE__",
    },
  ],
};

const mapStateToProps = (state) => {
  const { timezone } = state.DomainConfig || "";
  return { timezone };
};

export default withTranslation()(connect(mapStateToProps)(TimePickerForm));
