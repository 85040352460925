import React from "react";
import { Trans } from "react-i18next";

import "./index.less";

const Default = ({ message, togglePopover, type, triggerOnClick, className }) => {
  return (
    <div className={`base-popover-notification default ${type} ${className}`}>
      {triggerOnClick ? (
        <span onClick={togglePopover} className="icon icon-close1 close-btn" />
      ) : null}
      <span>
        <Trans>{message}</Trans>
      </span>
    </div>
  );
};

export default Default;
