export default {
  SET_SMM_POSTS_FETCH_IN_PROGRESS: "SET_SMM_POSTS_FETCH_IN_PROGRESS",
  SET_SMM_POSTS_FETCH_ERROR: "SET_SMM_POSTS_FETCH_ERROR",
  SET_SMM_POSTS_FETCH_DATA: "SET_SMM_POSTS_FETCH_DATA",
  UPDATE_SMM_POST_SUGGESTION: "UPDATE_SMM_POST_SUGGESTION",
  UPDATE_SMM_POST_POLL_DATA: "UPDATE_SMM_POST_POLL_DATA",
  SET_SMM_POST_SAVE_IN_PROGRESS: "SET_SMM_POST_SAVE_IN_PROGRESS",
  SET_SMM_POST_SAVE_ERROR: "SET_SMM_POST_SAVE_ERROR",
  DELETE_SMM_POST: "DELETE_SMM_POST",
  SET_FETCH_STATE_BOOST_DATA: "SET_FETCH_STATE_BOOST_DATA",
  SET_BOOST_DATA: "SET_BOOST_DATA",
  UPDATE_BOOST_DATA: "UPDATE_BOOST_DATA",
  SET_FETCH_STATE_BOOST_CAMPAIGN_DATA: "SET_FETCH_STATE_BOOST_CAMPAIGN_DATA",
  SET_BOOST_CAMPAIGN_DATA: "SET_BOOST_CAMPAIGN_DATA",
};
