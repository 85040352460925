import React from "react";
import { Trans } from "react-i18next";

import AsyncImage from "@onlinesales-ai/async-image-v2";
import { CarouselContainer } from "@onlinesales-ai/carousel-container-v2";
import { ModalWindow } from "@onlinesales-ai/modal-window-v2";
//import { PDFViewer } from "@onlinesales-ai/pdf-viewer-v2";

import "./index.less";

const ImagePreviewModal = ({
  isShow,
  onClose,
  list = [],
  title,
  className,
  modalHeaderTitleEllipsis,
  containerClass,
}) => {
  return (
    <ModalWindow
      isShow={isShow}
      onModalDestroy={onClose}
      containerClass={`image-preview-modal-wrapper ` + className}
      modalProps={{
        backdrop: "static",
      }}
    >
      <ModalWindow.Header closeButton>
        <span className={`${modalHeaderTitleEllipsis ? "ellipsis-text" : ""}`}><Trans>{title}</Trans></span>
      </ModalWindow.Header>
      <ModalWindow.Body>
        <CarouselContainer
          containerClassName={containerClass}
          items={list.map((src) => {
            if (src.endsWith(".pdf")) {
              /* getting sentry error for reactPDF library hence commented, if required in future can be used   
              return <PDFViewer className="pdf-view" title="pdf" src={src} />;
              */
              return null;
            }
            return <AsyncImage className="pdf-view" imgSrc={src} />;
          })}
          windowSize={1}
          isIndicatorShow={true}
        />
      </ModalWindow.Body>
    </ModalWindow>
  );
};

export default ImagePreviewModal;
