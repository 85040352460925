import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Trans } from "react-i18next";

import { FixLayout } from "@onlinesales-ai/fix-layout-v2";
import { loaderWhite } from "@onlinesales-ai/loader-v2";
import { getPublicUrl as getPublicUrlAction } from "@onlinesales-ai/app-v2/application";
import AsyncImage from "@onlinesales-ai/async-image-v2";
import { EmptyMessage, FetchBanner } from "@onlinesales-ai/message-card-v2";

import "./index.less";

const FILE_TYPE_MAPPING = {
  APPLICATION: "application",
  IMAGE: "image",
};

const FilePreview = ({ iframeUrl, fileUrl, getPublicUrl, headerTitle, fileType, isPublicUrl }) => {
  const urlToUse = iframeUrl || fileUrl;

  const [isLoading, setIsLoading] = useState(false);
  const [publicURL, setPublicURL] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const fetchPublicUrl = async () => {
    setIsLoading(true);

    try {
      const res = await getPublicUrl({ url: urlToUse });
      setPublicURL(res?.publicUrl);
      setIsLoading(false);
    } catch (error) {
      setErrorMsg("Looks like we are facing some internal issue. Please, retry after sometime.");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isPublicUrl) {
      setPublicURL(urlToUse);
    } else {
      fetchPublicUrl(urlToUse);
    }
  }, []);

  const renderBody = () => {
    if (fileType?.startsWith(FILE_TYPE_MAPPING.APPLICATION)) {
      return <iframe title={headerTitle || "link"} src={publicURL} />;
    } else if (fileType?.startsWith(FILE_TYPE_MAPPING.IMAGE) && publicURL) {
      return <AsyncImage imgSrc={publicURL} />;
    } else {
      return <EmptyMessage>No Preview Available</EmptyMessage>;
    }
  };

  return (
    <FetchBanner errorTitle="Internal Error" errorMsg={errorMsg} isLoading={isLoading}>
      <FixLayout className="iframe-preview-wrapper">
        <FixLayout.Header>
          <Trans>{headerTitle}</Trans>
        </FixLayout.Header>
        <FixLayout.Body className="iframe-preview-body-wrapper">
          {isLoading && loaderWhite()}
          {renderBody()}
        </FixLayout.Body>
      </FixLayout>
    </FetchBanner>
  );
};

FilePreview.defaultProps = {
  iframeUrl: "",
  headerTitle: "",
  isPublicUrl: false,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getPublicUrl: getPublicUrlAction,
    },
    dispatch,
  );
};

export default connect(null, mapDispatchToProps)(FilePreview);
