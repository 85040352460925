import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";

import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { Input } from "@onlinesales-ai/input-v2";

import "./index.less";

const styleOptions = {
  style: {
    base: {
      fontSize: "14px",
      color: "#424770",
      letterSpacing: "0.025em",
      fontFamily: "Source Code Pro, Menlo, monospace",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#ec4c4a",
    },
  },
};

const StripeCard = ({ stripeCardRef }) => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const stripe = useStripe();
  const elements = useElements();
  const isDisabled = !stripe || !elements;

  const getToken = async () => {
    if (isDisabled) {
      return Promise.reject();
    }

    if (!name) {
      return Promise.reject("Card details are missing, Please fill the details!");
    }

    const cardElement = elements.getElement(CardNumberElement);

    const response = await stripe.createToken(cardElement, { name });

    if (response.error) {
      return Promise.reject(response.error.message);
    }

    if (response?.token?.id) {
      return Promise.resolve(response.token.id);
    }

    return Promise.reject("Something went wrong, Please try again!");
  };

  useEffect(() => {
    if (stripeCardRef?.current) {
      stripeCardRef.current.getToken = getToken;
    }
  });

  return (
    <div className="payment-card-form">
      <CardNumberElement options={styleOptions} className="form-control" />
      <Input placeholder={t("FULL NAME")} value={name} onChange={setName} />
      <div className="payment-block">
        <span className="validity-check-txt"><Trans>Valid Till</Trans></span>
        <CardExpiryElement options={styleOptions} className="form-control inline-input" />
        <CardCvcElement options={{ ...styleOptions, type: "password" }} className="form-control inline-input" />
      </div>
    </div>
  );
};

const PowerdByLogo = () => {
  return (
    <div className = "stripe-logo-container">
    <a href="https://stripe.com/in/privacy" target="_blank"><Trans> Powerd By</Trans> <b className="stripe-name"><Trans>stripe</Trans></b></a>
   </div>
  );
};

const StripeCardElement = ({ stripKey, titleText, infoTexts, isShowLogo, apiVersion, ...props }) => {
  const { t } = useTranslation();
  const [stripePromise] = useState(() => loadStripe(stripKey, { apiVersion }));

  return (
    <div className="payment-card-details-wrapper">
      <div className="title-text">{t(titleText)}</div>
      <div className="payment-card-align">
        <div className="payment-card-wrapper col-md-6">
          <Elements stripe={stripePromise}>
            <StripeCard {...props} />
          </Elements>
          { isShowLogo &&
            PowerdByLogo(isShowLogo) }
        </div>
        <div className="credit-card-security-points col-md-6">
          <ul>
            {infoTexts.map((text, index) => (
              <li key={index}>{t(text)}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

StripeCardElement.defaultProps = {
  stripKey: "pk_live_WWZTdUIvw7k8QS7Uo9hX2kpw",
  onToken: () => {},
  titleText:
    "Please provide your Card Details. The amount will be charged on this card.",
  infoTexts: [
    "Stripe is PCI compliant and your credit card information is sent directly to them.",
    "This is a secure AES-256 encrypted payment",
    "Your card will be swiped within 24 hours",
  ],
  isShowLogo: true,
};

const mapStateToProps = (state) => {
  return {
    stripKey: state.DomainConfig.stripKey,
  };
};

export default connect(mapStateToProps)(StripeCardElement);
