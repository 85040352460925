import Types from "./types";

const reducer = (state, action, initialState = {}) => {
  switch (action.type) {
    case Types.APP_CLIENT_CHANGE:
      {
        state = {
          ...initialState,
        };
      }
      break;
    case Types.APP_SET_PENDING_OPPORTUNITIES_COUNT_FETCH_IN_PROGRESS:
      {
        state = {
          ...state,
          pendingOpportunitiesCountFetchInProgress: action.dataFetchInProgress,
        };
      }
      break;
    case Types.APP_SET_PENDING_OPPORTUNITIES_COUNT: {
      if (action.hasOwnProperty("count")) {
        state = {
          ...state,
          pendingOpportunitiesCount: {
            ...action.count,
          },
        };
      }
      break;
    }
    case Types.APP_SET_PENDING_OPPORTUNITIES_SUGGESTIONS: {
      if (action.suggestionIds) {
        state = {
          ...state,
          suggestionIds: [...action.suggestionIds],
        };
      }
      break;
    }
    case Types.APP_SET_PENDING_OPPORTUNITIES_HYGIENE: {
      state = {
        ...state,
        hygiene: {
          ...(action.hygiene || {}),
        },
      };
      break;
    }
    case Types.HYGIENE_SET_HYGIENE_FETCH_IN_PROGRESS:
      {
        state = {
          ...state,
          isHygieneFetchInProgress: action.hygieneFetchInProgress,
        };
      }
      break;
    case Types.HYGIENE_SET_HYGIENE_REFRESH_FETCH_IN_PROGRESS:
      {
        state = {
          ...state,
          isHygieneRefreshFetchInProgress: action.hygieneRefreshFetchInProgress,
        };
      }
      break;
    case Types.HYGIENE_SET_HYGIENE_FETCH_ERROR_MSG:
      {
        state = {
          ...state,
          hygieneFetchErrorMsg: action.hygieneFetchErrorMsg,
        };
      }
      break;
    case Types.SUGGESTION_ACTED:
      {
        const suggestionId = action.suggestionId;
        const currentCount = state.pendingOpportunitiesCount.SUGGESTIONS;
        const newCount = {
          ...state.pendingOpportunitiesCount,
          "SUGGESTIONS": (currentCount - 1) <= 0 ? 0 : currentCount - 1,
        };
        state = {
          ...state,
          suggestionIds: state.suggestionIds?.filter((_id) => suggestionId !== _id),
          pendingOpportunitiesCount: newCount,
        };
      }
      break;
    default:
      break;
  }

  return state;
};

export default reducer;
