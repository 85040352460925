import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { BaseClient, OSBillingServiceV2, InceptionService } from "@onlinesales-ai/services-v2";
import PlatformEventManager from "@onlinesales-ai/event-manager-v2";
import { OAuthHelper, populateEvent, wait } from "@onlinesales-ai/util-methods-v2";
import { fetchAndSetContactInfo as fetchAndSetContactInfoAction } from "@onlinesales-ai/app-v2/application";
import { OSHOCWithUtilities } from "@onlinesales-ai/os-hoc-with-utilities-v2";

function fireIntercomEvents(action, metaData) {
  populateEvent("APP", `PAYMENT||PAYU_PAYMENT_GATEWAY||${action}`, metaData);
}

class PayU extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fetchOrderIdLoading: false,
    };

    this.orderData = null;
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (
      newProps.amount !== this.props.amount ||
      newProps.currency !== this.props.currency ||
      newProps.userInfo?.email !== this.props.userInfo?.email
    ) {
      this.orderData = null;
    }
  }

  getPhoneNumber = (userInfo) => {
    const { countryDailingCode, marketplaceStoreDetails = {} } = this.props;
    let phone = userInfo.contact;

    // If no contact number is present add verfied/unverified contact number from authMetadata
    if (!phone && userInfo.authMetadataList) {
      userInfo.authMetadataList.forEach((authInfo = {}) => {
        if (authInfo.type === "MOBILE") {
          phone = authInfo.value;
        }
      });
    }

    if (!phone && marketplaceStoreDetails.mobile) {
      phone = marketplaceStoreDetails.mobile;
    }

    // Removing dialing code as gateway not accepting it.
    if (phone && phone.includes(countryDailingCode)) {
      phone = phone.substr(countryDailingCode.length);
    }

    return phone;
  };

  fetchOrderId = async () => {
    const { chargeAPICall, forceNewOrderId, financeChargeAPICall } = this.props;
    if (!forceNewOrderId && this.orderData) {
      await wait(2000);
      return Promise.resolve(this.orderData);
    }

    const {
      userInfo = {},
      clientId,
      agencyId,
      currency,
      amount,
      fireEventOnOrderId,
      useDummyEmail,
      fetchAndSetContactInfo,
      chargeExtraPayload,
      useEntity,
      isInvoicePayment,
      extraPayloadParams,
      entityId,
      entityType,
      paymentMode,
    } = this.props;

    this.setState({
      fetchOrderIdLoading: true,
    });

    let userEmail = userInfo.email;

    let phone = this.getPhoneNumber(userInfo);

    if (!phone) {
      try {
        const userData = await fetchAndSetContactInfo();
        phone = this.getPhoneNumber(userData);
      } catch (err) {
        throw Error("Something went wrong. Please contact support.");
      }
    }

    if (!userEmail && useDummyEmail && phone) {
      userEmail = `${phone}@onlinesales.ai`;
    }

    let response = {};

    const customerDetails = {
      firstname: userInfo?.name || "",
      productinfo: `Top-up from ${userInfo?.name || userInfo?.email}`,
      lastname: "",
      email: userEmail,
      phone,
      surl: `https://services.onlinesales.ai/neonService/util/redirect?redirectToUrl=${window.location.origin}/oauth_callback?stage=SUCCESS`,
      furl: `https://services.onlinesales.ai/neonService/util/redirect?redirectToUrl=${window.location.origin}/oauth_callback?error=true`,
    };

    if (financeChargeAPICall) {
      response = await financeChargeAPICall({
        paymentOverrides: {
          paymentModeInfo: customerDetails,
        },
      });
      response = {
        ...response?.result,
        metadata: {
          payUParams: response?.result?.entityPayments?.[0]?.paymentModeInfo,
        },
      };
    } else if (chargeAPICall) {
      response = await chargeAPICall(amount, { customer_details: customerDetails });
    } else if (useEntity) {
      const transactionResponse = await InceptionService.billingPostTransaction({
        entityId,
        entityType,
        amount,
        currency,
        transactionType: "CREDIT",
        paymentMode,
        creditType: "PREPAID",
        // description: "Test Description",
        metadata: {},
        ...chargeExtraPayload,
        paymentModeInfo: {
          payUParams: customerDetails,
        },
      });

      response = {
        ...transactionResponse?.response,
        metadata: {
          payUParams: transactionResponse?.response?.paymentModeInfo?.payUParams,
        },
      };
    } else if (isInvoicePayment) {
      const transactionResponse = await InceptionService.billingPostInvoiceTransaction({
        entityId,
        entityType,
        amount,
        currency,
        transactionType: "CREDIT",
        paymentMode,
        creditType: "PREPAID",
        // description: "Test Description",
        metadata: {},
        ...chargeExtraPayload,
        paymentModeInfo: {
          payUParams: customerDetails,
        },
        ...extraPayloadParams,
      });

      response = {
        ...transactionResponse?.response,
        metadata: {
          payUParams: transactionResponse?.response?.paymentModeInfo?.payUParams,
        },
      };
    } else {
      response = await OSBillingServiceV2.charge({
        clientId,
        email: userEmail,
        platform: "OS",
        amount,
        currency,
        application: "irisTestApplication",
        paymentMode: "PAYU_372426",
        ...chargeExtraPayload,
        creditType: "PREPAID",
        metadata: {
          payUParams: customerDetails,
        },
      });
    }

    if (!response?.metadata?.payUParams) {
      fireIntercomEvents("ORDER_CREATION_INVALID_RESPONSE", response);
      throw Error("PayU payment not supported please contact support.");
    }

    // this.orderData = response;

    this.setState({
      fetchOrderIdLoading: false,
    });

    fireIntercomEvents("ORDER_CREATED", response);

    if (fireEventOnOrderId && fireEventOnOrderId.length) {
      fireEventOnOrderId.forEach((event) => {
        PlatformEventManager.emit(event);
      });
    }

    return response;
  };

  fetchOrderDataFromPayu = async (popUpWindow) => {
    const { onError, payuEndpoint } = this.props;

    try {
      const orderDetails = await this.fetchOrderId();

      const request = orderDetails?.metadata?.payUParams || {};

      const callbackurlToOpen = `${
        window.location.origin
      }/payuPaymentWindowCallback?stage=INITIALIZE&params=${encodeURIComponent(
        JSON.stringify(request),
      )}&path=${payuEndpoint}&method=POST`;

      fireIntercomEvents("MODAL_OPEN", JSON.stringify(orderDetails));
      popUpWindow.postMessage({ isPayuRequestFromOS: true, callbackurlToOpen });
    } catch (err) {
      if (popUpWindow) {
        popUpWindow.postMessage({ isPayuRequestFromOS: true, closeWindow: true });
      }
      this.setState({
        fetchOrderIdLoading: false,
      });
      onError(err);
    }
  };

  openPaymentModal = async () => {
    const {
      onPaymentSuccess,
      onError,
      beforePayment,
      fireEventOnPaymentSuccess,
      fireEventOnPaymentFailed,
      paymentFailMessage,
    } = this.props;

    try {
      await beforePayment();
    } catch (err) {
      return;
    }

    let popUpWindow = null;

    const callbackurl = `${window.location.origin}/payuPaymentWindowCallback?stage=WAIT`;

    const resHandler = (event) => {
      let data = {};
      try {
        if (typeof event.data === "string") {
          data = JSON.parse(event.data || "{}");
        }
        if (
          (data.success !== undefined && data.success !== null) ||
          (data.error !== undefined && data.error !== null)
        ) {
          OAuthHelper.closePopUpWindow();
          if (data.success) {
            // reset order data after successfull payment
            fireIntercomEvents("PAYMENT_SUCCESS", JSON.stringify(this.orderData));
            this.orderData = null;
            if (fireEventOnPaymentSuccess && fireEventOnPaymentSuccess.length) {
              fireEventOnPaymentSuccess.forEach((e) => {
                PlatformEventManager.emit(e);
              });
            }
            onPaymentSuccess();
          } else {
            if (fireEventOnPaymentFailed && fireEventOnPaymentFailed?.length) {
              fireEventOnPaymentFailed.forEach((e) => {
                PlatformEventManager.emit(e);
              });
            }
            onError(paymentFailMessage);
          }
        }
      } catch (e) {
        throw new Error(BaseClient.getErrorMessage());
      }
    };

    popUpWindow = OAuthHelper.performOAuth(undefined, callbackurl, resHandler, false);

    popUpWindow.addEventListener(
      "message",
      (event) => {
        if (event?.data?.isPayuCallbackReady) {
          this.fetchOrderDataFromPayu(popUpWindow);
        }
      },
      false,
    );
  };

  render() {
    const { children } = this.props;
    const { fetchOrderIdLoading } = this.state;

    if (typeof children === "function") {
      return children({
        openPayment: this.openPaymentModal,
        isLoading: fetchOrderIdLoading,
      });
    }

    return null;
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    clientId,
    agencyId,
    userInfo: pUserInfo,
    marketplaceClientId,
    selectedEntityId,
    selectedEntityType,
  } = state.Application;

  const { contactNo, email, name, authMetadataList } = pUserInfo || {};
  const { marketplaceStoreDetails = {} } = state.OnBoarding?.data || {};

  const userInfo = {
    name,
    email,
    contact: contactNo,
    authMetadataList,
  };

  return {
    clientId: ownProps.clientId || clientId,
    agencyId,
    marketplaceClientId,
    userInfo: ownProps.userInfo || userInfo,
    marketplaceStoreDetails,
    entityId: ownProps.entityId || selectedEntityId,
    entityType: ownProps.entityType || selectedEntityType,
    paymentMode: ownProps?.paymentModeMapping?.[agencyId] || `PAYU_${marketplaceClientId}`,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchAndSetContactInfo: fetchAndSetContactInfoAction,
    },
    dispatch,
  );
};

const PayUWrapper = connect(mapStateToProps, mapDispatchToProps)(PayU);

PayUWrapper.defaultProps = {
  useEntity: false,
  isInvoicePayment: false,
  beforePayment: () => Promise.resolve(),
  fireEventOnOrderId: [],
  fireEventOnPaymentSuccess: [],
  currency: "INR",
  // payuEndpoint: "https://test.payu.in/_payment",
  payuEndpoint: "https://secure.payu.in/_payment",
  countryDailingCode: "+91",
  forceNewOrderId: false,
  chargeExtraPayload: {},
};

export default OSHOCWithUtilities(PayUWrapper);
