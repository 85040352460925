import { OnboardingService, NeonService } from "@onlinesales-ai/services-v2";
import { setDomainConfig } from "@onlinesales-ai/app-v2/domainConfig";

import types from "@onlinesales-ai/app-v2/onBoarding/types";
import { getDomainConfiguration } from "src/domainConfig/configuration";

import Types from "./types";

export const setHyperlocalSellerFetchInProgress = (isInProgress) => ({
  type: Types.SET_HYPERLOCAL_SELLER_DATA_FETCH_IN_PROGRESS,
  isInProgress,
});

export const setHyperlocalSellerFetchError = (error) => ({
  type: Types.SET_HYPERLOCAL_SELLER_DATA_FETCH_ERROR,
  error,
});

export const setHyperlocalSellerData = (sellerData) => ({
  type: Types.SET_HYPERLOCAL_SELLER_DATA,
  sellerData,
});

export const setNDAPostFlag = (shouldPostNDAStatus) => ({
  type: Types.SET_ONBOARDING_NDA_POST_FLAG,
  shouldPostNDAStatus,
});

export const fetchHyperlocalSellerData = (application) => {
  return (dispatch, getState) => {
    const state = getState();
    const { clientId } = state.Application;

    dispatch(setHyperlocalSellerFetchInProgress(true));
    dispatch(setHyperlocalSellerFetchError(false));

    NeonService.fetchSellerData({ clientId }, application)
      .then((response) => {
        dispatch(setHyperlocalSellerData(response));
        dispatch(setHyperlocalSellerFetchInProgress(false));
      })
      .catch((error, errorMsg) => {
        dispatch(setHyperlocalSellerFetchError(errorMsg));
        dispatch(setHyperlocalSellerFetchInProgress(false));
      });
  };
};

export const postHyperlocalSellerData = (payload, application) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      NeonService.postSellerData(payload, application)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
};

export const setOnboardingData = (onBoardingData, updateDomainConfig = true) => {
  return async (dispatch, getState) => {
    const state = getState();

    const appConfigs = onBoardingData?.appConfigs || {};
    const newClientConfigMapping = {};
    let domainConfig = state.DomainConfig;

    Object.keys(appConfigs).forEach((key) => {
      newClientConfigMapping[key] = {
        name: appConfigs[key],
      };
    });

    domainConfig = await getDomainConfiguration({
      clientConfigMapping: newClientConfigMapping,
    });

    // setting this first as, domain overrides are now dependent on onboarding details.   
    dispatch({
      type: types.SET_ONBOARDING_DETAILS,
      data: onBoardingData,
      domainConfig,
    });

    if (updateDomainConfig) {
      dispatch(setDomainConfig(domainConfig, onBoardingData));
    }
  };
};

export const postOnboardingData = (onBoardingData, updateDomainConfig = false) => {
  return (dispatch) => {
    return OnboardingService.postStatus(onBoardingData).then(async (response) => {
      dispatch(await setOnboardingData(response, updateDomainConfig));

      return response;
    });
  };
};
