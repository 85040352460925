import onBoardingReducer from "@onlinesales-ai/app-v2/onBoarding/reducer";

import Types from "./types";

const initialState = {
  data: null,
  isLoading: true,
  fetchError: false,
  shouldPostNDAStatus: false,
};

const reducer = (state = initialState, action) => {
  let hasChanged = true;
  switch (action.type) {
    case Types.SET_HYPERLOCAL_SELLER_DATA_FETCH_IN_PROGRESS:
      {
        state = {
          ...state,
          hyperlocalSeller: {
            ...state.hyperlocalSeller,
            fetchInProgress: action.isInProgress,
          },
        };
      }
      break;
    case Types.SET_HYPERLOCAL_SELLER_DATA_FETCH_ERROR:
      {
        state = {
          ...state,
          hyperlocalSeller: {
            ...state.hyperlocalSeller,
            error: action.error,
          },
        };
      }
      break;
    case Types.SET_HYPERLOCAL_SELLER_DATA: {
      state = {
        ...state,
        hyperlocalSeller: {
          ...state.hyperlocalSeller,
          sellerData: action.sellerData,
        },
      };
    }
      break;
    case Types.SET_ONBOARDING_NDA_POST_FLAG: {
      state = {
        ...state,
        shouldPostNDAStatus: action.shouldPostNDAStatus,
      };
    }
      break;
    default:
      hasChanged = false;
      break;
  }

  if (!hasChanged) {
    return onBoardingReducer(state, action, initialState);
  }
  return state;
};

export default {
  OnBoarding: reducer,
};
