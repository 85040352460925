// import { uiProxy, format } from "@onlinesales-ai/util-methods-v2";
// import { subDays } from "date-fns/esm";

export default {
  // "/kamService/report/fetch/performanceFunnel": uiProxy({
  //   resolver: () => {
  //     return {
  //       data: [
  //         {
  //           spend: 6000,
  //           impressions: 51250,
  //           clicks: 2304,
  //           ctr: 4.4,
  //         },
  //       ],
  //       groupedData: {},
  //     };
  //   },
  // }),
};
