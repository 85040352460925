import BaseClient from "../baseClient";

const servicePrefix = "/suggestionDbSvc/";

class SuggestionsService extends BaseClient {
  static fetch(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}rollupSuggestions`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
    );
  }

  static takeAction(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}rollupSuggestions`,
        method: "POST",
        data: JSON.stringify(payload),
        headers: {
          "X-EVENT-TYPE": "SHOP_SUGGESTION_ACTOR_EVENT",
          "X-REFERRAL": "shopsUI",
        },
      },
      application,
    );
  }
}

export default SuggestionsService;
