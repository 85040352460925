import React, { useMemo } from "react";
import _get from "lodash/get";

import { Pill } from "@onlinesales-ai/pill-v2";

import {
  formatValuesInThousands,
  getCurrencyDOM,
} from "@onlinesales-ai/util-methods-v2";

import "./style.less";

const TopupAdditionPills = ({
  additionPills = [],
  onChange,
  formValues,
  dataKey,
  showAddButton,
  showCurrency = false,
  ...rest
}) => {
  const value = useMemo(() => Number(_get(formValues, dataKey) || 0), [formValues, dataKey]);

  return (
    <div className="topup-addition-pills">
      {
        additionPills?.map(({
          amount = 0,
        }) => {
          return (
            <Pill
              className="top-up-add-pill pendo_feature_topup_additional_pill"
              variant="white"
              onClick={() => {
                onChange({
                  [dataKey]: showAddButton ? (value + amount) : amount,
                });
              }}
            >
              {showAddButton ? "+" : ""} {showCurrency && getCurrencyDOM()} {formatValuesInThousands(amount)}
            </Pill>
          );
        })
      }
    </div>
  );
};

TopupAdditionPills.defaultProps = {
  showAddButton: true,
};

export default TopupAdditionPills;
