import React, {
  useState, useEffect, useMemo, useRef, useContext
} from "react";
import { Trans } from "react-i18next";
import { Carousel } from "react-bootstrap";
import _get from "lodash/get";
import { Button } from "@onlinesales-ai/button-v2";
import { OSWorker, OAuthHelper } from "@onlinesales-ai/util-methods-v2";
import WithTooltip from "@onlinesales-ai/tooltip-v2";
import { OSHOCWithUtilities } from "@onlinesales-ai/os-hoc-with-utilities-v2";
import PlatformEventManager from "@onlinesales-ai/event-manager-v2";
import { pendoTrackEvent } from "@onlinesales-ai/util-methods-v2";
import uiAnnouncementWorker from "worker-plugin/loader?name=uiAnnouncement!@onlinesales-ai/workers-v2/src/uiAnnouncement.worker";

import FeatureReleaseBoard from "./templates/featureRelease";
import CelebratoryNoteBoard from "./templates/celebratoryNote";
import UpsellingTemplate from "./templates/upsellingTemplate";
import CampaignActiveNote from "./templates/campaignActiveNote";


import "./index.less";

const ctaTypes = {
  OAUTH: {
    key: "OAUTH",
  },
  ANCHOR: {
    key: "ANCHOR",
  },
  SCROLL_TO: {
    key: "SCROLL_TO",
  },
  TRIGGER_PENDO_GUIDE: {
    key: "TRIGGER_PENDO_GUIDE",
  },
  ALL_OUTLET_ANCHOR: {
    key: "ALL_OUTLET_ANCHOR",
  },
  PLATFORM_EVENT: {
    key: "PLATFORM_EVENT",
  },
};

const templatesEnum = {
  CELEBRATORY_NOTE_TEMPLATE: {
    key: "CELEBRATORY_NOTE_TEMPLATE",
    component: CelebratoryNoteBoard,
  },
  FEATURE_REALEASE_TEMPLATE: {
    key: "FEATURE_REALEASE_TEMPLATE",
    component: FeatureReleaseBoard,
  },
  "UPSELL_TEMPLATE": {
    key: "UPSELL_TEMPLATE",
    component: UpsellingTemplate,
  },
  CUSTOM_NO_CAMPAIGNS_ACTIVE_TEMPLATE: {
    key: "CUSTOM_NO_CAMPAIGNS_ACTIVE_TEMPLATE",
    component: CampaignActiveNote,
  },
};

const AnnouncementBoard = ({
  redirectUrlAction,
  announcements,
  setAsSeenCallback,
  userInfo,
  clientId,
  onboardingDetails,
  onboardingFlagToAnnouncementConfig,
  conditionalAnnouncements,
  state,
  showAnnouncementsIcon,
  changeClientId,
  ...props
}) => {
  const [iconClick, setIconClick] = useState(false);
  const [showAnnouncement, setShowAnnouncement] = useState(false);
  const [announcementsToShow, setAnnouncementsToShow] = useState([]);
  const [uiGeneratedAnnouncements, setUIGeneratedAnnouncements] = useState([]);
  const isAnnouncementOpenedOnce = useRef(false);
  const workerRef = useRef(null);
  const allAnnouncements = useMemo(() => {
    return (
      [
        ...uiGeneratedAnnouncements,
        ...announcementsToShow
      ]
    );
  }, [announcementsToShow, uiGeneratedAnnouncements]);

  useEffect(() => {
    const filteredAnnouncements = announcements.filter((ann) => ann.seen === false);
    setAnnouncementsToShow(filteredAnnouncements);
  }, [announcements]);

  useEffect(() => {
    if(!isAnnouncementOpenedOnce.current) {
      const isOpen = allAnnouncements?.length > 0;
      setShowAnnouncement(isOpen);
      isAnnouncementOpenedOnce.current = isOpen;
    }
    allAnnouncements.forEach((announcement) => {
      if (announcement?.message?.pendoTrackEvent) {
        let metaData;
        try {
          metaData = JSON.parse(announcement?.message?.announcementMetaData);
        } catch (e) {}
        pendoTrackEvent(`ANNOUCEMENT||${announcement?.message?.pendoTrackEvent || ""}`, metaData);
      }
    });
  }, [allAnnouncements]);

  useEffect(() => {
    setUIGeneratedAnnouncements([]);
    isAnnouncementOpenedOnce.current = false;
  }, [clientId]);

  useEffect(() => {
    try {
      workerRef.current?.terminate();
      workerRef.current = OSWorker(uiAnnouncementWorker, {}, true);

      workerRef.current.onmessage = (event) => {
        const {
          announcements = []
        } = event?.data || {};
        setUIGeneratedAnnouncements(announcements);

        workerRef.current.terminate();
        workerRef.current = null;
      };

      workerRef.current.postMessage({
        onboardingDetails,
        onboardingFlagToAnnouncementConfig,
        userId: userInfo?.id,
        conditionalAnnouncements,
        clientId,
        state: { ...state, DomainConfig: null },
      });
    }
    catch(e){}
  }, [
    onboardingDetails, state.ClientAchievement, state.AccountLinking,
    state.Billing, state.Application.reportingData, state.LmsV2, state?.GoalsV2?.funnelMetricsForWalletBalance, state?.GoalsV2?.totalSkuCount
  ]);

  const validateCTACondition = (ctaConfig, callback = () => {}) => {
    if (ctaConfig?.validationConfig?.conditions?.length) {
      let isValidated = true;
      let validationConfig = ctaConfig.validationConfig;
      let conditions = ctaConfig.validationConfig.conditions;
      for (let i = 0; i < conditions.length; i++) {
        let value = _get(state, conditions[i].path);
        isValidated = (value !== conditions[i].value);
        if (!isValidated) {
          break;
        }
      }
      if (!isValidated && validationConfig.confirmationConfig) {
        let confirmationConfig = validationConfig.confirmationConfig;
        props.showConfirmationModal({
          isShow: true,
          ...confirmationConfig,
          actionBtnCallback: () => {
            props.resetConfirmationModal();
            redirectUrlAction(confirmationConfig.href)
          },
          rightBtnCallback: () => {
            props.resetConfirmationModal();
          },
        });
      } else {
        callback();
      }
    } else {
      callback();
    }
  };

  const scrollTo = (scrollToId) => {
    const $scrollTo = document.getElementById(scrollToId);
    $scrollTo.scrollIntoView();
  };
  
  const performOAuth = (url) => {
    OAuthHelper.performOAuth(
      undefined,
      url,
      (event) => {
        let data = event.data || "{}";
          try {
            data = JSON.parse(decodeURIComponent(data));
          } catch (e) {}

          if (data.success !== undefined && data.success !== null) {
            OAuthHelper.closePopUpWindow();
          }
      },
      false
    );
  };

  const handleAnnouncementCTACallback = (announcement, ctaConfig) => {
    const ctaConfigToUse = ctaConfig || announcement?.message?.cta || {};
    const { type = "ANCHOR", scrollToId, pendoGuideId } = ctaConfigToUse;
    let { url } = ctaConfigToUse;
    if (type === ctaTypes.OAUTH.key) {
      performOAuth(url);
    } else if (type === ctaTypes.ANCHOR.key) {
      validateCTACondition(ctaConfig, () => {
        redirectUrlAction(url);
      });
    } else if (type === ctaTypes.ALL_OUTLET_ANCHOR.key) {
      if (url?.includes("__CLIENT_ID__") && clientId) {
        url = url.replace("__CLIENT_ID__", clientId);
      }

      changeClientId("CLIENT_AGGREGATOR");
      redirectUrlAction(url);
    } else if (type === ctaTypes.SCROLL_TO.key) {
      scrollTo(scrollToId);
    } else if (type === ctaTypes.TRIGGER_PENDO_GUIDE.key) {
      try {
        window.pendo.showGuideById(pendoGuideId);
      } catch (e) {}
    } else if (type === ctaTypes.PLATFORM_EVENT.key) {
      PlatformEventManager.emit(ctaConfigToUse.event);
    }
    setShowAnnouncement(false);
    setIconClick(false);
    setAsSeenCallback(announcement);
  };

  const getAnnouncementComponent = (announcement, index) => {
    let retnVal = null;
    if (announcement?.message?.type && templatesEnum[announcement.message.type]) {
      const Component = templatesEnum[announcement.message.type]?.component;
      if (Component) {
        retnVal = (
          <Component
            {...announcement.message}
            name={announcement.name}
            redirectTo={handleAnnouncementCTACallback.bind(this, announcement)}
          />
        );
      }
    }

    return retnVal;
  };

  const renderAnnouncement = () => {
    let announcementComponentsToShow = [],
      lengthOfAnnouncement = 0;

    if (allAnnouncements && Array.isArray(allAnnouncements) && allAnnouncements.length) {
      allAnnouncements.forEach((announcement, index) => {
        let componentElement = getAnnouncementComponent(announcement, index);
        if (componentElement) {
          announcementComponentsToShow.push(componentElement);
        }
      });
      lengthOfAnnouncement = announcementComponentsToShow.length;
    }

    return (
      <Carousel
        interval={null}
        indicators={lengthOfAnnouncement > 1}
        controls={lengthOfAnnouncement > 1}
        prevIcon={<span className="glyphicon-chevron-left icon icon-angle-left" />}
        nextIcon={<span className="glyphicon-chevron-right icon icon-angle-right" />}
      >
        {announcementComponentsToShow.map((announcement, index) => {
          return <Carousel.Item key ={index}>{announcement}</Carousel.Item>;
        })}
      </Carousel>
    );
  };

  const getAnnouncementDom = () => {
    return (
      <div className="announcement-container">
        <div className={`announcement-widget-wrapper ${showAnnouncement ? "open" : "not-open"}`}>
          <div className="os-widget-content">
            <div className="container">
              {renderAnnouncement()}
              <Button onClick={() => {
                setIconClick(false);
                setShowAnnouncement(false);
              }} type="default" className="close os-widget-close">
                <span className="icon icon-close1" />
              </Button>
            </div>
          </div>
        </div>
        <div className="announcement-widget-overlay" />
      </div>
    );
  };

  return (
    <>
      {showAnnouncementsIcon ? (
          <>
        <WithTooltip title="Announcements" placement="top">

            <Button
              type="primary" link
              icon="icon icon-announcement-nav"
              className="announcement-btn"
              onClick={() => {
                setIconClick(true);
                setShowAnnouncement(true);
              }}
            >
            <span className="badge announcement-count">{allAnnouncements.length}</span>
            
            </Button>
            </WithTooltip>
            {getAnnouncementDom()}
          </>
        
      ) : (
        getAnnouncementDom()
      )}
    </>
  );
};

AnnouncementBoard.defaultProps = {
  announcements: [],
  setAsSeenCallback: () => {},
};

export default OSHOCWithUtilities(AnnouncementBoard);
