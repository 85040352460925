import React, { useMemo, useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import _find from "lodash/find";
import _reject from "lodash/reject";

import { OSHOCWithUtilities } from "@onlinesales-ai/os-hoc-with-utilities-v2";
import { FixLayout } from "@onlinesales-ai/fix-layout-v2";
import { Button } from "@onlinesales-ai/button-v2";
import { customMergeOS } from "@onlinesales-ai/util-methods-v2";
import { CreativeUploadServiceV2 } from "@onlinesales-ai/services-v2";
import { Tabs } from "@onlinesales-ai/tabs-v2";
import { GlobalContext } from "@onlinesales-ai/utils-components-v2";

import CreativesSelection from "./components/creativesSelection";
import ThirdPartyImageSelection from "./components/thirdPartyImageSelection";
import { validateFileUsingConfig } from "./utils";

import "./index.less";

const component = {
  CREATIVES: CreativesSelection,
  THIRD_PARTY_LIBRARY: ThirdPartyImageSelection,
};

const mediaDefaultConfig = {
  CREATIVES: {
    title: "Creatives",
    allowedFileTypes: "image/*,video/*",
    uploadCta: "Upload Image/Video",
    subType: "CUSTOM",
    typeConfig: {
      IMAGE: {
        allowedFileTypes: "image/*",
        uploadCta: "Upload Image",
      },
      VIDEO: {
        allowedFileTypes: "video/*",
        uploadCta: "Upload Video",
      },
    },
  },
  THIRD_PARTY_LIBRARY: {
    title: "Unsplash",
  },
};

const MediaLibrary = ({
  clientId,
  userInfo,
  ctaText,
  hideFooter,
  hideHeader,
  heading,
  subHeading,
  mediaConfig: pMediaConfig,
  mediaToShow,
  isMobile,
  isSelectable,
  jobDoneCallback,
  allowMultiSelect,
  containerClass,
  onMediaSelect,
  uploadFile,
  mediaType,
  extraPostPayload,
  mediaFileConversionConstants,
  ...props
}) => {
  const { t } = useTranslation();
  const { showToastMessage } = useContext(GlobalContext);
  const [selectedData, setSelectedData] = useState([]);

  const mediaConfig = useMemo(() => {
    let config = customMergeOS({}, mediaDefaultConfig, pMediaConfig);

    if (mediaType && config?.typeConfig?.[mediaType]) {
      config = customMergeOS({}, config, config.typeConfig[mediaType]);
    }

    return config;
  }, [pMediaConfig, mediaType]);

  const onSelectList = (selected) => {
    if (_find(selectedData, selected)) {
      setSelectedData(_reject(selectedData, selected));
    } else if (allowMultiSelect) {
      setSelectedData([...selectedData, selected]);
    } else {
      setSelectedData([selected]);
    }
  };

  const resetSelectedList = () => {
    setSelectedData([]);
  };

  const onClickSave = () => {
    const { maxFileSizeInMB, minFileSizeInMB, dimensionValidation, durationValidation } =
      uploadFile || {};
    if (
      validateFileUsingConfig(
        {
          maxFileSizeInMB,
          minFileSizeInMB,
          mediaType,
          defaultCreativeLanguage: props?.defaultCreativeLanguage,
          dimensionValidation,
          durationValidation,
          ...extraPostPayload,
        },
        selectedData?.[0]?.mediaObj,
        {
          t,
          showToastMessage,
          mediaFileConversionConstants,
        },
      )
    ) {
      if (selectedData?.length) {
        const unsplashData = selectedData.filter((s) => s.source === "UNSPLASH");
        if (unsplashData.length) {
          const downloadLocations = unsplashData.map((u) => u.downloadLocation);
          try {
            CreativeUploadServiceV2.unsplashDownload({
              clientId,
              downloadLocations,
            });
          } catch (err) {}
        }
      }

      if (onMediaSelect) {
        onMediaSelect(selectedData);
      }
    }
  };

  const tabs = mediaToShow.map((key) => {
    const config = mediaConfig[key];

    return {
      key,
      title: config.title,
      props: {
        clientId,
        userInfo,
        subType: key,
        config,
        selectedData,
        isMobile,
        isSelectable,
        resetSelectedList,
        onSelect: (url, extraParams = {}) => {
          onSelectList({
            url,
            tabType: extraParams.type,
            subType: key,
            ...extraParams,
          });
        },
        mediaType,
        uploadFile,
        mediaConfig,
        extraPostPayload,
        ...props,
      },
      Component: component[key],
    };
  });

  const renderTabs = () => {
    return (
      <Tabs renderSingleTab={false} tabs={tabs} wrapperClassName="library-wrapper" type="pill" />
    );
  };

  return (
    <FixLayout className="media-library-fixed-layout-wrapper">
      {!hideHeader ? (
        <FixLayout.Header>
          <div
            className={`header-texts d-align-between ${subHeading ? "with-sub-heading" : "without-sub-heading"}`}
          >
            <h4 className="heading">
              <Trans>{heading}</Trans>{" "}
            </h4>
            {subHeading ? (
              <h5 className="sub-heading">
                <Trans>{subHeading}</Trans>
              </h5>
            ) : null}
          </div>
        </FixLayout.Header>
      ) : null}
      <FixLayout.Body>
        <div className={`${containerClass || ""} media-library-content-wrapper`}>
          <div className={`media-library-content ${`tab-count-${mediaToShow.length}`}`}>
            {renderTabs()}
          </div>
        </div>
      </FixLayout.Body>
      {!hideFooter ? (
        <FixLayout.Footer>
          <Button
            disabledTooltip={t("Please select a creative")}
            disabled={isSelectable && !selectedData.length}
            onClick={isSelectable ? onClickSave : jobDoneCallback}
            className="pendo_feature_media_library_save_button"
          >
            {ctaText}
          </Button>
        </FixLayout.Footer>
      ) : null}
    </FixLayout>
  );
};

MediaLibrary.defaultProps = {
  isSelectable: true,
  mediaToShow: ["CREATIVES"],
  mediaConfig: {},
  // mediaType: "IMAGE",
  application: "application",
  ctaText: "Choose",
  hideFooter: false,
  hideHeader: false,
  heading: "Creative Library",
};

const mapStateToProps = (state, ownProps) => {
  return {
    clientId: ownProps?.clientId || state.Application.clientId,
    userInfo: state.Application.userInfo,
    isMobile: state.Application.isMobile,
    mediaFileConversionConstants: state?.DomainConfig?.mediaFileConversionConstants,
  };
};

export default connect(mapStateToProps)(OSHOCWithUtilities(MediaLibrary));
