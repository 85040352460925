import Types from "./types";

const initialState = {
  data: {},
  dismissedKeys: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.APP_CLIENT_CHANGE:
      {
        state = {
          ...initialState,
        };
      }
      break;
    case Types.SET_UI_NOTIFICATIONS:
      {
        let data = {
          ...(action.upsert ? state.data || {} : {}),
          ...action.data,
        };

        Object.keys(data).forEach((notificationType) => {
          if (data[notificationType].length) {
            data[notificationType] = data[notificationType].filter((notificationInfo) => {
              return !state.dismissedKeys.includes(notificationInfo.key);
            });
          }
        });

        state = {
          ...state,
          data: {
            ...data,
          },
        };
      }
      break;
    case Types.DISMISS_UI_GENERATED_NOTIFICATIONS:
      {
        if (action.notificationType) {
          let notifications = state.data[action.notificationType] || [];
          notifications = notifications.filter((notificationInfo) => {
            return notificationInfo.key !== action.key;
          });

          state = {
            ...state,
            data: {
              [action.notificationType]: [...notifications],
            },
            dismissedKeys: [...state.dismissedKeys, action.key],
          };
        }
      }
      break;
    default:
      break;
  }

  return state;
};

export default {
  UINotification: reducer,
};
