import React from "react";
import "./index.less";

const SubscriptionFee = ({
  subscriptionFeeDescription,
  subscriptionImageUrl,
}) => {
    return (
      <div className="subscription-fee-block">
        <div className="subscription-icon">
          {subscriptionImageUrl && <img src={subscriptionImageUrl} className="img-fluid" alt="emptyicon" /> }
          {subscriptionFeeDescription && (
            <div className="subscription-fee-desc">
              <p className="fee-title">Subscription fee</p>
              <div className="fee-desc">{subscriptionFeeDescription}</div>
            </div>
          )}
        </div>
      </div>
    );
};

SubscriptionFee.defaultProps = {};

export default SubscriptionFee;
