import React from "react";
import { Trans } from "react-i18next";

import AsyncImage from "@onlinesales-ai/async-image-v2";
import { Button } from "@onlinesales-ai/button-v2";

import ModalWindow from "./modalWindow";

const SuccessModal = ({
  isShow,
  title,
  successMsg,
  actionBtnText,
  closeButtonHandler,
  imageUrl,
  imgClassName,
}) => {
  return (
    <ModalWindow
      isShow={isShow}
      headerTitle={title}
      containerClass="common-media-success-modal-container"
      modalProps={{
        backdrop: "static",
      }}
    >
      <div className="common-media-upload-successful">
        {isShow && <AsyncImage imgSrc={imageUrl} imgClassName={imgClassName} />}
        <div className="upload-success-message">
          {typeof successMsg === "string" ? <Trans>{successMsg}</Trans> : successMsg}
        </div>
        <div className="footer">
          {actionBtnText && <Button onClick={closeButtonHandler}>{actionBtnText}</Button>}
        </div>
      </div>
    </ModalWindow>
  );
};

SuccessModal.defaultProps = {
  imageUrl: "https://osads.gumlet.io/image/upload/v1614264577/BulkSku/winning-bulk-sku.svg",
};

export default SuccessModal;
