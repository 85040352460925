import Types from "./types";

const reducer = (state, action) => {
  switch (action.type) {
    case Types.SET_TOTAL_SKU_DATA:
      if (action.data) {
        state = {
          ...state,
          totalSkuCount: action.data?.totalCount,
        };
      }
      break;
    case Types.SET_GOAL_SKU_SELECTION_DATA:
      if (action.data) {
        state = {
          ...state,
          goalDetails: {
            ...state.goalDetails,
            [action.goalId]: {
              ...(state.goalDetails?.[action.goalId] || {}),
              skuSelectionData: action.data,
            },
          },
        };
      }
      break;
    case Types.SET_GOAL_DATA:
      if (action.goalData) {
        state = {
          ...state,
          goalDetails: {
            ...state.goalDetails,
            [action.goalData.id]: {
              ...(state.goalDetails?.[action.goalData.id] || {}),
              data: action.goalData,
            },
          },
        };
      }
      break;
    case Types.UPDATE_GOAL_DATA:
      if (action.goalData) {
        state = {
          ...state,
          goalDetails: {
            ...state.goalDetails,
            [action.goalData.id]: {
              ...(state.goalDetails?.[action.goalData.id] || {}),
              data: action.goalData,
            },
          },
        };
      }
      break;
    case Types.SET_GOAL_LOADING_STATUS:
      if (action.goalId) {
        state = {
          ...state,
          goalDetails: {
            ...state.goalDetails,
            [action.goalId]: {
              ...(state.goalDetails?.[action.goalId] || {}),
              isFetchInProgress: action.isLoading,
            },
          },
        };
      }
      break;
    case Types.SET_GOAL_LAUNCHER_ID:
      {
        state = {
          ...state,
          launcherId: action.goalId,
        };
      }
      break;
    case Types.SET_GOALS_LIST_DATA:
      {
        const dataToSave = Object.keys(action.goals).reduce((memo, id) => {
          return {
            ...memo,
            [id]: {
              ...(state?.goalDetails?.[id] || {}),
              ...action.goals[id],
            },
          };
        }, {});
        state = {
          ...state,
          goalDetails: dataToSave,
        };
      }
      break;
    case Types.UPDATE_GOALS_LIST_DATA:
      {
        const copyData = { ...state.goalDetails };

        action.goals.forEach((goal) => {
          copyData[goal.id] = {
            ...(state.goalDetails?.[goal.id] || {}),
            data: {
              ...(state.goalDetails?.[goal.id]?.data || {}),
              ...goal,
            },
          };
        });

        state = {
          ...state,
          goalDetails: copyData,
        };
      }
      break;
    case Types.SET_GOALS_CANDY_LOADING_STATUS:
      state = {
        ...state,
        isFetchInProgress: action.isLoading,
      };
      break;
    case Types.UPDATE_REPORTING_DATA_FOR_X_DAYS:
      {
        state = {
          ...state,
          reportingDataForXDays: {
            ...state.reportingDataForXDays,
            [action.key]: {
              ...(state.reportingDataForXDays[action.key] || {}),
              ...action.data,
            },
          },
        };
      }
      break;
    default:
      break;
  }

  return state;
};

export default reducer;
