import React from "react";

import Dropdown from "../dropdown";
import { components } from "@onlinesales-ai/dropdown-v2";

import "./index.less";

const DropdownSubtitle = ({ drodownProps, formGroupClassName, ...props }) => {
  const SingleValue = (SingleValueProps) => (
    <components.SingleValue {...SingleValueProps}>
      {SingleValueProps?.data?.label}
    </components.SingleValue>
  );

  const formatOptionLabel = ({ label, description }) => {
    return (
      <div className="dropdownSubtitle-option-row">
        <div className="content">
          <div className="label">{label}</div>
          <div className="description ellipsis-text">{description}</div>
        </div>
      </div>
    );
  };

  const dropdownProps = {
    ...drodownProps,
    formatOptionLabel,
    components: {
      SingleValue,
    },
  };

  return (
    <Dropdown
      {...props}
      formGroupClassName={`${formGroupClassName} select-trigger`}
      drodownProps={dropdownProps}
    />
  );
};

export default DropdownSubtitle;
