import React, { useEffect, useRef, useState } from "react";

import "./index.less";

const ScrollCarousel = ({ className, children, numberOfCards, cardPadding }) => {
  const wrapperRef = useRef();
  const [isPrevEnabled, setIsPrevEnabled] = useState(false);
  const [isNextEnabled, setIsNextEnabled] = useState(false);
  const eleRef = useRef();

  const onScroll = () => {
    if (wrapperRef.current) {
      const { scrollWidth, scrollLeft, clientWidth } = wrapperRef.current || {};
      setIsNextEnabled((scrollWidth !== scrollLeft + clientWidth) && (children || []).length > numberOfCards);
      setIsPrevEnabled(scrollLeft !== 0);
    }
  };

  useEffect(() => {
    onScroll();
  });

  const onClickNext = () => {
    const { scrollWidth, scrollLeft, clientWidth } = wrapperRef.current || {};
    const cardWidth = eleRef?.current?.scrollWidth || 0;

    let scrollValue = 0;

    if (scrollLeft) {
      scrollValue = scrollLeft + cardWidth + cardPadding;
    } else {
      scrollValue = cardWidth + cardPadding + cardPadding / 2; //- (clientWidth - cardWidth) / 4 + 10;
    }

    if (scrollValue > scrollWidth) {
      scrollValue = scrollWidth;
    }

    wrapperRef.current.scrollTo(scrollValue, 0);
  };

  const onClickPrev = () => {
    const { scrollLeft, scrollWidth, clientWidth } = wrapperRef.current || {};
    const cardWidth = eleRef?.current?.scrollWidth || 0;

    let scrollValue = 0;

    if (isNextEnabled) {
      scrollValue = scrollLeft - cardWidth - cardPadding;
    } else {
      scrollValue =
        scrollWidth -
        cardWidth * numberOfCards -
        (clientWidth - cardWidth) / numberOfCards -
        cardPadding * numberOfCards -
        cardPadding / 2; // just to incldue the padding of first card
    }

    if (scrollValue < 0) {
      scrollValue = 0;
    }

    wrapperRef.current.scrollTo(scrollValue, 0);
  };

  return (
    <div onScroll={onScroll} ref={wrapperRef} className={`os-scroll-carousel-wrapper ${className}`}>
      {isPrevEnabled ? <span className="icon icon-angle-left" onClick={onClickPrev} /> : null}
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, { ref: eleRef });
      })}
      {isNextEnabled ? <span className="icon icon-angle-right" onClick={onClickNext} /> : null}
    </div>
  );
};

ScrollCarousel.defaultProps = {
  numberOfCards: 4,
  cardPadding: 20,
};

export default ScrollCarousel;
