/* eslint-disable prefer-const */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withTranslation, Trans, useTranslation } from "react-i18next";
import { ProgressBar, Accordion, Popover, OverlayTrigger } from "react-bootstrap";

import { Button } from "@onlinesales-ai/button-v2";
import { OSBulkActionsService } from "@onlinesales-ai/services-v2";
import { asyncWait, sanitizePayload, usePrevious } from "@onlinesales-ai/util-methods-v2";
import {
  dismissFileDownload as dismissFileDownloadAction,
  updateFileDownloadStatus as updateFileDownloadStatusAction,
} from "@onlinesales-ai/app-v2/application";
import { CLIENT_AGGREGATOR_CONFIG } from "@onlinesales-ai/constants-v2";

import "./index.less";

const FILE_STATUS_POLL_TIME = 5000;

const FILE_DOWNLOAD_STATUS_ENUM = {
  INITIATED: {
    key: "INITIATED",
    step: 1,
  },
  PREPARING: {
    key: "PREPARING",
    step: 2,
  },
  SUCCESS: {
    key: "SUCCESS",
    step: 3,
  },
  FAILED: {
    key: "FAILED",
    step: 3,
  },
};

const DownloaderBar = ({
  agencyId,
  fileDownloadStatus,
  dismissFileDownload,
  clientId,
  clients,
  isClientLevel,
  isEntityLevel: pIsEntityLevel,
  selectedEntityId,
  selectedEntityType,
  updateFileDownloadStatus,
  triggerTooltip,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const prevFileDownloadStatus = usePrevious(fileDownloadStatus);
  const [showTooltip, setShowTooltip] = useState(true);
  const { t } = useTranslation();
  const pollRef = useRef(null);
  const targetRef = useRef(null);

  const startFileStatusPoll = () => {
    pollRef.current = pollDownloadStatus();
    pollRef.current?.startPoll();
  };

  useEffect(() => {
    startFileStatusPoll();

    return () => {
      if (pollRef.current) {
        pollRef.current.stopPoll();
      }
    };
  }, [fileDownloadStatus]);

  const isDownloadInProgress = ({ type, ...rest }) => {
    let isInProgress = false;

    switch (type) {
      case "BULK_ACTION":
        {
          if (["READY", "DRAFT", "INPROGRESS"].includes(rest.jobDetails?.status)) {
            isInProgress = true;
          }
        }
        break;
      default:
        break;
    }

    return isInProgress;
  };

  const fetchFileStatus = async (files = [], { isEntityLevel } = {}) => {
    // Convert this to batchcall as and when needed
    let clientIdToUse = clientId;
    // In case of All Clients passing the first valid client id from list
    if (isClientLevel && clientId === CLIENT_AGGREGATOR_CONFIG.value) {
      clientIdToUse = clients?.[1]?.id;
    }
    try {
      const payload = {
        sendWholeObject: true,
        agencyId,
        ...(isClientLevel && { clientId: clientIdToUse }),
        ...(isEntityLevel
          ? {
              entityId: selectedEntityId,
              entityType: selectedEntityType,
            }
          : {}),
        filters: {
          _id: { $in: files.map((fileInfo) => fileInfo?.jobDetails?.id) },
        },
        userActionsOnly: false,
      };
      let response = {};

      if (isEntityLevel) {
        response = await OSBulkActionsService.getBulkActionseEntity(sanitizePayload({ payload }), "application");
      } else if (isClientLevel) {
        response = await OSBulkActionsService.getBulkActionsClient(sanitizePayload({ payload }), "application");
      } else {
        response = await OSBulkActionsService.getBulkActions(sanitizePayload({ payload }), "application");
      }

      if (response.bulkActions) {
        updateFileDownloadStatus(
          response.bulkActions.map((jobDetails) => ({
            type: "BULK_ACTION",
            jobDetails: {
              ...jobDetails,
              uiConfig: {
                ...files.find((fileInfo) => fileInfo?.jobDetails?.id === jobDetails.id)?.jobDetails
                  ?.uiConfig,
              },
            },
          })),
        );
      }
    } catch (e) {}
  };

  const pollDownloadStatus = () => {
    let poll = true;

    if (isClientLevel && pIsEntityLevel) {
      const clientFilesToPoll = [];
      const entityFilesToPoll = [];

      fileDownloadStatus.forEach((fileInfo) => {
        if (isDownloadInProgress(fileInfo)) {
          if (fileInfo?.jobDetails?.uiConfig?.isEntityLevel) {
            entityFilesToPoll.push(fileInfo);
          } else {
            clientFilesToPoll.push(fileInfo);
          }
        }
      });

      if (clientFilesToPoll.length || entityFilesToPoll.length) {
        return {
          startPoll: async () => {
            while (poll) {
              if (poll) {
                await asyncWait(FILE_STATUS_POLL_TIME);
                try {
                  if (clientFilesToPoll.length) {
                    await fetchFileStatus(clientFilesToPoll);
                  }
                  if (entityFilesToPoll.length) {
                    await fetchFileStatus(entityFilesToPoll, { isEntityLevel: true });
                  }
                } catch (err) {
                  console.log("Err");
                }
              }
            }
          },
          stopPoll: () => {
            poll = false;
          },
        };
      }
    } else {
      const filesToPoll = [];

      fileDownloadStatus.forEach((fileInfo) => {
        if (isDownloadInProgress(fileInfo)) {
          filesToPoll.push(fileInfo);
        }
      });

      if (filesToPoll.length) {
        return {
          startPoll: async () => {
            while (poll) {
              if (poll) {
                await asyncWait(FILE_STATUS_POLL_TIME);
                try {
                  await fetchFileStatus(filesToPoll, { isEntityLevel: pIsEntityLevel });
                } catch (err) {}
              }
            }
          },
          stopPoll: () => {
            poll = false;
          },
        };
      }
    }

    return null;
  };

  const onDownloadFile = async (fileUrl) => {
    window.open(fileUrl, "_blank");
  };

  const onDismissFileDownload = (index) => {
    dismissFileDownload(index);
  };

  const getFileStatusDetails = ({ type, ...rest }, index) => {
    let isReadyToDownload = false;
    let status = FILE_DOWNLOAD_STATUS_ENUM.PREPARING.key;
    let fileUrl = null;
    let fileName = null;
    if (type === "BULK_ACTION") {
      const { jobDetails = {} } = rest;
      fileName = jobDetails.inputData.fileName;
      if (jobDetails.status === "COMPLETED") {
        status = FILE_DOWNLOAD_STATUS_ENUM.SUCCESS.key;
        isReadyToDownload = true;
        fileUrl = jobDetails?.outputData?.outputFilePublicLink;
      } else if (jobDetails.status === "SYSTEM_FAILED" || jobDetails.status === "USER_FAILED") {
        status = FILE_DOWNLOAD_STATUS_ENUM.FAILED.key;
      } else if (jobDetails.status === "INITIATED") {
        status = FILE_DOWNLOAD_STATUS_ENUM.INITIATED.key;
      }
    }

    const statusStep = FILE_DOWNLOAD_STATUS_ENUM[status]?.step;
    const iconClass =
      status === FILE_DOWNLOAD_STATUS_ENUM.FAILED.key ? "icon-close" : "icon-check1";
    const dom = (
      <div className="file-status-details-wrapper">
        <div className="left-section">
          <div className="firstblock col-md-4">
            <span className="icon icon-file-text-o" />
            <span className="file-name ellipsis-text">{fileName}</span>
          </div>
          <div className="status-details col-md-5">
            <div className={`step-status ${statusStep > 1 ? "SUCCESS" : "PENDING"}`}>
              <span className={`icon ${statusStep > 1 ? "icon-check1" : ""}`} />
              <span className="label">
                <Trans>Process Initiated</Trans>
              </span>
            </div>
            <ProgressBar variant="success" now={statusStep > 1 ? 100 : 0} />
            <div
              className={`step-status ${
                statusStep > 2 ? status : statusStep != 1 ? "PENDING" : ""
              }`}
            >
              <span className={`icon ${statusStep > 2 ? iconClass : ""}`} />
              <span className="label">
                <Trans>Preparing File</Trans>
              </span>
            </div>
            <ProgressBar
              variant={status === FILE_DOWNLOAD_STATUS_ENUM.FAILED.key ? "danger" : "success"}
              now={statusStep > 2 ? 100 : 0}
            />
            <div className={`step-status ${statusStep > 2 ? status : ""}`}>
              <span className={`icon ${statusStep > 2 ? iconClass : ""}`} />
              <span className="label">
                <Trans>
                  {status === FILE_DOWNLOAD_STATUS_ENUM.FAILED.key
                    ? "Download Failed"
                    : "Ready To Download"}
                </Trans>
              </span>
            </div>
          </div>
        </div>
        <div className="right-section">
          <Button className="pendo-track-bulk-download-button" disabled={!fileUrl} onClick={() => onDownloadFile(fileUrl, fileName)}>
            Download
          </Button>
          <span
            className="dismiss-icon icon icon-close1"
            onClick={() => onDismissFileDownload(index, { type, ...rest })}
          />
        </div>
      </div>
    );

    return {
      statusDOM: dom,
      isReadyToDownload,
    };
  };

  const getDownloadStatusDOM = () => {
    const fileStatusDOMs = [];
    let readyToDownloadCount = 0;
    fileDownloadStatus.forEach((fileDetails, index) => {
      const { statusDOM, isReadyToDownload } = getFileStatusDetails(fileDetails, index);
      if (isReadyToDownload) {
        readyToDownloadCount++;
      }
      fileStatusDOMs.push(statusDOM);
    });
    return {
      fileStatusDOMs,
      readyToDownloadCount,
    };
  };

  const { fileStatusDOMs, readyToDownloadCount } = getDownloadStatusDOM();

  useEffect(() => {
    if (fileDownloadStatus.length > (prevFileDownloadStatus || []).length) {
      setShowTooltip(true);
    }
  }, [fileDownloadStatus, prevFileDownloadStatus]);

  const renderTooltip = () => {
    return (
      <Popover
        className="colored-tooltip os-tooltip-popover download-bar-z-index"
        id="popover-tour"
        delay={{ show: 100, hide: 400 }}
      >
        <div
          onClick={() => {
            setShowTooltip(false);
          }}
        >
          <span className="icon icon-close" />
        </div>
        {t(triggerTooltip)}
      </Popover>
    );
  };

  if (!fileStatusDOMs.length) {
    return null;
  }

  return (
    <OverlayTrigger show={showTooltip} overlay={renderTooltip()}>
      <div className="file-download-status-bar" ref={targetRef}>
        {fileStatusDOMs.length === 1 ? (
          fileStatusDOMs[0]
        ) : (
          <Accordion activeKey={isExpanded ? "0" : ""}>
            <Accordion.Collapse eventKey="0">
              <>{fileStatusDOMs}</>
            </Accordion.Collapse>
            <div className="overall-status-container" onClick={() => setIsExpanded(!isExpanded)}>
              <div className="left-section">
                <span className="icon icon-clone" />
                <span className="status-summary">
                  <Trans>
                    <span className="status-name">
                      {{ readyToDownloadCount }}/{{ total: fileStatusDOMs.length }}
                    </span>{" "}
                    files are ready to download
                  </Trans>
                </span>
              </div>
              <div className="right-section">
                <Accordion.Toggle className="toggle-container" as="div" eventKey="0">
                  <span
                    className={`toggle-icon icon icon-${
                      isExpanded ? "arrow-1-left" : "arrow-1-up"
                    }`}
                  />
                </Accordion.Toggle>
              </div>
            </div>
          </Accordion>
        )}
      </div>
    </OverlayTrigger>
  );
};

DownloaderBar.defaultProps = {
  isClientLevel: false,
  triggerTooltip: "Your file(s) is being downloaded. Please check the status here.",
};

const mapStateToProps = (state) => {
  let {
    selectedEntityId,
    selectedEntityType,
    userInfo,
    clientId,
    clients,
    fileDownloadStatus = [],
    agencyId,
  } = state.Application;

  if (!selectedEntityId && selectedEntityType === "CLIENT") {
    selectedEntityId = clientId;
  }

  if (!selectedEntityId && selectedEntityType === "AGENCY") {
    selectedEntityId = agencyId;
  }

  return {
    userInfo,
    clientId,
    clients,
    agencyId,
    selectedEntityId,
    selectedEntityType,
    fileDownloadStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      dismissFileDownload: dismissFileDownloadAction,
      updateFileDownloadStatus: updateFileDownloadStatusAction,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DownloaderBar));
