import { setLSItem, getLSItem, removeLSItem } from "./utils";
import AppStore from "./appStore";

const getDomain = () => {
  const splitedList = window.location.hostname.split(".");

  return splitedList.slice(-2).join(".");
};

const COOKIE_OPTIONS = {
  DOMAIN: getDomain(),
  CURRENT_DOMAIN: window.location.hostname,
  PATH: "/",
};

export const UBID_VALUE = "unique12345";

const getParsedCookie = (cookieValue) => {
  let parsedCookie = "";
  try {
    parsedCookie = JSON.parse(cookieValue);
  } catch (error) {}
  return parsedCookie;
};

export const getCookie = (cname, isJSONParse = true) => {
  let retnVal = "";
  var name = cname + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      let cookieValue = decodeURIComponent(c.substring(name.length, c.length));
      retnVal = (cookieValue && isJSONParse) ? getParsedCookie(cookieValue) : cookieValue;
      break;
    }
  }

  if (!retnVal) {
    retnVal = getLSItem(cname) || "";
    try {
      retnVal = decodeURIComponent(retnVal);
      if (isJSONParse) {
        retnVal = JSON.parse(retnVal);
      }
    } catch (e) {
      retnVal = "";
    }
  }

  if (!retnVal) {
    const storeState = AppStore.getState();
    if (cname === "UA_TOKEN") {
      if (storeState?.Application?.UA_TOKEN) {
        retnVal = storeState.Application.UA_TOKEN;
      }
    } else if (cname === "shop") {
      if (storeState?.Application?.shopInfo) {
        retnVal = storeState.Application.shopInfo;
      }
    }
  }

  return retnVal;
};

export const setCookie = (cname, cvalue, exdays, shouldSetInLS = true, topDomain = true) => {
  if (typeof cvalue !== "string") {
    cvalue = JSON.stringify(cvalue);
  }
  cvalue = encodeURIComponent(cvalue);

  let d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  let domain = topDomain ? COOKIE_OPTIONS.DOMAIN : COOKIE_OPTIONS.CURRENT_DOMAIN;

  document.cookie =
    cname +
    "=" +
    cvalue +
    "; " +
    expires +
    "; domain=" +
    domain +
    "; SameSite=None; path=/;" +
    "secure";

  if (shouldSetInLS) {
    setLSItem(cname, cvalue);
  }
};

export const deleteCookie = (name) => {
  const pathBits = window.location.pathname.split("/");
  let pathCurrent = " path=";
  const domain = COOKIE_OPTIONS.DOMAIN;
  const currentDomain = COOKIE_OPTIONS.CURRENT_DOMAIN;

  document.cookie = name + "=; expires=Thu, 01-Jan-1970 00:00:01 GMT;";
  for (let i = 0; i < pathBits.length; i++) {
    pathCurrent += (pathCurrent.substr(-1) != "/" ? "/" : "") + pathBits[i];
    document.cookie = name + "=; expires=Thu, 01-Jan-1970 00:00:01 GMT;" + pathCurrent + ";domain=" + domain + ";SameSite=None; Secure";
    document.cookie = name + "=; expires=Thu, 01-Jan-1970 00:00:01 GMT;" + pathCurrent + ";domain=" + currentDomain + ";SameSite=None; Secure";
  }
  removeLSItem(name);
};

export const checkToken = () => {
  return !!getCookie("UA_TOKEN", false);
};

export const eraseCookieFromAllPaths = (name) => {
  // This function will attempt to remove a cookie from all paths.
  let pathCurrent = " path=" + COOKIE_OPTIONS.PATH;
  const domain = " domain=" + COOKIE_OPTIONS.DOMAIN;
  const currentDomain = " domain=" + COOKIE_OPTIONS.CURRENT_DOMAIN;

  // do a simple pathless delete first.
  document.cookie = name + "=; expires=Thu, 01-Jan-70 00:00:01 GMT;";
  document.cookie = name + "=; " + domain + "; expires=Thu, 01-Jan-70 00:00:01 GMT;" + pathCurrent + ";SameSite=None; Secure";
  document.cookie = name + "=; " + currentDomain + "; expires=Thu, 01-Jan-70 00:00:01 GMT;" + pathCurrent + ";SameSite=None; Secure";
  deleteCookie(name);
};
