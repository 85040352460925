import React, { useState } from "react";

import { ModalWindow } from "@onlinesales-ai/modal-window-v2";

import PaymentModal from "./paymentModal";

const PaymentModalWrapper = ({
  containerClass,
  children,
  beforePayment,
  onSubsciption,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = async () => {
    try {
      await beforePayment();
    } catch (err) {
      return null;
    }

    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const onSubsciptionDone = () => {
    closeModal();
    onSubsciption();
  };

  return (
    <>
      <ModalWindow
        backdrop="static"
        isShow={isOpen}
        onModalDestroy={closeModal}
        containerClass={containerClass}
      >
        <ModalWindow.Body>
          <PaymentModal {...props} onSubsciption={onSubsciptionDone} closePayment={closeModal} />
        </ModalWindow.Body>
      </ModalWindow>
      {children({ openPayment: openModal })}
    </>
  );
};

export default PaymentModalWrapper;
