import BaseClient from "../baseClient";

const servicePrefix = "/multiVerseService/";

class MultiVerseService extends BaseClient {
  static getErrorMessages() {
    return {
      default: "Looks like we are facing some internal issue. Please, retry after sometime.",
      UNAUTHORIZED:
        "Sorry, you do not have the necessary permissions to perform this action. Please contact your admin for assistance.",
      AD0000:
        "Sorry, you do not have the necessary permissions to perform this action. Please contact your admin for assistance.",
      INVALID_PARAMETERS_ERROR:
        "It seems some input parameters are not valid. Please update your inputs and try again.",
      ACCESS_DENIED:
        "Sorry, you do not have the necessary permissions to perform this action. Please contact your admin for assistance.",
    };
  }

  static createEntityClient(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}createEntity/client`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      { ...options },
    );
  }

  static createEntityAdmin(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}createEntity/admin`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      { ...options },
    );
  }

  static fetchEntityClient(config = {}, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}fetchEntity/client`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(config),
        },
      },
      application,
      { ...options },
    );
  }

  static fetchEntityAdmin(config, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}fetchEntity/admin`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(config || {}),
        },
      },
      application,
      { ...options },
    );
  }

  static postUserSettingMappings(payload, application, options) {
    return this.apiCall(
      {
        url: `/api/user-setting-mappings/createUpdate`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      { ...options, isUseOrigin: true },
    );
  }
}

export default MultiVerseService;
