import { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { routes } from "src/utilities/constants";
import { checkToken } from "@onlinesales-ai/util-methods-v2";

import { fetchUser, fetchClients } from "@onlinesales-ai/app-v2/application";
import { redirectUrl } from "src/store/application";
import PlatformEventManager from "@onlinesales-ai/event-manager-v2";
import { fetchEntitiesData as fetchEntitiesDataAction } from "@onlinesales-ai/client-v2";
import { fetchUserSettingMappings as fetchUserSettingMappingsAction } from "@onlinesales-ai/app-v2/setting";

const INFRA_ADMIN_ID = 4661;

const AppAndAuthData = ({
  fetchUserData,
  fetchEntitiesData,
  fetchClientsData,
  redirectUrlTo,
  userInfo,
  location,
  domainConfig,
  clientId,
  fetchUserSettingMappings,
}) => {
  const isCMSDataFetchedRef = useRef(false);
  const fetchInitData = () => {
    const isAuthenticated = checkToken();

    if (isAuthenticated) {
      if (domainConfig?.enableEntityHades) {
        fetchEntitiesData(null, { routes, redirectUrl });
      } else {
        fetchClientsData(routes, redirectUrl);
      }
      fetchUserData(routes, redirectUrl);
    } else {
      redirectUrlTo(routes.LOGIN.path);
    }
  };

  useEffect(() => {
    fetchInitData();
  }, []);

  useEffect(() => {
    const isAuthenticated = checkToken();

    if (!isAuthenticated) {
      redirectUrlTo(routes.LOGIN.path);
    }
  }, [location.pathname]);

  useEffect(() => {
    const { id } = userInfo || {};
    if (id && id === INFRA_ADMIN_ID) {
      PlatformEventManager.emit("INVALID_UA_TOKEN");
    }
  }, [userInfo]);

  useEffect(() => {
    if (userInfo && clientId && !isCMSDataFetchedRef?.current) {
      fetchUserSettingMappings("MONETIZE_SELLER");
      isCMSDataFetchedRef.current = true;
    }
  }, [userInfo, fetchUserSettingMappings, clientId]);

  return null;
};

const mapStateToProps = (state) => {
  const { userInfo, clientId } = state.Application;

  return {
    domainConfig: state.DomainConfig,
    userInfo,
    location: state.router?.location || {},
    clientId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchUserData: fetchUser,
      fetchClientsData: fetchClients,
      redirectUrlTo: redirectUrl,
      fetchEntitiesData: fetchEntitiesDataAction,
      fetchUserSettingMappings: fetchUserSettingMappingsAction,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AppAndAuthData);
