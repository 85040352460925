import React from "react";
import { Trans } from "react-i18next";

import { Button } from "@onlinesales-ai/button-v2";
import { FetchBanner } from "@onlinesales-ai/message-card-v2";
import { getCurrencyDOM } from "@onlinesales-ai/util-methods-v2";

import "./index.less";

const ThanaChartPaymentModal = ({
  closePayment,
  openCardUpdateModal,
  subscriptionData,
  onClickPayment,
  subscriptionIsLoading,
  subscriptionError,
  subscriptionPostIsLoading,
  isLoadingCardData,
  couponCodeDetails,
  amount,
}) => {
  const { cardData } = subscriptionData || {};
  const { last4: cardNumber } = cardData || {};
  const { value: couponValue } = couponCodeDetails || {};

  let chargedAmount = amount - (couponValue || 0);

  if (chargedAmount < 0) {
    chargedAmount = 0;
  }

  return (
    <FetchBanner
      errorTitle="Error while fetching required information"
      isLoading={subscriptionIsLoading}
      errorMsg={subscriptionError}
    >
      <div className="thanachart-payment-modal">
        <span className="icon icon-close1 close-button" onClick={closePayment} />
        <p className="title">
          <Trans>Top Up</Trans>
        </p>
        <div className="topup-information">
          <div className="topup-info-line">
            <span>
              <Trans>Topup amount</Trans>
            </span>
            <span>
              {getCurrencyDOM()}
              {amount}
            </span>
          </div>
          {couponValue ? (
            <div className="topup-info-line coupon">
              <span>
                <Trans>Coupon Code Applied</Trans>
              </span>
              <span>
                -{getCurrencyDOM()}
                {couponValue}
              </span>
            </div>
          ) : null}
          <hr className="topup-line" />
          <div className="topup-info-line sum">
            <span>
              <Trans>Amount charged</Trans>
            </span>
            <span>
              {getCurrencyDOM()}
              {chargedAmount}
            </span>
          </div>
        </div>
        {
          isLoadingCardData ? (
            <div className="animated-bg card-loading" />
          ) : null
        }
        {!isLoadingCardData && cardNumber ? (
          <div className="credit-card-info">
            <div className="credit-card-info-text"><Trans>You will be charged on this credit card.</Trans></div>
            <div className="credit-card-info-block">
              <span>
                <Trans>Credit Card</Trans>:
              </span>
              <span>{`XXXX - XXXX - XXXX - ${cardNumber}`}</span>
            </div>
          </div>
        ) : null}
        <div className="topup-info-footer">
          <Button
            isLoading={subscriptionPostIsLoading}
            disabled={subscriptionPostIsLoading || isLoadingCardData}
            onClick={onClickPayment}
          >
            Pay
          </Button>
          {cardNumber ? (
            <span className="change-card" onClick={openCardUpdateModal}>
              <Trans>Change credit card</Trans>
            </span>
          ) : null}
        </div>
      </div>
    </FetchBanner>
  );
};

export default ThanaChartPaymentModal;
