import { vendorChannelMapping } from "@onlinesales-ai/constants-v2";
import { isChannelLinked } from "@onlinesales-ai/util-methods-v2";
import Types from "./types";

const initialState = {
  vendorAccountData: {},
  vendorAccountIsLoading: true,
  vendorAccountFetchError: false,
  vendorAccountPostLoading: false,
};

const updateChannelLinkStatus = (vendorAccountsData) => {
  const channelLinkStatus = {};
  Object.keys(vendorChannelMapping).forEach((channelKey) => {
    channelLinkStatus[channelKey] = { isLinked: isChannelLinked(channelKey, vendorAccountsData) };
  });

  return channelLinkStatus;
};

const getFBActivePageName = (vendorList = {}) => {
  let FBactivePageName = null;
  if (vendorList && vendorList["com.sokrati.clientInputSvcObjects.parameter.FacebookPage"]) {
    const pages = vendorList["com.sokrati.clientInputSvcObjects.parameter.FacebookPage"];
    for (let i = 0; i < pages.length; i++) {
      if (pages[i].isActive && pages[i].pageName) {
        FBactivePageName = pages[i].pageName;
        break;
      }
    }
  }
  return FBactivePageName;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ACTIVE_CLIENT_ID":
    case "APP_CLIENT_CHANGE":
      {
        state = {
          ...initialState,
        };
      }
      break;
    case Types.SET_VENDOR_ACCOUNT_DATA:
      {
        state = {
          ...state,
          fbActivePageName: getFBActivePageName(action.data),
          vendorAccountData: action.data,
          channelLinkStatus: updateChannelLinkStatus(action.data),
        };
      }
      break;
    case Types.UPDATE_VENDOR_ACCOUNT_DATA:
      {
        state = {
          ...state,
          fbActivePageName: getFBActivePageName({
            ...state.vendorAccountData,
            ...action.data,
          }),
          vendorAccountData: {
            ...state.vendorAccountData,
            ...action.data,
          },
          channelLinkStatus: updateChannelLinkStatus({
            ...state.vendorAccountData,
            ...action.data,
          }),
        };
      }
      break;
    case Types.SET_VENDOR_ACCOUNT_FETCH_STATE:
      {
        state = {
          ...state,
          vendorAccountIsLoading: action.isLoading,
          vendorAccountFetchError: action.errorMsg,
        };
      }
      break;
    case Types.SET_VENDOR_ACCOUNT_POST_STATE:
      {
        state = {
          ...state,
          vendorAccountPostLoading: action.isLoading,
        };
      }
      break;
    default:
      break;
  }

  return state;
};

export default {
  AccountLinking: reducer,
};
