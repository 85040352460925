import React from "react";
import { useTranslation } from "react-i18next";
import "./textarea.less";

const Textarea = React.forwardRef(
  (
    { className, textareaWrapperClassName, suffix, placeholder, prefix, characterLimit, ...props },
    ref,
  ) => {
    const { t } = useTranslation();
    return (
      <div className={`textarea-wrapper ${textareaWrapperClassName} ${props?.disabled ? "disabled" : ""}`}>
        {prefix && <span className="prefix-metric">{prefix}</span>}
        <textarea
          ref={ref}
          className={`form-control ${className} ${characterLimit && "with-character-limit"}`}
          placeholder={t(placeholder)}
          {...props}
        />
        {!suffix && characterLimit && (
          <span className="character-suffix-textarea">{characterLimit}</span>
        )}
        {suffix && <span className="suffix-metric">{suffix}</span>}
      </div>
    );
  },
);

Textarea.defaultProps = {
  className: "",
  textareaWrapperClassName: "",
};

export default Textarea;
