/* eslint-disable camelcase */
import React, { useContext, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";

import { Button } from "@onlinesales-ai/button-v2";
import { ModalWindow } from "@onlinesales-ai/modal-window-v2";
import { Trans, useTranslation } from "react-i18next";
import { Checkbox } from "@onlinesales-ai/checkbox-v2";
import CollapsibleCard from "@onlinesales-ai/collapsible-card-v2";
import { formatValuesInThousands, formattedNumber, getCurrencyCode, getCurrencyDOM } from "@onlinesales-ai/util-methods-v2";
import { Label } from "@onlinesales-ai/label-v2";
import { OSBillingServiceV2 } from "@onlinesales-ai/services-v2";
import { GlobalContext, LabelValuePair } from "@onlinesales-ai/utils-components-v2";
import { loaderWhite } from "@onlinesales-ai/loader-v2";
import PlatformEventManager from "@onlinesales-ai/event-manager-v2";

import "./index.less";

const SnapdealCOD = ({
  isLoading,
  disabled,
  ctaText,
  beforePayment,
  clientId,
  agencyId,
  shopInfo,
  amount,
  openPayment,
  fireEventOnPaymentSuccess,
  chargeExtraPayload,
  codPaymentMode,
  renderCta,
  checkoutModalProps,
  ...props
}) => {
  const { showToastMessage } = useContext(GlobalContext);
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [showPartialPaymentModal, setShowPartialPaymentModal] = useState(false);
  const [isFetchLoading, setIsFetchLoading] = useState(true);
  const [isPaymentInLoading, setIsPaymentInLoading] = useState(false);
  const [codConfig, setCodConfig] = useState({
    isDisabled: true,
    disabledMsg: "",
    isChecked: false,
    amount: 0,
    amountWithTax: 0,
    taxPercentage: 18,
  });

  useEffect(() => {
    if (!showModal) {
      // reset states
      setCodConfig({
        ...codConfig,
        isDisabled: true,
        disabledMsg: "",
        isChecked: false,
        amountWithTax: 0,
        amount: 0,
      });
    }
  }, [showModal, amount]);

  const gstTaxAmount = useMemo(() => {
    return amount * (codConfig.taxPercentage / 100);
  }, [codConfig, amount]);

  const paymentSummary = useMemo(() => {
    let payableAmountWithTax = amount + gstTaxAmount;
    let payableAmount = amount;
    const codAdjustable = Math.min(codConfig.amount, amount) || 0;
    const codAdjustableWithTax = Math.min(codConfig.amountWithTax, amount + gstTaxAmount) || 0;
    if (codConfig.isChecked) {
      payableAmountWithTax -= codAdjustableWithTax;
      payableAmount -= codAdjustable;
    }
    return {
      payableAmountWithTax,
      payableAmount,
      codAdjustableWithTax,
      codAdjustable,
    };
  }, [codConfig, amount, gstTaxAmount]);

  const getPhoneNumber = (userInfo) => {
    const { countryDailingCode, marketplaceStoreDetails = {} } = props;
    let phone = userInfo.contact;

    // If no contact number is present add verfied/unverified contact number from authMetadata
    if (!phone && userInfo.authMetadataList) {
      userInfo.authMetadataList.forEach((authInfo = {}) => {
        if (authInfo.type === "MOBILE") {
          phone = authInfo.value;
        }
      });
    }

    if (!phone && marketplaceStoreDetails.mobile) {
      phone = marketplaceStoreDetails.mobile;
    }

    // Removing dialing code as gateway not accepting it.
    if (phone && phone.includes(countryDailingCode)) {
      phone = phone.substr(countryDailingCode.length);
    }

    return phone;
  };

  const onClickPayment = async () => {
    try {
      await beforePayment();
      setShowModal(true);
      setIsFetchLoading(true);
      const response = await OSBillingServiceV2.fetchSpandealCODBalance({
        clientId,
        agencyId,
        currency: shopInfo.currencyCode,
        source: codPaymentMode,
      });
      // if (response.amount) {
      setCodConfig({
        ...codConfig,
        isDisabled: false,
        isChecked: response.amount > 0,
        amount: response.amount,
        amountWithTax: response.amountWithTax,
        taxPercentage: response.sourceDetails?.taxPercentage || 0,
      });
      // }
      setIsFetchLoading(false);
    } catch (e) {
      setIsFetchLoading(false);
      setCodConfig({
        ...codConfig,
        amount: 0,
        amountWithTax: 0,
        disabledMsg: "This option is not available at the moment. Please try again later.",
        isChecked: false,
        isDisabled: true,
      });
      showToastMessage({
        type: "ERROR",
        messageToDisplay: e?.errorMsg || "Error while fetching COD Balance.",
        toastDuration: 5000,
      });
    }
  };

  const refreshBalance = () => {
    if (fireEventOnPaymentSuccess && fireEventOnPaymentSuccess.length) {
      fireEventOnPaymentSuccess.forEach((event) => {
        PlatformEventManager.emit(event);
      });
    }
  };

  const onClickProceed = async () => {
    const { userInfo } = props;
    let isCODPaymentDone = false;
    setIsPaymentInLoading(true);
    if (codConfig.isChecked && paymentSummary.codAdjustable > 0) {
      try {
        const phone = getPhoneNumber(userInfo);
        const userEmail = userInfo.email;
        const customer_details = {
          first_name: userInfo?.name || "",
          last_name: "",
          email: userEmail,
          phone,
        };
        const response = await OSBillingServiceV2.charge({
          paymentMode: codPaymentMode,
          platform: "OS",
          clientId,
          email: userEmail,
          amount: paymentSummary.codAdjustable,
          currency: getCurrencyCode(),
          metadata: {
            customer_details,
          },
          ...(chargeExtraPayload || {}),
        });
        if (response.status === "ACCEPTED") {
          isCODPaymentDone = true;
        } else {
          setIsPaymentInLoading(false);
          setCodConfig({
            ...codConfig,
            amount: 0,
            amountWithTax: 0,
            disabledMsg: "This option is not available at the moment. Please try again later.",
            isChecked: false,
            isDisabled: true,
          });
          showToastMessage({
            type: "ERROR",
            messageToDisplay:
              "Something went wrong while doing COD payment. Please try again later.",
            toastDuration: 5000,
          });
        }
      } catch (e) {
        setIsPaymentInLoading(false);
        setCodConfig({
          ...codConfig,
          amount: 0,
          amountWithTax: 0,
          disabledMsg: "This option is not available at the moment. Please try again later.",
          isChecked: false,
          isDisabled: true,
        });
        showToastMessage({
          type: "ERROR",
          messageToDisplay: "Something went wrong while doing COD payment. Please try again later.",
          toastDuration: 5000,
        });
      }
    }
    if (isCODPaymentDone || !codConfig.isChecked) {
      if (paymentSummary.payableAmount > 0) {
        // razorpay payment
        try {
          await openPayment(
            {},
            {
              overrides: {
                amount: `${paymentSummary.payableAmount}`,
              },
              onDismissCallback: () => {
                if (isCODPaymentDone) {
                  setShowModal(false);
                  setShowPartialPaymentModal(true);
                  refreshBalance();
                }
              },
              onPaymentSuccess: () => {
                setShowModal(false);
              },
            },
          );
        } catch (e) {
          setIsPaymentInLoading(false);
          if (isCODPaymentDone) {
            refreshBalance();
            setShowPartialPaymentModal(true);
          }
          showToastMessage({
            type: "ERROR",
            messageToDisplay: "Something went wrong while doing payment. Please try again later.",
            toastDuration: 5000,
          });
        }
      } else {
        setShowModal(false);
        showToastMessage({
          type: "SUCCESS",
          messageToDisplay:
            "Your payment was successful. Your ad wallet will be credited in the next 15 mins.",
          toastDuration: 5000,
        });
      }
    }

    refreshBalance();
    setIsPaymentInLoading(false);
  };

  const renderModalWindow = () => {
    const paymentSummaryList = [
      {
        label: "Top-up Amount",
        value: (
          <>
            {getCurrencyDOM()}
            {formattedNumber(amount, {
              ...checkoutModalProps,
              overrides: { precision: checkoutModalProps?.precision },
            })}
          </>
        ),
      },
      {
        label: t("GST ({{taxPercentage}}%)", {
          taxPercentage: codConfig.taxPercentage,
        }),
        value: (
          <>
            {formattedNumber(gstTaxAmount, {
              ...checkoutModalProps,
              overrides: { precision: checkoutModalProps?.precision },
            })}
          </>
        ),
      },
    ];
    if (codConfig.isChecked) {
      paymentSummaryList.push({
        label: t("COD Balance", {
          taxPercentage: codConfig.taxPercentage,
        }),
        value: (
          <div className="d-align-center gap-3">
            <span>-</span>
            {formattedNumber(paymentSummary.codAdjustableWithTax, {
              ...checkoutModalProps,
              overrides: { precision: checkoutModalProps?.precision },
            })}
          </div>
        ),
      });
    }
    return (
      <ModalWindow
        isShow={showModal}
        closeButton
        modalProps={{
          backdrop: "static",
        }}
        headerTitle={
          <>
            <Label
              labelDefinationKey="RAZOR_PAY_TOPUP_WINDOW_TITLE"
              translationConfig={{
                values: {
                  amount: formatValuesInThousands(amount, 2),
                },
              }}
            />
          </>
        }
        onModalDestroy={() => {
          setShowModal(false);
        }}
        // useBootstrapModal={true}
        containerClass="snapdeal-cod-option-modal"
      >
        {isFetchLoading ? (
          loaderWhite()
        ) : (
          <>
            <ModalWindow.Body>
              <div className="snapdeal-cod-option-body">
                <div className="option-row">
                  <div className="left">
                    <Checkbox
                      id="spandeal-cod"
                      disabled={codConfig.isDisabled}
                      label="Add from COD Balance"
                      key="spandeal-cod"
                      className="snapdeal-checkbox "
                      tooltip={codConfig?.disabledMsg}
                      checked={codConfig?.isChecked}
                      onChange={(val) => {
                        setCodConfig({
                          ...codConfig,
                          isChecked: val,
                        });
                      }}
                    />
                  </div>
                  <div className="right">
                    {formattedNumber(codConfig.amountWithTax, {
                      ...checkoutModalProps,
                      overrides: { precision: checkoutModalProps?.precision },
                    })}
                  </div>
                </div>
                {codConfig?.disabledMsg && (
                  <div className="error-msg">{t(codConfig?.disabledMsg)}</div>
                )}
                <div className="snapdeal-cod-tax-breakup-container">
                  <div className="payment-summary-title">
                    <Trans>Payment Summary</Trans>
                  </div>
                  <CollapsibleCard
                    containerClass="dashed-collapse-small"
                    isExpanded
                    contentPosition="TOP"
                    controlled
                    showCollapsibleIcon={false}
                    cardHeaderClass="cursor-default"
                    // customCollapsibleIcon="icon-angle-down"
                    cardHeader={
                      <div className="card-title">
                        <div>
                          <span className="mr-1">
                            <Trans>Payable Amount</Trans>
                          </span>
                          <span className="razorpay-text">
                            {t("(including {{taxPercentage}}% GST)", {
                              taxPercentage: codConfig.taxPercentage,
                            })}
                          </span>
                        </div>
                        <div className="right">
                          {formattedNumber(paymentSummary.payableAmountWithTax, {
                            ...checkoutModalProps,
                            overrides: { precision: checkoutModalProps?.precision },
                          })}
                        </div>
                      </div>
                    }
                  >
                    <LabelValuePair spaceBetween separator="" sameStyling list={paymentSummaryList} />
                  </CollapsibleCard>
                </div>
              </div>
            </ModalWindow.Body>
            <ModalWindow.Footer>
              <div className="center">
                <Button
                  isLoading={isLoading || isPaymentInLoading}
                  type="primary"
                  onClick={onClickProceed}
                  disabled={disabled || isPaymentInLoading}
                  className="proceed-button-cod"
                >
                  <Trans>Pay Securely</Trans>
                </Button>
              </div>
            </ModalWindow.Footer>
          </>
        )}
      </ModalWindow>
    );
  };

  const renderPartialPaymentModalWindow = () => {
    return (
      <ModalWindow
        isShow={showPartialPaymentModal}
        closeButton
        headerTitle="Payment"
        onModalDestroy={() => {
          setShowPartialPaymentModal(false);
        }}
        // useBootstrapModal={true}
        containerClass="snapdeal-cod-payment-status-modal"
      >
        <ModalWindow.Body>
          <div className="text-center snapdeal-payment-status-body">
            <div className="title"><Trans>Your Payment was partially successful</Trans></div>
            <div className="cod-balnce-msg">
              <span className="icon icon-check-circle-2 success-msg" />
              <Trans>Your COD Balance is successfully transferred to your wallet balance</Trans>
            </div>
            <div className="razor-page-msg">
              <span className="icon icon-warning-filled" />
              <Trans>Your Transaction form Payment Gateway failed due to some issues</Trans>
            </div>
          </div>
        </ModalWindow.Body>
      </ModalWindow>
    );
  };

  return (
    <>
      {renderModalWindow()}
      {renderPartialPaymentModalWindow()}
      {renderCta ? (
        renderCta({ onClickPayment })
      ) : (
        <Button className="pendo_feature_topup_button" onClick={onClickPayment}>
          {ctaText}
        </Button>
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { clientId, userInfo, email, shopInfo, agencyId } = state.Application || {};
  const { marketplaceStoreDetails = {} } = state.OnBoarding?.data || {};

  return {
    clientId: ownProps?.clientId || clientId,
    email,
    userInfo,
    shopInfo,
    agencyId,
    marketplaceStoreDetails,
  };
};

SnapdealCOD.defaultProps = {
  countryDailingCode: "+91",
  codPaymentMode: "SNAPDEAL_STAG_COD",
  checkoutModalProps: {
    precision: 2,
    showCurrency: true,
  },
};

export default connect(mapStateToProps)(SnapdealCOD);