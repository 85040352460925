import React, { useEffect, useMemo, useState } from "react";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import classnames from "classnames";

import { Button } from "@onlinesales-ai/button-v2";
import { OSHOCWithUtilities } from "@onlinesales-ai/os-hoc-with-utilities-v2";
import WithTooltip from "@onlinesales-ai/tooltip-v2";
import { Text } from "@onlinesales-ai/label-v2";

import { FormWrapper, RuleAction } from "../../../index";

import "./index.less";

const DyanamicList = ({
  fallbackValue,
  actionsToShow,
  actionConfig,
  formGroupClassName,
  formatOptionLabel,
  isRenderedFallBackValue,
  ...props
}) => {
  const {
    isEditable,
    dataKey,
    formValues,
    addButtonText,
    onChange,
  } = props;

  const value = useMemo(() => {
    return _get(formValues, dataKey);
  }, [dataKey, formValues]);

  const customAddButton = ({ onClickAdd }) => {
    return (
      <>
        <Button
          className="add-button pendo_track_dynamic_list_custom_add_icon_button"
          type="default"
          shape="rounded"
          icon="icon-add-plus"
          iconWeight
          onClick={onClickAdd}
        />
        <Button
          link
          onClick={onClickAdd}
          className="tracker-btn pendo_track_dynamic_list_custom_add_button"
        >
          {addButtonText}
        </Button>
      </>
    );
  };

  const customLabelFunction = ({ nonEditValue }) => {
    return `${nonEditValue}:`;
  };

  if (isRenderedFallBackValue && !isEditable && _isEmpty(value)) {
    return <FormWrapper {...props}>{fallbackValue}</FormWrapper>;
  }

  return (
    <div
      className={classnames("form-component-day-parting-container", formGroupClassName, {
        "read-only": !isEditable,
      })}
    >
      <RuleAction
        customAddButton={customAddButton}
        allowSameActions
        enableUniversalDropdown
        actionConfig={actionConfig}
        actionsToShow={actionsToShow}
        {...props}
        actionDropdownProps={{
          customLabelFunction,
          formatOptionLabel,
          showfullBorder: true,
          isSearchable: false,
          enableVirtualScroll: false,
          menuPosition: "fixed",
          flipMenu: true,
          ...(props?.actionDropdownProps || {})
        }}
      />
    </div>
  );
};

DyanamicList.defaultProps = {
  fallbackValue: <span className="guide-text">Not Set</span>,
  actionsToShow: [],
  actionConfig: {},
  addButtonText: "Add Day Parting",
  isRenderedFallBackValue: true,
};

export default OSHOCWithUtilities(DyanamicList);
