import BaseClient from "../baseClient";

const servicePrefix = "/keywordTargetingService/";

class KeywordTargetingService extends BaseClient {
  static getErrorMessages() {
    return {
      default: "Looks like we are facing some internal issue. Please, retry after sometime.",
      IP0000:
        "It seems some input parameters are not valid. Please update your inputs and try again.",
      AD0000:
        "Oops. It seems you are not authorized to take this action. Please log in with appropriate credentials.",
      I0004:
        "Oops. Seems the system is acting up - sorry for the inconvenience. Please try again in some time. (Please be assured our tech geniuses have been notified of this glitch).",
      CIS_FETCH_ENTITY_ERROR:
        "Looks like we are facing some internal issue. Please, retry after sometime.",
      RECORD_DUPLICATION_ERROR:
        "One or more Keywords you are adding is already present in your campaign.",
      KEYWORD_LIMIT_EXCEEDED_ERROR: "Keyword limits for your campaign has been exceeded.",
      TRANSACTION_FAILED_ERROR:
        "Looks like we are facing some internal issue. Please, retry after sometime.",
      INVALID_PARAMETER_ERROR: "It seems some input parameters are not valid. Please update your inputs and try again.",
    };
  }

  static fetchPositiveKeywords = (payload, application, options) => {
    return this.apiCall(
      {
        url: `${options?.servicePrefix || ""}${servicePrefix}fetchPositiveKeywords`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(payload),
        },
      },
      application,
      options,
    );
  };

  static fetchKeywordSuggestionsCategoryList = (payload, application, options) => {
    return this.apiCall(
      {
        url: `${options?.servicePrefix || ""}${servicePrefix}keywordSuggestion/fetchCategoryList`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(payload),
        },
      },
      application,
      options,
    );
  };

  static fetchKeywordSuggestionsClient = (payload, application, options) => {
    return this.apiCall(
      {
        url: `${options?.servicePrefix || ""}${servicePrefix}keywordSuggestion/fetch/client`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  };

  static fetchKeywordSuggestionsAdmin = (payload, application, options) => {
    return this.apiCall(
      {
        url: `${options?.servicePrefix || ""}${servicePrefix}keywordSuggestion/fetch/admin`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  };

  static keywordSuggestionDownloadClient = (payload, application, options) => {
    return this.apiCall(
      {
        url: `${options?.servicePrefix || ""}${servicePrefix}keywordSuggestion/download/client`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  };

  static keywordSuggestionDownloadAdmin = (payload, application, options) => {
    return this.apiCall(
      {
        url: `${options?.servicePrefix || ""}${servicePrefix}keywordSuggestion/download/admin`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  };

  static postBiddingKeywords = (payload, application, options) => {
    return this.apiCall(
      {
        url: `${options?.servicePrefix || ""}${servicePrefix}saveCampaignKeywords`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  };

  static fetchNegativeKeywords = (payload, application, options) => {
    return this.apiCall(
      {
        url: `${options?.servicePrefix || ""}${servicePrefix}fetchNegativeKeywords`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(payload),
        },
      },
      application,
      options,
    );
  };
}

export default KeywordTargetingService;
