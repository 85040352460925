import { format as dateFormat, addMilliseconds, startOfMonth, subDays, isWithinInterval } from "date-fns/esm";
import {
  zonedTimeToUtc,
  format as dateFormatTZ,
  utcToZonedTime,
  getTimezoneOffset,
} from "date-fns-tz";

import _groupBy from "lodash/groupBy";
import _sortBy from "lodash/sortBy";
import { getCurrentLanguage } from "@onlinesales-ai/i18n-v2";

import AppStore from "./appStore";
import { getAgencySettings } from "./utils";

let currentLocale = null;

export const millisecondsToTimezoneOffset = (milliseconds) => {
  const hours = Math.floor(milliseconds / 3600000); // Convert milliseconds to hours
  const minutes = Math.floor((milliseconds % 3600000) / 60000); // Convert remaining milliseconds to minutes
  const sign = hours >= 0 ? "+" : "-"; // Determine the sign
  const absHours = Math.abs(hours);
  const absMinutes = Math.abs(minutes);
  const formattedHours = absHours < 10 ? "0" + absHours : absHours; // Add leading zero if needed
  const formattedMinutes = absMinutes < 10 ? "0" + absMinutes : absMinutes; // Add leading zero if needed

  return `${sign}${formattedHours}:${formattedMinutes}`;
};

export const getClientTimezone = () => {
  const storeState = AppStore.getState();
  const { Application } = storeState || {};

  const timezone = getAgencySettings("timezone") || Application?.shopInfo?.timezone;

  return timezone;
};

export const getClientTimezoneText = ({ showTimezone = true } = {}) => {
  const timezone = getClientTimezone();

  const offset = getTimezoneOffset(timezone);
  return showTimezone ? `${timezone} (${millisecondsToTimezoneOffset(offset)})` : `(${millisecondsToTimezoneOffset(offset)})`;
};

export const getClientTimezoneOffset = () => {
  const timezone = getClientTimezone();

  return getTimezoneOffset(timezone);
};

export const isSafari = () => {
  if (typeof navigator === "undefined") {
    return false;
  }

  const userAgent = navigator.userAgent || "";
  const isSafariBrowser = navigator.vendor && navigator.vendor.indexOf("Apple") !== -1;
  const isSafariMobile = isSafariBrowser && userAgent.indexOf("Mobile/") !== -1;

  return isSafariBrowser || isSafariMobile;
};

const isSafariBrowser = isSafari();

export const setCurrentLocale = (locale) => {
  currentLocale = locale;
};

export const format = (date, formatStr = "yyyy-MM-dd", locale) => {
  return dateFormat(date, formatStr, {
    locale: typeof locale === "undefined" ? currentLocale : locale,
  });
};

export const formatWithTZ = (date, formatStr = "yyyy-MM-dd HH:mm:ss XXX", convetToZone = true) => {
  let dateToFormat = date;

  try {
    if (convetToZone) {
      dateToFormat = getUTCToZonedDate(dateToFormat);
    }
  } catch (err) {}

  return dateFormatTZ(dateToFormat, formatStr, {
    locale: currentLocale,
    timeZone: getClientTimezone(),
  });
};

export const reportingDateFormat = (dateStr = "") => {
  try {
    const dateTimeParts = dateStr.split(".")[0].split(/[- :]/);
    dateTimeParts[1]--;
    return new Date(...dateTimeParts);
  } catch (e) {
    return new Date(dateStr);
  }
};

export const isValidDate = (d) => {
  return d instanceof Date && !isNaN(d);
};

export const getUTCDateTime = (date) => {
  const timezoneOffsetInms = new Date().getTimezoneOffset() * 60 * 1000;

  return addMilliseconds(date, timezoneOffsetInms);
};

export const formatUTCDate = (d, str) => {
  const date = new Date(d);

  return format(new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()), str);
};

export const formatMultipleDates = (data) => {
  const groupedItems = _groupBy(data, (d) => format(startOfMonth(new Date(d.date)), "MMM ''yy"));

  return Object.keys(groupedItems).map((month) => {
    const dates = _sortBy(groupedItems[month], (d) => new Date(d.date).getDate()).map((d) => {
      return `${new Date(d.date).getDate()}${d.info || ""}`;
    });
    return `${dates.join(", ")} ${month}`;
  });
};

export const parseDateInLocalTimezone = (dateString) => {
  const hasTimePart = typeof dateString === "string" && dateString?.includes("T");

  if (hasTimePart) {
    [dateString] = dateString.split("T");
  }
  const doo = new Date(dateString);
  return new Date(doo.getTime() - doo.getTimezoneOffset() * -60000);
};

export const parseDateInUTCTimezone = (dateString) => {
  const doo = new Date(dateString);
  return new Date(doo.getTime() + doo.getTimezoneOffset() * -60000);
};

export const generateLastDaysDateData = (input, { dateKey = "date", lastDays = 7, valueKey = "value" }) => {
  const yesterday = subDays(new Date(), 1);
  const output = [];

  for (let i = 0; i < lastDays; i++) {
    const date = subDays(yesterday, i);
    const dateString = format(date, "yyyy-MM-dd");

    const existingData = input.find((item) => item[dateKey] === dateString);

    if (existingData) {
      output.unshift(existingData);
    } else {
      output.unshift({
        ...existingData,
        [dateKey]: dateString,
        [valueKey]: 0,
      });
    }
  }

  return output;
};

export const convertTimeRangeToAMPMFormat = (startTime, endTime) => {
  // Convert start time to 12-hour format
  let startHour = parseInt(startTime.split(":")[0]);
  let startAMPM = startHour >= 12 ? "PM" : "AM";
  if (startHour === 0) {
    startHour = 12;
  } else if (startHour > 12) {
    startHour -= 12;
  }
  let startTime12Hour = `${startHour.toString().padStart(2, "0")}:00 ${startAMPM}`;

  // Convert end time to 12-hour format
  let endHour = parseInt(endTime.split(":")[0]);
  let endAMPM = endHour >= 12 ? "PM" : "AM";
  if (endHour === 0) {
    endHour = 12;
  } else if (endHour > 12) {
    endHour -= 12;
  }
  let endTime12Hour = `${endHour.toString().padStart(2, "0")}:00 ${endAMPM}`;

  return `${startTime12Hour} - ${endTime12Hour}`;
};

// Timezone Handling
export const getZonedToUTCDate = (dateObj) => {
  return zonedTimeToUtc(dateObj, getClientTimezone());
};

export const getUTCToZonedDate = (dateObj) => {
  return utcToZonedTime(dateObj, getClientTimezone());
};

export const isDateRangeWithinRange = (range1Start, range1End, range2Start, range2End) => {
  return (
    isWithinInterval(range2Start, { start: range1Start, end: range1End }) &&
    isWithinInterval(range2End, { start: range1Start, end: range1End })
  );
};

export const getNewDate = (date) => {
  let dateObj = new Date();

  if (isSafariBrowser && typeof date === "string") {
    try {
      dateObj = new Date(date);
    } catch {
      dateObj = new Date(date.replace(/-/g, "/").replace(/T/g, " "));
    }
  } else if (date instanceof Date) {
    dateObj = date;
  } else if (date) {
    dateObj = new Date(date);
  }

  return dateObj;
};

// This function is convert the broswer time to client time with same date and time
// only change the timezone
// Ex:
// Broswer time is 4:30PM +05:30
// Return time is 4:30PM -08:30
export const convertBrowserToClientTimezoneWithSameDateTime = (date) => {
  // Get the current timezone offset
  const currentOffset = date.getTimezoneOffset() * -1;

  // Get the target timezone offset
  const targetOffset = getTimezoneOffset(getClientTimezone(), date) / 60 / 1000;

  // Calculate the offset difference
  const offsetDifference = currentOffset - targetOffset;

  // Adjust the time by the offset difference
  const targetTime = new Date(date.getTime() + offsetDifference * 60 * 1000);

  return targetTime;
};

export const convertClientTimezoneDateIntoUTCDate = (date) => {
  const targetOffset = getTimezoneOffset(getClientTimezone());

  const targetTime = getUTCToZonedDate(getNewDate(date).getTime() - targetOffset);

  return targetTime;
};

export const isTodayWithTz = (dateObj) => {
  return format(dateObj, "dd/MM/yyyy") === format(getUTCToZonedDate(new Date()), "dd/MM/yyyy");
};

const dateFormatConfig = {
  en: {
    api: "yyyy-MM-dd",
    date: "dd MMM yy",
    dateTime: "dd MMM yy, hh:mm a",
    dateTimeTz: "dd MMM yy, hh:mm a XXX",
    dateTimeSecondsTz: "dd MMM yy hh:mm:ss a XXX",
    time: "hh:mm aa",
    timeTz: "hh:mm aa XXX",
    month: "MMM yyyy",
    dateMonth: "dd MMM",
  },
  ko: {
    api: "yyyy-MM-dd",
    date: "yyyy년 MMM dd일",
    dateTime: "yyyy년 MMM dd일 hh:mm a",
    dateTimeTz: "yyyy년 MMM dd일 hh:mm a XXX",
    dateTimeSecondsTz: "yyyy년 MMM dd일 hh:mm:ss a XXX",
    time: "hh:mm aa",
    timeTz: "hh:mm aa XXX",
    month: "yyyy년 MMM",
    dateMonth: "MMM dd일",
  },
};

export const formattedDate = (date, formatType, { convetToZone, useFormat } = {}) => {
  const storeState = AppStore.getState();
  const { DomainConfig } = storeState || {};
  const currentLanguage = getCurrentLanguage();

  if (!date) {
    return date;
  }

  const dateObj = getNewDate(date);

  const formatString =
    DomainConfig?.dateFormatConfig?.[currentLanguage]?.[formatType] ||
    dateFormatConfig?.[currentLanguage]?.[formatType] ||
    dateFormatConfig.en.date;

  const formatterToUse = useFormat ? format : formatWithTZ;

  return formatterToUse(dateObj, formatString, convetToZone);
};
