import React, { useEffect, useState, useRef, useCallback } from "react";
import { connect } from "react-redux";
import _debounce from "lodash/debounce";
import _find from "lodash/find";
import { Trans, useTranslation } from "react-i18next";

import AsyncImage from "@onlinesales-ai/async-image-v2";
import { CreativeUploadServiceV2, NeonService } from "@onlinesales-ai/services-v2";
import { loaderWhite } from "@onlinesales-ai/loader-v2";
import { ErrorMessage } from "@onlinesales-ai/message-card-v2";

import { Input } from "@onlinesales-ai/input-v2";
import { Pill } from "@onlinesales-ai/pill-v2";
import "./index.less";

const LIMIT = 30;

const serviceNameConfig = {
  UNSPLASH: {
    name: "Unsplash",
    url: "https://unsplash.com/?utm_source=osx&utm_medium=referral",
  },
};

const ThirdPartyImageSelection = ({
  containerClass = "",
  config,
  onSelect,
  selectedData,
  fetchQueryConfig,
  showAttribution,
  clientId,
  sourceToUse,
  resetSelectedList,
  minSearchCharacters = 3,
  subType,
}) => {
  const { t } = useTranslation();
  const [imageList, setImageList] = useState([]);
  const isFirstTime = useRef(true);
  const [suggestedSearchTerms, setSuggestedSearchTerms] = useState(
    () => config?.suggestionTagConfig?.initTags || [],
  );
  const [fetchState, setFetchState] = useState({
    isLoading: false,
    errorMsg: null,
  });
  const [state, setState] = useState({
    searchTerm: "",
    pageNo: 1,
    haveMore: true,
  });
  const lazyLoadObserver = useRef();
  const lazyLoadRef = useRef();

  const fetchTags = async () => {
    try {
      const apis = [
        NeonService.fetchSellerData({ clientId, selectors: ["sellerInfo"] }, "mediaLibrary"),
      ];

      const [sellerData] = await Promise.all(apis);

      const category = sellerData?.sellerInfo?.categoryL1;
      const subCategories = sellerData?.sellerInfo?.categoryL2;

      const tags = [...suggestedSearchTerms];

      if (category) {
        tags.push(category);
      }

      if (subCategories) {
        tags.push(subCategories);
      }

      setSuggestedSearchTerms(tags);
    } catch (err) {}
  };

  useEffect(() => {
    if (config?.suggestionTagConfig?.shouldFetch) {
      fetchTags();
    }
  }, []);

  const fetchData = async (currentState, currentImageList) => {
    setFetchState({
      isLoading: true,
      errorMsg: null,
    });
    try {
      const response = await CreativeUploadServiceV2.fetchImages({
        clientId,
        searchTerms: [currentState.searchTerm],
        limitForSource: LIMIT,
        pageForSource: currentState.pageNo,
        ...fetchQueryConfig,
      });
      isFirstTime.current = false;
      setState((prev) => ({
        ...prev,
        haveMore: response.images.length === LIMIT,
      }));
      if (response?.images) {
        setImageList([...currentImageList, ...response.images]);
      }
      setFetchState({
        isLoading: false,
      });
    } catch (error) {
      setFetchState({
        isLoading: false,
        errorMsg: error.errorMsg || error,
      });
    }
  };

  useEffect(() => {
    if (lazyLoadRef.current) {
      lazyLoadObserver.current = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (!isFirstTime.current) {
              setState((prev) => ({ ...prev, pageNo: prev.pageNo + 1 }));
            }
          }
        });
      });

      lazyLoadObserver.current.observe(lazyLoadRef.current);

      return () => {
        if (lazyLoadObserver.current && lazyLoadRef.current) {
          lazyLoadObserver.current.unobserve(lazyLoadRef.current);
        }
      };
    }

    return () => {};
  }, []);

  const debouncedFetchData = useCallback(_debounce(fetchData, 500), []);

  useEffect(() => {
    if (state.searchTerm?.length >= minSearchCharacters) {
      if (state.haveMore) {
        debouncedFetchData(state, imageList);
      }
    }
  }, [state.searchTerm, state.pageNo]);

  const onChangeText = (newText) => {
    setImageList([]);
    setFetchState({
      isLoading: newText.length >= minSearchCharacters,
      errorMsg: null,
    });
    // to deselect previously selected image
    resetSelectedList();
    isFirstTime.current = true;
    setState({
      searchTerm: newText,
      pageNo: 1,
      haveMore: true,
    });
  };

  return (
    <div className={`third-party-image-selection ${containerClass}`}>
      <div className="search-input">
        <div className="label">
          <Trans>
            Search on{" "}
            <a href={serviceNameConfig[sourceToUse].url} target="_blank">
              {{ serviceName: serviceNameConfig[sourceToUse].name }}
            </a>
          </Trans>
        </div>
        <div className="input-wrapper-search col-md-9 padding-0">
          <Input
            placeholder={t("Search Term")}
            inputWrapperClassName="border-padding"
            value={state.searchTerm}
            onChange={onChangeText}
            prefix={<span className="icon icon-search2" />}
            suffix={
              state.searchTerm ? (
                <span onClick={() => onChangeText("")} className="icon icon-close1" />
              ) : (
                ""
              )
            }
          />
          {!state.searchTerm?.length && !!suggestedSearchTerms?.length && (
            <div className="suggested-search-terms">
              <Pill.List>
                {suggestedSearchTerms?.map((suggestedTerm) => {
                  return (
                    <Pill variant="oyster">
                      <span onClick={() => onChangeText(suggestedTerm)}>{suggestedTerm}</span>
                    </Pill>
                  );
                })}
              </Pill.List>
            </div>
          )}
        </div>
      </div>
      <div className="image-list-wrapper">
        <div className="image-list">
          {imageList.map(({ url, thumbnailUrl, user, source, downloadLocation }) => {
            const isSelected = _find(selectedData, { tabType: "IMAGE", subType, url });
            return (
              <div className="gap-list">
                <figure>
                  <div
                    key={url}
                    className={`media-item ${isSelected ? "selected" : "not-selected"}`}
                    onClick={() => onSelect(url, { type: "IMAGE", source, downloadLocation })}
                  >
                    <AsyncImage imgSrc={thumbnailUrl} />
                    {isSelected && <div className="plan-select icon-tick-mark " />}
                    <figcaption>
                      {showAttribution && (
                        <a
                          className="attribution"
                          href={`${user.profileUrl}?utm_source=osx&utm_medium=referral`}
                          target="_blank"
                        >
                          <Trans>Photo By {{ userName: user.name }}</Trans>
                          {/* on{" "}
                            <a href={serviceUrl} target="_blank">
                              {serviceName}
                            </a> */}
                        </a>
                      )}
                    </figcaption>
                  </div>
                </figure>
              </div>
            );
          })}
        </div>
        {!fetchState.isLoading &&
        !fetchState.errorMsg &&
        !imageList.length &&
        state.searchTerm?.length >= minSearchCharacters ? (
          <div className="no-data-available"><Trans>No {{ mediaType: config.displayName }} Found</Trans></div>
          ) : null}
        {fetchState.isLoading ? <div className="loader">{loaderWhite()}</div> : null}
        {fetchState.errorMsg ? (
          <ErrorMessage title="Error while fetching images" description={fetchState.errorMsg} />
          ) : null}
        <div className="lazy-load-trigger" ref={lazyLoadRef} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    clientId: state.Application.clientId,
  };
};

ThirdPartyImageSelection.defaultProps = {
  selectedData: [],
  sourceToUse: "UNSPLASH",
  fetchQueryConfig: {
    sources: [
      "UNSPLASH",
      // "PEXELS",
    ],
    orientation: "LANDSCAPE",
    enableSafeSearch: true,
    // "color": "RED",
    orderBy: "RELEVANT",
  },
  showAttribution: true,
};

export default connect(mapStateToProps)(ThirdPartyImageSelection);
