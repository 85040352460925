import { removeUndefinedAndNullFromObject } from "@onlinesales-ai/util-methods-v2";
import Types from "./types";

const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.APP_SET_ENTITY_ID:
      {
        state = {
          ...initialState,
        };
      }
      break;
    case Types.RESET_DASHBOARD_DATA:
      {
        state = {
          ...state,
          [action.key]: {},
        };
      }
      break;
    case Types.SET_GLOBAL_DASHBOARD_DATA:
      {
        state = {
          ...state,
          [action.key]: {
            ...(state[action.key] || {}),
            globalData: {
              ...(state?.[action.key]?.globalData || {}),
              [action.storeKey]: action.data,
            },
          },
        };
      }
      break;
    case Types.SET_GLOBAL_FILTER_DATA:
      {
        const filters = {
          ...(state?.[action.key]?.globalFilter || {}),
          [action.storeKey]: action.data,
        };

        state = {
          ...state,
          [action.key]: {
            ...(state[action.key] || {}),
            globalFilter: removeUndefinedAndNullFromObject(filters),
          },
        };
      }
      break;
    case Types.SET_GLOBAL_FILTER_MULTI_DATA:
      {
        const filter = {
          ...(state?.[action.key]?.globalFilter || {}),
          ...action.data,
        };

        state = {
          ...state,
          [action.key]: {
            ...(state[action.key] || {}),
            globalFilter: removeUndefinedAndNullFromObject(filter),
          },
        };
      }
      break;
    case Types.SET_DASHBOARD_REPORT_DATA:
      {
        state = {
          ...state,
          [action.key]: {
            ...(state[action.key] || {}),
            reportData: {
              ...(state?.[action.key]?.reportData || {}),
              [action.storeKey]: {
                ...(state?.[action.key]?.reportData?.[action.storeKey] || {}),
                ...action.data,
              },
            },
          },
        };
      }
      break;
    default:
      break;
  }

  return state;
};

export default {
  Reporting: reducer,
};
