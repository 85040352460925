export default {
  BILLING_UPDATE_CLIENT_STATUS: "BILLING_UPDATE_CLIENT_STATUS",
  BILLING_UPDATE_PENDING_ORDERS: "BILLING_UPDATE_PENDING_ORDERS",
  BILLING_SET_PLAN_DETAILS_FETCH_IN_PROGRESS: "BILLING_SET_PLAN_DETAILS_FETCH_IN_PROGRESS",
  BILLING_SET_PLAN_DETAILS: "BILLING_SET_PLAN_DETAILS",
  BILLING_UPDATE_CART_STATUS: "BILLING_UPDATE_CART_STATUS",
  BILLING_UPDATE_USER_PENDING_TRANSACTIONS: "BILLING_UPDATE_USER_PENDING_TRANSACTIONS",
  APP_SET_BILLING_HYGIENE_DATA: "APP_SET_BILLING_HYGIENE_DATA",
  BILLING_UPDATE_CLIENT_APP_STATUS: "BILLING_UPDATE_CLIENT_APP_STATUS",
};
