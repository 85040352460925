const translations = {
  "Cost per Click": "Cost per Click",
  Budget: "Budget",
  "title.SETTINGS_USER_PROFILE": "User Profile",
  // Manage: "Manage_EN",
  // "Manage Billing": "Manage Billing_EN",
  // Balance: "Balance_EN",
  // "Promotional Balance": "Promotional Balance_EN",
  // "Max usage allowed": "Max usage allowed_EN",
  // "The net remaining funds designated for ad spends dedicated to the selected campaign type.":
  //   "The net remaining funds designated for ad spends dedicated to the selected campaign type._EN",
  // "The net available amount, attached to all wallets designated for ad spends dedicated to the selected campaign type.":
  //   "The net available amount, attached to all wallets designated for ad spends dedicated to the selected campaign type._EN",
  // "The maximum net amount permitted for expenditure as ad spends for the selected campaign type.":
  //   "The maximum net amount permitted for expenditure as ad spends for the selected campaign type._EN",
  // "The remaining funds designated for ad spends within the default or selected wallet.":
  //   "The remaining funds designated for ad spends within the default or selected wallet._EN",
  // "The available amount, attached with the default or selected wallet, specifically allocated for incentivizing eligible campaign types.":
  //   "The available amount, attached with the default or selected wallet, specifically allocated for incentivizing eligible campaign types._EN",
  // "The maximum net amount permitted for expenditure as ad spends within the default or selected wallet.":
  //   "The maximum net amount permitted for expenditure as ad spends within the default or selected wallet._EN",
  // "Wallet Details": "Wallet Details_EN",
  // "Minimum Top-up Amount": "Minimum Top-up Amount_EN",
  // "Add Money": "Add Money_EN",
  // "Create New Wallet": "Create New Wallet_EN",
  // "+ Create New Wallet": "+ Create New Wallet_EN",
  // "Create Wallet": "Create Wallet_EN",
  // "Wallet Name": "Wallet Name_EN",
  // "Campaign Type": "Campaign Type_EN",
  // "Enter Wallet Name": "Enter Wallet Name_EN",
  // "<0>Select Campaign Type</0>": "<0>Select Campaign Type_EN</0>",
  // "Please enter wallet name": "Please enter wallet name_EN",
  // "Please select campaign Type": "Please select campaign Type_EN",
  // All: "All_EN",
  // Date: "Date_EN",
  // "Transaction Log": "Transaction Log_EN",
  // "Add Filter": "Add Filter_EN",
  // "Add/Remove Columns": "Add/Remove Columns_EN",
  // "Select a date": "Select a date_EN",
  // "less than (<)": "less than (<)_EN",
  // "less than or equals (<=)": "less than or equals (<=)_EN",
  // "greater than (>)": "greater than (>)_EN",
  // "greater than or equals (>=)": "greater than or equals (>=)_EN",
  // "<0>Select an options</0>": "<0>Select an options_EN</0>",
  // "has any of": "has any of_EN",
  // "has none of": "has none of_EN",
  // "equals (=)": "equals (=)_EN",
  // "not equals (≠)": "not equals (≠)_EN",
  // "Enter a value": "Enter a value_EN",
  // "does not contains": "does not contains_EN",
  // Search: "Search_EN",
  // Spend: "Spend_EN",
  // "(last 7 days)": "(last 7 days)_EN",
  // "Promotion Balance": "Promotion Balance_EN",
  // "Campaign Type Tagged": "Campaign Type Tagged_EN",
  // "Active Campaigns": "Active Campaigns_EN",
  // "Manage Wallet": "Manage Wallet_EN",
  // From: "From_EN",
  // To: "To_EN",
  // "Enter Amount": "Enter Amount_EN",
  // "Please select wallet": "Please select wallet_EN",
  // "Please enter amount": "Please enter amount_EN",
  // "Please recharge your wallet for transfer": "Please recharge your wallet for transfer_EN",
  // "Enter Description": "Enter Description_EN",
  // "Transferred from {{from}} to {{to}}": "Transferred from {{from}} to {{to}}_EN",
  // "<0>Select To</0>": "<0>Select To_EN</0>",
  // "<0>Select From</0>": "<0>Select From_EN</0>",
  // "<0>Wallet Balance</0>": "<0>Wallet Balance_EN</0>",
  // "<0>Amount should be less than</0>": "<0>Amount should be less than_EN</0>",
  // "<0>Maximum Transfer Amount:</0>": "<0>Maximum Transfer Amount:_EN</0>",
  // "Transfer": "Transfer_EN",
  // "Amount Transferred successfully.": "Amount Transferred successfully._EN",
  // "Transaction Log for {{walletName}}": "Transaction Log for {{walletName}}_EN",
  // " (Excluding **{{percentage}}%** {{taxName}})": " (Excluding **{{percentage}}%** {{taxName}})_EN",
  // "<0>Minimum Top-up Amount</0>": "<0>Minimum Top-up Amount_EN</0>",
  // "Wallet created successfully": "Wallet created successfully_EN",
  // "Wallet updated successfully": "Wallet updated successfully_EN",
  // "Add Money to Your Wallet": "Add Money to Your Wallet_EN",
  // "Refresh": "Refresh_EN",
  // "Wallet": "Wallet_EN",
  // "You’re unable to link this wallet to the campaign because it is not eligible for {{type}} format.": "You’re unable to link this wallet to the campaign because it is not eligible for {{type}} format.",
  // "Please be aware that if your wallet balance displays a negative value, it indicates overspending. This situation may arise if multiple users' activities are attributed to your ad simultaneously, especially when your wallet balance is close to exhaustion.": "Please be aware that if your wallet balance displays a negative value, it indicates overspending. This situation may arise if multiple users' activities are attributed to your ad simultaneously, especially when your wallet balance is close to exhaustion.",
  // "If we're unable to deliver the booked impressions, we'll reimburse the remaining balance back to your ad wallet.": "If we're unable to deliver the booked impressions, we'll reimburse the remaining balance back to your ad wallet.",
  "Budget": "Budget",
  "title.SETTINGS_USER_PROFILE": "User Profile",
  "Report Generated On:": "Report Generated On:",
  "Report Requested On:": "Report Requested On:",
  "Your report is delivered on your mail box": "Your report is delivered on your mail box",
  "You will receive detailed report in your mail box once ready":
    "You will receive detailed report in your mail box once ready",
  "Report Details for {{campaignName}}": "Report Details for {{campaignName}}",
  "Campaign is recently activated": "Campaign is recently activated",
  "Your campaign launched recently. Once your campaign starts serving, you’ll be able to troubleshoot any issues.":
    "Your campaign launched recently. Once your campaign starts serving, you’ll be able to troubleshoot any issues.",
  "Troubleshoot Report Generated in the last hour":
    "Troubleshoot Report Generated in the last hour",
  "You generated a troubleshoot report recently which is available for analysis. Please wait for a while before attempting troubleshooting again.":
    "You generated a troubleshoot report recently which is available for analysis. Please wait for a while before attempting troubleshooting again.",
  "Troubleshoot Report Available for Analysis": "Troubleshoot Report Available for Analysis",
  "You recently generated a troubleshoot report, which is now available for analysis. Would you like to review the report?":
    "You recently generated a troubleshoot report, which is now available for analysis. Would you like to review the report?",

  // Campaign Debugger Report
  "Campaign was recently created. Please try to troubleshoot after some time .":
    "Campaign was recently created. Please try to troubleshoot after some time .",
  "Your campaign is still in Draft status hence it’s not active.": "Your campaign is still in Draft status hence it’s not active.",
  "Your campaign has been paused, and there could be several reasons for this. Perhaps you manually paused the campaign, its end date has been reached, or the campaign has reached its spend cap / total budget.":
    "Your campaign has been paused, and there could be several reasons for this. Perhaps you manually paused the campaign, its end date has been reached, or the campaign has reached its spend cap / total budget.",
  "Your campaign is active and will start delivering ads soon.": "Your campaign is active and will start delivering ads soon.",
  "Your campaign has not yet reached its scheduled start date.": "Your campaign has not yet reached its scheduled start date.",
  "Your campaign has reached its end date.": "Your campaign has reached its end date.",
  "Your campaign is currently within its active date range.":
    "Your campaign is currently within its active date range.",
  "Your campaign is not getting impressions with respect to it’s potential.":
    "Your campaign is not getting impressions with respect to it’s potential.",
  "Your campaign is garnering a robust number of impressions, suggesting that your ads are effectively reaching potential audiences.":
    "Your campaign is garnering a robust number of impressions, suggesting that your ads are effectively reaching potential audiences.",
  "Your wallet balance is not sufficient to keep serving ads. Please top-up your wallet to avoid any interruptions.":
    "Your wallet balance is not sufficient to keep serving ads. Please top-up your wallet to avoid any interruptions.",
  "Your wallet balance is nearing the minimum threshold required to run the campaign. Consider adding funds to ensure uninterrupted ad delivery.":
    "Your wallet balance is nearing the minimum threshold required to run the campaign. Consider adding funds to ensure uninterrupted ad delivery.",
  "Your wallet balance is sufficient to continue running the campaign at the current spending rate.":
    "Your wallet balance is sufficient to continue running the campaign at the current spending rate.",
  "Your daily budget has been exhausted for the day. The campaign will stop serving ads until tomorrow unless the daily budget is increased.":
    "Your daily budget has been exhausted for the day. The campaign will stop serving ads until tomorrow unless the daily budget is increased.",
  "Your daily budget is nearing exhaustion. Consider increasing your budget to avoid any interruptions.":
    "Your daily budget is nearing exhaustion. Consider increasing your budget to avoid any interruptions.",
  "Your campaign has not exhausted its daily budget, leaving room for further spending for the day.":
    "Your campaign has not exhausted its daily budget, leaving room for further spending for the day.",
  "Your campaign bid falls below the required minimum CPM for certain inventories to participate in auction. This could potentially restrict your campaign's reach. To compete more efficiently, consider raising your bid.":
    "Your campaign bid falls below the required minimum CPM for certain inventories to participate in auction. This could potentially restrict your campaign's reach. To compete more efficiently, consider raising your bid.",
  "Your campaign bid is more than the minimum bid values required by all the ad inventories selected in your campaign.":
    "Your campaign bid is more than the minimum bid values required by all the ad inventories selected in your campaign.",
  "Your campaign has reached its maximum spend cap. The campaign will stop serving ads until you increase the spend cap on this campaign.":
    "Your campaign has reached its maximum spend cap. The campaign will stop serving ads until you increase the spend cap on this campaign.",
  "Your campaign is approaching its maximum spend cap. Consider monitoring the spend closely and adjusting the cap if necessary.":
    "Your campaign is approaching its maximum spend cap. Consider monitoring the spend closely and adjusting the cap if necessary.",
  "Your campaign has not exceeded its Maximum spend cap, leaving room for further spending for the day.":
    "Your campaign has not exceeded its Maximum spend cap, leaving room for further spending for the day.",
  "None of your campaign creatives are approved. Please check if there are rejections and adjust your creatives according to guidelines.": "None of your campaign creatives are approved. Please check if there are rejections and adjust your creatives according to guidelines.",
  "Some of your campaign creatives are not approved hence inactive in the campaign.": "Some of your campaign creatives are not approved hence inactive in the campaign.",
  "All your campaign creatives are approved and active.": "All your campaign creatives are approved and active.",
  "Your campaign didn't win in any of the chosen inventories. Consider increasing your bid, and if the situation persists raise a ticket to support for further investigation.": "Your campaign didn't win in any of the chosen inventories. Consider increasing your bid, and if the situation persists raise a ticket to support for further investigation.",
  "Your campaign didn't win in some of the chosen inventories. Consider increasing your bid in those inventories, and if the situation persists raise a ticket to support for further investigation.":
    "Your campaign didn't win in some of the chosen inventories. Consider increasing your bid in those inventories, and if the situation persists raise a ticket to support for further investigation.",
  "Your campaigns are performing in all of your chosen inventories.": "Your campaigns are performing in all of your chosen inventories.",
  "None of the keywords you've chosen for targeting have not been approved, and therefore, are inactive in the campaign.":
    "None of the keywords you've chosen for targeting have not been approved, and therefore, are inactive in the campaign.",
  "Some of the keywords you've chosen for targeting have not been approved, and therefore, are inactive in the campaign.":
    "Some of the keywords you've chosen for targeting have not been approved, and therefore, are inactive in the campaign.",
  "All your chosen keywords for targeting have been approved, and are active in the campaign.": "All your chosen keywords for targeting have been approved, and are active in the campaign.",
  // Capmpaign Debugger - Campaign Attributes Snapshot
  "Campaign Attributes Snapshot": "Campaign Attributes Snapshot",
  "Wallet Balance": "Wallet Balance",
  "Daily Budget": "Daily Budget",
  "CPM Bid": "CPM Bid",
  "Est. Impressions": "Est. Impressions",
  "Active Ad Creatives": "Active Ad Creatives",
  "Ad Inventories Count": "Ad Inventories Count",
};

export default {
  translations,
};
