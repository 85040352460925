import BaseClient from "../baseClient";

const servicePrefix = "/creativeUploadService/v2/";

class CreativeUploadServiceV2 extends BaseClient {
  static getErrorMessages() {
    return {
      default: "Looks like we are facing some internal issue. Please, retry after sometime.",
      IP0000:
        "It seems some input parameters are not valid. Please update your inputs and try again.",
      AD0000:
        "Oops. It seems you are not authorized to take this action. Please log in with appropriate credentials.",
      I0004:
        "Oops. Seems the system is acting up - sorry for the inconvenience. Please try again in some time. (Please be assured our tech geniuses have been notified of this glitch).",
    };
  }

  static fetchImages(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}search`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
    );
  }

  static unsplashDownload(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}search/trackUnsplashDownloads`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
    );
  }
}

export default CreativeUploadServiceV2;
