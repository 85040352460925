import React, { useState, useEffect, useMemo } from "react";
import { InputText } from "@onlinesales-ai/form-components-v2";
import { connect } from "react-redux";
import _get from "lodash/get";

import { FetchBanner } from "@onlinesales-ai/message-card-v2";
import { useNotification, pendoTrackEvent } from "@onlinesales-ai/util-methods-v2";

import TopupAdditionPills from "../topupAdditionPills";

import "./styles.less";

const TopupWithRunwayDays = ({
  guidTextConfig,
  defaultRunwayDays,
  runwayInputProps,
  goalsFetchInProgress,
  pillNoOfDaysArray,
  ...props
}) => {
  const { dataKey, formValues, onChange } = props;
  const [runwayDays, setRunwayDays] = useState(0);
  const [inputFormIsActive, setInputFormIsActive] = useState(false);
  const [notifications] = useNotification({
    keysToExtract: ["RECOMMENDED_TOPUP_AMOUNT"],
  });

  const {
    __RECOMMENDED_BUDGET__: totalDailyBudget = 0,
  } = useMemo(() => {
    return notifications?.RECOMMENDED_TOPUP_AMOUNT?.metadata?.placeholders || {};
  }, notifications?.RECOMMENDED_TOPUP_AMOUNT);

  const totalDailyBudgetToUse = useMemo(() => {
    if (!totalDailyBudget && !goalsFetchInProgress) {
      return 1000;
    }
    return totalDailyBudget;
  }, [!goalsFetchInProgress, totalDailyBudget]);

  const getValue = () => {
    return _get(formValues, dataKey);
  };

  const newTopupValue = useMemo(getValue, [props.formValues]);

  const handleRunwayDaysChange = ({ runwayDayValue }) => {
    setRunwayDays(runwayDayValue);
    onChange({
      [dataKey]: runwayDayValue * totalDailyBudgetToUse,
    });
    pendoTrackEvent("topup_drawer_runway_days_changed");
  };

  useEffect(() => {
    if (totalDailyBudgetToUse && !runwayDays) {
      handleRunwayDaysChange({
        runwayDayValue: defaultRunwayDays,
      });
    }
  }, [totalDailyBudgetToUse]);

  useEffect(() => {
    if (totalDailyBudgetToUse && newTopupValue) {
      setRunwayDays(Math.floor(newTopupValue / totalDailyBudgetToUse));
    }
  }, [totalDailyBudgetToUse, newTopupValue]);

  const additionPills = useMemo(() => {
    if (!totalDailyBudgetToUse) {
      return [];
    } else {
      return (
        pillNoOfDaysArray?.map((noOfDays) => {
          return (
            {
              amount: Math.ceil((totalDailyBudgetToUse * noOfDays) / 100) * 100,
            }
          );
        })
      );
    }
  }, [totalDailyBudgetToUse]);

  const focusClass = useMemo(() => {
    return inputFormIsActive ? "input-form-active" : "";
  }, [inputFormIsActive]);

  const commonProps = {
    ...props,
    onFocus: () => setInputFormIsActive(true),
    onBlur: () => setInputFormIsActive(false),
  };

  return (
    <div className={`topup-with-runway-days custom-background with-bg-image ${focusClass}`}>
      <FetchBanner
        isLoading={goalsFetchInProgress}
      >
        <div className="inputs-wrapper">
          <InputText {...commonProps} />
          <div className="form-connector">
            <span className="icon icon-exchange-2" />
          </div>
          <InputText
            {...commonProps}
            dataKey="runwayDayValue"
            value={runwayDays}
            onChange={handleRunwayDaysChange}
            showCurrency={false}
            {...runwayInputProps}
          />
        </div>
        <TopupAdditionPills
          additionPills={additionPills}
          {...props}
          showAddButton={false}
          showCurrency
        />
      </FetchBanner>
    </div>
  );
};

TopupWithRunwayDays.defaultProps = {
  defaultRunwayDays: 30,
  runwayInputProps: {},
};

const mapStateToProps = (state) => {
  return {
    goalsFetchInProgress: state.GoalsV2.isFetchInProgress,
  };
};

export default connect(mapStateToProps)(TopupWithRunwayDays);
