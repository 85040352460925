import React, { useEffect, useState, useMemo } from "react";
import { intervalToDuration } from "date-fns/esm";

import Flip from "./Flip";
import "./style.less";

const Flipclock = ({
  countdownTime, timeFramesToShow, timerInterval,
}) => {
  const [currentDateEpoch, setCurrentDateEpoch] = useState(() => new Date().valueOf());

  useEffect(() => {
    let interval;
    if (timerInterval) {
      interval = window.setInterval(() => {
        setCurrentDateEpoch(new Date().valueOf());
      }, timerInterval);
    }
    return () => {
      if (interval) {
        window.clearInterval(interval);
      }
    };
  }, []);

  const flipBlocks = useMemo(() => {
    const timeEpoch = new Date(countdownTime).valueOf();
    const duration = intervalToDuration({
      start: currentDateEpoch,
      end: timeEpoch,
    });
    const spacesToReturn = [];
    Object.keys(duration).forEach((key = "") => {
      if (timeFramesToShow?.includes(key)) {
        const value = duration?.[key];
        const valueToPush = `${value}`?.length === 1 ? `0${value}` : value;
        spacesToReturn.push({
          key,
          value: valueToPush,
        });
      }
    });
    return spacesToReturn;
  }, [currentDateEpoch]);

  return (
    <div className="flipclock-wrapper d-center">
      {flipBlocks?.map(({ value, key }, index) => {
        return (
          <span className="flip-content" key={key}>
            <span className="flip-title">{key}</span>
            <div className="flip-tick-block d-align-center">
              <Flip value={value} />
              {index !== flipBlocks?.length - 1 && <span className="flip-seperator">:</span>}
            </div>
          </span>
        );
      })}
    </div>
  );
};

Flipclock.defaultProps = {
  timeFramesToShow: [
  //   "years",
  // "months",
  "days",
  "hours",
  "minutes",
  "seconds",
  ],
  timerInterval: 1000
};

export default Flipclock;
