import { customMergeOS } from "@onlinesales-ai/util-methods-v2";
import _cloneDeep from "lodash/cloneDeep";
import _merge from "lodash/merge";

const loadConfig = async (configs, configName) => {
  const configToLoad = configs[configName];
  let jsonConfig = {};
  if (typeof configToLoad === "function") {
    try {
      jsonConfig = await configToLoad();
      jsonConfig = jsonConfig.default;
    } catch (err) {}
  } else {
    jsonConfig = configToLoad;
  }

  return jsonConfig;
};

export const getDomainConfig = async ({
  configIdentifier, // domain name part like apps
  defaultConfigName, // default domain config name
  configOverrides = {}, // domain config mapping
  clientConfigMapping = {}, // client config from onboarding data
  configs = {}, // domain config data
}) => {
  const getFromClientConfig = () => {
    let retnVal = null;

    if (clientConfigMapping.hasOwnProperty(configIdentifier)) {
      retnVal = clientConfigMapping[configIdentifier].name;
    }

    return retnVal;
  };

  const getFromOverrides = () => {
    let retnVal = null;

    if (configOverrides.hasOwnProperty(configIdentifier)) {
      retnVal = configOverrides[configIdentifier].name;
    }

    return retnVal;
  };

  let newConfigName = "base.json";

  if (configIdentifier) {
    if (
      !configOverrides[configIdentifier] ||
      configOverrides[configIdentifier].isCheckOBFlagFirst
    ) {
      newConfigName = getFromClientConfig();
      if (!newConfigName) {
        newConfigName = getFromOverrides();
      }
    } else {
      newConfigName = getFromOverrides();
      if (!newConfigName) {
        newConfigName = getFromClientConfig();
      }
    }
    if (!newConfigName || !configs[newConfigName]) {
      newConfigName = defaultConfigName;
    }
  }

  const baseConfig = _cloneDeep(await loadConfig(configs, defaultConfigName));
  const overrideConfig = _cloneDeep(await loadConfig(configs, newConfigName));

  const getInheritConfig = ({ inheritFrom = [] }) => {
    let inheritConfig = {};

    for (let i = 0; i < inheritFrom.length; i++) {
      const domainConfig = _cloneDeep(inheritFrom[i] || {});
      inheritConfig = _merge(
        inheritConfig,
        customMergeOS(getInheritConfig(domainConfig), domainConfig),
      );
    }

    return inheritConfig;
  };

  const mergedConfig = customMergeOS(
    {},
    baseConfig,
    getInheritConfig(overrideConfig),
    overrideConfig,
  );

  if (mergedConfig.inheritFrom) {
    delete mergedConfig.inheritFrom;
  }

  return mergedConfig;
};
