import React from "react";

import "./index.less";

const FixLayout = React.forwardRef(({ children, className, ...props }, ref) => {
  return (
    <div className={`fixed-layout ${className || ""}`} {...props} ref={ref}>
      {children}
    </div>
  );
});

FixLayout.Header = ({ children, className, enableBoxShadow, rightContent, ...props }) => {
  return (
    <div
      className={`fixed-layout-header ${className || ""} ${
        enableBoxShadow ? "boxshadow-header" : ""
      }`}
      {...props}
    >
      {children}
      {rightContent && <div className="fixed-layout-header-right-content">{rightContent}</div>}
    </div>
  );
};

FixLayout.Body = React.forwardRef(({ children, className, ...props }, ref) => {
  return (
    <div ref={ref} className={`fixed-layout-body ${className || ""}`} {...props}>
      {children}
    </div>
  );
});

FixLayout.Footer = ({ children, className, enableBoxShadow, ...props }) => {
  return (
    <div
      className={`fixed-layout-footer ${className || ""} ${
        enableBoxShadow ? "boxshadow-footer" : ""
      }`}
      {...props}
    >
      {children}
    </div>
  );
};

FixLayout.Header.defaultProps = {
  enableBoxShadow: false,
};

FixLayout.Footer.defaultProps = {
  enableBoxShadow: false,
};

export default FixLayout;
