import BaseClient from "../baseClient";

const servicePrefix = "/starkService/";
class StarkService extends BaseClient {
  static getErrorMessages() {
    return {
      default: "Looks like we are facing some internal issue. Please, retry after sometime.",
      IP0000:
        "It seems some input parameters are not valid. Please update your inputs and try again.",
      AD0000:
        "Oops. It seems you are not authorized to take this action. Please log in with appropriate credentials.",
      I0004:
        "Oops. Seems the system is acting up - sorry for the inconvenience. Please try again in some time. (Please be assured our tech geniuses have been notified of this glitch).",
      VENDOR_ACCOUNT_REQUIRED: "It seems selected vendor accounts are not linked, Please link and try again",
      FACEBOOK_PERMISSION_DENIED: "User does not have access to given Facebook page, Please re-link your facebook page and try again",
      "/starkService/posts": {
        POST: {
          VENDOR_ACCOUNT_REQUIRED: "It seems selected vendor accounts are not linked, Please link and try again",
          POST_ALREADY_PROCESSING: "Previous changes are still under process, please wait for a while and try again.",
          NO_SELLER_ADDED: "No sellers were added in bulk post",
          FACEBOOK_PERMISSION_DENIED: "User does not have access to given Facebook page, Please re-link your facebook page and try again"
        },
      },
      "/starkService/bulkPosts": {
        POST: {
          VENDOR_ACCOUNT_REQUIRED: "It seems selected vendor accounts are not linked, Please link and try again",
          POST_ALREADY_PROCESSING: "Previous changes are still under process, please wait for a while and try again.",
          NO_SELLER_ADDED: "No sellers were added in bulk post",
          FACEBOOK_PERMISSION_DENIED: "User does not have access to given Facebook page, Please re-link your facebook page and try again"
        },
      },
      "INVALID_PARAMETER_ERROR": "It seems some input parameters are not valid. Please update your inputs and try again.",
      "NO_SELLER_DATA_FOUND": "Unable to find any __SOUTLET_TEXT__s based on your inputs, Please change filters and try again!",
    };
  }

  static fetchPosts(config = {}, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}posts`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(config),
        },
      },
      application,
    );
  }

  static postPosts(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}posts`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
    );
  }

  static fetchBulkPosts(config = {}, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}bulkPosts`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(config),
        },
      },
      application,
    );
  }

  static postBulkPosts(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}bulkPosts`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
    );
  }

  static fetchPostPreview(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}posts/preview`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
    );
  }

  static postBulkPostsClients(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}bulkPosts/clients`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(payload),
        },
      },
      application,
    );
  }

  static deletePost(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}posts`,
        method: "DELETE",
        params: {
          jsonQuery: JSON.stringify(payload),
        },
      },
      application,
    );
  }

  static deleteBulkPost(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}bulkPosts`,
        method: "DELETE",
        params: {
          jsonQuery: JSON.stringify(payload),
        },
      },
      application,
    );
  }

  static fetchWorldCloud(config = {}, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}comments/wordCloud`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(config),
        },
      },
      application,
    );
  }

  static fetchAgencyWorldCloud(config = {}, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}comments/wordCloud/agency`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(config),
        },
      },
      application,
    );
  }

  static fetchAggregatedSentiments(config = {}, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}comments/sentiments`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(config),
        },
      },
      application,
    );
  }

  static fetchAggregatedAgencySentiments(config = {}, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}comments/sentiments/agency`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(config),
        },
      },
      application,
    );
  }

  static fetchComments(config = {}, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}comments`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(config),
        },
      },
      application,
      options,
    );
  }

  static fetchAgencyComments(config = {}, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}comments/agency`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(config),
        },
      },
      application,
      options,
    );
  }

  static fetchBoostPosts(config = {}, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}boost`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(config),
        },
      },
      application,
    );
  }

  static postBoostPost(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}boost`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
    );
  }

  static fetchBoostCampaignData(config = {}, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}boost/aggregate`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(config),
        },
      },
      application,
    );
  }

  static fetchCommentReplies(config = {}, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}comments/reply`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(config),
        },
      },
      application,
      options,
    );
  }

  static fetchRealtimeReviews(config = {}, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}comments/reviews`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(config),
        },
      },
      application,
      options,
    );
  }

  static fetchRealtimeAgencyReviews(config = {}, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}comments/reviews/agency`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(config),
        },
      },
      application,
      options,
    );
  }

  static postCommentReply(payload = {}, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}comments/reply`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static fetchCurrentGMBVerifications(config = {}, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}gmbVerification/retrieve`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(config),
        },
      },
      application,
      options,
    );
  }


  static fetchGMBVerificationsOptions(config = {}, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}gmbVerification/options`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(config),
        },
      },
      application,
      options,
    );
  }

  static initiateGMBVerification(payload = {}, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}gmbVerification/initiate`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static postGMBVerificationsOption(payload = {}, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}gmbVerification/complete`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }
}

export default StarkService;
