import React, { useMemo } from "react";
import { connect } from "react-redux";

import { Trans, useTranslation } from "react-i18next";

import { Label } from "@onlinesales-ai/label-v2";

import {
  getCurrencyDOM,
  formatValuesInThousands,
} from "@onlinesales-ai/util-methods-v2";

import "./index.less";

const RecommendedTopupAmount = ({
  uiNotifications,
  noOfDays = 30,
  guidTextConfig,
}) => {
  const { t } = useTranslation();
  const getMetadata = (notification = {}) => {
    let parsedMetadata = {};
    if (notification.metadata) {
      try {
        parsedMetadata = JSON.parse(notification.metadata);
      } catch (error) {
      }
    }
    return parsedMetadata?.placeholders;
  };

  const {
    __RECOMMENDED_BUDGET__: recommendedBudget,
  } = useMemo(() => {
    const notification = uiNotifications?.[0];
    return getMetadata(notification) || {};
  }, [uiNotifications]);

  const budgetToShow = useMemo(() => {
    const value = recommendedBudget * noOfDays;
    return Math.floor(value / 100) * 100;
  }, [recommendedBudget, noOfDays]);

  return (
    <span className="recommended-budget">
      {
        recommendedBudget &&
        <>
          {guidTextConfig && ", "}
          <Label
            labelDefinationKey="DASHBOARD_WALLET_TOPUP_RECOMMENDED_BUDGET"
            fallBackLabel={<em><Trans>Recommended <b>{getCurrencyDOM()}{formatValuesInThousands(budgetToShow)}</b> based on your campaigns.</Trans></em>}
            translationConfig={{
              values: {
                budgetToShow: formatValuesInThousands(budgetToShow),
              },
            }}
          />
        </>
      }
    </span>
  );
};

const mapStateToProps = (state) => {
  return {
    uiNotifications: state.UINotification?.data?.RECOMMENDED_TOPUP_AMOUNT,
  };
};

export default connect(mapStateToProps)(RecommendedTopupAmount);
