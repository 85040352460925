import React, { useContext, useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import _isEmpty from "lodash/isEmpty";

import { Tabs } from "@onlinesales-ai/tabs-v2";
import { Button } from "@onlinesales-ai/button-v2";
import { loaderWhite } from "@onlinesales-ai/loader-v2";
import { FixLayout } from "@onlinesales-ai/fix-layout-v2";
import { OSHOCWithUtilities } from "@onlinesales-ai/os-hoc-with-utilities-v2";
import { format } from "@onlinesales-ai/util-methods-v2";
import { DateRangePicker } from "@onlinesales-ai/datepicker-v2";
import { GlobalContext } from "@onlinesales-ai/utils-components-v2";
import { DEFAULT_DATE_FORMAT } from "@onlinesales-ai/constants-v2";

import { FILTER_TAB_COLORS, FILTER_COMPONENT_TYPES } from "./contants";
import CheckBoxFilterComponent from "./tabComponents/CheckBoxFilterComponent";
import RadioButtonFilterComponent from "./tabComponents/RadioButtonFilterComponent";
import RangeFilterComponent from "./tabComponents/RangeFilterComponent";
import TextInputFilterComponent from "./tabComponents/TextInputFilterComponent";
import DateSelectComponent from "./tabComponents/DateSelectComponent";
import UTCDateSelectComponent from "./tabComponents/UTCDateSelectComponent";

import "./index.less";

const Filter = ({
  filterListConfig,
  value,
  onFilterValueChange,
  onJobDone,
  resetConfirmationModal,
  showConfirmationModal,
  noFilterMessage,
  showResetButton,
  showClearAllButton,
  showFilterInDrawer,
  filterTitle,
  filterContainerClassName,
  getCustomComponentSelectedFiltersPills,
  getCustomComponentSelectedFiltersCount,
  updateCustomComponentDetails,
}) => {
  const { showToastMessage } = useContext(GlobalContext);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [error, setError] = useState({});
  const { t } = useTranslation();
  const wrapperRef = useRef();

  useEffect(() => {
    setSelectedFilters(value);
  }, [value]);

  const onResetFilters = () => {
    setSelectedFilters(value);
  };

  const onApplyFilters = () => {
    const filterListConfigKeys = Object.keys(filterListConfig) || [];

    const hasAnyError = filterListConfigKeys.some((k) => !!error[k]);

    if (hasAnyError) {
      showToastMessage({
        type: "ERROR",
        messageToDisplay: "Please correct all the filters before proceeding.",
        actionButtonLabel: null,
        toastDuration: 5000,
      });
    } else {
      onFilterValueChange(selectedFilters);
      onJobDone();
    }
  };

  const onClearAllFilters = () => {
    showConfirmationModal({
      isShow: true,
      title: "Are you sure you want to clear all the selected filters?",
      actionBtnText: "Yes",
      rightBtnText: "No",
      container: wrapperRef.current,
      actionBtnCallback: () => {
        resetConfirmationModal();
        onFilterValueChange({});
        onJobDone();
      },
      rightBtnCallback: () => {
        resetConfirmationModal();
      },
    });
  };

  const onDateChange = (selectedDated, dataKey) => {
    setSelectedFilters({
      ...selectedFilters,
      [dataKey]: {
        ...(selectedFilters[dataKey] || {}),
        ...selectedDated,
      },
    });
  };

  const onCustomFilterValueChange = (dataKey, pValue) => {
    setSelectedFilters({
      ...selectedFilters,
      [dataKey]: pValue,
    });
  };

  const getSelectedFiltersPills = () => {
    const filterListConfigKeys = Object.keys(filterListConfig) || [];
    let pillsToShow = [];

    filterListConfigKeys.forEach((key, tabIndex) => {
      const tabData = filterListConfig[key];
      const tabColor = FILTER_TAB_COLORS[tabIndex % FILTER_TAB_COLORS.length];
      if (tabData) {
        const commonClassName = error[key] ? "error" : "";
        // const errorDom = error[key] ? (
        //   <span className="error-icon">
        //     <span className="icon icon-warning-filled" />
        //   </span>
        // ) : null;

        switch (tabData.type) {
          case FILTER_COMPONENT_TYPES.RADIO_BUTTON:
            {
              const dataKey = tabData.key;
              if (dataKey) {
                const selectedValue = selectedFilters[dataKey];

                const removeFilterForRBType = () => {
                  setSelectedFilters({
                    ...selectedFilters,
                    [dataKey]: undefined,
                  });
                };
                if (typeof selectedValue !== "undefined") {
                  tabData.componentMetaData.items.forEach((item) => {
                    if (selectedValue === item.value) {
                      pillsToShow.push(
                        <span
                          key={`radio-${dataKey}-${item.value}`}
                          className={`button button-rounded ${commonClassName}`}
                          onClick={removeFilterForRBType}
                          style={{
                            backgroundColor: tabColor,
                            borderColor: tabColor,
                          }}
                        >
                          <Trans>{item.label}</Trans>
                        </span>,
                      );
                    }
                  });
                }
              }
            }
            break;
          case FILTER_COMPONENT_TYPES.CHECKBOX:
            {
              const dataKey = tabData.key;
              if (
                dataKey &&
                selectedFilters[dataKey] &&
                selectedFilters[dataKey].length &&
                tabData.componentMetaData &&
                tabData.componentMetaData.items
              ) {
                const removeFilterForCBType = (filterDataKey, filterValue) => {
                  const selectedData = selectedFilters[dataKey] || [];
                  const indexOfValue = selectedData.indexOf(filterValue);
                  if (indexOfValue > -1) {
                    selectedData.splice(indexOfValue, 1);

                    setSelectedFilters((prevSelectedFilters) => {
                      const updatedFilters = { ...prevSelectedFilters, [dataKey]: selectedData };

                      if (updatedFilters?.[filterDataKey]?.length === 0) {
                        delete updatedFilters[filterDataKey];
                      }

                      return updatedFilters;
                    });
                  }
                };

                tabData.componentMetaData.items.forEach((item) => {
                  const isSelected = selectedFilters[dataKey].includes(item.value);
                  if (isSelected) {
                    pillsToShow.push(
                      <span
                        key={`radio-${dataKey}-${item.value}`}
                        className={`button button-rounded ${commonClassName}`}
                        onClick={removeFilterForCBType.bind(this, dataKey, item.value)}
                        style={{
                          backgroundColor: tabColor,
                          borderColor: tabColor,
                        }}
                      >
                        <Trans>{item.label}</Trans>
                      </span>,
                    );
                  }
                });
              }
            }
            break;
          case FILTER_COMPONENT_TYPES.RANGE_FILTER:
            {
              const dataKey = tabData.key;
              if (dataKey && selectedFilters[dataKey] && tabData.componentMetaData) {
                const removeFilterForRangeType = () => {
                  setSelectedFilters({
                    ...selectedFilters,
                    [dataKey]: {
                      greater_than: "",
                      less_than: "",
                    },
                  });
                };

                let pillText = `${t(tabData.displayName)}: `;
                const pillPartsText = [];

                if (selectedFilters[dataKey].greater_than) {
                  pillPartsText.push(`> ${selectedFilters[dataKey].greater_than}`);
                }

                if (selectedFilters[dataKey].less_than) {
                  pillPartsText.push(`< ${selectedFilters[dataKey].less_than}`);
                }

                if (pillPartsText.length) {
                  pillText += pillPartsText.join(" & ");
                  pillsToShow.push(
                    <span
                      key={`range-filter-${dataKey}-${pillText}`}
                      className={`button button-rounded ${commonClassName}`}
                      onClick={removeFilterForRangeType.bind(this, dataKey)}
                      style={{
                        backgroundColor: tabColor,
                        borderColor: tabColor,
                      }}
                    >
                      {pillText}
                    </span>,
                  );
                }
              }
            }
            break;
          case FILTER_COMPONENT_TYPES.CONTAINS_TEXT:
            {
              const dataKey = tabData.key;
              if (
                dataKey &&
                selectedFilters[dataKey] &&
                tabData.componentMetaData &&
                tabData.componentMetaData
              ) {
                const removeFilterForTextInputType = (filterDataKey, keyToDelete) => {
                  setSelectedFilters((prevSelectedFilters) => {
                    const updatedFilters = {
                      ...prevSelectedFilters,
                    };
                    delete updatedFilters[keyToDelete];

                    return updatedFilters;
                  });
                };
                const selectedFiltersForDataKey = selectedFilters[dataKey];
                Object.keys(selectedFiltersForDataKey).forEach((pKey) => {
                  const displayValue = selectedFiltersForDataKey[pKey];
                  if (displayValue) {
                    pillsToShow.push(
                      <span
                        key={`text-${dataKey}-${displayValue}`}
                        className={`button button-rounded ${commonClassName}`}
                        onClick={removeFilterForTextInputType.bind(this, dataKey, pKey)}
                        style={{
                          backgroundColor: tabColor,
                          borderColor: tabColor,
                        }}
                      >
                        {displayValue}
                      </span>,
                    );
                  }
                });
              }
            }
            break;
          case FILTER_COMPONENT_TYPES.DATE_RANGE:
            {
              const dataKey = tabData.key;
              if (dataKey && selectedFilters[dataKey] && tabData.componentMetaData) {
                const removeFilterForRangeType = () => {
                  setSelectedFilters({
                    ...selectedFilters,
                    [dataKey]: {
                      startDate: "",
                      endDate: "",
                    },
                  });
                };

                let pillText = `${tabData.displayName}: `;
                const pillPartsText = [];

                if (selectedFilters[dataKey].startDate) {
                  pillPartsText.push(
                    `>= ${format(selectedFilters[dataKey].startDate, DEFAULT_DATE_FORMAT)}`,
                  );
                }

                if (selectedFilters[dataKey].endDate) {
                  pillPartsText.push(
                    `<= ${format(selectedFilters[dataKey].endDate, DEFAULT_DATE_FORMAT)}`,
                  );
                }

                if (pillPartsText.length) {
                  pillText += pillPartsText.join(" & ");
                  pillsToShow.push(
                    <span
                      key={`date-range-${dataKey}-${pillText}`}
                      className={`button button-rounded ${commonClassName}`}
                      onClick={removeFilterForRangeType.bind(this, dataKey)}
                      style={{
                        backgroundColor: tabColor,
                        borderColor: tabColor,
                      }}
                    >
                      {pillText}
                    </span>,
                  );
                }
              }
            }
            break;
          case FILTER_COMPONENT_TYPES.DATE_SELECT:
          case FILTER_COMPONENT_TYPES.UTC_DATE_SELECT:
            {
              const dataKey = tabData.key;
              if (dataKey && selectedFilters[dataKey] && tabData.componentMetaData) {
                const removeFilterForRangeType = () => {
                  setSelectedFilters({
                    ...selectedFilters,
                    [dataKey]: {
                      startDate: "",
                      endDate: "",
                    },
                  });
                };

                let pillText = `${tabData.displayName}: `;
                const pillPartsText = [];

                if (selectedFilters[dataKey].startDate) {
                  pillPartsText.push(
                    `>= ${format(selectedFilters[dataKey].startDate, DEFAULT_DATE_FORMAT)}`,
                  );
                }

                if (selectedFilters[dataKey].endDate) {
                  pillPartsText.push(
                    `<= ${format(selectedFilters[dataKey].endDate, DEFAULT_DATE_FORMAT)}`,
                  );
                }

                if (pillPartsText.length) {
                  pillText += pillPartsText.join(" & ");
                  pillsToShow.push(
                    <span
                      key={`date-select-${dataKey}-${pillText}`}
                      className={`button button-rounded ${commonClassName}`}
                      onClick={removeFilterForRangeType.bind(this, dataKey)}
                      style={{
                        backgroundColor: tabColor,
                        borderColor: tabColor,
                      }}
                    >
                      {pillText}
                    </span>,
                  );
                }
              }
            }
            break;
          default:
            {
              const newPill =
                getCustomComponentSelectedFiltersPills(
                  tabData,
                  selectedFilters,
                  {
                    tabColor,
                    onCustomFilterValueChange,
                  },
                ) || [];
              if (newPill?.length) {
                pillsToShow = [
                  ...(pillsToShow || []),
                  newPill,
                ];
              }
            }
            break;
        }
      }
    });

    return pillsToShow;
  };

  const onRangeFilterValueChange = (dataKey, key, pValue) => {
    if (dataKey && key) {
      const formattedValue = !Number.isNaN(Number(pValue)) ? Number(pValue) : "";

      setSelectedFilters({
        ...selectedFilters,
        [dataKey]: {
          ...(selectedFilters[dataKey] || {}),
          [key]: formattedValue,
        },
      });
    }
  };

  const onCheckboxToggle = (dataKey, pValue) => {
    setSelectedFilters((prevSelectedFilters) => {
      const updatedFilters = {
        ...prevSelectedFilters,
        [dataKey]: pValue,
      };
      if (Array.isArray(pValue) && pValue.length === 0) {
        delete updatedFilters[dataKey];
      }

      return updatedFilters;
    });
  };

  const onRadioButtonToggle = (dataKey, pValue) => {
    setSelectedFilters({
      ...selectedFilters,
      [dataKey]: pValue,
    });
  };

  const onTextInputFilterValueChange = (dataKey, key, pValue) => {
    setSelectedFilters((prevSelectedFilters) => {
      const updatedFilters = {
        ...prevSelectedFilters,
        [dataKey]: {
          ...(prevSelectedFilters[dataKey] || {}),
          [key]: pValue,
        },
      };

      if (pValue === "") {
        delete updatedFilters[dataKey];
      }

      return updatedFilters;
    });
  };

  const getTabTitleDOM = (tabData = {}, tabColor = "") => {
    let filterCount = 0;

    if (tabData.type) {
      switch (tabData.type) {
        case FILTER_COMPONENT_TYPES.RADIO_BUTTON:
          {
            const dataKey = tabData.key;
            if (dataKey && selectedFilters[dataKey]) {
              filterCount = 1;
            }
          }
          break;
        case FILTER_COMPONENT_TYPES.CHECKBOX:
          {
            const dataKey = tabData.key;
            if (dataKey && selectedFilters[dataKey] && selectedFilters[dataKey].length) {
              filterCount = selectedFilters[dataKey].length;
            }
          }
          break;
        case FILTER_COMPONENT_TYPES.RANGE_FILTER:
          {
            const dataKey = tabData.key;
            if (
              dataKey &&
              (selectedFilters[dataKey]?.greater_than || selectedFilters[dataKey]?.less_than)
            ) {
              filterCount = 1;
            }
          }
          break;
        case FILTER_COMPONENT_TYPES.CONTAINS_TEXT:
          {
            const dataKey = tabData.key;

            if (dataKey && selectedFilters[dataKey]) {
              const selectedFiltersForDataKey = selectedFilters[dataKey];
              Object.keys(selectedFiltersForDataKey).forEach((key) => {
                if (selectedFiltersForDataKey[key]) {
                  filterCount++;
                }
              });
            }
          }
          break;
        case FILTER_COMPONENT_TYPES.DATE_SELECT:
        case FILTER_COMPONENT_TYPES.UTC_DATE_SELECT:
          {
            const dataKey = tabData.key;
            if (
              dataKey &&
              (selectedFilters[dataKey]?.startDate || selectedFilters[dataKey]?.endDate)
            ) {
              filterCount = 1;
            }
          }
          break;
        default:
          filterCount = getCustomComponentSelectedFiltersCount(tabData, selectedFilters) || 0;
          break;
      }
    }

    return (
      <span className="title-name-container">
        <span><Trans>{tabData.displayName || ""}</Trans></span>
        <div className="title-end">
          <span>
            {!!error?.[tabData.key] ? (
              <span className="error-icon">
                <span className="icon icon-warning-filled" />
              </span>
            ) : null}
          </span>
          <span>
            {filterCount > 0 ? (
              <span className="applied-filters-count" style={{ backgroundColor: tabColor }}>
                {`${filterCount}`}
              </span>
            ) : null}
          </span>
        </div>
      </span>
    );
  };

  const updateError = (newError) => {
    setError((err) => ({ ...err, ...newError }));
  };

  const getTabComponentDetails = (tabData = {}) => {
    if (tabData.type) {
      const componentDetails = {
        props: {
          tabKey: tabData.key,
          componentMetaData: tabData.componentMetaData,
          error,
          onError: updateError,
        },
      };

      if (tabData.isDataFetchInProgress) {
        componentDetails.Component = loaderWhite;
      } else if (tabData.errorMsg) {
        componentDetails.Component = () => <span className="error-msg">{t(tabData.errorMsg)}</span>;
      } else {
        switch (tabData.type) {
          case FILTER_COMPONENT_TYPES.RADIO_BUTTON:
            {
              componentDetails.props = {
                ...componentDetails.props,
                onChange: onRadioButtonToggle,
                value: selectedFilters[tabData.key],
                ...filterListConfig[tabData.key],
              };
              componentDetails.Component = RadioButtonFilterComponent;
            }
            break;
          case FILTER_COMPONENT_TYPES.CHECKBOX:
            {
              componentDetails.props = {
                ...componentDetails.props,
                onChange: onCheckboxToggle,
                value: selectedFilters[tabData.key] || [],
                ...filterListConfig[tabData.key],
              };
              componentDetails.Component = CheckBoxFilterComponent;
            }
            break;
          case FILTER_COMPONENT_TYPES.RANGE_FILTER:
            {
              componentDetails.props = {
                ...componentDetails.props,
                onChange: onRangeFilterValueChange,
                value: selectedFilters[tabData.key] || {},
                minInputProps: tabData?.minInputProps || {},
                maxInputProps: tabData?.maxInputProps || {},
              };
              componentDetails.Component = RangeFilterComponent;
            }
            break;
          case FILTER_COMPONENT_TYPES.CONTAINS_TEXT:
            {
              componentDetails.props = {
                ...componentDetails.props,
                onChange: onTextInputFilterValueChange,
                value: selectedFilters[tabData.key] || {},
                ...filterListConfig[tabData.key],
              };
              componentDetails.Component = TextInputFilterComponent;
            }
            break;
          case FILTER_COMPONENT_TYPES.DATE_RANGE:
            {
              componentDetails.props = {
                ...componentDetails.props,
                position: "left",
                dateFormat: DEFAULT_DATE_FORMAT,
                value: selectedFilters[tabData.key] || {},
                onChange: onDateChange,
              };
              componentDetails.Component = DateRangePicker;
            }
            break;
          case FILTER_COMPONENT_TYPES.DATE_SELECT:
            {
              componentDetails.props = {
                ...componentDetails.props,
                ...tabData,
                value: selectedFilters[tabData.key] || {},
                onChange: onDateChange,
              };
              componentDetails.Component = DateSelectComponent;
            }
            break;
          case FILTER_COMPONENT_TYPES.UTC_DATE_SELECT:
            {
              componentDetails.props = {
                ...componentDetails.props,
                ...tabData,
                value: selectedFilters[tabData.key] || {},
                onChange: onDateChange,
              };
              componentDetails.Component = UTCDateSelectComponent;
            }
            break;
          default:
            updateCustomComponentDetails(
              componentDetails,
              tabData,
              selectedFilters,
              {
                onCustomFilterValueChange,
              },
            );
            break;
        }
      }

      return componentDetails;
    }

    return null;
  };

  const getTabList = () => {
    const filterListConfigKeys = Object.keys(filterListConfig) || [];
    return filterListConfigKeys.map((key, tabIndex) => {
      const tabData = filterListConfig[key] || {};
      const tabColor = FILTER_TAB_COLORS[tabIndex % FILTER_TAB_COLORS.length];
      if (tabData && !tabData.doNotShow) {
        return {
          key,
          title: getTabTitleDOM(tabData, tabColor),
          ...(getTabComponentDetails(tabData) || {}),
        };
      }
      return null;
    });
  };

  const pillsToRender = getSelectedFiltersPills();

  const getFilterTabsDOM = () => {
    return (
      <div className="filters-tabs-wrap">
        <Tabs tabs={getTabList()} wrapperClassName="tabs-container" display="default" renderIfNotActive />
        <div className="btn-container clearfix">
          {showClearAllButton && (
            <Button disabled={!pillsToRender.length} className="pendo_feature_global_filter_clear_all" type="primary" link onClick={onClearAllFilters}>
              Clear All
            </Button>
          )}
          <Button className="pendo_feature_global_filter_apply" onClick={onApplyFilters}>Apply</Button>
          {showResetButton && (
            <Button type="primary" link onClick={onResetFilters}>
              Reset
            </Button>
          )}
        </div>
      </div>
    );
  };

  const getMobileFilterTabsDOM = () => {
    return (
      <>
        <FixLayout.Header enableBoxShadow={true}>
          <Trans>{filterTitle}</Trans>
        </FixLayout.Header>
        <FixLayout.Body>
          <div className="available-filters">
            <div className="filters-tabs-wrap">
              <Tabs tabs={getTabList()} wrapperClassName="tabs-container" display="default" />
            </div>
          </div>
        </FixLayout.Body>
        <FixLayout.Footer enableBoxShadow={true}>
          <div className="available-filters">
            <div className="btn-container clearfix">
              <Button onClick={onApplyFilters}>Apply</Button>
              {showResetButton && (
                <Button type="primary" link onClick={onResetFilters}>
                  Reset
                </Button>
              )}
              {showClearAllButton && (
                <Button type="primary" disabled={!pillsToRender.length} link onClick={onClearAllFilters}>
                  Clear All
                </Button>
              )}
            </div>
          </div>
        </FixLayout.Footer>
      </>
    );
  };

  return showFilterInDrawer ? (
    <FixLayout className="filters-container">{getMobileFilterTabsDOM()}</FixLayout>
  ) : (
    <div className={`filters-container ${filterContainerClassName || ""}`} ref={wrapperRef}>
      <div className="selected-filters-wrap">
        <div className="selected-filters">
          {pillsToRender.length ? (
            pillsToRender
          ) : (
            <span className="no-filters-selected">
              <Trans>{noFilterMessage}</Trans>
            </span>
          )}
        </div>
      </div>
      <div className="available-filters">{getFilterTabsDOM()}</div>
    </div>
  );
};

Filter.defaultProps = {
  filterListConfig: {},
  value: {},
  onFilterValueChange: () => {},
  onJobDone: () => {},
  resetConfirmationModal: () => {},
  showConfirmationModal: () => {},
  noFilterMessage: "No Filters Selected",
  showResetButton: false,
  showClearAllButton: true,
  getCustomComponentSelectedFiltersPills: () => {},
  getCustomComponentSelectedFiltersCount: () => {},
  updateCustomComponentDetails: () => {},
};

export default OSHOCWithUtilities(Filter);
