import React from "react";
import ReactDOM from "react-dom";

import { loaderBlack } from "./Loader";

import "./overlayLoader.less";

const OverlayLoader = ({ withPortal, className, containerRef, inline }) => {
  const renderLoader = () => {
    return (
      <section className={`overlay-loader-window-section ${className} ${inline ? "inline" : ""}`}>
        <div className="overlay-loader-window-div">
          <div className="overlay-loader-window-background-overlay" />
          <div className="overlay-loader-window-wrapper">{loaderBlack()}</div>
        </div>
      </section>
    );
  };
  return withPortal
    ? ReactDOM.createPortal(renderLoader(), containerRef?.current || document.body)
    : renderLoader();
};

OverlayLoader.defaultProps = {
  withPortal: true,
  inline: false,
  className: "",
};

export default OverlayLoader;
