import { removeLSItem } from "@onlinesales-ai/util-methods-v2";
import Types from "./types";
import AppActionTypes from "../application/types";

const initialState = {
  clientsBillingPackageDetails: {
    isPackageFetchInProgress: false,
    packageDetails: {},
    clientStatus: {},
    pendingOrderDetails: {},
    cartStatus: [],
    userPendingTransactions: {},
    tdsAmountData: {},
    lowWalletBalanceCount: 0,
  },
  billingHygieneData: {}, // ONLY used in hygiene
  clientAppStatus: {
    ACTIVE: [],
    INACTIVE: [],
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AppActionTypes.APP_LOG_OUT:
    case AppActionTypes.APP_CLIENT_CHANGE:
      {
        removeLSItem("cartStatus");
        state = {
          ...initialState,
          clientsBillingPackageDetails: {
            ...initialState.clientsBillingPackageDetails,
            lowWalletBalanceCount: state?.clientsBillingPackageDetails?.lowWalletBalanceCount,
          },
        };
      }
      break;
    case Types.BILLING_UPDATE_CLIENT_STATUS:
      {
        const clientStatusKeys = Object.keys(action?.clientStatus);
        let count = 0;

        clientStatusKeys.forEach((x) => {
          if (
            action?.clientStatus[x]?.status === "ACTIVE" &&
            action?.clientStatus[x]?.billingInfo?.balance <= action?.lowBalanceAmount
          ) {
            count++;
          }
        });

        state = {
          ...state,
          clientsBillingPackageDetails: {
            ...state.clientsBillingPackageDetails,
            clientStatus: {
              ...(state.clientsBillingPackageDetails.clientStatus || {}),
              ...(action.clientStatus || {}),
            },
            lowWalletBalanceCount: count,
          },
        };
      }
      break;
    case Types.BILLING_UPDATE_CLIENT_APP_STATUS:
      {
        state = {
          ...state,
          clientAppStatus: Object.values(action.clientStatus || {}).reduce((memo, data) => {
            if (data.status === "ACTIVE") {
              memo.ACTIVE.push(data.clientId);
            } else {
              memo.INACTIVE.push(data.clientId);
            }
            return memo;
          }, {
            ACTIVE: [],
            INACTIVE: [],
          }),
        };
      }
      break;
    case Types.BILLING_UPDATE_PENDING_ORDERS:
      {
        state = {
          ...state,
          clientsBillingPackageDetails: {
            ...state.clientsBillingPackageDetails,
            pendingOrderDetails: {
              ...(action.pendingOrderDetails || {}),
            },
          },
        };
      }
      break;
    case Types.BILLING_SET_PLAN_DETAILS_FETCH_IN_PROGRESS:
      {
        state = {
          ...state,
          clientsBillingPackageDetails: {
            ...state.clientsBillingPackageDetails,
            isPackageFetchInProgress: !!action.isFetchInProgress,
          },
        };
      }
      break;
    case Types.BILLING_SET_PLAN_DETAILS:
      {
        state = {
          ...state,
          clientsBillingPackageDetails: {
            ...state.clientsBillingPackageDetails,
            packageDetails: action.packageDetails || {},
          },
        };
      }
      break;
    case Types.BILLING_UPDATE_CART_STATUS:
      {
        const shouldOverride = action.shouldOverride;
        if (shouldOverride) {
          state = {
            ...state,
            clientsBillingPackageDetails: {
              ...state.clientsBillingPackageDetails,
              cartStatus: [...action.cartStatus],
              tdsAmountData:
                action?.cartStatus?.find?.((c) => !!c?.forUI?.tdsAmountData)?.forUI
                  ?.tdsAmountData || {},
            },
          };
        }
      }
      break;
    case Types.BILLING_UPDATE_USER_PENDING_TRANSACTIONS:
      {
        state = {
          ...state,
          clientsBillingPackageDetails: {
            ...state.clientsBillingPackageDetails,
            userPendingTransactions: {
              ...(action.transactions || {}),
            },
          },
        };
      }
      break;
    case Types.APP_SET_BILLING_HYGIENE_DATA:
      {
        state = {
          ...state,
          billingHygieneData: {
            ...state.billingHygieneData,
            ...action?.data?.data?.data?.[0],
          },
        };
      }
      break;
    default:
      break;
  }

  return state;
};

export default {
  Billing: reducer,
};
