import { ShopsUIService } from "@onlinesales-ai/services-v2";
import { routes } from "src/utilities/constants";
import { setBillingKeyToUse, setAgreementPropertyData } from "@onlinesales-ai/app-v2/application";

const hyundaiBillingHandler = async ({
  billingDetails = [],
  getState,
  dispatch,
  redirectUrl = () => {},
}) => {
  if (!billingDetails[0]?.sellerTaggingData?.dealerCode) {
    return Promise.resolve();
  }

  const state = getState();
  const { agencyId } = state.DomainConfig;
  const { userInfo } = state.Application;

  try {
    const payload = {
      requestType: "GetAllProperties",
      propertyTypeFilter: ["DEALER_AGREEMENT_STATUS"],
      isActive: true,
      entityDetails: [
        {
          entityType: "DEALER",
          entityValue: billingDetails[0]?.sellerTaggingData?.dealerCode,
        },
      ],
      userId: userInfo?.id,
      clientId: billingDetails[0]?.clientId,
      agencyId,
      application: "shopsUIServices",
    };

    if (userInfo?.id) {
      const response = await ShopsUIService.getPropertySettings(payload, "application");
      const parsedResponse = JSON.parse(response);

      let isAgreementSigned = false;
      let usersAllowedToSign = [];
      if (parsedResponse?.propertiesHierarchies?.[0]?.properties?.length) {
        const properties = parsedResponse?.propertiesHierarchies?.[0]?.properties;
        let activePropertyValue = null;
        for (let i = 0; i < properties.length; i++) {
          if (properties[i]?.isActive) {
            activePropertyValue = JSON.parse(properties[i].propertyDetails?.propertyValue || "{}");
            dispatch(setAgreementPropertyData(properties[i]));
            break;
          }
        }

        isAgreementSigned = !!activePropertyValue?.isSigned;
        usersAllowedToSign = activePropertyValue?.allowedUserIds || [];
      }

      if (!isAgreementSigned) {
        let billingKeyToUse = null;
        if (usersAllowedToSign.includes(userInfo.id)) {
          billingKeyToUse = "askAgreement";
        } else {
          billingKeyToUse = "agreementNotSigned";
        }

        dispatch(setBillingKeyToUse(billingKeyToUse));
      }
    }
    return Promise.resolve();
  } catch (e) {
    dispatch(redirectUrl(routes.DOWNTIME.path));
  }
};

export const billingExtraHandler = ({ getState, ...rest }) => {
  const state = getState();
  const { agencyId } = state.DomainConfig;
  if (agencyId === 152) {
    return hyundaiBillingHandler({ getState, ...rest });
  }
  return Promise.resolve();
};
