export default {
  OMS_SET_CAMPAIGN_TEMPLATE_DATA: "OMS_SET_CAMPAIGN_TEMPLATE_DATA",
  OMS_UPDATE_CAMPAIGN_TEMPLATE_DATA: "OMS_UPDATE_CAMPAIGN_TEMPLATE_DATA",
  OMS_SET_PACKAGE_DATA: "OMS_SET_PACKAGE_DATA",
  OMS_UPDATE_PACKAGE_DATA: "OMS_UPDATE_PACKAGE_DATA",
  OMS_SET_BOOKING_DATA: "OMS_SET_BOOKING_DATA",
  OMS_UPDATE_BOOKING_DATA: "OMS_UPDATE_BOOKING_DATA",
  OMS_SET_BOOKING_REVIEW: "OMS_SET_BOOKING_REVIEW",
  OMS_UPDATE_BOOKING_REVIEW: "OMS_UPDATE_BOOKING_REVIEW",
  OMS_SET_BOOKING_REVIEW_SORTED_IDS: "OMS_SET_BOOKING_REVIEW_SORTED_IDS",
  OMS_UPDATE_BOOKING_REVIEW_SORTED_IDS: "OMS_UPDATE_BOOKING_REVIEW_SORTED_IDS",
  OMS_SET_BOOKING_CREATIVE: "OMS_SET_BOOKING_CREATIVE",
  OMS_UPDATE_BOOKING_CREATIVE: "OMS_UPDATE_BOOKING_CREATIVE",
  OMS_SET_AND_REPLACE_BOOKING_CREATIVE: "OMS_SET_AND_REPLACE_BOOKING_CREATIVE",
};
