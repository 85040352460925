export default {
  SET_GOAL_LOADING_STATUS: "SET_GOAL_LOADING_STATUS",
  SET_GOAL_DATA: "SET_GOAL_DATA",
  UPDATE_GOALS_LIST_DATA: "UPDATE_GOALS_LIST_DATA",
  SET_GOAL_LAUNCHER_ID: "SET_GOAL_LAUNCHER_ID",
  SET_GOALS_CANDY_LOADING_STATUS: "SET_GOALS_CANDY_LOADING_STATUS",
  SET_GOALS_LIST_DATA: "SET_GOALS_LIST_DATA",
  UPDATE_REPORTING_DATA_FOR_X_DAYS: "UPDATE_REPORTING_DATA_FOR_X_DAYS",
  SET_GOAL_SKU_SELECTION_DATA: "SET_GOAL_SKU_SELECTION_DATA",
  SET_TOTAL_SKU_DATA: "SET_TOTAL_SKU_DATA",
};
