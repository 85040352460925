/* eslint-disable camelcase */
import React from "react";
import { connect } from "react-redux";
import { OSBillingServiceV2, OSBillingService, BaseClient } from "@onlinesales-ai/services-v2";
import PlatformEventManager from "@onlinesales-ai/event-manager-v2";
import { populateEvent, loadFlutterwavePaymentScript } from "@onlinesales-ai/util-methods-v2";

function fireIntercomEvents(action, metaData) {
  populateEvent("APP", `PAYMENT||FLUTTERWAVE_PAYMENT||${action}`, metaData);
}

class FlutterwavePayment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fetchOrderIdLoading: false,
    };

    this.orderData = null;
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (
      newProps.amount !== this.props.amount ||
      newProps.currency !== this.props.currency ||
      newProps.userInfo?.email !== this.props.userInfo?.email
    ) {
      this.orderData = null;
    }
  }

  async componentDidMount() {
    const { onError } = this.props;

    try {
      await loadFlutterwavePaymentScript();
      fireIntercomEvents("CHECKOUT_LOADED");
    } catch (err) {
      fireIntercomEvents("CHECKOUT_LOAD_ERROR");
      onError(err);
    }
  }

  updateTransactionId = ({
    transactionId, chargeId,
  } = {}) => {
    const { clientId } = this.props;
    if (transactionId || chargeId) {
      return OSBillingService.saveChargeV2({
        "@class": "com.sokrati.osBillingServiceObjects.requests.PostChargeV2ByExternalRequest",
        "metadata": {
          "flutterwave_payment_id": transactionId, // flutterwave transaction id
        },
        clientId,
        "id": chargeId, // flutter wave chargeId
      });
    }

    return Promise.reject({ errorMsg: BaseClient.getErrorMessage() });
  }

  wait = (time) => {
    return new Promise((resolve) => {
      setTimeout(resolve, time);
    });
  };

  checkIfTransactionIsAccepted = async (transactionId) => {
    if (!transactionId) {
      return Promise.reject({ errorMsg: BaseClient.getErrorMessage() });
    }

    const { clientId } = this.props;
    return new Promise(async (resolve, reject) => {
      try {
        let isAccepted = false;
        let retryCount = 10;

        while (true) {
          const statusResponse = await OSBillingService.fetchInfo({
            "selectors": [
              "transactionId",
              "status",
            ],
            "entityLevel": "CLIENT_CREDIT_LOG",
            "filters": [
              {
                "column": "clientId",
                "value": `${clientId}`,
                "operator": "EQUAL_TO",
              },
              {
                "column": "transactionId",
                "value": encodeURIComponent(transactionId),
                "operator": "EQUAL_TO",
              },
            ],
            "clientId": clientId,
          });

          if (statusResponse?.data?.[0]?.status) {
            if (statusResponse?.data?.[0]?.status === "ACCEPTED") {
              isAccepted = true;
            } else if (statusResponse?.data?.[0]?.status !== "PENDING") {
              retryCount = 0;
            }
          }

          retryCount--;

          if (isAccepted) {
            resolve();
            break;
          } else if (retryCount <= 0) {
            reject();
            break;
          } else {
            await this.wait(1000);
          }
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  fetchOrderId = async () => {
    const { forceNewOrderId } = this.props;
    if (!forceNewOrderId && this.orderData) {
      return Promise.resolve(this.orderData);
    }

    const {
      clientId, userInfo, currency, amount, fireEventOnOrderId, useDummyEmail,
      chargeAPICall, subAccountId, financeChargeAPICall,
    } = this.props;

    this.setState({
      fetchOrderIdLoading: true,
    });

    let userEmail = userInfo.email;

    if (!userEmail && useDummyEmail && userInfo.contact) {
      userEmail = `${userInfo.contact}@onlinesales.ai`;
    }
    let response = {};

    if (financeChargeAPICall) {
      await financeChargeAPICall({
        paymentOverrides: {
          metadata: {
            subaccounts: [
              {
                id: subAccountId,
                transaction_charge_type: "percentage",
                transaction_charge: "0.0181",
              },
            ],
          },
        },
      });
    } else if (chargeAPICall) {
      response = await chargeAPICall(amount, {});
    } else {
      response = await OSBillingServiceV2.charge({
        clientId,
        email: userEmail,
        platform: "OS",
        amount,
        currency,
        metadata: {
          subaccounts: [
            {
              id: subAccountId,
              transaction_charge_type: "percentage",
              transaction_charge: "0.0181",
            },
          ],
        },
      });
    }

    const {
      flutterwave_order_id, flutterwave_api_key, transactionId, chargeId,
      platform,
    } = response?.metadata || {};

    if ((!flutterwave_order_id || !flutterwave_api_key || !chargeId) && !response?.doNotInitPG) {
      throw Error("Flutterwave payment not supported please contact support.");
    }

    this.orderData = {
      orderId: flutterwave_order_id,
      apiKey: flutterwave_api_key,
      transactionId,
      chargeId,
      platform,
      amount: response?.amount,
      doNotInitPG: response.doNotInitPG || false,
    };

    this.setState({
      fetchOrderIdLoading: false,
    });

    fireIntercomEvents("ORDER_CREATED", response);

    if (fireEventOnOrderId && fireEventOnOrderId.length) {
      fireEventOnOrderId.forEach((event) => {
        PlatformEventManager.emit(event);
      });
    }

    return this.orderData;
  };

  closePaymentModal = () => {
    const checkoutIframes = document.querySelectorAll("iframe[name=checkout]");
    checkoutIframes?.forEach((iframeNode) => {
      iframeNode?.setAttribute("style",
        "position:fixed;top:0;left:0;z-index:-1;border:none;opacity:0;pointer-events:none;width:100%;height:100%;");
    });
    document.body.style.overflow = "";
  };

  openPaymentModal = async () => {
    const {
      name,
      description,
      image,
      currency,
      amount,
      userInfo = {},
      onPaymentSuccess,
      onError,
      beforePayment,
      fireEventOnPaymentSuccess,
      onDismissCallback,
      clientId,
      subAccountId,
    } = this.props;

    try {
      await beforePayment();
    } catch (err) {
      return;
    }

    try {
      const orderDetails = await this.fetchOrderId();
      const options = {
        public_key: orderDetails.apiKey,
        tx_ref: orderDetails.orderId,
        amount: orderDetails.amount,
        currency,
        payment_options: " ",
        customer: {
          email: userInfo.email,
          phone_number: userInfo.contact,
          name: userInfo.name,
        },
        subaccounts: [
          {
            id: subAccountId,
            transaction_charge_type: "percentage",
            transaction_charge: "0.0181",
          },
        ],
        callback: async (response) => {
          try {
            await this.updateTransactionId({
              chargeId: orderDetails?.chargeId,
              transactionId: response?.transaction_id,
            });
            await this.checkIfTransactionIsAccepted(orderDetails?.transactionId);
          } catch (err) {
            this.setState({
              fetchOrderIdLoading: false,
            });
            onError(err?.errorMsg);
            return;
          }

          // reset order data after successfull payment
          this.orderData = null;
          fireIntercomEvents("PAYMENT_SUCCESS", JSON.stringify(orderDetails));
          if (fireEventOnPaymentSuccess && fireEventOnPaymentSuccess.length) {
            fireEventOnPaymentSuccess.forEach((event) => {
              PlatformEventManager.emit(event);
            });
          }

          this.closePaymentModal();
          onPaymentSuccess(response);
        },
        onclose: () => {
          if (fireEventOnPaymentSuccess && fireEventOnPaymentSuccess.length) {
            fireEventOnPaymentSuccess.forEach((event) => {
              PlatformEventManager.emit(event);
            });
          }
        },
        customizations: {
          title: name,
          description,
          logo: "https://osads.gumlet.io/image/upload/v1629805935/product/logos/flutterwave_logo.png",
        },
        meta: {
          ...orderDetails,
          clientId,
        },
      };

      if (!orderDetails?.doNotInitPG) {
        window.FlutterwaveCheckout(options);
        fireIntercomEvents("MODAL_OPEN", JSON.stringify(options));
      } else {
        fireIntercomEvents("PAYMENT_SUCCESS", JSON.stringify(orderDetails));
        if (fireEventOnPaymentSuccess && fireEventOnPaymentSuccess.length) {
          fireEventOnPaymentSuccess.forEach((event) => {
            PlatformEventManager.emit(event);
          });
        }
        onPaymentSuccess(orderDetails);
      }
    } catch (err) {
      this.setState({
        fetchOrderIdLoading: false,
      });
      onError(err);
    }
  };

  render() {
    const { children } = this.props;
    const { fetchOrderIdLoading } = this.state;

    if (typeof children === "function") {
      return children({
        openPayment: this.openPaymentModal,
        isLoading: fetchOrderIdLoading,
      });
    }

    return null;
  }
}

const mapStateToProps = (state, ownProps) => {
  const { contactNo, email, name } = state.Application?.userInfo || {};
  const userInfo = {
    name,
    email,
    contact: contactNo,
  };

  return {
    clientId: ownProps.clientId || state.Application.clientId,
    userInfo: ownProps.userInfo || userInfo,
  };
};

const FlutterwavePaymentWrapper = connect(mapStateToProps)(FlutterwavePayment);

FlutterwavePaymentWrapper.defaultProps = {
  beforePayment: () => Promise.resolve(),
  fireEventOnOrderId: [],
  subAccountId: "RS_48CCAB1414C51E1DC6BA64F05794FFE5",
  fireEventOnPaymentSuccess: [],
  currency: "NGN",
  name: "Flutterwave",
  description: "",
  image: "https://osads.gumlet.io/image/upload/logos/logo_without_text_250x250.png",
  onDismissCallback: () => { },
  forceNewOrderId: false,
};

export default FlutterwavePaymentWrapper;
