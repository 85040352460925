export const isChunkError = (error) => {
  try {
    return !!(error || "")
      ?.toString?.()
      ?.trim?.()
      ?.substring?.(0, 200)
      ?.match?.(/Loading chunk [\d]+ failed|Loading CSS chunk [\d]+ failed/i);
  } catch (err) {
    return false;
  }
};
