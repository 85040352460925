class AppStore {
  static setStore(store) {
    AppStore.store = store;
  }

  static getStore() {
    return AppStore.store;
  }

  static getState() {
    return AppStore.store ? AppStore.store.getState() : {};
  }
}

export default AppStore;
