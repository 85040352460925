import React from "react";

import { Drawer } from "@onlinesales-ai/drawer-v2";

import MediaLibrary from "./index";

const MediaLibraryDrawer = ({ isOpen = true, containerClass = "", onClose, size, ...props }) => {
  return (
    <Drawer
      isOpen={isOpen}
      onClickClose={onClose}
      size={size}
      containerClass={`mediaLibrary-drawer-container ${containerClass}`}
    >
      <MediaLibrary {...props} />
    </Drawer>
  );
};

export default MediaLibraryDrawer;
