import React, { useMemo } from "react";

import { startOfDay, endOfDay } from "date-fns/esm";
import {
  convertBrowserToClientTimezoneWithSameDateTime,
  getUTCToZonedDate,
} from "@onlinesales-ai/util-methods-v2";

import DateRangePicker from "./DateRangePicker";

const timeConvert = {
  startOfDay,
  endOfDay,
};

const UTCDateRangePicker = ({
  onChange,
  startAndEndOfDay,
  startDate: pStartDate,
  endDate: pEndDate,
  compareStartDate: pCompareStartDate,
  compareEndDate: pCompareEndDate,
  datePickerProps: pDatePickerProps,
  ...props
}) => {
  const startDate = useMemo(() => {
    if (pStartDate) {
      return getUTCToZonedDate(pStartDate);
    }

    return pStartDate;
  }, [pStartDate]);

  const endDate = useMemo(() => {
    if (pEndDate) {
      return getUTCToZonedDate(pEndDate);
    }

    return pEndDate;
  }, [pEndDate]);

  const compareStartDate = useMemo(() => {
    if (pCompareStartDate) {
      return getUTCToZonedDate(pCompareStartDate);
    }

    return pCompareStartDate;
  }, [pCompareStartDate]);

  const compareEndDate = useMemo(() => {
    if (pCompareEndDate) {
      return getUTCToZonedDate(pCompareEndDate);
    }

    return pCompareEndDate;
  }, [pCompareEndDate]);

  const datePickerProps = useMemo(() => {
    const newData = { ...(pDatePickerProps || {}) };

    if (newData.minDate) {
      newData.minDate = getUTCToZonedDate(newData.minDate);
    }

    if (newData.maxDate) {
      newData.maxDate = getUTCToZonedDate(newData.maxDate);
    }

    return newData;
  }, [pDatePickerProps]);

  const convertSetDate = (date, { convertDate }) => {
    let newData = null;

    if (startAndEndOfDay && convertDate) {
      newData = convertBrowserToClientTimezoneWithSameDateTime(timeConvert[convertDate](date));
    } else {
      newData = convertBrowserToClientTimezoneWithSameDateTime(date);
    }

    if (startAndEndOfDay && convertDate === "endOfDay") {
      return newData.valueOf() - 999;
    }

    return newData.valueOf();
  };

  const convertLocalToUTCDate = (date) => {
    const dataToChange = {};

    if (date.startDate) {
      dataToChange.startDate = convertSetDate(date.startDate, { convertDate: "startOfDay" });
    }

    if (date.endDate) {
      dataToChange.endDate = convertSetDate(date.endDate, { convertDate: "endOfDay" });
    }

    if (date.compareStartDate) {
      dataToChange.compareStartDate = convertSetDate(date.compareStartDate, {
        convertDate: "startOfDay",
      });
    }

    if (date.compareEndDate) {
      dataToChange.compareEndDate = convertSetDate(date.compareEndDate, {
        convertDate: "endOfDay",
      });
    }

    return dataToChange;
  };

  const currentDate = useMemo(() => {
    return getUTCToZonedDate(new Date());
  }, []);

  return (
    <DateRangePicker
      startDate={startDate}
      endDate={endDate}
      compareStartDate={compareStartDate}
      compareEndDate={compareEndDate}
      onChange={(date) => onChange(convertLocalToUTCDate(date))}
      datePickerProps={datePickerProps}
      currentDate={currentDate}
      {...props}
    />
  );
};

export default UTCDateRangePicker;
