import React, { useState, useMemo, useEffect, useRef, Children, useContext } from "react";
import { Trans, useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _isEmpty from "lodash/isEmpty";
import _uniq from "lodash/uniq";

import { components, Dropdown as DropdownVirtualized } from "@onlinesales-ai/dropdown-v2";
import AsyncImage from "@onlinesales-ai/async-image-v2";
import { Input } from "@onlinesales-ai/input-v2";
// import { Button } from "@onlinesales-ai/button-v2";
import { useDebounce } from "@onlinesales-ai/util-methods-v2";

import { loaderWhite } from "@onlinesales-ai/loader-v2";
// import { GlobalContext } from "@onlinesales-ai/utils-components-v2";
// import WithTooltip from "@onlinesales-ai/tooltip-v2";

import {
  changeEntityId as changeEntityIdAction,
  fetchEntitiesData as fetchEntitiesDataAction,
  onFilterEntityBySearchText as onFilterEntityBySearchTextAction,
} from "../../store/entityApplication";
import {
  getEntityInfo,
  parseProcessedEntityId,
  entityTypeEnum,
  // entityTypeBusineessNameEnum,
  // getEntityMetadata,
  // businessDefinitionEnum,
} from "../utilities";

// import NewEntityCreation from "../newEntityCreation";

import "./index.less";

const NON_ACTIONING_ITEM = {
  DUMMY_LOADING: "DUMMY_LOADING",
  DUMMY_SEARCH: "DUMMY_SEARCH",
  DUMMY_EMPTY_STATE: "DUMMY_EMPTY_STATE",
  ADD_NEW_BRAND: "ADD_NEW_BRAND",
};

const SEARCH_FETCH_LIMIT = 1000;

const CustomMenuList = ({ selectProps, ...props }) => {
  const { t } = useTranslation();

  const {
    onMenuInputFocus,
    searchModeState,
    onSearchTextChange,
    rootEntityId,
    agencyId,
    currentListEntityId,
    currentEntityBreadCrumbs,
    onSearchIconClick,
    onBreadCrumbClick,
    isEnableSearchModeOnly,
    refSearchInput,
    rootPlaceholder,
    // isAllowNewEntityCreation,
  } = selectProps;

  // Copied from source
  const ariaAttributes = {
    "aria-autocomplete": "list",
    "aria-label": selectProps["aria-label"],
    "aria-labelledby": selectProps["aria-labelledby"],
  };

  let breadCrumbToUse = [...currentEntityBreadCrumbs];
  if (rootEntityId === `${entityTypeEnum.AGENCY}_${agencyId}`) {
    breadCrumbToUse = [
      {
        entityId: rootEntityId,
        entityName: rootPlaceholder,
      },
      ...breadCrumbToUse,
    ];
  }

  return (
    // <div className={`hriday-menu ${isAllowNewEntityCreation ? "allow-entity-creation" : ""}`}>
    <div>
      <div className="entity-search-and-navigator">
        {searchModeState.isEnabled && (
          <Input
            autoCorrect="off"
            autoComplete="off"
            spellCheck="false"
            type="text"
            placeholder={t("Search")}
            ref={refSearchInput}
            inputWrapperClassName="border-padding entity-search-input"
            value={searchModeState.searchText}
            onChange={onSearchTextChange}
            trimOnBlur={false}
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
            onKeyUp={(e) => {
              e.stopPropagation();
            }}
            onTouchEnd={(e) => {
              e.stopPropagation();
              e.target.focus();
            }}
            onClick={(e) => {
              e.stopPropagation();
              e.target.focus();
              onMenuInputFocus();
            }}
            {...ariaAttributes}
          />
        )}
        {breadCrumbToUse.length > 0 && !searchModeState.isEnabled && (
          <div className="left menu-breadcrumb-navigation">
            {breadCrumbToUse?.map((item) => {
              return (
                <div className="menu-breadcrumb-item">
                  {item.entityId === currentListEntityId ? (
                    <span className="menu-label">{item.entityName}</span>
                  ) : (
                    <>
                      <a
                        href="javascript:void(0)"
                        className="menu-label"
                        onClick={(e) => onBreadCrumbClick(e, item.entityId)}
                      >
                        {item.entityName}
                      </a>
                      <span className="icon icon-angle-right ml-2 mr-2" />
                    </>
                  )}
                </div>
              );
            })}
          </div>
        )}
        <div className="right" onClick={onSearchIconClick}>
          <div className="main-search-icon">
            <span
              className={`icon ${
                !isEnableSearchModeOnly && searchModeState.isEnabled
                  ? "icon-close1"
                  : "icon-search-2"
              }`}
            />
          </div>
        </div>
      </div>
      <components.MenuList {...props} selectProps={selectProps} />
    </div>
  );
};

const MultiLevelDropdown = ({
  isDarkMode,
  selectedEntityId,
  selectedEntityType,
  entityInfo,
  // entityMetadata,
  entitiesFetchState,
  entitiesFetchInProgress,
  onEntityChange,
  fetchEntitiesData,
  onFilterEntityBySearchText,
  rootEntityId,
  agencyId,
  onChangeHandler,
  selectableEntityType,
  isEnableSearchModeOnly,
  isEditable,
  // isAllowNewEntityCreation,
  showEntityType,
  onOptionLoading,
  dropdownContainerClassName,
  placeholder,
  entityBusineessDefinitionConfig,
  // isShowBreadCrumbExpandForAdvertiser,
  rootPlaceholder,
}) => {
  const { t } = useTranslation();
  // const { redirectUrl } = useContext(GlobalContext);
  const timestampRef = useRef(new Date().valueOf());
  const refSearchInput = useRef(null);
  const containerRef = useRef(null);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [isFetchComplete, setIsFetchComplete] = useState(false);
  const [searchModeState, setSearchModeState] = useState(() => ({
    isEnabled: isEnableSearchModeOnly,
    searchText: "",
    resultEntityIds: [],
    offset: 0,
    isAllFetched: false,
    isFetchInProgress: false,
    fetchCounter: 0,
  }));
  // const [isShowNewEntityCreationDrawer, setIsShowNewEntityCreationDrawer] = useState(false);
  const searchTerm = useDebounce(searchModeState.searchText, 500);

  const onDomClick = (e) => {
    const menu = containerRef?.current?.querySelector(".Select__menu-portal");

    if ((!menu || (!menu.contains(e.target))) && (containerRef?.current?.contains(e.target))) {
      setIsSearchFocused((prev) => !prev);
    } else if (!containerRef?.current?.contains(e.target)) {
      setIsSearchFocused(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", onDomClick);

    return () => {
      document.removeEventListener("mousedown", onDomClick);
    };
  }, []);

  const getBreadCrumbs = (entityId) => {
    let currentId = entityId;
    let breadCrumbItems = [];

    while (true) {
      const currentItem = getEntityInfo(entityInfo, currentId);
      if (currentItem.entityId) {
        breadCrumbItems = [
          {
            entityId: currentItem.entityId,
            entityType: currentItem.entityType,
            entityName: currentItem.entityName,
          },
          ...breadCrumbItems,
        ];

        if (!currentItem.parentMCCId) {
          break;
        }

        currentId = `${entityTypeEnum.MCC}_${currentItem.parentMCCId}`;
      } else {
        break;
      }
    }

    return breadCrumbItems;
  };

  const selectedEntityInfo = useMemo(() => {
    if (!selectedEntityType || !selectedEntityId) {
      return {};
    }

    const storedInfo = getEntityInfo(entityInfo, `${selectedEntityType}_${selectedEntityId}`);

    const breadCrumbItems = getBreadCrumbs(`${selectedEntityType}_${selectedEntityId}`);

    storedInfo.breadCrumbText = breadCrumbItems.map((i) => i.entityName).join(" > ");

    return storedInfo;
  }, [selectedEntityId, selectedEntityType, entityInfo]);

  const [currentListEntityId, setCurrentListEntityId] = useState(null);

  useEffect(() => {
    const id = selectedEntityInfo?.parentMCCId
      ? `${entityTypeEnum.MCC}_${selectedEntityInfo?.parentMCCId}`
      : rootEntityId;
    if (
      !entitiesFetchState[id]?.offset &&
      !entitiesFetchState[id]?.isAllFetched &&
      !entitiesFetchState[id]?.isFetchInProgress
    ) {
      const entityDetails = parseProcessedEntityId(id) || {};
      const mccId = entityDetails.entityType === entityTypeEnum.MCC ? entityDetails.entityId : null;
      fetchEntitiesData(mccId);
    }

    setCurrentListEntityId(id);
  }, [selectedEntityInfo?.parentMCCId, rootEntityId]);

  useEffect(() => {
    if (searchModeState.isEnabled && (isEnableSearchModeOnly || !!searchTerm)) {
      setSearchModeState((currentVal) => ({
        ...currentVal,
        offset: 0,
        resultEntityIds: [],
        isAllFetched: false,
        isFetchInProgress: true,
        fetchCounter: (searchModeState?.fetchCounter || 0) + 1,
      }));
    }
  }, [searchModeState.isEnabled, searchTerm]);

  const fetchSearchResults = async () => {
    try {
      setSearchModeState((currentVal) => ({
        ...currentVal,
        isFetchInProgress: true,
      }));

      let mccId = null;
      if (rootEntityId !== `${entityTypeEnum.AGENCY}_${agencyId}`) {
        mccId = parseProcessedEntityId(rootEntityId).entityId;
      }

      const searchResultIds = await onFilterEntityBySearchText(
        {
          agencyId,
          mccId,
          searchQuery: encodeURIComponent(searchModeState.searchText),
          offset: searchModeState.offset,
          entityType: selectableEntityType,
          limit: SEARCH_FETCH_LIMIT,
          selectors: [
            "entityId",
            "entityName",
            "entityAlias",
            "entityType",
            "statusType",
            "depth",
            "hierarchy.entityId",
            "hierarchy.entityType",
            "hierarchy.entityName",
            "hierarchy.entityAlias",
            "metadata.businessDefinition",
          ],
        },
        {
          abortId: `entity_search_result_${timestampRef.current}`,
        },
      );

      setSearchModeState((currentVal) => ({
        ...currentVal,
        resultEntityIds: _uniq([...(currentVal.resultEntityIds || []), ...searchResultIds]),
        isFetchInProgress: false,
        isAllFetched: searchResultIds?.length !== SEARCH_FETCH_LIMIT,
      }));
    } catch (e) {
      // Not thrown from action
    }
    setIsFetchComplete(true);
  };

  useEffect(() => {
    if (searchModeState.fetchCounter > 0) {
      fetchSearchResults();
    }
  }, [searchModeState.fetchCounter]);

  const currentEntityFetchState = useMemo(() => {
    if (currentListEntityId) {
      return entitiesFetchState[currentListEntityId] || {};
    }

    return {};
  }, [currentListEntityId, entitiesFetchState]);

  const currentEntityBreadCrumbs = useMemo(() => {
    return getBreadCrumbs(currentListEntityId);
  }, [currentListEntityId, entityInfo]);

  // const currentEntityBreadCrumbText = useMemo(() => {
  //   return currentEntityBreadCrumbs.map((i) => i.entityName).join(" > ");
  // }, [currentEntityBreadCrumbs]);

  const entityOptions = useMemo(() => {
    const entityIds = searchModeState.isEnabled
      ? searchModeState.resultEntityIds || []
      : currentEntityFetchState?.children || [];
    return entityIds.map((id) => {
      const currentEntityInfo = getEntityInfo(entityInfo, id);
      // const currentEntityMetadata = getEntityMetadata(entityMetadata, id);

      currentEntityInfo.hierarchy = getBreadCrumbs(currentEntityInfo.entityId);

      return {
        label: currentEntityInfo.entityName,
        value: id,
        entityType: currentEntityInfo.entityType,
        hierarchy: currentEntityInfo.hierarchy,
        businessDefinition: currentEntityInfo?.metadata?.businessDefinition || "CLIENT",
      };
    });
  }, [
    currentEntityFetchState.children,
    searchModeState.resultEntityIds,
    searchModeState.isEnabled,
  ]);

  const options = useMemo(() => {
    let retnVal = [];

    retnVal = [...retnVal, ...entityOptions];

    const isFetchInProgress = searchModeState.isEnabled
      ? searchModeState.isFetchInProgress
      : currentEntityFetchState.isFetchInProgress;

    let isEmpty = false;
    let isLoaderWithEmptyOption = false;

    if (isFetchInProgress) {
      isLoaderWithEmptyOption = retnVal.length === 0;
      retnVal.push({
        label: "Loading...",
        value: NON_ACTIONING_ITEM.DUMMY_LOADING,
        isLazyLoadStatusOption: retnVal.length > 0,
        isLoaderWithEmptyOption,
      });
    }

    if (
      !isFetchInProgress &&
      !searchModeState.isEnabled &&
      !currentEntityFetchState.children?.length
    ) {
      retnVal.push({
        label: currentEntityFetchState?.fetchErrorMsg || t("No account found"),
        value: NON_ACTIONING_ITEM.DUMMY_EMPTY_STATE,
        isEmptyState: true,
      });

      isEmpty = true;
    }

    if (
      !isFetchInProgress &&
      searchModeState.isEnabled &&
      !searchModeState.resultEntityIds?.length
    ) {
      retnVal.push({
        label: t("No Search Result Found"),
        value: NON_ACTIONING_ITEM.DUMMY_EMPTY_STATE,
        isEmptyState: true,
      });

      isEmpty = true;
    }

    // if (isAllowNewEntityCreation && !isEmpty && !isLoaderWithEmptyOption) {
    //   retnVal.push({
    //     label: "Add New Brand",
    //     value: NON_ACTIONING_ITEM.ADD_NEW_BRAND,
    //     isAddNewEntity: true,
    //     className: "add-brand-item",
    //   });
    // }

    return retnVal;
  }, [entityOptions, currentEntityFetchState, searchModeState]);

  useEffect(() => {
    if (onOptionLoading && isFetchComplete) {
      onOptionLoading(options);
    }
  }, [isFetchComplete]);

  useEffect(() => {
    if (searchModeState.isEnabled) {
      refSearchInput?.current?.focus();
    }
  }, [searchModeState.isEnabled]);

  const onChangeOption = (newVal) => {
    if (NON_ACTIONING_ITEM[newVal]) {
      return;
    }

    setIsSearchFocused(false);

    const newEntityInfo = getEntityInfo(entityInfo, newVal);

    if (selectableEntityType && selectableEntityType !== newEntityInfo?.entityType) {
      return;
    }

    const parsedData = parseProcessedEntityId(newEntityInfo?.entityId);

    if (selectedEntityId && selectedEntityId === parsedData?.entityId) {
      return;
    }

    const { entityId: newEntityId, entityType: newEntityType } = parseProcessedEntityId(newVal);
    if (onChangeHandler && typeof onChangeHandler === "function") {
      onChangeHandler(newEntityId, newEntityType);
    } else {
      onEntityChange(newEntityId, newEntityType);
    }
  };

  const handleMenuScrollToBottom = () => {
    if (searchModeState.isEnabled) {
      if (!searchModeState.isAllFetched && !searchModeState.isFetchInProgress) {
        setSearchModeState((currentVal) => ({
          ...currentVal,
          offset: searchModeState?.resultEntityIds?.length,
          fetchCounter: (searchModeState?.fetchCounter || 0) + 1,
        }));
      }
    } else if (!_isEmpty(currentEntityFetchState) && !currentEntityFetchState.isAllFetched) {
      const entityDetails = parseProcessedEntityId(currentListEntityId) || {};
      const mccId = entityDetails.entityType === entityTypeEnum.MCC ? entityDetails.entityId : null;
      fetchEntitiesData(mccId);
    }
  };

  const onEntityDrillDown = (val) => {
    if (!entitiesFetchState[val]?.offset && !entitiesFetchState[val]?.isFetchInProgress) {
      const entityDetails = parseProcessedEntityId(val) || {};
      const mccId = entityDetails.entityType === entityTypeEnum.MCC ? entityDetails.entityId : null;
      fetchEntitiesData(mccId);
    }
    setCurrentListEntityId(val);
  };

  const onClickHandlerWithoutPropogation = (e) => {
    e.stopPropagation();
  };

  const onBreadCrumbClick = (e, entityId) => {
    e.stopPropagation();
    onEntityDrillDown(entityId);
  };

  const onSearchIconClick = (e) => {
    e.stopPropagation();
    if (isEnableSearchModeOnly) {
      refSearchInput?.current?.focus();
    } else {
      setSearchModeState((currentVal) => ({
        ...currentVal,
        isEnabled: !currentVal.isEnabled,
      }));
    }
  };

  const onSearchTextChange = (val) => {
    setSearchModeState((currentVal) => ({
      ...currentVal,
      searchText: val,
    }));
  };

  // const onClickUpdateDetails = (e, val) => {
  //   e.stopPropagation();
  //   const { entityId, entityType } = parseProcessedEntityId(val?.value);

  //   redirectUrl(`__APPEND__/drawer/BUSINESS_DETAILS?entityId=${entityId}&entityType=${entityType}`);
  // };

  // const onCreateNewEntity = (e) => {
  //   e.stopPropagation();
  //   setIsShowNewEntityCreationDrawer(true);
  // };

  // const onNewEntityCreation = ({ newEntityType, newEntityId }) => {
  //   if (searchModeState.isEnabled) {
  //     if (!selectableEntityType || selectableEntityType === newEntityType) {
  //       setSearchModeState((currentVal) => ({
  //         ...currentVal,
  //         resultEntityIds: _uniq([
  //           `${newEntityType}_${newEntityId}`,
  //           ...(currentVal.resultEntityIds || []),
  //         ]),
  //       }));
  //     }
  //   }
  // };

  const getOptionDOM = (option) => {
    if (option.isEmptyState) {
      return (
        <div className="no-options-dom">
          <div className="no-option-icon">
            <span className="icon icon-no-results-3" />
          </div>
          {searchModeState.isEnabled ? (
            <>
              <div className="info-text"><Trans>No result found</Trans></div>
              <div className="placeholder-text"><Trans>Try different keyword</Trans></div>
              {/* {isAllowNewEntityCreation && (
                <Button icon="icon-plus-2" className="cta-button" link onClick={onCreateNewEntity}>
                  Create Brand
                </Button>
              )} */}
            </>
          ) : (
            <>
              <div className="info-text">{t(option?.label || "No account found")}</div>
              {/* {isAllowNewEntityCreation && (
                <Button icon="icon-plus-2" className="cta-button" link onClick={onCreateNewEntity}>
                  Create Brand
                </Button>
              )} */}
            </>
          )}
        </div>
      );
    }

    // if (option.isAddNewEntity) {
    //   return (
    //     <Button icon="icon-plus-2" className="cta-button" link onClick={onCreateNewEntity}>
    //       {option.label}
    //     </Button>
    //   );
    // }

    if (option.isLazyLoadStatusOption) {
      return (
        <AsyncImage
          imgClassName="lazy-loading-image"
          imgSrc="https://osads.gumlet.io/image/upload/v1580290660/product/spinner_loader.gif"
        />
      );
    }

    if (option.isLoaderWithEmptyOption) {
      return (
        <div className="no-options-dom">
          {loaderWhite()}
          <div className="info-text"><Trans>Loading...</Trans></div>
        </div>
      );
    }

    const isOptionNotSelectable =
      selectableEntityType && option.entityType !== selectableEntityType;

    const showBreadCrumbExpand =
      option.entityType === entityTypeEnum.MCC && !searchModeState.isEnabled;

    // check based on depth if required
    // if (option.businessDefinition === businessDefinitionEnum.ADVERTISER) {
    //   showBreadCrumbExpand =
    //     isShowBreadCrumbExpandForAdvertiser &&
    //     option.entityType === entityTypeEnum.MCC &&
    //     !searchModeState.isEnabled;
    // }

    const isChildrenAvailable = showEntityType || showBreadCrumbExpand;

    return (
      <div className={`option-item ${searchModeState.isEnabled && "is-seach-active"}`}>
        <div
          className={`left ${isOptionNotSelectable ? "not-selectable" : ""}`}
          onClick={isOptionNotSelectable ? onClickHandlerWithoutPropogation : undefined}
        >
          {searchModeState.isEnabled && (
            <div className="highlight-search-label d-align-between">{option?.label}</div>
          )}
          <div className="left-inner">
            {searchModeState.isEnabled && option?.hierarchy?.length > 0 ? (
              option?.hierarchy?.map((subItem, index) => {
                return (
                  <span>
                    {index > 0 && (
                      <>
                        &nbsp;
                        <span className="icon icon-angle-right ml-2 mr-2" />
                      </>
                    )}
                    {subItem.entityName}
                  </span>
                );
              })
            ) : (
              <div className="label d-align-between">{option?.label}</div>
            )}
          </div>
        </div>
        {isChildrenAvailable ? (
          <div className="right d-align-center">
            {showEntityType && (
              <span className="sub-text-label">
                {entityBusineessDefinitionConfig[option?.businessDefinition].label}
              </span>
            )}
            {showBreadCrumbExpand && (
              <div className="item-arrow" onClick={(e) => onBreadCrumbClick(e, option?.value)}>
                <span className="icon icon-angle-right" />
              </div>
            )}
          </div>
        ) : null}
      </div>
    );
  };

  const CustomOption = (props) => {
    const { data } = props;
    return <components.Option {...props}>{getOptionDOM(data)}</components.Option>;
  };

  const ValueContainer = ({ children, ...props }) => {
    const notAllowedTypes = ["SingleValue"];

    if (selectedEntityInfo.breadCrumbText) {
      notAllowedTypes.push("Placeholder");
    }

    const allowedChildren = Children.map(children, (child) => {
      return child && !notAllowedTypes.includes(child.type.name) ? child : null;
    });

    return (
      <components.ValueContainer {...props}>
        {allowedChildren}
        {selectedEntityInfo.breadCrumbText}
      </components.ValueContainer>
    );
  };

  if (!entitiesFetchInProgress) {
    // const newEntityParentInfo = parseProcessedEntityId(
    //   searchModeState.isEnabled ? rootEntityId : currentListEntityId,
    // );

    return (
      <>
        {/* <NewEntityCreation
          isOpen={isShowNewEntityCreationDrawer}
          onHide={() => setIsShowNewEntityCreationDrawer(false)}
          jobDoneCallback={() => setIsShowNewEntityCreationDrawer(false)}
          title={
            currentEntityBreadCrumbText
              ? `Create New Brand for "${currentEntityBreadCrumbText}"`
              : "Create New Brand"
          }
          onNewEntityCreation={onNewEntityCreation}
          parentMCCId={
            newEntityParentInfo?.entityType === entityTypeEnum.MCC
              ? newEntityParentInfo?.entityId
              : null
          }
        /> */}
        <div
          className={`os-multi-level-dropdown-wrapper ${isDarkMode ? "dark-mode" : ""}`}
          ref={containerRef}
        >
          {isEditable ? (
            <DropdownVirtualized
              inputId={`entity-select-dd-${timestampRef.current}`}
              components={{
                ValueContainer,
                Option: CustomOption,
                MenuList: CustomMenuList,
              }}
              containerClassName={dropdownContainerClassName}
              isSearchable={false}
              hideSelectedOptions={false}
              value={`${selectedEntityType}_${selectedEntityId}`}
              onMenuScrollToBottom={handleMenuScrollToBottom}
              onChange={onChangeOption}
              onMenuInputFocus={() => setIsSearchFocused(true)}
              isLoading={entitiesFetchInProgress}
              options={options}
              searchModeState={searchModeState}
              onSearchTextChange={onSearchTextChange}
              rootEntityId={rootEntityId}
              agencyId={agencyId}
              currentEntityBreadCrumbs={currentEntityBreadCrumbs}
              onSearchIconClick={onSearchIconClick}
              onBreadCrumbClick={onBreadCrumbClick}
              isEnableSearchModeOnly={isEnableSearchModeOnly}
              refSearchInput={refSearchInput}
              currentListEntityId={currentListEntityId}
              menuIsOpen={isSearchFocused}
              isFocused={isSearchFocused}
              // isAllowNewEntityCreation={isAllowNewEntityCreation}
              menuPosition="fixed"
              placeholder={placeholder}
              rootPlaceholder={t(rootPlaceholder)}
            />
          ) : (
            <div className="non-edit-mode-text">{selectedEntityInfo.breadCrumbText || ""}</div>
          )}
        </div>
      </>
    );
  }

  return null;
};

const mapStateToProps = (state, ownProps) => {
  const { agencyId } = state.Application || {};
  const { entityBusineessDefinitionConfig } = state.DomainConfig || {};
  const {
    selectedEntityId,
    selectedEntityType,
    entityInfo,
    entityMetadata,
    entitiesFetchState,
    entitiesFetchInProgress,
  } = state.EntityApplication || {};

  return {
    selectedEntityId,
    selectedEntityType,
    entityInfo,
    entityMetadata,
    entitiesFetchState,
    entitiesFetchInProgress,
    rootEntityId: `${entityTypeEnum.AGENCY}_${agencyId}`,
    agencyId,
    entityBusineessDefinitionConfig,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onEntityChange: changeEntityIdAction,
      fetchEntitiesData: fetchEntitiesDataAction,
      onFilterEntityBySearchText: onFilterEntityBySearchTextAction,
    },
    dispatch,
  );
};

MultiLevelDropdown.defaultProps = {
  isDarkMode: false,
  isEditable: true,
  showEntityType: false,
  // isShowBreadCrumbExpandForAdvertiser: true,
  // rootEntityId => To define a root under which the hierarchy is to be rendered
  // selectedEnitityId => For a controlled dropdown pass selected entity id
  // selectedEntityType => For a controlled dropdown pass selected entity type
  onChangeHandler: null, // Callback when entity selection changes, to be used when controlled from outside,
  selectableEntityType: null, // MCC, CLIENT,
  isEnableSearchModeOnly: false, //To enable exhaustive list of entities. This will show only search mode and load with empty search text as well
  // isAllowNewEntityCreation: false,
  rootPlaceholder: "All Businesses",
};

export default connect(mapStateToProps, mapDispatchToProps)(MultiLevelDropdown);
