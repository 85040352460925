import React from "react";

const FilterWrapper = ({ children, error, tabKey }) => {
  return (
    <div>
      {children}
      {error?.[tabKey] ? <div className="error-msg">{error[tabKey]}</div> : null}
    </div>
  );
};

export default FilterWrapper;
