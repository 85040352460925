import React from "react";
import { DropdownWithSearch } from "@onlinesales-ai/dropdown-v2";
import DropdownForm from "../dropdown";

const DropdownWithSearchForm = (props) => {
  return (
    <DropdownForm
      dropdownComponent={DropdownWithSearch}
      {...props}
    />
  );
};

export default DropdownWithSearchForm;
