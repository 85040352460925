import { OnboardingService } from "@onlinesales-ai/services-v2";
import { CLIENT_AGGREGATOR_CONFIG, DefaultOnboardingConfig } from "@onlinesales-ai/constants-v2";
import { uiAPIMonitor } from "@onlinesales-ai/error-catcher-v2";

import types from "./types";

export const setOnboardingDataFetchInProgress = (isLoading) => ({
  type: types.SET_ONBOARDING_FETCH_IN_PROGRESS,
  isLoading,
});

export const setOnboardingDataFetchError = (fetchError) => ({
  type: types.SET_ONBOARDING_FETCH_ERROR,
  fetchError,
});

export const fetchOnBoardingStatus = (
  routes, redirectUrl, setOnboardingData, shouldShowLoader = true
) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { clientId, activeApp } = state.Application || {};
    const locationPath = state.router?.location?.pathname || "";

    if (shouldShowLoader) {
      dispatch(setOnboardingDataFetchInProgress(true));
    }
    dispatch(setOnboardingDataFetchError(false));

    try {
      if (clientId === CLIENT_AGGREGATOR_CONFIG.value) {
        dispatch(await setOnboardingData(DefaultOnboardingConfig));
      } else {
        const response = await OnboardingService.getStatus(clientId, "application" , {abortId : "APP_LEVEL_ONBOARDING__STATUS"});

        if (response && !response.error && response.success && response.data) {
          const onboardingDetails = response.data || {};
          if (onboardingDetails?.webtourStatus) {
            if (!onboardingDetails.metadata) {
              onboardingDetails.metadata = {};
            }

            onboardingDetails.metadata = {
              ...onboardingDetails.metadata,
              ...onboardingDetails.webtourStatus,
            };
          }
          await dispatch(setOnboardingData(onboardingDetails));
        } else {
          dispatch(setOnboardingDataFetchError(response.error));
          uiAPIMonitor("INTERNAL", "DOWNTIME_PAGE_CALLED", {
            clientId,
            error: response.error,
            calledFrom: "fetchOnBoardingStatus",
          });
          dispatch(redirectUrl(routes.DOWNTIME.path));
        }
      }

      dispatch(setOnboardingDataFetchInProgress(false));
    } catch (err) {
      if (!err.isAborted) {
        dispatch(setOnboardingDataFetchError(err.errorMsg));
        dispatch(setOnboardingDataFetchInProgress(false));
        uiAPIMonitor("INTERNAL", "DOWNTIME_PAGE_CALLED", {
          clientId,
          error: err,
          calledFrom: "fetchOnBoardingStatus",
        });
        dispatch(redirectUrl(routes.DOWNTIME.path));
        uiAPIMonitor("SEV2", "APP_LEVEL_CONFIG_DATA_ERROR", {
          error: err,
          clientId,
        });
        throw new Error(err.errorMsg);
      }
    }
  };
};
