import { routes } from "src/utilities/constants";
import _isEmpty from "lodash/isEmpty";

import { getRedirectUrl, changeClientId } from "@onlinesales-ai/app-v2/application/actions";
import { ShopsUIService } from "@onlinesales-ai/services-v2";
import {
  setVendorAccountData,
  setVendorAccountFetchState,
  prepareVendorAccountData,
} from "@onlinesales-ai/account-linking-v2";

import { getDomainConfiguration } from "src/domainConfig/configuration";
import { fetchClientsBillingStatus } from "@onlinesales-ai/app-v2/biling";
import { CLIENT_AGGREGATOR_CONFIG } from "@onlinesales-ai/constants-v2";

export const redirectUrl = getRedirectUrl(routes);

export const fetchClientLevelRequiredInfo = (clientId) => (dispatch, getState) => {
  const state = getState();

  const { clientLevelRequiredInfoConfig } = state.DomainConfig || {};

  const batchCallIdentifierEnum = {
    ACCOUNT_LINKING: "ACCOUNT_LINKING",
  };

  const batchCallRequests = clientLevelRequiredInfoConfig?.dataToFetch?.reduce?.((memo, key) => {
    if (key === batchCallIdentifierEnum.ACCOUNT_LINKING) {
      memo[batchCallIdentifierEnum.ACCOUNT_LINKING] = {
        hostname: "services.onlinesales.ai",
        endpoint: "/clientInputService/data",
        method: "GET",
        data: {
          jsonQuery: {
            "@class": "com.sokrati.clientInputSvcObjects.request.GetParamRequest",
            clientId,
            userId: null,
            filters: [
              {
                column: "PROPERTY_TYPES",
                operator: "IN",
                values: [
                  "GOOGLE.COM::ACCOUNT",
                  "FACEBOOK.COM::ACCOUNT",
                  "FACEBOOK.COM::PAGE",
                  "INSTAGRAM::ACCOUNT",
                  "AMAZON.COM::ADS_ACCOUNT",
                  "GMB_LOCATION",
                ],
              },
            ],
          },
        },
      };
    }

    return memo;
  }, {});

  if (_isEmpty(batchCallRequests)) {
    return Promise.resolve();
  }

  return new Promise((resolve, reject) => {
    dispatch(setVendorAccountFetchState(true, false));
    ShopsUIService.batchCall({ requests: batchCallRequests }, "Acquire")
      .then((response) => {
        if (response && response.data) {
          if (response.data[batchCallIdentifierEnum.ACCOUNT_LINKING]) {
            const accountLinkingResponse = response.data[batchCallIdentifierEnum.ACCOUNT_LINKING];
            const { paramDetails = [] } = accountLinkingResponse || {};
            const vendorAccountData = prepareVendorAccountData(paramDetails);
            dispatch(setVendorAccountData(vendorAccountData));
            dispatch(setVendorAccountFetchState(false, false));
          }
        } else {
          reject(
            "Something Went Wrong, Please try again or Contact Support if the problem persists",
          );
        }
      })
      .catch((error, errorMsg) => {
        dispatch(setVendorAccountFetchState(false));
        reject(errorMsg);
      });
  });
};

export const changeClientIdAction = (clientId) => {
  return (dispatch) => {
    dispatch(changeClientId(clientId, getDomainConfiguration));
  };
};

export const fetchBillingStatus = (config) => {
  return (dispatch, getState) => {
    const state = getState();
    const { clientId } = state.Application;

    try {
      dispatch(fetchClientsBillingStatus({
        taggingSelectors: ["outletId", "outletName"],
        clientId: clientId === CLIENT_AGGREGATOR_CONFIG.value ? undefined : clientId,
        lowBalanceAmount: config?.lowBalanceAmount,
        abortId: "APP_LEVEL_FETCH_BILLING_STATUS_FOR_COUNT",
      }));
    } catch (error) {};
  };
};
