import React from "react";
import { Overlay, Popover } from "react-bootstrap";
import _capitalize from "lodash/capitalize";
import _map from "lodash/map";
import classnames from "classnames";

import { DropdownMenu } from "@onlinesales-ai/dropdown-v2";
import { formatNumberInAbbreviations, formatValuesInThousands, getCurrencyPosition } from "@onlinesales-ai/util-methods-v2";

import "./input.less";

class Input extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editInProgress: !props.formatNumber,
      showFocusTooltip: false,
      value: props.value,
      isFocused: false,
      currencyPosition: getCurrencyPosition(),
      ...this.getPrefixValue(props, true),
    };

    this.inputRef = React.createRef();
    this.target = React.createRef(null);
    this.timeStamp = React.createRef();
    this.timeStamp.current = (new Date()).valueOf();
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (this.props.value !== nextProps.value) {
      this.setState({
        ...this.getPrefixValue(nextProps),
      });
    }
  };

  componentDidMount() {
    const { inputPropsRef } = this.props;
    if (inputPropsRef && this.inputRef.current) {
      inputPropsRef.current = this.inputRef.current;
    }
  }

  getPrefixValue = (props, isFirstTime) => {
    const { enablePrefixControl, value, prefixOptions } = props || this.props;
    const { prefixInputValue } = this.state || {};

    if (enablePrefixControl && prefixOptions?.length && !prefixInputValue) {
      let selected = "";

      if (isFirstTime && value) {
        selected = prefixOptions.find((p) => value.startsWith(p.value))?.value;
      } else {
        selected = prefixOptions[0]?.value;
      }
      return {
        prefixInputValue: selected,
        value: selected && value ? value.replace(selected, "") : value,
      };
    } else if (value && prefixInputValue) {
      return {
        value: value.replace(prefixInputValue, ""),
      };
    }

    return {
      value,
    };
  };

  onFocusFakeInput = () => {
    const { formatNumber } = this.props;
    if (formatNumber) {
      this.setState(
        {
          editInProgress: true,
        },
        () => {
          this.inputRef.current && this.inputRef.current.focus();
        },
      );
    }
  };

  onChangeInput = (event) => {
    const {
      onChange,
      maxLength,
      decimal = 2,
      type,
      isTitleCase,
      isCapitalize,
      getValAsNumber,
      emptyStringValueToSet,
      doNotSendEmptyString,
      doNotAllowNegativeNumber,
    } = this.props;
    const { prefixInputValue, value: existingVal } = this.state;

    let val = event?.target?.value;

    if (isCapitalize && val) {
      val = val?.toUpperCase?.();
    }

    if (maxLength && `${val}`.length > maxLength) {
      return null;
    }

    const validDecimalNumberRegex = new RegExp(`^\\d*(\\.\\d{0,${decimal}})?$`);
    if (type === "number" && val?.toString()?.length > existingVal?.toString()?.length && !val.match(validDecimalNumberRegex)) {
      return null;
    }

    if (doNotAllowNegativeNumber && type === "number" && getValAsNumber && parseInt(val, 10) < 0) {
      return null;
    }

    if (prefixInputValue && val) {
      val = prefixInputValue + val;
    }

    if (isTitleCase) {
      const titleCase = (str) => _map(str.split(" "), _capitalize).join(" ");
      val = titleCase(val);
    }

    if (getValAsNumber && val && type === "number") {
      val = parseInt(val, 10);
    }

    if (!val && doNotSendEmptyString) {
      val = emptyStringValueToSet;
    }

    if (onChange) {
      onChange(val, event);
    }
  };

  onPaste = (event) => {
    const { decimal = 2 } = this.props;
    const validDecimalNumberRegex = new RegExp(`^\\d*(\\.\\d{0,${decimal}})?$`);

    try {
      const clipboardData = event.clipboardData?.getData("text");

      if (!clipboardData.match(validDecimalNumberRegex)) {
        event.preventDefault();
      }
    } catch (error) {}
  };

  onChangePrefixDropdown = (newVal) => {
    const { prefixInputValue } = this.state;
    const { value, onChange } = this.props;

    let valToUpdate = value;

    if (prefixInputValue && valToUpdate.startsWith(prefixInputValue)) {
      valToUpdate = valToUpdate.replace(prefixInputValue, "");
    }

    valToUpdate = newVal + valToUpdate;

    this.setState(
      {
        prefixInputValue: newVal,
      },
      () => {
        if (value) {
          onChange(valToUpdate);
        }
      },
    );
  };

  onKeyUp = (event) => {
    const { onEnter } = this.props;

    if (onEnter && event.which === 13) {
      onEnter();
    }
  };

  onKeyDown = (e) => {
    const { blockDecimal, type, blockNumberSpecialCharacters = true } = this.props;
    const invalidNumberChars = ["-", "+", "e", "E"];

    if (blockDecimal && e.key === ".") {
      e.preventDefault();
    }

    if(type === "number" && blockNumberSpecialCharacters && invalidNumberChars.includes(e.key)) {
      e.preventDefault();
    }
  };

  resetContent = (e) => {
    const { onChange } = this.props;
    onChange("");
  }

  onNumberInputBlur = () => {
    const { formatNumber, onBlur, enableFocusToolTip } = this.props;
    if (formatNumber) {
      this.setState({
        editInProgress: false,
      });
    }
    if (onBlur) {
      onBlur();
    }
    if (enableFocusToolTip) {
      this.setState({
        showFocusTooltip: false,
      });
    }
  };

  numberOnWheelHandler = (event) => {
    const { isFocused } = this.state;
    // if number is being scrolled when focused it triggers on change and changes input value
    // triggering blur event so that this unexpected behaviour is avoided
    if (isFocused) {
      event?.target?.blur();
    }
  };

  onInputBlur = () => {
    const { enableFocusToolTip, onBlur, trimOnBlur } = this.props;
    const { value } = this.state;

    if (enableFocusToolTip) {
      this.setState({
        showFocusTooltip: false,
      });
    }
    if (trimOnBlur) {
      this.onChangeInput({ target: { value: typeof value === "string" ? value.trim() : value } });
    }
    if (onBlur) {
      onBlur();
    }
  };

  onFocusInput = (e) => {
    const { enableFocusToolTip, onFocus } = this.props;
    if (enableFocusToolTip) {
      this.setState({
        showFocusTooltip: true,
        isFocused: true,
      });
    }

    if (onFocus) {
      onFocus(e);
    }
  };

  showFocusTooltip = () => {
    return (
      <div className="d-align-center">
        <span className={`${this.props.isShowInfoIcon ? "info-icon" : ""}`} style={{ opacity: "1" }}>
          <i className={`${this.props.isShowInfoIcon ? "icon icon-info-circle1" : ""}`} ref={this.target} />
        </span>
        <Overlay target={this.target} show placement={this.props.toolTipPlacement}>
          <Popover className="overlay-trigger-input">
            <div>
              {this.props.focusInfoToolTip}
            </div>
          </Popover>
        </Overlay>
      </div>
    );
  };

  render() {
    const {
      type,
      precision,
      suffix,
      prefix,
      currencyClass,
      className,
      formatNumber,
      isFormatInThousands,
      characterLimit,
      inputPropsRef,
      inputWrapperClassName,
      wrapperRef,
      prefixOptions,
      isError,
      isWarning,
      isResetContent,
      focusInfoToolTip,
      enableFocusToolTip,
      toolTipPlacement,
      dataList,
      isSearchInput,
      setParseValue,
      ...props
    } = this.props;

    const { editInProgress, value, prefixInputValue, currencyPosition } = this.state;

    return (
      <div
        ref={wrapperRef}
        className={`input-wrapper  ${props?.disabled && "disabled"} ${isError && "error"} ${
          isWarning && "warning"
        } ${inputWrapperClassName} `}
      >
        {currencyClass && currencyPosition === "LEFT" && (
          <span className="currency-symbol d-align-center">
            <span className={currencyClass} />
          </span>
        )}
        {prefix && <span className="prefix-metric">{prefix}</span>}
        {isSearchInput && <span className="icon icon-search-2 searh-icon" />}
        {prefixOptions?.length &&
          (prefixOptions.length === 1 ? (
            <span className="prefix-metric">{prefixInputValue}</span>
          ) : (
            <span className="prefix-metric">
              <DropdownMenu
                className="input-prefix-dropdown"
                options={prefixOptions}
                onClickItem={this.onChangePrefixDropdown}
                alignMenuRight={false}
                iconClassName=""
              >
                {prefixInputValue}
              </DropdownMenu>
            </span>
          ))}

        {type === "number" ? (
          <>
            {editInProgress ? (
              <div className="in-progress-wrapper">
                <input
                  type="number"
                  className={`form-control simple-input ${className}`}
                  list={!!dataList?.length ? `input-datalist-${this.timeStamp.current}` : undefined}
                  ref={this.inputRef}
                  onKeyUp={this.onKeyUp}
                  onKeyDown={this.onKeyDown}
                  {...props}
                  value={setParseValue ? parseFloat(value, precision) : value}
                  onBlur={this.onNumberInputBlur}
                  onChange={this.onChangeInput}
                  onFocus={this.onFocusInput}
                  onWheel={this.numberOnWheelHandler}
                  onPaste={this.onPaste}
                />
              </div>
            ) : (
              <div className="fake-input-wrapper">
                <span
                  className="form-control fake-input"
                  tabIndex="0"
                  onFocus={this.onFocusFakeInput}
                >
                  {value ? (
                    <>
                      <span>
                        {isFormatInThousands
                          ? formatValuesInThousands(value || 0, precision)
                          : formatNumberInAbbreviations(value || 0, precision)}
                      </span>
                    </>
                  ) : (
                    <span className="placeholder-number">{props.placeholder || ""}</span>
                  )}
                </span>
              </div>
            )}
          </>
        ) : (
          <input
            type={type}
            className={`form-control simple-input ${className}`}
            onKeyUp={this.onKeyUp}
            onKeyDown={this.onKeyDown}
            list={!!dataList?.length ? `input-datalist-${this.timeStamp.current}` : undefined}
            {...props}
            value={value}
            ref={inputPropsRef}
            onChange={this.onChangeInput}
            onBlur={this.onInputBlur}
            onFocus={this.onFocusInput}
          />
        )}
        {isResetContent && !!value && (
          <span onClick={this.resetContent} className="reset-input-content icon icon-close1" />
        )}
        {currencyClass && currencyPosition === "RIGHT" && (
          <span className="currency-symbol d-align-center">
            <span className={classnames("pr-0", currencyClass)} />
          </span>
        )}

        {suffix && <span className="suffix-metric">{suffix}</span>}
        {!suffix && characterLimit && (
          <span className="suffix-metric character-suffix">{characterLimit}</span>
        )}
        {enableFocusToolTip && (
          <span className="suffix-with-focus-metric onfocus-tooltip">
            {this.state.showFocusTooltip ? this.showFocusTooltip() : null}
          </span>
        )}

        {!!dataList?.length && (
          <datalist id={`input-datalist-${this.timeStamp.current}`}>
            {dataList.map(({ label, value: optionValue }) => {
              return <option value={optionValue}>{label}</option>;
            })}
          </datalist>
        )}
      </div>
    );
  }
}

Input.defaultProps = {
  type: "text",
  formatNumber: false,
  isFormatInThousands: false,
  precision: 0,
  suffix: "",
  className: "",
  currencyClass: "",
  isError: false,
  isWarning: false,
  isTitleCase: false,
  trimOnBlur: true,
  enableFocusToolTip: false,
  setParseValue: false,
  toolTipPlacement: "right",
};

export default React.forwardRef((props, ref) => <Input {...props} inputPropsRef={ref} />);
