import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import { MarketPlaceServiceClient } from "@onlinesales-ai/services-v2";
import { getCurrencyDOM } from "@onlinesales-ai/util-methods-v2";

import "./index.less";

const SpendingLimitBreakUp = ({
  shopInfo,
  clientId,
  isShowSpendingLimitBreakUp,
  spendingLimitBreakdownConfig,
  onChangeSpendingBreakUp,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState(false);
  const [showBreakUp, setShowBreakUp] = useState(false);
  const [data, setData] = useState({});

  const fetchData = async () => {
    try {
      const response = await MarketPlaceServiceClient.getSpendingLimitForClient(
        {
          clientId,
          storeType: shopInfo.storeType,
          currency: shopInfo.currencyCode,
        },
        "TOPUP",
      );

      const spendingLimit = response?.result?.spendingLimit || 0;

      setData({
        spendingLimit,
        spendingLimitBreakdown: response?.result?.spendingLimitBreakdown,
      });

      if (onChangeSpendingBreakUp) {
        onChangeSpendingBreakUp(spendingLimit);
      }
    } catch (err) {
      setErrorMsg(err.errorMsg);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const toggleBreakUp = () => {
    setShowBreakUp(!showBreakUp);
  };

  const renderBreakUp = () => {
    return spendingLimitBreakdownConfig.map(({ key, description, subDescription, title }) => {
      return (
        <div className="spending-limit-breakup-card col-sm-4">
          <div className="breakup-info">
            <div className="breakup-title">{title}</div>
            <div className="breakup-amount">
              {getCurrencyDOM()}
              {Math.round(data?.spendingLimitBreakdown?.[key] || 0)}
            </div>
            <div className="breakup-desc" dangerouslySetInnerHTML={{ __html: description }} />
            <div
              className="breakup-sub-desc"
              dangerouslySetInnerHTML={{ __html: subDescription }}
            />
          </div>
        </div>
      );
    });
  };

  return (
    <div className={`spending-limit-wrapper ${showBreakUp ? "show-boxes" : "hide-boxes"}`}>
      {isLoading ? (
        <div className="animated-bg loading" />
      ) : (
        <>
          {errorMsg ? (
            <span className="error-msg">{errorMsg}</span>
          ) : (
            <div className="value-wrapper">
              <span>
                {getCurrencyDOM()}
                {data?.spendingLimit}
              </span>
              {isShowSpendingLimitBreakUp ? (
                <div className="breakup-toggle" onClick={toggleBreakUp}>
                  <span className="icon icon-help-bold" />
                </div>
              ) : null}
            </div>
          )}
        </>
      )}
      <div className="inline-block">
        <div className="spending-limit-breakup">{renderBreakUp()}</div>
      </div>
    </div>
  );
};

SpendingLimitBreakUp.defaultProps = {
  isShowSpendingLimitBreakUp: false,
  spendingLimitBreakdownConfig: [],
};

const mapStateToProps = (state) => {
  return {
    shopInfo: state.Application.shopInfo,
    clientId: state.Application.clientId,
  };
};

export default connect(mapStateToProps)(withTranslation()(SpendingLimitBreakUp));
