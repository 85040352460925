import React from "react";
import _get from "lodash/get";
import _isEqual from "lodash/isEqual";
import { withTranslation } from "react-i18next";
import { validateValue } from "@onlinesales-ai/util-methods-v2";
import { InputWithDataList as DataList } from "@onlinesales-ai/input-v2";

import FormWrapper from "../../FormWrapper";
import "./index.less";

class InputWithDataList extends React.Component {
  constructor(props) {
    super(props);

    const { dataKey, formValues } = this.props;
    const currentValue = _get(formValues, dataKey);

    this.state = {};
    this.validate(currentValue);
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const { validations } = this.props;
    const newValue = this.getValue(nextProps);
    const newErrorValue = this.getError(nextProps);

    if (
      newValue !== this.getValue(this.props) ||
      (!newValue && newErrorValue !== this.getError()) ||
      !_isEqual(validations, nextProps.validations)
    ) {
      this.validate(newValue, () => {}, nextProps.validations);
    }
  };

  validate = (value, callback, pValidations) => {
    const { onError, dataKey, validations = [], title, formValues } = this.props;
    let errorMsg = null;

    const validationsToUse = pValidations || validations;

    const validationValue = validateValue({
      validations: validationsToUse,
      value,
      formValues,
      title,
    });
    errorMsg = validationValue?.errorMsg;

    onError({ [dataKey]: errorMsg }, callback);
    return !errorMsg;
  };

  getError = (props = this.props) => {
    const { dataKey, formErrors } = props;
    return _get(formErrors, dataKey);
  };

  getValue = (props = this.props) => {
    const { dataKey, formValues, getValue } = props;

    if (getValue) {
      return getValue(props);
    }

    return _get(formValues, dataKey);
  };

  onChangeInput = (value) => {
    const { onChange, dataKey, resetUndefined, trimOnChange } = this.props;

    let valueToAdd = value;

    if (trimOnChange && typeof valueToAdd === "string") {
      valueToAdd = value.trim();
    }

    onChange({ [dataKey]: valueToAdd || (resetUndefined ? undefined : valueToAdd) });
  };

  render() {
    const {
      // FormWrapper props
      labelColumns,
      formGroupClassName,
      guidText,
      guidElement,
      apiError,
      warning,
      title,
      titleTooltip,
      titleGuidText,

      // tag input props
      dataKey,
      isEditable,
      showErrors,
      formErrors,
      disabled,
      name,
      layout,
      nonEditableComponent,
      renderNonEditableChildren,
      defaultValue,
      id,
      data,
      labelDefinationKey,
      enableFullBorder,
      ...rest
    } = this.props;

    const value = this.getValue();

    return (
      <FormWrapper
        formGroupClassName={formGroupClassName}
        labelColumns={labelColumns}
        hasError={showErrors && formErrors[dataKey]}
        error={formErrors[dataKey]}
        apiError={apiError}
        guidElement={guidElement}
        guidText={guidText}
        warning={warning}
        title={title}
        titleTooltip={titleTooltip}
        titleGuidText={titleGuidText}
        isEditable={isEditable}
        labelDefinationKey={labelDefinationKey}
      >
        {isEditable ? (
          <DataList
            data={data}
            enableFullBorder={enableFullBorder}
            {...rest}
            value={value}
            onChange={this.onChangeInput}
          />
        ) : (
          <div className="non-edit-mode-text">
            {nonEditableComponent && value}
          </div>
        )}
      </FormWrapper>
    );
  }
}

InputWithDataList.defaultProps = {
  name: "form-checkbox",
  dataKey: null,
  defaultValue: false,
  title: "",
  labelColumns: 3,
  formGroupClassName: "form-component-input-with-datalist",
  validations: [
    {
      type: "nonEmpty",
      msg: "Please enter __FIELD_TITLE__",
    },
  ],
};

export default withTranslation()(InputWithDataList);
