/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-else-return */
import React, { useRef, useState } from "react";
import { CheckboxGroup } from "@onlinesales-ai/checkbox-v2";

import "./index.less";
import DropdownAsync from "./DropdownAsync";

const DropDownWithSearchSelectAll = (props) => {
  const [menuIsOpen, setMenuIsOpen] = useState(undefined);

  const valueRef = useRef(props.value);
  valueRef.current = props.value;

  const isSelectAllSelected = () => {
    const withOutSelectAllOptions = (props.options || []).filter(
      (o) => o.value !== props.selectAllOption?.value,
    );
    const selectedValues = valueRef?.current?.map((v) => v.value);
    return (
      selectedValues?.length &&
      withOutSelectAllOptions?.every((o) => selectedValues?.includes(o.value))
    );
  };

  const isOptionSelected = (option) =>
    valueRef?.current?.some((val = {}) => val?.value === option.value) || isSelectAllSelected();

  const onChange = (newValue, actionMeta) => {
    const { action, option, removedValue } = actionMeta || {};

    if (action === "select-option" && option.value === props.selectAllOption?.value) {
      props.onChange(
        [...(props.options || []), ...(valueRef?.current || [])].filter(
          (o) => o.value !== props.selectAllOption?.value,
        ),
        actionMeta,
      );
    } else if (
      (action === "deselect-option" && option.value === props.selectAllOption?.value) ||
      (action === "remove-value" && removedValue.value === props.selectAllOption?.value)
    ) {
      const allOptionsValues = (props.options || []).map((o) => o.value);
      props.onChange(
        (valueRef?.current || []).filter((o) => !allOptionsValues.includes(o.value)),
        actionMeta,
      );
    } else if (action === "deselect-option") {
      props.onChange(
        newValue.filter(({ value }) => value !== option.value),
        actionMeta,
      );
    } else {
      props.onChange(newValue || [], actionMeta);
    }
  };

  const getOptionDOM = (option, { context }) => {
    const valuesList = (props.value || []).map((o) => o.value);
    const isSelected = isOptionSelected(option);
    if (isSelected && !valuesList?.includes(option.value)) {
      valuesList.push(option.value);
    }
    if (context === "menu") {
      return (
        <div className="option-with-checkbox">
          <CheckboxGroup
            type="checkbox"
            className="shops-custom-checkbox option-checkbox"
            defaultValue={null}
            data={[option]}
            groupValue={valuesList || []}
            checked={isSelected}
            onChange={() => {
              // Ignoring this event as it is handled by the react-select iteam click
            }}
            disableLabelClick
          />
        </div>
      );
    }
  };

  const onInputChange = (inputValue, { action, prevInputValue }) => {
    switch (action) {
      case "input-change":
      case "set-value":
        return inputValue;
      case "menu-close":
        let menuIsOpen = undefined;
        if (prevInputValue) {
          menuIsOpen = true;
        }
        setMenuIsOpen(menuIsOpen);
        return prevInputValue;
      default:
        return prevInputValue;
    }
  };

  const loadOptions = (search, callback) => {
    const { loadOptions = () => {}, isShowSelectAll } = props;
    return loadOptions(search, (entries = []) => {
      let newData = [];
      if (entries.length > 0) {
        if (isShowSelectAll) {
          newData = [props.selectAllOption || {}, ...entries];
        } else {
          newData = entries;
        }
      }
      // IMP: Set your optinos from callback result as it will include the select all option
      callback(newData);
      return newData;
    });
  };

  return (
    <DropdownAsync
      {...props}
      formatOptionLabel={getOptionDOM}
      loadOptions={loadOptions}
      isOptionSelected={isOptionSelected}
      // value={getValue()}
      controlShouldRenderValue={false}
      onChange={onChange}
      hideSelectedOptions={false}
      onSelectResetsInput={false}
      onInputChange={onInputChange}
      closeMenuOnSelect={false}
      menuIsOpen={menuIsOpen}
    />
  );
};

DropDownWithSearchSelectAll.defaultProps = {
  entityName: "Campaigns",
  options: [],
  placeholder: "search and select",
  className: "",
  value: [],
  isMulti: true,
  autoSet: false,
  isShowSelectAll: true,
  selectAllOption: {
    value: "<SELECT_ALL>",
    label: "Select All",
  },
  isClearable: false,
  closeMenuOnSelect: false,
  isSearchable: true,
  onChange: () => {},
};
export default DropDownWithSearchSelectAll;
