import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { differenceInHours } from "date-fns/esm";

import { ConfirmationModal, SuccessModal } from "@onlinesales-ai/modal-window-v2";
import { ToastV1, ToastV2 } from "@onlinesales-ai/toast-v2";
import { ToastTypeEnum } from "@onlinesales-ai/constants-v2";
import { OS_ID_TO_TOAST_ID_MAPPING, useMutationObserver } from "@onlinesales-ai/util-methods-v2";

const toastMapping = {
  [ToastTypeEnum.INFO.key]: "info",
  [ToastTypeEnum.WARNING.key]: "warn",
  [ToastTypeEnum.ERROR.key]: "error",
  [ToastTypeEnum.SUCCESS.key]: "success",
};

let showNewToast = false;
let showNewConfirmation = false;

export const swithToNewToast = () => {
  showNewToast = true;
};

export const swithToNewConfirmartion = () => {
  showNewConfirmation = true;
};

const OSHOCWithUtilities = (WrappedComponent, containerClass, hocProps = {}) => {
  return React.forwardRef((props, ref) => {
    const { t } = useTranslation();
    const wrapperDivRef = useRef();
    const [confirmationModalData, setConfirmationModalData] = useState({
      isShow: false,
      title: "",
      actionBtnText: "",
      rightBtnText: "",
      actionBtnCallback: () => {},
      rightBtnCallback: () => {},
    });
    const [successModalData, setSuccessModal] = useState({
      isShow: false,
      title: "",
      actionBtnText: "",
      actionBtnCallback: () => {},
    });

    const [toastMessageConfig, setToastMessageConfig] = useState({
      isShow: false,
    });

    const showConfirmationModal = (data, callback = () => {}) => {
      let header = "";
      if (typeof data.headerTitle === "undefined") {
        if (data.modalType === "ALERT") {
          header = "Alert";
        } else {
          header = "Confirmation";
        }
      } else {
        header = data.headerTitle;
      }
      setConfirmationModalData({
        ...data,
        ...(showNewConfirmation
          ? {
              headerTitle: header,
              containerClass: `${data.containerClass} left-align-content`,
              rightBtnTextProps: { link: false, outline: true },
            }
          : {}),
      });
      callback();
      // this callback shouldn't depend on confirmation modal state as setstate is asynchronous
    };

    const pendoFeatureGuideTrigger = () => {
      if (window?.pendo && window?.pendo?.findGuideById?.(props?.pendoGuideConfig?.guideId)) {
        const guideInfo = window?.pendo?.findGuideById?.(props?.pendoGuideConfig?.guideId);
        const guideLastSeenAt = guideInfo?.steps?.[0]?.lastSeenAt;
        if (!guideInfo?.hasBeenSeen() || props?.pendoGuideConfig?.isShowMultiTime) {
          window?.pendo?.showGuideById?.(props?.pendoGuideConfig?.guideId);
        }
        if (
          !!props?.pendoGuideConfig?.timeIntervalHour &&
          guideLastSeenAt &&
          differenceInHours(new Date(), guideLastSeenAt) >=
            props?.pendoGuideConfig?.timeIntervalHour &&
          guideInfo?.hasBeenSeen()
        ) {
          if (
            props?.pendoGuideConfig?.guideDismissCount &&
            guideInfo?.guideDismissCount >= props?.pendoGuideConfig?.guideDismissCount
          ) {
            window?.pendo?.showGuideById?.(props?.pendoGuideConfig?.guideId);
          } else {
            window?.pendo?.showGuideById?.(props?.pendoGuideConfig?.guideId);
          }
        }
      }
    };

    const mutationObserver = (mutation, observer) => {
      if (
        props?.pendoGuideConfig?.guideId &&
        props?.pendoGuideConfig?.selectorToCheck &&
        wrapperDivRef?.current?.querySelector(props?.pendoGuideConfig?.selectorToCheck) !== null
      ) {
        pendoFeatureGuideTrigger();
        observer?.disconnect();
      }
    };

    if (props?.pendoGuideConfig?.selectorToCheck) {
      useMutationObserver(wrapperDivRef, mutationObserver);
    }

    const resetConfirmationModal = (callback = () => {}) => {
      setConfirmationModalData({
        isShow: false,
        title: "",
        actionBtnText: "",
        rightBtnText: "",
        actionBtnCallback: () => {},
        rightBtnCallback: () => {},
      });
      callback();
      // this callback shouldn't depend on confirmation modal state as setstate is asynchronous
    };

    const showSuccessModal = (data, callback = () => {}) => {
      setSuccessModal(data);
      callback();
    };

    const resetSuccessModal = (callback = () => {}) => {
      setSuccessModal({
        isShow: false,
        title: "",
        actionBtnText: "",
        actionBtnCallback: () => {},
      });
      if (typeof callback === "function") {
        callback();
      }
    };

    const onToastDestroy = () => {
      if (toastMessageConfig?.onToastDestroy) {
        toastMessageConfig.onToastDestroy();
      }
      setToastMessageConfig({
        isShow: false,
      });
    };

    const showToast = () => {
      if (toastMessageConfig && toastMessageConfig.isShow && toastMessageConfig.messageToDisplay) {
        return <ToastV1 {...toastMessageConfig} onToastDestroy={onToastDestroy} />;
      }
    };

    const showToastMessage = (toastConfig) => {
      if (showNewToast || toastConfig.newToast) {
        const toastId = toastConfig?.toastId || "os-custom-toast-universal";
        const {
          type,
          messageToDisplay,
          toastDuration,
          onToastDestroy: cOnToastDestroy,
          ...rest
        } = toastConfig || {};
        const toastFunction =
          type && ToastV2[toastMapping[type]] ? ToastV2[toastMapping[type]] : ToastV2;
        const translatedMessage =
          typeof messageToDisplay === "string" ? t(messageToDisplay) : messageToDisplay;

        const toastAPIConfig = {
          autoClose: toastDuration,
          onClose: (...params) => {
            delete OS_ID_TO_TOAST_ID_MAPPING[toastId];
            if (cOnToastDestroy) {
              cOnToastDestroy(...params);
            }
          },
          ...rest,
        };

        if (OS_ID_TO_TOAST_ID_MAPPING[toastId]) {
          ToastV2.update(OS_ID_TO_TOAST_ID_MAPPING[toastId], {
            ...toastAPIConfig,
            type: type ? type.toLowerCase() : undefined,
            render: translatedMessage,
          });
        } else if (toastId && translatedMessage) {
          OS_ID_TO_TOAST_ID_MAPPING[toastId] = toastFunction(translatedMessage, toastAPIConfig);
        }

        return OS_ID_TO_TOAST_ID_MAPPING[toastId];
      } else {
        setToastMessageConfig({
          isShow: true,
          ...toastConfig,
        });
      }
    };

    const renderContent = () => {
      return (
        <>
          {!hocProps?.doNotShowConfirmationModal && (
            <ConfirmationModal
              closeButtonHandler={resetConfirmationModal}
              {...confirmationModalData}
            />
          )}
          <SuccessModal closeButtonHandler={(e) => resetSuccessModal()} {...successModalData} />
          {showToast()}
          <WrappedComponent
            {...props}
            ref={ref}
            showConfirmationModal={showConfirmationModal}
            resetConfirmationModal={resetConfirmationModal}
            showSuccessModal={showSuccessModal}
            resetSuccessModal={resetSuccessModal}
            showToastMessage={showToastMessage}
            pendoFeatureGuideTrigger={pendoFeatureGuideTrigger}
          />
        </>
      );
    };

    if (hocProps?.withWrapperDiv) {
      return (
        <div ref={wrapperDivRef} className={containerClass || ""}>
          {renderContent()}
        </div>
      );
    }

    return renderContent();
  });
};

OSHOCWithUtilities.defaultProps = {
  withWrapperDiv: false,
  containerClass: "",
};

export default OSHOCWithUtilities;
