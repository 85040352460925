import React, { useMemo, useState } from "react";
import { connect } from "react-redux";
import { Trans } from "react-i18next";

import { Form } from "@onlinesales-ai/form-components-v2";
import { FixLayout } from "@onlinesales-ai/fix-layout-v2";
import { configHoc, UserError } from "@onlinesales-ai/util-methods-v2";
import { loaderWhite } from "@onlinesales-ai/loader-v2";
import Card from "@onlinesales-ai/collapsible-card-v2";
import { OSHOCWithUtilities } from "@onlinesales-ai/os-hoc-with-utilities-v2";
import { InfoBanner } from "@onlinesales-ai/utils-components-v2";

import CouponCode from "../couponCode";
import defaultFormComponents from "./formComponents";

import TopupButton from "./topupButton";

import "./index.less";

const TopUp = ({
  controlled,
  expandable,
  isExpanded,
  onCardExpandCollapse,
  cardClass,
  paymentMethodProps,
  title,
  componentList,
  autoRenewalConfig,
  couponCodeConfig,
  listenToWalletLoading,
  enableSpendingLimit,
  showInCard,
  formComponents,
  topUpButtonProps,
  enablePayLater,
  payLaterText,
  payLaterCallback,
  ctaGuideText,
  infoBannerConfig,
  defaultTopUpAmount,
  renderTopupLastComponent,
  ...restProps
}) => {
  const [isLoading, setIsLoading] = useState(listenToWalletLoading);
  const [couponCodeDetails, setCouponCodeDetails] = useState({});
  const [maxSpendingLimit, setMaxSpendingLimit] = useState(0);

  const preparedFormComponents = useMemo(() => {
    return {
      ...defaultFormComponents,
      ...formComponents,
    };
  }, [formComponents]);

  const prepareComponentList = useMemo(() => {
    let newComponentList = componentList;

    if (enableSpendingLimit) {
      newComponentList = JSON.parse(
        JSON.stringify(newComponentList).replace(/__MAX_VALUE__/g, maxSpendingLimit),
      );
    }

    return newComponentList;
  }, [maxSpendingLimit]);

  const onWalletLoadingComplete = () => {
    setIsLoading(false);
  };

  const renderCTA = ({ disabled, values, checkIfError }) => {
    const beforePayment = async () => {
      const isError = await checkIfError();
      if (isError) {
        return Promise.reject(new UserError("Topup form validation failed"));
      }
      return Promise.resolve();
    };

    return (
      <TopupButton
        beforePayment={beforePayment}
        topUpAmount={values?.topUpAmount}
        disabled={disabled}
        isLoading={isLoading}
        {...topUpButtonProps}
        {...restProps}
        paymentMethodProps={{
          ...paymentMethodProps,
          couponCodeDetails,
          ...(topUpButtonProps.paymentMethodProps || {}),
          ...(restProps.paymentMethodProps || {}),
        }}
      />
    );
  };

  const onCouponCodeRemoved = () => {
    setCouponCodeDetails({});
  };

  const onCouponCodeApplied = () => {
    setCouponCodeDetails({});
  };

  const onCouponCodeVerified = (details = {}) => {
    setCouponCodeDetails(details);
  };

  const renderLastComponent = () => {
    return (
      <>
        {couponCodeConfig.isShow && (
          <CouponCode
            onCouponCodeApplied={onCouponCodeApplied}
            onCouponCodeRemoved={onCouponCodeRemoved}
            onCouponCodeVerified={onCouponCodeVerified}
            {...couponCodeConfig.props}
          />
        )}
        {renderTopupLastComponent()}
      </>
    );
  };

  const renderFistComponent = () => {
    if (infoBannerConfig) {
      return <InfoBanner {...infoBannerConfig} />;
    }
    return null;
  };

  const renderBody = (bodyProps) => {
    return (
      <>
        {isLoading && loaderWhite()}
        <Form
          {...bodyProps}
          stickyFooter
          initialValues={{ topUpAmount: defaultTopUpAmount || "" }}
          editMode={false}
          isSubmitDisable={false}
          componentList={prepareComponentList}
          formComponents={preparedFormComponents}
          fistComponent={renderFistComponent()}
          lastComponent={renderLastComponent()}
          childComponentProps={{
            onWalletLoadingComplete,
            setMaxSpendingLimit,
            formGroupInnerClassName: "mb-0",
          }}
          renderCTA={renderCTA}
        />
      </>
    );
  };

  if (showInCard) {
    return (
      <Card
        controlled={controlled}
        expandable={expandable}
        isExpanded={isExpanded}
        onCardExpandCollapse={onCardExpandCollapse}
        containerClass={`${cardClass || ""} topup-card-wrapper`}
        cardHeader={
          <div className="card-title">
            <Trans>{title}</Trans>
          </div>
        }
        cardBody={renderBody()}
      />
    );
  }

  return (
    <FixLayout className={`topup-form-wrapper ${isLoading ? "dont-show-form" : ""}`}>
      <FixLayout.Header>
        <Trans>{title}</Trans>
      </FixLayout.Header>
      {renderBody({
        wrapperClassName: "fixed-layout-body with-sticky-footer",
        bodyWrapperClass: "layout-sub-body",
      })}
    </FixLayout>
  );
};
const mapStateToProps = (state) => {
  const { commonConfigs } = state.DomainConfig;

  return {
    topUpButtonProps: commonConfigs?.topUpButtonProps || {},
    ...(commonConfigs?.topUpProps || {}),
  };
};

const WrapperComponent = configHoc(connect(mapStateToProps)(OSHOCWithUtilities(TopUp)), {
  isForm: true,
});

WrapperComponent.defaultProps = {
  // paymentMethod: PAYMENT_METHOD.RAZORPAY,
  paymentMethodProps: {},
  userInfo: {},
  title: "Add Money to Wallet",
  ctaText: "Top Up",
  enablePayLater: false,
  payLaterText: "Pay Later",
  payLaterCallback: () => {},
  jobDoneCallback: () => {},
  listenToWalletLoading: false,
  showCurrentWalletBalance: false,
  isSubscribeAndCharge: false,
  showTC: false,
  showInCard: false,
  billingDetails: {},
  ioProps: {},
  showConfirmationOnTopup: false,
  formComponents: {},
  componentToShow: ["topup"],
  componentConfig: {
    topup: {
      componentType: "TopupNew",
      doNotShowIfEditMode: true,
      props: {
        labelColumns: 12,
        dataKey: "topUpAmount",
        title: "Top-up Amount:",
        type: "number",
        showCurrency: true,
        // guidText: "Minimum Top-up Amount ₹5000 (Excluding 18% GST.)",
        guidText: "Minimum Top-up Amount ₹5000",
        validations: [
          {
            type: "nonEmpty",
            msg: "Please enter top-up amount",
          },
          {
            type: "minValue",
            value: 5000,
            msg: "Minimum top-up amount is ₹5000",
          },
        ],
      },
    },
  },
  couponCodeConfig: {
    isShow: false,
    props: {},
  },
  renderTopupLastComponent: () => {},
};

export default WrapperComponent;
