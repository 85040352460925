import React, { useMemo, useState, useEffect } from "react";
import { connect } from "react-redux";

import { Button } from "@onlinesales-ai/button-v2";
import { pendoTrackEvent, shouldRenderComponent } from "@onlinesales-ai/util-methods-v2";

const PWAInstallButton = ({ pwaConfig, isMobile }) => {
  const { installButton } = pwaConfig || {};
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isShowInstall, setIsShowInstall] = useState(false);

  const trackEventOnPendo = (eventName, e) => {
    const initialText = isMobile ? "PWA" : "PWA_DESKTOP";
    const eventText = `${initialText}||${eventName}`;
    pendoTrackEvent(eventText, e);
  };

  const isShow = useMemo(() => {
    return shouldRenderComponent(installButton);
  }, [installButton]);

  useEffect(() => {
    if (isShow) {
      window.addEventListener("beforeinstallprompt", (e) => {
        if (e === undefined) {
          trackEventOnPendo("NOT_SHOW", e);
        } else {
          e.preventDefault();
          setDeferredPrompt(e);
          setIsShowInstall(true);
          trackEventOnPendo("SHOWN", e);
        }
      });

      window.addEventListener("appinstalled", (e) => {
        trackEventOnPendo("INSTALLED", e);
        setIsShowInstall(false);
      });

      const isStandalone = window?.matchMedia("(display-mode: standalone)")?.matches;
      if (document?.referrer?.startsWith("android-app://")) {
        trackEventOnPendo("OPENED||TWA");
      } else if (isStandalone) {
        trackEventOnPendo("OPENED||ANDROID");
      } else if (navigator?.standalone) {
        trackEventOnPendo("OPENED||IOS");
      } else {
        trackEventOnPendo("OPENED||BROWSER");
      }
    }
  }, [isShow]);

  const installApp = async () => {
    trackEventOnPendo("INSTALL||CLICKED");
    try {
      trackEventOnPendo("PROMPT||SHOWN");
      deferredPrompt.prompt();

      const choiceResult = await deferredPrompt.userChoice;
      setDeferredPrompt(undefined);
      if (choiceResult.outcome === "accepted") {
        trackEventOnPendo("PROMPT||INSTALL");
        setIsShowInstall(false);
      } else {
        trackEventOnPendo("PROMPT||CANCEL");
      }
    } catch (e) {
      trackEventOnPendo("PROMPT||INSTALL||FAILED", e);
    }
  };

  if (isShow && isShowInstall) {
    return (
      <div className="d-align-center install-btn">
        <Button
          onClick={installApp}
          icon={isMobile ? "icon-download1" : null}
          iconWeight={!!isMobile}
          className="mr-3 install-pwa-button"
        >
          {!isMobile ? "INSTALL" : null }
        </Button>
      </div>
    );
  }

  return null;
};

const mapStateToProps = (state) => {
  const domainConfig = state.DomainConfig || {};
  const { pwaConfig } = domainConfig;
  return {
    pwaConfig,
    isMobile: state.Application.isMobile,
  };
};

export default connect(mapStateToProps)(PWAInstallButton);
