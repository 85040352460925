import React, { useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AddOnsHandler, DownloaderBar, HelpSection, OfflineToast } from "@onlinesales-ai/addons-handler-v2";
import { FluffyLinesAndWhatsNextWithEvent } from "@onlinesales-ai/fluffy-lines-whats-next-v2";
import { swithToNewToast, swithToNewConfirmartion } from "@onlinesales-ai/os-hoc-with-utilities-v2";
import { ClientSelection, LanguageSelection } from "@onlinesales-ai/utils-components-v2";

import AnnouncementWrapper from "src/components/announcement";
// import TncWrapper from "src/components/tncWrapper";
import { redirectUrl } from "src/store/application";
import { routes } from "src/utilities/constants";
import { postOnboardingData as postOnboardingDataAction } from "src/store/onBoarding";
import { configOverrides, isStagingDomain, isLngDomain } from "src/domainConfig/configuration";

import "./addOns.less";

const annoucementNotShowRoutes = [
  routes.ONBOARDING.path,
  "/drawer/SIGN_AGREEMENT",
  "/activate",
  "/notSigned",
];

swithToNewConfirmartion();
swithToNewToast();

const AddOns = ({
  redirect,
  location,
  helpSection,
  clientId,
  postOnboardingData,
  isInternalUser,
}) => {
  const isOnOnboardingPage = location?.pathname?.includes(routes.ONBOARDING.path);

  const blockAnnoucementRoute = useMemo(() => {
    return annoucementNotShowRoutes.find((route) => {
      return location?.pathname?.includes(route);
    });
  }, [location?.pathname]);

  return (
    <AddOnsHandler
      showToastMessage
      location={location}
      postOnboardingData={postOnboardingData}
      isOnOnboardingPage={isOnOnboardingPage}
      redirectUrl={redirect}
    >
      <OfflineToast />
      <FluffyLinesAndWhatsNextWithEvent eventToListen="SHOW_FLUFFY_LINES_AND_WHATS_NEXT" />
      <div className="addons-floating-btn-wrapper">
        <div className="addons-floating-btn-inner">
          {!blockAnnoucementRoute && <AnnouncementWrapper />}
          {!!helpSection?.isShow && (
            <HelpSection redirectUrl={redirect} {...(helpSection?.props || {})} />
          )}
        </div>
      </div>
      <DownloaderBar clientId={clientId} isClientLevel isEntityLevel />
      {/* <TncWrapper /> */}
      {isInternalUser && isStagingDomain ? (
        <ClientSelection configOverrides={configOverrides} uiId="m" />
      ) : null}
      {isInternalUser || isLngDomain ? (
        <LanguageSelection uiId="m" />
      ) : null}
    </AddOnsHandler>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      postOnboardingData: postOnboardingDataAction,
      redirect: redirectUrl,
    },
    dispatch,
  );
};

const mapStateToProps = (state) => {
  const { helpSection } = state.DomainConfig || {};
  const { clientId, isInternalUser } = state.Application || {};

  return {
    location: state.router.location,
    helpSection,
    clientId,
    isInternalUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddOns);
