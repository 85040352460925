import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { setLocale } from "@onlinesales-ai/datepicker-v2";

import { getLocalLoader } from "./localLoader";

const fallbackLng = "en";
const isDevMode = NODE_ENV === "development";

if (isDevMode) {
  window.translations = {};
}

export const initializeTranslations = ({
  languagesSupported = ["vi", "vi_sendo", "vi_haraads", "en", "id", "ru"],
  loadedTranslations = {},
  lazyTranslations = {},
  restOptions,
}) => {
  const LocalLoader = getLocalLoader({
    loadedTranslations,
    lazyTranslations,
  });

  return i18n
    .use(LanguageDetector)
    .use(LocalLoader)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      // resources,
      keySeparator: false, // we do not use keys in form messages.welcome
      // debug: isDevMode,
      nsSeparator: false, // allow keys to be phrases having `:`, `.`
      detection: {
        order: ["querystring"],
      },
      ns: ["allTranslations"],
      defaultNS: "allTranslations",
      languages: languagesSupported,
      saveMissing: isDevMode,
      missingKeyHandler: ([lng], ns, key, fallbackValue) => {
        window.translations[lng] = window.translations[lng] || {};
        window.translations[lng][key] = fallbackValue;
      },
      interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ",",
      },
      fallbackLng: [fallbackLng],
      // react i18next special options (optional)
      react: {
        wait: true,
        withRef: true,
        bindI18n: "languageChanged loaded",
        bindStore: "added removed",
        nsMode: "default",
        useSuspense: true,
      },
      ...restOptions,
    });
};

i18n.on("loaded", () => {
  let lng = i18n.language;
  if (["vi_sendo", "vi_haraads"].includes(i18n.language)) {
    lng = "vi";
  }
  if (["id_mitsubishi"].includes(i18n.language)) {
    lng = "id";
  }
  setLocale(lng);
});

export const setDefaultNameSpace = (nameSpace) => {
  if (nameSpace) {
    i18n.setDefaultNamespace(nameSpace);
  }
};

export const changeLanguage = (language) => {
  if (language) {
    i18n.changeLanguage(language, (err) => {
      if (err) {
        console.error(`Couldn't load language ${language}`, err);
      }
    });
  }
};

export const getCurrentLanguage = (isAbsolute = false) => {
  let lng = typeof i18n.language === "undefined" || i18n.language === "undefined" ? fallbackLng : i18n.language;

  if (isAbsolute) {
    if (["vi_sendo", "vi_haraads"].includes(lng)) {
      lng = "vi";
    }
    if (["id_mitsubishi"].includes(lng)) {
      lng = "id";
    }
  }

  return lng;
};

export default i18n;
