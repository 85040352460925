export const indianLanguages = [
  {
    label: "English",
    value: "en",
  },
  {
    label: "Assamese",
    value: "as",
  },
  {
    label: "Bengali",
    value: "bn",
  },
  {
    label: "Bihari languages",
    value: "bh",
  },
  {
    label: "Gujarati",
    value: "gu",
  },
  {
    label: "Hindi",
    value: "hi",
  },
  {
    label: "Kannada",
    value: "kn",
  },
  {
    label: "Kashmiri",
    value: "ks",
  },
  {
    label: "Malayalam",
    value: "ml",
  },
  {
    label: "Marathi",
    value: "mr",
  },
  {
    label: "Nepali",
    value: "ne",
  },
  {
    label: "Punjabi",
    value: "pa",
  },
  {
    label: "Sanskrit",
    value: "sa",
  },
  {
    label: "Sindhi",
    value: "sd",
  },
  {
    label: "Tamil",
    value: "ta",
  },
  {
    label: "Telugu",
    value: "te",
  },
  {
    label: "Urdu",
    value: "ur",
  },
  {
    label: "Odia",
    value: "or",
  },
];

export const yearsTillDate = (() => {
  const list = [];
  let currentYear = new Date().getFullYear();

  for (let i = currentYear; i >= 1980; i--) {
    list.push({
      label: `${i}`,
      value: `${i}`,
    });
  }

  return list;
})();

export const gmbPageCategories = async () => {
  let result = [];
  let response = await fetch("https://run.mocky.io/v3/9344ab0c-5231-4482-92d7-3b845f509044");
  let res = await response.json();
  result = res.categories || [];
  return result;
};

export const facebookPageCTAs = [
  {
    label: "Shop Now",
    value: "SHOP_NOW",
  },
  {
    label: "Call",
    value: "CALL",
  },
  {
    label: "Call me",
    value: "CALL_ME",
  },
  {
    label: "Video call",
    value: "VIDEO_CALL",
  },
  {
    label: "Install Mobile App",
    value: "INSTALL_MOBILE_APP",
  },
  {
    label: "Use Mobile App",
    value: "USE_MOBILE_APP",
  },
  {
    label: "Mobile Download",
    value: "MOBILE_DOWNLOAD",
  },
  {
    label: "Book Now",
    value: "BOOK_TRAVEL",
  },
  {
    label: "Listen Music",
    value: "LISTEN_MUSIC",
  },
  {
    label: "Watch Video",
    value: "WATCH_VIDEO",
  },
  {
    label: "Learn More",
    value: "LEARN_MORE",
  },
  {
    label: "Sign Up",
    value: "SIGN_UP",
  },
  {
    label: "Download",
    value: "DOWNLOAD",
  },
  {
    label: "Watch More",
    value: "WATCH_MORE",
  },
  {
    label: "No Button",
    value: "NO_BUTTON",
  },
  {
    label: "Visit Pages Feed",
    value: "VISIT_PAGES_FEED",
  },
  {
    label: "Apply Now",
    value: "APPLY_NOW",
  },
  {
    label: "Contact",
    value: "CONTACT",
  },
  {
    label: "Buy Now",
    value: "BUY_NOW",
  },
  {
    label: "Get Offer",
    value: "GET_OFFER",
  },
  {
    label: "Get Offer View",
    value: "GET_OFFER_VIEW",
  },
  {
    label: "Buy Tickets",
    value: "BUY_TICKETS",
  },
  {
    label: "Update App",
    value: "UPDATE_APP",
  },
  {
    label: "Buy",
    value: "BUY",
  },
  {
    label: "Donate",
    value: "DONATE",
  },
  {
    label: "Subscribe",
    value: "SUBSCRIBE",
  },
  {
    label: "Say Thanks",
    value: "SAY_THANKS",
  },
  {
    label: "Sell Now",
    value: "SELL_NOW",
  },
  {
    label: "Share",
    value: "SHARE",
  },
  {
    label: "Donate Now",
    value: "DONATE_NOW",
  },
  {
    label: "Get Quote",
    value: "GET_QUOTE",
  },
  {
    label: "Contact Us",
    value: "CONTACT_US",
  },
  {
    label: "Start Order",
    value: "START_ORDER",
  },
  {
    label: "Add To Cart",
    value: "ADD_TO_CART",
  },
  {
    label: "Video Annotation",
    value: "VIDEO_ANNOTATION",
  },
  {
    label: "Moments",
    value: "MOMENTS",
  },
  {
    label: "Record Now",
    value: "RECORD_NOW",
  },
  {
    label: "Refer Friends",
    value: "REFER_FRIENDS",
  },
  {
    label: "Request Time",
    value: "REQUEST_TIME",
  },
  {
    label: "Get Showtimes",
    value: "GET_SHOWTIMES",
  },
  {
    label: "Listen Now",
    value: "LISTEN_NOW",
  },
  {
    label: "Woodhenge Support",
    value: "WOODHENGE_SUPPORT",
  },
  {
    label: "Sotto Subscribe",
    value: "SOTTO_SUBSCRIBE",
  },
  {
    label: "Follow User",
    value: "FOLLOW_USER",
  },
  {
    label: "Event Rsvp",
    value: "EVENT_RSVP",
  },
  {
    label: "Follow News Storyline",
    value: "FOLLOW_NEWS_STORYLINE",
  },
  {
    label: "See More",
    value: "SEE_MORE",
  },
  {
    label: "Find A Group",
    value: "FIND_A_GROUP",
  },
  {
    label: "Find Your Groups",
    value: "FIND_YOUR_GROUPS",
  },
  {
    label: "Pay To Access",
    value: "PAY_TO_ACCESS",
  },
  {
    label: "Purchase Gift Cards",
    value: "PURCHASE_GIFT_CARDS",
  },
  {
    label: "Follow Page",
    value: "FOLLOW_PAGE",
  },
  {
    label: "Send A Gift",
    value: "SEND_A_GIFT",
  },
  {
    label: "Swipe Up Shop",
    value: "SWIPE_UP_SHOP",
  },
  {
    label: "Swipe Up Product",
    value: "SWIPE_UP_PRODUCT",
  },
  {
    label: "Send Gift Money",
    value: "SEND_GIFT_MONEY",
  },
];
