import React from "react";

import { Drawer } from "@onlinesales-ai/drawer-v2";

import IO from "./index";

const IODrawer = ({ isOpen, onClickClose, ...rest }) => {
  return (
    <Drawer size="medium" containerClass="io-drawer" isOpen={isOpen} onClickClose={onClickClose}>
      <IO jobDoneCallback={onClickClose} {...rest} />
    </Drawer>
  );
};

export default IODrawer;
