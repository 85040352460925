import AppActionTypes from "@onlinesales-ai/app-v2/application/types";
import GoalsReducer from "@onlinesales-ai/app-v2/goal/reducer";

import Types from "./types";
import SuggestionTypes from "../suggestions/types";

const initialState = {
  isFetchInProgress: true,
  goalDetails: {
    // 123: {
    //   isFetchInProgress: false,
    //   data: {},
    // },
  },
  consolidatedGoalData: {
    // budgetInfo: {
    //   channelWise: [
    //     {
    //       spendCap: 5000,
    //       channelInfo: {
    //         GOOGLE: {
    //           "goalIds": [
    //             64327
    //           ],
    //           "status": "PAUSED",
    //           "type": "VENDOR",
    //           "goalStatus": "PAUSED"
    //         }
    //       }
    //     }
    //   ]
    // },
    // currency: "INR",
    // country: "india",
    // bidInfo: null
  },
  reportingDataForXDays: {},
};

const reducer = (state = initialState, action) => {
  let hasChanged = true;
  switch (action.type) {
    case AppActionTypes.APP_CLIENT_CHANGE: {
      state = {
        ...initialState,
      };
    }
      break;
    case SuggestionTypes.SUGGESTION_ACTED:
      {
        const copyGoals = { ...state.goalDetails };

        Object.keys(copyGoals).forEach((id) => {
          if (copyGoals[id]?.suggestions?.length) {
            copyGoals[id].suggestions = copyGoals[id]?.suggestions.filter(
              (s) => s._id !== action.suggestionId,
            );
          }
        });

        state = {
          ...state,
          goalDetails: copyGoals,
        };
      }
      break;

    case Types.SET_CONSOLIDATED_GOAL_DATA:
      {
        state = {
          ...state,
          consolidatedGoalData: action.data || {}
        };
      }
      break;
    default:
      hasChanged = false;
      break;
  }
  if (!hasChanged) {
    return GoalsReducer(state, action, initialState);
  }
  return state;
};

export default {
  Goals: reducer,
};
