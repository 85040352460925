import React from "react";
import AsyncSelect from "react-select/async";

import BaseDropdown from "./BaseDropdown";

const DropdownAsync = (props) => {
  return <BaseDropdown {...props} SelectComponent={AsyncSelect} />;
};

export default DropdownAsync;
