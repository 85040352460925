import React from "react";
import _get from "lodash/get";
import { withTranslation } from "react-i18next";

import classnames from "classnames";

import { RadioGroup, RadioGroupTab } from "@onlinesales-ai/radio-v2";
import AsyncImage from "@onlinesales-ai/async-image-v2";
import { Text } from "@onlinesales-ai/label-v2";

import FormWrapper from "../../FormWrapper";

import "./index.less";

class Radio extends React.Component {
  constructor(props) {
    super(props);

    this.validate(this.getValue());
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const newValue = _get(nextProps.formValues, nextProps.dataKey);

    if (newValue !== this.getValue()) {
      this.validate(newValue);
    }
  }

  getValue = () => {
    const { dataKey, formValues } = this.props;
    return _get(formValues, dataKey);
  };

  validate = (value) => {
    const { onError, dataKey, validations = [], title } = this.props;
    let errorMsg = null;

    for (let i = 0; i < validations.length; i++) {
      if (!validations[i].type || !validations[i].msg) {
        continue;
      }

      switch (validations[i].type) {
        case "nonEmpty":
          {
            if (!value) {
              errorMsg = validations[i].msg;
            }
          }
          break;
        default:
          break;
      }

      if (errorMsg) {
        errorMsg = errorMsg.replace("__FIELD_TITLE__", title.toLowerCase());
        break;
      }
    }

    onError({ [dataKey]: errorMsg });
  };

  onChangeRadio = (value) => {
    const { onChange, dataKey } = this.props;
    onChange({
      [dataKey]: value,
    });
  };

  render() {
    const {
      // FormWrapper props
      labelColumns,
      formGroupClassName,
      formGroupInnerClassName,
      guidText,
      guidElement,
      guidElementClass,
      apiError,
      warning,
      title,
      titleTooltip,
      titleGuidText,
      infoTooltip,
      errorClassName,
      showRadioNonEditable,

      // tag input props
      dataKey,
      isEditable,
      showErrors,
      formErrors,
      disabled,
      options,
      layout,
      name,
      defaultValue,
      labelDefinationKey,
      useRadioTab,
      iconUrl,
      className,
      isRequired,
      onlyBorder,
      tabsWrapperClass,
      t,
      allowUndefined,
      nonEditModeClass,
      extraLabelProps,
      innerLabelClass,
      labelClassName,
      labelBottomComponent,
    } = this.props;

    const value = this.getValue();
    const selectedOption = options?.find?.((o) => o?.value === value);
    const imageToUse = selectedOption?.iconUrl;
    const iconToUse = selectedOption?.icon;
    const labelToUse = selectedOption?.label;
    const labelDefinationKeyToUse = selectedOption?.labelDefinationKey;

    return (
      <FormWrapper
        labelColumns={labelColumns}
        formGroupClassName={formGroupClassName}
        hasError={showErrors && formErrors[dataKey]}
        error={formErrors[dataKey]}
        apiError={apiError}
        guidElement={guidElement}
        guidText={guidText}
        guidElementClass={guidElementClass}
        warning={warning}
        title={title}
        titleTooltip={titleTooltip}
        titleGuidText={titleGuidText}
        isEditable={isEditable}
        infoTooltip={infoTooltip}
        isRequired={isRequired}
        labelDefinationKey={labelDefinationKey}
        formGroupInnerClassName={formGroupInnerClassName}
        errorClassName={errorClassName}
        extraLabelProps={extraLabelProps}
        innerLabelClass={innerLabelClass}
        labelClassName={labelClassName}
        labelBottomComponent={labelBottomComponent}
      >
        {isEditable || showRadioNonEditable ? (
          <>
            {useRadioTab ? (
              <RadioGroupTab
                name={name}
                onlyBorder={onlyBorder}
                groupValue={value}
                data={options}
                onChange={this.onChangeRadio}
                disabled={disabled}
                className={className}
                layout={layout}
                defaultValue={defaultValue}
                iconUrl={iconUrl}
                labelDefinationKey={labelDefinationKey}
                tabsWrapperClass={tabsWrapperClass}
                allowUndefined={allowUndefined}
              />
            ) : (
              <RadioGroup
                name={name}
                groupValue={value}
                data={options}
                onChange={this.onChangeRadio}
                disabled={disabled}
                layout={layout}
                className={className}
                defaultValue={defaultValue}
                labelDefinationKey={labelDefinationKey}
              />
            )}
          </>
        ) : (
          <div className="non-edit-mode-text">
            {useRadioTab ? (
              <div className={classnames("radio-tab-edit-mode", nonEditModeClass)}>
                {imageToUse && <AsyncImage imgSrc={imageToUse || value} />}
                <>
                  {labelDefinationKeyToUse || value ? (
                    <Text
                      iconClassName="mr-2 lh0"
                      icon={iconToUse || value}
                      labelDefinationKey={
                        labelDefinationKeyToUse || value
                      }
                    />
                  ) : (
                    <Text iconClassName="mr-2 lh0" icon={iconToUse || value}>{labelToUse || value}</Text>
                  )}
                </>
              </div>
            ) : (
              <>{t(labelToUse || value)}</>
            )}
          </div>
        )}
      </FormWrapper>
    );
  }
}

Radio.defaultProps = {
  dataKey: null,
  name: "form-radio",
  title: "",
  formGroupClassName: "form-component-radio",
  labelColumns: 3,
  options: [],
  validations: [
    {
      type: "nonEmpty",
      msg: "Please select __FIELD_TITLE__",
    },
  ],
  useRadioTab: false,
};

export default withTranslation()(Radio);
