import parse from "html-react-parser";

import { sanitizeHtml } from "./sanitizer";

export const htmlToReact = (
  htmlString,
  { senitizeHtml = true, senitizeHtmlOptions, ...options } = {},
) => {
  let htmlToRender = htmlString;

  if (senitizeHtml) {
    htmlToRender = sanitizeHtml(htmlString, senitizeHtmlOptions);
  }

  return parse(htmlToRender, options);
};
