import _debounce from "lodash/debounce";
import { isAfter } from "date-fns/esm";

const addNewFeatureTagClasses = ({
  selectors,
  className = "new-feature-tag",
}) => {
  selectors?.forEach(({
    selector,
    variantClasses = ["top-left"],
    expiryTimestamp,
  }) => {
    const currentDateEpoch = (new Date()).valueOf();
    const isExpired = expiryTimestamp ? (isAfter(currentDateEpoch, expiryTimestamp)) : false;
    if (isExpired) {
      return;
    }

    // selector class can be any valid selector e.g. "#root body"
    const selectedDOMNodeList = document.querySelectorAll(selector);
    const selectedDOMNodes = Array.from(selectedDOMNodeList);
    if (selectedDOMNodes?.length) {
      selectedDOMNodes?.forEach((domNode) => {
        const classListArray = Array.from(domNode?.classList);
        const shouldAddClass = (
          !classListArray.length ||
          !classListArray.includes(className)
        );
        if (
          shouldAddClass
        ) {
          domNode?.classList.add(className, ...variantClasses);
        }
      });
    }
  });
};

export const newFeatureTriggerCallback = _debounce(addNewFeatureTagClasses, 1000);
