/* eslint class-methods-use-this: 0 */
/* eslint consistent-return: 0 */

import { addLocale } from "@onlinesales-ai/datepicker-v2";
import { setCurrentLocale } from "@onlinesales-ai/util-methods-v2";

export const getLocalLoader = ({
  loadedTranslations,
  lazyTranslations,
}) => {
  class LocalLoader {
    read(language, namespace, callback) {
      let lngToLoad = language;
      if (Array.isArray(language) && language.length) {
        lngToLoad = language[0];
      }

      if (lngToLoad === "vi_haraads") {
        lngToLoad = "vi";
      }

      const loadedData = loadedTranslations[lngToLoad];

      if (loadedData) {
        callback(null, loadedData);
      } else {
        const loader = lazyTranslations[lngToLoad];
        if (!loader) return callback(null, true);
        loader()
          .then((res) => {
            const { translations, locale, localeCode } = res.default || {};

            // set react-datepicker locale
            if (localeCode && locale) {
              addLocale(localeCode, locale);
              setCurrentLocale(locale);
            }

            callback(null, translations);
          })
          .catch((err) => callback(err, false));
      }
    }
  }

  LocalLoader.type = "backend";

  return LocalLoader;
};
