import React, { useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import _debounce from "lodash/debounce";

import { pendoTrackEvent } from "@onlinesales-ai/util-methods-v2";

const isSupported = !!window?.IntersectionObserver && !!window?.MutationObserver;

const ViewPortTracking = ({ viewTracking }) => {
  const viewTrackingConfig = useRef({});
  const [started, setStarted] = useState({ triggered: false });

  const intersectionObserver = useMemo(() => {
    if (!isSupported) {
      return false;
    }

    const local = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const config = viewTrackingConfig.current[entry.target.id];
          if (entry.isIntersecting && config) {
            setTimeout(() => {
              pendoTrackEvent(config.eventName, config.eventData);
            }, 0);
            if (config.singleEventInSession) {
              local.unobserve(entry.target);
              delete viewTrackingConfig.current[entry.target.id];

              if (!Object.keys(viewTrackingConfig.current).length) {
                setStarted({ triggered: true });
              }
            }
          }
        });
      },
      {
        rootMargin: "0px",
        threshold: 0.6,
      },
    );

    return local;
  }, []);

  const debounceFindElement = useRef(
    _debounce(() => {
      Object.keys(viewTrackingConfig.current).forEach((ele) => {
        const idEle = document.getElementById(ele);

        if (idEle) {
          intersectionObserver.unobserve(idEle);
          intersectionObserver.observe(idEle);
        }
      });
    }, 2000),
  );

  const mutationObserver = useMemo(() => {
    if (!isSupported) {
      return false;
    }

    return new MutationObserver(debounceFindElement.current);
  }, [debounceFindElement]);

  useEffect(() => {
    if (isSupported && mutationObserver) {
      if (Object.keys(viewTrackingConfig.current).length) {
        mutationObserver.observe(document.body, { attributes: false, childList: true });
      } else if (started.triggered) {
        mutationObserver.disconnect();
      }
    }
  }, [started]);

  useEffect(() => {
    if (isSupported) {
      if (!started.triggered && Object.keys(viewTracking).length) {
        viewTrackingConfig.current = { ...viewTracking };
        setStarted({ triggered: true });
      }
    }
  }, [viewTracking, started]);

  return null;
};

const mapStateToProps = (state) => {
  const domainConfig = state.DomainConfig;

  return {
    viewTracking: domainConfig?.viewTracking || {},
    // viewTracking config
    // industryBenchmarkV2: {
    //   singleEventInSession: false,
    //   eventName: "Dashboard -> Industry Benchmark View",
    //   eventData: {},
    // },
  };
};

export default connect(mapStateToProps)(ViewPortTracking);
