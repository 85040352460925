export const smmPostStatusEnum = {
  FAILED: {
    key: "FAILED",
    displayName: "Failed",
    colorClass: "red",
    priority: 2
  },
  ACTIVE: {
    key: "ACTIVE",
    displayName: "Active",
    colorClass: "green",
    priority: 3
  },
  PROCESSING: {
    key: "PROCESSING",
    displayName: "Processing",
    colorClass: "yellow",
    priority: 3
  },
  SCHEDULED: {
    key: "SCHEDULED",
    displayName: "Scheduled",
    colorClass: "green",
    priority: 3
  },
  DELETED: {
    key: "DELETED",
    displayName: "Deleted",
    colorClass: "red",
    priority: 4
  },
  DRAFT: {
    key: "DRAFT",
    displayName: "Draft",
    colorClass: "yellow",
    priority: 1
  },
};

export const keysToDelete = {
  value: ["media", "postData"],
};

export const postCreationErrorMessages = {
  "Make sure that your Page post includes an image that can be used in an ad.": "Make sure that your Page post includes an image that can be used in an ad.",
  "Your photos couldn't be uploaded. Photos should be smaller than 4 MB and saved as JPG, PNG, GIF, TIFF, HEIF or WebP files.": "Your photos couldn't be uploaded. Photos should be smaller than 4 MB and saved as JPG, PNG, GIF, TIFF, HEIF or WebP files.",
  // "We're unable to process your Facebook Page Post.": "We're unable to process your Facebook Page Post.",
  "Creating/Updating a local post is not authorized for this location.": "Creating/Updating a local post is not authorized for this location. Please re-link your GMB page and retry post.",
  // "Request contains an invalid argument.": "Request contains an invalid argument.",
  "User does not have access to given Facebook entity": "User does not have access to given Facebook page, Please re-link your facebook page and retry post",
};
