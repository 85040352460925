import { vendorChannelMapping } from "./accountLinking";

export const templateDistinctionEnum = {
  PREMIUM: "PREMIUM",
  NON_PREMIUM: "NON_PREMIUM",
};

export const creativeType = {
  MPA: {
    key: "MPA",
    vendorChannelName: vendorChannelMapping.FACEBOOK.key,
    displayName: "Facebook MPA",
    serviceName: "FACEBOOK_MPA",
    templateDataKey: "MPATemplates",
  },
  PPLA: {
    key: "PPLA",
    vendorChannelName: vendorChannelMapping.FACEBOOK.key,
    displayName: "Facebook PPLA",
    serviceName: "FACEBOOK_PPLA",
    templateDataKey: "PPLATemplates",
  },
  GOOGLE_SEARCH: {
    key: "GOOGLE_SEARCH",
    vendorChannelName: vendorChannelMapping.GOOGLE_SEARCH.key,
    displayName: "Google Search",
    serviceName: "GOOGLE_EXPANDED_TEXT_AD",
  },
  GOOGLE_SEARCH_TEXT_AD: {
    key: "GOOGLE_SEARCH_TEXT_AD",
    vendorChannelName: vendorChannelMapping.GOOGLE_SEARCH.key,
    displayName: "Google Search",
    serviceName: "GOOGLE_SEARCH_TEXT_AD",
  },
  GOOGLE_DSA_TEXT_AD: {
    key: "GOOGLE_DSA_TEXT_AD",
    vendorChannelName: vendorChannelMapping.GOOGLE_SEARCH.key,
    displayName: "Google Search",
    serviceName: "GOOGLE_DSA_TEXT_AD",
  },
  GOOGLE_SHOPPING: {
    key: "GOOGLE_SHOPPING",
    vendorChannelName: vendorChannelMapping.GOOGLE_PLA.key,
    displayName: "Google Shopping",
    serviceName: "GOOGLE_SHOPPING_AD",
  },
  DPA: {
    key: "DPA",
    vendorChannelName: vendorChannelMapping.FACEBOOK_DPA.key,
    displayName: "Facebook DPA",
    serviceName: "FACEBOOK_DPA",
    templateDataKey: "DPATemplates",
  },
  INSTAGRAM: {
    key: "INSTAGRAM",
    vendorChannelName: vendorChannelMapping.FACEBOOK_DPA.key,
    displayName: "Instagram",
    serviceName: "FACEBOOK_DPA"
    // serviceName: ["FACEBOOK_DPA", "FACEBOOK_PPLA"]
  },
  DISPLAY_RESPONSIVE_AD: {
    key: "DISPLAY_RESPONSIVE_AD",
    vendorChannelName: vendorChannelMapping.GOOGLE_DISPLAY.key,
    displayName: "Display Ads",
    serviceName: "DISPLAY_RESPONSIVE_AD",
    templateDataKey: "DRMTemplates",
  },
  EMAIL: {
    key: "EMAIL",
    vendorChannelName: vendorChannelMapping.EMAIL.key,
    displayName: "EMAIL",
    serviceName: "EMAIL",
    templateDataKey: "DRMTemplates",
  },
  WHATSAPP: {
    key: "WHATSAPP",
    vendorChannelName: vendorChannelMapping.WHATSAPP.key,
    displayName: "WHATSAPP",
    serviceName: "WHATSAPP",
    templateDataKey: "DRMTemplates",
  },
  // COLLECTION_ADS: {
  //   key: "COLLECTION_ADS",
  //   vendorChannelName: vendorChannelMapping.FACEBOOK.key,
  //   displayName: "Collection Ads",
  //   serviceName: "Collection Ads",
  // },
  // CANVAS_ADS: {
  //   key: "CANVAS_ADS",
  //   vendorChannelName: vendorChannelMapping.FACEBOOK.key,
  //   displayName: "Canvas Ads",
  //   serviceName: "Canvas Ads",
  // },
  BRAND_ADS: {
    key: "BRAND",
    displayName: "Brand Ads",
  }
};

export const creativeSubAdType = {
  FACEBOOK_AD: {
    key: "FACEBOOK_AD",
    displayName: "Facebook Ad",
    serviceName: "FACEBOOK_AD",
  },
  LEAD_AD: {
    key: "LEAD_AD",
    displayName: "Lead Form",
    serviceName: "LEAD_AD",
  },
  BRAND_AWARENESS_AD: {
    key: "BRAND_AWARENESS_AD",
    displayName: "Brand Awareness Ad",
    serviceName: "BRAND_AWARENESS_AD",
  },
  MESSENGER_AD: {
    key: "MESSENGER_AD",
    displayName: "Messenger Ad",
    serviceName: "MESSENGER_AD",
  },
  CALL_ONLY_AD: {
    key: "CALL_ONLY_AD",
    displayName: "Call Ad",
    serviceName: "CALL_ONLY_AD",
  },
  EXPANDED_TEXT_AD: {
    key: "EXPANDED_TEXT_AD",
    displayName: "Expanded Text Ad",
    serviceName: "EXPANDED_TEXT_AD",
  },
  EXPANDED_DYNAMIC_SEARCH_AD: {
    key: "EXPANDED_DYNAMIC_SEARCH_AD",
    displayName: "Expanded Dynamic Ad",
    serviceName: "EXPANDED_DYNAMIC_SEARCH_AD",
  },
  GOOGLE_DSA_TEXT_AD: {
    key: "GOOGLE_DSA_TEXT_AD",
    displayName: "Dynamic Search Ad",
    serviceName: "GOOGLE_DSA_TEXT_AD",
  },
  RESPONSIVE_SEARCH_AD: {
    key: "RESPONSIVE_SEARCH_AD",
    displayName: "Responsive Search Ad",
    serviceName: "RESPONSIVE_SEARCH_AD",
  },
};

export const entityLevelEnum = {
  CLIENT: "CLIENT",
  MARKETING_CAMPAIGN: "MARKETING_CAMPAIGN",
};

export const templateTypeEnum = {
  STATIC: "STATIC",
  DYNAMIC: "DYNAMIC",
};

export const creativesStatusEnum = {
  DRAFT: "DRAFT",
  ACTIVE: "ACTIVE",
  PAUSED: "PAUSED",
  LAUNCH_INPROGRESS: "LAUNCH_INPROGRESS",
};

export const templateLevelEnum = {
  CLIENT: "CLIENT",
};

export const callToActions = {
  NO_BUTTON: "No Button",
  SHOP_NOW: "Shop Now",
  BUY_NOW: "Buy Now",
  SELL_NOW: "Sell Now",
  LEARN_MORE: "Learn More",
  CONTACT_US: "Contact Us",
  SEND_MESSAGE: "Send Message",
  SIGN_UP: "Sign Up",
  APPLY_NOW: "Apply Now",
  DOWNLOAD: "Download",
  GET_QUOTE: "Get Quote",
  SUBSCRIBE: "Subscribe",
  BOOK_TRAVEL: "Book Now",
  GET_SHOWTIMES: "Get Showtimes",
  REQUEST_TIME: "Request Time",
  SEE_MENU: "See Menu",
  LISTEN_NOW: "Listen Now",
  OPEN_LINK: "Open Link",
  CHECK_AVAILABILITY: "Check Availability",
  CALL_NOW: "Call Now",
  ORDER_NOW: "Order Now",
};

export const subAdTypeWiseCTAsForFB = {
  default: [
    "SHOP_NOW",
    "BUY_NOW",
    "SELL_NOW",
    "LEARN_MORE",
    "CONTACT_US",
    "SEND_MESSAGE",
    "SIGN_UP",
    "APPLY_NOW",
    "DOWNLOAD",
    "GET_QUOTE",
    "SUBSCRIBE",
    "BOOK_TRAVEL",
    "GET_SHOWTIMES",
    "REQUEST_TIME",
    "SEE_MENU",
    "LISTEN_NOW",
    "CHECK_AVAILABILITY",
    "NO_BUTTON",
  ],
  [creativeSubAdType.FACEBOOK_AD.key]: [
    "SHOP_NOW",
    "BUY_NOW",
    "SELL_NOW",
    "LEARN_MORE",
    "CONTACT_US",
    "SIGN_UP",
    "BOOK_TRAVEL",
    "ORDER_NOW",
  ],
  [creativeSubAdType.LEAD_AD.key]: [
    "APPLY_NOW",
    "DOWNLOAD",
    "GET_QUOTE",
    "LEARN_MORE",
    "SIGN_UP",
    "SUBSCRIBE",
  ],
  [creativeSubAdType.BRAND_AWARENESS_AD.key]: [
    "GET_QUOTE",
    "GET_SHOWTIMES",
    "LEARN_MORE",
    "REQUEST_TIME",
    "SEE_MENU",
    "SHOP_NOW",
    "SIGN_UP",
    "SUBSCRIBE",
    "SEND_MESSAGE",
    "LISTEN_NOW",
    "APPLY_NOW",
    "BOOK_TRAVEL",
    "CONTACT_US",
    "DOWNLOAD",
    "NO_BUTTON",
  ],
  [creativeSubAdType.MESSENGER_AD.key]: [
    "GET_QUOTE",
    "LEARN_MORE",
    "SELL_NOW",
    "SHOP_NOW",
    "SIGN_UP",
    "SUBSCRIBE",
    "SEND_MESSAGE",
    "APPLY_NOW",
    "BOOK_TRAVEL",
    "CONTACT_US",
  ],
  DPA_AD: [
    "SHOP_NOW",
    "LEARN_MORE",
    "GET_SHOWTIMES",
    "SIGN_UP",
    "DOWNLOAD",
    "OPEN_LINK",
    "LISTEN_NOW",
    "SUBSCRIBE",
    "CHECK_AVAILABILITY",
  ],
};

export const creativeAdTypeByConfig = {
  DISPLAY_RESPONSIVE_AD: [
    "Shop Now",
    "See More",
    "Contact Us",
    "Learn More",
    "Visit Site",
  ],
};

export const brandAdsCreativeStatusEnum = {
  DRAFT: "DRAFT",
  REJECTED: "REJECTED",
  APPROVED: "APPROVED",
  UNDER_REVIEW: "UNDER_REVIEW",
  INACTIVE: "INACTIVE",
  ENABLED: "ENABLED",
  DISABLED: "DISABLED",
  PAUSED: "PAUSED",
  ARCHIVED: "ARCHIVED",
};

export const approvalStatus = {
  UNDER_REVIEW: "Under Review",
  APPROVED: "Approved",
  REJECTED: "Rejected",
};

export const creativeLevel = {
  CLIENT: "Client Level",
  AGENCY: "Agency Level",
};

export const DEFAULT_VIDEO_FORMAT = ["mp4", "webm", "ogg", "avi", "mov", "mkv", "wmv", "flv", "3gp", "mpg", "mpeg", "vob", "m2ts", "amv", "m4v"];
export const DEFAULT_IMAGE_FORMAT = ["jpg", "jpeg", "png", "gif", "svg", "bmp", "tiff", "tif", "webp", "raw", "ico", "jp2", "psd"];
