import React, { useState } from "react";
import { Trans } from "react-i18next";
import { Button } from "@onlinesales-ai/button-v2";
import { Drawer } from "@onlinesales-ai/drawer-v2";
import { FixLayout } from "@onlinesales-ai/fix-layout-v2";
import PlatformEventManager from "@onlinesales-ai/event-manager-v2";

import TemplateSelection from "./index";

import "./wrapper.less";

const TemplateSelectionWrapper = ({ children, platformEventsToFire, ...props }) => {
  console.log(props);
  const [isOpenTemplateSelectionDrawer, setIsOpenTemplateSelectionDrawer] = useState(false);

  const onCTAClick = () => {
    setIsOpenTemplateSelectionDrawer(true);
  };

  const renderDefaultBannerCTA = () => {
    return (
      <div className="creative-selection-by-template-banner">
        <div className="creative-xd-block" onClick={() => setIsOpenTemplateSelectionDrawer(true)}>
          {/* <div className="alpha-tag">Alpha</div> */}
          <div className="icon-text-wrapper">
            <div className="creative-side-img">
              <img
                src="https://osads.gumlet.io/image/upload/v1673961465/product/Choose_Template_icon.svg"
                alt=""
              />
            </div>
            <div className="creative-info-text">
              <div className="title"><Trans>Choose template and auto create multiple sizes</Trans></div>
              <div className="sub-text">
                <Trans>Use these templates to create your own personalised creatives without any hassle!</Trans>
              </div>
            </div>
          </div>
          <div className="creative-block-icon">
            <i className="icon icon-angle-right" />
          </div>
        </div>
      </div>
    );
  };

  const onJobDoneCallBack = () => {
    (platformEventsToFire || []).map((key) => {
      PlatformEventManager.emit(key);
    });
    setIsOpenTemplateSelectionDrawer(false);
  };

  return (
    <div className="template-selection-container">
      <Drawer
        containerClass="creative-xd-drawer-wrapper"
        isOpen={isOpenTemplateSelectionDrawer}
        onClickClose={() => {
          setIsOpenTemplateSelectionDrawer(false);
        }}
        size="medium"
        closebutton
      >
        <FixLayout>
          <FixLayout.Header><Trans>Select Template</Trans></FixLayout.Header>
          <FixLayout.Body>
            <TemplateSelection jobDoneCallback={onJobDoneCallBack} {...props} />
          </FixLayout.Body>
        </FixLayout>
      </Drawer>
      {children ? children(onCTAClick) : renderDefaultBannerCTA()}
    </div>
  );
};

TemplateSelectionWrapper.defaultProps = {
  platformEventsToFire: ["REFRESH_CREATIVE_LIBRARY"],
};

export default TemplateSelectionWrapper;
