import React, { useMemo, useState } from "react";
import { connect } from "react-redux";
import _isEmpty from "lodash/isEmpty";

import AccessControlContext from "./accessControlContext";

const AccessControllProvider = ({ children, hasAccess: pHasAccess }) => {
  const [permissions, setPermissions] = useState(null);
  const isPermissionsLoaded = useMemo(() => !_isEmpty(permissions), [permissions]);

  // this function is check if user have permission or not
  const hasAccess = (config) => {
    const {
      access = [], // list of string: access key to check
      customAccessCheck, // function: if dev want to add some more checks,
      accessCheck = "EVERY", // "EVERY|SOME". This tell use to check every access or any of one access
    } = config;

    let isGranted = false;
    let extraData = {};

    if (accessCheck === "EVERY") {
      isGranted = access.every((a) => permissions?.[a]);
    } else if (accessCheck === "SOME") {
      isGranted = access.some((a) => permissions?.[a]);
    }

    if (customAccessCheck) {
      extraData = customAccessCheck({ isGranted, permissions, ...config });
    }

    return { isGranted, ...extraData };
  };

  const setAppPermissions = ({ permissions: newPermissions }) => {
    setPermissions(newPermissions);
  };

  const providerValues = useMemo(() => {
    return {
      permissions,
      hasAccess: pHasAccess || hasAccess,
      setPermissions: setAppPermissions,
      isPermissionsLoaded,
    };
  }, [permissions, isPermissionsLoaded, pHasAccess]);

  return (
    <AccessControlContext.Provider value={providerValues}>{children}</AccessControlContext.Provider>
  );
};

const mapStateToProps = (state) => {
  return {
    // userPermissions: state.EntityApplication,
  };
};

export default connect(mapStateToProps)(AccessControllProvider);
