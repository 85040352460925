import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { Trans } from "react-i18next";
import WithTooltip from "@onlinesales-ai/tooltip-v2";
import AsyncImage from "@onlinesales-ai/async-image-v2";

import "./index.less";

const OSTabs = ({
  defaultSelected,
  activeTab,
  id,
  tabs,
  onChange,
  display,
  wrapperClassName,
  renderSingleTab,
  renderIfNotActive,
  type,
  alignVertically,
  renderRightHeaderContent,
  showSingleTabAsText,
  ...props
}) => {
  const [selectedTab, setSelectedTab] = useState(defaultSelected);

  useEffect(() => {
    if (typeof activeTab !== "undefined") {
      setSelectedTab(activeTab);
    } else {
      if (tabs.length) {
        setSelectedTab(tabs[0].key);
      }
    }
  }, [activeTab]);

  const onTabSelect = (key) => {
    if (typeof activeTab === "undefined") {
      setSelectedTab(key);
    }

    if (onChange) {
      onChange(key);
    }
  };

  let SingleTabComponent = null;
  let singlComponentProps = {};

  if (tabs.length === 1) {
    SingleTabComponent = tabs[0].Component;
    singlComponentProps = tabs[0].props;
  }

  let textHideClass = "general-tab-text";

  if (tabs.length > 4) {
    textHideClass = "hide-tab-text";
  }

  if (tabs.length <= 1) {
    textHideClass = "notabs";
  }

  return (
    <div className={`tabs-wrapper ${display} tab-${type} ${wrapperClassName} ${textHideClass} ${showSingleTabAsText && tabs?.length === 1 ? "show-single-title" : ""}`}>
      {(renderRightHeaderContent && tabs.length > 1) && (<div className="tabs-right-header-content">{renderRightHeaderContent}</div>)}
      {renderSingleTab || tabs.length > 1 ? (
        <Tabs id={id} {...props} activeKey={selectedTab} onSelect={onTabSelect}>
          {tabs.map(
            ({
              key,
              Component,
              title,
              icon = "",
              imageIcon,
              pillContent,
              className,
              tooltip,
              titleComponent,
              props: componentProps,
              tagInfo,
              ...rest
            }) => {
              return (
                <Tab
                  key={key}
                  eventKey={key}
                  tabClassName={className}
                  title={
                    <>
                      <WithTooltip title={tooltip}>
                        <div
                          className={`${titleComponent && "has-title"} ${
                            alignVertically ? "direction-column" : ""
                          } tab-with-tooltip`}
                        >
                          {icon ? <span className={`tab-icon icon ${icon}`}></span> : null}
                          {pillContent ? (
                            <span className="tab-pill-content">{pillContent}</span>
                          ) : null}
                          {imageIcon ? (
                            <span className="tab-image-icon">
                              <AsyncImage imgSrc={imageIcon} />
                            </span>
                          ) : null}
                          <span className="tab-text">
                            <Trans>{title}</Trans>
                          </span>
                        </div>
                        {tagInfo?.text && <div className={`new-feature-tag-2 no-before-after ${tagInfo?.type} red top-right round no-pointer-events z-index-2`}>{tagInfo?.text}</div>}
                      </WithTooltip>
                      {
                        titleComponent &&
                        <span className="title-component">{titleComponent}</span>
                      }
                    </>
                  }
                  {...rest}
                >
                  {renderIfNotActive || selectedTab === key ? (
                    <Component {...componentProps} />
                  ) : null}
                </Tab>
              );
            },
          )}
        </Tabs>
      ) : null}
      {!renderSingleTab && tabs.length === 1 ? (
        <SingleTabComponent {...singlComponentProps} />
      ) : null}
    </div>
  );
};

OSTabs.defaultProps = {
  tabs: [],
  display: "horizontal", // "vertical"
  wrapperClassName: "",
  renderSingleTab: true,
  renderIfNotActive: false,
  type: "default", // "pill" "card", "vcard", "vpill"
  alignVertically: false,
  showSingleTabAsText: false,
};

export default OSTabs;
