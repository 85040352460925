import React, { Suspense, useEffect } from "react";
import { Provider } from "react-redux";
import { HistoryRouter as Router } from "redux-first-history/rr6";

import "bootstrap/dist/css/bootstrap.css";
import "@onlinesales-ai/global-css-v2/css/common.less";
import "@onlinesales-ai/global-css-v2/css/fonts.less";
import "@onlinesales-ai/global-css-v2/css/theme.less";
import "@onlinesales-ai/global-css-v2/css/inmar-theme.less";
import "@onlinesales-ai/global-css-v2/css/osmos-theme.less";
import "@onlinesales-ai/global-css-v2/css/family-dollar-theme.less";
import "@onlinesales-ai/global-css-v2/css/tops-markets-theme.less";
import "@onlinesales-ai/global-css-v2/css/pnp-theme.less";
import "@onlinesales-ai/global-css-v2/css/tving-theme.less";
import "@onlinesales-ai/global-css-v2/css/dunn-humby-theme.less";

import store, { history } from "src/store";
import InitDomainConfig from "src/domainConfig/InitDomainConfig";
import { AppLoader } from "@onlinesales-ai/app-v2";
import { populateEvent, NetworkProvider } from "@onlinesales-ai/util-methods-v2";
import { AccessControllProvider } from "@onlinesales-ai/access-control-v2";
import { trackUIPerformance } from "@onlinesales-ai/tracking-reporting-v2";
import {
  Sentry,
  SentryErrorCatcher,
  sentryErrorBoundryBeforeCapture,
} from "@onlinesales-ai/error-catcher-v2";
import { ShortcutProvider } from "@onlinesales-ai/shortcuts-v2";
import { BaseClient } from "@onlinesales-ai/services-v2";
import demoData from "src/demoData";
import proxyConfig, { getProxyKey } from "src/cdnProxy";
import InitLanguage from "src/i18n/InitLanguage";
import Routes from "./routes";
import AddOns from "./AddOns";

if (IS_STAGING_BOX) {
  BaseClient.setProxyConfig(proxyConfig, getProxyKey());
}

const checkTime = (eventName = "") => {
  try {
    if (window.osStartTime && eventName) {
      const osEndTime = new Date().getTime();
      const loadTime = (osEndTime - window.osStartTime) / 1000;

      populateEvent("APP", `APP||${eventName}`, { timeInSec: loadTime }, { utm_content: loadTime });
    }
  } catch (e) {}
};

window.onload = () => {
  checkTime("APP_LOAD_TIME");
};

const sentryFallback = (props) => {
  return <SentryErrorCatcher {...props} />;
};

const App = () => {
  useEffect(() => {
    BaseClient.proxyMap = demoData;
    try {
      trackUIPerformance();
    } catch (e) {}
  }, []);

  return (
    <Sentry.ErrorBoundary fallback={sentryFallback} beforeCapture={sentryErrorBoundryBeforeCapture}>
      <Suspense fallback={AppLoader()}>
        <Provider store={store}>
          <Router history={history}>
            <InitDomainConfig>
              <AccessControllProvider>
                <InitLanguage />
                <ShortcutProvider>
                  <Routes />
                  <NetworkProvider>
                    <AddOns />
                  </NetworkProvider>
                </ShortcutProvider>
              </AccessControllProvider>
            </InitDomainConfig>
          </Router>
        </Provider>
      </Suspense>
    </Sentry.ErrorBoundary>
  );
};

export default App;
