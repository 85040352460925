import React from "react";

import { Downtime } from "@onlinesales-ai/downtime-v2";

class ErrorCatcher extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, chunkError: false };
  }

  static getDerivedStateFromError(error) {
    let chunkErrorFlag = false;

    try {
      chunkErrorFlag = (error || "")
        ?.toString()
        ?.trim()
        ?.substring(0, 200)
        ?.match(/Loading chunk [\d]+ failed|Loading CSS chunk [\d]+ failed/i)
        ? true
        : false;
    } catch (error) {}

    return {
      hasError: !!error,
      chunkError: chunkErrorFlag,
    };
  }

  componentDidCatch(error, info) {
    try {
      const data = {
        error: (error || "").toString().trim().substring(0, 200),
        componentStack: (info.componentStack || "")
          ?.toString()
          .trim()
          .replace(/[^a-zA-Z ]/g, "")
          .substring(0, 400),
      };
      console.error(data, error);
    } catch (e) {
      console.error(e, error);
      this.fireIntercomEvents("ERROR_IN_CATCHER", { error: e });
    }
  }

  render() {
    const { hasError, chunkError } = this.state || {};
    const { children, defaultErrorInfo, chunkErrorInfo } = this.props || {};

    if (chunkError) {
      return <Downtime {...chunkErrorInfo} />;
    }

    if (hasError) {
      return <Downtime {...defaultErrorInfo} />;
    }

    return children;
  }
}

ErrorCatcher.defaultProps = {
  defaultErrorInfo: {
    imgUrl:
      "https://osads.gumlet.io/image/authenticated/s--IbTZA8IH--/v1638855748/product/Group_124861.svg",
    title: "We've Encountered a Internal Error",
    description: "We've reported this error. We are looking into it.",
    containerClass: "",
    appName: "MAVERICK_UI",
  },
  chunkErrorInfo: {
    imgUrl: "https://osads.gumlet.io/image/authenticated/s--PQMDQgs7--/product/system_upgraded.svg",
    title: "New version available",
    description:
      "Hey There, we have upgraded our system. Please refresh the app for the seamless experience.",
    containerClass: "",
    appName: "MAVERICK_UI",
  },
};

export default ErrorCatcher;
