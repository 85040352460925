import _uniq from "lodash/uniq";

import { customMergeOS } from "@onlinesales-ai/util-methods-v2";

import Types from "./types";

const initialState = {
  campaignTemplate: {
    data: {},
  },
  package: {
    data: {},
  },
  booking: {
    data: {},
    sortedIds: [],
  },

};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.OMS_SET_CAMPAIGN_TEMPLATE_DATA:
      {
        state = {
          ...state,
          campaignTemplate: {
            ...(state.campaignTemplate || {}),
            data: customMergeOS({}, state?.campaignTemplate?.data, action?.campaignTemplate),
          },
        };
      }
      break;
    case Types.OMS_UPDATE_CAMPAIGN_TEMPLATE_DATA:
      {
        state = {
          ...state,
          campaignTemplate: {
            data: {
              ...state.campaignTemplate.data,
              [action.id]: {
                ...(state.campaignTemplate?.data?.[action?.id] || {}),
                data: {
                  ...state.campaignTemplate.data[action.id]?.data,
                  ...action.campaignTemplate,
                },
              },
            },
          },
        };
      }
      break;
    case Types.OMS_SET_PACKAGE_DATA:
      {
        state = {
          ...state,
          package: {
            ...state.package,
            data: customMergeOS({}, state?.package?.data, action?.package),
          },
        };
      }
      break;
    case Types.OMS_UPDATE_PACKAGE_DATA:
      {
        state = {
          ...state,
          package: {
            data: {
              ...state.package.data,
              [action.id]: {
                data: {
                  ...state.package.data[action.id]?.data,
                  ...action.package,
                },
              },
            },
          },
        };
      }
      break;
    case Types.OMS_SET_BOOKING_DATA:
      {
        state = {
          ...state,
          booking: {
            ...state.booking,
            data: customMergeOS({}, state?.booking?.data, action?.booking),
          },
        };
      }
      break;
    case Types.OMS_UPDATE_BOOKING_DATA:
      {
        state = {
          ...state,
          booking: {
            ...state.booking,
            ...(action.setPrevOrder
              ? {
                  prevData: {
                    ...state.booking.prevData,
                    [action.id]: {
                      ...(state.booking?.prevData?.[action.id] || {}),
                      data: customMergeOS(
                        {},
                        state?.booking?.prevData?.[action.id]?.data,
                        action?.booking,
                      ),
                    },
                  },
                }
              : {
                  data: {
                    ...state.booking.data,
                    [action.id]: {
                      ...(state.booking?.data?.[action.id] || {}),
                      data: customMergeOS({}, state.booking.data[action.id]?.data, action?.booking),
                    },
                  },
                }),
          },
        };
      }
      break;
    case Types.OMS_SET_BOOKING_CREATIVE:
      {
        state = {
          ...state,
          booking: {
            ...state.booking,
            data: {
              ...state.booking.data,
              [action.bookingId]: {
                ...state.booking?.data?.[action.bookingId],
                creatives: {
                  ...state?.booking?.data?.[action.bookingId]?.creatives,
                  ...action?.creatives,
                },
              },
            },
          },
        };
      }
      break;
    case Types.OMS_SET_AND_REPLACE_BOOKING_CREATIVE:
      {
        state = {
          ...state,
          booking: {
            ...state.booking,
            ...(action.setPrevOrder
              ? {
                  prevData: {
                    ...state.booking.prevData,
                    [action.bookingId]: {
                      ...state.booking.prevData[action.bookingId],
                      creatives: {
                        ...action.creatives,
                      },
                    },
                  },
                }
              : {
                  data: {
                    ...state.booking.data,
                    [action.bookingId]: {
                      ...state.booking.data[action.bookingId],
                      creatives: {
                        ...action.creatives,
                      },
                    },
                  },
                }),
          },
        };
      }
      break;
    case Types.OMS_UPDATE_BOOKING_CREATIVE:
      {
        state = {
          ...state,
          booking: {
            ...state.booking,
            data: {
              ...state.booking.data,
              [action.bookingId]: {
                ...state.booking.data[action.bookingId],
                creatives: {
                  ...state.booking.data[action.bookingId].creatives,
                  [action.creative.id]: {
                    ...state.booking.data[action.bookingId].creatives[action.creative.id],
                    ...action.creative,
                  },
                },
              },
            },
          },
        };
      }
      break;
    case Types.OMS_SET_BOOKING_REVIEW_SORTED_IDS:
      {
        state = {
          ...state,
          booking: {
            ...state.booking,
            sortedIds: action?.sortedIds || [],
          },
        };
      }
      break;
    case Types.OMS_UPDATE_BOOKING_REVIEW_SORTED_IDS:
      {
        state = {
          ...state,
          booking: {
            ...state.booking,
            sortedIds: _uniq([...(state?.booking?.sortedIds || []), ...(action?.sortedIds || [])]),
          },
        };
      }
      break;
    default:
      break;
  }

  return state;
};

export default {
  OMS: reducer,
};
