import BaseClient from "../baseClient";

const servicePrefix = "/notificationsServiceV2/";

class NotificationService extends BaseClient {
  static getErrorMessages() {
    return {
      default: "Looks like we are facing some internal issue. Please, retry after sometime.",
      IP0000:
        "It seems some input parameters are not valid. Please update your inputs and try again.",
      AD0000:
        "Oops. It seems you are not authorized to take this action. Please log in with appropriate credentials.",
      I0004:
        "Oops. Seems the system is acting up - sorry for the inconvenience. Please try again in some time. (Please be assured our tech geniuses have been notified of this glitch).",
    };
  }

  static updateUINotifications(payload, application) {
    return this.apiCall(
      {
        url: servicePrefix + "updates",
        method: "POST",
        data: encodeURIComponent(JSON.stringify(payload)),
      },
      application,
    );
  };

  static getUINotifications(notificationType, payload, application) {
    let apiUrl = null;

    if (notificationType === "announcementBoard") {
      apiUrl = "announcements";
    } else if (notificationType === "header") {
      apiUrl = "uinotifications";
    }

    return this.apiCall(
      {
        url: servicePrefix + apiUrl,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
    );
  };
}

export default NotificationService;
