import { alVendorEnum as vendorEnum } from "@onlinesales-ai/constants-v2";

export const globalErrorCodesToShow = [
  "ACT001",
  "ACT004",
  "ACT005",
  "ACT006",
  "ACT007",
  "ACT011",
  "ACT012",
  "FB009",
  "GGL016",
  "GGL017",
  "access_denied",
];

export const defaultVendorConfig = {
  [vendorEnum.GOOGLE]: {
    listConfig: {
      onboardingFlagsToCheck: ["isGMBPageCreated"],
      displayName: "Google",
      description: "",
      linkButtonText: "Link Google Page",
      linkedButtonText: "View Details",
      createPageButtonText: "+ Create Page",
      pageCreatedButtonText: "View Details",
      key: vendorEnum.GOOGLE,
      tooltip: {
        linkTooltip: "Click to link an existing account",
        createTooltip: "Click to create a new account",
      },
      showCreateOption: true,
      iconClass: "icon-google-adwords-grey",
      mustHaveAccounts: ["googleAdwordAccount"],
    },
    accountConfig: {
      googleAdwordAccount: {
        name: "GOOGLE.COM::ACCOUNT",
        key: "googleAdwordAccount",
        class: "com.sokrati.clientInputSvcObjects.parameter.GoogleAdwordsAccount",
        appKey: "SOK_SHOPS",
        displayName: "Google Adwords Account",
        accountNameKey: "accountName",
        accountIdKey: "accountId",
        linkActivityValue: "GOOGLE_ADWORDS_ACCOUNT_LINKED",
        unlinkActivityValue: "GOOGLE_ADWORDS_ACCOUNT_UNLINKED",
        unlinkConfirmationConfig: {
          title: "Are you sure you want to unlink your account?",
          leftButtonText: "Yes",
          rightButtonText: "No",
        },
        errorCodes: ["GGL001", "GGL004", "GGL012", "GGL013", "GGL014", "GGL015", "GGL018"],
        accountNotLinkedErrorMsg: "No Google Adwords Account Selected",
        accountLinkInfoMsg: "Please link your ad account to advertise on Google channel",
        parentAccountNotSelectedMsg:
          "To select this adwords account please unselect the selected Google Merchant Center as they are not linked to each other. ",
      },
      googleMerchantCenter: {
        name: "GOOGLE.COM::ACCOUNT",
        key: "googleMerchantCenter",
        class: "com.sokrati.clientInputSvcObjects.parameter.GoogleGmcAccount",
        appKey: "SOK_SHOPS",
        displayName: "Google Merchant Center",
        accountNameKey: "accountName",
        accountIdKey: "accountId",
        parentVendorKey: "googleAdwordAccount",
        parentAccountIdKey: "googleAdwordsAccountIds",
        linkActivityValue: "GOOGLE_GMC_ACCOUNT_LINKED",
        unlinkActivityValue: "GOOGLE_GMC_ACCOUNT_UNLINKED",
        parentAccountNotSelectedMsg:
          "Please, select the right adwords account to which this Google Merchant Center is linked.",
        notValidWebsiteMsg:
          "Your store domain url on OnlineSales does not match with GMC website url. Please update website domain url on your GMC account.",
        accountNotLinkedErrorMsg: "No GMC Account Selected",
        accountLinkInfoMsg: "Please link your GMC account to advertise on Google Shopping",
        unlinkConfirmationConfig: {
          title: "Are you sure you want to unlink your account?",
          leftButtonText: "Yes",
          rightButtonText: "No",
        },
        errorCodes: ["GGL002", "GGL005", "GGL007"],
      },
      googleMyBysiness: {
        name: "GMB_LOCATION",
        key: "googleMyBysiness",
        class: "com.sokrati.clientInputSvcObjects.parameter.GmbLocation",
        "appKey": "SOK_SHOPS_GMB",
        displayName: "Google My Business",
        accountNameKey: "locationName",
        accountIdKey: "locationId",
        linkActivityValue: "GOOGLE_GMB_ACCOUNT_LINKED",
        unlinkActivityValue: "GOOGLE_GMB_ACCOUNT_UNLINKED",
        accountNotLinkedErrorMsg: "No GMB Account Selected",
        accountLinkInfoMsg: "Please link your GMB location to enable post creation",
        locationNotVerified: "This location is not verified by Google. Please verify this location on google and re-link.",
        onlyOnceLinkingTooltipMsg: "You can only link gmb page once, page change is not allowed",
        unlinkConfirmationConfig: {
          title: "Are you sure you want to unlink your account?",
          leftButtonText: "Yes",
          rightButtonText: "No",
        },
        errorCodes: ["GGL024", "GGL025", "GGL026", "GGL027", "GGL028", "GGL029", "GGL030"],
      },
    },
    linkAccountConfig: {
      stepsConfig: [
        "To enjoy a seamless experience, please ensure you have the admin access and are logged in to the correct Google Ads account you wish to link. Please also ensure that you’re GMC account is linked to your AdWords.",
        'Click on the "Link" button below. You\'ll be redirected to Google and prompted to start the linking process. Select the email address you wish to login with.',
        'Please click "Ok" to allow the required permissions. We require these permissions to be approved to manage ads on Google Ads for you.',
      ],
    },
  },
  [vendorEnum.FACEBOOK]: {
    listConfig: {
      onboardingFlagsToCheck: ["isFBPageCreated"],
      displayName: "Facebook",
      description: "",
      linkButtonText: "Link Facebook Page",
      linkedButtonText: "View Details",
      createPageButtonText: "+ Create Page",
      pageCreatedButtonText: "View Details",
      key: vendorEnum.FACEBOOK,
      tooltip: {
        linkTooltip: "Click to link an existing account",
        createTooltip: "Click to create a new account",
      },
      showCreateOption: true,
      iconClass: "icon-facebook-page-grey",
      mustHaveAccounts: ["facebook", "facebookPage"],
    },
    accountConfig: {
      facebook: {
        name: "FACEBOOK.COM::ACCOUNT",
        key: "facebook",
        class: "com.sokrati.clientInputSvcObjects.parameter.FacebookAccount",
        appKey: "SOK_SokratiShops_FB",
        accountNameKey: "accountName",
        accountIdKey: "accountId",
        displayName: "Facebook Ad Account",
        linkActivityValue: "FACEBOOK_ACCOUNT_LINKED",
        unlinkActivityValue: "FACEBOOK_ACCOUNT_UNLINKED",
        businessIdMissingMsg: "Ad account needs to be linked to atleast one business manager.",
        adminAceessNAMsg: "Admin access is required on the account.",
        accountNotLinkedErrorMsg: "No Facebook Ad Account Selected",
        accountLinkInfoMsg: "Please link your ad account to advertise on Facebook channel",
        unlinkConfirmationConfig: {
          title: "Are you sure you want to unlink your account?",
          leftButtonText: "Yes",
          rightButtonText: "No",
        },
        errorCodes: ["FB001", "FB003", "FB005", "FB006", "FB007", "FB010"],
      },
      facebookPage: {
        name: "FACEBOOK.COM::PAGE",
        key: "facebookPage",
        class: "com.sokrati.clientInputSvcObjects.parameter.FacebookPage",
        appKey: "SOK_SokratiShops_FB",
        displayName: "Facebook Page",
        accountNameKey: "pageName",
        accountIdKey: "pageId",
        linkActivityValue: "FACEBOOK_PAGE_LINKED",
        unlinkActivityValue: "FACEBOOK_PAGE_UNLINKED",
        hygieneChecksToRefresh: ["CHECK_FACEBOOK_PAGE_ID", "CHECK_FACEBOOK_PAGE_ID_BY_PAGE_TOKEN"],
        accountNotLinkedErrorMsg: "Please select Facebook Business Page you want to link.",
        accountLinkInfoMsg: "Please link your Facebook Page to advertise on Facebook channel",
        adminAceessNAMsg: "Please Re-link this page with admin access",
        pageNotPublishedMsg:
          "Facebook Page you are trying to link is not published. Please, publish the page and re-link again.",
        unlinkConfirmationConfig: {
          title:
            "From now on your facebook page wont be used by platform, Are you sure you want to unlink your page?",
          leftButtonText: "Yes",
          rightButtonText: "No",
        },
        validationChecks: [
          "FACEBOOK_PAGE_ADMIN_CHECK",
          "FACEBOOK_PAGE_ADMIN_USER_ADD_CHECK",
          "ADD_FB_PAGE_TO_BUSINESS_MANAGER",
        ],
        errorCodes: ["FB002", "FB004", "FB008", "FB_PAGE_ADMIN_USER_CHECK_FAILED", "FB_PAGE_ADMIN_CHECK_FAILED", "ADD_FB_PAGE_TO_BUSINESS_MANAGER_CHECK_FAILED"],
        onlyOnceLinkingTooltipMsg: "You can only link facebook page once, page change is not allowed",
        linkConfirmationConfig: {
          title:
            "You cannot change selected page after linking, Are you sure you want to link this page?",
          leftButtonText: "Yes",
          rightButtonText: "No",
        },
      },
      instagram: {
        name: "INSTAGRAM::ACCOUNT",
        key: "instagram",
        class: "com.sokrati.clientInputSvcObjects.parameter.InstagramAccount",
        appKey: "SOK_SokratiShops_FB",
        displayName: "Instagram",
        accountNameKey: "accountName",
        accountIdKey: "accountId",
        parentAccountIdKey: "fbAccountIds",
        parentVendorKey: "facebook",
        linkActivityValue: "INSTAGRAM_ACCOUNT_LINKED",
        unlinkActivityValue: "INSTAGRAM_ACCOUNT_UNLINKED",
        accountNotLinkedErrorMsg: "No Instagram Account Selected",
        accountLinkInfoMsg: "Please link your Instagram account to advertise on Instagram",
        parentAccountNotSelectedMsg:
          "Please select appropriate parent facebook account associated with this instagram account",
        unlinkConfirmationConfig: {
          title: "Are you sure you want to unlink your account?",
          leftButtonText: "Yes",
          rightButtonText: "No",
        },
        errorCodes: ["IN001", "IN002", "IN003", "IN004", "IN005", "IN006", "IN007", "IN008"],
      },
    },
    linkAccountConfig: {
      stepsConfig: [
        "<p>Are you an Admin?</p> To enjoy a seamless experience, please ensure you have the admin access and are logged in to the Facebook account you wish to link.",
        `<p>Facebook Login</p> Once you click on "Link" button below, you'll be redirected to Facebook. Please start the linking process > Click Continue as {profile name}`,
        `<p>Permissions</p> Please click "OK" to grant us the requested permissions. These permissions are needed for us to manage your ad campaigns on Facebook and Instagram.`,
      ],
    },
  },
  [vendorEnum.INSTAGRAM]: {
    listConfig: {
      displayName: "Instagram",
      description: "",
      linkButtonText: "Link Instagram Account",
      linkedButtonText: "View Details",
      createPageButtonText: "+ Create Page",
      pageCreatedButtonText: "View Details",
      defaultLinkAccountKey: "instagram",
      mustHaveAccounts: ["instagram"],
      image: "https://osads.gumlet.io/image/upload/v1645764748/product/monetize/LinkInstagram.svg",
      mustHaveAccountsLinkedMessages: {
        linked: "Link Instagram Account",
        notLinked: "Link Instagram Account",
      },
      enablePageCreation: false
    },
    linkAccountConfig: {
      //Credentials Config
    },
    accountConfig: {
      instagram: {
        name: "INSTAGRAM::ACCOUNT",
        key: "instagram",
        class: "com.sokrati.clientInputSvcObjects.parameter.InstagramAccount",
        displayName: "Instagram",
        accountNameKey: "handle",
        accountIdKey: "password",
        linkActivityValue: "INSTAGRAM_ACCOUNT_LINKED",
        unlinkActivityValue: "INSTAGRAM_ACCOUNT_UNLINKED",
        faq: [
          "Please make sure your 2 factor authentication is turned off. <a target='_blank' href='https://help.instagram.com/1124604297705184'> Learn more </a> (Refer: To remove a connected device from two-factor authentication)",
          "It will take up to 24 business hours to link your Instagram account. Once activated, all the ads on instagram will start using your instagram handle.",
        ],
      },
    },
  },
};
