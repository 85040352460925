import BaseClient from "../baseClient";

const marketplaceService = "/marketplace/";

class MarketPlaceServiceClient extends BaseClient {
  static getErrorMessages() {
    return {
      default: "Looks like we are facing some internal issue. Please, retry after sometime.",
      IP0000:
        "It seems some input parameters are not valid. Please update your inputs and try again.",
      AD0000:
        "Oops. It seems you are not authorized to take this action. Please log in with appropriate credentials.",
      I0004:
        "Oops. Seems the system is acting up - sorry for the inconvenience. Please try again in some time. (Please be assured our tech geniuses have been notified of this glitch).",
      MP001: "It seems some input parameters are not valid. Please check your input parameters.",
      SWIPE_ON_GLOBE_ERROR:
        "Looks like we are facing some internal issue. Please, retry after sometime.",
      SPENDING_LIMIT_ERROR: "You are not eligible for the amount of package you have chosen",
      DUPLICATE_TRANSACTION_ERROR:
        "Oops! You can make only one transaction in 24 hours. Kindly try again later.",
      INVALID_BUDGET_VALUES: "Budget values are not valid, Kindly change values and try again",
      DAILY_LIMIT_REACHED: "Budget can be updated only once in 24 hours. Kindly try again later.",
      TRANSACTION_FAILED:
        "Looks like we are facing some internal issue. Please, retry after sometime.",
    };
  }

  static paymentStoreCall(config, application) {
    return this.apiCall(
      {
        url: `${marketplaceService}store`,
        method: "POST",
        data: JSON.stringify(config),
      },
      application,
    );
  }

  static proxyCall(config, application) {
    return this.apiCall(
      {
        url: `${marketplaceService}proxy`,
        method: "POST",
        data: JSON.stringify(config),
      },
      application,
    );
  }

  static checkAndUpdateTicket(config, application) {
    return this.apiCall(
      {
        url: `${marketplaceService}ticket`,
        method: "POST",
        data: JSON.stringify(config),
      },
      application,
    );
  }

  static getClientOnboardingStatus(config, application) {
    return this.apiCall(
      {
        url: `${marketplaceService}clientOnboardStatus`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(config) },
      },
      application,
    );
  }

  static getSpendingLimitForClient(payload, application) {
    return this.apiCall(
      {
        url: `${marketplaceService}spendingLimit`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
    );
  }

  static makePartnerPayment(config, application) {
    return this.apiCall(
      {
        url: `${marketplaceService}globeTelcoPayment`,
        method: "POST",
        data: JSON.stringify(config),
      },
      application,
    );
  }

  static postBudgetAllocation(payload, application) {
    return this.apiCall(
      {
        url: `${marketplaceService}/budgetReallocation`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
    );
  }
}

export default MarketPlaceServiceClient;
