import React from "react";
import Select from "react-select";

import BaseDropdown from "./BaseDropdown";

const Dropdown = (props) => {
  return <BaseDropdown {...props} SelectComponent={Select} />;
};

export default Dropdown;
