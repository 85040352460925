export const rmnChannelStatusEnum = {
  IN_PROGRESS_APPROVAL_STATUS: "INPROGRESS",
  LINKED: "Linked",
  IN_PROGRESS: "In Progress",
  NOT_LINKED: "Not Linked",
};

export const rmnAccountConfigOverride = {
  "1mg-marketplace-staging": {
    icon: "https://osads.gumlet.io/image/upload/v1659346369/product/rmn/channel/1mg.png",
  },
  "1mg": {
    icon: "https://osads.gumlet.io/image/upload/v1659346369/product/rmn/channel/1mg.png",
  },
  takealot: {
    icon: "https://osads.gumlet.io/image/upload/v1659346386/product/rmn/channel/takealot.png",
  },
  blibli: {
    icon: "https://osads.gumlet.io/image/upload/v1659346360/product/rmn/channel/blibli.png",
  },
  "monetize-sandbox": {
    icon: "https://osads.gumlet.io/image/upload/v1660032190/product/rmn/channel/rmn-logo.png",
  },
  "kornershoppe-prod": {
    icon: "https://osads.gumlet.io/image/upload/v1661843349/product/rmn/channel/kornershoppe.png",
  },
  "kornershoppe-staging": {
    icon: "https://osads.gumlet.io/image/upload/v1662626259/product/rmn/channel/kornershoppe_staging_icon.png",
  },
  "lowes-foods": {
    icon: "https://osads.gumlet.io/image/upload/v1662473990/product/rmn/channel/lowers_logo.webp",
  },
  "fanatics": {
    icon: "https://osads.gumlet.io/image/upload/v1662473990/product/rmn/channel/fanatic_logo.png",
  },
  "tops-market": {
    icon: "https://osads.gumlet.io/image/upload/v1662473990/product/rmn/channel/tops_logo.jpg",
  },
  default: {
    icon: "https://osads.gumlet.io/image/upload/v1659619571/product/rmn/dummy-img.svg",
  },
};

export const featuresIconsEnum = {
  PLA: { icon: "icon-tshirt-filled", title: "PLA" },
  BRAND_ADS: { icon: "icon-auction-hammer", title: "Brand Ads" },
};
