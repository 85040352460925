import React, { useState, forwardRef, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";

import { isAfter, isBefore, startOfMonth } from "date-fns/esm";
import _isEqual from "lodash/isEqual";

import { Button } from "@onlinesales-ai/button-v2";
import { usePrevious, formattedDate } from "@onlinesales-ai/util-methods-v2";

import "./datepicker.less";

const CustomInput = forwardRef(
  ({ onClick, userValue, placeHolder, disableIcon, disabled }, ref) => {
    const text = placeHolder;
    return (
      <div ref={ref} className={`os-datepicker ${disabled ? "disabled" : ""}`}>
        <div
          className="date-picker-input-wrapper d-align-between form-controls"
          onClick={!disabled ? onClick : () => {}}
        >
          {userValue ? (
            <div className="date-picker-input">
              {userValue ? formattedDate(userValue, "month") : text}
            </div>
          ) : (
            <div className="date-picker-placeholder">{text}</div>
          )}
          {!disableIcon ? (
            <img
              className="datepicker-icon"
              src="https://osads.gumlet.io/image/authenticated/s--QF_p6Gt6--/v1627034581/product/monetize/calendar.svg"
            />
          ) : null}
        </div>
      </div>
    );
  },
);

const MonthPicker = ({
  value,
  onChange,
  placeHolder,
  shouldCloseOnSelect,
  disableIcon,
  className,
  disabled,
  enableborderbottom,
  popperClassName,
  minMonth: pMinMonth,
  maxMonth: pMaxMonth,
  showCTAs,
  showfullBorder,
  ...props
}) => {
  const [selectedMonth, setSelectedMonth] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const previousValue = usePrevious(value);
  const { t } = useTranslation();

  const minMonth = useMemo(() => {
    if (pMinMonth) {
      return startOfMonth(new Date(pMinMonth));
    }
    return null;
  }, [pMinMonth]);

  const maxMonth = useMemo(() => {
    if (pMaxMonth) {
      return startOfMonth(new Date(pMaxMonth));
    }
    return null;
  }, [pMaxMonth]);

  useEffect(() => {
    if (value) {
      if (!_isEqual(previousValue, value)) {
        setSelectedMonth(value);
      }
    } else {
      setSelectedMonth(null);
    }
  }, [value, previousValue]);

  const onCalendarOpen = () => {
    setIsOpen(true);
  };

  const onCalendarClose = () => {
    setIsOpen(false);
    if (value) {
      setSelectedMonth(value);
    }
  };

  const onClickApply = () => {
    onChange(selectedMonth);
    setIsOpen(false);
  };

  const onChangeSelectedMonth = (month) => {
    let updatedMonth = month;
    if (month && minMonth && isBefore(month, minMonth)) {
      updatedMonth = minMonth;
    } else if (month && maxMonth && isAfter(month, maxMonth)) {
      setSelectedMonth(maxMonth);
      updatedMonth = maxMonth;
    }

    setSelectedMonth(updatedMonth);

    if (shouldCloseOnSelect || !updatedMonth) {
      onChange(updatedMonth);
    }
  };

  const isApplyDisabled = useMemo(() => {
    return !selectedMonth;
  }, [selectedMonth]);

  return (
    <div
      className={`date-picker-wrapper ${className} ${enableborderbottom ? "borderbottom" : ""} ${
        showfullBorder ? "full-border" : ""
      }`}
    >
      <DatePicker
        open={isOpen}
        shouldCloseOnSelect={shouldCloseOnSelect}
        onCalendarClose={onCalendarClose}
        selected={selectedMonth}
        onChange={onChangeSelectedMonth}
        onInputClick={onCalendarOpen}
        minDate={minMonth}
        maxDate={maxMonth}
        popperClassName={`single-date-picker-wrapper month-date-picker ${popperClassName}`}
        customInput={
          <CustomInput
            userValue={value}
            placeHolder={t(placeHolder)}
            disabled={disabled}
            disableIcon={disableIcon}
          />
        }
        disabledKeyboardNavigation
        disabled={disabled}
        showMonthYearPicker
        showFullMonthYearPicker
        {...props}
      >
        {showCTAs && (
          <div className="os-btn-wrapper">
            <Button disabled={isApplyDisabled} onClick={onClickApply}>
              Apply
            </Button>
            <Button type="default" onClick={onCalendarClose}>
              Cancel
            </Button>
          </div>
        )}
      </DatePicker>
    </div>
  );
};

MonthPicker.defaultProps = {
  shouldCloseOnSelect: false,
  disableIcon: false,
  disabled: false,
  enableborderbottom: true,
  showCTAs: false,
};

export default MonthPicker;
