import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { createBrowserHistory } from "history";
import { createReduxHistoryContext } from "redux-first-history";

import { AppStore } from "@onlinesales-ai/util-methods-v2";

import { accountLinkingReducer } from "@onlinesales-ai/account-linking-v2";
import billingReducer from "@onlinesales-ai/app-v2/biling/reducer";
import uiNotificationReducer from "@onlinesales-ai/app-v2/uiNotification/reducer";
import leadReducer from "@onlinesales-ai/app-v2/lms/reducer";
import leadV2Reducer from "@onlinesales-ai/lms-v2-new-v2/store/reducer";
import goalV2Reducer from "@onlinesales-ai/app-v2/goalv2/reducer";
import brandAdsReducer from "@onlinesales-ai/app-v2/brandAds/reducer";
import settingReducer from "@onlinesales-ai/app-v2/setting/reducer";
import emailGoalsReducer from "@onlinesales-ai/app-v2/emailGoals/reducer";
import whatsAppGoals from "@onlinesales-ai/app-v2/whatsAppGoals/reducer";
import { smmReducer } from "@onlinesales-ai/smm-common-v2/store";
import { creativesReducer } from "@onlinesales-ai/creatives-v2/store";
import financeReducer from "@onlinesales-ai/finance-v2/store/finance/reducer";
import entityApplicationReducer from "@onlinesales-ai/client-v2/store/entityApplication/reducer";
import omsReducer from "@onlinesales-ai/oms-v2/store/reducer";
import reportingReducer from "@onlinesales-ai/reports-v2/store/reporting/reducer";

import applicationReducer from "./application/reducer";
import domainConfigReducer from "./domainConfig/reducer";
import onBoardingReducer from "./onBoarding/reducer";
import dashboardReducer from "./dashboard/reducer";
import goalsReducer from "./goals/reducer";
import bidConfigurationReducer from "./bidConfiguration/reducer";
import suggestionsReducer from "./suggestions/reducer";
import pendingOpportunityReducer from "./pendingOpportunity/reducer";
import notificationsReducer from "./notifications/reducer";
import clientAchievementReducer from "./clientAchievement/reducer";

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
});
const rootReducer = combineReducers({
  ...reportingReducer,
  ...applicationReducer,
  ...domainConfigReducer,
  ...onBoardingReducer,
  ...bidConfigurationReducer,
  ...dashboardReducer,
  ...goalsReducer,
  ...emailGoalsReducer,
  ...whatsAppGoals,
  ...pendingOpportunityReducer,
  ...suggestionsReducer,
  ...notificationsReducer,
  ...accountLinkingReducer,
  ...clientAchievementReducer,
  ...billingReducer,
  ...uiNotificationReducer,
  ...leadReducer,
  ...goalV2Reducer,
  ...leadV2Reducer,
  ...brandAdsReducer,
  ...smmReducer,
  ...settingReducer,
  ...creativesReducer,
  ...financeReducer,
  ...entityApplicationReducer,
  ...omsReducer,
  router: routerReducer,
});

const initialState = {};

const middlewares = [thunk, routerMiddleware];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(...middlewares)),
);

AppStore.setStore(store);

export const history = createReduxHistory(store);

export default store;
