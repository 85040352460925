import React, { Suspense, useEffect, useRef, useState } from "react";

import { loaderApp } from "@onlinesales-ai/loader-v2";

import Drawer from "./Drawer";

const RouteBasedDrawer = ({
  containerClass,
  drawerProps,
  location,
  componentKey,
  subComponentKey,
  redirectUrl,
  drawerRoutePrefix,
  suspenseLoading,
  DrawerChildren,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const searchString = location?.search || "";
  const currentPathName = location?.pathname || "";

  const routeState = useRef([]);
  const redirectFromRouteState = useRef(false);
  const isFirstTime = useRef(true);
  const drawerRef = useRef({});

  const [state, setState] = useState({
    searchString,
    componentKey: null,
    subComponentKey: null,
  });

  useEffect(() => {
    if (
      !isFirstTime.current &&
      !redirectFromRouteState.current &&
      state.componentKey !== componentKey &&
      state?.subComponentKey !== subComponentKey
    ) {
      routeState.current.push(state);
    }

    setState({
      subComponentKey,
      componentKey,
      searchString,
    });

    redirectFromRouteState.current = false;
    isFirstTime.current = false;
  }, [componentKey, searchString]);

  const onUnMountDrawer = () => {
    let splitText = `${drawerRoutePrefix}/${state.componentKey}`;

    if (subComponentKey) {
      splitText += `/${subComponentKey}`;
    }

    const newPathName = currentPathName.split(splitText).join("");

    redirectUrl(newPathName + state.searchString);
  };

  const onClickClose = (forceClose = false) => {
    const isDrawerClosable = drawerRef.current?.isDrawerClosable;

    if (!forceClose && typeof isDrawerClosable === "function" && !isDrawerClosable(onClickClose)) {
      return;
    }

    if (routeState.current.length) {
      const newState = routeState.current.pop();
      redirectFromRouteState.current = true;

      const replaceComponentkey =
        newState.componentKey + (newState?.subComponentKey ? `/${newState.subComponentKey}` : "");

      const pathName = currentPathName.replace(state.componentKey, replaceComponentkey);

      redirectUrl(pathName + newState.searchString);
      return;
    }

    setIsOpen(false);
  };

  return (
    <Drawer
      isOpen={isOpen}
      containerClass={`route-based-drawer-wrapper ${containerClass}`}
      onClickClose={onClickClose}
      onUnMount={onUnMountDrawer}
      {...drawerProps}
    >
      <Suspense fallback={suspenseLoading || loaderApp()}>
        <DrawerChildren
          component={state.componentKey}
          subComponent={subComponentKey}
          jobDoneCallback={onClickClose}
          onClickClose={onClickClose}
          drawerRef={drawerRef}
        />
      </Suspense>
    </Drawer>
  );
};

RouteBasedDrawer.defaultProps = {
  drawerProps: {},
  drawerRoutePrefix: "/drawer",
  containerClass: "",
};

export default RouteBasedDrawer;
