import React from "react";
import { Trans } from "react-i18next";
import { Button } from "@onlinesales-ai/button-v2";
import "./index.less";

const EmptyMessage = ({
  className = "",
  type,
  layout,
  noBgImg,
  withIllustration,
  illustrationProps,
  noBoxShadow,
  children,
  btnClickCallback: pBtnClickCallback,
  ...props
}) => {
  let bgClass = noBgImg ? "empty-bg" : "";
  let style = {};
  if (noBgImg) {
    style.backgroundImage = `url(${noBgImg})`;
  }

  return (
    <>
      {withIllustration ? (
        <>
          {illustrationProps?.map(
            ({
              illustrationClass,
              illustration,
              description,
              btnDescription,
              btnClickCallback,
              btnDescriptionProps,
            }) => {
              return (
                <div
                  className={`empty-message-wrapper ${illustrationClass} ${type} ${
                    noBoxShadow ? "nobox-shadow" : ""
                  } ${className} ${bgClass} ${layout}`}
                  style={style}
                  {...props}
                >
                  {illustration && (
                    <div className="empty-animation-left">
                      <img alt="empty illustration" src={illustration} />
                    </div>
                  )}
                  {(description || btnDescription) && (
                    <div className="empty-animation-right">
                      <div className="animation-desc">
                        {description && (
                          <div className="animation-desc-inner">
                            <Trans>{description}</Trans>
                          </div>
                        )}
                        {btnDescription && (
                          <Button
                            {...btnDescriptionProps}
                            onClick={btnClickCallback || pBtnClickCallback}
                          >
                            {btnDescription}
                          </Button>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              );
            },
          )}
        </>
      ) : (
        <div
          className={`empty-message-wrapper ${type} ${layout} ${className} ${bgClass}`}
          style={style}
          {...props}
        >
          {typeof children === "string" ? <Trans>{children}</Trans> : children}
        </div>
      )}
    </>
  );
};

EmptyMessage.defaultProps = {
  type: "dark", //light, grey
  layout: "column", //row
  noBgImg: false,
  noBoxShadow: false,
  withIllustration: false,
  illustrationProps: [
    {
      illustrationClass: "",
      illustration:
        "https://osads.gumlet.io/image/upload/v1621925996/product/smm/posts_gif.gif",
      description: "No Data Found",
      btnDescription: "Create New",
    },
  ],
};

export default EmptyMessage;
