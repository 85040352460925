import React from "react";
import { InputText } from "@onlinesales-ai/form-components-v2";
import { Label } from "@onlinesales-ai/label-v2";

import { formatValuesInThousands } from "@onlinesales-ai/util-methods-v2";
import TopupAdditionPills from "../topupAdditionPills";
import RecommendedTopupAmount from "../recommendedTopupAmount";

const TopupNew = ({
  additionPills,
  guidTextConfig,
  ...props
}) => {
  const { labelDefinationKey, budgetToShow, fallBackLabel } = guidTextConfig || {};
  return (
    <div className="custom-background with-bg-image">
      <InputText {...props} />
      <TopupAdditionPills additionPills={additionPills} {...props} />
      <div className="min-recommended-amount-wrapper">
        {guidTextConfig && (
        <span className="min-amount">
          <em>
            <Label
              labelDefinationKey={labelDefinationKey}
              fallBackLabel={fallBackLabel}
              translationConfig={{
                values: {
                  budgetToShow: formatValuesInThousands(budgetToShow),
                },
              }}
            />
          </em>
        </span>)}
        <RecommendedTopupAmount guidTextConfig={guidTextConfig} />
      </div>
    </div>
  );
};

export default TopupNew;
