import { setCookie, mergeObject } from "@onlinesales-ai/util-methods-v2";
import { COOKIES } from "@onlinesales-ai/constants-v2";

import {
  parseProcessedEntityId,
  entityTypeEnum,
} from "../../src/utilities";

import Types from "./types";

const initialState = {
  entityInfo: {
    clients: {},
  },
  entityMetadata: {
    clients: {},
    mccs: {},
  },
  selectedEntityId: null,
  selectedEntityType: null,
  entitiesFetchInProgress: true,
  entitiesFetchError: false,
  entitiesFetchState: {
    // parentId1 : {
    //   children: [],
    //   offset: 0,
    //   isAllFetched: false,
    //   isFetchInProgress: false,
    // }
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.APP_LOG_OUT:
      {
        state = {
          ...initialState,
        };
      }
      break;
    case Types.APP_SET_ENTITY_ID:
      {
        state = {
          ...state,
          selectedEntityId: action.entityId,
          selectedEntityType: action.entityType,
        };
        setCookie(
          COOKIES.SELECTED_ENTITY_ID,
          `${state.selectedEntityType}_${state.selectedEntityId}`,
          100 /*Expiry no of days*/,
          true,
          false,
        );
      }
      break;
    case Types.SET_APP_UPDATE_ENTITY_INFO:
      {
        state = {
          ...state,
          entityInfo: mergeObject({}, state.entityInfo, action.entityInfoToUpdate),
        };
      }
      break;
    case Types.SET_APP_UPDATE_ENTITY_FETCH_STATE:
      {
        state = {
          ...state,
          entitiesFetchState: {
            ...state.entitiesFetchState,
            [action.entityId]: action.entitiesFetchStateToUpdate,
          },
        };
      }
      break;
    case Types.SET_APP_ENTITIES_FETCH_IN_PROGRESS:
      {
        state = {
          ...state,
          entitiesFetchInProgress: action.entitiesFetchInProgress,
        };
      }
      break;
    case Types.UPSERT_ENTITY_METADATA:
      {
        const { entityId: combinedEntityId, entityMetadata } = action;
        const { entityId, entityType } = parseProcessedEntityId(combinedEntityId);
        const storeKey = entityType === entityTypeEnum.MCC ? "mccs" : "clients";

        if (entityId && entityType && storeKey) {
          state = {
            ...state,
            entityMetadata: {
              ...state?.entityMetadata,
              [storeKey]: {
                ...state?.entityMetadata?.[storeKey],
                [combinedEntityId]: {
                  ...state?.entityMetadata?.[storeKey]?.[combinedEntityId],
                  ...entityMetadata,
                },
              },
            },
          };
        }
      }
      break;
    case Types.UPSERT_ENTITY_METADATA_LIST:
      {
        Object.keys(action.entityMetadata).forEach((combinedEntityId) => {
          const { entityType } = parseProcessedEntityId(combinedEntityId);
          const storeKey = entityType === entityTypeEnum.MCC ? "mccs" : "clients";

          state = {
            ...state,
            entityMetadata: {
              ...state?.entityMetadata,
              [storeKey]: {
                ...state?.entityMetadata[storeKey],
                [combinedEntityId]: {
                  ...state?.entityMetadata[storeKey][combinedEntityId],
                  ...action.entityMetadata[combinedEntityId],
                },
              },
            },
          };
        });
      }
      break;
    case Types.SET_USER_PERMISSIONS:
      {
        state = {
          ...state,
          userPermissions: action.userPermissions,
        };
      }
      break;
    default: {
      break;
    }
  }

  return state;
};

export default {
  EntityApplication: reducer,
};
