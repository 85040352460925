import React from "react";
import { DropDownWithCheckbox } from "@onlinesales-ai/dropdown-v2";
import DropdownForm from "../dropdown";

const DropdownWithCheckboxForm = (props) => {
  return (
    <DropdownForm
      dropdownComponent={DropDownWithCheckbox}
      {...props}
    />
  );
};

export default DropdownWithCheckboxForm;
