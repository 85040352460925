import React, { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _cloneDeep from "lodash/cloneDeep";
import { useTranslation } from "react-i18next";
import { FetchBanner } from "@onlinesales-ai/message-card-v2";
import { BILLING_PACKAGE_TYPE } from "@onlinesales-ai/constants-v2";
import { BaseClient, OSBillingServiceV2 } from "@onlinesales-ai/services-v2";
import { fetchPackageDetails as fetchPackageDetailsAction } from "@onlinesales-ai/app-v2/biling";
import { OverlayLoader } from "@onlinesales-ai/loader-v2";
import {
  formatValuesInThousands,
  getCurrencyCode,
  getCurrencyIconCode,
} from "@onlinesales-ai/util-methods-v2";
import { InfoBanner } from "@onlinesales-ai/utils-components-v2";

import Topup from "../topup";
import { postOnboardingData as postOnboardingDataAction } from "src/store/onBoarding";
import SubscriptionFee from "./subsciptionFee";

const Billing = ({
  clientId,
  shopInfo,
  clientAchievement,
  obFlagToPostOnSubscription,
  isAskBillingIfNotSubscribed,
  isAskTopup,
  isBillingNotSubscribed,
  packageDetails,
  isPackageFetchInProgress,
  fetchPackageDetails,
  jobDoneCallback,
  onBoardingDetails,
  postOnboardingStatus,
  componentList,
  subscriptionImageUrl,
  subscriptionFeeDescription,
  goalData,
  minNoOfDays,
  minimumTopupAmount,
  fixedMinimumTopupConfig,
  enoughWalletBalanceMsgConfig,
  accountBalance,
  enablePayLater,
  doNotUseNewChargeAPI,
  paymentMethodProps,
  notEnoughBalanceConfig,
  ...restProps
}) => {
  const { t } = useTranslation();
  const [isPackagesFetchInProgress, setIsPackageFetchInProgress] = useState(true);
  const [packageFetchErrorMsg, setPackageFetchErrorMsg] = useState(null);
  const [isPaymentCancellationInProgress, setIsPaymentCancellationInProgress] = useState(false);
  const isShowBilling = useMemo(() => {
    return isAskBillingIfNotSubscribed && isBillingNotSubscribed;
  }, [isAskBillingIfNotSubscribed, isBillingNotSubscribed]);

  const platformEvents = useMemo(() => {
    const events = [];

    if (isAskTopup) {
      events.push("OS_ACC_BALANCE_UPDATE");
    }

    if (isShowBilling) {
      events.push("REFRESH_BILLING_STATUS");
    }

    return events;
  }, [isShowBilling, isAskTopup]);

  const [minimumWalletBalance, setMinimumWalletBalance] = useState(0);

  useEffect(() => {
    setMinimumWalletBalance((goalData?.data?.budget?.dailyBudget || 0) * minNoOfDays);
  }, [goalData]);

  const canPayLater = useMemo(() => {
    return (
      accountBalance > 0 &&
      accountBalance >= minimumWalletBalance &&
      enablePayLater &&
      !isBillingNotSubscribed
    );
  }, [minimumWalletBalance, accountBalance, enablePayLater, isBillingNotSubscribed]);

  const componentsToShow = useMemo(() => {
    const retnData = [];
    const componentPropsToUse = _cloneDeep(componentList);

    componentPropsToUse.forEach((item) => {
      if ((item.isAskOnlyForBilling && isShowBilling) || (item.isAskOnlyForTopup && isAskTopup)) {
        if (item?.props?.dataKey === "topUpAmount") {
          const validations = item.props.validations || [];
          let minTopupAmount = 0;
          if (fixedMinimumTopupConfig) {
            minTopupAmount = canPayLater
              ? fixedMinimumTopupConfig.enoughWalletBalance || fixedMinimumTopupConfig.default
              : fixedMinimumTopupConfig.default;
          } else {
            minTopupAmount = Math.max(
              minimumTopupAmount,
              Math.ceil(minimumWalletBalance - accountBalance),
            );
          }
          item.props.guidText = t(item.props.guidText);
          item.props.guidText = item.props.guidText?.replace("__MIN_AMOUNT__", minTopupAmount);
          validations.forEach((validationConfig) => {
            if (validationConfig.type === "minValue") {
              validationConfig.value = minTopupAmount;
              validationConfig.msg = validationConfig.msg?.replace(
                "__MIN_AMOUNT__",
                minTopupAmount,
              );
            }
          });
        }
        retnData.push(item);
      }
    });

    return retnData;
  }, [isShowBilling, isAskTopup, minimumWalletBalance, accountBalance]);

  useEffect(() => {
    if (isShowBilling) {
      fetchBillingPackage();
    } else {
      setIsPackageFetchInProgress(false);
    }
  }, [clientId]);

  const fetchBillingPackage = async () => {
    setIsPackageFetchInProgress(true);
    setPackageFetchErrorMsg(null);

    try {
      await fetchPackageDetails({ clientId });
    } catch (e) {
      setPackageFetchErrorMsg(e.errorMsg || BaseClient.getErrorMessage());
    } finally {
      setIsPackageFetchInProgress(false);
    }
  };

  const getCartDetails = (topupAmount) => {
    const billingCartDetails = {
      clientId,
    };

    if (isShowBilling) {
      billingCartDetails.packages = [
        {
          packageId: packageDetails?.[BILLING_PACKAGE_TYPE.ACTIVATION]?.[0].packageId,
        },
      ];
    }

    if (topupAmount) {
      billingCartDetails.topup = {
        amount: topupAmount || 0,
        currency: shopInfo?.currencyCode,
      };
    }

    if (accountBalance) {
      billingCartDetails.balance = {
        amount: accountBalance || 0,
        currency: shopInfo?.currencyCode,
      };
    }

    return billingCartDetails;
  };

  const createCart = (topupAmount) => {
    return OSBillingServiceV2.fetchClientCartDetails({
      clientId,
      currency: shopInfo?.currencyCode,
      source: "DEALER",
      mappings: [getCartDetails(topupAmount)],
      metadata: {},
    });
  };

  const createOrder = ({
    topupAmount,
    payableAmount,
    invoiceId,
    metadata,
    checkoutExtraPayload = {},
  }) => {
    const payload = {
      clientId,
      currency: shopInfo?.currencyCode,
      mappings: [getCartDetails(topupAmount)],
      source: "DEALER",
      totalAmount: payableAmount,
      paymentMode: restProps?.paymentMethod,
      cartInvoiceId: invoiceId,
      metadata,
      ...checkoutExtraPayload,
    };

    return payableAmount === 0
      ? OSBillingServiceV2.postSubscriptionClient(payload)
      : OSBillingServiceV2.postClientTopup(payload);
  };

  const handleChargeAPICall = (topupAmount, metadata, checkoutExtraPayload) => {
    //Create cart to generate invoice id
    return new Promise(async (resolve, reject) => {
      try {
        const cartResponse = await createCart(topupAmount);
        const { summary, invoiceId } = cartResponse;
        const { total: payableAmount = 0 } = summary;

        const orderDetails = await createOrder({
          topupAmount,
          payableAmount,
          invoiceId,
          metadata,
          checkoutExtraPayload,
        });
        // doNotInitPG for case of utilizing wallet balance to do subscription
        resolve({
          ...orderDetails,
          doNotInitPG: payableAmount === 0,
        });
      } catch (error) {
        reject(error);
      }
    });
  };

  const handlePaymentCancellation = async (orderDetails) => {
    if (orderDetails?.transactionId) {
      try {
        setIsPaymentCancellationInProgress(true);
        await OSBillingServiceV2.cancelClientOrder({
          clientId,
          transactionId: orderDetails?.transactionId,
          metadata: {},
        });
      } catch (error) {
      } finally {
        setIsPaymentCancellationInProgress(false);
      }
    }
  };

  const onPaymentDone = () => {
    if (isShowBilling && obFlagToPostOnSubscription) {
      if (onBoardingDetails?.metadata && !onBoardingDetails.metadata[obFlagToPostOnSubscription]) {
        const copyOnBoarding = JSON.parse(JSON.stringify(onBoardingDetails));
        copyOnBoarding.metadata[obFlagToPostOnSubscription] = true;
        postOnboardingStatus(copyOnBoarding);
      }
    }
    jobDoneCallback();
  };

  const renderTopupLastComponent = () => {
    const { dailyBudget } = goalData?.data?.budget || {};
    const keyValueToReplace = {
      currency: `<span class="${getCurrencyIconCode(getCurrencyCode())}" />`,
      minNoOfDays,
      dailyBudget: formatValuesInThousands(dailyBudget),
      balanceRequired: formatValuesInThousands(dailyBudget * minNoOfDays),
      daysOfBudgetLeft: Math.floor(accountBalance / dailyBudget),
    };
    if (
      !notEnoughBalanceConfig.isShow ||
      canPayLater ||
      keyValueToReplace.daysOfBudgetLeft > minNoOfDays
    ) {
      return null;
    }

    return (
      <InfoBanner
        type="info"
        showIcon={false}
        message={
          <span
            dangerouslySetInnerHTML={{
              __html: t(notEnoughBalanceConfig.htmlMessage, keyValueToReplace),
            }}
          />
        }
        {...notEnoughBalanceConfig.infoBannerProps}
      />
    );
  };

  return (
    <FetchBanner
      isLoading={isPackagesFetchInProgress}
      className="error-card"
      errorTitle="Error occurred while fetching billing details."
      errorMsg={packageFetchErrorMsg}
    >
      {isPaymentCancellationInProgress && <OverlayLoader />}
      <Topup
        infoBannerConfig={canPayLater && enoughWalletBalanceMsgConfig}
        paymentMethodProps={{
          forceNewOrderId: true,
          chargeAPICall: doNotUseNewChargeAPI ? undefined : handleChargeAPICall,
          onDismissCallback: handlePaymentCancellation,
          fireEventOnPaymentSuccess: platformEvents,
          ...paymentMethodProps,
        }}
        jobDoneCallback={onPaymentDone}
        componentList={componentsToShow}
        formComponents={{
          Subscription: SubscriptionFee,
        }}
        payLaterCallback={() => {
          jobDoneCallback();
        }}
        renderTopupLastComponent={renderTopupLastComponent}
        enablePayLater={canPayLater}
        {...restProps}
      />
    </FetchBanner>
  );
};

Billing.defaultProps = {
  isAskBillingIfNotSubscribed: false,
  isAskTopup: true,
  enablePayLater: false,
  title: "Top Up",
  ctaText: "Pay Now",
  minNoOfDays: 7,
  minimumTopupAmount: 5000,
  notEnoughBalanceConfig: {
    isShow: false,
    htmlMessage: `<div> Your daily budget is {{currency}}{{dailyBudget}} - which will make your campaign run only for {{daysOfBudgetLeft}} days. To get sufficient visitors to your store a minimum {{minNoOfDays}} day advertising period is recommended. </div><br/><div> Hence, Please ensure that your wallet has at least {{currency}}{{balanceRequired}} = {{minNoOfDays}}(No of Days) * {{currency}}{{dailyBudget}}(Daily Budget) balance. </div>`,
    infoBannerProps: {},
  },
  componentList: [
    {
      isAskOnlyForTopup: true,
      componentType: "InputText",
      doNotShowIfEditMode: true,
      props: {
        labelColumns: 3,
        dataKey: "topUpAmount",
        title: "Top-up Amount:",
        type: "number",
        showCurrency: true,
        validations: [
          {
            type: "nonEmpty",
            msg: "Please enter top-up amount",
          },
          {
            type: "minValue",
            value: 5000,
            msg: "Minimum top-up amount is ₹5000",
          },
        ],
      },
    },
  ],
  doNotUseNewChargeAPI: false,
  jobDoneCallback: () => {},
  obFlagToPostOnSubscription: null,
};

const mapStateToProps = (state) => {
  const { clientsBillingPackageDetails = {} } = state.Billing;
  const { packageDetails = {}, isPackageFetchInProgress } = clientsBillingPackageDetails;
  const clientAchievement = state.ClientAchievement || {};
  const accountBalance = clientAchievement?.data?.prepaidAccountBalance || 0;

  return {
    clientId: state.Application?.clientId,
    shopInfo: state.Application?.shopInfo,
    clientAchievement: state.ClientAchievement,
    onBoardingDetails: state.OnBoarding?.data,
    packageDetails,
    accountBalance,
    isPackageFetchInProgress,
    isBillingNotSubscribed: state.Application.isBillingNotSubscribed,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      postOnboardingStatus: postOnboardingDataAction,
      fetchPackageDetails: fetchPackageDetailsAction,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Billing);
