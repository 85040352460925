import { useEffect } from "react";

import { setDeviceDetectionClassName, useMediaQuery } from "@onlinesales-ai/util-methods-v2";

import "./index.less";

const DetectDevice = ({ onChange }) => {
  const isMobile = useMediaQuery("(max-width: 767px)");
  const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 1024px)");

  const getDeviceType = () => {
    if (isMobile) {
      return "MOBILE";
    }

    if (isTablet) {
      return "TABLET";
    }

    return "DESKTOP";
  };

  useEffect(() => {
    setDeviceDetectionClassName();
    onChange({
      isMobile,
      deviceType: getDeviceType(),
    });
  }, [isMobile, isTablet]);

  return null;
};

export default DetectDevice;
