import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  format,
  formatNumberInAbbreviations,
  formatValuesInThousands,
  getCurrencyDOM
} from "@onlinesales-ai/util-methods-v2";
import { Trans } from "react-i18next";
import { fetchClientAchievements } from "@onlinesales-ai/app-v2/clientAchievement";

import "./index.less";

const WalletBalance = ({
  fetchClientAchievementData,
  startDate,
  endDate,
  clientAchievement = {},
  children = () => {},
  showWalletBalance = true,
  clientId,
  iconClass,
  formatInThousands
}) => {
  const isLoading = clientAchievement?.isLoading;
  const fetchData = () => {
    fetchClientAchievementData({
      fetchAccountBalance: true,
      dateRange: {
        min: format(new Date(startDate), "yyyy-MM-dd"),
        max: format(new Date(endDate), "yyyy-MM-dd"),
        // NOTE: date range doesn't have any effect on wallet balance amount
        // this is just for api validation as svc throws an error without dateRange
      },
    });
  };

  useEffect(() => {
    const existingClientAchievementClientId = clientAchievement?.data?.clientId;
    if (!isLoading && clientId !== existingClientAchievementClientId) {
      fetchData();
    }
  }, [clientId]);

  const accountBalance = clientAchievement?.data?.prepaidAccountBalance || 0;
  const formattedAccountBalance = formatInThousands ? formatValuesInThousands(accountBalance) : formatNumberInAbbreviations(accountBalance);
  return (
    <div className="purge-ignore-wallet-balance-wrapper">
      {showWalletBalance && (
        <>
          <div className="form-group">
            <label className="wallet-label info-label">
              <Trans>Wallet Balance :</Trans>
            </label>
          </div>
          {iconClass && <i className={`icon ${iconClass}`} />}
          <div className="amount form-group mx-sm-3">
            {isLoading ? (
              <span className="animated-bg loading" />
            ) : (
              <>
                {getCurrencyDOM()}
                {formattedAccountBalance}
              </>
            )}
          </div>
        </>
      )}
      {children({
        formattedAccountBalance,
        accountBalance,
        isLoading,
        getCurrencyDOM,
      })}
    </div>
  );
};

WalletBalance.defaultProps = {
  iconClass: null,
  formatInThousands: false
};

const mapStateToProps = (state) => ({
  clientId: state.Application.clientId,
  startDate: state.Dashboard.startDate,
  endDate: state.Dashboard.endDate,
  clientAchievement: state.ClientAchievement,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchClientAchievementData: fetchClientAchievements,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(WalletBalance);
