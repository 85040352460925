import { marked } from "marked";

import { sanitizeHtml } from "./sanitizer";

marked.setOptions({
  renderer: new marked.Renderer(),
  gfm: true,
  breaks: true,
  xhtml: false,
});

export const markdownToHtml = (
  markdownString,
  { senitizeHtml = true, senitizeHtmlOptions } = {},
) => {
  let htmlString = marked.parse(markdownString);

  if (senitizeHtml) {
    htmlString = sanitizeHtml(htmlString, senitizeHtmlOptions);
  }

  return htmlString;
};
