import { formComponents } from "@onlinesales-ai/form-components-v2";

import UploadFile from "./uploadFile";
import Screenshot from "./screenshot";
import Type from "./type";

export default {
  ...formComponents,
  UploadFile,
  Screenshot,
  Type,
};
