import React, { useMemo } from "react";
import { Trans } from "react-i18next";

import { OSHOCWithUtilities } from "@onlinesales-ai/os-hoc-with-utilities-v2";

import MediaUpload from "../mediaUpload";

const Upload = ({
  uploadCta,
  allowedFileTypes,
  uploadFile,
  showToastMessage,
  userInfo,
  clientId,
  onUploadMedia,
  creativeTag,
  useCustomCreativeFetch,
  tooltipMsg,
  uploadTags,
  beforeFileUpload,
}) => {
  const cloudinaryTags = useMemo(() => {
    const tags = [`uploaded_by:${userInfo?.email || ""}`];

    if (creativeTag) {
      tags.push(creativeTag);
    }

    if (useCustomCreativeFetch) {
      tags.push("creative_user_upload");
    }

    if (uploadTags) {
      tags.push(...uploadTags);
    }

    return tags;
  }, [userInfo, useCustomCreativeFetch, creativeTag]);

  const onUploadError = (errorMsg) => {
    showToastMessage({
      type: "ERROR",
      messageToDisplay: errorMsg,
      actionButtonLabel: null,
      toastDuration: 5000,
    });
  };

  return (
    <MediaUpload
      onUploadError={onUploadError}
      overlayDomNode={
        <label className="browse-btn plus-icon-txt-btn">
          {uploadCta ? <Trans>Upload {{ mediaType: uploadCta }}</Trans> : <Trans>Upload</Trans>}
        </label>
      }
      tooltipMsg={tooltipMsg}
      clientId={clientId}
      onUpload={onUploadMedia}
      cloudinaryTags={cloudinaryTags}
      accept={allowedFileTypes}
      beforeFileUpload={beforeFileUpload}
      isValidateSize
      isValidateDimension
      {...uploadFile}
    />
  );
};

export default OSHOCWithUtilities(Upload);
