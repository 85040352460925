import React, { useEffect } from "react";
import { connect } from "react-redux";

const AdBlockerNotification = ({ showToastMessage, isAdBlockerEnabled }) => {
  useEffect(() => {
    if (isAdBlockerEnabled) {
      showToastMessage({
        type: "ERROR",
        messageToDisplay: "AdBlocker is Enabled",
        position: "top-center",
        closeButton: true,
        hideProgressBar: true,
        delay: 500,
        newToast: true,
        autoClose: false,
      });
    }
  }, [isAdBlockerEnabled]);
  return null;
};

AdBlockerNotification.defaultProps = {};

const mapStateToProps = (state) => {
  const { isAdBlockerEnabled } = state.Application;
  return {
    isAdBlockerEnabled,
  };
};

export default connect(mapStateToProps)(AdBlockerNotification);
