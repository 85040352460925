import _keyBy from "lodash/keyBy";
// import { subDays, min, max, startOfDay, differenceInDays } from "date-fns/esm";

// import { format, getCurrencyCode, wait, difference } from "@onlinesales-ai/util-methods-v2";
import { RMN_API_WRAPPER_PREFIX, isNullOrUndefined } from "@onlinesales-ai/util-methods-v2";

// import { marketingCampaignActionType } from "@onlinesales-ai/constants-v2";
import { BrandAdsService, MarketingCampaignServiceV2, MultiVerseService } from "@onlinesales-ai/services-v2";

import Types from "./types";
import { setDomainConfig } from "../domainConfig/actions";

// export const setAudienceData = (data) => ({
//   type: Types.SET_SETTING_AUDIENCE_DATA,
//   data,
// });

export const updateAudienceData = (data) => ({
  type: Types.UPDATE_SETTING_AUDIENCE_DATA,
  data,
});

const prepareAudienceDataToUpdate = (datas = []) => {
  const result = {};
  datas.map((data) => {
    result[data.settingId] = {
      ...(data || {}),
    };
  });
  return result;
};

export const saveAudienceSettings = (data, rmnRetailerId) => {
  return async (dispatch, getState) => {
    const state = getState();
    const clientId = state.Application.clientId;

    const payload = {
      jsonQuery: data,
      clientId,
      endpoint: "saveMasterSettings",
      rmnVendorId: rmnRetailerId,
    };

    const response = await BrandAdsService.postMarketplaceWrapper(payload, "SETTING", {
      servicePrefix: rmnRetailerId ? RMN_API_WRAPPER_PREFIX : "",
    });

    if (response?.settingId) {
      const defsById = prepareAudienceDataToUpdate([{
        ...data,
        settingId: response.settingId
      }]);
      dispatch(updateAudienceData(defsById));
    }

    return response;
  };
};

export const fetchSettingById = (settingIds, rmnRetailerId) => async (dispatch, getState) => {
  const state = getState();
  const clientId = state.Application.clientId;

  const payload = {
    jsonQuery: {
      settingIds,
      selectors: [
        "output",
        "name",
        "channel",
        "launchStep",
        "scope",
        "isActive",
        "_id",
        "alias",
        "isUpdatable",
      ],
      isMergeArrayObject: true,
    },
    endpoint: "fetchSettingsById",
    clientId,
    rmnVendorId: rmnRetailerId,
  };

  const response = await BrandAdsService.fetchMarketplaceWrapper(payload, "SETTING", {
    servicePrefix: rmnRetailerId ? RMN_API_WRAPPER_PREFIX : "",
  });
  const defsById = prepareAudienceDataToUpdate(response?.settings || []);

  dispatch(updateAudienceData(defsById));

  return defsById;
};

export const updateUserInfo = (userInfo) => ({
  type: Types.UPDATE_USER_INFO_V2,
  userInfo,
});

const getSettingsDetails = (data, agencyId, userId) => {
  let foundItem = data?.find((item) => item?.attributes?.userId == userId);
  if (!foundItem) {
    foundItem = data?.find((item) => item?.attributes?.agencyId == agencyId);
  }
  if (!foundItem) {
    foundItem = data?.find(
      (item) => item?.attributes?.userId === "DEFAULT" && item?.attributes?.agencyId === "DEFAULT",
    );
  }
  if (foundItem) {
    return _keyBy(
      foundItem?.attributes?.setting_collections_v2?.data.map((item) => item.attributes),
      "settingType",
    );
  }
  return null;
};

export const fetchUserSettingMappings = (application, isAdmin) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { agencyId, userInfo, clientId } = state.Application;
    const domainConfig = state.DomainConfig || {};
    const payload = {
      agencyId,
      application,
      clientId,
      populate: "*",
      filters: {
        $and: [
          {
            $or: [{ userId: { $eq: userInfo?.id } }, { userId: { $eq: "DEFAULT" } }],
          },
          {
            $or: [{ agencyId: { $eq: agencyId } }, { agencyId: { $eq: "DEFAULT" } }],
          },
        ],
        application: { $eq: application },
      },
      collection: "user-setting-mappings",
      provider: "STRAPI_CLOUD_OSMOS_CMS",
    };
    try {
      let response;
      if (isAdmin) {
        response = await MultiVerseService.fetchEntityAdmin(payload);

      } else {
        response = await MultiVerseService.fetchEntityClient(payload);
      }

      const userSettings = getSettingsDetails(response?.result?.data, agencyId, userInfo?.id);

      dispatch(updateUserInfo(userSettings));
      // To apply user settings level overrides
      dispatch(setDomainConfig(domainConfig));
    } catch (err) {}
  };
};
