import appReducer from "@onlinesales-ai/app-v2/application/reducer";

const initialState = {
  clients: [],
  platformEvents: {},
  userInfo: null,
  clientsFetchInProgress: true,
  clientsFetchError: false,
  isAllClientsFetched: false,
  userFetchInProgress: true,
  userFetchError: false,
  billingAppName: "acquire",
  UA_TOKEN: null,
  shopInfo: null,
  isMobile: false,
  isMonetizeGoalsFetchInProgress: true,
  isBillingStatusFetchInProgress: true,
  isBillingNotSubscribed: false,
  isClientNotSubscribed: false,
  pendingOrderDetails: [],
  selectedEntityType: "CLIENT",
  fetchAppLevelData: true,
};

const reducer = (state = initialState, action) => {
  return appReducer(state, action, initialState);
};

// const reducer = (state = initialState, action) => {
//   let hasChanged = true;

//   switch (action.type) {
//     case "HelloTest":
//       break;
//     default:
//       hasChanged = false;
//       break;
//   }

//   if (!hasChanged) {
//     return appReducer(state, action);
//   }

//   return state;
// };

export default {
  Application: reducer,
};
