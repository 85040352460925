import React from "react";

import { DropDownWithSearchSelectAll } from "@onlinesales-ai/dropdown-v2";

import DropdownForm from "../dropdown";

const DropdownAsyncWithCheckbox = (props) => {
  return (
    <DropdownForm
      dropdownComponent={DropDownWithSearchSelectAll}
      {...props}
    />
  );
};

export default DropdownAsyncWithCheckbox;
