import React from "react";
import times from 'lodash/times';

import { logoConfigExtract } from "@onlinesales-ai/util-methods-v2";

import "./index.less";

const defaultWhiteURL =
  "https://osads.gumlet.io/image/upload/logos/os_loader_white.gif";
const defaultBlackURL =
  "https://osads.gumlet.io/image/upload/logos/os_loader_black.gif";

export const loaderWhite = ({ imgProps = {}, className } = {}) => {
  const loaderURL = logoConfigExtract("loaderWhite");

  return (
    <div className={`loader-wrapper white-loader ${className}`}>
      <img alt="logo" className="loader-img" {...imgProps} src={loaderURL || defaultWhiteURL} />
    </div>
  );
};

export const loaderApp = (className) => {
  return loaderWhite({ imgProps: { style: { width: "60px" } }, className });
};

export const loaderBlack = ({ imgProps = {} } = {}) => {
  const loaderURL = logoConfigExtract("loaderBlack");

  return (
    <div className="loader-wrapper black-loader">
      <img alt="logo" className="loader-img" {...imgProps} src={loaderURL || defaultBlackURL} />
    </div>
  );
};

export const loaderLine = () => {
  return (
    <div className="line-loader">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="-30 45 725 80">
        <polyline
          className="animate"
          points="0.3 138.79 134.3 102.29 267.3 107.29 400.3 87.79 533.8 85 665.8 67.79"
        />
      </svg>
    </div>
  );
};

export const loaderTreemap = () => {
  return (
    <div className="loader-treemap">
      <div className="treemap">
        <i />
        <i />
        <i />
        <i />
      </div>
    </div>
  );
};

export const loaderArea = ({ containerClass = null, height }) => {
  return (
    <div className={`loader-area ${containerClass}`} style={{ height: `${height}px` }}>
      <div className="graph" />
      <div className="graph" />
    </div>
  );
};

export const loaderQuadrant = ({ containerClass = null, height }) => {
  return (
    <div className={`loader-quadrant ${containerClass}`}>
      <div className="loader-quadrant-section" style={{ minHeight: `${height}px` }}>
        <div className="loader-quadrant-inner">
          <div className="animated-circle top-left large" />
          <div className="animated-circle top-right large" />
        </div>
        <div className="loader-quadrant-inner">
          <div className="animated-circle top-right large" />
          <div className="animated-circle top-left small" />
        </div>
        <div className="loader-quadrant-inner">
          <div className="animated-circle top-bottom small" />
          <div className="animated-circle top-bottom large ml-auto mr-5" />
          <div className="animated-circle large ml-5 mr-auto" />
        </div>
        <div className="loader-quadrant-inner">
          <div className="animated-circle bottom-right mt-3 ml-3" />
          <div className="animated-circle top-left mt-3 ml-auto" />
          <div className="animated-circle top-left" />
        </div>
      </div>
    </div>
  );
};

export const loaderBar = (count = 30) => {
  return (
    <div className="loader-bar stacked">
      {
        times(count, () => (
          <i />
        ))
      }
    </div>
  );
};

export const loaderGrid = ({ containerClass = null, height, rows = 8, columns = 14 }) => {
  return (
    <div className={`grid-loader ${containerClass}`} style={{ height: `${height}px` }}>
      {times(rows, () => (
        <div className="d-align-center w100p">
          {times(columns, () => (
            <div className="animated-bg" />
          ))}
        </div>
      ))}
    </div>
  );
};

export const loaderSolidGauge = () => {
  return (
    <div className="loader-solid-gauge">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0px"
        width="392px"
        height="350px"
        viewBox="0 0 162 220"
        enableBackground="new 0 0 200 200"
        className="svg-loader"
      >
        <g transform="rotate(-90 100 100)">
          <circle fill="none" cx="6.25em" cy="6.25em" r="5em" className="svg-loader_glow bg" />
          <circle fill="none" cx="6.25em" cy="6.25em" r="3.5em" className="svg-loader_glow bg" />
          <circle
            fill="none"
            cx="6.25em"
            cy="6.25em"
            r="5em"
            className="svg-loader_grow svg-loader_animate outer"
          />
          <circle
            fill="none"
            cx="5.9em"
            cy="5.9em"
            r="4.375em"
            className="svg-loader_grow svg-loader_animate inner"
            transform="scale(.8, .8) translate(25 25)"
          />
        </g>
      </svg>
    </div>
  );
};

export const loaderSolidGaugeSingle = () => {
  return (
    <div className="loader-solid-gauge single">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0px"
        width="392px"
        height="300px"
        viewBox="0 0 162 220"
        enableBackground="new 0 0 200 200"
        className="svg-loader"
      >
        <g transform="rotate(-90 100 100)">
          <circle fill="none" cx="6.25em" cy="6.25em" r="5em" className="svg-loader_glow bg" />
          <circle
            fill="none"
            cx="6.25em"
            cy="6.25em"
            r="5em"
            className="svg-loader_grow svg-loader_animate outer"
          />
        </g>
      </svg>
    </div>
  );
};

export const loaderThreeColorDots = () => {
  return <div className="loader-three-color-dots" />;
};

export const loaderSpinner = () => {
  return <img alt="logo" className="loader-spinner" src="https://osads.gumlet.io/image/upload/v1580290660/product/spinner_loader.gif" />;
};
