import React from "react";
import { Trans } from "react-i18next";

import { Button } from "@onlinesales-ai/button-v2";

import "./index.less";

const ErrorMessage = ({
  className = "",
  type,
  title = "",
  description = "",
  onDismiss,
  isDismissable,
  dismissIconClass = "icon-close",
  showRetryButton,
  retryButtonProps,
}) => {
  return (
    <div className={`fetch-error-banner ${className}`}>
      <div className={`error-callout-container bs-callout ${type}`}>
        <div className="error-title">
          <Trans>{title}</Trans>
        </div>
        <div className="error-description">
          <Trans>{description}</Trans>
          {showRetryButton && (
            <Button
              link
              className="retry-button pl-1"
              onClick={retryButtonProps?.retryBtnCallback}
              {...retryButtonProps}
            >
              {retryButtonProps?.cta || "Retry"}
            </Button>
          )}
        </div>
      </div>
      {isDismissable && (
        <span className={`close-icon icon ${dismissIconClass}`} onClick={onDismiss} />
      )}
    </div>
  );
};

ErrorMessage.defaultProps = {
  type: "error",
  isDismissable: false,
  showRetryButton: false,
  retryButtonProps: {},
};

export default ErrorMessage;
