import React, {useState} from "react";
import { connect } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

const RouteBasedModal = ({ 
  modalRoutePrefix,
  routeBasedModalConfig,
  redirectUrl = () => {},
  componentMapping = {}
}) => {
  const { component, subComponent } = useParams();
  const location = useLocation();
  const searchString = location?.search || "";
  const currentPathName = location?.pathname || "";
  const componentProps = routeBasedModalConfig?.componentConfig?.[component] || {};

  const [isShow, setIsShow] = useState(true);

  const onCloseModal = () => {
    setIsShow(false);
  };

  const onModalDestroy = () => {
    let splitText = `${modalRoutePrefix}/${component}`;

    if (subComponent) {
      splitText += `/${subComponent}`;
    }

    const newPathName = currentPathName.split(splitText).join("");

    redirectUrl(newPathName + searchString);
  };

  if(component && componentMapping[component]){
    const ModalComponent = componentMapping[component];

    return (
      <ModalComponent
        isShow={isShow}
        jobDoneCallback={onCloseModal}
        onToggle={onCloseModal}
        onExited={onModalDestroy}
        containerClass={"route-based-modal"}
        {...componentProps}/>
    );
  }

  return null;
};

RouteBasedModal.defaultProps = {
  modalRoutePrefix: "/modal",
};

const mapStateToProps = (state) => {
  return {
    routeBasedModalConfig: state.DomainConfig.routeBasedModalConfig || {},
  };
};
  
export default connect(mapStateToProps)(RouteBasedModal);