/* eslint-disable no-shadow */
import { useEffect, useCallback, useState, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _isEmpty from "lodash/isEmpty";
import _difference from "lodash/difference";

import { routes } from "src/utilities/constants";
import { redirectUrl as redirectUrlAction } from "src/store/application";
import { ONBOARDINGSTATUS, CLIENT_AGGREGATOR_CONFIG } from "@onlinesales-ai/constants-v2";
import {
  fetchOnBoardingStatus,
  setOnboardingDataFetchInProgress,
} from "@onlinesales-ai/app-v2/onBoarding";
import { uiAPIMonitor } from "@onlinesales-ai/error-catcher-v2";
import {
  checkBillingSubscription as checkBillingSubscriptionAction,
  setBillingSubscribedState as setBillingSubscribedStateAction,
  pollPendingOrders as pollPendingOrdersAction,
  fetchPropertySetting as fetchPropertySettingAction,
  fetchSellerConfig as fetchSellerConfigAction,
} from "@onlinesales-ai/app-v2/application";
import {
  pollData as smmPollDataAction,
  pollBoostPostData as pollBoostPostDataAction,
} from "@onlinesales-ai/smm-common-v2/store";
import { fetchHygieneSuggestionsPerformanceData as fetchPendingOpportunity } from "@onlinesales-ai/app-v2/pendingOpportunity";
import { setSuggestionDefinitions } from "src/store/suggestions";
import { getDefaultRoute, getUTCToZonedDate } from "@onlinesales-ai/util-methods-v2";
import PlatformEventManager from "@onlinesales-ai/event-manager-v2";
import { blockingAccountLinkingData as fetchVendorAccountDataAction } from "@onlinesales-ai/account-linking-v2";
import { fetchBillingSettings as fetchBillingSettingsAction } from "@onlinesales-ai/finance-v2/store/finance";

import { fetchClientLevelRequiredInfo as fetchClientLevelInfo, fetchBillingStatus as fetchBillingStatusAction } from "src/store/application/actions";
import { setDashobardDateRange as setDashobardDateRangeAction } from "src/store/dashboard/actions";
import { setOnboardingData } from "src/store/onBoarding";
import { billingExtraHandler } from "./MiscHandler";
import { subDays } from "date-fns/esm";
const ClientLevelData = ({
  clientId,
  clients,
  userInfo,
  isInternalUser,
  isAllClientsSelected,
  clientsBillingStatus,
  onBoardingData,
  fetchOnBoarding,
  setOnboardingDataFetchInProgress,
  activeApp,
  location,
  redirectUrl,
  fetchHygieneSuggestionsPerformanceData,
  fetchClientLevelRequiredInfo,
  fetchBillingStatus,
  setBillingSubscribedState,
  isBillingStatusFetchInProgress,
  appBillingConfig,
  isBillingNotSubscribed,
  checkBillingSubscription,
  notAllowedRoutesForClient,
  billingRouteKey,
  pendingOrderDetails,
  pollPendingOrders,
  pollBoostPostData,
  smmPollData,
  isPollSmmData,
  postIds,
  fetchPropertySetting,
  fetchSellerConfig,
  clientAppLevelData,
  fetchBillingSettings,
  appLevelData,
  fetchVendorAccountData,
  setDashobardDateRange,
}) => {
  const clientsSetForFirstTime = useRef(false);

  const [localPendingOrderIds, setLocalPendingOrderIds] = useState([]);
  const appLevelActionsEnum = {
    "PROPERTY_SETTINGS": fetchPropertySetting,
    "SELLER_CONFIG": fetchSellerConfig,
    "LOW_PAYMENT_COUNT": fetchBillingStatus,
    "ACCOUNT_LINKING_DATA": fetchVendorAccountData,
    "FINANCE_CONFIG": fetchBillingSettings,
  };

  const fetchConfigData = async (dataConfig) => {
    if (!dataConfig?.dataToFetch?.length) {
      return Promise.resolve();
    }

    try {
      const allActions = dataConfig.dataToFetch.map((key) => {
        const config = dataConfig.config[key];
        const action = appLevelActionsEnum[config.action];
        return action({
          ...config,
          redirectUrl,
        });
      });
      await Promise.all(allActions);
    } catch (err) {
      uiAPIMonitor("SEV2", "DOWNTIME_PAGE_CALLED", {
        calledFrom: "fetchConfigData",
        error: err,
        dataConfig,
      });
      redirectUrl(routes.DOWNTIME.path);
    }
  };

  const setDashboardDates = () => {
    setDashobardDateRange({
      startDate: subDays(getUTCToZonedDate(new Date()), "7").valueOf(),
      endDate: subDays(getUTCToZonedDate(new Date()), "1").valueOf(),
      compareStartDate: null,
      compareEndDate: null,
    });
  };

  const onClientChange = async () => {
    if (clientId) {
      try {
        await fetchConfigData(clientAppLevelData);
        if (onBoardingData?.clientId !== clientId) {
          await fetchOnBoarding(routes, redirectUrlAction, setOnboardingData);
          setDashboardDates();
        } else {
          setOnboardingDataFetchInProgress(false);
        }

        await checkBillingSubscription(routes, redirectUrlAction, billingExtraHandler);
        fetchHygieneSuggestionsPerformanceData(setSuggestionDefinitions);
        if (clientId != CLIENT_AGGREGATOR_CONFIG.value) {
          fetchClientLevelRequiredInfo(clientId);
        }
      } catch (error) {
        uiAPIMonitor("SEV2", "CLIENT_ON_STATUS_BOARDING_FAILED", {
          error,
        });
      }
    } else {
      await checkBillingSubscription(routes, redirectUrlAction, billingExtraHandler);
    }
  };

  const onRefreshBillingStatus = useCallback(async () => {
    await checkBillingSubscription(routes, redirectUrlAction);
    await fetchOnBoarding(routes, redirectUrlAction, setOnboardingData, false);
  }, []);

  const CheckIfRouteMatch = (routes = []) => {
    let isMatch = false;

    for (let i = 0; i < routes.length; i++) {
      if (routes[i] && location.pathname.includes(routes[i])) {
        isMatch = true;
        break;
      }
    }

    return isMatch;
  };

  useEffect(() => {
    const orderIds = pendingOrderDetails
      .map((d) => d.id)
      .sort();

    if (isBillingNotSubscribed && _difference(localPendingOrderIds, orderIds).length) {
      onRefreshBillingStatus();
    }

    setLocalPendingOrderIds(orderIds);
  }, [pendingOrderDetails]);

  useEffect(() => {
    PlatformEventManager.on("REFRESH_BILLING_STATUS", onRefreshBillingStatus);
    return () => {
      PlatformEventManager.off("REFRESH_BILLING_STATUS", onRefreshBillingStatus);
    };
  }, []);

  useEffect(() => {
    if (clientId && location && CheckIfRouteMatch(notAllowedRoutesForClient)) {
      redirectUrl(getDefaultRoute() || routes?.DASHBOARD?.path);
    }
  }, [notAllowedRoutesForClient]);

  useEffect(() => {
    const finalStatus = onBoardingData?.apps?.[activeApp]?.finalStatus;
    const { pathname } = location || {};
    const {
      isNotAllowBillingAccessIfSubscribed,
      billingRoute,
      keysToNotBlock = [],
      allowAccessIfBlocked = [],
    } = appBillingConfig;
    const isBlockBilling = keysToNotBlock.length
      ? !keysToNotBlock.includes(billingRouteKey || "default")
      : true;
    const billingRouteToUse = billingRoute?.[billingRouteKey || "default"];

    if (!_isEmpty(onBoardingData)) {
      if (finalStatus !== ONBOARDINGSTATUS.ACTIVE && !pathname.includes(routes.ONBOARDING.path)) {
        redirectUrl(routes.ONBOARDING.path);
      } else if (
        finalStatus === ONBOARDINGSTATUS.ACTIVE &&
        isBillingNotSubscribed &&
        billingRouteToUse &&
        isBlockBilling &&
        !CheckIfRouteMatch([billingRouteToUse.replace("__APPEND__", ""), ...allowAccessIfBlocked])
      ) {
        redirectUrl(billingRouteToUse);
      } else if (
        finalStatus === ONBOARDINGSTATUS.ACTIVE &&
        billingRouteToUse &&
        isNotAllowBillingAccessIfSubscribed &&
        pathname.includes(billingRouteToUse.replace("__APPEND__", "")) &&
        !isBillingNotSubscribed
      ) {
        redirectUrl(getDefaultRoute() || routes.DASHBOARD.path);
      } else if (
        finalStatus === ONBOARDINGSTATUS.ACTIVE &&
        pathname.includes(routes.ONBOARDING.path)
      ) {
        redirectUrl(getDefaultRoute() || routes.DASHBOARD.path);
      }
    }
  }, [
    onBoardingData,
    isBillingNotSubscribed,
    appBillingConfig,
    location.pathname,
    billingRouteKey,
  ]);

  useEffect(() => {
    if (isBillingStatusFetchInProgress) {
      return;
    }

    let isNotSubscribed = true;

    if (isNotSubscribed) {
      const clientKeys = Object.keys(clientsBillingStatus);
      for (let i = 0; i < clientKeys.length; i++) {
        if (clientsBillingStatus[clientKeys[i]].status === "ACTIVE") {
          isNotSubscribed = false;
          break;
        }
      }
    }

    if (
      !isAllClientsSelected &&
      appBillingConfig?.checkForSingleClient &&
      appBillingConfig?.isCheckClientLevelBilling &&
      clientsBillingStatus?.[clientId]?.status
    ) {
      isNotSubscribed = clientsBillingStatus?.[clientId]?.status !== "ACTIVE";
    }

    if (isNotSubscribed !== isBillingNotSubscribed) {
      setBillingSubscribedState(isNotSubscribed);
    }
  }, [clientsBillingStatus, clientId]);

  useEffect(() => {
    onClientChange();
  }, [clientId, isAllClientsSelected]);

  useEffect(() => {
    if (appBillingConfig.checkClientBillingOnly) {
      checkBillingSubscription(routes, redirectUrlAction, billingExtraHandler);
    }
  }, [appBillingConfig.checkClientBillingOnly]);

  useEffect(() => {
    if (clients.length > 0 && (!_isEmpty(userInfo)) && !clientsSetForFirstTime.current) {
      clientsSetForFirstTime.current = true;
      fetchConfigData(appLevelData);
    }
  }, [clients, userInfo]);

  useEffect(() => {
    const { isPollPendingOrders = false } = appBillingConfig;
    if (clientId !== CLIENT_AGGREGATOR_CONFIG.value && isPollPendingOrders) {
      const poll = pollPendingOrders();

      poll.startPoll();

      return () => {
        poll.stopPoll();
      };
    }
  }, [clientId]);

  useEffect(() => {
    if (isPollSmmData) {
      const poll = smmPollData();

      poll.startPoll();

      return () => {
        poll.stopPoll();
      };
    }
  }, [postIds]);

  useEffect(() => {
    if (isPollSmmData) {
      const poll = pollBoostPostData();

      poll.startPoll();

      return () => {
        poll.stopPoll();
      };
    }
  }, []);

  return null;
};

const mapStateToProps = (state) => {
  const { clientStatus: clientsBillingStatus } = state.Billing.clientsBillingPackageDetails;

  return {
    onBoardingData: state.OnBoarding.data,
    clientId: state.Application.clientId,
    clients: state.Application.clients,
    userInfo: state.Application.userInfo,
    activeApp: state.DomainConfig.activeApp || "ACQUIRE",
    isInternalUser: state.Application.isInternalUser,
    billingRouteKey: state.Application.billingRouteKey,
    isAllClientsSelected: state.Application.isAllClientsSelected,
    location: state.router?.location || {},
    postIds: state.SMMPosts?.postIds,
    appBillingConfig: state.DomainConfig.appBillingConfig || {},
    isPollSmmData: state.DomainConfig.isPollSmmData || false,
    notAllowedRoutesForClient: state.DomainConfig.notAllowedRoutesForClient || [],
    isBillingNotSubscribed: state.Application.isBillingNotSubscribed,
    isBillingStatusFetchInProgress: state.Application.isBillingStatusFetchInProgress,
    clientsBillingStatus,
    pendingOrderDetails: state.Application.pendingOrderDetails,
    clientAppLevelData: state.DomainConfig.clientAppLevelData,
    appLevelData: state.DomainConfig.appLevelData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      redirectUrl: redirectUrlAction,
      fetchOnBoarding: fetchOnBoardingStatus,
      setOnboardingDataFetchInProgress,
      fetchHygieneSuggestionsPerformanceData: fetchPendingOpportunity,
      fetchClientLevelRequiredInfo: fetchClientLevelInfo,
      fetchBillingStatus: fetchBillingStatusAction,
      checkBillingSubscription: checkBillingSubscriptionAction,
      setBillingSubscribedState: setBillingSubscribedStateAction,
      pollPendingOrders: pollPendingOrdersAction,
      fetchPropertySetting: fetchPropertySettingAction,
      fetchSellerConfig: fetchSellerConfigAction,
      smmPollData: smmPollDataAction,
      pollBoostPostData: pollBoostPostDataAction,
      fetchVendorAccountData: fetchVendorAccountDataAction,
      fetchBillingSettings: fetchBillingSettingsAction,
      setDashobardDateRange: setDashobardDateRangeAction,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientLevelData);
