import BaseClient from "../baseClient";

const servicePrefix = "/hadesV2/";

class Hades extends BaseClient {
  static getErrorMessages() {
    return {
      default: "Looks like we are facing some internal issue. Please, retry after sometime.",
      IP0000:
        "It seems some input parameters are not valid. Please update your inputs and try again.",
      AD0000:
        "Oops. It seems you are not authorized to take this action. Please log in with appropriate credentials.",
      I0004:
        "Oops. Seems the system is acting up - sorry for the inconvenience. Please try again in some time. (Please be assured our tech geniuses have been notified of this glitch).",
      IR0000: "Oops! The password reset link you used has either expired or is invalid. Password reset links are time-sensitive and can only be used once. Please click 'Forgot Password' again to receive a fresh link and reset your password",

      // mobile number login errors
      EPU001: "Looks like we are facing some internal issue.Please, retry after sometime",
      EPU002: "OTP validation failed, Please try again!", // Firebase error if phone number validation failed
      EPU003:
        "Unable to update contact, Please ensure new details is different than current details", // Duplicate entity error

      // re-captch login errors
      EPU007: "Something went wrong. we are not able to validate your request. please try again.",

      // sign up errors
      EPU011: "User with given email already exist.",
      EPU012: "Your verification link has been expired, Please try again.",
      EPU013: "Invalid verification link, Please use correct link to verify user.",
      [`${servicePrefix}authenticate`]: {
        AD0000: "Invalid credentials, Please log in with appropriate credentials.",
      },
      [`${servicePrefix}profileUpdate`]: {
        IR0000: "The provided contact information is already associated with another account.",
      },
      EPU014: "Derived access can not be removed",
    };
  }

  static getUser(application) {
    return this.apiCall(
      {
        url: `${servicePrefix}usersV2?jsonQuery=${JSON.stringify({
          "@class": "com.sokrati.hadesObjects.aclActions.getUsers.GetUserRequest",
        })}`,
        method: "GET",
      },
      application,
    );
  }

  static getUsersByClientId(config, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}usersV2?jsonQuery=${JSON.stringify({
          "@class": "com.sokrati.hadesObjects.aclActions.getUsers.GetUsersByClientIdRequest",
          clientId: config.clientId,
          entityType: config.entityType || "CLIENT",
        })}`,
        method: "GET",
      },
      application,
    );
  }

  static logoutUser() {
    return this.apiCall({
      url: `${servicePrefix}logout`,
      method: "GET",
    });
  }

  static signUpUser(request, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}signup`,
        method: "POST",
        data: encodeURIComponent(JSON.stringify(request)),
      },
      application,
      {
        doNotSendData: true,
      },
    );
  }

  // static postClientData(request) {
  //   return this.apiCall({
  //     url: `${servicePrefix}clients`,
  //     data: JSON.stringify(request),
  //     method: "POST",
  //   });
  // }

  // static postMccData(request) {
  //   return this.apiCall({
  //     url: `${servicePrefix}mcc`,
  //     data: JSON.stringify(request),
  //     method: "POST",
  //   });
  // }

  // static fetchEntityMetaData(request) {
  //   return this.apiCall({
  //     url: `${servicePrefix}entityMetadata`,
  //     params: { jsonQuery: JSON.stringify(request) },
  //     method: "GET",
  //   });
  // }

  // static signUpUser(request, application) {
  //   return this.apiCall(
  //     {
  //       url: `${servicePrefix}signup`,
  //       method: "POST",
  //       data: encodeURIComponent(JSON.stringify(request)),
  //     },
  //     application,
  //   );
  // }

  static fetchUsersList(config = {}, application = "APPLICATION") {
    return this.apiCall(
      {
        url: `${servicePrefix}userEntityMapping`,
        method: "GET",
        params: {
          entityType: config.entityType || "CLIENT",
          isFetchAllUsers: config.hasOwnProperty("isFetchAllUsers") ? config.isFetchAllUsers : true,
          entityId: config.entityId,
          isInternalRequest: config.hasOwnProperty("isInternalRequest")
            ? config.isInternalRequest
            : false,
        },
      },
      application,
    );
  }

  static changeUserAccessRole(request, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}aclUserAccess`,
        method: "POST",
        data: JSON.stringify(request),
      },
      application,
    );
  }

  static authenticate(request, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}authenticate`,
        method: "POST",
        data: encodeURIComponent(JSON.stringify(request)),
      },
      application,
      {
        doNotSendData: true,
      },
    );
  }

  static profileUpdate(request, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}profileUpdate`,
        method: "POST",
        data: encodeURIComponent(JSON.stringify(request)),
      },
      application,
    );
  }

  static fetchClients(config = {}, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}clients`,
        method: "GET",
        params: config,
      },
      application,
    );
  }

  static validateUser(config = {}, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}validateUser`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(config) },
      },
      application,
    );
  }

  static validateUserPostCall(request = {}, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}validateUser`,
        method: "POST",
        data: encodeURIComponent(JSON.stringify(request)),
      },
      application,
    );
  }

  static forgotPassword(request, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}forgotPassword`,
        method: "POST",
        data: JSON.stringify(request),
      },
      application,
    );
  }

  static changePassword(request, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}changePassword`,
        method: "POST",
        data: JSON.stringify(request),
      },
      application,
      {
        doNotSendData: true,
      },
    );
  }

  static renameUser(request, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}renameUser`,
        method: "POST",
        data: JSON.stringify(request),
      },
      application,
    );
  }

  static getEntities(request, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}userEntities`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(request) },
      },
      application,
      options,
    );
  }

  static getEntitiesBySearch(request, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}userEntitiesSearch`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(request) },
      },
      application,
      options,
    );
  }

  static getEntityDataBySelectors(request, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}entity`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(request) },
      },
      application,
      options,
    );
  }

  // static getEntityData(request, application, options) {
  //   return this.apiCall(
  //     {
  //       url: `${servicePrefix}entityMetadata`,
  //       method: "GET",
  //       params: { jsonQuery: JSON.stringify(request) },
  //     },
  //     application,
  //     options,
  //   );
  // }

  static getEntityChildren(request, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}getEntityChildren`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(request) },
      },
      application,
      options,
    );
  }

  static getUserAccess(request, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}usersV2?jsonQuery=${JSON.stringify({
          "@class": "com.sokrati.hadesObjects.aclActions.getUsers.GetUsersByEntityRequest",
          ...request,
        })}`,
        method: "GET",
      },
      application,
    );
  }

  static getUserAccessRoleList(request, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}accessRoles`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(request) },
      },
      application,
      options,
    );
  }

  static postUserAccess(request, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}inviteUser`,
        method: "POST",
        data: encodeURIComponent(JSON.stringify(request)),
      },
      application,
    );
  }

  static updateUserAccess(request, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}aclUserAccess`,
        method: "POST",
        data: encodeURIComponent(JSON.stringify(request)),
      },
      application,
    );
  }

  static postAdvertiserData(request, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}entity`,
        method: "POST",
        data: encodeURIComponent(JSON.stringify(request)),
      },
      application,
    );
  }
}

export default Hades;
