import types from "./types";

export const setUINotifications = ({ ...params }) => ({
  type: types.SET_UI_NOTIFICATIONS,
  ...params,
});

export const dimissUIGeneratedNotification = (notificationType, key) => ({
  type: types.DISMISS_UI_GENERATED_NOTIFICATIONS,
  notificationType,
  key,
});
