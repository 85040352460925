import React, { useEffect, useMemo, useRef } from "react";
import { connect } from "react-redux";

import _isEmpty from "lodash/isEmpty";

import { PopoverNotification } from "@onlinesales-ai/popover-notification-v2";
import { pendoTrackEvent } from "@onlinesales-ai/util-methods-v2";

const PopoverNotificationConsumer = ({
  tooltipNotifications,
  defaultNotifications,
  children,
}) => {
  const notificationRef = useRef(null);
  const firstTimeShownFor = useRef(null);

  const notificationsToUse = useMemo(() => {
    let retnData = [];
    if (tooltipNotifications?.length > 0) {
      retnData = [...(tooltipNotifications || [])];
    }
    if (defaultNotifications?.length > 0) {
      retnData.push(...defaultNotifications || []);
    }
    return retnData;
  }, [tooltipNotifications, defaultNotifications]);

  const notificationProps = useMemo(() => {
    return notificationsToUse?.[0] || {};
  }, [notificationsToUse]);

  useEffect(() => {
    if (notificationProps.showFirstTime && firstTimeShownFor.current !== notificationProps.name) {
      notificationRef.current?.forceShow();
      firstTimeShownFor.current = notificationProps.name;
      let notificationMetadata = {};
      try {
        notificationMetadata = JSON.parse(notificationProps.metadata);
      } catch (error) {}
      pendoTrackEvent(`POPOVER_NOTIFICATION||${notificationProps.name}`, notificationMetadata);
    }
  }, [notificationProps]);

  return (
    <>
      {!_isEmpty(notificationProps) ? (
        <PopoverNotification
          componentRef={notificationRef}
          {...notificationProps}
          childrenProps={{
            childrenClass: notificationProps.childrenClass,
          }}
        >
          {children}
        </PopoverNotification>
      ) : (
        <>{children({})}</>
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { notificationKey } = ownProps;
  return {
    tooltipNotifications: state.UINotification?.data[notificationKey] || [],
  };
};

export default connect(mapStateToProps)(PopoverNotificationConsumer);
