import React, { useMemo, useState } from "react";

import Select from "react-select";
import AsyncSelect from "react-select/async";

import { Dropdown } from "react-bootstrap";

import BaseDropdown from "./BaseDropdown";
import { useOnClickOutside } from "@onlinesales-ai/util-methods-v2";

const DropdownIndicator = () => <span className="icon icon-search-2 search-icon" />;

const DropdownWithSearch = ({
  onChange: pOnChange,
  components,
  isAsync,
  value,
  options,
  placeholder,
  searchPlaceholder,
  selectedValue: pSelectedValue,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const selectedValue = useMemo(() => {
    return pSelectedValue || options?.find?.((o) => o.value === value);
  }, [value, options]);

  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  const dropdownRef = useOnClickOutside(() => {
    setIsOpen(false);
  });

  return (
    <Dropdown ref={dropdownRef} className="dropdown-with-search-wrapper" onToggle={onToggle} show={isOpen}>
      <Dropdown.Toggle>
        <div className="selected-text-wrapper">
          <div className="selected-text ellipsis-text">
            {selectedValue?.label || <span className="placeholder">{placeholder}</span>}
          </div>
          <span className="icon icon-caret-down" />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-search-menu">
        {isOpen ? (
          <BaseDropdown
            {...props}
            SelectComponent={isAsync ? AsyncSelect : Select}
            autoFocus
            backspaceRemovesValue={false}
            components={{ ...components, DropdownIndicator, IndicatorSeparator: null }}
            controlShouldRenderValue={false}
            hideSelectedOptions={false}
            isClearable={false}
            menuIsOpen
            onChange={(newValue) => {
              pOnChange(newValue);
              setIsOpen(false);
            }}
            tabSelectsValue={false}
            value={value}
            options={options}
            placeholder={searchPlaceholder}
          />
        ) : null}
      </Dropdown.Menu>
    </Dropdown>
  );
};

DropdownWithSearch.defaultProps = {
  searchPlaceholder: "Search",
};

export default DropdownWithSearch;
