import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import _capitalize from "lodash/capitalize";

import { Form } from "@onlinesales-ai/form-components-v2";
import { ShopsUIService, NeonService } from "@onlinesales-ai/services-v2";
import { Button } from "@onlinesales-ai/button-v2";
import { configHoc, pendoTrackEvent } from "@onlinesales-ai/util-methods-v2";

import formComponents from "./formComponents";

import "./index.less";

const TicketComponent = ({
  componentToShow,
  componentConfig,
  ctaText,
  title,
  onClickClose,
  userInfo,
  shopInfo,
  clientId,
  initialValues,
  agencyId,
  sellerTags,
  descKeys,
}) => {
  const formRef = useRef({});
  const [postState, setPostState] = useState({
    isLoading: false,
    postSuccess: false,
  });

  const onSubmit = async (values) => {
    setPostState({
      isLoading: true,
      postSuccess: false,
    });

    const payload = {
      contact: {
        email: userInfo?.email,
        lastName: userInfo?.name,
        phone: userInfo?.contactNo,
      },
      subject: `Ticket: ${shopInfo?.name} (${clientId})`,
      description: descKeys.reduce((memo, key) => {
        if (values[key]) {
          return `${memo}<br/><b>${_capitalize(key)}</b>: ${values[key]}<br/>`;
        }

        return memo;
      }, ""),
      departmentId: values.departmentId,
      tags: [],
      zohoParams: {
        cf: {},
      },
    };

    try {
      if (sellerTags?.length) {
        const res = await NeonService.fetchDealerMetadata({
          clientId,
          agencyId,
          currency: shopInfo.currencyCode,
          selectors: ["sellerTags"],
        });

        if (sellerTags?.length) {
          sellerTags.forEach((tag) => {
            if (res.sellerTags[tag.key]) {
              const val = `${tag.prefix || ""}${res.sellerTags[tag.key]}`;

              payload.tags.push(val);

              if (tag.cfKey) {
                payload.zohoParams.cf[tag.cfKey] = val;
              }
            }
            return null;
          });
        }
      }

      await ShopsUIService.createZohoTicket(payload);
      setPostState({
        isLoading: false,
        postSuccess: true,
      });
      pendoTrackEvent("zoho_ticket_create", {
        clientId,
        description: payload.description,
      });
      formRef.current.resetForm();
      window.setTimeout(() => {
        onClickClose();
      }, 3000);
    } catch (error) {
      pendoTrackEvent("zoho_ticket_error", {
        clientId,
        description: payload.description,
        error: JSON.stringify(error?.err?.data?.exception?.error || {}),
      });
      setPostState({
        isLoading: false,
        postSuccess: false,
        errorMsg: error?.errorMsg,
      });
    }
  };

  const renderCTA = ({ isLoading, disabled, onClickSubmit }) => {
    return (
      <>
        <div className="footer-error-msg">{postState?.errorMsg}</div>
        <Button
          block
          isLoading={isLoading}
          disabled={isLoading || disabled}
          onClick={async (event) => {
            try {
              await onClickSubmit(event);
            } catch (err) {}
          }}
          className="cta-btn"
        >
          {ctaText}
        </Button>
      </>
    );
  };

  return (
    <div className="ticket-component-wrapper">
      <div className="header">
        <div className="title">{title}</div>
        <div className="closebtn icon icon icon-close1" onClick={onClickClose} />
      </div>
      <Form
        formRef={formRef}
        wrapperClassName="ticket-form-wrapper"
        initialValues={initialValues}
        formComponents={formComponents}
        isCTALoading={postState?.isLoading}
        ctaText={ctaText}
        onSubmit={onSubmit}
        renderCTA={renderCTA}
        componentToShow={componentToShow}
        componentConfig={componentConfig}
      />
      {postState?.postSuccess ? (
        <div className="ticket-success-overlay">
          <div className="ticket-success-overlay-inner">
            <div className="ticket-success-overlay-txt">
              Your query has been submitted.
              <br />
              We will get in touch with you shortly.
            </div>
            <div className="ticket-success-overlay-img" />
          </div>
        </div>
      ) : null}
    </div>
  );
};

const TicketComponentWrapper = configHoc(TicketComponent);

TicketComponentWrapper.defaultProps = {
  defaultConfigProps: {
    title: "Raise a Ticket",
    initialValues: {},
    descKeys: [],
    sellerTags: [],
    componentToShow: ["description", "fileUpload"],
    componentConfig: {
      type: {
        componentType: "Type",
        props: {
          labelColumns: 12,
          dropdownType: "default",
          dataKey: "type",
          placeholder: "Select Type",
          options: [],
        },
      },
      description: {
        componentType: "Textarea",
        props: {
          labelColumns: 12,
          placeholder: "Describe your query",
          dataKey: "description",
          guidText: "Minimum 30 characters",
          formGroupClassName: "inline-error-textarea",
          validations: [
            {
              type: "nonEmpty",
              msg: "Please enter __FIELD_TITLE__",
            },
            {
              type: "minLength",
              msg: "Minimum 30 characters",
              value: 30,
            },
          ],
        },
      },
      fileUpload: {
        componentType: "UploadFile",
        props: {
          dataKey: "fileUrl",
        },
      },
      screenshot: {
        componentType: "Screenshot",
        props: {
          dataKey: "screenshotUrl",
        },
      },
    },
    ctaText: "Submit",
  },
};

const mapStateToProps = (state) => {
  const { clientId, userInfo, shopInfo, marketplaceClientId, agencyId } = state.Application || {};
  return {
    location: state.router.location,
    marketplaceClientId,
    clientId,
    userInfo,
    shopInfo,
    agencyId,
  };
};

export default connect(mapStateToProps)(TicketComponentWrapper);
