import InputText from "./inputText";
import Dropdown from "./dropdown";
import DropdownCreatable from "./dropdownCreatable";
import DropdownVirtualized from "./dropdownVirtualized";
import DropdownAsync from "./dropdownAsync";
import Tab from "./tab";
import TagInput from "./tagInput";
import Radio from "./radio";
import Checkbox from "./checkbox";
import DatePicker from "./datePicker";
import Textarea from "./textarea";
import DateRange from "./dateRange";
// import LocationDropdown from "./locationDropdown";
import EmailList from "./emailList";
import DropdownWithCheckbox from "./dropdownWithCheckbox";
import RuleAction from "./ruleAction";
import RuleCondition from "./ruleCondition";
import BooleanCheckbox from "./booleanCheckbox";
import DropdownWithBelowPills from "./dropdownWithBelowPills";
import InputWithDataList from "./inputWithDatalist";
import TimePicker from "./timePicker";
import DropdownSubtitle from "./dropdownSubtitle";
import ToggleSwitch from "./ToggleSwitch";
import CheckboxToggle from "./checkboxToggle";
import RadioTabSelection from "./radioTabSelection";
import DropdownAsyncWithCheckbox from "./dropdownAsyncWithCheckbox";
import DropdownAsyncWithBelowPills from "./dropdownAsyncWithBelowPills";
import DropdownWithSearch from "./dropdownWithSearch";
import PasswordWithTick from "./passwordWithTick";
import Tracker from "./tracker";
import CopyClipboardValue from "./copyClipboardValue";
import UniversalDropdown from "./universalDropdown";
import DynamicMultiComponent from "./dynamicMultiComponent";
import FrequencyCap from "./frequencyCap";
import DayParting from "./dayParting";
import Screenshot from "./screenshot";
import EmailInput from "./emailInput";
import MonthPicker from "./monthPicker";
import InputWithDropdown from "./inputWithDropdown";
import MultiFormSubComponent from "./multiFormSubComponent";

// MediaUpload and MediaLibrary moved to media-library-v2 as MediaUploadFrom and MediaLibraryForm

export default {
  InputText,
  Dropdown,
  DropdownCreatable,
  Tab,
  TagInput,
  Radio,
  Checkbox,
  DatePicker,
  Textarea,
  DateRange,
  // LocationDropdown,
  DropdownVirtualized,
  DropdownWithCheckbox,
  DropdownAsyncWithCheckbox,
  DropdownAsync,
  DropdownWithBelowPills,
  EmailList,
  RuleAction,
  RuleCondition,
  BooleanCheckbox,
  InputWithDataList,
  TimePicker,
  DropdownSubtitle,
  ToggleSwitch,
  CheckboxToggle,
  RadioTabSelection,
  DropdownAsyncWithBelowPills,
  PasswordWithTick,
  Tracker,
  DropdownWithSearch,
  CopyClipboardValue,
  UniversalDropdown,
  DynamicMultiComponent,
  FrequencyCap,
  DayParting,
  Screenshot,
  EmailInput,
  MonthPicker,
  InputWithDropdown,
  MultiFormSubComponent,
};
