import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { CarouselContainer } from "@onlinesales-ai/carousel-container-v2";
import { Pill } from "@onlinesales-ai/pill-v2";
import AsyncImage from "@onlinesales-ai/async-image-v2";
import WithTooltip from "@onlinesales-ai/tooltip-v2";
import { EmptyMessage } from "@onlinesales-ai/message-card-v2";

import "./index.less";

const CreativeTemplateLibrary = ({ data, selectTemplate, prepareURLs }) => {
  const { t } = useTranslation();
  const [isSelectedId, setIsSelectedId] = useState(null);
  return (
    <div className="creative-template-container">
      {data?.length > 0 ? (
        <div className="creative-template-wrapper">
          {data.map((item) => {
            const { id, imgTemplates, sampleData, name, tags } = item;
            return (
              <div
                className={`item-card ${isSelectedId === id && "selected"}`}
                onClick={() => {
                  setIsSelectedId(id);
                  selectTemplate(item);
                }}
              >
                {/* <div className="template-select">
                    <span className="icon icon-check" />
                  </div> */}
                <div className="carousel-section">
                  <CarouselContainer
                    windowSize={1}
                    items={imgTemplates.map((imgTemplate) => {
                      return (
                        <AsyncImage
                          className="pdf-view"
                          imgSrc={prepareURLs(imgTemplate.URL, sampleData)}
                        />
                      );
                    })}
                  />
                </div>
                <div className="bottom-description">
                  {name && (
                    <div>
                      <div className="label">
                        <Trans>Title</Trans>
                      </div>
                      <WithTooltip title={name}>
                        <div className="value ellipsis-text">{name}</div>
                      </WithTooltip>
                    </div>
                  )}
                  {tags && (
                    <div>
                      <div className="label">
                        <Trans>Tags</Trans>
                      </div>
                      <div className="template-pills">
                        <Pill.List>
                          {tags.slice(0, 1).map((tag) => {
                            return (
                              <Pill small variant="grey">
                                {tag}
                              </Pill>
                            );
                          })}
                        </Pill.List>
                        {tags?.length > 1 && (
                          <WithTooltip title={tags.join(", ")}>
                            <div>
                              <Pill.List>
                                <Pill small variant="grey">
                                  {t("+ {{tagLength}} More", {
                                    tagLength: tags?.length - 1,
                                  })}
                                </Pill>
                              </Pill.List>
                            </div>
                          </WithTooltip>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <EmptyMessage>No Templates Available</EmptyMessage>
      )}
    </div>
  );
};

CreativeTemplateLibrary.defaultProps = {
  data: [],
};

export default CreativeTemplateLibrary;
