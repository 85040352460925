import _keyBy from "lodash/keyBy";
import _uniq from "lodash/uniq";
import _intersection from "lodash/intersection";
import _isEmpty from "lodash/isEmpty";
import _pick from "lodash/pick";

import { customMergeOS } from "@onlinesales-ai/util-methods-v2";
import Types from "./types";
import AppActionTypes from "../application/types";

const initialState = {
  isFetchInProgress: false,
  areAllGoalsFetched: false,
  isFetchSuccessful: false,
  isFirstCallInProgress: true,
  isFirstCallSuccessful: false,
  fetchErrorMsg: null,
  goalsV2Offset: 0,
  sortedGoalIds: [],
  goalDetails: {
    // "123": {
    //   "data": {},
    //   "skuSelectionData": {},
    //   "bidSettingData": {}
    // }
  },
  launcherId: null,
  campaignListForNotificationsOnly: {},
};

const getFirstActiveVendorChannel = (channelSettings) => {
  const channels = channelSettings?.filter((channelObj) => {
    return channelObj.status === "ACTIVE";
  });
  const goalVendor = channels?.[0]?.vendor;
  return goalVendor;
};

const findDuplicateSkus = ({ goalDetails, goalId }) => {
  const currentVendor = getFirstActiveVendorChannel(goalDetails?.[goalId]?.data?.channelSettings);

  let allSkus = Object.keys(goalDetails).reduce((memo, key) => {
    const vendorChannel = getFirstActiveVendorChannel(goalDetails?.[key]?.data?.channelSettings);
    if (
      key === goalId ||
      goalDetails?.[key]?.data?.status !== "ACTIVE" ||
      vendorChannel !== currentVendor
    ) {
      return memo;
    }
    const skuSelectionData = goalDetails?.[key]?.skuSelectionData;
    const skus = skuSelectionData?.filters?.[0]?.values?.map?.((v) => v.value) || [];
    return memo.concat(skus);
  }, []);

  const currentSKUSelectionData = goalDetails?.[goalId]?.skuSelectionData;
  const currentSkus = currentSKUSelectionData?.filters?.[0]?.values?.map?.((v) => v.value) || [];

  allSkus = _uniq(allSkus);

  const duplicateSkus = _intersection(allSkus, currentSkus);

  return {
    hasDuplicateError: goalDetails?.[goalId]?.data?.status === "ACTIVE" && duplicateSkus.length > 0,
    currentSkusLength: currentSkus.length,
    allSkus,
    allSkusLength: allSkus.length,
    duplicateSkus,
    duplicateSkusLength: duplicateSkus.length,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AppActionTypes.APP_CLIENT_CHANGE:
      {
        state = {
          ...initialState,
        };
      }
      break;
    case Types.SET_GOALS_V2_LIST_DATA:
      {
        const dataToSave = action.goals;

        Object.keys(state.goalDetails).forEach((id) => {
          dataToSave[id] = {
            ...(state.goalDetails?.[id] || {}),
            ...dataToSave[id],
          };
        });

        state = {
          ...state,
          goalDetails: dataToSave,
        };
      }
      break;
    case Types.UPDATE_GOALS_OFFSET_GOALS_V2:
      {
        state = {
          ...state,
          goalsV2Offset: action.offset,
        };
      }
      break;
    case Types.SET_GOALS_V2_SORTED_IDS:
      {
        state = {
          ...state,
          sortedGoalIds: [...state?.sortedGoalIds, ...action.sortedGoalIds],
        };
      }
      break;
    case Types.UPDATE_GOALS_V2_LIST_DATA:
      {
        const copyData = { ...state.goalDetails };

        action.goals.forEach((goal) => {
          copyData[goal.id] = {
            ...(state.goalDetails?.[goal.id] || {}),
            data: {
              ...(state.goalDetails?.[goal.id]?.data || {}),
              ...goal,
            },
          };
        });

        state = {
          ...state,
          goalDetails: copyData,
        };
      }
      break;
    case Types.MERGE_ALL_GOALS_DATA_PERFORMANCE:
      {
        const goalDetails = customMergeOS(action.goalsData, state.goalDetails);
        state = {
          ...state,
          goalDetails,
        };
      }
      break;
    case Types.SET_GOALS_V2_LIST_FETCH_IN_PROGRESS:
      state = {
        ...state,
        isFetchInProgress: action.isLoading,
      };
      break;
    case Types.SET_GOALS_V2_FETCH_SUCCESSFUL:
      state = {
        ...state,
        isFetchSuccessful: action.isSuccessful,
      };
      break;
    case Types.SET_GOALS_V2_FETCH_ERROR_MSG:
      state = {
        ...state,
        fetchErrorMsg: action.errorMsg,
      };
      break;
    case Types.SET_IS_FIRST_CALL_IN_PROGRESS_GOALS_V2:
      state = {
        ...state,
        isFirstCallInProgress: action.isFirstCallInProgress,
      };
      break;
    case Types.SET_IS_FIRST_CALL_SUCCESSFUL_GOALS_V2:
      state = {
        ...state,
        isFirstCallSuccessful: action.isFirstCallSuccessful,
      };
      break;
    case Types.MAKE_GOALV2_EMPTY:
      state = {
        ...state,
        goalDetails: {},
      };
      break;
    case Types.MAKE_GOALV2_SORTED_EMPTY:
      state = {
        ...state,
        sortedGoalIds: [],
      };
      break;
    case Types.SET_GOALS_V2_DATA:
      {
        state = {
          ...state,
          goalDetails: {
            ...state.goalDetails,
            [action.goalId]: {
              ...(state.goalDetails?.[action.goalId] || {}),
              data: {
                ...(state.goalDetails?.[action.goalId]?.data || {}),
                ...action.data,
              },
            },
          },
          campaignListForNotificationsOnly: {
            ...state.campaignListForNotificationsOnly,
            [action.goalId]: {
              ...(state.campaignListForNotificationsOnly?.[action.goalId] || {}),
              data: {
                ...(state.campaignListForNotificationsOnly?.[action.goalId]?.data || {}),
                ..._pick(action?.data, ["id", "name", "alias", "status", "budget", "effectiveStatus"]),
              },
            },
          },
        };
      }
      break;

    case Types.SET_GOALS_V2_BID_SETTING_DATA:
      {
        state = {
          ...state,
          goalDetails: {
            ...state.goalDetails,
            [action.goalId]: {
              ...(state.goalDetails?.[action.goalId] || {}),
              bidSettingData: action.data,
            },
          },
        };
      }
      break;
    case Types.SET_GOALS_V2_KEYWORD_BIDDING_DATA:
      {
        state = {
          ...state,
          goalDetails: {
            ...state.goalDetails,
            [action.goalId]: {
              ...(state.goalDetails?.[action.goalId] || {}),
              keywordBiddingData: {
                ...(state.goalDetails?.[action.goalId]?.keywordBiddingData || {}),
                [action.keywordType]: _keyBy(action.data?.keywords || [], "id"),
              },
            },
          },
        };
      }
      break;
    case Types.SET_GOALS_V2_KEYWORD_BIDDING_FETCH_STATUS:
      {
        state = {
          ...state,
          goalDetails: {
            ...state.goalDetails,
            [action.goalId]: {
              ...(state.goalDetails?.[action.goalId] || {}),
              isKeywordBiddingFetchInProgress: action.isFetchInProgress,
            },
          },
        };
      }
      break;
    case Types.UPDATE_GOALS_V2_KEYWORD_BIDDING_DATA:
      {
        const dataToUpdate = {
          [action.keywordType]: {
            ...(state.goalDetails?.[action.goalId]?.keywordBiddingData?.[action.keywordType] || {}),
            ..._keyBy(action.data?.keywords || [], "id"),
          },
        };
        let keywordCount = Object.values(dataToUpdate[action.keywordType] || {})?.length;
        keywordCount = Object.values(dataToUpdate[action.keywordType] || {})?.filter(
          ({ status }) => status === "ACTIVE",
        )?.length;
        const keywordsCount = {
          ...(state.goalDetails?.[action.goalId]?.keywordBiddingData?.keywordsCount || {}),
          [action.keywordCountKey]: keywordCount,
        };
        state = {
          ...state,
          goalDetails: {
            ...state.goalDetails,
            [action.goalId]: {
              ...(state.goalDetails?.[action.goalId] || {}),
              keywordBiddingData: {
                ...(state.goalDetails?.[action.goalId]?.keywordBiddingData || {}),
                ...dataToUpdate,
                keywordsCount,
              },
            },
          },
        };
      }
      break;
    case Types.SET_GOALS_V2_KEYWORDS_COUNT:
      {
        state = {
          ...state,
          goalDetails: {
            ...state.goalDetails,
            [action.goalId]: {
              ...(state.goalDetails?.[action.goalId] || {}),
              keywordBiddingData: {
                ...(state.goalDetails?.[action.goalId]?.keywordBiddingData || {}),
                keywordsCount: action.data,
              },
            },
          },
        };
      }
      break;

    case Types.SET_GOALS_V2_NEGATIVE_KEYWORDS_COUNT:
      {
        state = {
          ...state,
          goalDetails: {
            ...state.goalDetails,
            [action.goalId]: {
              ...(state.goalDetails?.[action.goalId] || {}),
              keywordBiddingData: {
                ...(state.goalDetails?.[action.goalId]?.keywordBiddingData || {}),
                keywordsCount: {
                  ...(state.goalDetails?.[action.goalId]?.keywordBiddingData?.keywordsCount || {}),
                  negativeKeywordCount: action.data?.negativeKeywordCount,
                },
              },
            },
          },
        };
      }
      break;

    case Types.UPDATE_GOALS_V2_BID_SETTING_DATA:
      {
        const newBidSettingData = {
          ...(state.goalDetails?.[action.goalId]?.bidSettingData || {}),
        };

        newBidSettingData.data = newBidSettingData.data.map((data) => {
          const dataToUpdate = action.data.find((d) => d.categoryValue === data.categoryValue);
          return dataToUpdate || data;
        });

        state = {
          ...state,
          goalDetails: {
            ...state.goalDetails,
            [action.goalId]: {
              ...(state.goalDetails?.[action.goalId] || {}),
              bidSettingData: newBidSettingData,
            },
          },
        };
      }
      break;

    case Types.SET_GOALS_V2_SKU_SELECTION_DATA:
      {
        state = {
          ...state,
          goalDetails: {
            ...state.goalDetails,
            [action.goalId]: {
              ...(state.goalDetails?.[action.goalId] || {}),
              skuSelectionData: action.data,
            },
          },
        };
      }
      break;

    case Types.SET_GOALS_V2_SKU_SELECTION_FETCH:
      {
        state = {
          ...state,
          goalDetails: {
            ...state.goalDetails,
            [action.goalId]: {
              ...(state.goalDetails?.[action.goalId] || {}),
              isSKUSelectionFetchInProgress: action.isLoading,
            },
          },
        };
      }
      break;

    case Types.SET_CAMPAIGN_TARGETING_DATA:
      {
        state = {
          ...state,
          goalDetails: {
            ...state.goalDetails,
            [action.goalId]: {
              ...(state.goalDetails?.[action.goalId] || {}),
              geoDetails: action?.data?.geoDetails?.response || [],
              audienceDetails: action?.data?.audienceDetails?.response || [],
            },
          },
        };
      }
      break;

    case Types.SET_CAMPAIGN_GEO_TARGETING_DATA:
      {
        let isActionGeoSettingPresentInStore = false;
        const geoDetails = state?.goalDetails?.[action.goalId].geoDetails || [];

        const updatedGeoDetails = geoDetails.map((setting) => {
          if (setting?._id === action?.data?._id) {
            setting = action.data || {};
            isActionGeoSettingPresentInStore = true;
          }
          return setting;
        });

        if (!isActionGeoSettingPresentInStore) {
          updatedGeoDetails.push(action.data || {});
        }

        {
          state = {
            ...state,
            goalDetails: {
              ...state.goalDetails,
              [action.goalId]: {
                ...(state.goalDetails?.[action.goalId] || {}),
                geoDetails: updatedGeoDetails,
              },
            },
          };
        }
      }
      break;

    case Types.SET_CAMPAIGN_AUDIENCE_TARGETING_DATA:
      {
        let isActionAudienceSettingPresentInStore = false;
        const audienceDetails = state?.goalDetails?.[action.goalId].audienceDetails || [];

        const updatedAudienceDetails = audienceDetails.map((setting) => {
          if (setting?._id === action?.data?._id) {
            setting = action.data || {};
            isActionAudienceSettingPresentInStore = true;
          }
          return setting;
        });

        if (!isActionAudienceSettingPresentInStore) {
          updatedAudienceDetails.push(action.data || {});
        }

        {
          state = {
            ...state,
            goalDetails: {
              ...state.goalDetails,
              [action.goalId]: {
                ...(state.goalDetails?.[action.goalId] || {}),
                audienceDetails: updatedAudienceDetails,
              },
            },
          };
        }
      }
      break;

    case Types.SET_GOALS_V2_TOTAL_SKU_DATA:
      state = {
        ...state,
        totalSkuCount: action.data?.totalCount,
      };
      break;

    case Types.UPDATE_GOALS_V2_SKU_SELECTION_DATA:
      {
        state = {
          ...state,
          goalDetails: {
            ...state.goalDetails,
            [action.goalId]: {
              ...(state.goalDetails?.[action.goalId] || {}),
              skuSelectionData: {
                ...(state.goalDetails?.[action.goalId]?.skuSelectionData || {}),
                ...action.data,
              },
            },
          },
        };
      }
      break;

    case Types.SET_GOALS_V2_LAUNCHER_ID:
      {
        state = {
          ...state,
          launcherId: action.goalId,
          sortedGoalIds: [action.goalId, ...(state.sortedGoalIds || [])],
        };
      }
      break;
    case Types.SET_GOAL_V2_CREATIVES_TEMPLATES_DATA:
      {
        state = {
          ...state,
          goalDetails: {
            ...state.goalDetails,
            [action?.data?.goalId]: {
              ...(state.goalDetails?.[action?.data?.goalId] || {}),
              adTemplatesData: action?.data?.creativeTemplatesData,
            },
          },
        };
      }
      break;
    case Types.UPDATE_REPORTING_DATA_FOR_X_DAYS_V2:
      {
        state = {
          ...state,
          reportingDataForXDays: {
            ...state.reportingDataForXDays,
            [action.key]: {
              ...(state.reportingDataForXDays[action.key] || {}),
              ...action.data,
            },
          },
        };
      }
      break;
    case Types.SET_GOAL_V2_AD_CREATION_TEMPLATES_DATA:
      {
        const { adTypeWiseTemplateIds: newAdTypeWiseTemplateIds } = action.data;
        delete action?.data?.adTypeWiseTemplateIds;

        const updatedAdTypeIds = {
          ...(state.goalDetails?.[action?.data?.goalId]?.adTypeWiseTemplateIds || {}),
        };

        Object.keys(newAdTypeWiseTemplateIds || {}).forEach((adType) => {
          if (updatedAdTypeIds[adType]) {
            newAdTypeWiseTemplateIds[adType].forEach((id) => {
              if (!updatedAdTypeIds[adType].includes(id)) {
                updatedAdTypeIds[adType].unshift(id);
              }
            });
          } else {
            updatedAdTypeIds[adType] = newAdTypeWiseTemplateIds[adType];
          }
        });
        state = {
          ...state,
          goalDetails: {
            ...state.goalDetails,
            [action?.data?.goalId]: {
              ...(state.goalDetails?.[action?.data?.goalId] || {}),
              adCreationTemplatesData: {
                ...(state.goalDetails?.[action?.data?.goalId]?.adCreationTemplatesData || {}),
                ...action?.data,
                templateIds: [
                  ...(state.goalDetails?.[action?.data?.goalId]?.adCreationTemplatesData
                    ?.templateIds || []),
                  ...action?.data?.templateIds,
                ],
              },
              adTypeWiseTemplateIds: updatedAdTypeIds,
            },
          },
        };
      }
      break;
    case Types.UPDATE_GOAL_V2_AD_CREATION_TEMPLATES_DATA:
      {
        const { adTypeWiseTemplateIds: newAdTypeWiseTemplateIds } = action.data;
        delete action?.data?.adTypeWiseTemplateIds;

        const updatedAdTypeIds = {
          ...(state.goalDetails?.[action?.data?.goalId]?.adTypeWiseTemplateIds || {}),
        };

        Object.keys(newAdTypeWiseTemplateIds || {}).forEach((adType) => {
          if (updatedAdTypeIds[adType]) {
            newAdTypeWiseTemplateIds[adType].forEach((id) => {
              if (!updatedAdTypeIds[adType].includes(id)) {
                updatedAdTypeIds[adType].unshift(id);
              }
            });
          } else {
            updatedAdTypeIds[adType] = newAdTypeWiseTemplateIds[adType];
          }
        });

        state = {
          ...state,
          goalDetails: {
            ...state.goalDetails,
            [action?.data?.goalId]: {
              ...(state.goalDetails?.[action?.data?.goalId] || {}),
              adCreationTemplatesData: {
                ...(state.goalDetails?.[action?.data?.goalId]?.adCreationTemplatesData || {}),
                ...action?.data,
                templateIds: [
                  ...(state.goalDetails?.[action?.data?.goalId]?.adCreationTemplatesData
                    ?.templateIds || []),
                  ...action?.data?.templateIds,
                ],
              },
              adTypeWiseTemplateIds: updatedAdTypeIds,
            },
          },
        };
      }
      break;
    case Types.SET_FUNNEL_METRICS_DATA_FOR_WB:
      state = {
        ...state,
        funnelMetricsForWalletBalance: action.data,
      };
      break;
    default:
      break;
  }

  switch (action.type) {
    case Types.SET_GOALS_V2_LIST_DATA:
    case Types.UPDATE_GOALS_V2_LIST_DATA:
    case Types.MERGE_ALL_GOALS_DATA_PERFORMANCE:
    case Types.SET_GOALS_V2_DATA:
    case Types.SET_GOALS_V2_SKU_SELECTION_DATA:
    case Types.UPDATE_GOALS_V2_SKU_SELECTION_DATA:
      {
        if (
          action?.computeDuplicateSku &&
          Object.values(state?.goalDetails || {}).every(
            (g) => g.data.status === "DRAFT" || !_isEmpty(g.skuSelectionData),
          )
        ) {
          Object.keys(state.goalDetails).forEach((id) => {
            state = {
              ...state,
              goalDetails: {
                ...state.goalDetails,
                [id]: {
                  ...(state.goalDetails?.[id] || {}),
                  duplicateSkuData: findDuplicateSkus({
                    goalDetails: state.goalDetails,
                    goalId: id,
                  }),
                },
              },
            };
          });
        }
      }
      break;
    case Types.SET_IS_FIRST_CAMPAIGN_LAUNCHED:
      {
        state = {
          ...state,
          isFistCampaignLaunched: action.isFistCampaignLaunched,
        };
      }
      break;
    case Types.SET_SELECTED_NETWORK_LIST:
      {
        state = {
          ...state,
          goalDetails: {
            ...state.goalDetails,
            [action.goalId]: {
              ...(state.goalDetails?.[action.goalId] || {}),
              selectedNetworkList: {
                ...action?.list,
              },
            },
          },
        };
      }
      break;
    case Types.PERFORMANCE_GOAL_LIST_NOTIFICATION:
      {
        state = {
          ...state,
          campaignListForNotificationsOnly: action?.campaigns || {},
        };
      }
      break;
    default:
      break;
  }

  return state;
};

export default {
  GoalsV2: reducer,
};
