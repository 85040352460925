import React, { useState } from "react";

import WithTooltip from "@onlinesales-ai/tooltip-v2";
import { Button } from "@onlinesales-ai/button-v2";
import { ModalWindow } from "@onlinesales-ai/modal-window-v2";

import "./index.less";

const CallButton = ({ title, isMobile, phoneNumberToCall, modalHeader }) => {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const onClickCall = () => {
    if (isMobile) {
      window.open(`tel:${phoneNumberToCall}`);
    } else {
      toggleModal();
    }
  };

  return (
    <>
      <WithTooltip title={title}>
        <Button icon="icon-phone" rounded onClick={onClickCall} />
      </WithTooltip>
      <ModalWindow
        isShow={showModal}
        onModalDestroy={toggleModal}
        containerClass="app-level-call-button-modal"
      >
        <ModalWindow.Header closeButton>
          {modalHeader}
        </ModalWindow.Header>
        <ModalWindow.Body>
          <span className="phone-number">{phoneNumberToCall}</span>
        </ModalWindow.Body>
      </ModalWindow>
    </>
  );
};

CallButton.defaultProps = {
  title: "Call Us",
  modalHeader: "Call Us",
};

export default CallButton;
