import React from "react";

import { FormWrapper } from "@onlinesales-ai/form-components-v2";
import { getCurrencyDOM, formatNumberInAbbreviations } from "@onlinesales-ai/util-methods-v2";

import WalletBalance from "../../../walletBalance";
import WalletBalanceV2 from "../../../walletBalanceV2";

import "./index.less";

const FormWalletBalance = ({
  title,
  labelColumns,
  titleTooltip,
  titleGuidText,
  formGroupClassName,
  formatInThousands,
  v1 = true,
  guidText,
  alwaysShowGuide,
  formGroupInnerClassName,
  ...rest
}) => {
  return (
    <FormWrapper
      title={title}
      formGroupClassName={`purge-ignore-wallet-balance-form-component ${formGroupClassName || ""}`}
      titleTooltip={titleTooltip}
      titleGuidText={titleGuidText}
      labelColumns={labelColumns}
      guidText={guidText}
      alwaysShowGuide={alwaysShowGuide}
      formGroupInnerClassName={formGroupInnerClassName}
    >
      {
        v1 ? (
          <WalletBalance
            showWalletBalance={false}
            formatInThousands={formatInThousands}>
            {({ formattedAccountBalance, isLoading, getCurrencyDOM }) => {
              if (isLoading) {
                return <div className="animated-bg loading" />;
              }

              return (
                <span className="purge-ignore-wallet-balance-drawer-count">
                  {getCurrencyDOM()}
                  {formattedAccountBalance}
                </span>
              );
            }}
          </WalletBalance>
        ) : (
          <WalletBalanceV2
            formatInThousands={formatInThousands}
            {...rest}
            >
              {({ balance, isLoading }) => {
              if (isLoading) {
                return <div className="animated-bg loading" />;
              }

              return (
                <div className="purge-ignore-wallet-balance-wrapper-inner d-align-center">
                  <div className="purge-ignore-wallet-balance-text d-align-center">
                    <span className="purge-ignore-wallet-balance-drawer-count amount">
                      {getCurrencyDOM()}
                      {balance}
                    </span>
                  </div>
                </div>
              );
            }}
          </WalletBalanceV2>
        )
      }
    </FormWrapper>
  );
};

FormWalletBalance.defaultProps = {
  title: "Wallet Balance:",
};

export default FormWalletBalance;
