import { callToActionTypes } from "./application";

export const components = {
  SHOPS_INFO: "ShopsInfoWrapper",
  BILLING: "Billing",
  ACCOUNT_LINKING: "LinkAccountsWrapper",
  MERCHANDISE_CONNECT: "MerchandiseConnect",
  SEED_PLACEMENT: "PixelPlacement",
  MERCHANDISE_HEADER_MAPPER: "MerchandisehHeaderMapper",
  BUSINESS_CARD: "BusinessCardWrapper",
  CHANNEL_MANAGEMENT: "ChannelManagement",
  ACL: "Acl",
  INFORMATION_LIST: "InformationList",
  PIXEL_ACCESS_CHECK_DRAWER: "PixelAccessCheckDrawer",
  CATALOGUE_PIXEL_HYGINE_STEPS: "catalogPixelHygieneSteps",
  DRM_PREFLIGHT: "DrmPreflight",
  SUGGESTIONS_COUNT_CHART: "SuggestionsCountChart",
  PIXEL_INFORMATION_LIST: "PixelInformationList",
  PRODUCT_FEED_QUALITY_CHART: "ProductFeedQualityChart",
  GOALS_LAUNCHER: "GoalsLauncher",
  SELLER_DETAILS: "SellerDetails",
  HELP_THE_AI: "HELP_THE_AI",
};

export const definitions = {
  CHECK_LOW_WALLET_BALANCE: {
    iconImageUrl: "https://res.cloudinary.com/onlinesales/image/upload/v1638883297/product/pendingaction/Walled-balance-exhausted.gif",
    imgTitle: "Top up your",
    imgSubTitle: "Wallet",
    title: "Low Wallet Balance",
    description: "Your wallet balance will last only for __NO_OF_DAYS__ days. Please top-up your wallet to keep your ads running.",
    refreshCheckList: [],
    callToActionData: {
      // text: "Fix it",
      // type: callToActionTypes.REDIRECT_TO_URL,
      // link: "__APPEND__/drawer/POST_OB_GOAL_LAUNCHER?defaultCardToOpen=SKU_SELECTION&goalId=\"__GOAL_ID__\"&isEditMode=true",
    },
  },
  CHECK_EXHAUSTED_WALLET_BALANCE: {
    iconImageUrl: "https://res.cloudinary.com/onlinesales/image/upload/v1638883297/product/pendingaction/Walled-balance-exhausted.gif",
    imgTitle: "Top up your",
    imgSubTitle: "Wallet",
    title: "Wallet balance exhausted",
    description: "Your wallet balance has exhausted. Please top-up your wallet to keep your ads running.",
    refreshCheckList: [],
    callToActionData: {
      // text: "Fix it",
      // type: callToActionTypes.REDIRECT_TO_URL,
      // link: "__APPEND__/drawer/POST_OB_GOAL_LAUNCHER?defaultCardToOpen=SKU_SELECTION&goalId=\"__GOAL_ID__\"&isEditMode=true",
    },
  },
  LOW_BALANCE_REDIRECT: {
    iconImageUrl: "https://res.cloudinary.com/onlinesales/image/upload/v1638883297/product/pendingaction/Walled-balance-exhausted.gif",
    imgTitle: "Top up your",
    imgSubTitle: "Wallet",
    title: "Low Wallet Balance",
    description: "",
    refreshCheckList: [],
    callToActionData: {
      text: "Top Up",
      type: callToActionTypes.REDIRECT_TO_URL,
      link: "__APPEND__/drawer/TOP_UP_WITH_TRANSACTION",
      className: "pendo_feature_hygiene_low_wallet_balance_cta",
    },
    messageMap: {
      inFewDays: "Your wallet balance will last only for __NO_OF_DAYS__ days. Please top-up your wallet to keep your ads running.",
      today: "Your wallet balance is exhausted. Please top-up your wallet to keep your ads running.",
    },
  },
  PLA_WALLET_BALANCE_EXPIRED_IN_FEW_DAYS: {
    iconImageUrl:
      "https://res.cloudinary.com/onlinesales/image/upload/v1654252288/product/pendingaction/Impression-loss-due-to-Budget.gif",
    imgTitle: "Top up your",
    imgSubTitle: "Wallet",
    title: "Low Wallet Balance!",
    description: "Your wallet balance will exhaust soon. Please top-up your wallet to keep your ads running.",
    refreshCheckList: [],
    callToActionData: {
      text: "Recharge Wallet",
      type: callToActionTypes.REDIRECT_TO_URL,
      link: "__APPEND__/drawer/TOP_UP_WITH_TRANSACTION?defaultTopUpAmount=__ESTIMATE_WALLET_",
      className: "pendo_feature_track-pending-action-low-wallet-balance",
    },
  },
  PLA_WALLET_BALANCE_EXPIRED_TODAY: {
    iconImageUrl:
      "https://res.cloudinary.com/onlinesales/image/upload/v1654252288/product/pendingaction/Impression-loss-due-to-Budget.gif",
    imgTitle: "Top up your",
    imgSubTitle: "Wallet",
    title: "Wallet Exhausted!",
    description: "Your ads are stopped due to insufficient wallet balance. Please top up to activate the ads again.",
    refreshCheckList: [],
    callToActionData: {
      text: "Recharge Wallet",
      type: callToActionTypes.REDIRECT_TO_URL,
      link: "__APPEND__/drawer/TOP_UP_WITH_TRANSACTION?defaultTopUpAmount=__ESTIMATE_WALLET_",
      className: "pendo_feature_track-pending-action-wallet-balance-exhausted",
    },
  },
  SDA_WALLET_BALANCE_EXPIRED_IN_FEW_DAYS: {
    iconImageUrl:
      "https://res.cloudinary.com/onlinesales/image/upload/v1654252288/product/pendingaction/Impression-loss-due-to-Budget.gif",
    imgTitle: "Top up your",
    imgSubTitle: "Wallet",
    title: "Low Wallet Balance!",
    description: "Your wallet balance will exhaust soon. Please top-up your wallet to keep your ads running.",
    refreshCheckList: [],
    callToActionData: {
      text: "Recharge Wallet",
      type: callToActionTypes.REDIRECT_TO_URL,
      link: "__APPEND__/drawer/TOP_UP_WITH_TRANSACTION?defaultTopUpAmount=__ESTIMATE_WALLET_",
      className: "pendo_feature_track-pending-action-low-wallet-balance",
    },
  },
  SDA_WALLET_BALANCE_EXPIRED_TODAY: {
    iconImageUrl:
      "https://res.cloudinary.com/onlinesales/image/upload/v1654252288/product/pendingaction/Impression-loss-due-to-Budget.gif",
    imgTitle: "Top up your",
    imgSubTitle: "Wallet",
    title: "Wallet Exhausted!",
    description: "Your ads are stopped due to insufficient wallet balance. Please top up to activate the ads again.",
    refreshCheckList: [],
    callToActionData: {
      text: "Recharge Wallet",
      type: callToActionTypes.REDIRECT_TO_URL,
      link: "__APPEND__/drawer/TOP_UP_WITH_TRANSACTION?defaultTopUpAmount=__ESTIMATE_WALLET_",
      className: "pendo_feature_track-pending-action-wallet-balance-exhausted",
    },
  },
  SPA_FINANCE_WALLET_BALANCE_EXPIRED_TODAY: {
    iconImageUrl:
      "https://res.cloudinary.com/onlinesales/image/upload/v1654252288/product/pendingaction/Impression-loss-due-to-Budget.gif",
    imgTitle: "Top up your",
    imgSubTitle: "Wallet",
    title: "Wallet Exhausted!",
    description: "Your ads are stopped due to insufficient wallet balance. Please top up to activate the ads again.",
    refreshCheckList: [],
    callToActionData: {
      text: "Recharge Wallet",
      type: callToActionTypes.REDIRECT_TO_URL,
      link: "__APPEND__/drawer/MANAGE_FINANCE?defaultCampaignType=SPA",
      className: "pendo_feature_track-pending-action-wallet-balance-exhausted",
    },
  },
  SPA_FINANCE_WALLET_BALANCE_EXPIRED_IN_FEW_DAYS: {
    iconImageUrl:
      "https://res.cloudinary.com/onlinesales/image/upload/v1654252288/product/pendingaction/Impression-loss-due-to-Budget.gif",
    imgTitle: "Top up your",
    imgSubTitle: "Wallet",
    title: "Low Wallet Balance!",
    description: "Your wallet balance will exhaust soon. Please top-up your wallet to keep your ads running.",
    refreshCheckList: [],
    callToActionData: {
      text: "Recharge Wallet",
      type: callToActionTypes.REDIRECT_TO_URL,
      link: "__APPEND__/drawer/MANAGE_FINANCE?defaultCampaignType=SDA",
      className: "pendo_feature_track-pending-action-low-wallet-balance",
    },
  },
  SDA_FINANCE_WALLET_BALANCE_EXPIRED_TODAY: {
    iconImageUrl:
      "https://res.cloudinary.com/onlinesales/image/upload/v1654252288/product/pendingaction/Impression-loss-due-to-Budget.gif",
    imgTitle: "Top up your",
    imgSubTitle: "Wallet",
    title: "Wallet Exhausted!",
    description: "Your ads are stopped due to insufficient wallet balance. Please top up to activate the ads again.",
    refreshCheckList: [],
    callToActionData: {
      text: "Recharge Wallet",
      type: callToActionTypes.REDIRECT_TO_URL,
      link: "__APPEND__/drawer/MANAGE_FINANCE?defaultCampaignType=SPA",
      className: "pendo_feature_track-pending-action-wallet-balance-exhausted",
    },
  },
  SDA_FINANCE_WALLET_BALANCE_EXPIRED_IN_FEW_DAYS: {
    iconImageUrl:
      "https://res.cloudinary.com/onlinesales/image/upload/v1654252288/product/pendingaction/Impression-loss-due-to-Budget.gif",
    imgTitle: "Top up your",
    imgSubTitle: "Wallet",
    title: "Low Wallet Balance!",
    description: "Your wallet balance will exhaust soon. Please top-up your wallet to keep your ads running.",
    refreshCheckList: [],
    callToActionData: {
      text: "Recharge Wallet",
      type: callToActionTypes.REDIRECT_TO_URL,
      link: "__APPEND__/drawer/MANAGE_FINANCE?defaultCampaignType=SDA",
      className: "pendo_feature_track-pending-action-low-wallet-balance",
    },
  },
  MAX_SPEND_CAP_REACHED: {
    iconImageUrl: "https://res.cloudinary.com/onlinesales/image/upload/v1652878794/product/pendingaction/Spend-Cap-paused.gif",
    imgTitle: "",
    imgSubTitle: "Increase Spend Limit",
    title: "Campaign Spend Limit Reached!",
    description: "",
    refreshCheckList: [],
    callToActionData: {
      text: "Increase Spend Limit",
      type: callToActionTypes.REDIRECT_TO_URL,
      link: "__APPEND__/drawer/GOAL_LAUNCHER/PERFORMANCE___GOAL_SUB_TYPE__?defaultCardToOpen=GOAL_SETTINGS&goalId=__GOAL_ID__&isEditMode=true",
      className: "pendo_feature_track-pending-action-campaign-paused-due-to-max-cap-reached",
    },
    messageMap: {
      today: `Your campaign <bold>__CAMPAIGN__</bold> has been paused due to Maximum Spend Cap. Please increase your spend cap to activate your campaign or launch a new campaign.`,
    },
  },
  CREATIVE_REJECTED_BY_ADMIN: {
    iconImageUrl: "https://res.cloudinary.com/onlinesales/image/upload/v1653284178/product/pendingaction/Creative-Rejected.gif",
    imgTitle: "",
    imgSubTitle: "Review Creative",
    title: "Creative Rejected",
    description: "Your creative for campaign <bold>__GOAL_NAME__</bold> has been rejected. Make the necessary changes in the creatives by referring to the admin's comment and resubmit your Ads for approval.",
    refreshCheckList: [],
    callToActionData: {
      text: "View Creative",
      type: callToActionTypes.REDIRECT_TO_URL,
      link: "__APPEND__/drawer/GOAL_LAUNCHER/INVENTORY_GAURANTEED?defaultCardToOpen=CREATIVE_SELECTION&isEditMode=true&goalId=__GOAL_ID__",
    },
  },
  CREATIVE_REJECTED_BY_ADMIN_AUCTION: {
    iconImageUrl: "https://res.cloudinary.com/onlinesales/image/upload/v1653284178/product/pendingaction/Creative-Rejected.gif",
    imgTitle: "",
    imgSubTitle: "Review Creative",
    title: "Creative Rejected",
    description: "Your creative for campaign <bold>__GOAL_NAME__</bold> has been rejected. Make the necessary changes in the creatives by referring to the admin's comment and resubmit your Ads for approval.",
    refreshCheckList: [],
    callToActionData: {
      text: "View Creative",
      type: callToActionTypes.REDIRECT_TO_URL,
      link: "__APPEND__/drawer/GOAL_LAUNCHER/INVENTORY_AUCTION?defaultCardToOpen=CREATIVE_SELECTION&isEditMode=true&goalId=__GOAL_ID__",
    },
  },
  CHECK_GOAL_PRODUCT_SELECTION_COUNT: {
    iconImageUrl: "https://res.cloudinary.com/onlinesales/image/upload/v1638968985/product/pendingaction/Campaign-Product-Selection.gif",
    imgTitle: "Select more",
    imgSubTitle: "Products",
    title: "Campaign Product Selection",
    description: "You have only __SKU_COUNT__ products selected out of __TOTAL_SKU_COUNT__ for \"__GOAL_NAME__\" campaign. Please select __MINIMUM_RANGE_TO_SELECT_PRODUCT__ or more for better results.",
    refreshCheckList: [],
    callToActionData: {
      text: "Fix it",
      type: callToActionTypes.REDIRECT_TO_URL,
      link: "__APPEND__/drawer/GOAL_LAUNCHER/PERFORMANCE___CAMPAIGN_SUB_TYPE__?defaultCardToOpen=GOAL_SETTINGS&defaultSettingsToOpen=SKU_SELECTION&goalId=\"__GOAL_ID__\"&isEditMode=true",
    },
  },
  CHECK_OLD_GOAL_PRODUCT_SELECTION_COUNT: {
    iconImageUrl: "https://res.cloudinary.com/onlinesales/image/upload/v1638968985/product/pendingaction/Campaign-Product-Selection.gif",
    imgTitle: "Select more",
    imgSubTitle: "Products",
    title: "Campaign Product Selection",
    description: "You have only __SKU_COUNT__ products selected out of __TOTAL_SKU_COUNT__ for \"__GOAL_NAME__\" campaign. Please select __MINIMUM_RANGE_TO_SELECT_PRODUCT__ or more for better results.",
    refreshCheckList: [],
    callToActionData: {
      text: "Fix it",
      type: callToActionTypes.REDIRECT_TO_URL,
      link: "__APPEND__/drawer/POST_OB_GOAL_LAUNCHER?defaultCardToOpen=SKU_SELECTION&goalId=\"__GOAL_ID__\"&isEditMode=true",
    },
  },
  CHECK_IF_CAMPIGN_ACTIVATED: {
    iconImageUrl: "https://res.cloudinary.com/onlinesales/image/upload/v1638883297/product/pendingaction/Activate-Campaigns.gif",
    imgTitle: "Activate your",
    imgSubTitle: "Campaign",
    title: "Campaign Activation",
    description: "Please activate campaign \"__GOAL_NAME__\" to start running your ads and grow your business.",
    refreshCheckList: [],
    callToActionData: {
      text: "Activate it",
      type: callToActionTypes.REDIRECT_TO_URL,
      link: "__APPEND__/drawer/POST_OB_GOAL_LAUNCHER?defaultCardToOpen=\"__DEFAULT_CARD__\"&goalId=\"__GOAL_ID__\"&isNewLaunch=true&isCampaignActive=false",
      className: "pendo_feature_hygiene_campaign_is_activated_cta",
    },
  },
  CHECK_DUPLICATE_SKUS: {
    iconImageUrl: "https://res.cloudinary.com/onlinesales/image/upload/v1654866018/product/pendingaction/same-sku-new.gif",
    imgTitle: "Optimize your",
    imgSubTitle: "Campaign",
    title: "Duplicate Car Model Alert",
    description: "The car model selected in \"__GOAL_NAME__\" already exists in other active campaigns. Please update selected car models to optimize your campaigns.",
    refreshCheckList: [],
    callToActionData: {
      text: "Fix It",
      type: callToActionTypes.REDIRECT_TO_URL,
      link: "__APPEND__/drawer/POST_OB_GOAL_LAUNCHER?defaultCardToOpen=SKU_SELECTION&goalId=\"__GOAL_ID__\"&isEditMode=true",
      className: "pendo_feature_hygiene_check_duplicate_skus_cta",
    },
  },
  CHECK_LEAD_RATED_COUNT: {
    iconImageUrl: "https://res.cloudinary.com/onlinesales/image/upload/v1638968985/product/pendingaction/Lead-status-update.gif",
    imgTitle: "Update your",
    imgSubTitle: "Leads",
    title: "Lead status updates",
    description: "Only __RATED_LEAD_PERCENTAGE__% of your leads have status updated. Please update the lead status to optimize your campaigns even further",
    refreshCheckList: [],
    callToActionData: {
      text: "Do it now",
      type: callToActionTypes.REDIRECT_TO_URL,
      link: "/lms",
      className: "pendo_feature_hygiene_lead_rated_count_cta",
    },
  },
  CHECK_DPA_FEED_UPLOAD_REFRESH: {
    title: "Feed Refresh Frequency For DPA",
    refreshCheckList: ["CHECK_DPA_FEED_UPLOAD_REFRESH", "CHECK_PLA_FEED_UPLOAD_REFRESH"],
    severity: {
      WARNING: {
        description: "Feed sync is not refreshed in last __HOURS__ hours",
      },
      ERROR: {
        description: "Feed sync is not refreshed in more than __HOURS__ hours",
      },
      NONE: {
        description: "Feed sync is refreshed at  __TIMESTAMP__ ",
      },
    },
    callToActionData: {
      text: "Fix it",
      // //helpText : "Learn More",
      type: callToActionTypes.OPEN_DRAWER,
      metadata: components.MERCHANDISE_CONNECT,
    },
  },
  CHECK_PLA_FEED_UPLOAD_REFRESH: {
    title: "Feed Refresh Frequency For PLA",
    refreshCheckList: ["CHECK_DPA_FEED_UPLOAD_REFRESH", "CHECK_PLA_FEED_UPLOAD_REFRESH"],
    severity: {
      WARNING: {
        description: "Feed sync is not refreshed in last __HOURS__ hours",
      },
      ERROR: {
        description: "Feed sync is not refreshed in more than __HOURS__ hours",
      },
      NONE: {
        description: "Feed sync is refreshed at  __TIMESTAMP__ ",
      },
    },
    callToActionData: {
      text: "Fix it",
      type: callToActionTypes.OPEN_DRAWER,
      metadata: components.MERCHANDISE_CONNECT,
    },
  },
  CHECK_MERCHANDISE_FEED_UPLOAD_REFRESH: {
    title: "Feed Refresh Frequency For Merchandise",
    refreshCheckList: ["CHECK_MERCHANDISE_FEED_UPLOAD_REFRESH", "CHECK_MERCHANDISE_CONNECT"],
    severity: {
      WARNING: {
        description: "Feed sync is not refreshed in last __HOURS__ hours",
      },
      ERROR: {
        description: "Feed sync is not refreshed in more than __HOURS__ hours",
      },
      NONE: {
        description: "Feed sync is refreshed at  __TIMESTAMP__ ",
      },
    },
    callToActionData: {
      text: "Fix it",
      type: callToActionTypes.OPEN_DRAWER,
      metadata: components.MERCHANDISE_CONNECT,
    },
  },
  PRODUCT_FEED_QUALITY: {
    title: "Feed Quality",
    description: "Score:__PERCENTAGE__%",
    // callToActionData: {
    //     text: "Learn More",
    //     type: callToActionTypes.OPEN_DRAWER,
    //     metadata: components.SEED_PLACEMENT,

    // }
  },
  CHECK_GOOGLE_GMC_FEED_APPROVAL: {
    title: "Google Merchant Feed Approval Rates",
    severity: {
      WARNING: {
        description: "",
      },
      ERROR: {
        description: "",
      },
      NONE: {
        description: "Product Feed has been approved for Google Merchant. ",
      },
    },
    messageMap: {
      criticalIssuesProductsCount:
        "GMC Products Count having critical Issue : __CRITICAL_ISSUES_PROD_COUNT__. ",
      warningIssuesProductsCount:
        "Products Count having warning Issue : __WARN_ISSUES_PROD_COUNT__.  ",
      isMultiSourcesOfFeed:
        "GMC Feed is getting uploaded from multiple sources. Remove all scheduled syncs to avoid feed override issue. ",
      isWebsiteClaimed: "Website Claim is pending for connected GMC account. ",
    },
    callToActionData: {
      text: "Learn More",
      //helpText: "Learn More",
      type: callToActionTypes.OPEN_DRAWER,
      metadata: components.INFORMATION_LIST,
    },
  },
  CHECK_FACEBOOK_CATALOG_FEED_APPROVAL: {
    title: "Facebook Catalog Feed Approval Rates",
    severity: {
      WARNING: {
        description: "",
      },
      ERROR: {
        description: "",
      },
      NONE: {
        description: "Product Feed has been approved for Facebook Catalog. ",
      },
    },
    messageMap: {
      isProductSyncDone: "Facebook Catalog Sync is not started yet. ",
      isProductDisapproved: "Facebook Catalog Feed Approval Rate is 0%. ",
      isMultiSourcesOfFeed:
        "Facebook Catalog Feed is getting uploaded from mulitple sources. Remove all scheduled syncs to avoid feed override issue. ",
      percentageErrorRate: "__PERCENTAGE_ERROR_RATE__% products are not satisfying quality. ",
    },
    callToActionData: {
      text: "Learn More",
      //helpText: "Learn More",
      type: callToActionTypes.OPEN_DRAWER,
      metadata: components.INFORMATION_LIST,
    },
  },
  CHECK_MERCHANDISE_CONNECT: {
    title: "Merchandise Connect",
    refreshCheckList: ["CHECK_MERCHANDISE_FEED_UPLOAD_REFRESH", "CHECK_MERCHANDISE_CONNECT"],
    severity: {
      WARNING: {
        description: "Product Feed has not been linked yet or is missing",
      },
      ERROR: {
        description: "Product Feed has not been linked yet or is missing",
      },
      NONE: {
        description: "Product Feed has been linked",
      },
    },
    callToActionData: {
      text: "Fix it",
      type: callToActionTypes.OPEN_DRAWER,
      metadata: components.MERCHANDISE_CONNECT,
    },
  },
  CHECK_PRODUCT_FEED_QUALITY: {
    title: "Product Feed Quality",
    description: "Overall Coverage : __OVERALL_SCORE__%",
    callToActionData: {
      text: "Learn More",
      type: callToActionTypes.OPEN_DRAWER,
      metadata: components.PRODUCT_FEED_QUALITY_CHART,
    },
  },
  CHECK_CATALOG_PIXEL_HYGIENE: {
    title: "Catalog Pixel Hygiene",
    severity: {
      ERROR: {
        description: "",
      },
      NONE: {
        description: "",
      },
    },
    messageMap: {
      isEventNotReceived: "Event data sources are not setup for your catalog",
      isEventReceived:
        "Pixel is successfully attached with your Product Catalog. Product ids matching with your Product Catalog - __TOTAL_MATCHED_CONTENT_IDS__",
    },
    callToActionData: {
      text: "Learn More",
      type: callToActionTypes.OPEN_DRAWER,
      metadata: components.CATALOGUE_PIXEL_HYGINE_STEPS,
    },
  },
  PRICE_BENCHMARKS: {
    title: "Price Benchmarks",
    description: "Avg Product Price is __PERCENTAGE__ % higher than the industry average",
    // callToActionData: {
    //     text: "Learn More",
    //     type: callToActionTypes.OPEN_DRAWER,
    //     metadata: components.SEED_PLACEMENT
    // }
  },
  DISCOUNT_BENCHMARKS: {
    title: "Discounting Benchmarks",
    description:
      "Avg Discount is __CLIENT_PERCENTAGE__ %vs __INDUSTRY_PERCENTAGE__ % for the industry",
    // callToActionData: {
    //     text: "Learn More",
    //     type: callToActionTypes.OPEN_DRAWER,
    //     metadata: components.SEED_PLACEMENT
    // }
  },
  SITE_FUNNEL_BENCHMARKS: {
    title: "Site Funnel Benchmarks",
    description:
      "__CLIENT_PERCENTAGE__% Shoppers Convert vs __INDUSTRY_PERCENTAGE__% for the industry",
    keysToBeReplaced: ["__CLIENT_PERCENTAGE__", "__INDUSTRY_PERCENTAGE__"],
    // callToActionData: {
    //     text: "Learn More",
    //     type: callToActionTypes.OPEN_DRAWER,
    //     metadata: components.SEED_PLACEMENT
    // }
  },
  GOAL_REALISTICNESS: {
    title: "Goal Realisticness",
    description: "<__COUNT__> Goals have un-realistic/conservative/realistic Objectives set",
    callToActionData: {
      text: "Fix it",
      type: callToActionTypes.REDIRECT_TO_URL,
      link: "https://apps.onlinesales.ai/goalsManager",
      target: "_blank",
    },
  },
  SUGGESTION_ACCEPT_PERCENTAGE: {
    title: "Suggestions Accept Percentage",
    description: "__PERCENTAGE__% of the suggestions are actioned in last 7 days",
    callToActionData: {
      text: "Checkout Suggestions",
      type: callToActionTypes.OPEN_DRAWER,
      metadata: components.SUGGESTIONS_COUNT_CHART,
    },
  },
  GOAL_MANUAL_CHANGES: {
    title: "Manual Changes",
    description: "__PERCENTAGE__% of changes are being done manually",
    callToActionData: {
      text: "Fix it",
      // helpText: "Learn More",
      type: callToActionTypes.REDIRECT_TO_URL,
      link: "https://apps.onlinesales.ai/goalsManager",
      target: "_blank",
    },
  },
  CHECK_OS_PIXEL_HYGIENE: {
    title: "Check OS Pixel",
    description: "OS Pixel Coverage: __OVERALL_SCORE__% ",
    callToActionData: {
      text: "Learn More",
      type: callToActionTypes.OPEN_DRAWER,
      metadata: components.PIXEL_INFORMATION_LIST,
    },
  },
  OS_PIXEL_HYGIENE: {
    title: "OS Pixel",
    description: "Score: __PERCENTAGE__%",
    // callToActionData: {
    //     text: "Fix it",
    //     type: callToActionTypes.OPEN_DRAWER,
    //     metadata: components.SEED_PLACEMENT,
    // }
  },
  CONVERSION_TRACKING_HYGIENE: {
    title: "Conversion Tracking",
    description: "vendor level Status -__PERCENTAGE__%",
    // callToActionData: {
    //     text: "Fix it",
    //     helpText: "Need Tech Help?",
    //     type: callToActionTypes.OPEN_DRAWER,
    //     metadata: components.SEED_PLACEMENT,

    // }
  },
  AUDIENCE_BEHAVIOUR_TRACKING_HYGIENE: {
    title: "Audience + Behavior Tracking",
    description: "Score:__PERCENTAGE__%",
    // callToActionData: {
    //     text: "Fix it",
    //     helpText: "Need Tech Help?",
    //     type: callToActionTypes.OPEN_DRAWER,
    //     metadata: components.SEED_PLACEMENT,

    // }
  },
  CHECK_ADD_TO_CARTS_DROP: {
    title: "Add to Cart Rates",
    description: "",
    messageMap: {
      isAddToCartsDropped: "Add To Carts rate dropped by __PERCENTAGE__%",
      isAddToCartsIncreased: "Add To Carts rate increased by __PERCENTAGE__%",
      isDataAvailable:
        "Last week add to cart count: __LAST_WEEK_DATA__, Second last week add to cart count: __PREVIOUS_WEEK_DATA__",
    },
    callToActionData: {
      text: "Contact Support",
      type: callToActionTypes.OPEN_INTERCOM,
      metadata: "My Add to Carts Rates have dropped. Kindly help me!",
    },
  },
  CHECK_CONVERSIONS_DROP: {
    title: "Conversion Rates",
    description: "",
    messageMap: {
      isCoversionsDropped: "Conversion rate dropped by __PERCENTAGE__%",
      isCoversionsIncreased: "Conversion rate increased by __PERCENTAGE__%",
      isDataAvailable:
        "Last week conversions count: __LAST_WEEK_DATA__, Second last week conversions count: __PREVIOUS_WEEK_DATA__",
    },
    callToActionData: {
      text: "Contact Support",
      type: callToActionTypes.OPEN_INTERCOM,
      metadata: "My Conversion Rates have dropped. Kindly help me!",
    },
  },
  CHECK_CHUKNU_VERIFIED: {
    title: "Pixel Placement",
    severity: {
      WARNING: {
        description: "Performance tracking pixel has not been placed or verified yet",
      },
      ERROR: {
        description: "Performance tracking pixel has not been placed or verified yet",
      },
      NONE: {
        description: "Performance tracking pixel has been placed",
      },
    },
    callToActionData: {
      text: "Contact Support",
      type: callToActionTypes.OPEN_INTERCOM,
      metadata: "My Pixel Placement is not done. Kindly help me!",
    },
  },
  CHECK_FACEBOOK_ACCOUNT_ACCESS: {
    refreshCheckList: [
      "CHECK_FACEBOOK_ACCOUNT_ACCESS",
      "CHECK_FACEBOOK_ACCOUNT",
      "CHECK_FACEBOOK_CATALOG_ACCESS",
      "CHECK_GOOGLE_ADWORDS_ACCESS",
      "CHECK_ADWORDS_ACCOUNT",
      "CHECK_GOOGLE_MERCHANT_ACCESS",
      "CHECK_FACEBOOK_PAGE_ID",
      "CHECK_INSTAGRAM_ACCOUNT",
      "CHECK_FACEBOOK_FUNDING_SOURCE_CHECK",
      "FACEBOOK_ACCOUNT_HYGIENE",
    ],
    severity: {
      WARNING: {
        description:
          "Our access to your Facebook Account has been revoked, please relink your account.",
      },
      ERROR: {
        description:
          "Our access to your Facebook Account has been revoked, please relink your account.",
      },
      NONE: {
        description: "We have access to Facebook Accounts",
      },
    },
    title: " Facebook Account Access",
    callToActionData: {
      text: "Fix it",
      type: callToActionTypes.OPEN_DRAWER,
      metadata: components.ACCOUNT_LINKING,
    },
  },

  CHECK_GMB_ACCOUNT: {
    refreshCheckList: [],
    severity: {
      WARNING: {
        description: "You have not linked your GMB Account",
      },
      ERROR: {
        description: "You have not linked your GMB Account",
      },
      NONE: {
        description: "We have access to GMB Accounts",
      },
    },
    title: "Google My Business Linking",
    callToActionData: {
      text: "Link Now",
      type: callToActionTypes.OPEN_DRAWER,
      metadata: components.ACCOUNT_LINKING,
    },
  },
  CHECK_FACEBOOK_ACCOUNT: {
    refreshCheckList: [
      "CHECK_FACEBOOK_ACCOUNT_ACCESS",
      "CHECK_FACEBOOK_ACCOUNT",
      "CHECK_FACEBOOK_CATALOG_ACCESS",
      "CHECK_GOOGLE_ADWORDS_ACCESS",
      "CHECK_ADWORDS_ACCOUNT",
      "CHECK_GOOGLE_MERCHANT_ACCESS",
      "CHECK_FACEBOOK_PAGE_ID",
      "CHECK_INSTAGRAM_ACCOUNT",
      "CHECK_FACEBOOK_FUNDING_SOURCE_CHECK",
      "FACEBOOK_ACCOUNT_HYGIENE",
    ],
    severity: {
      WARNING: {
        description: "You have not linked your Facebook Account",
      },
      ERROR: {
        description: "You have not linked your Facebook Account",
      },
      NONE: {
        description: "We have access to Facebook Accounts",
      },
    },
    title: "Facebook Account Linking",
    callToActionData: {
      text: "Fix it",
      type: callToActionTypes.OPEN_DRAWER,
      metadata: components.ACCOUNT_LINKING,
    },
  },
  CHECK_FACEBOOK_PIXEL_ACCESS: {
    severity: {
      WARNING: {
        description: "We are missing access to Facebook Pixel",
      },
      ERROR: {
        description: "We are missing access to Facebook Pixel",
      },
      NONE: {
        description: "We have access to Facebook Pixel",
      },
    },
    title: "Facebook Pixel Account Access",
    callToActionData: {
      text: "Learn More",
      type: callToActionTypes.OPEN_DRAWER,
      metadata: components.PIXEL_ACCESS_CHECK_DRAWER,
    },
  },
  CHECK_FACEBOOK_CATALOG_ACCESS: {
    refreshCheckList: [
      "CHECK_FACEBOOK_ACCOUNT_ACCESS",
      "CHECK_FACEBOOK_ACCOUNT",
      "CHECK_FACEBOOK_CATALOG_ACCESS",
      "CHECK_GOOGLE_ADWORDS_ACCESS",
      "CHECK_ADWORDS_ACCOUNT",
      "CHECK_GOOGLE_MERCHANT_ACCESS",
      "CHECK_FACEBOOK_PAGE_ID",
      "CHECK_INSTAGRAM_ACCOUNT",
      "CHECK_FACEBOOK_FUNDING_SOURCE_CHECK",
      "FACEBOOK_ACCOUNT_HYGIENE",
    ],
    severity: {
      WARNING: {
        description: "We are missing access to Facebook Catalog",
      },
      ERROR: {
        description: "We are missing access to Facebook Catalog",
      },
      NONE: {
        description: "We have access to Facebook Catalog",
      },
    },
    title: "Facebook Catalog Account Access",
    callToActionData: {
      text: "Fix it",
      type: callToActionTypes.OPEN_DRAWER,
      metadata: components.ACCOUNT_LINKING,
    },
  },
  PHONE_NUMBER_VERIFICATION_CHECK_FOR_GOOGLE: {
    severity: {
      WARNING: {
        description: "Your Ads has been disapproved by Google due to unverified phone number, Please make sure your phone number is present on your weblink.",
      },
      NONE: {
        description: "",
      },
    },
    title: "Google Ads Phone Number Verification",
  },
  CHECK_ADWORDS_ACCOUNT: {
    refreshCheckList: [
      "CHECK_FACEBOOK_ACCOUNT_ACCESS",
      "CHECK_FACEBOOK_ACCOUNT",
      "CHECK_FACEBOOK_CATALOG_ACCESS",
      "CHECK_GOOGLE_ADWORDS_ACCESS",
      "CHECK_ADWORDS_ACCOUNT",
      "CHECK_GOOGLE_MERCHANT_ACCESS",
      "CHECK_FACEBOOK_PAGE_ID",
      "CHECK_INSTAGRAM_ACCOUNT",
    ],
    severity: {
      WARNING: {
        description: "You have not linked your Google Adwords Account",
      },
      ERROR: {
        description: "You have not linked your Google Adwords Account",
      },
      NONE: {
        description: "We have access to Google Adwords",
      },
    },
    title: "Google Adwords Account Linking",
    callToActionData: {
      text: "Fix it",
      type: callToActionTypes.OPEN_DRAWER,
      metadata: components.ACCOUNT_LINKING,
    },
  },
  CHECK_GOOGLE_ADWORDS_ACCESS: {
    refreshCheckList: [
      "CHECK_FACEBOOK_ACCOUNT_ACCESS",
      "CHECK_FACEBOOK_ACCOUNT",
      "CHECK_FACEBOOK_CATALOG_ACCESS",
      "CHECK_GOOGLE_ADWORDS_ACCESS",
      "CHECK_ADWORDS_ACCOUNT",
      "CHECK_GOOGLE_MERCHANT_ACCESS",
      "CHECK_FACEBOOK_PAGE_ID",
      "CHECK_INSTAGRAM_ACCOUNT",
    ],
    severity: {
      WARNING: {
        description:
          "Our access to your Google Adwords Account has been revoked, please relink your account.",
      },
      ERROR: {
        description:
          "Our access to your Google Adwords Account has been revoked, please relink your account.",
      },
      NONE: {
        description: "We have access to Google Adwords",
      },
    },
    title: "Google Adwords Account Access",
    callToActionData: {
      text: "Fix it",
      type: callToActionTypes.OPEN_DRAWER,
      metadata: components.ACCOUNT_LINKING,
    },
  },
  CHECK_GOOGLE_MERCHANT_ACCESS: {
    refreshCheckList: [
      "CHECK_FACEBOOK_ACCOUNT_ACCESS",
      "CHECK_FACEBOOK_ACCOUNT",
      "CHECK_FACEBOOK_CATALOG_ACCESS",
      "CHECK_GOOGLE_ADWORDS_ACCESS",
      "CHECK_ADWORDS_ACCOUNT",
      "CHECK_GOOGLE_MERCHANT_ACCESS",
      "CHECK_FACEBOOK_PAGE_ID",
      "CHECK_INSTAGRAM_ACCOUNT",
    ],
    severity: {
      WARNING: {
        description:
          "Our access to your Google Merchant Account has been revoked, please relink your account.",
      },
      ERROR: {
        description:
          "Our access to your Google Merchant Account has been revoked, please relink your account.",
      },
      NONE: {
        description: "We have access to Google Merchant",
      },
    },
    title: "Google Merchant Account Access",
    callToActionData: {
      text: "Fix it",
      type: callToActionTypes.OPEN_DRAWER,
      metadata: components.ACCOUNT_LINKING,
    },
  },
  CHECK_GOOGLE_ACCOUNT_HYGIENE: {
    title: "Google Budget Order Setup",
    messageMap: {
      isBudgetExhausted:
        "Your campaigns on google are paused due to low budget order (__CURRENCY__ __AMOUNT__).",
      isBudgetOrderNotPresent: "Your campaigns on google are not live due to no budget order.",
      isBudgetRemaining:
        "Campaigns on google are all set with respect to budget order (Amount remaining: __CURRENCY__ __AMOUNT__).",
      isBudgetInfinite: "Campaigns on google are all set with respect to budget order.",
      isUserAccessDenied:
        "We are unable to access the required information. Kindly re-link your Google Ads account with complete admin access.",
    },
  },
  CHECK_FACEBOOK_PAGE_ID: {
    refreshCheckList: [
      "CHECK_FACEBOOK_ACCOUNT_ACCESS",
      "CHECK_FACEBOOK_ACCOUNT",
      "CHECK_FACEBOOK_CATALOG_ACCESS",
      "CHECK_GOOGLE_ADWORDS_ACCESS",
      "CHECK_ADWORDS_ACCOUNT",
      "CHECK_GOOGLE_MERCHANT_ACCESS",
      "CHECK_FACEBOOK_PAGE_ID",
      "CHECK_INSTAGRAM_ACCOUNT",
      "CHECK_FACEBOOK_FUNDING_SOURCE_CHECK",
      "FACEBOOK_ACCOUNT_HYGIENE",
    ],
    severity: {
      NONE: {
        description: "We have access to Facebook Page",
      },
    },
    title: "Facebook Page Access",
    callToActionData: {
      text: "Link Now",
      type: callToActionTypes.OPEN_DRAWER,
      metadata: components.ACCOUNT_LINKING,
    },
    messageMap: {
      isPageLinked: "Your Facebook page is not linked. ",
      isPagePublished: "Your Facebook page is not yet published. ",
      accessOnPage: "Your user does not have access for the linked Facebook page. ",
    },
  },
  CHECK_INSTAGRAM_ACCOUNT: {
    refreshCheckList: [
      "CHECK_FACEBOOK_ACCOUNT_ACCESS",
      "CHECK_FACEBOOK_ACCOUNT",
      "CHECK_FACEBOOK_CATALOG_ACCESS",
      "CHECK_GOOGLE_ADWORDS_ACCESS",
      "CHECK_ADWORDS_ACCOUNT",
      "CHECK_GOOGLE_MERCHANT_ACCESS",
      "CHECK_FACEBOOK_PAGE_ID",
      "CHECK_INSTAGRAM_ACCOUNT",
      "CHECK_FACEBOOK_FUNDING_SOURCE_CHECK",
      "FACEBOOK_ACCOUNT_HYGIENE",
    ],
    title: "Instagram Access",
    severity: {
      NONE: {
        description: "We have access to Instagram Account",
      },
    },
    callToActionData: {
      text: "Fix it",
      type: callToActionTypes.OPEN_DRAWER,
      metadata: components.ACCOUNT_LINKING,
    },
    messageMap: {
      isInstaLinked: "Your Instagram account is not linked. ",
      isInstaLinkedToFb:
        "The Instagram account linked to OnlineSales.ai is not connected with the linked Facebook Ad account. ",
    },
  },
  FACEBOOK_ACCOUNT_HYGIENE: {
    refreshCheckList: [
      "CHECK_FACEBOOK_ACCOUNT_ACCESS",
      "CHECK_FACEBOOK_ACCOUNT",
      "CHECK_FACEBOOK_CATALOG_ACCESS",
      "CHECK_FACEBOOK_FUNDING_SOURCE_CHECK",
    ],
    title: "Facebook Terms Of Service",
    severity: {
      NONE: {
        description: "Your Facebook Account is setup correctly",
      },
    },
    messageMap: {
      isTosAccepted: "Terms of Service for your Facebook Ad account are not accepted.",
    },
    callToActionData: {
      text: "Fix it",
      type: callToActionTypes.REDIRECT_TO_URL,
      target: "_blank",
    },
  },
  CHECK_FACEBOOK_LEAD_ADS_TOC: {
    refreshCheckList: ["CHECK_GOAL_CREATED"],
    title: "Facebook Lead Adverts Terms",
    severity: {
      NONE: {
        description: "Your Facebook Account is setup correctly",
      },
    },
    messageMap: {
      isTosAccepted: "Terms and Condition is not accepted for Lead Adverts Terms.",
    },
    callToActionData: {
      text: "Fix it",
      type: callToActionTypes.REDIRECT_TO_URL,
      target: "_blank",
    },
  },
  CHECK_FACEBOOK_FUNDING_SOURCE_CHECK: {
    refreshCheckList: [
      "CHECK_FACEBOOK_ACCOUNT_ACCESS",
      "CHECK_FACEBOOK_ACCOUNT",
      "CHECK_FACEBOOK_CATALOG_ACCESS",
      "FACEBOOK_ACCOUNT_HYGIENE",
    ],
    title: "Facebook Account Funding Setup",
    severity: {
      NONE: {
        description: "Your Facebook Account Funding is setup correctly",
      },
    },
    messageMap: {
      isFundingSourceAttached: "Payment method for your Facebook Ad account is not set up.",
      spendingLimitReached: "Facebook Ads Account spending limit reached.",
      paymentIssue: "Facebook Ads Account Payment is unsettled.",
    },
  },
  CHECK_REACH_ESTIMATE: {
    refreshCheckList: ["CHECK_REACH_ESTIMATE"],
    title: "Low Audience Reach",
    severity: {
      ERROR: {
        description:
          "Low estimated reach of targeted geographies, edit targeted locations and radius.",
      },
    },
    callToActionData: {
      text: "Fix it",
      type: callToActionTypes.OPEN_DRAWER,
      metadata: components.SELLER_DETAILS,
    },
  },

  // CHECK_BUSINESS_DATA: {
  //     title: "Business Data Completion",
  //     callToActionData: {
  //         text: "Fix it",
  //         type: callToActionTypes.OPEN_DRAWER,
  //         metadata: components.BUSINESS_CARD
  //     },
  //     severity: {
  //         "ERROR" :{
  //             description: "Some business data parameters needed for Ad Creation are not filled out by the client",
  //         },
  //         "NONE" :{
  //             description: "All Business data parameters needed for Ad Creation are filled out by the client",
  //         }
  //     },
  // },
  NUMBER_OF_USERS_HYGIENE: {
    title: "Number of Users with access to OS",
    description: "__COUNT__ users have access to onlineSales.ai",
    keysToBeReplaced: ["__COUNT__"],
    callToActionData: {
      text: "Add Users",
      type: callToActionTypes.OPEN_DRAWER,
      metadata: components.ACL,
    },
  },
  KARMA_POINTS_EARNED: {
    title: "Karma Points earned",
    description: "__POINTS__ Points/__BADGES__ Badges",
    // callToActionData: {
    //     text: "Earn Rewards",
    //     type: callToActionTypes.OPEN_DRAWER,
    //     metadata: components.SEED_PLACEMENT
    // }
  },
  CHECK_HELP_THE_AI_CONFIG: {
    title: "Help The AI",
    description: "Help our AI serve ads with more personalized targeting that suits your business.",
    callToActionData: {
      text: "Fix it",
      type: callToActionTypes.OPEN_DRAWER,
      inputParams: ["notAvailableCountries"],
      metadata: components.HELP_THE_AI,
    },
    severity: {
      WARNING: {
        callToActionData: {},
        description:
          "None of your Campaigns are active. You need to activate your Campaigns in order to optimise AI according to your Help The AI data",
      },
      ERROR: {
        description:
          "Help our AI serve ads with more personalized targeting that suits your business.",
      },
    },
  },
  BILLING_HYGIENE: {
    refreshCheckList: ["BILLING_HYGIENE", "DO_MORE_WITH_OS"],
    title: "Billing Hygiene",
    description: "__CURRENCY____AMOUNT__ is Over Due",
    keysToBeReplaced: ["__AMOUNT__", "__CURRENCY__"], //Keep __CURRENCY__ in the last
    callToActionData: {
      text: "Fix it",
      type: callToActionTypes.OPEN_DRAWER,
      metadata: components.BILLING,
    },
  },
  DO_MORE_WITH_OS: {
    refreshCheckList: ["BILLING_HYGIENE", "DO_MORE_WITH_OS"],
    title: "Do More with OS",
    description: "__APP_COUNT__/__TOTAL_APP_COUNT__  Apps being used",
    callToActionData: {
      text: "Fix it",
      type: callToActionTypes.OPEN_DRAWER,
      metadata: components.BILLING,
    },
  },
  CAMPAIGN_PAUSED_DATE_NEAR_DUE_TO_MAX_CAP_REACHED: {
    iconImageUrl: "https://res.cloudinary.com/onlinesales/image/upload/v1652878799/product/pendingaction/Spend-Cap-about-to-end.gif",
    imgTitle: "",
    imgSubTitle: "Increase Spend Limit",
    title: "Campaign Spend Limit Alert",
    description: "",
    refreshCheckList: [],
    callToActionData: {
      text: "Increase Spend Limit",
      type: callToActionTypes.REDIRECT_TO_URL,
      link: "__APPEND__/drawer/GOAL_LAUNCHER/PERFORMANCE___CAMPAIGN_SUB_TYPE__?defaultCardToOpen=GOAL_SETTINGS&goalId=__GOAL_ID__&isEditMode=true",
      className: "pendo_feature_track-pending-action-campaign-to-be-paused-due-to-max-cap",
    },
    messageMap: {
      inFewDays: `Your campaign <bold>__GOAL_NAME__</bold> has reached 80% of maximum spend limit of <bold> <currency /> __MAX_SPEND_CAP__</bold> and will be paused soon. Please increase the max spend limit or remove the limit so that the campaign is not paused which may impact the performance.`,
    },
  },
  CHECK_DRM_MARKETING_IMAGES: {
    title: "Marketing Image Status",
    severity: {
      WARNING: {
        description:
          "Marketing images not provided which are required for Google Display Responsive Ads",
      },
      ERROR: {
        description:
          "Marketing images not provided which are required for Google Display Responsive Ads",
      },
      NONE: {
        description:
          "Marketing images are provided which are required for Google Display Responsive Ads",
      },
    },
    callToActionData: {
      text: "Fix it",
      type: callToActionTypes.OPEN_DRAWER,
      metadata: components.DRM_PREFLIGHT,
      updateText: "Update",
    },
  },
  CREATIVE_SCHEDULED_IS_TWO_DAYS_AWAY_CREATIVES_ARE_MISSING: {
    iconImageUrl: "https://res.cloudinary.com/onlinesales/image/upload/v1653284174/product/pendingaction/Creative-Missing_1.gif",
    imgTitle: "Activate your",
    imgSubTitle: "Campaign",
    title: "Creative Missing",
    description: `Your campaign <bold>__GOAL_NAME__</bold> schedule is <bold> 2 days </bold> away and creative(s) is/are missing`,
    refreshCheckList: [],
    callToActionData: {
      text: "View Creative",
      type: callToActionTypes.REDIRECT_TO_URL,
      link: "__APPEND__/drawer/GOAL_LAUNCHER/INVENTORY_GAURANTEED?defaultCardToOpen=CREATIVE_SELECTION&isEditMode=true&goalId=__GOAL_ID__",
    },
  },
  CREATIVE_SCHEDULED_IS_TWO_DAYS_AWAY_CREATIVES_ARE_MISSING_AUCTION: {
    iconImageUrl: "https://res.cloudinary.com/onlinesales/image/upload/v1653284174/product/pendingaction/Creative-Missing_1.gif",
    imgTitle: "Activate your",
    imgSubTitle: "Campaign",
    title: "Creative Missing",
    description: `Your campaign <bold>__GOAL_NAME__</bold> schedule is <bold> 2 days </bold> away and creative(s) is/are missing`,
    refreshCheckList: [],
    callToActionData: {
      text: "View Creative",
      type: callToActionTypes.REDIRECT_TO_URL,
      link: "__APPEND__/drawer/GOAL_LAUNCHER/INVENTORY_AUCTION?defaultCardToOpen=CREATIVE_SELECTION&isEditMode=true&goalId=__GOAL_ID__",
    },
  },
  CREATIVE_SCHEDULED_IS_TWO_DAYS_AWAY_PAYMENT_PENDING: {
    iconImageUrl: "https://res.cloudinary.com/onlinesales/image/upload/v1653284183/product/pendingaction/payment-missing.gif",
    imgTitle: "Activate your",
    imgSubTitle: "Campaign",
    title: "Campaign Activation",
    description: `Your campaign <bold>__GOAL_NAME__</bold> schedule is <bold> 2 days </bold> away and payment is pending. Please make payment to activate your campaign.`,
    refreshCheckList: [],
    callToActionData: {
      text: "Pay and Activate Campaign",
      type: callToActionTypes.REDIRECT_TO_URL,
      link: "__APPEND__/drawer/GOAL_LAUNCHER/INVENTORY_GAURANTEED?defaultCardToOpen=CREATIVE_SELECTION&isEditMode=true&goalId=__GOAL_ID__",
    },
  },
  NO_PERFORMANCE_CAMPAIGN_FOUND: {
    iconImageUrl: "https://res.cloudinary.com/onlinesales/image/upload/v1656492047/product/monetize/Create-Campaign.gif",
    imgTitle__NOT_USED__: "Add",
    imgSubTitle: "Create Campaign",
    title: "Create your First Campaign",
    description: `You have not created any campaigns. Create your first campaign now to start running ads to attract more shoppers.`,
    refreshCheckList: [],
    callToActionData: {
      text: "Create Campaign",
      updateText: "Create Campaign",
      type: callToActionTypes.REDIRECT_TO_URL,
      link: "__APPEND__/drawer/GOAL_LAUNCHER/PERFORMANCE_SMART_SHOPPING?defaultCardToOpen=GOAL_SETTINGS&isNewLaunch=true&isCampaignActive=false",
    },
  },
  NO_PERFORMANCE_CAMPAIGN_IS_ACTIVE: {
    iconImageUrl: "https://res.cloudinary.com/onlinesales/image/upload/v1656492047/product/monetize/Activate-Campaign.gif",
    imgTitle: "Activate your",
    imgSubTitle: "Campaign",
    title: "No Active Campaigns",
    description: `None of your campaigns are active on the platform. Activate your previous campaigns or create a new campaign now to avoid any effect on your performance.`,
    refreshCheckList: [],
    callToActionData: {
      text: "Activate Campaign",
      type: callToActionTypes.PLATFORM_EVENT,
      event: "OPEN_VIEW_ALL_CAMPAIGN_DRAWER",
    },
  },
  CAMPAIGN_ACTIVE_END_DATE_REACHED: {
    iconImageUrl: "https://res.cloudinary.com/onlinesales/image/upload/v1661409491/product/pendingaction/End-date-reached.gif",
    imgTitle: "",
    imgSubTitle: "Campaign End Date Reached",
    title: "Campaign paused after reaching the end date!",
    description: `Your campaign <bold>__GOAL_NAME__</bold> is now paused since it has reached its end date. Extend the end date to continue running ads for this campaign or create a new campaign.`,
    refreshCheckList: [],
    callToActionData: {
      text: "Change End Date",
      type: callToActionTypes.REDIRECT_TO_URL,
      link: "__APPEND__/drawer/GOAL_LAUNCHER/INVENTORY_AUCTION?defaultCardToOpen=GOAL_DEFINITION&goalId=__GOAL_ID__&isEditMode=true",
    },
  },
  CAMPAIGN_ACTIVE_MAX_CAP_REACHED: {
    iconImageUrl: "https://res.cloudinary.com/onlinesales/image/upload/v1652878794/product/pendingaction/Spend-Cap-paused.gif",
    imgTitle: "",
    imgSubTitle: "Increase Spend Limit",
    title: "Campaign Spend Limit Reached!",
    description: `Your campaign <bold>__GOAL_NAME__</bold> has been paused due to Maximum Spend Cap. Please increase your spend cap to activate your campaign or launch a new campaign.`,
    refreshCheckList: [],
    callToActionData: {
      text: "Increase Spend Limit",
      type: callToActionTypes.REDIRECT_TO_URL,
      link: "__APPEND__/drawer/GOAL_LAUNCHER/INVENTORY_AUCTION?defaultCardToOpen=GOAL_DEFINITION&goalId=__GOAL_ID__&isEditMode=true",
    },
  },
  CAMPAIGN_DEBUGGER_REPORT_GENERATED: {
    iconImageUrl: "https://res.cloudinary.com/onlinesales/image/upload/v1712573252/product/pendingaction/troubleshoot.gif",
    imgTitle: "Troubleshoot Report",
    imgSubTitle: "",
    title: "Troubleshoot Report Available for Analysis",
    description: "Report Details for \"__GOAL_NAME__\"",
    refreshCheckList: [],
    callToActionData: {
      text: "View Report",
      type: callToActionTypes.REDIRECT_TO_URL,
      link: "__APPEND__/drawer/CAMPAIGN_TROUBLESHOOT?fr=false&goalId=__GOAL_ID__&clientId=__CLIENT_ID__",
    },
  },
};

export const PendingOpportunityList = ["CHECK_FACEBOOK_CATALOG_FEED_APPROVAL"];

export const hygieneTypes = {
  PENDING_OPPORTUNITIES: "pending_opportunities",
  HYGIENE: "HYGIENE",
};

export const feedKeysForFacebook = {
  summary: {
    displayName: "Summary",
  },
  total_count: {
    displayName: "Count",
  },
  description: {
    displayName: "Description",
  },
};

export const feedKeysForGoogleGMC = {
  message: {
    displayName: "Message",
  },
  numItems: {
    displayName: "Number Of Products",
  },
};
