export default {
  APP_SET_ENTITY_ID: "APP_SET_ENTITY_ID",
  UPDATE_BILLING_SETTINGS: "UPDATE_BILLING_SETTINGS",
  UPDATE_CAMPAIGN_TYPE_WALLET_DATA: "UPDATE_CAMPAIGN_TYPE_WALLET_DATA",
  UPDATE_WALLET_DATA: "UPDATE_WALLET_DATA",
  UPDATE_PROMOTIONS_DATA: "UPDATE_PROMOTIONS_DATA",
  UPDATE_PROMOTION_TABLE_DATA: "UPDATE_PROMOTION_TABLE_DATA",
  UPDATE_PROMOTION_FORM_DATA: "UPDATE_PROMOTION_FORM_DATA",
  SET_ACTIVE_CLIENT_ID: "SET_ACTIVE_CLIENT_ID",
  APP_CLIENT_CHANGE: "APP_CLIENT_CHANGE",
  GLOBAL_WALLET_DATA_FETCH_STATE: "GLOBAL_WALLET_DATA_FETCH_STATE",
};
