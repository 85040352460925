export const entityEnum = {
  MARKETING_CAMPAIGN: "MARKETING_CAMPAIGN",
  CHANNEL: "CHANNEL",
  STRATEGY: "STRATEGY",
  VENDOR_CAMPAIGN: "VENDOR_CAMPAIGN",
  PROMOTION: "PROMOTION",
  CAMPAIGN: "CAMPAIGN",
  ORDER: "ORDER",
  CREATIVE: "CREATIVE",
};

export const suggestionActionType = {
  ACCEPTED: "ACCEPTED",
  REJECTED: "REJECTED",
  EXPIRED: "EXPIRED",
  UNSEEN: "UNSEEN",
};

export const ObjectivesEnum = {
  "Absolute Revenue": {
    alias: "Revenue",
    key: "Absolute Revenue",
    derivedObjective: "ROI",
    nextObjectives: {
      "Absolute Revenue": {
        alias: "Revenue",
        key: "Absolute Revenue",
      },
      Transactions: {
        alias: "Orders",
        key: "Transactions",
      },
    },
  },
  Transactions: {
    alias: "Orders",
    key: "Transactions",
    derivedObjective: "CPO",
    nextObjectives: {
      "Absolute Revenue": {
        alias: "Revenue",
        key: "Absolute Revenue",
      },
      Transactions: {
        alias: "Orders",
        key: "Transactions",
      },
    },
  },
  Visitors: {
    alias: "Visitor",
    key: "Visitors",
    derivedObjective: "CPC",
    nextObjectives: {
      Visitors: {
        alias: "Visitor",
        key: "Visitors",
      },
      "Absolute Revenue": {
        alias: "Revenue",
        key: "Absolute Revenue",
      },
      Transactions: {
        alias: "Orders",
        key: "Transactions",
      },
    },
  },
  Engagement: {
    alias: "Engagements",
    key: "Engagement",
    nextObjectives: {
      Engagement: {
        alias: "Engagements",
        key: "Engagement",
      },
    },
  },
  App_Install: {
    alias: "App Installs",
    key: "App_Install",
    nextObjectives: {
      App_Install: {
        alias: "App Installs",
        key: "App_Install",
      },
    },
  },
  Messages: {
    alias: "Messages",
    key: "Messages",
    derivedObjective: "CPM",
    nextObjectives: {
      Messages: {
        alias: "Messages",
        key: "Messages",
      },
    },
  },
  "Brand Awareness": {
    alias: "Brand Awareness",
    key: "Brand Awareness",
    nextObjectives: {
      "Brand Awareness": {
        alias: "Brand Awareness",
        key: "Brand Awareness",
      },
    },
  },
  "Lead Generation": {
    alias: "Lead Generation",
    key: "Lead Generation",
    derivedObjective: "CPL",
    nextObjectives: {
      "Lead Generation": {
        alias: "Lead Generation",
        key: "Lead Generation",
      },
    },
  },
  "Store Visits": {
    alias: "Store Visits",
    key: "Store Visits",
    derivedObjective: "CPSV",
    nextObjectives: {
      "Store Visits": {
        alias: "Store Visits",
        key: "Store Visits",
      },
    },
  },
  "Visibility": {
    alias: "Visiblilty",
    key: "Visibility",
    derivedObjective: "CPT",
    nextObjectives: {
      "Visibility": {
        alias: "Visiblilty",
        key: "Visibility",
      },
    },
  },
  Reach: {
    alias: "Reach",
    key: "Reach",
    derivedObjective: "CPR",
    nextObjectives: {
      Reach: {
        alias: "Reach",
        key: "Reach",
      },
    },
  },
  Call: {
    alias: "Call Tracking",
    key: "Call",
    derivedObjective: "CPCT",
    nextObjectives: {
      Call: {
        alias: "Call Tracking",
        key: "Call",
      },
    },
  },
  ROI: {
    alias: "ROI",
    key: "ROI",
    isDerived: true,
    mainObjective: "Absolute Revenue",
  },
  CPC: {
    alias: "CPC",
    key: "CPC",
    isDerived: true,
    mainObjective: "Visitors",
  },
  CPO: {
    alias: "CPO",
    key: "CPO",
    isDerived: true,
    mainObjective: "Transactions",
  },
  CPL: {
    alias: "CPL",
    key: "CPL",
    isDerived: true,
    mainObjective: "Lead Generation",
  },
  CPM: {
    alias: "CPM",
    key: "CPM",
    isDerived: true,
    mainObjective: "Messages",
  },
  CPR: {
    alias: "CPR",
    key: "CPR",
    isDerived: true,
    mainObjective: "Reach",
  },
  CPCT: {
    alias: "CPCT",
    key: "CPCT",
    isDerived: true,
    mainObjective: "Call",
  },
  CPSV: {
    alias: "CPSV",
    key: "CPSV",
    isDerived: true,
    mainObjective: "Store Visits",
  },
  CPT: {
    alias: "CPM",
    key: "CPT",
    isDerived: true,
    mainObjective: "Visibility",
  }
};

export const marketingCampaignActionType = {
  BUDGET_CHANGE: {
    key: "BUDGET_CHANGE",
  },
  MARKETING_CAMPAIGN_GOAL: {
    key: "MARKETING_CAMPAIGN_GOAL",
  },
  MARKETING_CAMPAIGN_OBJECTIVE: {
    key: "MARKETING_CAMPAIGN_OBJECTIVE",
  },
};

export const budgetTypeEnum = {
  DAILY_BUDGET_WITH_MAX_CAP: "DAILY_BUDGET_WITH_MAX_CAP",
};

export const channelStatusEnum = {
  DRAFT: "DRAFT",
  ACTIVE: "ACTIVE",
  PAUSED: "PAUSED",
  LAUNCH_INPROGRESS: "LAUNCH_INPROGRESS",
};

export const goalStatusEnum = {
  DRAFT: "DRAFT",
  ACTIVE: "ACTIVE",
  PAUSED: "PAUSED",
  LAUNCH_INPROGRESS: "LAUNCH_INPROGRESS",
  ARCHIVED: "ARCHIVED",
  UNDER_REVIEW: "UNDER_REVIEW",
  DELIVERED: "DELIVERED",
};

export const goalSortOrderTypes = {
  ASC: {
    key: "ASC",
    displayName: "ASC",
  },
  DESC: {
    key: "DESC",
    displayName: "DESC",
  },
};

export const goalSortTypesConst = {
  BUDGET: {
    key: "BUDGET",
    displayName: "Budget",
    defaultSortOrder: [
      {
        key: "BUDGET",
        order: goalSortOrderTypes.DESC.key,
      },
    ],
  },
  STATUS: {
    key: "STATUS",
    displayName: "Status",
    defaultSortOrder: [
      {
        key: "STATUS",
        order: goalSortOrderTypes.ASC.key,
      },
    ],
  },
  STATUS_PRIORITY: {
    key: "STATUS_PRIORITY",
    displayName: "Status",
    defaultSortOrder: [
      {
        key: "STATUS_PRIORITY",
        order: goalSortOrderTypes.DESC.key,
      },
      {
        key: "ID",
        order: goalSortOrderTypes.DESC.key,
      },
    ],
  },
  ID: {
    key: "ID",
    displayName: "Creation Date",
    defaultSortOrder: [
      {
        key: "ID",
        order: goalSortOrderTypes.DESC.key,
      },
    ],
  },
  SPEND: {
    key: "SPEND",
    displayName: "Spend",
    defaultSortOrder: [
      {
        key: "SPEND",
        order: goalSortOrderTypes.DESC.key,
      },
    ],
  },
  IMPRESSION: {
    key: "IMPRESSION",
    displayName: "Impressions",
    sortingOrder: [
      {
        key: "IMPRESSION",
        order: goalSortOrderTypes.DESC.key,
      },
    ],
    selectors: [
      {
        alias: "impressions",
        canonicalColumn: "sum.campaign_performance_facts.impressions",
        isDistinct: false,
        isCount: false,
      },
    ],
  },
  ORDERS: {
    key: "ORDERS",
    displayName: "Orders",
    sortingOrder: [
      {
        key: "ORDERS",
        order: goalSortOrderTypes.DESC.key,
      },
    ],
    selectors: [
      {
        alias: "conversions",
        canonicalColumn: "sum.campaign_performance_facts.conversions",
        isDistinct: false,
        isCount: false,
      },
    ],
  },
  BRAND_ADS_CREATION_DATE: {
    key: "BRAND_ADS_CREATION_DATE",
    displayName: "Creation Date",
    sortingOrder: [
      {
        key: "BRAND_ADS_CREATION_DATE",
        order: goalSortOrderTypes.DESC.key,
      },
    ],
  },
};

export const goalEffectiveStatus = {
  PAUSED_DUE_TO_MAX_CAP_REACHED: "PAUSED_DUE_TO_MAX_CAP_REACHED",
  PAUSED_DUE_TO_END_DATE_REACHED: "PAUSED_DUE_TO_END_DATE_REACHED",
};

export const entityActionEnum = {
  STATUS_CHANGE: "STATUS_CHANGE",
  AUDIENCE_CHANGE: "AUDIENCE_CHANGE",
  GEO_CHANGE: "GEO_CHANGE",
  DEVICE_CHANGE: "DEVICE_CHANGE",
};

export const brandAdsGoalStatusEnum = {
  DRAFT: "DRAFT",
  ACTIVE: "ACTIVE",
  PAUSED: "PAUSED",
  UNDER_REVIEW: "UNDER_REVIEW",
  DRAFT_REJECT: "DRAFT_REJECT",
  SCHEDULED: "SCHEDULED",
  DELIVERED: "DELIVERED",
  ARCHIVED: "ARCHIVED",
};

export const MARKETPLACE_VENDOR_CHANNEL_MAPPING = {
  OS_PRODUCT_ADS: {
    key: "OS_PRODUCT_ADS",
    vendor: "os_ads",
    channel: "OS_PRODUCT_ADS",
    displayName: "Sofie Onsite",
  },
  ALL_CHANNELS: {
    key: "ALL_CHANNELS",
    vendor: "os_ads",
    channel: "ALL_CHANNELS",
    displayName: "Google, Facebook",
  },
};

export const GOAL_SUB_TYPE_TO_LAUNCHER_POSTFIX = {
  BLOCK_BUY: "_GAURANTEED",
  AUCTION: "_AUCTION",
  OS_ADS_SEARCH: "_SEARCH_ONLY",
  SMART_SHOPPING: "_SMART_SHOPPING",
};

export const GOAL_SUB_TYPE_KEY_TO_SUB_TYPE = {
  PERFORMANCE_SMART_SHOPPING: "SMART_SHOPPING",
  PERFORMANCE_SEARCH_ONLY: "OS_ADS_SEARCH",
  INVENTORY_AUCTION: "AUCTION",
  INVENTORY_GAURANTEED: "BLOCK_BUY",
  INVENTORY_GAURANTEED_CPD: "BLOCK_BUY",
};

export const DAILY_BUDGET_TYPE_ENUM = {
  AVG_DAILY_BUDGET: "AVG_DAILY_BUDGET",
  DAILY_BUDGET: "DAILY_BUDGET",
};

export const BIDDING_SOURCE_ENUM = {
  INVENTORY_LEVEL_BID: "INVENTORY_LEVEL_BID",
  CAMPAIGN_LEVEL_BID: "CAMPAIGN_LEVEL_BID",
};
