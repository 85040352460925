import { creativeSubAdType, ObjectivesEnum, vendorChannelMapping } from "@onlinesales-ai/constants-v2";

export const getSubAdTypeFromObjective = (channel, objective) => {
  let adSubType = null;
  if (channel === vendorChannelMapping.FACEBOOK.key) {
    switch (objective) {
      case ObjectivesEnum.Messages.key:
        {
          adSubType = creativeSubAdType.MESSENGER_AD.key;
        }
        break;
      case ObjectivesEnum["Brand Awareness"].key:
        {
          adSubType = creativeSubAdType.BRAND_AWARENESS_AD.key;
        }
        break;
      case ObjectivesEnum["Lead Generation"].key:
        {
          adSubType = creativeSubAdType.LEAD_AD.key;
        }
        break;
      default:
        adSubType = creativeSubAdType.FACEBOOK_AD.key;
        break;
    }
  } else if (channel === vendorChannelMapping.GOOGLE_SEARCH.key) {
    // switch (objective) {}
  } else if (channel === vendorChannelMapping.FACEBOOK_DPA.key) {
    switch (objective) {
      case ObjectivesEnum["Lead Generation"].key:
        {
          adSubType = creativeSubAdType.LEAD_AD.key;
        }
        break;
      default:
        break;
    }
  }
  return adSubType;
};
