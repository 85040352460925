/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useCallback, useEffect, useMemo } from "react";
import { Trans } from "react-i18next";
import classnames from "classnames";

import { Button } from "@onlinesales-ai/button-v2";
import { ModalWindow } from "@onlinesales-ai/modal-window-v2";
import AsyncImage from "@onlinesales-ai/async-image-v2";
import { Text } from "@onlinesales-ai/label-v2";
import WithTooltip from "@onlinesales-ai/tooltip-v2";

import "./index.less";

const VideoPlayer = ({
  src,
  thumbnailUrl,
  showInModal,
  showPreviewIcon,
  allowPlayOnHover,
  shouldShowControls,
  enableWrapperClick,
  modalTitle,
  showPreview,
  showCloseOnTop,
  onVideoLoad,
  noImageUrl,
  hasAudio,
  addOverlayDom,
  hidePlayButton,
  wrappperClassName,
  enableDownload,
  showNoPreviewTooltip,
}) => {
  const isGif = useMemo(() => src?.includes(".gif"), [src]);
  const [showModalVideo, setShowModalVideo] = useState({
    isShowVideo: false,
    src: null,
  });
  const [controlledVideoDOMNode, setControlledVideoDOMNode] = useState(null);
  const [shouldShowThumbnail, setShouldShowThumnail] = useState(true);

  const isVideoLoaded = useMemo(() => {
    const extensionToNotSupport = ["avi", "wmv", "mpg", "mov"];

    if (src) {
      const extension = src.split(".").pop()?.toLowerCase();
      return !extensionToNotSupport.includes(extension);
    }

    return false;
  }, [src]);

  const startVideoPlay = (selectedVideo) => {
    if (showInModal) {
      setShowModalVideo({
        isShowVideo: true,
        src: selectedVideo,
      });
    } else {
      // hide overlay and play video
      setShouldShowThumnail(false);
    }
  };

  useEffect(() => {
    if (!src) {
      setShouldShowThumnail(false);
    } else {
      setShouldShowThumnail(true);
    }
  }, [src]);

  const showVideoInModal = (selectedVideo) => {
    setShowModalVideo({
      isShowVideo: true,
      src: selectedVideo,
    });
  };
  const setShowModalVideoClose = () => {
    setShowModalVideo(false);
  };

  const onRefChange = useCallback(
    (node) => {
      if (node) {
        setControlledVideoDOMNode(node);
        if (!shouldShowThumbnail) {
          node.play();
        }
      }
    },
    [shouldShowThumbnail],
  );

  const pauseVideo = () => {
    if (allowPlayOnHover && controlledVideoDOMNode) {
      controlledVideoDOMNode.pause();
    }
  };
  const startVideo = () => {
    if (allowPlayOnHover && controlledVideoDOMNode) {
      controlledVideoDOMNode.play();
    }
  };

  const handleButtonHover = () => {
    if (allowPlayOnHover && !showInModal) {
      startVideoPlay(src);
    }
  };

  const onMouseLeave = () => {
    if (allowPlayOnHover && !showInModal) {
      pauseVideo();
      setShouldShowThumnail(true);
    }
  };

  const nonNegatableProps = useMemo(() => {
    const propsToReturn = {};
    if (shouldShowControls) {
      propsToReturn.controls = shouldShowControls;
    }
    return propsToReturn;
  }, [shouldShowControls]);

  const renderVideoElement = () => {
    return (
      <>
        {!src || isGif ? (
          <AsyncImage imgSrc={src} noImageUrl={noImageUrl} />
        ) : (
          <>
            {isVideoLoaded ? (
              <video
                key={src}
                id="video-player-element"
                muted={!hasAudio}
                className="video-player-element"
                ref={onRefChange}
                onMouseLeave={onMouseLeave}
                onMouseEnter={startVideo}
                {...nonNegatableProps}
                onLoadedData={onVideoLoad}
              >
                <source src={src} />
              </video>
            ) : (
              <WithTooltip title={showNoPreviewTooltip && "No preview available"}>
                <div className="no-preview-wrapper d-center flex-column position-absolute w100p h100p">
                  <AsyncImage imgSrc="https://osads.gumlet.io/image/authenticated/s--kDhOJ5jY--/v1717416983/product/No_preview_avilable_icon.svg" />
                  <Text className="mh-auto">No preview available</Text>
                </div>
              </WithTooltip>
            )}
          </>
        )}
      </>
    );
  };

  const renderThumbnail = () => {
    return <img src={thumbnailUrl} />;
  };

  return (
    <div
      className={`video-player-wrapper ${wrappperClassName || ""} ${
        thumbnailUrl ? "thumbnail-wrapper" : ""
      } ${!isVideoLoaded ? "preview-not-loaded h100p w100p" : ""} ${showPreviewIcon ? "show-preview-icon" : ""}`}
      onClick={(e) => (!enableWrapperClick ? e.stopPropagation() : null)}
    >
      {(showInModal || showPreview) && (
        <ModalWindow
          isShow={showModalVideo.isShowVideo}
          onModalDestroy=""
          useBootstrapModal
          containerClass="modal-lg video-player-modal"
          modalProps={{
            backdrop: "static",
          }}
        >
          <ModalWindow.Header>
            <Trans>{modalTitle}</Trans>
            {showCloseOnTop && (
              <span
                className="video-close-icon icon icon-close1 close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowModalVideoClose(false);
                }}
              />
            )}
          </ModalWindow.Header>
          <ModalWindow.Body>
            <div className="video-player-wrapper">
              <video className="video-player-element" autoPlay controls>
                <source src={showModalVideo.src} />
              </video>
            </div>
          </ModalWindow.Body>
          {!showCloseOnTop && (
            <ModalWindow.Footer>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  setShowModalVideoClose(false);
                }}
              >
                Close
              </Button>
            </ModalWindow.Footer>
          )}
        </ModalWindow>
      )}
      {isVideoLoaded && shouldShowThumbnail && !hidePlayButton && !isGif && (
        <>
          <Button
            type="play"
            onClick={() => startVideoPlay(src)}
            onMouseEnter={handleButtonHover}
            className="play-pause-button play-icon videoplay"
          />
          <div
            onClick={() => startVideoPlay(src)}
            onMouseEnter={handleButtonHover}
            onMouseLeave={onMouseLeave}
            className="thumbnail-overlay"
          />
        </>
      )}
      {(isVideoLoaded && showPreview && !isGif) || enableDownload ? (
        <div className="preview-and-download-wrapper d-align-center gap-10 pendo_feature_video_player_preview_and_download_button">
          {enableDownload ? (
            <a
              className={classnames("text-decoration-none download-video", {
                "d-none": isVideoLoaded,
              })}
              href={src}
              download
            >
              <span className="icon icon-download1" />
            </a>
          ) : null}
          {isVideoLoaded && showPreview && !isGif && (
            <div
              onClick={(e) => {
                showVideoInModal(src);
                e.stopPropagation();
              }}
              onMouseEnter={handleButtonHover}
              className="preview-video"
            >
              <span className="icon icon-expand1" />
            </div>
          )}
        </div>
      ) : null}
      {thumbnailUrl && shouldShowThumbnail && renderThumbnail()}
      {thumbnailUrl
        ? !showInModal && !shouldShowThumbnail && renderVideoElement()
        : renderVideoElement()}
      {addOverlayDom ? (
        <div
          onMouseEnter={handleButtonHover}
          onMouseLeave={onMouseLeave}
          className="media-overlay"
        />
      ) : null}
    </div>
  );
};

VideoPlayer.defaultProps = {
  // thumbnailUrl:
  //   "https://osads.gumlet.io/image/upload/v1625571002/bjqzrm3wvzyy5qts6tcp.jpg",
  showInModal: false,
  showPlayButtonWithoutThumbnail: true,
  allowPlayOnHover: true,
  hidePlayButton: false,
  shouldShowControls: true,
  showPreview: false,
  modalTitle: "Video Preview",
  showCloseOnTop: false,
  hasAudio: false,
  onVideoLoad: () => {},
  enableDownload: true,
};

export default VideoPlayer;
