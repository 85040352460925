import React, { useEffect, memo } from "react";
import { getParameterByName, setCookie, populateEvent } from "@onlinesales-ai/util-methods-v2";
import { COOKIES } from "@onlinesales-ai/constants-v2";

const fireIntercomEvents = (action, metaData) => {
  populateEvent("GOOGLE_CALLBACK_PAGE", "GOOGLE_CALLBACK||" + action, metaData);
};

const GoogleCallback = () => {
  const setCookies = (token) => {
    /**
     * Set UA_TOKEN and ubid with expire of 1 year
     */
    setCookie(COOKIES.UA_TOKEN, token, 365);
    setCookie(COOKIES.UBID, COOKIES.UBID_VALUE, 365);
  };

  const handleOauthBasedSignIn = () => {
    const token = getParameterByName("uaToken");
    if (token) {
      setCookies(token);
      fireIntercomEvents("GOOGLE_CALLBACK_WITH_TOKEN", { token });
    }
    const data = {
      success: !!token,
      token,
      responseType: getParameterByName("responseType"),
      errorCode: getParameterByName("errorCode"),
    };
    // var targetOrigin = window.location.origin;
    if (window.opener) {
      window.opener.postMessage(JSON.stringify(data), "*");
    } else {
      console.error("ERROR: window.opener is null, /callback must be opened from another window.");
    }
  };

  useEffect(() => {
    handleOauthBasedSignIn();
  }, []);

  return <></>;
};

export default memo(GoogleCallback);
