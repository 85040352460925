import BaseClient from "../baseClient";

const servicePrefix = "/clientInputService/";

class ClientInputService extends BaseClient {
  static getErrorMessages() {
    return {
      default: "Looks like we are facing some internal issue. Please, retry after sometime.",
      IP0000:
        "It seems some input parameters are not valid. Please update your inputs and try again.",
      AD0000:
        "Oops. It seems you are not authorized to take this action. Please log in with appropriate credentials.",
      I0004:
        "Oops. Seems the system is acting up - sorry for the inconvenience. Please try again in some time. (Please be assured our tech geniuses have been notified of this glitch).",
    };
  }

  static fetchCISValue(data, application) {
    const payload = {
      "@class": "com.sokrati.clientInputSvcObjects.request.GetParamRequest",
      clientId: data.clientId,
      userId: data.userId,
      filters: [
        {
          column: "PROPERTY_TYPES",
          operator: "IN",
          values: data.values,
        },
      ],
    };

    return this.apiCall(
      {
        url: `${servicePrefix}data`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(payload),
        },
      },
      application,
    );
  }
}

export default ClientInputService;
