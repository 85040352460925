import React, { useState, useMemo, useCallback, useEffect, useRef } from "react";

import { useKeyboard } from "@onlinesales-ai/shortcuts-v2";
import { ModalWindow } from "@onlinesales-ai/modal-window-v2";
import { Text } from "@onlinesales-ai/label-v2";
import { setLSItem } from "@onlinesales-ai/util-methods-v2";

import "./index.less";

const ClientSelection = ({ configOverrides, uiId }) => {
  const [inputValue, setInputValue] = useState("");
  const [focusIndex, setFocusIndex] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const inputRef = useRef();
  const resultRef = useRef();

  const list = useMemo(() => {
    return Object.keys(configOverrides);
  }, [configOverrides]);

  const searchResults = useMemo(() => {
    if (inputValue) {
      return list.filter((l) => l.includes(inputValue));
    }
    return list;
  }, [inputValue]);

  const onClickItem = (item) => {
    setLSItem(`${uiId}-configName`, item);
    window.location.reload();
  };

  const onClickOpen = useCallback(() => {
    setShowModal((old) => !old);
  }, []);

  useEffect(() => {
    if (showModal && inputRef.current) {
      setTimeout(() => {
        inputRef.current.focus();
        setInputValue("");
      }, 0);
    }
  }, [showModal]);

  useKeyboard(["meta+.", "meta+]"], onClickOpen);

  const onClickKeyboard = useCallback((event, hotEvent) => {
    const key = hotEvent?.keys?.[0];

    if (key === "esc") {
      setShowModal(false);
      setInputValue("");
    } else if (key === "up") {
      if (focusIndex === 0) {
        // setFocusIndex(searchResults.length - 1);
      } else {
        setFocusIndex(focusIndex - 1);
      }
    } else if (key === "down") {
      if (focusIndex === searchResults.length - 1) {
        // setFocusIndex(0);
      } else {
        setFocusIndex(focusIndex + 1);
      }
    } else if (key === "enter") {
      if (typeof focusIndex === "number") {
        onClickItem(searchResults[focusIndex]);
      }
    }
  }, [focusIndex, searchResults]);

  useEffect(() => {
    if (resultRef.current && typeof focusIndex === "number") {
      const elementToScroll = resultRef.current.querySelector(`[data-index="${focusIndex}"]`);
      if (elementToScroll) {
        const elementRect = elementToScroll.getBoundingClientRect();
        const wrapperRect = resultRef.current.getBoundingClientRect();

        if (elementRect.bottom > wrapperRect.bottom) {
          resultRef.current.scroll({
            top: resultRef.current.scrollTop + elementRect.height,
            behavior: "smooth",
          });
        } else if (elementRect.top < wrapperRect.top) {
          resultRef.current.scroll({
            top: resultRef.current.scrollTop - elementRect.height,
            behavior: "smooth",
          });
        }
      }
    }
  }, [focusIndex]);

  useKeyboard("esc, up, down, enter", onClickKeyboard, {
    enabled: showModal,
    enableOnFormTags: true,
    keyup: true,
    keydown: false,
  });

  return (
    <ModalWindow
      isShow={showModal}
      containerClass="client-selection-internal-modal"
      backdropClassName="dark-backdrop"
      onModalDestroy={() => {
        setShowModal(false);
        setInputValue("");
      }}
    >
      <ModalWindow.Body>
        <div className="search-input-wrapper">
          <input
            type="search"
            ref={inputRef}
            className="search-input"
            onChange={(ev) => {
              setInputValue(ev.target.value);
            }}
            onKeyDown={(ev) => {
              if (ev.key === "ArrowUp" || ev.key === "ArrowDown") {
                ev.preventDefault();
              }
            }}
            placeholder="Search Client..."
          />
          <span className="icon icon-search-2 search-icon" />
        </div>
        <div className="search-results">
          <>
            {searchResults?.length !== 0 ? (
              <>
                {searchResults.map((item, index) => {
                  return (
                    <a
                      data-index={index}
                      className={`search-item ${focusIndex === index ? "focus" : ""}`}
                      onClick={(e) => {
                        e.preventDefault();
                        onClickItem(item);
                      }}
                    >
                      {item}
                    </a>
                  );
                })}
              </>
            ) : (
              <>
                {inputValue ? (
                  <div className="no-options-dom d-center flex-column">
                    <div className="no-option-icon d-center">
                      <span className="icon icon-no-results-3" />
                    </div>
                    <Text weight="semiBold" className="mb-1">
                      No result found
                    </Text>
                    <Text>Try different keyword</Text>
                  </div>
                ) : null}
              </>
            )}
          </>
        </div>
      </ModalWindow.Body>
    </ModalWindow>
  );
};

export default ClientSelection;
