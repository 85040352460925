import React, { useMemo } from "react";
import _get from "lodash/get";

import { MediaUploadWithProgress } from "@onlinesales-ai/media-library-v2";

import "./index.less";

const UploadFile = ({ dataKey, onChange, formValues }) => {
  const value = useMemo(() => {
    return _get(formValues, dataKey) || "";
  }, [formValues, dataKey]);

  const onFileUpload = (fileUrl) => {
    onChange({
      [dataKey]: fileUrl,
    });
  };

  const onRemoveFile = () => {
    onFileUpload("");
  };

  const fileUrlParts = value.split("/");
  const fileName = fileUrlParts[fileUrlParts.length - 1] || "";

  return (
    <div className="ticket-component-media-upload">
      {
        value ? (
          <div className="url-show-wrapper">
            <a href={value} target="_blank">{fileName}</a>
            <span className="icon icon-ico-cross" onClick={onRemoveFile} />
          </div>
        ) : (
          <MediaUploadWithProgress
            sampleFileText=""
            uploadEndPoint="/onboardingService/uploadFeed"
            isUploadOnCloudinary={false}
            onUpload={onFileUpload}
            overlayDomText={<>Add File <br /><span className="optional-text">(optional)</span></>}
          />
        )
      }
    </div>
  );
};

export default UploadFile;
