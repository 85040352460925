/* eslint-disable camelcase */
import React from "react";
import { connect } from "react-redux";
import { BaseClient, OSBillingServiceV2 } from "@onlinesales-ai/services-v2";
import PlatformEventManager from "@onlinesales-ai/event-manager-v2";
import { populateEvent, loadSnapPayScript } from "@onlinesales-ai/util-methods-v2";
import {PAYMENT_METHOD} from "@onlinesales-ai/constants-v2";

function fireIntercomEvents(action, metaData) {
  populateEvent("APP", `PAYMENT||SNAP_PAY_PAYMENT||${action}`, metaData);
}

class BlibliSnapPayment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fetchOrderIdLoading: false,
    };

    this.orderData = null;
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (
      newProps.amount !== this.props.amount ||
      newProps.currency !== this.props.currency ||
      newProps.userInfo?.email !== this.props.userInfo?.email
    ) {
      this.orderData = null;
    }
  }

  async componentDidMount() {
    const { onError, isStaging } = this.props;

    try {
      await loadSnapPayScript(isStaging);
      fireIntercomEvents("SNAP_PAY_LOADED");
    } catch (err) {
      fireIntercomEvents("SNAP_PAY_LOAD_ERROR");
      onError(err);
    }
  }

  fetchOrderId = async () => {
    const {
      clientId,
      userInfo = {},
      currency,
      amount,
      fireEventOnOrderId,
      useDummyEmail,
      sellerId,
      marketPlaceName,
      chargeAPICall,
      paymentMethod,
      forceNewOrderId,
      financeChargeAPICall,
    } = this.props;

    if (!forceNewOrderId && this.orderData) {
      return Promise.resolve(this.orderData);
    }

    this.setState({
      fetchOrderIdLoading: true,
    });

    let userEmail = userInfo.email;

    if (!userEmail && useDummyEmail && userInfo.contact) {
      userEmail = `${userInfo.contact}@onlinesales.ai`;
    }
    let response;

    let firstName = sellerId ? `${marketPlaceName} - ${sellerId}` : (userInfo?.name || "");

    if (
      paymentMethod === PAYMENT_METHOD.MIDTRANS_BUKUKAS || 
      paymentMethod === PAYMENT_METHOD.MIDTRANS_BUKUKAS_STAGING
    ) {
      firstName = userInfo?.name;
    }
    
    const customer_details = {
      first_name: firstName,
      last_name: "",
      email: userEmail,
      phone: userInfo?.contactNo,
    };

    if (financeChargeAPICall) {
      await financeChargeAPICall({
        paymentOverrides: {
          metadata: {
            customer_details,
          },
        },
      });
    } else if (chargeAPICall) {
      response = await chargeAPICall(amount, { customer_details });
    } else {
      response = await OSBillingServiceV2.charge({
        clientId,
        email: userEmail,
        platform: "OS",
        amount,
        currency,
        metadata: {
          customer_details,
        },
      });
    }

    const { midtrans_order_id: order_id, midtrans_snap_txn_token: token, transactionId } =
      response?.metadata || {};

    if ((!order_id || !token) && !response?.doNotInitPG) {
      throw Error("Snap Pay payment not supported please contact support.");
    }

    this.orderData = {
      orderId: order_id,
      token,
      doNotInitPG: response.doNotInitPG || false,
      transactionId,
    };

    this.setState({
      fetchOrderIdLoading: false,
    });

    fireIntercomEvents("ORDER_CREATED", response);

    if (fireEventOnOrderId && fireEventOnOrderId.length) {
      fireEventOnOrderId.forEach((event) => {
        PlatformEventManager.emit(event);
      });
    }

    return this.orderData;
  };

  openPaymentModal = async () => {
    const {
      onPaymentSuccess, onError, beforePayment, fireEventOnPaymentSuccess,
      onDismissCallback,
    } = this.props;

    try {
      await beforePayment();
    } catch (err) {
      return;
    }

    try {
      const orderDetails = await this.fetchOrderId();
      if (!orderDetails?.doNotInitPG) {
        window.snap?.pay(orderDetails?.token, {
          onSuccess(result) {
            // this.updateTransactionId(orderDetails);
            // reset order data after successfull payment
            this.orderData = null;

            fireIntercomEvents("PAYMENT_SUCCESS", JSON.stringify(orderDetails));
            if (fireEventOnPaymentSuccess && fireEventOnPaymentSuccess.length) {
              fireEventOnPaymentSuccess.forEach((event) => {
                PlatformEventManager.emit(event);
              });
            }

            onPaymentSuccess(result);
          },
          onError(result) {
            throw new Error(BaseClient.getErrorMessage());
          },
          onClose: () => onDismissCallback(orderDetails),
        });
        fireIntercomEvents("MODAL_OPEN", JSON.stringify(orderDetails));
      } else {
        // this.updateTransactionId(orderDetails);
        fireIntercomEvents("PAYMENT_SUCCESS", JSON.stringify(orderDetails));
        if (fireEventOnPaymentSuccess && fireEventOnPaymentSuccess.length) {
          fireEventOnPaymentSuccess.forEach((event) => {
            PlatformEventManager.emit(event);
          });
        }
        onPaymentSuccess(orderDetails);
      }
    } catch (err) {
      this.setState({
        fetchOrderIdLoading: false,
      });
      onError(err);
    }
  };

  render() {
    const { children } = this.props;
    const { fetchOrderIdLoading } = this.state;

    if (typeof children === "function") {
      return children({
        openPayment: this.openPaymentModal,
        isLoading: fetchOrderIdLoading,
      });
    }

    return null;
  }
}

const mapStateToProps = (state, ownProps) => {
  const { contactNo, email, name } = state.Application?.userInfo || {};
  const { data: onboardingDetails = {} } = state.OnBoarding || {};
  const userInfo = {
    name,
    email,
    contact: contactNo,
  };
  const sellerId = onboardingDetails?.marketplaceStoreDetails?.id || null;

  return {
    clientId: ownProps.clientId || state.Application.clientId,
    userInfo: ownProps.userInfo || userInfo,
    sellerId,
  };
};

const BlibliSnapPaymentWrapper = connect(mapStateToProps)(BlibliSnapPayment);

BlibliSnapPaymentWrapper.defaultProps = {
  beforePayment: () => Promise.resolve(),
  fireEventOnOrderId: [],
  fireEventOnPaymentSuccess: [],
  currency: "IDR",
  marketPlaceName: "Bliklan",
  isStaging: false,
  onDismissCallback: () => { },
};

export default BlibliSnapPaymentWrapper;
