/* eslint-disable no-else-return */
import React from "react";
import { useTranslation } from "react-i18next";
import { components } from "react-select";
import { CheckboxGroup } from "@onlinesales-ai/checkbox-v2";
import Dropdown from "./Dropdown";
import "./index.less";

const DropDownWithCheckbox = (props) => {
  const { t } = useTranslation();
  const getOptionDOM = (option, { context }) => {
    if (context === "menu") {
      return (
        <div className="option-with-checkbox">
          <CheckboxGroup
            type="checkbox"
            className="shops-custom-checkbox option-checkbox"
            defaultValue={null}
            data={[option]}
            groupValue={props.value || []}
            checked={props.value?.includes(option.value)}
            onChange={props.onChange}
            disableLabelClick={props.disableLabelClick}
            {...(props.checkboxProps || {})}
          />
        </div>
      );
    } else if (context === "value") {
      return getValueDOM(option);
    }
  };

  const getValueDOM = (valueObj) => {
    return (
      <span className="value-container">
        <span className="value-text">{valueObj.label}</span>
        {/* <span className="value-separator">, </span> */}
      </span>
    );
  };

  // const MultiValueRemove = () => {
  //   return null;
  // };

  const MultiValue = (config) => {
    // Only interested in rendering one/first multiValue
    if (config.index > 0) return null;

    if (props.showCommaSeparated) {
      const selectedValues = [];
      config.getValue().forEach((element) => {
        selectedValues.push(element?.label);
      });
      return <div className="ellipsis-text">{selectedValues.join(", ")}</div>
    } else {
      const { length } = config.getValue();
      return length > props.customTextAfterLength ? (
        <div className="selected-entity-count">
          {t(props.selectionText, {
              length,
              entityName: props.entityName,
            })}
        </div>
      ) : (
        <components.MultiValue {...config} />
      );  
    }
  };

  const colourStyles = {
    multiValue: () => {
      return {
        backgroundColor: "none",
      };
    },
  };

  return (
    <div className="os-dropdown-checkbox-wrapper">
      <Dropdown
        {...props}
        // styles={colourStyles}
        components={{ MultiValue, ...props.components }}
        formatOptionLabel={getOptionDOM}
        // getOptionValue={getValueDOM}
        // onChange={props.onChange}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
      />
    </div>
  );
};

DropDownWithCheckbox.defaultProps = {
  entityName: "Campaigns",
  customTextAfterLength: 1,
  selectionText: "{{length}} {{entityName}} selected",
  options: [],
  placeholder: "",
  className: "",
  value: [],
  isMulti: true,
  isClearable: true,
  closeMenuOnSelect: false,
  isSearchable: false,
  onChange: () => {},
  showCommaSeparated: false,
};
export default DropDownWithCheckbox;
