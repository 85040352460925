import BaseClient from "../baseClient";

const serviceUrl = "/onBoardingSvcV2";

class OnboardingService extends BaseClient {
  static getErrorMessages() {
    return {
      default: "Looks like we are facing some internal issue. Please, retry after sometime.",
      IP0000:
        "It seems some input parameters are not valid. Please update your inputs and try again.",
      AD0000:
        "Oops. It seems you are not authorized to take this action. Please log in with appropriate credentials.",
      I0004:
        "Oops. Seems the system is acting up - sorry for the inconvenience. Please try again in some time. (Please be assured our tech geniuses have been notified of this glitch).",
    };
  }

  static uploadImage(data, application) {
    return this.apiCall(
      {
        url: "/onboardingService/uploadImage",
        method: "POST",
        data,
      },
      application,
    );
  }

  static getStatus(clientId, application, options) {
    return this.apiCall(
      {
        url: serviceUrl + "/status",
        method: "GET",
        params: { jsonQuery: JSON.stringify({ clientId, fetchAgencySettings: true }) },
      },
      application,
      options,
    );
  }

  static postStatus(payload, application) {
    return this.apiCall(
      {
        url: serviceUrl + "/status",
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
    );
  }

  static verifySeedPlacement(payload, application) {
    let { url, storeType, metaContentId } = payload;
    return this.apiCall(
      {
        url:
          serviceUrl +
          "/verifyChuknu?url=" +
          url +
          "&storeType=" +
          storeType +
          (metaContentId ? "&metaContentId=" + metaContentId : ""),
        method: "GET",
      },
      application,
    );
  }

  static getSellerConfig(payload, application) {
    return this.apiCall(
      {
        url: serviceUrl + "/sellerConfig",
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
    );
  }

  static postSellerConfig(payload, application) {
    return this.apiCall(
      {
        url: serviceUrl + "/sellerConfig",
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
    );
  }

  static assignPackage(payload, application) {
    return this.apiCall(
      {
        url: serviceUrl + "/sellerConfig/assignPackage",
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
    );
  }
}

export default OnboardingService;
