import { ClientInputService, ReportingUIService, MerchandiseFeedService, NexusService } from "@onlinesales-ai/services-v2";
import { getvendorAccountData } from "@onlinesales-ai/util-methods-v2";

import Types from "./types";

export const setVendorAccountData = (data) => ({
  type: Types.SET_VENDOR_ACCOUNT_DATA,
  data,
});

export const updateVendorAccountData = (data) => ({
  type: Types.UPDATE_VENDOR_ACCOUNT_DATA,
  data,
});

export const setVendorAccountFetchState = (isLoading, errorMsg) => ({
  type: Types.SET_VENDOR_ACCOUNT_FETCH_STATE,
  isLoading,
  errorMsg,
});

export const setVendorAccountPostState = (isLoading, errorMsg) => ({
  type: Types.SET_VENDOR_ACCOUNT_POST_STATE,
  isLoading,
  errorMsg,
});

export const prepareVendorAccountData = (paramDetails = {}) => {
  return getvendorAccountData(paramDetails.filter((p) => !!p));
};

export const fetchVendorAccountData = (accountsToFetch, application, shouldReturnParamDetails) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { clientId, userInfo } = state.Application;

    dispatch(setVendorAccountFetchState(true, false));

    try {
      const response = await ClientInputService.fetchCISValue(
        {
          clientId,
          userId: userInfo?.id,
          values: accountsToFetch,
        },
        application,
      );

      const { paramDetails = [] } = response || {};
      const vendorAccountData = prepareVendorAccountData(paramDetails);
      dispatch(setVendorAccountData(vendorAccountData));
      dispatch(setVendorAccountFetchState(false, false));

      if (shouldReturnParamDetails) {
        return Promise.resolve({ vendorAccountData, paramDetails });
      }
      return Promise.resolve(vendorAccountData);
    } catch (err) {
      dispatch(setVendorAccountFetchState(false, err.errorMsg));
      return Promise.reject(err);
    }
  };
};

export const blockingAccountLinkingData = ({
  accountsToFetch,
  redirectUrl,
  accountCheckConfig = {},
}) => {
  const { noActiveAccountRedirectUrl, accountsToCheck } = accountCheckConfig;

  return async (dispatch, getState) => {
    const state = getState();
    const clientId = state.Application.clientId;

    if (clientId && clientId !== "CLIENT_AGGREGATOR") {
      const { paramDetails = [], vendorAccountsData: vendorAccounts } = await dispatch(
        fetchVendorAccountData(accountsToFetch, "APP_LEVEL_ACCOUNT_LINKING", true),
      );

      const isAtLeastOneAccountActive =
        paramDetails?.length &&
        accountsToCheck?.some((key) => {
          const paramObj = paramDetails?.find(({ name }) => name === key);
          // FIND data for FACEBOOK.COM::ACCOUNT

          // find if FACEBOOK.COM::ACCOUNT is isActive true and it contains at least one account
          // with isActive true
          return paramObj?.isActive && paramObj?.values?.some(({ isActive }) => isActive);
        });

      if (!isAtLeastOneAccountActive) {
        redirectUrl(noActiveAccountRedirectUrl);
      }

      return vendorAccounts;
    }

    return {};
  };
};

export const refreshVendorAccountsData = (accountsToFetch, application) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { clientId, userInfo } = state.Application;

    try {
      const response = await ClientInputService.fetchCISValue(
        {
          clientId,
          userId: userInfo?.id,
          values: accountsToFetch,
        },
        application,
      );

      const { paramDetails = [] } = response || {};
      const vendorAccountData = prepareVendorAccountData(paramDetails);
      dispatch(updateVendorAccountData(vendorAccountData));

      return Promise.resolve(vendorAccountData);
    } catch (err) {
      return Promise.reject(err);
    }
  };
};

export const saveAccountToCISData = ({
  paramDetails,
  activity = null,
  checks = [],
  application,
}) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { clientId, userInfo, shopInfo } = state.Application;

    dispatch(setVendorAccountPostState(true));

    try {
      const response = await ReportingUIService.postAccountLinking(
        {
          clientId,
          userId: userInfo.id,
          paramDetails,
          activity,
          checks,
          storeType: shopInfo.storeType,
        },
        application,
      );

      const { paramDetails: newParamDetails = [] } = response || {};

      dispatch(
        updateVendorAccountData(
          getvendorAccountData(
            newParamDetails.filter((p) => !!p),
            false,
          ),
        ),
      );

      dispatch(setVendorAccountPostState(false));
    } catch (err) {
      dispatch(setVendorAccountPostState(false));
      return Promise.reject(err.errorMsg);
    }
  };
};

export const linkMarketplaceSeller = (config, application) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { clientId, userInfo } = state.Application;

    const response = await NexusService.linkMarketplaceSeller(
      {
        clientId,
        userId: userInfo.id,
        ...config,
      },
      application,
    );

    const { accountLinked = [] } = response || {};
    const responseVendorAccountData = prepareVendorAccountData([{ "values": accountLinked }]);

    const { vendorAccountData = {} } = state.AccountLinking || {};

    const localVendorAccountData = {
      ...vendorAccountData,
      "com.sokrati.clientInputSvcObjects.parameter.RMNAccount": [
        ...(vendorAccountData?.["com.sokrati.clientInputSvcObjects.parameter.RMNAccount"] || []),
        ...(responseVendorAccountData?.["com.sokrati.clientInputSvcObjects.parameter.RMNAccount"] || []),
      ],
    };

    dispatch(updateVendorAccountData(localVendorAccountData));

    return response;
  };
};
