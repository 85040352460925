export const alVendorEnum = {
  GOOGLE: "GOOGLE",
  FACEBOOK: "FACEBOOK",
  INSTAGRAM: "INSTAGRAM",
};

export const alAccountStateEnum = {
  NOT_LINKED: "NOT_LINKED",
  LINKED: "LINKED",
  PARTIALLY_LINKED: "PARTIALLY_LINKED",
  VERIFICATION_PENDING: "VERIFICATION_PENDING",
  PERMISSION_MISSING: "PERMISSION_MISSING",
  LINKING_UNDER_PROGRESS: "LINKING_UNDER_PROGRESS",
  REJECTED: "REJECTED",
};

export const vendorChannelMapping = {
  GOOGLE_PLA: {
    key: "GOOGLE_PLA",
    vendor: "google",
    channel: "Shopping",
    displayName: "Google Shopping",
    imageClassName: "vendor-channel-GOOGLE_PLA",
    vendorKey: "GOOGLE",
  },
  GOOGLE_SEARCH: {
    key: "GOOGLE_SEARCH",
    vendor: "google",
    channel: "Search",
    displayName: "Google Search",
    imageClassName: "vendor-channel-GOOGLE_SEARCH",
    vendorKey: "GOOGLE",
  },
  GOOGLE_DISPLAY: {
    key: "GOOGLE_DISPLAY",
    vendor: "google",
    channel: "Display",
    displayName: "Google Display",
    imageClassName: "vendor-channel-GOOGLE_DISPLAY",
    vendorKey: "GOOGLE",
  },
  FACEBOOK: {
    key: "FACEBOOK",
    vendor: "facebook",
    channel: "facebook",
    displayName: "Facebook",
    imageClassName: "vendor-channel-FACEBOOK",
    vendorKey: "FACEBOOK",
  },
  FACEBOOK_DPA: {
    key: "FACEBOOK_DPA",
    vendor: "facebook",
    channel: "facebook_dpa",
    displayName: "Facebook DPA",
    imageClassName: "vendor-channel-FACEBOOK_DPA",
    vendorKey: "FACEBOOK",
  },
  AMAZON_SHOPPING: {
    key: "AMAZON_SHOPPING",
    vendor: "amazon",
    channel: "Shopping",
    displayName: "Amazon Shopping",
    imageClassName: "vendor-channel-AMAZON_SHOPPING",
    vendorKey: "AMAZON",
  },
  OS_PRODUCT_ADS: {
    key: "OS_PRODUCT_ADS",
    vendor: "os_ads",
    channel: "os_product_ads",
    displayName: "Onsite",
    imageClassName: "vendor-channel-OS_PRODUCT_ADS",
    vendorKey: "os_ads",
  },
  PROGRAMMATIC: {
    key: "PROGRAMMATIC",
    vendor: "programmatic",
    channel: "programmatic",
    displayName: "Programmatic",
    imageClassName: "vendor-channel-PROGRAMMATIC",
    vendorKey: "programmatic",
  },
  SOFIE_ONSITE: {
    key: "SOFIE_ONSITE",
    vendor: "sofie",
    channel: "sofie_onsite",
    displayName: "Onsite",
  },
  EMAIL: {
    key: "EMAIL",
    vendor: "os_ads",
    channel: "EMAIL",
    displayName: "EMAIL",
  },
  WHATSAPP: {
    key: "WHATSAPP",
    vendor: "os_ads",
    channel: "WHATSAPP",
    displayName: "WHATSAPP",
  },
  RMN_1MG: {
    key: "RMN_1MG",
    vendor: "RMN_EXCLUSIVE",
    channel: "1mg",
    displayName: "1MG",
  },
  RMN_TAKEALOT: {
    key: "RMN_TAKEALOT",
    vendor: "RMN_EXCLUSIVE",
    channel: "takealot",
    displayName: "TakeALot",
  },
  "RMN_1MG-MARKETPLACE-STAGING": {
    key: "RMN_1MG-MARKETPLACE-STAGING",
    vendor: "RMN_EXCLUSIVE",
    channel: "1mg-marketplace-staging",
    displayName: "1mg Staging",
  },
  "RMN_MONETIZE-SANDBOX": {
    key: "SANDBOX",
    vendor: "RMN_EXCLUSIVE",
    channel: "monetize-sandbox",
    displayName: "Monetize Sandbox",
  },
  "RMN_KORNERSHOPPE-STAGING": {
    key: "RMN_KORNERSHOPPE-STAGING",
    vendor: "RMN_EXCLUSIVE",
    channel: "kornershoppe-staging",
    displayName: "KornerShoppe Staging",
  },
  "RMN_KORNERSHOPPE-PROD": {
    key: "RMN_KORNERSHOPPE_PROD",
    vendor: "RMN_EXCLUSIVE",
    channel: "kornershoppe-prod",
    displayName: "KornerShoppe",
  },
  "RMN_TOPS-MARKET": {
    key: "RMN_TOPS-MARKET",
    vendor: "RMN_EXCLUSIVE",
    channel: "tops-market",
    displayName: "Tops Market",
  },
  "RMN_FANATICS": {
    key: "RMN_FANATICS",
    vendor: "RMN_EXCLUSIVE",
    channel: "fanatics",
    displayName: "Fanatics",
  },
  "RMN_LOWES-FOODS": {
    key: "RMN_LOWES-FOODS",
    vendor: "RMN_EXCLUSIVE",
    channel: "lowes-foods",
    displayName: "Lowes Foods",
  },
  // "EBAY": {
  //     "key": "EBAY",
  //     "vendor": "ebay",
  //     "channel": "All",
  //     "displayName": "eBay"
  // }
  // "BING_PLA": {
  //     "vendor": "bing",
  //     "channel": "Shopping",
  //     "displayName": "Bing Shopping"
  // }
};
