export default {
  SET_GOALS_V2_BID_SETTING_DATA: "SET_GOALS_V2_BID_SETTING_DATA",
  SET_GOALS_V2_SKU_SELECTION_DATA: "SET_GOALS_V2_SKU_SELECTION_DATA",
  SET_GOALS_V2_SKU_SELECTION_FETCH: "SET_GOALS_V2_SKU_SELECTION_FETCH",
  SET_GOALS_V2_TOTAL_SKU_DATA: "SET_GOALS_V2_TOTAL_SKU_DATA",
  SET_GOALS_V2_DATA: "SET_GOALS_V2_DATA",
  SET_GOALS_V2_LAUNCHER_ID: "SET_GOALS_V2_LAUNCHER_ID",
  SET_GOALS_V2_LIST_DATA: "SET_GOALS_V2_LIST_DATA",
  SET_GOALS_V2_LIST_FETCH_IN_PROGRESS: "SET_GOALS_V2_LIST_LOADING_STATUS",
  SET_GOALS_V2_FETCH_SUCCESSFUL: "SET_GOALS_V2_FETCH_SUCCESSFUL",
  SET_GOALS_V2_FETCH_ERROR_MSG: "SET_GOALS_V2_FETCH_ERROR_MSG",
  UPDATE_GOALS_V2_BID_SETTING_DATA: "UPDATE_GOALS_V2_BID_SETTING_DATA",
  UPDATE_GOALS_V2_SKU_SELECTION_DATA: "UPDATE_GOALS_V2_SKU_SELECTION_DATA",
  MERGE_ALL_GOALS_DATA_PERFORMANCE: "MERGE_ALL_GOALS_DATA_PERFORMANCE",
  UPDATE_GOALS_V2_LIST_DATA: "UPDATE_GOALS_V2_LIST_DATA",
  SET_GOAL_V2_CREATIVES_TEMPLATES_DATA: "SET_GOAL_V2_CREATIVES_TEMPLATES_DATA",
  UPDATE_REPORTING_DATA_FOR_X_DAYS_V2: "UPDATE_REPORTING_DATA_FOR_X_DAYS_V2",
  SET_GOAL_V2_AD_CREATION_TEMPLATES_DATA: "SET_GOAL_V2_AD_CREATION_TEMPLATES_DATA",
  SET_CAMPAIGN_TARGETING_DATA: "SET_CAMPAIGN_TARGETING_DATA",
  SET_CAMPAIGN_GEO_TARGETING_DATA: "SET_CAMPAIGN_GEO_TARGETING_DATA",
  SET_CAMPAIGN_AUDIENCE_TARGETING_DATA: "SET_CAMPAIGN_AUDIENCE_TARGETING_DATA",
  UPDATE_GOAL_V2_AD_CREATION_TEMPLATES_DATA: "UPDATE_GOAL_V2_AD_CREATION_TEMPLATES_DATA",
  SET_GOALS_V2_KEYWORDS_COUNT: "SET_GOALS_V2_KEYWORDS_COUNT",
  SET_GOALS_V2_KEYWORD_BIDDING_DATA: "SET_GOALS_V2_KEYWORD_BIDDING_DATA",
  UPDATE_GOALS_V2_KEYWORD_BIDDING_DATA: "UPDATE_GOALS_V2_KEYWORD_BIDDING_DATA",
  SET_GOALS_V2_NEGATIVE_KEYWORDS_COUNT: "SET_GOALS_V2_NEGATIVE_KEYWORDS_COUNT",
  SET_IS_FIRST_CAMPAIGN_LAUNCHED: "SET_IS_FIRST_CAMPAIGN_LAUNCHED",
  SET_GOALS_V2_ARE_ALL_GOALS_FETCHED: "SET_GOALS_V2_ARE_ALL_GOALS_FETCHED",
  SET_IS_FIRST_CALL_IN_PROGRESS_GOALS_V2: "SET_IS_FIRST_CALL_IN_PROGRESS_GOALS_V2",
  MAKE_GOALV2_EMPTY: "MAKE_GOALV2_EMPTY",
  UPDATE_GOALS_OFFSET_GOALS_V2: "UPDATE_GOALS_OFFSET_GOALS_V2",
  SET_IS_FIRST_CALL_SUCCESSFUL_GOALS_V2: "SET_IS_FIRST_CALL_SUCCESSFUL_GOALS_V2",
  SET_GOALS_V2_SORTED_IDS: "SET_GOALS_V2_SORTED_IDS",
  MAKE_GOALV2_SORTED_EMPTY: "MAKE_GOALV2_SORTED_EMPTY",
  SET_FUNNEL_METRICS_DATA_FOR_WB: "SET_FUNNEL_METRICS_DATA_FOR_WB",
  SET_GOALS_V2_KEYWORD_BIDDING_FETCH_STATUS: "SET_GOALS_V2_KEYWORD_BIDDING_FETCH_STATUS",
  SET_SELECTED_NETWORK_LIST: "SET_SELECTED_NETWORK_LIST",
  PERFORMANCE_GOAL_LIST_NOTIFICATION: "PERFORMANCE_GOAL_LIST_NOTIFICATION",
  PERFORMANCE_GOAL_LIST_NOTIFICATION_UPDATE: "PERFORMANCE_GOAL_LIST_NOTIFICATION_UPDATE",
};
