import React, {useCallback, useEffect, useState, useRef} from "react";
import {connect} from "react-redux";
import {Trans,withTranslation} from "react-i18next";
import {differenceInDays, differenceInMinutes} from "date-fns/esm"
import {ModalWindow} from "@onlinesales-ai/modal-window-v2";
import Rating from "./ratingWidget";
import {setLSItem, getLSItem, populateEvent, pendoTrackEvent} from "@onlinesales-ai/util-methods-v2";
import "./index.less";
import {Modal} from "react-bootstrap";

function fireIntercomEvents(action, metaData) {
  populateEvent("SHOPS||APP", "APP||CLIENT_RATING||" + action, metaData);
}

const ClientRating = ({
  clientId,
  userInfo,
  isOnOnboardingPage,
  isMobile,
  triggerDetails,
  title,
  showToastMessage
}) => {
  const [askRating, setAskRating] = useState(false);
  const triggeredRatingInfo = useRef(null);

  useEffect(() => {
    if(!askRating){
      triggeredRatingInfo.current = null;
    } else {
      pendoTrackEvent("MONETISE||CLIENT_RATING||MODAL_SHOW", {
        ...(triggeredRatingInfo.current || {}),
      });
    }
  }, [askRating]);

  useEffect(() => {
    startUserSession();
    if(isMobile) {
      setTimeout(() => {
        checkForRating();
      }, 60 * 1000);
    }
    else{
      document.addEventListener("mouseleave", onPlatformLeave);
    }

    return () => {
      if(!isMobile) {
        document.removeEventListener("mouseleave", onPlatformLeave);
      }
    };
  }, []);

  const onPlatformLeave = useCallback((event) => {
    if (event.clientY <= 0 || event.clientX <= 0 ||
      (event.clientX >= window.innerWidth || event.clientY >= window.innerHeight)) {
      checkForRating();
    }
  }, [triggerDetails]);

  const setRatingInfo = (ratingInfo = {}) => {
    setLSItem("ratingInfo", JSON.stringify(ratingInfo));
  };

  const getRatingInfo = () => {
    let ratingInfo = getLSItem("ratingInfo") || "{}";
    try{
      ratingInfo = JSON.parse(ratingInfo);
    }
    catch(error){
      ratingInfo = {};
    }

    return ratingInfo;
  };

  const startUserSession = () => {
    let ratingInfo = getRatingInfo();
    ratingInfo.sessionStartTime = (new Date()).getTime();
    if(!ratingInfo.sessionStreak){
      ratingInfo.sessionStreak = 0;
    }
    ratingInfo.sessionStreak++;

    setRatingInfo(ratingInfo);
  };

  const checkForRating = () => {
    let ratingInfo = getRatingInfo();
    const currentTime = (new Date()).getTime();
    const {daysFrequency, streakThreshold, sessionInMinutesThreshold} = triggerDetails;
    if( !isOnOnboardingPage &&
      (!ratingInfo.lastShownTimeStamp || differenceInDays(currentTime, ratingInfo.lastShownTimeStamp) >= daysFrequency) &&
      (
        ratingInfo.sessionStreak >= streakThreshold ||
        (ratingInfo.sessionStartTime && differenceInMinutes(currentTime, ratingInfo.sessionStartTime) >= sessionInMinutesThreshold)
      )){
      triggerRating();
    }
  };

  const triggerRating = () => {
    if(askRating){
      return;
    }

    let currentRatingInfo = getRatingInfo();
    let ratingInfo = {
      ...currentRatingInfo,
      lastShownTimeStamp: (new Date()).getTime(),
      sessionStreak: 0
    };

    triggeredRatingInfo.current = currentRatingInfo;
    setRatingInfo(ratingInfo);

    setAskRating(true);

    fireIntercomEvents("RATING_TRIGGERED", {
      userInfo: userInfo,
      clientId: clientId,
      triggeredRatingInfo: currentRatingInfo
    });
  };

  const closePopUp = () => {
    setAskRating(false);
  };


  if(askRating){
    return (
      <ModalWindow
        containerClass="rating-modal-container"
        onModalDestroy={closePopUp}
        isShow={askRating}
        modalProps={{
          backdrop: "static",
        }}>
        <Modal.Header className="rating-close" closeButton>
        <span>
          <Trans>{title}</Trans>
        </span>
        </Modal.Header>
        <div className="rating-wrapper">
          <Rating
            jobDoneCallback={closePopUp}
            triggeredRatingInfo={triggeredRatingInfo.current}
            showToastMessage = {showToastMessage}
          />
        </div>
      </ModalWindow>
    );
  }
  else{
    return null;
  }
};

ClientRating.defaultProps = {
  userInfo: {},
  triggerDetails: {
    daysFrequency: 7,
    streakThreshold: 5,
    sessionInMinutesThreshold: 15
  },
  title: "Please rate your session",
  isOnOnboardingPage: false
};

const mapStateToProps = (state) => {
  const {ratingProps = {}} = state.DomainConfig;
  return {
    userInfo: state.Application.userInfo,
    clientId: state.Application.clientId,
    isMobile: state.Application.isMobile,
    ...ratingProps
  };
};

export default connect(mapStateToProps)(withTranslation()(ClientRating));
