import clientAchievementReducer from "@onlinesales-ai/app-v2/clientAchievement/reducer";

const initialState = {
  data: {},
  isLoading: false,
  fetchError: false,
};

const reducer = (state = initialState, action) => {
  return clientAchievementReducer(state, action, initialState);
};

export default {
  ClientAchievement: reducer,
};
