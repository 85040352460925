import React, { useMemo } from "react";
import { connect } from "react-redux";

import { FixLayout } from "@onlinesales-ai/fix-layout-v2";
import { Button } from "@onlinesales-ai/button-v2";
import { Text } from "@onlinesales-ai/label-v2";

import "./index.less";

const HelpSectionIframe = ({ iframeUrl, fragmentIdentifier, headerTitle, isShowBackButton, preferredTheme }) => {
  const iframeSrc = useMemo(() => {
    return preferredTheme
      ? `${iframeUrl}?theme=${preferredTheme}${fragmentIdentifier ? `#${fragmentIdentifier}` : ""}`
      : `${iframeUrl}${fragmentIdentifier ? `#${fragmentIdentifier}` : ""}`;
  }, [iframeUrl, preferredTheme, fragmentIdentifier]);

  return (
    <FixLayout className="help-section-iframe-wrapper">
      <FixLayout.Header>
        <div className="d-align-center gap-10">
          {isShowBackButton ? (
            <Button
              onClick={() => window?.history?.back()}
              link
              icon="icon-angle-left"
              className="width-fit btnheight"
            >
              Back
            </Button>
          ) : null}
          <Text size="extraLarge" weight="semiBold" type="secondary">
            {headerTitle}
          </Text>
        </div>
      </FixLayout.Header>
      <FixLayout.Body className="help-section-iframe-body-wrapper">
        <iframe
          title={headerTitle || "link"}
          src={iframeSrc}
          allow="clipboard-write"
        />
      </FixLayout.Body>
    </FixLayout>
  );
};

const mapStateToProps = (state) => {
  const { preferredTheme } = state.Application;

  return {
    preferredTheme,
  };
};

HelpSectionIframe.defaultProps = {
  iframeUrl: "",
  headerTitle: "",
};

export default connect(mapStateToProps)(HelpSectionIframe);
