import React from "react";
import { connect } from "react-redux";
import _get from "lodash/get";
import _isEqual from "lodash/isEqual";
import { withTranslation, Trans } from "react-i18next";

import { OSHOCWithUtilities } from "@onlinesales-ai/os-hoc-with-utilities-v2";
import { FormWrapper } from "@onlinesales-ai/form-components-v2";
import { Button } from "@onlinesales-ai/button-v2";
import AsyncImage from "@onlinesales-ai/async-image-v2";
import WithTooltip from "@onlinesales-ai/tooltip-v2";
import { ImagePreviewModal } from "@onlinesales-ai/utils-components-v2";
import { Text } from "@onlinesales-ai/label-v2";

import MediaUploadWithProgress from "../../mediaUpload/MediaUploadWithProgress";
import "./index.less";

class FileUpload extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      previewModal: {
        isShow: false,
      },
    };

    this.validate(this.getValue());
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const newValue = this.getValue(nextProps);

    if (!_isEqual(newValue, this.getValue())) {
      this.validate(newValue);
    }
  }

  getValue = (props) => {
    const { dataKey, formValues } = props || this.props;
    return _get(formValues, dataKey, []);
  };

  validate = (value) => {
    const { onError, dataKey, validations = [], title } = this.props;
    let errorMsg = null;
    const filteredValue = value?.filter((item) => {
      return !!item;
    });

    for (let i = 0; i < validations.length; i++) {
      if (!validations[i].type || !validations[i].msg) {
        continue;
      }

      switch (validations[i].type) {
        case "min":
          {
            if (filteredValue?.length < validations[i].min) {
              errorMsg = validations[i].msg;
            }
          }
          break;
        case "max":
          {
            if (filteredValue?.length > validations[i].max) {
              errorMsg = validations[i].msg;
            }
          }
          break;
        default:
          break;
      }

      if (errorMsg) {
        errorMsg = errorMsg.replace("__FIELD_TITLE__", title.toLowerCase());
        break;
      }
    }

    onError({ [dataKey]: errorMsg });
  };

  getOverlayDOMNode = (key, value) => {
    return (
      <>
        <div className={"media-upload-btn"}>
          {value ? (
            <div className="media-image">
              <AsyncImage imgSrc={value} />
              <div
                className="delete-icon icon icon-trash-o"
                onClick={() => this.onUploadMedia(key, null)}
              />
            </div>
          ) : (
            <div className="media-form-btn">
              <span className="add-btn icon icon-add-plus" />
            </div>
          )}
        </div>
      </>
    );
  };

  onUploadError = (errorMsg) => {
    this.props.showToastMessage({
      type: "ERROR",
      messageToDisplay: errorMsg,
      actionButtonLabel: null,
      toastDuration: 5000,
    });
  };

  onUploadMedia = (url) => {
    const { onChange, dataKey } = this.props;
    const value = this.getValue();
    onChange({
      [dataKey]: [...value, url],
    });

    return Promise.resolve();
  };

  onRemoveFile = (index) => {
    const { onChange, dataKey } = this.props;
    const newValue = [...this.getValue()];

    newValue.splice(index, 1);

    onChange({
      [dataKey]: newValue,
    });
  };

  togglePreviewModal = ({ isShow, ...rest }) => {
    this.setState({
      previewModal: {
        isShow,
        ...rest,
      },
    });
  };

  getFileThumbnail = (fileUrl = "", isImageFile) => {
    let thumbNailDom = null;

    if (fileUrl.endsWith(".pdf")) {
      thumbNailDom = <span className="icon icon-file-pdf-o" />;
    } else if (isImageFile) {
      thumbNailDom = <AsyncImage className="thumbnail-img" imgSrc={fileUrl} />;
    } else {
      thumbNailDom = <span className="icon icon-file-o" />;
    }

    return thumbNailDom;
  };

  getFileDetailsTile = ({ fileUrl = "", index }) => {
    const fileUrlParts = fileUrl.split("/");
    const fileName = fileUrlParts[fileUrlParts.length - 1] || "";
    const { fileNameClass, imageFormats } = this.props;
    const isImageFile = imageFormats.some((format) => fileUrl.endsWith(format));

    return (
      <div className="file-details-container d-align-center">
        <div className="d-align-between w100p">
          <div className="d-align-center width-flex-1">
            <span
              className="thumbnail"
              onClick={() =>
                isImageFile && this.togglePreviewModal({ isShow: true, list: [fileUrl], title: fileName })
              }
            >
              {this.getFileThumbnail(fileUrl, isImageFile)}
              {isImageFile && (
                <span className="overlay-icon">
                  <span className="icon icon-preview" />
                </span>
              )}
            </span>
            <Text showEllipsis className={`file-name ml-2 width-flex-1 ${fileNameClass}`}>{fileName}</Text>
          </div>
          <span className="actions-container ml-3 cursor-pointer error-color">
            <span className="icon icon-close1" onClick={() => this.onRemoveFile(index)} />
          </span>
        </div>
      </div>
    );
  };

  render() {
    const { previewModal = {} } = this.state;

    const {
      // FormWrapper props
      labelColumns,
      formGroupClassName,
      guidText,
      guidElement,
      apiError,
      warning,
      title,
      titleTooltip,
      titleGuidText,

      // rest props
      dataKey,
      isEditable,
      showErrors,
      formErrors,
      t,
      clientId,
      maxAllowedFiles,
      fileExtToValidates,
      uploadEndPoint,
      overlayDomText,
      ...restProps
    } = this.props;

    const value = this.getValue();

    return (
      <FormWrapper
        labelColumns={labelColumns}
        formGroupClassName={formGroupClassName}
        hasError={showErrors && formErrors[dataKey]}
        error={formErrors[dataKey]}
        apiError={apiError}
        guidElement={guidElement}
        guidText={guidText}
        warning={warning}
        title={title}
        titleTooltip={titleTooltip}
        titleGuidText={titleGuidText}
        isEditable={isEditable}
      >
        <div className="media-file-upload-container file-upload-addons">
          <ImagePreviewModal
            title="Preview"
            {...previewModal}
            onClose={() => this.togglePreviewModal({ isShow: false })}
          />
          {value.length < maxAllowedFiles && (
            <MediaUploadWithProgress
              key={value.length}
              sampleFileText=""
              accept={fileExtToValidates.join(",")}
              fileExtToValidates={fileExtToValidates}
              uploadEndPoint={uploadEndPoint}
              isUploadOnCloudinary={false}
              onUpload={this.onUploadMedia}
              overlayDomText={overlayDomText}
            >
              {({ onClick }) => {
                return (
                  <Button type="default" icon="icon-upload1" onClick={onClick}>
                    Upload
                  </Button>
                );
              }}
            </MediaUploadWithProgress>
          )}
          <div className="uploaded-file-list">
            {value.map((currentValue, index) => {
              return this.getFileDetailsTile({ fileUrl: currentValue, index });
            })}
          </div>
        </div>
      </FormWrapper>
    );
  }
}

FileUpload.defaultProps = {
  fileExtToValidates: [".pdf", ".png", ".jpeg"],
  imageFormats: [".png", ".jpeg", ".jpg", ".svg", ".webp", ".gif"],
  uploadEndPoint: "/onboardingService/uploadFeed",
  overlayDomText: "Upload file",
  dataKey: null,
  title: "",
  labelColumns: 4,
  formGroupClassName: "form-component-file-uploader",
  maxAllowedFiles: 1,
  fileNameClass: "",
  validations: [
    {
      type: "min",
      min: 1,
      msg: "Please upload __FIELD_TITLE__",
    },
  ],
};

const mapStateToProps = (state) => {
  const { clientId } = state.Application || {};

  return {
    clientId,
  };
};

export default connect(mapStateToProps, null)(withTranslation()(OSHOCWithUtilities(FileUpload)));
