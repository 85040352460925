import React, { Component } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { Trans } from "react-i18next";
import { ConfirmationModal } from "@onlinesales-ai/modal-window-v2";
import WithTooltip from "@onlinesales-ai/tooltip-v2";
import "./index.less";

class StatusDropDown extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      showModal: false,
      status: "",
      event: {},
    };

    this.onModalDialogSuccess = this.onModalDialogSuccess.bind(this);
    this.onModalDialogCancel = this.onModalDialogCancel.bind(this);
    this.handleStatusDropDown = this.handleStatusDropDown.bind(this);
  }

  handleSelect(status, event) {
    const { onStatusChange, showModal } = this.props;
    if (showModal) {
      this.setState({
        showModal: true,
        status,
        event,
      });
    } else {
      onStatusChange(status, event);
    }
  }

  getStatusConfigFromStatus(status, disabled) {
    let statusConfig = {
      className: "icon icon-review",
      displayName: "Unknown Status",
    };
    switch (status) {
      case "ACTIVE":
        {
          statusConfig = {
            className: "icon icon-circle active-green",
            displayName: "Active",
            passiveName: "Activate",
            showNameWithIcon: true,
            tooltipText: !disabled ? "Campaign Active, Click to Pause" : "Campaign Active",
          };
        }
        break;
      case "PAUSED":
      case "PAUSE":
        {
          statusConfig = {
            className: "icon icon-circle paused-red",
            displayName: "Paused",
            passiveName: "Pause",
            showNameWithIcon: true,
            tooltipText: !disabled ? "Campaign Paused, Click to Activate" : "Campaign Paused",
          };
        }
        break;
      case "DRAFT":
        {
          statusConfig = {
            className: "icon icon-draft",
            displayName: "Draft",
            passiveName: "Draft",
            tooltipText: "Campaign Not Active",
          };
        }
        break;
      case "LAUNCH_INPROGRESS":
        {
          statusConfig = {
            className: "icon icon-review",
            displayName: "Launch In Progress",
            passiveName: "Launch In Progress",
          };
        }
        break;
      case "REVIEW_COMPLETED":
        {
          statusConfig = {
            className: "icon icon-review",
            displayName: "Launch In Progress",
          };
        }
        break;
      case "DRAFT_REJECT":
        {
          statusConfig = {
            className: "icon icon-pause1",
            displayName: "Pause",
          };
        }
        break;
      default:
        break;
    }

    return statusConfig;
  }

  getConfirmationModalDOM() {
    let modalMsg;
    const btnText = "Yes";
    const { showModal, status } = this.state;
    const { entity = "", name = "", disabled } = this.props;

    if (showModal) {
      modalMsg = "Just to confirm, would you like to ";

      const statusConfig = this.getStatusConfigFromStatus(status, disabled);

      if (statusConfig.displayName !== "Unknown Status") {
        modalMsg += `${statusConfig.passiveName.toLowerCase()} ${entity}`;
      }

      if (name) {
        modalMsg += ` ${name}`;
      }

      modalMsg += "?";

      return ReactDOM.createPortal(
        <div className="do-not-expand">
          <ConfirmationModal
            isShow
            title={modalMsg}
            actionBtnText={btnText}
            rightBtnText="No"
            actionBtnCallback={this.onModalDialogSuccess}
            rightBtnCallback={this.onModalDialogCancel}
          />
        </div>,
        document.body,
      );
    }

    return null;
  }

  onModalDialogSuccess() {
    const { onStatusChange } = this.props;
    const { status, event } = this.state;

    onStatusChange(status, event);

    this.setState({
      showModal: false,
      status: "",
      event: {},
    });
  }

  onModalDialogCancel() {
    this.setState({
      showModal: false,
      status: "",
      event: {},
    });
  }

  handleStatusDropDown() {
    const { dropdownList, noActionCallback } = this.props;

    if (dropdownList.length === 0) {
      if (typeof noActionCallback === "function") {
        noActionCallback();
      }
    }
  }

  renderList() {
    const { dropdownList } = this.props;

    return dropdownList.map((key, index) => {
      const statusConfig = this.getStatusConfigFromStatus(key);
      if (statusConfig) {
        return (
          <li key={key + index} onClick={this.handleSelect.bind(this, key)}>
            <a>
              <Trans>{statusConfig.displayName}</Trans>
            </a>
          </li>
        );
      }
    });
  }

  render() {
    let { showDropdown = true } = this.props;
    const {
      statusClassName,
      disabled,
      effectiveStatus: propsEffectiveStatus,
      dropdownList = [],
      doNotShowDropdownFor1Option,
      status,
      tooltipText,
      tooltipPlacement,
      effectiveStatusMessage,
    } = this.props;

    const statusConfig = this.getStatusConfigFromStatus(status);
    let title = statusConfig.displayName;
    let longTooltip = "";
    const effectiveStatus = propsEffectiveStatus ? propsEffectiveStatus.toUpperCase() : null;
    const dropdownListLength = dropdownList.length;

    if (statusClassName) {
      statusConfig.className = statusClassName;
    }

    /*
            added  effectiveStatusMessage[effectiveStatus] condition because
            if status is PAUSED and effective status is ACTIVE then its a valid and
            should be allowed to be actioned.
         */
    if (effectiveStatus && effectiveStatus !== status && effectiveStatusMessage[effectiveStatus]) {
      title = effectiveStatusMessage[effectiveStatus];
      showDropdown = false;
      statusConfig.className += " disabled";
      longTooltip += "long-tooltip";
    } else {
      title = statusConfig.tooltipText;
    }

    if (tooltipText) {
      title = tooltipText;
    }

    return (
      <div className="status-dropdown-container do-not-expand">
        {this.getConfirmationModalDOM()}
        <WithTooltip title={title} placement={tooltipPlacement}>
          <div
            className={`dropdown-toggle status-icon-container ${longTooltip}`}
            data-toggle="dropdown"
            onClick={
              dropdownListLength === 1 && doNotShowDropdownFor1Option
                ? this.handleSelect.bind(this, dropdownList[0])
                : this.handleStatusDropDown
            }
          >
            <span
              className={`status-icon ${statusConfig.className} ${disabled ? "disabled" : ""}`}
              key={statusConfig.displayName}
            >
              {statusConfig.showNameWithIcon && (
                <Trans>
                  <span className="status-displayname">{statusConfig.displayName}</span>
                </Trans>
              )}
            </span>
          </div>
        </WithTooltip>
        {showDropdown
          ? (dropdownListLength === 1 ? !doNotShowDropdownFor1Option : dropdownListLength > 0) && (
              <ul className="dropdown-menu">{this.renderList()}</ul>
            )
          : null}
      </div>
    );
  }
}

StatusDropDown.propTypes = {
  status: PropTypes.string,
  statusClassName: PropTypes.string,
  dropdownList: PropTypes.arrayOf(PropTypes.string),
  onStatusChange: PropTypes.func,
  entity: PropTypes.string,
  name: PropTypes.string,
  showModal: PropTypes.bool,
  noActionCallback: PropTypes.func,
  tooltipPlacement: PropTypes.string,
  effectiveStatus: PropTypes.string,
};

StatusDropDown.defaultProps = {
  status: "DRAFT",
  statusClassName: undefined,
  dropdownList: [],
  onStatusChange: () => {},
  entity: "",
  name: "",
  showModal: false,
  noActionCallback: () => {},
  tooltipPlacement: "top",
  effectiveStatus: null,
  effectiveStatusMessage: {},
};

export default StatusDropDown;
