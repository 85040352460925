const styleToCopy = [
  "wordWrap",
  "whiteSpace",
  "direction", // RTL support
  "boxSizing",
  "width", // on Chrome and IE, exclude the scrollbar, so the mirror div wraps exactly as the textarea does
  "height",
  "overflowX",
  "overflowY", // copy the scrollbar for IE

  "borderTopWidth",
  "borderRightWidth",
  "borderBottomWidth",
  "borderLeftWidth",
  "borderStyle",

  "paddingTop",
  "paddingRight",
  "paddingBottom",
  "paddingLeft",

  // https://developer.mozilla.org/en-US/docs/Web/CSS/font
  "fontStyle",
  "fontVariant",
  "fontWeight",
  "fontStretch",
  "fontSize",
  "fontSizeAdjust",
  "lineHeight",
  "fontFamily",

  "textAlign",
  "textTransform",
  "textIndent",
  "textDecoration", // might not make a difference, but better be safe

  "letterSpacing",
  "wordSpacing",

  "tabSize",
  "MozTabSize",
];

export const getCaretPosition = (input) => {
  const { selectionStart, selectionEnd } = input;

  // create dummy div
  const dummyDiv = document.createElement("div");
  dummyDiv.textContent = input.value || "d";

  // Apply Style to dummy div
  const style = getComputedStyle(input);
  styleToCopy.forEach((key) => {
    dummyDiv.style[key] = style[key];
  });
  dummyDiv.style.overflow = "auto";
  dummyDiv.style.width = `${input.offsetWidth}px`;
  dummyDiv.style.height = `${input.offsetHeight}px`;
  dummyDiv.style.position = "absolute";
  dummyDiv.style.left = `${input.offsetLeft}px`;
  dummyDiv.style.top = `${input.offsetTop}px`;

  document.body.appendChild(dummyDiv);

  const range = document.createRange();
  range.setStart(dummyDiv.firstChild, selectionStart);
  range.setEnd(dummyDiv.firstChild, selectionEnd);

  const selection = document.getSelection();
  selection.removeAllRanges();
  selection.addRange(range);

  const rect = range.getBoundingClientRect();
  document.body.removeChild(dummyDiv);

  input.selectionStart = selectionStart;
  input.selectionEnd = selectionEnd;
  input.focus();

  return {
    x: rect.left - input.scrollLeft,
    y: rect.top - input.scrollTop,
  };
};
