import React from "react";
import { connect } from "react-redux";

import { shouldRenderComponent } from "@onlinesales-ai/util-methods-v2";
import CallButton from "./callButton";
import ReportBug from "./reportBug";
import ZohoTicket from "./zohoTicket";
import Tourmodal from "./tourModal";

import "./index.less";

const Components = {
  callButton: CallButton,
  reportBug: ReportBug,
  zohoTicket: ZohoTicket,
  platformTour: Tourmodal,
};

const AppActions = ({
  appActionConfig,
  actionList,
  isMobile,
  clientId,
  postOnboardingData,
  location,
  hasBottomNav,
}) => {
  return (
    <div
      className={`global-app-actions ${appActionConfig?.className || ""} ${
        isMobile && hasBottomNav ? "bottom-nav" : ""
      }`}
    >
      {actionList.map((action) => {
        const { routesToAllow = [] } = appActionConfig?.[action] || {};
        const validPath =
          !routesToAllow?.length ||
          (location.pathname && routesToAllow.some((route) => location.pathname.includes(route)));

        if (shouldRenderComponent(appActionConfig?.[action]) && validPath) {
          const Comp = Components[action];

          if (Comp) {
            return (
              <Comp
                isMobile={isMobile}
                clientId={clientId}
                {...appActionConfig[action]}
                postOnboardingData={postOnboardingData}
              />
            );
          }
        }

        return null;
      })}
    </div>
  );
};

AppActions.defaultProps = {
  actionList: ["callButton", "reportBug", "platformTour", "zohoTicket"],
  hasBottomNav: false,
};

const mapStateToProps = (state) => {
  const { isMobile, clientId } = state.Application || {};
  const { appLevelTabs } = state.DomainConfig || {};

  return {
    isMobile,
    clientId,
    hasBottomNav: !!appLevelTabs?.length,
  };
};

export default connect(mapStateToProps)(AppActions);
