import React, {useState} from "react";
import {connect} from "react-redux";
import {withTranslation, Trans, useTranslation} from "react-i18next";
import {Carousel} from "react-bootstrap";
import {Button} from "@onlinesales-ai/button-v2";
import {ShopsUIService} from "@onlinesales-ai/services-v2";
import {formComponents} from "@onlinesales-ai/form-components-v2";
import {BaseClient} from "@onlinesales-ai/services-v2";
import Textarea from "@onlinesales-ai/form-components-v2/src/components/textarea";
import "./index.less";

const RatingWidget = ({
  clientId,
  userInfo,
  shopInfo,
  content,
  jobDoneCallback,
  triggeredRatingInfo,
  ratingTitle,
  showToastMessage,
  storeType,
  successTost

}) => {
  const [screenIndex, setScreenIndex] = useState(0);
  const [rating, setRating] = useState(null);
  const [tempRating, setTempRating] = useState(null);
  const [ratingFeedback, setRatingFeedback] = useState("");
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);
  const [showFeedBackForm, setShowFeedBackForm] =useState(false);
  const [selectedPills, setSelectedPills] = useState([]);
  const {t} = useTranslation();

  const onStarOver = (ratingValue) => {
    if (!showFeedBackForm){
      setTempRating(rating);
      setRating(ratingValue);
    }
  };

  const onStarOut = () => {
    setRating(tempRating);
  };

  const onStarClick = (ratingValue) => {
    setTempRating(ratingValue);
    setRating(ratingValue);
    setShowFeedBackForm(true)
    setSelectedPills([]);

  };

  const onSubmit = async (event) => {
    event.preventDefault();
    let {name: clientName} = shopInfo || {};
    let payload = {
      clientId,
      userId: userInfo?.id,
      ratingCount: rating + 1,
      description: JSON.stringify({ selectedPills, note: ratingFeedback }),
      userEmail: userInfo?.email,
      contactNo: userInfo?.contactNo,
      clientName,
      storeType,
      metadata: JSON.stringify({
        triggeredRatingInfo,
      }),
    };

    setIsSubmitInProgress(true);

    try{
      await ShopsUIService.postUserOSRating(payload, "application");
      showToastMessage({
        messageToDisplay: successTost,
        type: "SUCCESS",
        toastDuration: 5000,
      });
    }
    catch(error){
      let errorMsg = BaseClient.getErrorMessage();
      if(typeof error === "object" && error.errorMsg){
        errorMsg = error.errorMsg
      }
      showToastMessage({
        messageToDisplay: errorMsg,
        type: "ERROR",
        toastDuration: 5000,
      });
    }
    finally {
      setIsSubmitInProgress(false);
      jobDoneCallback()
    }
  };

  const renderRatingStarsScreen = () => {
    const stars = [];
    for(let i = 0; i < 5; i++) {
      let klass = 'star-rating__star';

      if (rating >= i && rating != null) {
        klass += ' is-selected';
      }

      stars.push(
        <label
          key = {i}
          className={klass}
          onClick={() => onStarClick(i)}
          onMouseOver={() => onStarOver(i)}
          onMouseOut={onStarOut}>
          ★
        </label>
      );
    }

    return (
      <div className="rating-stars-wrapper">
        <label className="info-label">
          <Trans>{ratingTitle}</Trans>
        </label>
        <div className="star-rating">{stars}</div>
        {showFeedBackForm && (
          <div className="user-feedback-wrapper">
            <div className="rating-feedback-wrapper">
              <label className="info-label">
                <Trans>{Array.isArray(content) && content[rating]?.questionTitle}</Trans>
              </label>
              {getPills()}
              <Textarea
                onError={() => {}}
                formErrors={{}}
                isEditable={true}
                placeholder={t("Write Feedback")}
                formValues={{ ratingFeedback }}
                dataKey={"ratingFeedback"}
                labelColumns={12}
                formGroupClassName={"user-feedback-form-group"}
                onChange={({ ratingFeedback }) => setRatingFeedback(ratingFeedback)}
              />
              <div className="cta-container">
                <Button
                  
                  isLoading={isSubmitInProgress}
                  disabled={isSubmitInProgress}
                  onClick={onSubmit}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };
  
  const selectPills = (value) => {
    if(selectedPills.includes(value)){
      setSelectedPills(selectedPills.filter((e) => e !== value));
    }else{
      setSelectedPills([...selectedPills, value]);
    }
  };

  const getPills = () => {
    let pillsToShow = content?.[rating]?.pills || [];
 
    return (
      <div className={`improvement-pills star-${rating+1}`}>
        {pillsToShow.map((value, index) => {
          return (
            <span key={index} className={`pills${selectedPills.includes(value) ? " selected" : ""}`} onClick={(e) => selectPills(value)}>
              <Trans>{value}</Trans>
            </span>
          );
        })}
      </div>
    );
  };

  return (
    <div className = "rating-widget-container">
      <Carousel
        indicators={false}
        controls={false}
        activeIndex={screenIndex}>
        <Carousel.Item>
          {renderRatingStarsScreen()}
        </Carousel.Item>
      </Carousel>

    </div>
  );
};

RatingWidget.defaultProps = {
  jobDoneCallback: () => {},
  triggeredRatingInfo: {},
  successTost:"Your feedback has been submitted successfully",
  content:[
    {
      questionTitle:"What went wrong?",
      pills:["Dashboard","Campaign","Leads","Performance","Aesthetics","Other"],
    },
    {
      questionTitle:"What went wrong?",
      pills:["Dashboard","Campaign","Leads","Performance","Aesthetics","Other"],
    },
    {
      questionTitle:"What went wrong?",
      pills:["Dashboard","Campaign","Leads","Performance","Aesthetics","Other"],
    },
    {
      questionTitle:"Almost there, what can we improve?",
      pills:["Dashboard","Campaign","Leads","Performance","Aesthetics","Other"],
    },
    {
      questionTitle:"Awesome, what did you like?",
      pills:["Dashboard","Campaign","Leads","Performance","Aesthetics","Other"],
    }
  ]
};

const mapStateToProps = (state) => {
  const {ratingProps = {}, storeType} = state.DomainConfig;
  return {
    userInfo: state.Application.userInfo,
    shopInfo: state.Application.shopInfo,
    clientId: state.Application.clientId,
    storeType,
    ...ratingProps
  };
};

export default connect(mapStateToProps)(withTranslation()(RatingWidget));
