import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { BaseClient, OSBillingServiceV2, OSBillingService } from "@onlinesales-ai/services-v2";
import PlatformEventManager from "@onlinesales-ai/event-manager-v2";
import { populateEvent, loadBankOpenScript } from "@onlinesales-ai/util-methods-v2";
import { fetchAndSetContactInfo as fetchAndSetContactInfoAction } from "@onlinesales-ai/app-v2/application";

function fireIntercomEvents(action, metaData) {
  populateEvent("APP", `PAYMENT||BANK_OPEN_LAYER_PAYMENT||${action}`, metaData);
}

class BankOpenLayerPayment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fetchOrderIdLoading: false,
    };

    this.orderData = null;
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (
      newProps.amount !== this.props.amount ||
      newProps.currency !== this.props.currency ||
      newProps.userInfo?.email !== this.props.userInfo?.email
    ) {
      this.orderData = null;
    }
  }

  async componentDidMount() {
    const { onError } = this.props;

    try {
      await loadBankOpenScript();
      fireIntercomEvents("BANK_OPEN_LAYER_LOADED");
    } catch (err) {
      fireIntercomEvents("BANK_OPEN_LAYER_LOAD_ERROR");
      onError(err);
    }
  }

  getPhoneNumber = (userInfo) => {
    const { countryDailingCode, marketplaceStoreDetails = {} } = this.props;
    let phone = userInfo.contact;

    // If no contact number is present add verfied/unverified contact number from authMetadata
    if (!phone && userInfo.authMetadataList) {
      userInfo.authMetadataList.forEach((authInfo = {}) => {
        if (authInfo.type === "MOBILE") {
          phone = authInfo.value;
        }
      });
    }

    if (!phone && marketplaceStoreDetails.mobile) {
      phone = marketplaceStoreDetails.mobile;
    }

    // Removing dialing code as gateway not accepting it.
    if (phone && phone.includes(countryDailingCode)) {
      phone = phone.substr(countryDailingCode.length);
    }

    return phone;
  };

  fetchOrderId = async () => {
    const { chargeAPICall, forceNewOrderId } = this.props;
    if (!forceNewOrderId && this.orderData) {
      return Promise.resolve(this.orderData);
    }

    const {
      userInfo = {},
      clientId,
      currency,
      amount,
      fireEventOnOrderId,
      useDummyEmail,
      fetchAndSetContactInfo,
      chargeExtraPayload,
      financeChargeAPICall,
    } = this.props;

    this.setState({
      fetchOrderIdLoading: true,
    });

    let userEmail = userInfo.email;

    let phone = this.getPhoneNumber(userInfo);

    if (!phone) {
      try {
        const userData = await fetchAndSetContactInfo();
        phone = this.getPhoneNumber(userData);
      } catch (err) {
        throw Error("Something went wrong. Please contact support.");
      }
    }

    if (!userEmail && useDummyEmail && phone) {
      userEmail = `${phone}@onlinesales.ai`;
    }

    let response = {};

    const customer_details = {
      first_name: userInfo?.name || "",
      last_name: "",
      email: userEmail,
      phone,
    };

    if (financeChargeAPICall) {
      await financeChargeAPICall({
        paymentOverrides: {
          metadata: {
            customer_details,
          },
        },
      });
    } else if (chargeAPICall) {
      response = await chargeAPICall(amount, { customer_details });
    } else {
      response = await OSBillingServiceV2.charge({
        clientId,
        email: userEmail,
        platform: "OS",
        amount,
        currency,
        metadata: {
          customer_details,
        },
        ...chargeExtraPayload,
      });
    }

    const { bank_open_order_id: orderId, bank_open_payment_token_id: token, transactionId } =
      response?.metadata || {};

    if ((!orderId || !token) && !response?.doNotInitPG) {
      throw Error("Bank Open Layer payment not supported please contact support.");
    }

    this.orderData = {
      orderId,
      token,
      transactionId,
      doNotInitPG: response.doNotInitPG || false,
    };

    this.setState({
      fetchOrderIdLoading: false,
    });

    fireIntercomEvents("ORDER_CREATED", response);

    if (fireEventOnOrderId && fireEventOnOrderId.length) {
      fireEventOnOrderId.forEach((event) => {
        PlatformEventManager.emit(event);
      });
    }

    return this.orderData;
  };

  wait = (time) => {
    return new Promise((resolve) => {
      setTimeout(resolve, time);
    });
  };

  checkIfTransactionIsAccepted = async (transactionId) => {
    if (!transactionId) {
      return Promise.reject({ errorMsg: BaseClient.getErrorMessage() });
    }

    const { clientId } = this.props;
    return new Promise(async (resolve, reject) => {
      try {
        let isAccepted = false;
        let retryCount = 10;

        while (true) {
          const statusResponse = await OSBillingService.fetchInfo({
            "selectors": [
              "transactionId",
              "status",
            ],
            "entityLevel": "CLIENT_CREDIT_LOG",
            "filters": [
              {
                "column": "clientId",
                "value": `${clientId}`,
                "operator": "EQUAL_TO",
              },
              {
                "column": "transactionId",
                "value": encodeURIComponent(transactionId),
                "operator": "EQUAL_TO",
              },
            ],
            "clientId": clientId,
          });

          if (statusResponse?.data?.[0]?.status) {
            if (statusResponse?.data?.[0]?.status === "ACCEPTED") {
              isAccepted = true;
            } else if (statusResponse?.data?.[0]?.status !== "PENDING") {
              retryCount = 0;
            }
          }

          retryCount--;

          if (isAccepted) {
            resolve();
            break;
          } else if (retryCount <= 0) {
            reject({ errorMsg: "Could not verify status of your transaction, Please contact support team if money is debited from your bank account." });
            break;
          } else {
            await this.wait(1000);
          }
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  openPaymentModal = async () => {
    const {
      onPaymentSuccess,
      onError,
      beforePayment,
      fireEventOnPaymentSuccess,
      accessKey,
      onDismissCallback
    } = this.props;

    try {
      await beforePayment();
    } catch (err) {
      return;
    }

    try {
      const orderDetails = await this.fetchOrderId();

      if (!orderDetails?.doNotInitPG) {
        window.Layer?.checkout(
          {
            token: orderDetails.token,
            accesskey: accessKey,
          },
          async (response) => {
            if (response.status === "captured") {
              try {
                this.setState({
                  fetchOrderIdLoading: true,
                });
                await this.checkIfTransactionIsAccepted(orderDetails?.transactionId);
              } catch (err) {
                this.setState({
                  fetchOrderIdLoading: false,
                });
                onError(err?.errorMsg);
                return;
              } finally {
                this.setState({
                  fetchOrderIdLoading: false,
                });
              }

              // response.payment_token_id
              // response.payment_id
              // reset order data after successfull payment
              this.orderData = null;

              fireIntercomEvents("PAYMENT_SUCCESS", JSON.stringify(orderDetails));
              if (fireEventOnPaymentSuccess && fireEventOnPaymentSuccess.length) {
                fireEventOnPaymentSuccess.forEach((event) => {
                  PlatformEventManager.emit(event);
                });
              }

              onPaymentSuccess(response);
            } else if (response.status === "failed") {
              throw new Error(BaseClient.getErrorMessage());
            } else if (response.status === "cancelled") {
              onDismissCallback(orderDetails);
              throw new Error(BaseClient.getErrorMessage());
            }
          },
          (err) => {
            throw new Error(BaseClient.getErrorMessage());
          },
        );
      } else {
        fireIntercomEvents("PAYMENT_SUCCESS", JSON.stringify(orderDetails));
        if (fireEventOnPaymentSuccess && fireEventOnPaymentSuccess.length) {
          fireEventOnPaymentSuccess.forEach((event) => {
            PlatformEventManager.emit(event);
          });
        }

        onPaymentSuccess(orderDetails);
      }

      fireIntercomEvents("MODAL_OPEN", JSON.stringify(orderDetails));
    } catch (err) {
      this.setState({
        fetchOrderIdLoading: false,
      });
      onError(err);
    }
  };

  render() {
    const { children } = this.props;
    const { fetchOrderIdLoading } = this.state;

    if (typeof children === "function") {
      return children({
        openPayment: this.openPaymentModal,
        isLoading: fetchOrderIdLoading,
      });
    }

    return null;
  }
}

const mapStateToProps = (state, ownProps) => {
  const { contactNo, email, name, authMetadataList } = state.Application?.userInfo || {};
  const { marketplaceStoreDetails = {} } = state.OnBoarding?.data || {};
  const userInfo = {
    name,
    email,
    contact: contactNo,
    authMetadataList,
  };

  return {
    clientId: ownProps.clientId || state.Application.clientId,
    userInfo: ownProps.userInfo || userInfo,
    marketplaceStoreDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchAndSetContactInfo: fetchAndSetContactInfoAction,
    },
    dispatch,
  );
};

const BankOpenLayerPaymentWrapper = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BankOpenLayerPayment);

BankOpenLayerPaymentWrapper.defaultProps = {
  beforePayment: () => Promise.resolve(),
  fireEventOnOrderId: [],
  fireEventOnPaymentSuccess: [],
  currency: "INR",
  accessKey: "d9592290-8ae4-11eb-a1aa-d10fdbf4b03e",
  // accessKey: "c2898bb0-8ae4-11eb-b411-dbef0dacfecd", // Staging key
  countryDailingCode: "+91",
  forceNewOrderId: true,
  chargeExtraPayload: {},
};

export default BankOpenLayerPaymentWrapper;
