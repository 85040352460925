import React, { useEffect } from "react";
import { connect } from "react-redux";

import InputText from "../inputText";

const EmailInput = (props) => {
  const { userEmail, dataKey, onChange } = props;
  useEffect(() => {
    if (userEmail) {
      onChange({ [dataKey]: userEmail });
    }
  }, []);

  return <InputText {...props} isEditable={!userEmail} />;
};

EmailInput.defaultProps = {
  valuesToReplace: {},
};

const mapStateToProps = (state) => {
  const { userInfo } = state.Application;
  const userEmail = userInfo?.authMetadataList?.find(
    (item) => item?.type === "EMAIL" && item?.isVerified,
  )?.value;

  return {
    userEmail,
  };
};

export default connect(mapStateToProps, null)(EmailInput);
