import React, { useMemo, useState } from "react";
import { toBlob } from "html-to-image";
import _get from "lodash/get";

import { Checkbox } from "@onlinesales-ai/checkbox-v2";
import { loaderWhite } from "@onlinesales-ai/loader-v2";
import { BaseClient } from "@onlinesales-ai/services-v2";
import AsyncImage from "@onlinesales-ai/async-image-v2";
import { ModalWindow } from "@onlinesales-ai/modal-window-v2";

import "./index.less";

const Screenshot = ({ onChange, dataKey, formValues }) => {
  const [enableScreenshot, setEnableScreenshot] = useState(false);
  const [fetchState, setFetchState] = useState({
    isLoading: false,
    errorMsg: false,
  });
  const [isOpen, setIsOpen] = useState(false);
  const value = useMemo(() => _get(formValues, dataKey));

  const toggleModal = () => {
    if (value) {
      setIsOpen(!isOpen);
    }
  };

  const uploadImage = async (file) => {
    const data = new FormData();
    data.append("file", file);

    try {
      const response = await BaseClient.apiCall(
        {
          url: "/onboardingService/uploadFeed",
          method: "POST",
          data,
        },
        "ui",
      );

      const { url } = response || {};

      onChange({ [dataKey]: url });

      setFetchState({
        isLoading: false,
        errorMsg: false,
      });
    } catch (err) {
      setFetchState({
        isLoading: false,
        errorMsg: err.errorMsg,
      });
    }
  };

  const filterScreenshot = (node) => {
    const exclusionClasses = ["global-app-actions", "bug-form-wrapper", "ticket-popover"];
    return !exclusionClasses.some((classname) => {
      if (node?.classList?.contains) {
        return node.classList.contains(classname);
      }
      return false;
    });
  };

  const takeScreenshot = async () => {
    try {
      setFetchState({
        isLoading: true,
        errorMsg: false,
      });

      const useBody = [
        ".route-based-drawer-wrapper",
        ".modal-dialog",
        ".popover .popover-body",
      ].some((query) => document.querySelector(query));

      const ele = useBody
        ? document.body
        : document.getElementsByClassName("content-body")?.[0] || document.body;

      const imgBlob = await toBlob(ele, {
        filter: filterScreenshot,
        width: ele.scrollWidth,
        height: ele.scrollHeight,
      });
      const imgFile = new File([imgBlob], "screenshot.png", { type: imgBlob.type });
      uploadImage(imgFile);
    } catch (err) {
      setFetchState({
        isLoading: true,
        errorMsg: "Unable to take screenshot, Please try again!",
      });
    }
  };

  const onChangeEnableScreenshot = (val) => {
    if (val) {
      takeScreenshot();
    } else {
      onChange({ [dataKey]: undefined });
    }
    setEnableScreenshot(val);
  };

  return (
    <div className="ticket-screenshot-form-component">
      <Checkbox
        checked={enableScreenshot}
        onChange={onChangeEnableScreenshot}
        label={<>Include Screenshot <span className="optional-text">(optional)</span></>}
        id="includescreenshot"
      />
      {enableScreenshot ? (
        <div className="image-contain d-center" onClick={toggleModal}>
          {fetchState?.errorMsg ? (
            <div className="error-msg">{fetchState.errorMsg}</div>
          ) : (
            <>
              {fetchState.isLoading ? (
                loaderWhite()
              ) : (
                <AsyncImage className="thumbnail-img" imgSrc={value} />
              )}
            </>
          )}
        </div>
      ) : null}
      <ModalWindow
        isShow={isOpen}
        onModalDestroy={toggleModal}
        containerClass="ticket-screenshot-modal-preview large"
      >
        <ModalWindow.Header closeButton>
          <span className="title">Screenshot</span>
        </ModalWindow.Header>
        <ModalWindow.Body>
          <AsyncImage className="thumbnail-img" imgSrc={value} />
        </ModalWindow.Body>
      </ModalWindow>
    </div>
  );
};

export default Screenshot;
