import { entityEnum } from "@onlinesales-ai/constants-v2";

import BaseClient from "../baseClient";
import { OSBillingService } from "../index";

const servicePrefix = "/reportingUISvc/";

class ReportingUIService extends BaseClient {
  static getErrorMessages() {
    return {
      ...(OSBillingService.getErrorMessages() || {}),
      default: "Looks like we are facing some internal issue. Please check again after sometime.",
      FB_PAGE_ADMIN_CHECK_FAILED: "Oops! It seems like you do not have admin access to this page. Please try again with the admin credentials to start running ads on facebook.",
      ADD_FB_PAGE_TO_BUSINESS_MANAGER_CHECK_FAILED: "Oops! It seems like you've recently been assigned as Admin for this page. Facebook requires you to be an admin of a page for atleast 7 days to start running ads. Please try again after completing 7 days as Admin for this page.",
      IP0000:
        "It seems some input parameters are not valid. Please update your inputs and try again.",
      AD0000:
        "Oops. It seems you are not authorized to take this action. Please log in with appropriate credentials.",
      I0004:
        "Oops. Seems the system is acting up - sorry for the inconvenience. Please try again in some time. (Please be assured our tech geniuses have been notified of this glitch).",
    };
  }

  static postMarketingCampaign(payload, application) {
    const request = encodeURIComponent(JSON.stringify(payload));
    return this.apiCall(
      {
        url: `${servicePrefix}goal`,
        method: "POST",
        data: request,
      },
      application,
    );
  }

  static getMerchandise(payload, application, options) {
    return this.apiCall(
      {
        url: `${options?.servicePrefix || ""}${servicePrefix}v1/search`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
      options,
    );
  }

  static kongaOnboard(payload, application) {
    const request = encodeURIComponent(JSON.stringify(payload));
    return this.apiCall(
      {
        url: `${servicePrefix}konga/onboard`,
        method: "POST",
        data: request,
      },
      application,
    );
  }

  static fetchGoalSuggestionValue(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}suggestion/objective`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
    );
  }

  static fetchPlatformContributionData(payload, application, options = {}) {
    return this.apiCall(
      {
        url: `${servicePrefix}getSitePerfData`,
        method: "POST",
        data: encodeURIComponent(JSON.stringify(payload)),
      },
      application,
      {
        retryConfig: {
          TypeError: 1,
        },
        ...options,
      },
    );
  }

  static fetchMarketingCampaignsById(config, application) {
    const payload = {
      entityLevel: entityEnum.MARKETING_CAMPAIGN,
      selectors: [
        "id",
        "name",
        "alias",
        "clientId",
        "objective",
        "objectiveSettings",
        "isRecurring",
        "goal",
        "derivedGoal",
        "status",
        "statusPriority",
        "country",
        "originalStartDate",
        "campaignSubType",
        "startDate",
        "endDate",
        "channelSettings",
        "budget",
        "metadata",
        // "cities",
        "budgetType",
      ],
      filters: [],
      clientId: config.clientId,
    };

    if (config.campaignId) {
      payload.filters.push({
        column: "id",
        operator: "EQUAL_TO",
        value: config.campaignId,
      });
    }

    if (config.isFetchPreflightErrors) {
      payload.preFlightSelectors = ["USER"];
    }

    return this.apiCall(
      {
        url: `${servicePrefix}goal`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
    );
  }

  static fetchMarketingCampaigns(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}goal`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
    );
  }

  static fetchMarketingCampaignsByStatus(config, application) {
    const payload = {
      entityLevel: entityEnum.MARKETING_CAMPAIGN,
      selectors: [...(config?.selectors || []), "clientId"],
      filters: [
        ...(config.additionalFilters || []),
      ],
      clientId: config.clientId,
      fetchBillingRemainingBudget: config.fetchBillingRemainingBudget,
      limit: config.limit,
      offset: config.offset,
    };

    if (config.isFetchPreflightErrors) {
      payload.preFlightSelectors = ["USER"];
    }

    if (config.orderBy) {
      payload.orderBy = config.orderBy;
    }

    return this.apiCall(
      {
        url: `${servicePrefix}goal`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
    );
  }

  static fetchMarketingCampaignsByStatusAdmin(config, application) {
    const payload = {
      entityLevel: entityEnum.MARKETING_CAMPAIGN,
      selectors: config.selectors,
      filters: [
        {
          column: "status",
          operator: "IN",
          value: config.statusTypes,
        },
        ...(config.additionalFilters || []),
      ],
      clientId: config.clientId,
      fetchBillingRemainingBudget: config.fetchBillingRemainingBudget,
    };

    if (config.isFetchPreflightErrors) {
      payload.preFlightSelectors = ["USER"];
    }

    if (config.orderBy) {
      payload.orderBy = config.orderBy;
    }

    return this.apiCall(
      {
        url: `${servicePrefix}goal`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
    );
  }

  static fetchClientAchievements(payload, application, options = {}) {
    return this.apiCall(
      {
        url: `${servicePrefix}dashboard/clientAchievements`,
        method: "POST",
        data: encodeURIComponent(JSON.stringify(payload)),
      },
      application,
      {
        retryConfig: {
          TypeError: 1,
        },
        ...options,
      },
    );
  }

  static fetchAdPreview(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}adPreview`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
    );
  }

  static fetchConsolidatedMarketingCampaigns(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}marketingCampaigns`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
    );
  }

  static postConsolidatedMarketingCampaign(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}marketingCampaigns`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
    );
  }

  static fetchClientSubscriptions(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}clientSubscriptions`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
    );
  }

  static postSubscriptionPlans(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}osBillingWrapper/subscription`,
        method: "POST",
        data: encodeURIComponent(JSON.stringify(payload)),
      },
      application,
    );
  }

  static fetchMedialLibrary(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}mediaLibrary`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
    );
  }

  static postAccountLinking(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}accountLinking`,
        method: "POST",
        data: encodeURIComponent(JSON.stringify(payload)),
      },
      application,
    );
  }

  static fetchTopAds(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}topCreatives`,
        method: "POST",
        data: encodeURIComponent(JSON.stringify(payload)),
      },
      application,
      options,
    );
  }

  static fetchHygieneData(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}hygieneCheck`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
    );
  }

  static refreshHygieneData(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}refreshChecks`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
    );
  }

  static fetchClientProfile(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}clientProfileFromPage`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
    );
  }

  static postClientProfile(payload, application) {
    const endpoint = payload.clientId ? "clientProfileFromPage" : "createClientProfile";
    return this.apiCall(
      {
        url: `${servicePrefix}${endpoint}`,
        method: "POST",
        data: encodeURIComponent(JSON.stringify(payload)),
      },
      application,
    );
  }

  static postBulkOperations(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}bulkOperations`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
    );
  }

  static fetchClientPerformance(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}clientPerformance`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
    );
  }

  static linkMarketplaceSeller(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}accountLinking`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
    );
  }

  static downloadFileViaPostMethod(payload) {
    return BaseClient.downloadFileSameWindow(
      {
        url: `${servicePrefix}downloadReport`,
        method: "POST",
        body: JSON.stringify(payload),
        fileName: payload?.fileName || "download",
      },
    );
  }
}

export default ReportingUIService;
