import React, { useEffect, useMemo, useRef, useState } from "react";

import { useOnClickOutside } from "@onlinesales-ai/util-methods-v2";
import { Input } from "@onlinesales-ai/input-v2";

import { getCaretPosition } from "./utils";

import "./index.less";

const DynamicField = ({
  onSelect,
  options,
  children,
  valueTemplate,
  hasShiftKey,
  triggerChar,
  showSearch,
}) => {
  const inputRef = useRef();
  const wrapperRef = useRef();
  const searchRef = useRef();
  const selectionPosition = useRef();

  const [showOptions, setShowOptions] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const closeShowOptions = () => {
    const pos = selectionPosition.current;
    if (pos) {
      inputRef.current.setSelectionRange(pos, pos);
    }
    setShowOptions(false);
  };

  const listRef = useOnClickOutside(() => {
    closeShowOptions();
  });

  const renderOptions = () => {
    const pos = getCaretPosition(inputRef.current);

    listRef.current.style.top = `${pos.y}px`;
    listRef.current.style.left = `${pos.x + 5}px`;
  };

  useEffect(() => {
    if (inputRef.current) {
      const onKeyup = (event) => {
        if ((hasShiftKey ? event.shiftKey : true) && event.key === triggerChar) {
          renderOptions();
          setShowOptions(true);
          event.stopPropagation();
        } else if (showOptions) {
          closeShowOptions();
        }
      };

      inputRef.current.addEventListener("keyup", onKeyup);
      const observerInputRefValue = inputRef.current;
      return () => {
        observerInputRefValue.removeEventListener("keyup", onKeyup);
      };
    }

    return () => {};
  }, [showOptions]);

  useEffect(() => {
    if (showOptions && searchRef.current) {
      selectionPosition.current = inputRef.current.selectionStart;
      searchRef.current.focus();
    }
  }, [showOptions]);

  const onClickOption = (value) => {
    closeShowOptions();
    if (onSelect) {
      let selectedValue = value;

      if (valueTemplate) {
        selectedValue = valueTemplate.replace(/VALUE/g, selectedValue);
      }

      onSelect({
        value: selectedValue,
        startPosition: inputRef.current.selectionStart,
      });
    }
  };

  const filteredOptions = useMemo(() => {
    return options?.filter((op) => {
      if (op.label.toLowerCase().indexOf(searchValue.toLowerCase()) === -1) {
        return false;
      }
      return true;
    });
  }, [options, searchValue]);

  return (
    <div ref={wrapperRef} className="dynamic-field-wrapper">
      <ul ref={listRef} className={`field-dropdown ${showOptions ? "show" : ""}`}>
        {showSearch ? (
          <li className="search-input-li">
            <Input
              ref={searchRef}
              value={searchValue}
              onChange={setSearchValue}
              className="dynamic-search-input"
              prefix={<span className="icon icon-search" />}
            />
          </li>
        ) : null}
        <ul className="options-list">
          {filteredOptions.map(({ value, label }) => {
            return <li onClick={() => onClickOption(value)}>{label}</li>;
          })}
        </ul>
      </ul>
      {children({ inputRef })}
    </div>
  );
};

DynamicField.valueReplacer = (text, { value, startPosition }) => {
  if (!text) {
    return value;
  }

  const newText = text.substring(0, startPosition - 1) + text.substring(startPosition);

  return newText.substring(0, startPosition - 1) + value + newText.substring(startPosition - 1);
};

DynamicField.defaultProps = {
  options: [],
  valueTemplate: "VALUE",
  hasShiftKey: true,
  triggerChar: "^",
  showSearch: true,
};

export default DynamicField;
