import BaseClient from "../baseClient";

const servicePrefix = "/adCardService/";

class AdCardService extends BaseClient {
  static getErrorMessages() {
    return {
      default:
        "Looks like we are facing some internal issue. Please, retry after sometime.",
      IP0000:
        "It seems some input parameters are not valid. Please update your inputs and try again.",
      AD0000:
        "Oops. It seems you are not authorized to take this action. Please log in with appropriate credentials.",
      I0004:
        "Oops. Seems the system is acting up - sorry for the inconvenience. Please try again in some time. (Please be assured our tech geniuses have been notified of this glitch).",
      APIERR023: "Coupon code used is invalid",
      ADCARD0000: "This coupon code is already used",
      AD_CARD_0001: "Coupon code used is invalid"
    };
  }

  static fetchCodeDetails(payload, application){
    if(payload) {
      return this.apiCall(
        {
          url: servicePrefix + "codeDetails",
          method: "GET",
          params: {
            "jsonQuery" : JSON.stringify(payload)
          }
        },
        application
      );
    }
  }
}

export default AdCardService;
