import React from "react";
import { DropDownCreatable } from "@onlinesales-ai/dropdown-v2";
import DropdownForm from "../dropdown"

const DropdownCreatableForm = (props) => {
  return (
    <DropdownForm
      dropdownComponent={DropDownCreatable}
      {...props}
    />
  );
};

export default DropdownCreatableForm;
