import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import _get from "lodash/get";

import { CopyToClipboard } from "@onlinesales-ai/utils-components-v2";
import WithTooltip from "@onlinesales-ai/tooltip-v2";

import FormWrapper from "../../FormWrapper";

const CopyClipboardValue = ({ formValues, dataKey, ...props }) => {
  const { t } = useTranslation();

  const value = useMemo(() => _get(formValues, dataKey));

  return (
    <FormWrapper {...props} formValues={formValues} dataKey={dataKey}>
      <div className="non-edit-mode-text">
        <CopyToClipboard text={value} className="reset">
          <WithTooltip placement="top" title={<div>Click to copy {value}</div>}>
            <div className="product-id ellipsis-text">{value}</div>
          </WithTooltip>
        </CopyToClipboard>
      </div>
    </FormWrapper>
  );
};

export default CopyClipboardValue;
