import React, { useContext, useEffect } from "react";
import classnames from "classnames";

import StepsContext from "./StepsContext";

const Step = ({
  stepNumber,
  isLastStep,
  children,
  isValid = null,
  showStepNumber,
  id,
  contentClassName,
}) => {
  const { currentIndex, onChangeIndex, totalSteps } = useContext(StepsContext);

  useEffect(() => {
    if (typeof isValid === "boolean") {
      if (isValid) {
        jobDone();
      } else {
        onReset();
      }
    }
  }, [isValid]);

  const jobDone = () => {
    if (currentIndex === stepNumber && stepNumber <= totalSteps) {
      onChangeIndex(stepNumber + 1);
    }
  };

  const onReset = () => {
    if (currentIndex === stepNumber + 1) {
      onChangeIndex(stepNumber);
    }
  };

  return (
    <div
      className={`step-wrapper ${currentIndex > stepNumber ? "completed" : ""} ${
        isLastStep ? "is-last-step" : ""
      }`}
      id={id}
    >
      {showStepNumber ? <div className="step-number">{stepNumber}</div> : null}
      <div className={classnames("step-content", contentClassName)}>
        {children({
          disabled: currentIndex < stepNumber,
          stepNumber,
          onChangeIndex,
          jobDone,
          onReset,
          currentIndex,
        })}
      </div>
    </div>
  );
};

export default Step;
