export default {
  APP_SET_UA_TOKEN: "APP_SET_UA_TOKEN",
  APP_SET_SHOP_INFO: "APP_SET_SHOP_INFO",
  APP_SET_USER_INFO: "APP_SET_USER_INFO",
  APP_LOG_OUT: "APP_LOG_OUT",
  APP_SET_CLIENTS: "APP_SET_CLIENTS",
  APP_SET_OUTLETS: "APP_SET_OUTLETS",
  SET_ACTIVE_CLIENT_ID: "SET_ACTIVE_CLIENT_ID",
  SET_CLIENTS_FETCH_STATE: "SET_CLIENTS_FETCH_STATE",
  SET_IS_ALL_CLIENTS_FETCHED: "SET_IS_ALL_CLIENTS_FETCHED",
  SET_USER_FETCH_STATE: "SET_USER_FETCH_STATE",
  APP_CLIENT_CHANGE: "APP_CLIENT_CHANGE",
  SET_IS_INTERNAL_USER: "SET_IS_INTERNAL_USER",
  SET_MEDIA_QUERY_DEVICE: "SET_MEDIA_QUERY_DEVICE",
  APP_ADD_NEW_CLIENT: "APP_ADD_NEW_CLIENT",
  SET_IS_AD_BLOCKER_ENABLED: "SET_IS_AD_BLOCKER_ENABLED",
  APP_PUSH_FILE_DOWNLOAD: "APP_PUSH_FILE_DOWNLOAD",
  APP_DISMISS_FILE_DOWNLOAD: "APP_DISMISS_FILE_DOWNLOAD",
  APP_UPDATE_FILE_DOWNLOAD_STATUS: "APP_UPDATE_FILE_DOWNLOAD_STATUS",
  APP_SET_BILLING_STATUS_FETCH_IN_PROGRESS: "APP_SET_BILLING_STATUS_FETCH_IN_PROGRESS",
  APP_SET_BILLING_SUBSCRIBED_STATUS: "APP_SET_BILLING_SUBSCRIBED_STATUS",
  APP_SET_BILLING_ROUTE_KEY_TO_USE: "APP_SET_BILLING_ROUTE_KEY_TO_USE",
  APP_SET_AGREEMENT_PROPERTY_DATA: "APP_SET_AGREEMENT_PROPERTY_DATA",
  APP_SET_CLIENT_PENDING_ORDERS: "APP_SET_CLIENT_PENDING_ORDERS",
  SET_STICKY_HEADER: "SET_STICKY_HEADER",
  SET_MONETIZE_GOALS_FETCH_STATUS: "SET_MONETIZE_GOALS_FETCH_STATUS",
  APP_SET_TAGGING_DATA: "APP_SET_TAGGING_DATA",
  SET_BUSINESS_DETAILS_CHECK_FLAG: "SET_BUSINESS_DETAILS_CHECK_FLAG",
  APP_SET_REPORTING_X_DAYS_DATA: "APP_SET_REPORTING_X_DAYS_DATA",
  SET_APP_LEVEL_ACTIONS_DATA: "SET_APP_LEVEL_ACTIONS_DATA",
  APP_SET_CLIENT_SUBSCRIBED_STATUS: "APP_SET_CLIENT_SUBSCRIBED_STATUS",
  SET_RMN_ACCOUNT_CONFIG: "SET_RMN_ACCOUNT_CONFIG",
  SET_DATA_DELAY_FOR_LIVE_REPORT: "SET_DATA_DELAY_FOR_LIVE_REPORT",
  SET_NETWORK_LIST: "SET_NETWORK_LIST",
  SET_IS_TOPUP_ENABLED_IN_APPLICATION: "SET_IS_TOPUP_ENABLED_IN_APPLICATION",
  SET_ONBOARDING_DETAILS: "SET_ONBOARDING_DETAILS",
  SET_AGENCY_SETTINGS_IN_APP: "SET_AGENCY_SETTINGS_IN_APP",
  SET_IS_INTERNAL_USER_VIEW_MODE: "SET_IS_INTERNAL_USER_VIEW_MODE",
  SET_APP_LEVEL_DATA_FETCH_STATE: "SET_APP_LEVEL_DATA_FETCH_STATE",
  APP_SET_ENTITY_ID: "APP_SET_ENTITY_ID",
  SET_IS_PARTNER_INTERNAL_USER: "SET_IS_PARTNER_INTERNAL_USER",
  SET_DEVICE_THEME: "SET_DEVICE_THEME",
};
