import React from "react";

import { WalletBalanceV2 } from "@onlinesales-ai/billing-v2";

const WalletBalanceHeaderWrapper = ({
  isBillingNotSubscribed,
  isBillingStatusFetchInProgress,
  isOnboardingFetchInProgress,
  ...props
}) => {
  return (
    !isBillingStatusFetchInProgress && !isBillingNotSubscribed && !isOnboardingFetchInProgress &&
    <WalletBalanceV2 {...props} />
  );
};

export default WalletBalanceHeaderWrapper;
