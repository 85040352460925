import React, { useCallback, useMemo } from "react";

import { startOfDay, endOfDay, isSameDay } from "date-fns/esm";
import {
  convertBrowserToClientTimezoneWithSameDateTime,
  getUTCToZonedDate,
} from "@onlinesales-ai/util-methods-v2";

import DatePicker from "./DatePicker";

const timeConvert = {
  startOfDay,
  endOfDay,
};

const UTCDatePicker = ({
  onChange,
  defaultTime,
  enableTime,
  value: pValue,
  minDate: pMinDate,
  maxDate: pMaxDate,
  ...props
}) => {
  const minDate = useMemo(() => {
    if (pMinDate) {
      return getUTCToZonedDate(pMinDate);
    }

    return pMinDate;
  }, [pMinDate]);

  const maxDate = useMemo(() => {
    if (pMaxDate) {
      return getUTCToZonedDate(pMaxDate);
    }

    return pMaxDate;
  }, [pMaxDate]);

  const value = useMemo(() => {
    if (pValue) {
      return getUTCToZonedDate(pValue);
    }

    return pValue;
  }, [pValue]);

  const convertLocalToUTCDate = (date) => {
    if (!date) {
      return date;
    }

    let newData = null;

    if (!enableTime && defaultTime && timeConvert[defaultTime]) {
      newData = convertBrowserToClientTimezoneWithSameDateTime(timeConvert[defaultTime](date));
    } else {
      newData = convertBrowserToClientTimezoneWithSameDateTime(date);
    }

    if (!enableTime && defaultTime === "endOfDay") {
      return newData.valueOf() - 999;
    }

    return newData.valueOf();
  };

  const currentDate = useMemo(() => {
    return getUTCToZonedDate(new Date());
  }, []);

  const dayClassName = useCallback((date) => {
    if (isSameDay(date, currentDate)) {
      return "today-date";
    }

    return "";
  }, [currentDate]);

  return (
    <DatePicker
      value={value}
      onChange={(date) => onChange(convertLocalToUTCDate(date))}
      enableTime={enableTime}
      minDate={minDate}
      maxDate={maxDate}
      popperClassName="utc-single-date-picker"
      dayClassName={dayClassName}
      {...props}
    />
  );
};

export default UTCDatePicker;
