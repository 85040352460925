import React from "react";
import { NavLink } from "react-router-dom";
import { Trans } from "react-i18next";
import { connect } from "react-redux";

import "./index.less";

const AppLevelTabs = ({ appLevelTabs = [], isMobile }) => {
  if (!appLevelTabs?.length) {
    return null;
  }

  const propToCheck = isMobile ? "shouldBeRenderOnMobile" : "shouldBeRenderOnDesktop";

  return (
    <div className="mobile-bottom-nav-tabs">
      {appLevelTabs?.map((tabInfo) => {
        if (tabInfo[propToCheck]) {
          return (
            <NavLink
              key={tabInfo.key}
              to={tabInfo.href}
              className={({ isActive }) => "nav-item" + (isActive ? " selected" : "")}>
              <span className={`icon ${tabInfo.icon}`}></span>
              <span><Trans>{tabInfo.name}</Trans></span>
            </NavLink>
          );
        }
        return null;
      })}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    appLevelTabs: state.DomainConfig.appLevelTabs,
    isMobile: state.Application.isMobile,
  };
};

export default connect(mapStateToProps)(AppLevelTabs);
