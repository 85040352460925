export const validateFileUsingConfig = (
  config,
  creative,
  { showToastMessage, t, mediaFileConversionConstants },
) => {
  const { height, width, sizeInBytes, type, metadata } = creative;
  const { ONE_MB_IN_BYTES = 1000000 } = mediaFileConversionConstants || {};

  if (config.mediaType !== "MEDIA" && config.mediaType && config.mediaType !== type) {
    showToastMessage({
      type: "ERROR",
      messageToDisplay: t(`Please choose correct media type to proceed further`),
      actionButtonLabel: null,
      toastDuration: 5000,
      className: "pendo-track-media-library-wrong-file-format-selected",
    });
    return false;
  }

  if (
    config?.dimensionValidation?.height &&
    config?.dimensionValidation?.width &&
    !(
      height === config?.dimensionValidation?.height && width === config?.dimensionValidation?.width
    )
  ) {
    showToastMessage({
      type: "ERROR",
      messageToDisplay: t(`You can only choose file with dimension {{width}} x {{height}}`, {
        width: config?.dimensionValidation?.width,
        height: config?.dimensionValidation?.height,
      }),
      actionButtonLabel: null,
      toastDuration: 5000,
    });
    return false;
  }

  if (sizeInBytes && sizeInBytes > config.maxFileSizeInMB * ONE_MB_IN_BYTES) {
    showToastMessage({
      type: "ERROR",
      messageToDisplay: t(`You can only choose file with max size upto {{maxFileSizeInMB}}MB`, {
        maxFileSizeInMB: config.maxFileSizeInMB,
      }),
      actionButtonLabel: null,
      toastDuration: 5000,
    });
    return false;
  }

  if (sizeInBytes && sizeInBytes < config.minFileSizeInMB * ONE_MB_IN_BYTES) {
    showToastMessage({
      type: "ERROR",
      messageToDisplay: t(`You can only choose file with min size {{minFileSizeInMB}}MB`, {
        minFileSizeInMB: config.minFileSizeInMB,
      }),
      actionButtonLabel: null,
      toastDuration: 5000,
    });
    return false;
  }

  if (
    type === "VIDEO" &&
    config?.durationValidation?.min &&
    config?.durationValidation?.max &&
    !(
      config?.durationValidation?.min <= metadata?.duration &&
      config?.durationValidation?.max >= metadata?.duration
    )
  ) {
    showToastMessage({
      type: "ERROR",
      messageToDisplay: t(
        `You can only choose video with a duration between {{min}} and {{max}} seconds`,
        {
          min: config?.durationValidation?.min,
          max: config?.durationValidation?.max,
        },
      ),
      actionButtonLabel: null,
      toastDuration: 5000,
    });
    return false;
  }

  if (
    type === "VIDEO" &&
    config?.defaultCreativeLanguage &&
    config?.defaultCreativeLanguage !== metadata?.language
  ) {
    showToastMessage({
      type: "ERROR",
      messageToDisplay: t(
        `You can only choose creative with {{defaultCreativeLanguage}} language`,
        {
          language: config?.defaultCreativeLanguage,
        },
      ),
      actionButtonLabel: null,
      toastDuration: 5000,
    });
    return false;
  }

  return true;
};
