import Types from "./types";

const reducer = (state, action, initialState = {}) => {
  switch (action.type) {
    case Types.APP_CLIENT_CHANGE: {
      state = {
        ...initialState,
      };
    }
      break;
    case Types.SET_CLIENT_ACHIVEMENT_DATA:
      {
        state = {
          ...state,
          data: action.data,
        };
      }
      break;
    case Types.UPDATE_CLIENT_ACHIVEMENT_DATA:
      {
        state = {
          ...state,
          data: {
            ...(state.data || {}),
            ...action.data,
          },
        };
      }
      break;
    case Types.UPDATE_HYGIENE_CLIENT_ACHIVEMENT_DATA:
      {
        state = {
          ...state,
          data: {
            ...state.data,
            ...action?.data?.data?.summary,
          },
        };
      }
      break;
    case Types.SET_CLIENT_ACHIVEMENT_FETCH_STATE:
      {
        state = {
          ...state,
          isLoading: action.isLoading,
          fetchError: action.fetchError,
        };
      }
      break;
    default:
      break;
  }

  return state;
};

export default reducer;
