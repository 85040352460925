export default {
  SET_BRAND_ADS_INVENTORY_DATA: "SET_BRAND_ADS_INVENTORY_DATA",
  SET_BRAND_ADS_PAGE_TYPES: "SET_BRAND_ADS_PAGE_TYPES",
  SET_BRAND_ADS_INVENTORY_SLOTS_DATA: "SET_BRAND_ADS_INVENTORY_SLOTS_DATA",
  SET_BRAND_ADS_GOALS: "SET_BRAND_ADS_GOALS",
  UPDATE_BRAND_ADS_GOAL_DATA: "UPDATE_BRAND_ADS_GOAL_DATA",
  UPDATE_BRAND_ADS_GOAL_CAMPAIGN_DATA: "UPDATE_BRAND_ADS_GOAL_CAMPAIGN_DATA",
  SET_BRAND_ADS_INVENTORY_SLOT_STATUS: "SET_BRAND_ADS_INVENTORY_SLOT_STATUS",
  UPDATE_BRAND_ADS_SCHEDULE_STATUS: "UPDATE_BRAND_ADS_SCHEDULE_STATUS",
  DELETE_BRAND_ADS_SLOT_STATUS: "DELETE_BRAND_ADS_SLOT_STATUS",
  UPDATE_CAMPAIGN_STATUS: "UPDATE_CAMPAIGN_STATUS",
  MERGE_ALL_GOALS_DATA_INVENTORY: "MERGE_ALL_GOALS_DATA_INVENTORY",
  UPDATE_BRAND_ADS_GOAL_MERCHENDISE_DATA: "UPDATE_BRAND_ADS_GOAL_MERCHENDISE_DATA",
  UPDATE_BRAND_ADS_GOAL_CREATIVE_DATA: "UPDATE_BRAND_ADS_GOAL_CREATIVE_DATA",
  BRAND_ADS_UPDATE_CAMPAIGN_STATUS: "BRAND_ADS_UPDATE_CAMPAIGN_STATUS",
  SET_BRAND_ADS_KEYWORD_BIDDING_DATA: "SET_BRAND_ADS_KEYWORD_BIDDING_DATA",
  UPDATE_BRAND_ADS_KEYWORD_BIDDING_DATA: "UPDATE_BRAND_ADS_KEYWORD_BIDDING_DATA",
  UPDATE_GOALS_OFFSET_BRAND_ADS: "UPDATE_GOALS_OFFSET_BRAND_ADS",
  SET_GOALS_LIST_FETCH_IN_PROGRESS: "SET_GOALS_LIST_FETCH_IN_PROGRESS",
  SET_GOALS_FETCH_SUCCESSFUL: "SET_GOALS_FETCH_SUCCESSFUL",
  SET_IS_FIRST_CALL_IN_PROGRESS_BRAND_ADS: "SET_IS_FIRST_CALL_IN_PROGRESS_BRAND_ADS",
  SET_GOALS_FETCH_ERROR_MSG: "SET_GOALS_FETCH_ERROR_MSG",
  MAKE_GOAL_EMPTY: "MAKE_GOAL_EMPTY",
  SET_IS_FIRST_CALL_SUCCESSFUL_BRAND_ADS: "SET_IS_FIRST_CALL_SUCCESSFUL_BRAND_ADS",
  MAKE_GOALS_SORTED_EMPTY: "MAKE_GOALS_SORTED_EMPTY",
  SET_GOALS_SORTED_IDS: "SET_GOALS_SORTED_IDS",
  SET_BRAND_ADS_KEYWORD_BIDDING_FETCH_STATUS: "SET_BRAND_ADS_KEYWORD_BIDDING_FETCH_STATUS",
  SET_NEW_GOAL_TO_SORTED_IDS: "SET_NEW_GOAL_TO_SORTED_IDS",
  SET_SELECTED_NETWORK_LIST: "SET_SELECTED_NETWORK_LIST",
  SET_CAMPAIGN_DEBUGGER_DATA: "SET_CAMPAIGN_DEBUGGER_DATA",
  AUCTION_GOAL_LIST_NOTIFICATION: "AUCTION_GOAL_LIST_NOTIFICATION",
  BRAND_ADS_UPDATE_CAMPAIGNS: "BRAND_ADS_UPDATE_CAMPAIGNS",
};
