import React from "react";
import { Trans } from "react-i18next";
import "./index.less";

const typeImage = {
  warning: "https://osads.gumlet.io/image/upload/v1618578456/product/warning-emoji.png",
  ottWarning: "https://osads.gumlet.io/image/upload/v1618578456/product/warning-emoji.png",
};

const InfoBanner = ({ type, message, showIcon = true, borderDash, icon, iconClassName }) => {
  return (
    <div className={`info-container d-align-center ${type} ${borderDash ? "border-dash" : ""}`}>
      {showIcon && typeImage[type] ? (
        <img src={typeImage[type]} className="info-icon" alt={type} />
      ) : null}
      {icon && <span className={`banner-icon mr10 icon ${icon} ${iconClassName}`} /> }
      <span>
        <Trans>{message}</Trans>
      </span>
    </div>
  );
};

InfoBanner.defaultProps = {
  type: "warning",
  message: "",
  borderDash: false,
};

export default InfoBanner;
