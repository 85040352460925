import { convertBrowserToClientTimezoneWithSameDateTime, getUTCToZonedDate, parsedGetLSItem } from "@onlinesales-ai/util-methods-v2";
import {
  add,
  sub,
  startOfDay,
  subDays,
  startOfMonth,
  endOfMonth,
  subMonths,
  differenceInDays,
  startOfQuarter,
  lastDayOfQuarter,
  subQuarters,
  startOfWeek,
} from "date-fns/esm";

/*
  value config

  {
    years: 2,
    months: 9,
    weeks: 1,
    days: 7,
    hours: 5,
    minutes: 9,
    seconds: 30
  }
*/

export const computeTime = (type, value) => {
  if (type === "now") {
    return Date.now();
  }

  if (type === "nowWithAddTime") {
    return add(new Date(), value).valueOf();
  }

  if (type === "nowWithSubTime") {
    return sub(new Date(), value).valueOf();
  }

  if (type === "startOfDayWithClientZone") {
    return convertBrowserToClientTimezoneWithSameDateTime(startOfDay(Date.now()));
  }

  return type;
};

export const getLSValues = (dashboardKey) => {
  return parsedGetLSItem(`global_filter_date_range_${dashboardKey}`) || {};
};

export const AVALIABLE_PRESET = {
  TODAY: "TODAY",
  YESTERDAY: "YESTERDAY",
  THIS_WEEK: "THIS_WEEK",
  LAST_7_DAYS: "LAST_7_DAYS",
  LAST_30_DAYS: "LAST_30_DAYS",
  THIS_MONTH: "THIS_MONTH",
  LAST_MONTH: "LAST_MONTH",
  THIS_QUARTER: "THIS_QUARTER",
  LAST_QUARTER: "LAST_QUARTER",
  CUSTOM: "CUSTOM",
};

export const getPresetsDate = (
  preset,
  { currentDate, presetConfig, showTodaysDate, isTodayDisabled },
  t = (str) => str,
) => {
  if (!preset || !Object.keys(AVALIABLE_PRESET).includes(preset)) {
    return null;
  }
  const todayDate = currentDate || getUTCToZonedDate(new Date());
  const yesterday = subDays(todayDate, "1");
  const lastMonth = subMonths(yesterday, "1");
  const endDate =
    (presetConfig?.endDate === "__TODAY_DATE__" ? todayDate : presetConfig?.endDate) || yesterday;
  const previousLastMonth = subMonths(yesterday, "2");
  const previousQuarterFirstDay = startOfQuarter(subQuarters(yesterday, "1"));
  const startOfWeekDate = startOfWeek(todayDate);
  const daysOffSet = differenceInDays(todayDate, startOfWeekDate) + 1;

  let presetDate = null;
  switch (preset) {
    case AVALIABLE_PRESET.TODAY:
      presetDate = {
        displayName: t("Today"),
        startDate: currentDate || getUTCToZonedDate(new Date()),
        endDate: currentDate || getUTCToZonedDate(new Date()),
        resetCompareMode: true,

        isDisabled: !!isTodayDisabled,
        tooltip: `Today feature is not available in "Comparison Mode"`,
        className: "pendo_feature_realtime_enabled",
      };
      break;
    case AVALIABLE_PRESET.YESTERDAY:
      presetDate = {
        displayName: t("Yesterday"),
        startDate: yesterday,
        endDate: yesterday,
        compareStartDate: subDays(yesterday, "1"),
        compareEndDate: subDays(yesterday, "1"),
      };
      break;
    case AVALIABLE_PRESET.THIS_WEEK:
      presetDate = {
        displayName: t("This Week"),
        startDate: startOfWeekDate,
        endDate: todayDate,
        compareStartDate: subDays(startOfWeekDate, daysOffSet),
        compareEndDate: subDays(todayDate, daysOffSet),
      };
      break;
    case AVALIABLE_PRESET.LAST_7_DAYS:
      presetDate = {
        displayName: t("Last 7 Days"),
        startDate: subDays(todayDate, "7"),
        endDate,
        compareStartDate: subDays(todayDate, "14"),
        compareEndDate: subDays(todayDate, "8"),
      };
      break;
    case AVALIABLE_PRESET.LAST_30_DAYS:
      presetDate = {
        displayName: t("Last 30 Days"),
        startDate: subDays(todayDate, "30"),
        endDate,
        compareStartDate: subDays(todayDate, "60"),
        compareEndDate: subDays(todayDate, "31"),
      };
      break;
    case AVALIABLE_PRESET.THIS_MONTH:
      presetDate = {
        displayName: t("This Month"),
        startDate: startOfMonth(yesterday),
        endDate: showTodaysDate ? todayDate : endDate,
        compareStartDate: startOfMonth(lastMonth),
        compareEndDate: endOfMonth(lastMonth),
      };
      break;
    case AVALIABLE_PRESET.LAST_MONTH:
      presetDate = {
        displayName: t("Last Month"),
        startDate: startOfMonth(lastMonth),
        endDate: endOfMonth(lastMonth),
        compareStartDate: startOfMonth(previousLastMonth),
        compareEndDate: endOfMonth(previousLastMonth),
      };
      break;
    case AVALIABLE_PRESET.THIS_QUARTER:
      presetDate = {
        displayName: t("This Quarter"),
        startDate: startOfQuarter(yesterday),
        endDate: showTodaysDate ? todayDate : endDate,
        compareStartDate: previousQuarterFirstDay,
        compareEndDate: lastDayOfQuarter(previousQuarterFirstDay),
      };
      break;
    case AVALIABLE_PRESET.LAST_QUARTER:
      presetDate = {
        displayName: t("Last Quarter"),
        startDate: previousQuarterFirstDay,
        endDate: lastDayOfQuarter(previousQuarterFirstDay),
        compareStartDate: startOfQuarter(subQuarters(previousQuarterFirstDay, "1")),
        compareEndDate: lastDayOfQuarter(subQuarters(previousQuarterFirstDay, "1")),
      };
      break;
    case AVALIABLE_PRESET.CUSTOM:
      presetDate = {
        displayName: t("Custom"),
        rangeType: "CUSTOM",
        isCustom: true,
      };
      break;
    default:
      break;
  }

  return presetDate;
};
