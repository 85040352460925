import React from "react";
import _get from "lodash/get";
import { withTranslation } from "react-i18next";

import { ToggleButton } from "@onlinesales-ai/toggle-button-v2";

import FormWrapper from "../../FormWrapper";

import "./index.less";

class ToggleSwitch extends React.Component {
  getValue = () => {
    const { dataKey, formValues, getValue } = this.props;

    if (getValue) {
      return getValue(this.props);
    }

    return _get(formValues, dataKey);
  };

  onChangeToggle = (event, value) => {
    const { onChange, dataKey } = this.props;

    onChange({
      [dataKey]: value,
    });
  };

  render() {
    const {
      // FormWrapper props
      labelColumns,
      formGroupClassName,
      formGroupInnerClassName,
      guidText,
      guidElement,
      apiError,
      warning,
      title,
      titleTooltip,
      titleGuidText,
      infoTooltip,
      extraLabelProps,
      labelDefinationKey,
      errorClassName,
      contentClassName,
      hideLabelSeparator,

      // tag input props
      dataKey,
      isEditable,
      showErrors,
      formErrors,
      disabled,
      className,
      toggleType,
    } = this.props;

    const value = this.getValue();

    return (
      <FormWrapper
        formGroupClassName={formGroupClassName}
        formGroupInnerClassName={formGroupInnerClassName}
        labelColumns={labelColumns}
        hasError={showErrors && formErrors[dataKey]}
        error={formErrors[dataKey]}
        apiError={apiError}
        guidElement={guidElement}
        guidText={guidText}
        warning={warning}
        title={title}
        titleTooltip={titleTooltip}
        titleGuidText={titleGuidText}
        infoTooltip={infoTooltip}
        labelDefinationKey={labelDefinationKey}
        extraLabelProps={extraLabelProps}
        isEditable={isEditable}
        errorClassName={errorClassName}
        contentClassName={contentClassName}
        hideLabelSeparator={hideLabelSeparator}
      >
        <ToggleButton
          isOn={value}
          onToggleCallback={this.onChangeToggle}
          isEnabled={!disabled && isEditable}
          className={className}
          toggleType={toggleType}
        />
      </FormWrapper>
    );
  }
}

ToggleSwitch.defaultProps = {
  name: "form-toggle-switch",
  dataKey: null,
  title: "",
  labelColumns: 3,
  formGroupClassName: "form-component-toggle-switch",
};

export default withTranslation()(ToggleSwitch);
