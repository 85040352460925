import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "@onlinesales-ai/input-v2";
import { RadioGroup } from "@onlinesales-ai/radio-v2";
import {EmptyMessage} from "@onlinesales-ai/message-card-v2";

const RadioButtonFilterComponent = ({
  value,
  onChange,
  tabKey,
  componentMetaData,
  emptyMsg,
  noValueLabel,
  ...restProps
}) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");

  const getFilteredItems = (items = [], searchStr) => {
    const filteredItems = [];

    if (items) {
      items.forEach(({ label, value }) => {
        const lowerCasedText = label && label.toLowerCase();
        const lowerCasedSearchStr = searchStr && searchStr.toLowerCase();
        if (lowerCasedText.includes(lowerCasedSearchStr)) {
          filteredItems.push({ label, value });
        }
      });
    }

    return filteredItems;
  };

  const handleSearchInputChange = (value) => {
    setSearchQuery(value);
  };

  const renderSearchInput = (tabKey, searchPlaceholder = "Type to Search") => {
    return (
      <div className="search-box-container">
        <Input
          value={searchQuery}
          placeholder={t(searchPlaceholder)}
          onChange={handleSearchInputChange}
        />
      </div>
    );
  };

  const { showSearchInput = false, searchPlaceholder, ...rest } = restProps;
  const { items: originalItems = [] } = componentMetaData;
  let filteredItems = [...originalItems];
  if (searchQuery) {
    filteredItems = getFilteredItems(filteredItems, searchQuery);
  }

  filteredItems = [
    {
      label: noValueLabel,
      value: undefined,
    },
    ...filteredItems
  ];

  return (
    <div className="form-group">
      {showSearchInput && renderSearchInput(tabKey, searchPlaceholder)}
      {
        filteredItems.length ? (
          <RadioGroup
            allowUndefined={true}
            name={`filter-tab-${tabKey}`}
            groupValue={value}
            data={filteredItems}
            onChange={(...params) => onChange(tabKey, ...params)}
            {...rest}
          />
        ) : (
          <EmptyMessage>
            {emptyMsg}
          </EmptyMessage>
        )

      }
    </div>
  );
};

RadioButtonFilterComponent.defaultProps = {
  value: undefined,
  onChange: () => {},
  componentMetaData: {},
  emptyMsg: "No matching items found",
  noValueLabel: "All"
};

export default RadioButtonFilterComponent;
