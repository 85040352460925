import AppActionTypes from "@onlinesales-ai/app-v2/application/types";
import _keyBy from "lodash/keyBy";
import _sortBy from "lodash/sortBy";

import Types from "./types";

const initialState = {
  leadData: {
    ids: [],
    data: {},
  },
  leadStatusData: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AppActionTypes.APP_CLIENT_CHANGE:
      {
        state = {
          ...initialState,
        };
      }
      break;
    case Types.SET_LEAD_DATA:
      {
        const key = action.data.storeKey || "leadData";
        const leadData = {
          ...state[key],
        };

        if (action.data?.list) {
          leadData.ids = action.data.list.map((l) => l.id);
          leadData.data = _keyBy(action.data.list, "id");
        }

        if (action.data?.listToAppend) {
          leadData.ids = [...leadData.ids, ...action.data.listToAppend.map((l) => l.id)];
          leadData.data = {
            ...leadData.data,
            ..._keyBy(action.data.listToAppend, "id"),
          };
        }

        state = {
          ...state,
          [key]: leadData,
        };
      }
      break;
    case Types.SET_MARK_AS_DONE_DATA:
      {
        const key = action.data.storeKey || "reminderData";
        const leadData = {
          ...state[key],
        };

        leadData.ids = leadData.ids.filter((id) => {
          return id !== action.data.reminderId;
        });

        state = {
          ...state,
          [key]: leadData,
        };
      }
      break;
    case Types.UNDO_MARK_AS_DONE_DATA:
      {
        const key = action.data.storeKey || "reminderData";
        const leadData = {
          ...state[key],
        };

        leadData.ids.splice(action.data.cardIndex, 0, action.data.leadId);

        state = {
          ...state,
          [key]: leadData,
        };
      }
      break;
    case Types.UPDATE_LEAD_DATA:
      {
        const key = action.data.storeKey || "leadData";
        const leadData = {
          ...state[key],
        };

        if (action.data?.leadToUpdate) {
          leadData.data[action.data.leadToUpdate.id] = {
            ...(leadData.data[action.data.leadToUpdate.id] || {}),
            ...action.data.leadToUpdate,
          };
        }

        state = {
          ...state,
          [key]: leadData,
        };
      }
      break;
    case Types.SET_LEAD_STATUS_COUNT:
      {
        state = {
          ...state,
          [action.storeKey]: action.data,
        };
      }
      break;
    case Types.LEAD_STATUS_COUNT: {
      const columnName = action?.accessor;
      if (Array.isArray(action?.data?.leadsGroupedData) && columnName) {
        const statusWiseCount = {
          ALL_LEADS: 0,
          statusCount: {
            nullSelectionHandle: 0,
          },
        };
        action?.data?.leadsGroupedData.map((status) => {
          if (status[columnName]) {
            statusWiseCount.statusCount[status[columnName]?.toLowerCase()] = status?.count || 0;
          }
          statusWiseCount.ALL_LEADS += status?.count || 0;
        });
        if (action?.key) {
          state = {
            ...state,
            [action.key]: {
              ...state[action.key],
              ...statusWiseCount,
            },
          };
        } else {
          state = {
            ...state,
            ...statusWiseCount,
          };
        }
      }
    }
      break;
    case Types.SET_LEAD_ALL_TIME_STATUS_COUNT:
      {
        const columnName = action?.accessor;
        if (Array.isArray(action?.data?.leadsGroupedData) && columnName) {
          const statusWiseCount = {
            ALL_LEADS: 0,
            statusCount: {
              nullSelectionHandle: 0,
            },
          };
          action?.data?.leadsGroupedData.map((status) => {
            if (status[columnName]) {
              statusWiseCount.statusCount[status[columnName]?.toLowerCase()] = status?.count;
            }
            statusWiseCount.ALL_LEADS += status?.count || 0;
          });
          state = {
            ...state,
            allTimeData: {
              ...(state.allTimeData || {}),
              ...statusWiseCount,
            },
          };
        }
      }
      break;
    default:
      break;
  }

  return state;
};

export default {
  LmsV2: reducer,
};