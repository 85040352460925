import React, { useEffect, useMemo, useRef, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

import { useControlled } from "@onlinesales-ai/util-methods-v2";

import { Default, WithIconHeader } from "../templates";

const TEMPLATES = {
  DEFAULT: Default,
  WITH_ICON_HEADER: WithIconHeader,
};

const PopoverNotification = ({
  template,
  children,
  wrapperTag: WrapperTag,
  templateProps,
  placement,
  isShow,
  setIsShow,
  triggerOnClick,
  type,
  showPopoverOnly,
  popoverClassName,
  showFirstTime,
  componentRef,
  childrenProps,
  overlayProps,
  ...restProps
}) => {
  const [show, setShow] = useControlled(isShow, showFirstTime);
  const [timeStamp] = useState(() => new Date().getTime());
  const isFirstTime = useRef(true);
  // const [isWalleBalancetLowClose, setWalleBalancetLowClose] = useState("false");

  const Component = TEMPLATES[template] || TEMPLATES.DEFAULT || TEMPLATES.WITH_ICON_HEADER;

  const setIsShowToUse = setIsShow || setShow;

  const triggerOnClickToUse = useMemo(() => {
    if (isFirstTime.current && showFirstTime) {
      isFirstTime.current = false;
      return true;
    }
    return triggerOnClick;
  }, [show, triggerOnClick]);

  const togglePopover = (status) => (setIsShowToUse(status || !show));

  const forceShow = () => {
    if (!show) {
      isFirstTime.current = true;
      togglePopover();
    }
  };

  useEffect(() => {
    isFirstTime.current = false;
  }, []);

  useEffect(() => {
    if (componentRef) {
      componentRef.current = {
        forceShow,
      };
    }

    return () => {
      if (componentRef) {
        componentRef.current = {};
      }
    };
  }, [show, isFirstTime.current]);

  // const walletlowBalCloseToggle = () => setWalleBalancetLowClose(!isWalleBalancetLowClose);

  const popover = (
    <Popover
      id={`${template}-${timeStamp}`}
      className={`popover-notification-wrapper ${
        show ? "popover-in" : "popover-out"
      } ${popoverClassName} ${template} ${type}`}
    >
      <Component
        onClose={() => {
          setIsShowToUse(false);
        }}
        // walletLowClose = {walletlowBalCloseToggle}
        type={type}
        togglePopover={togglePopover}
        triggerOnClick={triggerOnClickToUse}
        {...templateProps}
        {...restProps}
      />
    </Popover>
  );

  if (showPopoverOnly) {
    return popover;
  }

  return (
    <OverlayTrigger
      {...(triggerOnClickToUse ? { show } : {})}
      {...overlayProps}
      placement={placement}
      overlay={popover}
    >
      {WrapperTag ? (
        <WrapperTag>{children({ togglePopover, ...childrenProps })}</WrapperTag>
      ) : (
        children({ togglePopover, ...childrenProps })
      )}
    </OverlayTrigger>
  );
};

PopoverNotification.defaultProps = {
  template: "DEFAULT",
  overlayProps: {},
  placement: "bottom",
  triggerOnClick: false,
  showPopoverOnly: false,
  popoverClassName: "",
  showFirstTime: false,
  childrenProps: {},
};

export default PopoverNotification;
