import React, {useCallback} from "react";
import Creatable from "react-select/creatable";
import BaseDropdown from "./BaseDropdown";
import "./index.less";

const getValue = (opts, val) => {
  let retnVal = null;
  retnVal = opts.find((o) => o.value === val);

  if(!retnVal){
    retnVal = {
      label: val,
      value: val
    };
  }

  return retnVal;
};
const getMultipleValue = (opts, val) => {
  const retnVal = [];

  val.forEach((pValue) => {
    let valueToAppend = opts.find((o) => o.value === pValue);

    if (typeof pValue === "string" && pValue?.trim()?.length === 0) {
      return;
    }

    if (!valueToAppend) {
      valueToAppend = {
        label: pValue,
        value: pValue,
      };
    }

    retnVal.push(valueToAppend);
  });

  return retnVal;
};

const DropDownCreatable = ({
  createLabelPrefix = "Create",
  ...restProps
}) => {
  return (
    <BaseDropdown
      formatCreateLabel={
        useCallback((inputValue) => {
          return (!!createLabelPrefix ? `${createLabelPrefix} "${inputValue}"` : inputValue);
        },[createLabelPrefix])
      }
      {...restProps}
      SelectComponent={Creatable}
      getValue={getValue}
      getMultipleValue={getMultipleValue}
    />
  );
};

export default DropDownCreatable;
