import BaseClient from "../baseClient";

const serviceUrl = "/merchandiseFeedService";

class MerchandiseFeedService extends BaseClient {
  static getErrorMessages() {
    return {
      default: "Looks like we are facing some internal issue. Please, retry after sometime.",
      IP0000:
        "It seems some input parameters are not valid. Please update your inputs and try again.",
      AD0000:
        "Oops. It seems you are not authorized to take this action. Please log in with appropriate credentials.",
      AD001: "Oops. It seems you are not authorized to take this action. Please log in with appropriate credentials.",
      I0004:
        "Oops. Seems the system is acting up - sorry for the inconvenience. Please try again in some time. (Please be assured our tech geniuses have been notified of this glitch).",
    };
  }

  static searchMerchandiseFeed(request, application, options) {
    return this.apiCall(
      {
        url: `${options?.servicePrefix || ""}${serviceUrl}/search`,
        method: "POST",
        data: encodeURIComponent(JSON.stringify(request)),
      },
      application,
      options,
    );
  }

  static searchCollapseByMerchandiseFeed(request, application, options) {
    return this.apiCall(
      {
        url: `${options?.servicePrefix || ""}${serviceUrl}/searchCollapseBy`,
        method: "POST",
        data: encodeURIComponent(JSON.stringify(request)),
      },
      application,
      options,
    );
  }

  static entityLevelFetch(request, application, options) {
    return this.apiCall(
      {
        url: `${options?.servicePrefix || ""}${serviceUrl}/entityLevelFetch`,
        method: "POST",
        data: encodeURIComponent(JSON.stringify(request)),
      },
      application,
      options,
    );
  }

  static entityLevelSave(request, application, options) {
    return this.apiCall(
      {
        url: `${options?.servicePrefix || ""}${serviceUrl}/save`,
        method: "POST",
        data: encodeURIComponent(JSON.stringify(request)),
      },
      application,
      options,
    );
  }

  static upsertClientProduct(request, application) {
    return this.apiCall(
      {
        url: `${serviceUrl}/hyperlocal/upsertClientProducts`,
        method: "POST",
        data: encodeURIComponent(JSON.stringify(request)),
      },
      application,
    );
  }

  static updateProducts(request, application) {
    return this.apiCall(
      {
        url: `${serviceUrl}/hyperlocal/updateProducts`,
        method: "POST",
        data: JSON.stringify(request),
      },
      application,
    );
  }

  static fetchHeaderColumnMappings(payload, application) {
    return this.apiCall(
      {
        url: `${serviceUrl}/getHeaderColumnMappings`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
    );
  }

  static fetchMarketplaceEntities(payload, application, options) {
    return this.apiCall(
      {
        url: `${options?.servicePrefix || ""}${serviceUrl}/getMarketplaceFeedEntities`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
      options,
    );
  }

  static fetchLanguages(payload, application) {
    return this.apiCall(
      {
        url: `${serviceUrl}/getLanguages`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
    );
  }

  static fetchCategoryBiddingData(payload, application, options) {
    return this.apiCall(
      {
        url: `${options?.servicePrefix || ""}${serviceUrl}/categoryBiddingData`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
      options,
    );
  }

  static saveCategoryBiddingData(payload, application, options) {
    return this.apiCall(
      {
        url: `${options?.servicePrefix || ""}${serviceUrl}/biddingStrategies/storeBidStrategyData`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static getFunnelMetrics(payload, application, options) {
    return this.apiCall(
      {
        url: `${options?.servicePrefix || ""}${serviceUrl}/monetize/funnelMetrics`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
      options,
    );
  }

  static fetchMarketplaceSellerList(payload, application, options) {
    return this.apiCall(
      {
        url: `${options?.servicePrefix || ""}${serviceUrl}/getMarketplaceFeedEntities`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
      options,
    );
  }

  static fetchCategoryBidValues(payload, application) {
    return this.apiCall(
      {
        url: `${serviceUrl}/categoryConfigurations`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
    );
  }

  static postCategoryBidValues(payload, application, options) {
    return this.apiCall(
      {
        url: `${serviceUrl}/categoryConfigurations`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }
}

export default MerchandiseFeedService;
