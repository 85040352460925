import React from 'react';
import "./index.less"

const FooterNote = props => {
  return (
    <span
      className="credits-foot-note"
      dangerouslySetInnerHTML={{__html: props.footerContent}}
    />
  );
}

export default FooterNote;