/* eslint-disable no-else-return */
import {
  defaultVendorConfig,
} from "@onlinesales-ai/account-linking-v2/src/utilities/config";
import { alVendorEnum as vendorEnum } from "@onlinesales-ai/constants-v2";
import { isGMBLocationUnVerified } from "@onlinesales-ai/util-methods-v2";
import _some from "lodash/some";

export const ValidationMapping = {
  SUBSCRIPTION_CHECK: "SUBSCRIPTION_CHECK",
  FB_PAGE_LINK_CHECK: "FB_PAGE_LINK_CHECK",
  GMB_LINK_CHECK: "GMB_LINK_CHECK",
  MIN_PAGE_LINK_CHECK: "MIN_PAGE_LINK_CHECK",
  ALL_PAGE_LINK_CHECK: "ALL_PAGE_LINK_CHECK",
  IS_GMB_LOCATION_VERIFIED: "IS_GMB_LOCATION_VERIFIED",
  SUBSCRIPTION_PROCESSING_CHECK: "SUBSCRIPTION_PROCESSING_CHECK",
  BUSINESS_DETAILS_FILLED: "BUSINESS_DETAILS_FILLED",
  IS_MOBILE_DEVICE: "IS_MOBILE_DEVICE",
  FALSE_CHECK: "FALSE_CHECK",
  TRUE_CHECK: "TRUE_CHECK",
};

const pageToALMapping = {
  FACEBOOK: {
    vendor: vendorEnum.FACEBOOK,
    accountKey: "facebookPage",
  },
  GMB: {
    vendor: vendorEnum.GOOGLE,
    accountKey: "googleMyBysiness",
  },
};

export const checkValidation = (validation, state) => {
  const isBillingNotSubscribed = state.Application?.isBillingNotSubscribed;
  if (validation.validationType === ValidationMapping.SUBSCRIPTION_CHECK) {
    return !isBillingNotSubscribed;
  } else if (validation.validationType === ValidationMapping.GMB_LINK_CHECK) {
    const gmbClass = defaultVendorConfig[vendorEnum.GOOGLE]?.accountConfig?.googleMyBysiness?.class;
    if (state.AccountLinking?.vendorAccountData?.[gmbClass]) {
      return _some(state.AccountLinking?.vendorAccountData?.[gmbClass], (acc) => {
        return acc.isActive;
      });
    } else {
      return false;
    }
  } else if (validation.validationType === ValidationMapping.MIN_PAGE_LINK_CHECK) {
    const pagesToCheck = validation.pagesToCheck || Object.keys(pageToALMapping);
    let retnValue = false;

    for (let i = 0; i < pagesToCheck.length; i++) {
      const vendor = pageToALMapping[pagesToCheck[i]].vendor;
      const accountKey = pageToALMapping[pagesToCheck[i]].accountKey;
      const pageClass = defaultVendorConfig[vendor]?.accountConfig?.[accountKey]?.class;
      let isPageLinked = false;
      if (state.AccountLinking?.vendorAccountData?.[pageClass]) {
        isPageLinked = _some(state.AccountLinking?.vendorAccountData?.[pageClass], (acc) => {
          return acc.isActive;
        });
      }

      retnValue = retnValue || isPageLinked;

      if (retnValue) {
        break;
      }
    }

    return retnValue;
  } else if (validation.validationType === ValidationMapping.ALL_PAGE_LINK_CHECK) {
    const pagesToCheck = validation.pagesToCheck || Object.keys(pageToALMapping);
    let retnValue = true;

    for (let i = 0; i < pagesToCheck.length; i++) {
      const vendor = pageToALMapping[pagesToCheck[i]].vendor;
      const accountKey = pageToALMapping[pagesToCheck[i]].accountKey;
      const pageClass = defaultVendorConfig[vendor]?.accountConfig?.[accountKey]?.class;
      let isPageLinked = false;
      if (state.AccountLinking?.vendorAccountData?.[pageClass]) {
        isPageLinked = _some(state.AccountLinking?.vendorAccountData?.[pageClass], (acc) => {
          return acc.isActive;
        });
      }

      retnValue = retnValue && isPageLinked;

      if (!retnValue) {
        break;
      }
    }

    return retnValue;
  } else if (validation.validationType === ValidationMapping.IS_GMB_LOCATION_VERIFIED) {
    const vendorAccountData = state.AccountLinking?.vendorAccountData || {};
    const classKey = defaultVendorConfig[vendorEnum.GOOGLE]?.accountConfig?.googleMyBysiness?.class;
    return !isGMBLocationUnVerified(vendorAccountData[classKey]);
  } else if (validation.validationType === ValidationMapping.SUBSCRIPTION_PROCESSING_CHECK) {
    const atLeastOnePendingOrder = !!state.Application.pendingOrderDetails?.length;
    return !(isBillingNotSubscribed && atLeastOnePendingOrder);
  } else if (validation.validationType === ValidationMapping.BUSINESS_DETAILS_FILLED) {
    return !!state.Application.isBusinessDetailsFilled;
  } else if (validation.validationType === ValidationMapping.IS_MOBILE_DEVICE) {
    return state.Application.isMobile;
  } else if (validation.validationType === ValidationMapping.FALSE_CHECK) {
    return false;
  } else if (validation.validationType === ValidationMapping.TRUE_CHECK) {
    return true;
  }
};

export const checkOSValidations = (validations, state) => {
  const retnData = [];

  validations.forEach((validationDetails) => {
    if (validationDetails) {
      const result = checkValidation(validationDetails, state);
      if (result === validationDetails.value) {
        retnData.push({
          ...validationDetails.config,
        });
      }
    }
  });

  return retnData;
};
