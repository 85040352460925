import { setDomainConfig } from "@onlinesales-ai/app-v2/domainConfig";
import { getDomainConfiguration } from "src/domainConfig/configuration";

import Types from "./types";

export const setDashobardDateRange = ({
  startDate,
  endDate,
  compareStartDate,
  compareEndDate,
}) => ({
  type: Types.SET_DASHBOARD_DATE_RANGE,
  startDate,
  endDate,
  compareStartDate,
  compareEndDate,
});

export const setIsDashboardLocked = (isLocked) => ({
  type: Types.SET_IS_DASHBOARD_LOCKED,
  isLocked,
});

export const setRealTimeReporting = (isRealTimeReporting) => {
  return async (dispatch, getState) => {
    const { OnBoarding = {}, DomainConfig = {} } = getState() || {};

    // this has to be from original config file as
    // we are repeating all process of setting domain config
    const originalDomainConfig = await getDomainConfiguration({});

    dispatch(
      setDomainConfig(
        originalDomainConfig,
        OnBoarding.data || {},
        // took this from DomainConfig as,
        // if we have override liveReporting from acl it will apply here
        isRealTimeReporting ? DomainConfig.liveReportingConfigOverride : {},
      ),
    );

    dispatch({
      type: Types.SET_REAL_TIME_REPORTING,
      isRealTimeReporting,
    });
  };
};
