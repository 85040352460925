import React from "react";
import _get from "lodash/get";
import { withTranslation } from "react-i18next";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { InputField } from "@onlinesales-ai/form-components-v2";
import { MarketPlaceServiceClient } from "@onlinesales-ai/services-v2";

class TopupLimit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      spendingLimitFetchErrorMsg: null,
      spendingLimit: 0,
      spendingLimitBreakdown: null,
    };
    const { dataKey, formValues } = this.props;
    this.currentValue = _get(formValues, dataKey);
  }

  componentDidMount() {
    this.fetchSpendingLimit();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { dataKey, formValues } = this.props;
    const newValue = _get(nextProps.formValues, nextProps.dataKey);

    if (newValue !== _get(formValues, dataKey)) {
      // this.fetchSpendingLimit();
    }
  }

  fetchSpendingLimit = () => {
    const { clientId, shopInfo, onChange, dataKey, onWalletLoadingComplete = () => {} } = this.props;
    this.setState({
      spendingLimitFetchErrorMsg: null,
    });
    MarketPlaceServiceClient.getSpendingLimitForClient(
      {
        clientId,
        storeType: shopInfo.storeType,
        currency: shopInfo.currencyCode,
      },
      "Dashboard",
    )
      .then((response) => {
        const spendingLimitResult = (response && response.result) || {};

        this.setState({
          spendingLimitFetchErrorMsg: null,
          spendingLimit: spendingLimitResult.spendingLimit || 0,
          spendingLimitBreakdown: spendingLimitResult.spendingLimitBreakdown,
        });
        onChange({ [dataKey]: spendingLimitResult.spendingLimit || 0 });
        onWalletLoadingComplete();
      })
      .catch(({ error }) => {
        this.setState({
          spendingLimitFetchErrorMsg: error?.errorMsg,
          spendingLimit: 0,
        });
      });
  };

  render() {
    const { formValues, dataKey, showErrors, formErrors, ...restProps } = this.props;
    const { spendingLimitFetchErrorMsg, spendingLimit } = this.state;
    return (
      <InputField
        showCurrency
        type="number"
        value={spendingLimit}
        apiError={spendingLimitFetchErrorMsg}
        {...restProps}
        isEditable={false}
      />
    );
  }
}

TopupLimit.defaultProps = {
  showCurrency: true,
  dataKey: "TopupLimit",
  title: "Wallet Balance:",
  onWalletLoadingComplete: () => {}
};

const mapStateToProps = (state) => {
  return {
    shopInfo: state.Application.shopInfo,
    clientId: state.Application.clientId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TopupLimit));
