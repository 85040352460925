import React from "react";
import PropTypes from "prop-types";
import { Trans } from "react-i18next";
import { formatNumberInAbbreviations, getCurrencyDOM } from "@onlinesales-ai/util-methods-v2";
import WithTooltip from "@onlinesales-ai/tooltip-v2";
import "./index.less";

const ConfigurationCandy = ({
  className,
  candyInfo = {},
  secondRowChildren,
  isLoading = false,
  shouldHaveHoverEffect,
  shouldHaveShadowEffect,
  bgColorStatus,
  firstRowLeftChildren,
  firstRowRightChildren,
}) => {
  const getLoaderDom = (secondRowChildren) => {
    return (
      <div className="configuration-candy-loader">
        <div className="top-content-loader animated-bg">
          <div className="bg-masker top" />
        </div>
        {secondRowChildren && (
          <React.Fragment>
            <div className="candy-divider" />
            <div className="bottom-content-loader animated-bg">
              <div className="bg-masker top" />
            </div>
          </React.Fragment>
        )}
      </div>
    );
  };

  const renderStats = (stats) => {
    let domToReturn = null;
    if (stats && stats.length) {
      domToReturn = stats.map((statsObj) => {
        let {
          displayName,
          value = 0,
          showCurrency,
          isLoading,
          dataFetchError,
          showInPercentage,
          precision = 0,
        } = statsObj;
        if (precision && Number(precision) > 0) {
          value = Number(value);
        } else {
          value = Math.round(value);
        }
        if (showInPercentage) {
          value = value * 100;
        }
        value = dataFetchError
          ? "NA"
          : formatNumberInAbbreviations(value, precision);
        return (
          <div className="configuration-candy-stat col-sm-2">
            <span
              className={`configuration-candy-stat-value ${
                isLoading ? "is-loading" : "not-loading"
              }`}
            >
              {isLoading ? (
                <div className="animated-bg" />
              ) : (
                <React.Fragment>
                  {!dataFetchError && showCurrency && getCurrencyDOM()}
                  {value}
                  {!dataFetchError && showInPercentage && "%"}
                </React.Fragment>
              )}
            </span>
            <div className="configuration-candy-stat-unit">
              <Trans>{displayName}</Trans>
            </div>
          </div>
        );
      });
    }
    return domToReturn;
  };

  let {
    name = "",
    subHeading = "",
    logo = "",
    icon = "",
    stats = [],
    isActive,
    isLaunchInProgress,
  } = candyInfo;

  let loadingClass = isLoading ? "candy-loading" : "candy-not-loading";
  let launchStatusClass = isActive ? "launch-approved" : "launch-pending"; // to show whether launch is approved by sendo
  let hoverStatusClass = shouldHaveHoverEffect ? "hover-on" : "";
  let shadowStatusClass = shouldHaveShadowEffect ? "shadow-on" : "";

  return (
    <div
      className={`configuration-candy-wrapper ${className} ${launchStatusClass} ${hoverStatusClass} ${shadowStatusClass} ${bgColorStatus}`}
    >
      <div className={`configuration-candy-card ${loadingClass}`}>
        {isLoading ? (
          getLoaderDom(secondRowChildren)
        ) : (
          <React.Fragment>
            <div className="first-row">
              {firstRowLeftChildren}
              {logo && (
                <div className="configuration-candy-logo">
                  <img src={logo} title={name} alt={name} />
                </div>
              )}
              <div className="configuration-candy-header">
                <div className="configuration-candy-title">
                  <WithTooltip title={name}>
                    <h4 className="ellipsis-text">{name}</h4>
                  </WithTooltip>
                </div>
                {subHeading &&
                  (typeof subHeading === "string" ? (
                    <div className="configuration-candy-desc">{subHeading}</div>
                  ) : (
                    subHeading
                  ))}
              </div>

              <div className="configuration-candy-stats-wrapper">
                {!!stats.length &&  renderStats(stats)}
              </div>
              {firstRowRightChildren && (
                <div className="configuration-candy-right-children">
                  {firstRowRightChildren}
                </div>
              )}
            </div>
            {secondRowChildren && (
              <React.Fragment>
                <div className="candy-divider" />
                <div className="second-row">{secondRowChildren}</div>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
      {isLaunchInProgress && (
        <div className="configuration-candy-overlay">
          <p>
            <Trans>Activation in progress, Stay Tuned!</Trans>
          </p>
        </div>
      )}
    </div>
  );
};

ConfigurationCandy.propTypes = {
  className: PropTypes.string,
  candyInfo: PropTypes.shape({}),
  secondRowChildren: PropTypes.element,
  isLoading: PropTypes.bool,
  shouldHaveHoverEffect: PropTypes.bool,
  shouldHaveShadowEffect: PropTypes.bool,
  bgColorStatus: PropTypes.array,
  firstRowLeftChildren: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  firstRowRightChildren: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
};

ConfigurationCandy.defaultProps = {
  isLoading: false,
  candyInfo: {},
};

export default ConfigurationCandy;

/**
 * Css use cases
 * 1. isLoading prop true, false,
 * 2. isLaunchInProgress true false
 */
