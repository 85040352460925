import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";

/**
 * find dom element that portal is append react component on it.
 */
const modalRoot = document.body;

/**
 * Modal Component
 * append component outside react dom.
 * pass custom component for override default component
 *
 * destroyOnClose: un mount component from dom when closed
 */
const ModalBase = ({
  isOpen,
  component: Component,
  destroyOnClose,
  onUnMount,
  renderInPortal,
  portalElementSelector,
  ...rest
}) => {
  const [unMounted, setUnMounted] = useState(true);

  useEffect(() => {
    if (isOpen) {
      setUnMounted(false);
    }
  }, [isOpen]);

  const onChangeUnMounted = (val) => {
    setUnMounted(val);
    if (onUnMount) {
      onUnMount();
    }
  };

  if (destroyOnClose && unMounted) {
    return null;
  }

  const element = portalElementSelector ? document.querySelector(portalElementSelector) : modalRoot;

  const render = () => (
    <Component
      onChangeUnMounted={onChangeUnMounted}
      isOpen={isOpen}
      renderInPortal={renderInPortal}
      element={element}
      {...rest}
    />
  );

  if (renderInPortal) {
    return ReactDOM.createPortal(render(), element);
  }

  return render();
};

ModalBase.defaultProps = {
  component: null,
  destroyOnClose: true,
  renderInPortal: true,
};

export default ModalBase;
