import _isEmpty from "lodash/isEmpty";
import { ShopsUIService, ReportingUIService } from "@onlinesales-ai/services-v2";
import { definitions as HygieneDefinitions } from "@onlinesales-ai/constants-v2";
import PlatformEventManager from "@onlinesales-ai/event-manager-v2";
import types from "./types";

export const setPendingOpportunitiesCountFetchInProgress = (dataFetchInProgress) => {
  return {
    type: types.APP_SET_PENDING_OPPORTUNITIES_COUNT_FETCH_IN_PROGRESS,
    dataFetchInProgress,
  };
};

const setPendingOpportunitiesCount = (count) => {
  return {
    type: types.APP_SET_PENDING_OPPORTUNITIES_COUNT,
    count,
  };
};

const setSuggestionIds = (suggestionIds) => {
  return {
    type: types.APP_SET_PENDING_OPPORTUNITIES_SUGGESTIONS,
    suggestionIds,
  };
};

const setPendingOpportunitiesHygiene = (hygiene) => {
  return {
    type: types.APP_SET_PENDING_OPPORTUNITIES_HYGIENE,
    hygiene,
  };
};

const setHygieneFetchInProgress = (inProgress) => {
  return {
    type: types.HYGIENE_SET_HYGIENE_FETCH_IN_PROGRESS,
    hygieneFetchInProgress: inProgress,
  };
};

const setHygieneRefreshFetchInProgress = (inProgress) => {
  return {
    type: types.HYGIENE_SET_HYGIENE_REFRESH_FETCH_IN_PROGRESS,
    hygieneRefreshFetchInProgress: inProgress,
  };
};

const setHygieneError = (errorMsg) => {
  return {
    type: types.HYGIENE_SET_HYGIENE_FETCH_ERROR_MSG,
    hygieneFetchErrorMsg: errorMsg,
  };
};

export const fetchHygieneData = (request) => {
  return (dispatch, getState) => {
    dispatch(setHygieneFetchInProgress(true));
    dispatch(setHygieneError(null));
    let retnPromise = ReportingUIService.fetchHygieneData(request, "HYGIENE_ACQUIRE")
      .then((response) => {
        dispatch(setPendingOpportunitiesHygiene(response));
        dispatch(setHygieneFetchInProgress(false));
      })
      .catch((error, errorMsg) => {
        dispatch(setHygieneError(errorMsg));
        dispatch(setHygieneFetchInProgress(false));
      });
    return retnPromise;
  };
};

export const refreshHygieneData = (request) => {
  return (dispatch, getState) => {
    let state = getState();

    dispatch(setHygieneRefreshFetchInProgress(true));
    dispatch(setHygieneError(null));
    let retnPromise = ReportingUIService.refreshHygieneData(request, "HYGIENE_ACQUIRE")
      .then((response) => {
        dispatch(setPendingOpportunitiesHygiene(response));
        dispatch(setHygieneRefreshFetchInProgress(false));
      })
      .catch((error, errorMsg) => {
        dispatch(setHygieneError(errorMsg));
        dispatch(setHygieneRefreshFetchInProgress(false));
      });
    return retnPromise;
  };
};

let hygieneSuggestionPollingTimer = null;
let hygieneSuggestionEventCb = null;
let stopPolling = false;

export const getPendingActionRequiredData = (
  dispatch,
  getState,
  shouldSetLoadingState,
  setSuggestionDefinitions,
) => {
  const filterSuggestionByAttribute = (rollupSuggestions, attributeTypes, suggestionsToConsider) => {
    return rollupSuggestions.filter((suggestion) => {
      if (suggestionsToConsider?.length) {
        if (!suggestionsToConsider.includes(suggestion?.metaData?.suggestionType)) {
          return false;
        }
      }
      const attributes = attributeTypes?.[suggestion?.metaData?.suggestionType] || [];
      return attributes.length === 0 || attributes.includes(suggestion?.metaData?.attribute);
    });
  };
  const requests = {};
  const state = getState();
  const { clientId, isAllClientsSelected } = state.Application;

  if (!clientId || isAllClientsSelected) {
    return;
  }
  const domainConfig = state.DomainConfig || {};
  const config = domainConfig.pendingOpportunitiesCountFetchConfig || {};

  const props = domainConfig.pendingOpportunitiesCountFetchConfigProps || {};
  const suggestionsConfig = config.SUGGESTIONS;
  const hygieneConfig = config.HYGIENE;
  const result = {
    count: {
      HYGIENE: 0,
      SUGGESTIONS: 0,
    },
  };
  // if (hygieneConfig && hygieneConfig.shouldFetch) {
  //   const jsonQueryOverride = hygieneConfig.jsonQueryOverride || {};
  //   requests["HYGIENE"] = {
  //     hostname: "services.onlinesales.ai",
  //     endpoint: "/reportingUISvc/hygieneCheck",
  //     method: "GET",
  //     data: {
  //       jsonQuery: {
  //         clientId,
  //         validationType: "HYGIENE",
  //         extraFilters: {
  //           severity: ["ERROR", "WARNING"],
  //         },
  //         ...jsonQueryOverride,
  //       },
  //     },
  //   };
  // }

  // if (suggestionsConfig && suggestionsConfig.shouldFetch) {
  //   const jsonQueryOverride = suggestionsConfig.jsonQueryOverride || {};
  //   const { suggestionsFetchConfig = {} } = suggestionsConfig || {};
  //   const { suggestionTypes = [] } = suggestionsFetchConfig || {};

  //   requests.SUGGESTIONS = {
  //     hostname: "services.onlinesales.ai",
  //     endpoint: "/suggestionDbSvc/rollupSuggestions",
  //     method: "GET",
  //     data: {
  //       jsonQuery: {
  //         clientId,
  //         filter: {
  //           isExpired: false,
  //           suggestionTypes,
  //         },
  //         ordering: {
  //           orderBy: "created_date",
  //           order: "DESCENDING",
  //         },
  //         ...jsonQueryOverride,
  //       },
  //     },
  //   };
  // }

  if (_isEmpty(requests)) {
    dispatch(setPendingOpportunitiesCount(result.count));
    return new Promise((resolve, reject) => {
      reject();
    });
  }
  const payload = {
    requests: requests,
  };

  if (shouldSetLoadingState) {
    dispatch(setPendingOpportunitiesCountFetchInProgress(true));
  }

  // eslint-disable-next-line consistent-return
  return new Promise((resolve, reject) => {
    ShopsUIService.batchCall(payload, "application")
      .then((response) => {
        if (response && response.data) {
          const { suggestionsFetchConfig = {} } = suggestionsConfig || {};
          const { suggestionsToConsider, attributeTypes = {} } = suggestionsFetchConfig || {};

          const suggestions = response.data?.SUGGESTIONS;
          const hygiene = response.data?.HYGIENE;

          if (requests.HYGIENE && hygiene) {
            const checksToNotShow = hygieneConfig.checksToNotShow || [];

            Object.keys(hygiene).forEach((currentValue) => {
              const hygieneDataForCurrentCheck = hygiene[currentValue] || {};
              if (
                !hygieneDataForCurrentCheck.isSuccessful &&
                !!HygieneDefinitions[currentValue] &&
                !checksToNotShow.includes(currentValue)
              ) {
                result.count.HYGIENE++;
              }
            });

            result.HYGIENE = hygiene;

            dispatch(setPendingOpportunitiesHygiene(hygiene));
          }

          if (
            requests.SUGGESTIONS &&
            suggestions &&
            !suggestions.exception &&
            suggestions.rollUpSuggestions
          ) {
            let rollupSuggestions = suggestions.rollUpSuggestions || [];
            // Filter by attribute types
            // if (!_isEmpty(attributeTypes)) {
              rollupSuggestions = filterSuggestionByAttribute(rollupSuggestions, attributeTypes, suggestionsToConsider);
            // }
            result.count.SUGGESTIONS = rollupSuggestions.length;
            result.SUGGESTIONS = rollupSuggestions;
            const definitions = {};
            const suggestionIds = rollupSuggestions.map((data = {}) => {
              const { _id } = data;
              if (_id) {
                definitions[_id] = data;
                return _id;
              }
            });
            dispatch(setSuggestionIds(suggestionIds));
            if (typeof setSuggestionDefinitions === "function") {
              // This method needs to be pass from where you are calling
              dispatch(setSuggestionDefinitions(definitions));
            }
          }
          dispatch(setPendingOpportunitiesCount(result.count));

          if (shouldSetLoadingState) {
            dispatch(setPendingOpportunitiesCountFetchInProgress(false));
          }
          resolve(result);
        } else {
          reject(
            "Something Went Wrong, Please try again or Contact Support if the problem persists",
          );
        }

        if (hygieneSuggestionEventCb) {
          PlatformEventManager.off("REFRESH_SUGGESTION_HYGIENE_DATA", hygieneSuggestionEventCb);
        }

        hygieneSuggestionEventCb = async () => {
          try {
            await getPendingActionRequiredData(dispatch, getState, false);
          } catch (err) {}
        };

        PlatformEventManager.on("REFRESH_SUGGESTION_HYGIENE_DATA", hygieneSuggestionEventCb);

        if (props.hasDataPolling && !stopPolling) {
          if (hygieneSuggestionPollingTimer) {
            clearTimeout(hygieneSuggestionPollingTimer);
          }
          hygieneSuggestionPollingTimer = setTimeout(async () => {
            try {
              await getPendingActionRequiredData(dispatch, getState, false);
            } catch (err) {}
          }, 2 * 60 * 1000);
        }
      })
      .catch((error, errorMsg) => {
        if (shouldSetLoadingState) {
          dispatch(setPendingOpportunitiesCountFetchInProgress(false));
        }

        if (error?.errorCode === "UNAUTHORIZED" || error?.errorCode === "AD0000") {
          stopPolling = true;
        }
        reject(errorMsg);
      });
  });
};

export const fetchHygieneSuggestionsPerformanceData = (setSuggestionDefinitions) => {
  return async (dispatch, getState) => {
    try {
      await getPendingActionRequiredData(dispatch, getState, true, setSuggestionDefinitions);
    } catch (err) {}
  };
};
