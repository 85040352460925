import React, { useState } from "react";
import { Button } from "@onlinesales-ai/button-v2";
import WithTooltip from "@onlinesales-ai/tooltip-v2";

import "./index.less";

const HelpSection = ({ containerClass, iconClass, title, url, redirectUrl }) => {
  const onIconClick = () => {
    redirectUrl(url);
  };

  return (
    <div className={`addon-help-btn-wrapper ${containerClass}`}>
      <WithTooltip title={title}>
        <Button shape="rounded" className="help-icon-btn btnheight" onClick={onIconClick}>
          <span className={`icon ${iconClass}`} />
        </Button>
      </WithTooltip>
    </div>
  );
};

HelpSection.propTypes = {};
HelpSection.defaultProps = {
  iconClass: "icon-help",
  containerClass: "",
  title: "Help",
  redirectUrl: () => {},
};

export default HelpSection;
