import React from "react";
import { connect } from "react-redux";
import StripeCardUpdate from "./index";
import { OSBillingServiceV2 } from "@onlinesales-ai/services-v2";
import PlatformEventManager from "@onlinesales-ai/event-manager-v2";

class StripeBillingV2Wrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fetchOrderIdLoading: false,
    };
  }

  onCardUpdate = async() => {
    const {
      clientId,
      userInfo,
      currency,
      platform,
      amount,
      onPaymentSuccess,
      onError,
      beforePayment = () => Promise.resolve(),
      fireEventOnOrderId,
      fireEventOnPaymentSuccess,
      chargeAPICall,
      financeChargeAPICall,
    } = this.props;

    this.setState({
      fetchOrderIdLoading: true,
    });

    try {
      await beforePayment();
    } catch (err) {
      return;
    }

    try {
      let response = {};

      if (financeChargeAPICall) {
        await financeChargeAPICall();
      } else if (chargeAPICall) {
        response = await chargeAPICall(amount, {}, {platform});
      } else {
        response = await OSBillingServiceV2.charge({
          clientId,
          email: userInfo?.email,
          platform,
          amount,
          currency,
        });
      }

      if (fireEventOnOrderId && fireEventOnOrderId.length) {
        fireEventOnOrderId.forEach((event) => {
          PlatformEventManager.emit(event);
        });
      }

      if (fireEventOnPaymentSuccess && fireEventOnPaymentSuccess.length) {
        fireEventOnPaymentSuccess.forEach((event) => {
          PlatformEventManager.emit(event);
        });
      }

      this.setState({
        fetchOrderIdLoading: false,
      });

      onPaymentSuccess(response);
    }
    catch({err, errorMsg}){
      this.setState({
        fetchOrderIdLoading: false,
      });
      onError(errorMsg);
    }
    
  };
  
  render() {
    const {children, platform, apiVersion} = this.props;
    const { fetchOrderIdLoading } = this.state;

    return (
      <StripeCardUpdate
        platform={platform}
        isPaymentInProgress={fetchOrderIdLoading}
        onCardUpdate={this.onCardUpdate}
        apiVersion={apiVersion}
        children={children}/>
    );
  }
};

const mapStateToProps = (state, ownProps) => {
  return {
    clientId: ownProps.clientId || state.Application.clientId,
    userInfo: ownProps.userInfo || state.Application?.userInfo,
  };
};

export default connect(mapStateToProps)(StripeBillingV2Wrapper);