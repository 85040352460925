import { AbortError } from "./AbortController";

const DONE_STATE = 4;

function xhr(url, options = {}) {
  return new Promise((resolve, reject) => {
    if (options.signal && options.signal.aborted) {
      return reject(new AbortError());
    }

    const method = options.method || "get";
    const request = new XMLHttpRequest();

    function abort() {
      request.abort();
    }

    request.open(method, url, true);

    if(options.headers){
      Object.keys(options.headers).forEach((key) => {
        request.setRequestHeader(key, options.headers[key]);
      });
    }

    request.onload = () => {
      if (request.status >= 200 && request.status < 400) {
        return resolve(JSON.parse(request.responseText));
      }

      let res = {};

      try {
        res = JSON.parse(request.responseText);
      } catch (err) {}

      return reject(res);
    };

    if (options.signal) {
      options.signal.addEventListener("abort", abort);

      request.onreadystatechange = function () {
        if (request.readyState === DONE_STATE) {
          options.signal.removeEventListener("abort", abort);
        }
      };
    }

    if(options.onProgress){
      request.upload.onprogress = (e) => {
        let completedPercent = (e.loaded / e.total) * 100;
        const downLink = navigator?.connection?.downlink;
        let eta = 0;
        if (downLink && e.total) {
          eta = (e.total / 1000000) / downLink;
        }

        options.onProgress({
          loaded: e.loaded,
          total: e.total,
          completed: completedPercent,
          eta: parseInt(eta, 10),
        });
      };
    }

    request.onabort = function () {
      reject(new AbortError());
    };

    request.onerror = reject;
    request.ontimeout = reject;

    request.send(options.body || null);
  });
}

export default xhr;
