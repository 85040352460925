import AppActionTypes from "../application/types";

import Types from "./types";

const initialState = {
  isFetchInProgress: false,
  goalDetails: {
    // "123": {
    //   "data": {},
    //   "skuSelectionData": {},
    //   "bidSettingData": {}
    // }
  },
  launcherId: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AppActionTypes.APP_CLIENT_CHANGE:
      {
        state = {
          ...initialState,
        };
      }
      break;

    case Types.SET_EMAIL_GOALS_LIST_DATA:
      {
        const dataToSave = action.goals;

        Object.keys(state.goalDetails).forEach((id) => {
          dataToSave[id] = {
            ...(state.goalDetails?.[id] || {}),
            ...dataToSave[id],
          };
        });

        state = {
          ...state,
          goalDetails: dataToSave,
        };
      }
      break;
    case Types.SET_EMAIL_GOALS_LIST_FETCH_IN_PROGRESS:
      state = {
        ...state,
        isFetchInProgress: action.isLoading,
      };
      break;

    case Types.SET_EMAIL_GOAL_DATA:
      {
        state = {
          ...state,
          goalDetails: {
            ...state.goalDetails,
            [action.goalId]: {
              ...(state.goalDetails?.[action.goalId] || {}),
              data: {
                ...(state.goalDetails?.[action.goalId]?.data || {}),
                ...action.data,
              },
            },
          },
        };
      }
      break;

    default:
      break;
  }
  return state;
};

export default {
  EmailGoals: reducer,
};
