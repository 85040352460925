import React from "react";
import { connect } from "react-redux";
import "src/i18n";

import { getLSItem } from "@onlinesales-ai/util-methods-v2";
import { changeLanguage } from "@onlinesales-ai/i18n-v2";
import { routes } from "src/utilities/constants";

class InitLanguage extends React.Component {
  constructor(props) {
    super(props);

    const { pathname, search } = props.location || {};
    const searchParams = new URLSearchParams(search);
    let shouldChangeLanguage = !searchParams.get("lng");

    if (shouldChangeLanguage && getLSItem("m-lng")) {
      shouldChangeLanguage = false;
    }

    if (shouldChangeLanguage) {
      if (
        window.location.hostname === "thanachart.onlinesales.ai" ||
        window.location.hostname === "driveconnectplatform.onlinesales.ai"
      ) {
        changeLanguage("th");
      } else if (window.location.hostname === "mitsubishi-id.onlinesales.ai") {
        changeLanguage("id_mitsubishi");
      } else if (
        window.location.hostname === "lamoda-staging.onlinesales.ai" ||
        // window.location.hostname === "appslocal-dev.onlinesales.ai" ||
        window.location.hostname === "lamoda.onlinesales.ai"
      ) {
        changeLanguage("ru");
      } else if (
        window.location.hostname === "oms.tving.com" ||
        window.location.hostname === "oms-dev.tving.com"
      ) {
        changeLanguage("ko");
      }
    }
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    location: state.router.location,
  };
};

export default connect(mapStateToProps)(InitLanguage);
